<div style="padding: 10px">
  <app-member-goals
    [therapySession]="therapySession"
    [patient]="patient"
    [hasGoalsViewAccess]="hasGoalsViewAccess"
    [hasGoalsAddAccess]="hasGoalsAddAccess"
    [hasGoalsEditAccess]="hasGoalsEditAccess"
    [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
  >
  </app-member-goals>
</div>
