import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ICaseTreatmentAsam } from 'src/app/_models/case/treatment/admission/casetreatmentasam.model';
import { CaseTreatmentAsamApiService } from 'src/app/_services/case/treatment/admission/casetreatmentasam.service';
import { CaseAsamAdmissionOngoingComponent } from '../admissions/case-asam-admission-ongoing/case-asam-admission-ongoing.component';

@Component({
  selector: 'app-case-treatment-asam',
  templateUrl: './case-treatment-asam.component.html',
  styleUrl: './case-treatment-asam.component.css',
})
export class CaseTreatmentAsamComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() case: Case;
  @Input() patient: Patient;

  displayedColumns = ['createdDate', 'createdBy', 'actions'];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;
  selectedTab = 'Assessments';

  asamTabs = [
    { value: 'Assessments', label: 'Assessments' },
    { value: 'Progress Report', label: 'Progress Report' },
  ];

  patientId: string;

  constructor(
    public dialog: MatDialog,
    public casetreatmentasamApiService: CaseTreatmentAsamApiService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadCaseTreatmentAsamList();
  }

  addCaseTreatmentAsam() {
    const dialogRef = this.dialog.open(CaseAsamAdmissionOngoingComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      maxHeight: '80vh',
      data: {
        action: 'ADD',
        casetreatmentasam: null,
        case: this.case,
        patient: this.patient,
        recordType: 'Ongoing',
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseTreatmentAsamList();
      }
    });
  }

  public editCaseTreatmentAsam(casetreatmentasam: ICaseTreatmentAsam) {
    const dialogRef = this.dialog.open(CaseAsamAdmissionOngoingComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      maxHeight: '80vh',
      data: {
        action: 'EDIT',
        casetreatmentasam,
        case: this.case,
        patient: this.patient,
        recordType: 'Ongoing',
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseTreatmentAsamList();
      }
    });
  }

  public loadCaseTreatmentAsamList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.casetreatmentasamApiService
      .getCaseTreatmentAsamList(
        this.case.id,
        this.patient.id,
        '',
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadCaseTreatmentAsamList();
  }

  selectTab(subTab: string) {
    this.selectedTab = subTab;
  }
}
