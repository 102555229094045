import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ICaseTreatmentAdmissionProfile } from 'src/app/_models/case/treatment/admission/casetreatmentadmissionprofile.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CaseTreatmentAdmissionProfileApiService {
    constructor(private httpClient: HttpClient) { }
    getCaseTreatmentAdmissionProfileDetails(caseId: string, programId: string): Observable<ResponseModal> {
        let params = new HttpParams();
        if (caseId) params = params.append('caseId', caseId);
        if (programId) params = params.append('programId', programId);

        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/casetreatmentadmissionprofile`, { params: params });
    }
    updateCaseTreatmentAdmissionProfile(thisCaseTreatmentAdmission: ICaseTreatmentAdmissionProfile): Observable<ICaseTreatmentAdmissionProfile> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ICaseTreatmentAdmissionProfile>(`${environment.apiUrl}/casetreatmentadmissionprofile`, thisCaseTreatmentAdmission, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}