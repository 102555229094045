<div class="col-md-12">
  <form
    [formGroup]="treatmentdischargelegalForm"
    (ngSubmit)="onTreatmentDischargeLegalFormSubmit()"
  >
    <div class="row">
      <!-- Legal Status  -->
      <div class="col-md-12">
        <label class="labelText">
          Legal Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="legalStatus"
            multiple
            placeholder="Legal Status"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-option
                  class="mat-elevation-z2 mat-chip-active"
                  *ngFor="
                    let record of treatmentdischargelegalForm.controls[
                      'legalStatus'
                    ].value;
                    let i = index
                  "
                  (removed)="removeSelectedOption(record, i)"
                >
                  {{ record }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </mat-chip-listbox>
            </mat-select-trigger>
            <ng-container>
              <mat-option
                *ngFor="let availableOption of legalStatusOptions"
                [value]="availableOption"
              >
                {{ availableOption }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="treatmentdischargelegalForm.controls['legalStatus'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--      Number of Arrests Up To 30 Days During Treatment -->
      <div class="col-md-6">
        <label class="labelText">
          Number of Arrests Up To 30 Days During Treatment
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Number Of Arrests Up To 30 Days During Treatment"
            formControlName="numberOfArrestsUpTo30DaysDuringTreatment"
            name="numberOfArrestsUpTo30DaysDuringTreatment"
            required
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="
              treatmentdischargelegalForm.controls[
                'numberOfArrestsUpTo30DaysDuringTreatment'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Total Number of Arrests Since Admission -->
      <div class="col-md-6">
        <label class="labelText">
          Total Number of Arrests Since Admission
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Total Number Of Arrests Since Admission"
            formControlName="totalNumberOfArrestsSinceAdmission"
            name="totalNumberOfArrestsSinceAdmission"
            required
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="
              treatmentdischargelegalForm.controls[
                'totalNumberOfArrestsSinceAdmission'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentdischargelegalForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
