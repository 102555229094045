<div class="padding:15px">
  <form
    [formGroup]="treatmentplanoverviewForm"
    (ngSubmit)="onTreatmentPlanOverviewFormSubmit()"
  >
    <div class="row">
      <!-- Assessments Reviewed -->
      <div class="col-md-12">
        <label class="labelText">
          Assessments Reviewed <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="assessmentsReviewed"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentplanoverviewForm.controls['assessmentsReviewed'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Presenting Problem-->
      <div class="col-md-12">
        <label class="labelText">
          Presenting Problem <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="presentingProblem"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentplanoverviewForm.controls['presentingProblem'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Strengths Resources Abilities -->
      <div class="col-md-12">
        <label class="labelText">
          Strengths Resources Abilities Interests Barriers To Success
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="strengthsResourcesAbilitiesInterestsBarriersToSuccess"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentplanoverviewForm.controls[
                'strengthsResourcesAbilitiesInterestsBarriersToSuccess'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Transfer Discharge Criteria -->
      <div class="col-md-12">
        <label class="labelText">
          Transfer Discharge Criteria <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="transferDischargeCriteria"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentplanoverviewForm.controls['transferDischargeCriteria']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Unknown Field -->
      <div class="col-md-12">
        <label class="labelText">
          Unknown Field <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea formControlName="unknownField" matInput rows="3"></textarea>
          <mat-error
            *ngIf="treatmentplanoverviewForm.controls['unknownField'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--Clinician Comments Recommendations -->
      <div class="col-md-12">
        <label class="labelText">
          Clinician Comments Recommendations <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="clinicianCommentsRecommendations"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentplanoverviewForm.controls[
                'clinicianCommentsRecommendations'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="selectedPlan.status === 'Active'"
      >
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentplanoverviewForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
