import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ICaseTreatmentAdmissionLegal } from 'src/app/_models/case/treatment/admission/casetreatmentadmissionlegal.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CaseTreatmentAdmissionLegalApiService {
  constructor(private httpClient: HttpClient) {}

  getCaseTreatmentAdmissionLegalDetails(
    caseId: string
  ): Observable<ResponseModal> {
    let params = new HttpParams();
    if (caseId) params = params.append('caseId', caseId);
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/casetreatmentadmissionlegal`,
      { params: params }
    );
  }

  updateCaseTreatmentAdmissionLegal(
    thisCaseTreatmentAdmissionLegal: ICaseTreatmentAdmissionLegal
  ): Observable<ICaseTreatmentAdmissionLegal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ICaseTreatmentAdmissionLegal>(
        `${environment.apiUrl}/casetreatmentadmissionlegal`,
        thisCaseTreatmentAdmissionLegal,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
