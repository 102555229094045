<div class="col-md-12">
  <form
    [formGroup]="treatmentdischargesummaryForm"
    (ngSubmit)="onTreatmentDischargeSummaryFormSubmit()"
  >
    <div class="row">
      <!-- Presenting Problem  -->
      <div class="col-md-12">
        <label class="labelText">
          Presenting Problem <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Presenting Problem"
            formControlName="presentingProblem"
            name="presentingProblem"
            required
          />
          <mat-error
            *ngIf="
              treatmentdischargesummaryForm.controls['presentingProblem']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Strengths Abilities Needs Preferences -->
      <div class="col-md-12">
        <label class="labelText">
          Strengths Abilities Needs Preferences
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="strengthsAbilitiesNeedsPreferences"
            matInput
            rows="5"
          ></textarea>
          <mat-error
            *ngIf="
              treatmentdischargesummaryForm.controls[
                'strengthsAbilitiesNeedsPreferences'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentdischargesummaryForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
