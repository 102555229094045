import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataTablesModel, IMedication } from 'src/app/_models';
import {
  MedicationApiService,
  NDCMedicationService,
  ToastMessageService,
} from 'src/app/_services/';
import { MedicationFrequency } from 'src/app/shared/utilities/medicationFrequency';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-medication-dialog',
  templateUrl: './add-edit-medication-dialog.component.html',
  styleUrls: ['./add-edit-medication-dialog.component.css'],
})
export class AddEditMedicationDialogComponent implements OnInit {
  public organizationId: string;
  public patientId: string;
  public action: string;
  public sessionId: string;
  public medicationForm: FormGroup;

  public formErrors: Record<string, Record<string, string>> = {};
  medication: IMedication;
  medicationFrequency = MedicationFrequency;

  processing: boolean = false;
  isNDCMedicationLoading: boolean = false;
  ndcMedicationData: DataTablesModel = {} as DataTablesModel;

  constructor(
    public dialogRef: MatDialogRef<AddEditMedicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public medicationApiService: MedicationApiService,
    private formBuilder: FormBuilder,
    private ndcMedicationService: NDCMedicationService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.medication = data.medication;
    this.organizationId = data.patient.organizationId;
    this.patientId = data.patient.id;
    this.sessionId = data.sessionId;
  }

  public ngOnInit() {
    this.ndcMedicationData.per_page = 25;
    this.ndcMedicationData.page = 0;

    this.buildForm();

    if (this.action === 'EDIT') {
      this.medicationForm.controls['id'].setValue(this.medication.id);
      this.medicationForm.controls['organizationId'].setValue(
        this.medication.organizationId
      );
      this.medicationForm.controls['locationId'].setValue(
        this.medication.locationId
      );
      this.medicationForm.controls['patientId'].setValue(
        this.medication.patientId
      );
      this.medicationForm.controls['medicationName'].setValue(
        this.medication.medicationName
      );
      this.medicationForm.controls['dosage'].setValue(this.medication.dosage);

      if (this.medication.startDate) {
        this.medicationForm.controls['startDate'].setValue(
          mmddyyyyToDate(this.medication.startDate)
        );
      }

      if (this.medication.endDate) {
        this.medicationForm.controls['endDate'].setValue(
          mmddyyyyToDate(this.medication.endDate)
        );
      }
      this.medicationForm.controls['frequency'].setValue(
        this.medication.frequency
      );
      this.medicationForm.controls['time1'].setValue(this.medication.time1);
      this.medicationForm.controls['time2'].setValue(this.medication.time2);
      this.medicationForm.controls['time3'].setValue(this.medication.time3);
      this.medicationForm.controls['time4'].setValue(this.medication.time4);
      this.medicationForm.controls['time5'].setValue(this.medication.time5);
      this.medicationForm.controls['time6'].setValue(this.medication.time6);
      this.medicationForm.controls['time7'].setValue(this.medication.time7);
      this.medicationForm.controls['time8'].setValue(this.medication.time8);
      this.medicationForm.controls['time9'].setValue(this.medication.time9);
      this.medicationForm.controls['time10'].setValue(this.medication.time10);
      this.medicationForm.controls['time11'].setValue(this.medication.time11);
      this.medicationForm.controls['time12'].setValue(this.medication.time12);
      this.medicationForm.controls['specialInstructions'].setValue(
        this.medication.specialInstructions
      );

      this.medicationForm.controls['productId'].setValue(
        this.medication?.productId
      );
      this.medicationForm.controls['productNDC'].setValue(
        this.medication?.productNDC
      );
      this.medicationForm.controls['productTypeName'].setValue(
        this.medication?.productTypeName
      );
      this.medicationForm.controls['proprietaryName'].setValue(
        this.medication?.proprietaryName
      );
      this.medicationForm.controls['dosageFormName'].setValue(
        this.medication?.dosageFormName
      );
      this.medicationForm.controls['routeName'].setValue(
        this.medication?.routeName
      );
      this.medicationForm.controls['marketingCategoryName'].setValue(
        this.medication?.marketingCategoryName
      );
      this.medicationForm.controls['applicationNumber'].setValue(
        this.medication?.applicationNumber
      );
      this.medicationForm.controls['labelerName'].setValue(
        this.medication?.labelerName
      );
      this.medicationForm.controls['substanceName'].setValue(
        this.medication?.substanceName
      );
      this.medicationForm.controls['activeNumeratorStrength'].setValue(
        this.medication?.activeNumeratorStrength
      );
      this.medicationForm.controls['activeIngredUnit'].setValue(
        this.medication?.activeIngredUnit
      );
      this.medicationForm.controls['pharmClasses'].setValue(
        this.medication?.pharmClasses
      );
    } else {
      this.medicationForm.controls['organizationId'].setValue(
        this.organizationId
      );
      this.medicationForm.controls['patientId'].setValue(this.patientId);
    }
  }

  updateMedicationInfo(info) {
    this.medicationForm.controls['dosage'].setValue(
      info?.activeNumeratorStrength + ' ' + info?.activeIngredUnit
    );

    this.medicationForm.controls['productId'].setValue(info?.productId);
    this.medicationForm.controls['productNDC'].setValue(info?.productNDC);
    this.medicationForm.controls['productTypeName'].setValue(
      info?.productTypeName
    );
    this.medicationForm.controls['proprietaryName'].setValue(
      info?.proprietaryName
    );
    this.medicationForm.controls['dosageFormName'].setValue(
      info?.dosageFormName
    );
    this.medicationForm.controls['routeName'].setValue(info?.routeName);
    this.medicationForm.controls['marketingCategoryName'].setValue(
      info?.marketingCategoryName
    );
    this.medicationForm.controls['applicationNumber'].setValue(
      info?.applicationNumber
    );
    this.medicationForm.controls['labelerName'].setValue(info?.labelerName);
    this.medicationForm.controls['substanceName'].setValue(info?.substanceName);
    this.medicationForm.controls['activeNumeratorStrength'].setValue(
      info?.activeNumeratorStrength
    );
    this.medicationForm.controls['activeIngredUnit'].setValue(
      info?.activeIngredUnit
    );
    this.medicationForm.controls['pharmClasses'].setValue(info?.pharmClasses);
  }

  onMedicationFormSubmit() {
    if (this.medicationForm.invalid) {
      this.medicationForm.markAllAsTouched();
      return;
    }
    this.medication = Object.assign({}, this.medication);
    this.medication = Object.assign(this.medication, this.medicationForm.value);
    if (this.medication.startDate && this.medication.startDate !== '') {
      this.medication.startDate = formatDate(
        new Date(this.medication.startDate)
      );
    }
    if (this.medication.endDate && this.medication.endDate !== '') {
      this.medication.endDate = formatDate(new Date(this.medication.endDate));
    }

    this.processing = true;
    if (this.action === 'EDIT') {
      this.medicationApiService.updateMedication(this.medication).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Medication has been updated'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error occured editing the medication'
          );
        }
      );
    } else {
      this.medicationApiService.addMedication(this.medication).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Medication has been added'
          );
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error occured while adding the medication'
          );
        }
      );
    }
  }

  public validation_messages = {
    medicationName: [
      { type: 'required', message: 'Medication Name is required' },
      {
        type: 'minlength',
        message: 'Medication Name cannot be less than 2 characters long',
      },
    ],
    startDate: [{ type: 'required', message: 'startDate is required' }],
  };

  private buildForm() {
    this.medicationForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      locationId: new FormControl(''),
      patientId: new FormControl(''),
      sessionId: new FormControl(this.sessionId ? this.sessionId : null),
      medicationName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(2)])
      ),
      dosage: new FormControl(''),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      frequency: new FormControl(''),
      time1: new FormControl(''),
      time2: new FormControl(''),
      time3: new FormControl(''),
      time4: new FormControl(''),
      time5: new FormControl(''),
      time6: new FormControl(''),
      time7: new FormControl(''),
      time8: new FormControl(''),
      time9: new FormControl(''),
      time10: new FormControl(''),
      time11: new FormControl(''),
      time12: new FormControl(''),
      specialInstructions: new FormControl(''),

      productId: new FormControl(''),
      productNDC: new FormControl(''),
      productTypeName: new FormControl(''),
      proprietaryName: new FormControl(''),
      dosageFormName: new FormControl(''),
      routeName: new FormControl(''),
      marketingCategoryName: new FormControl(''),
      applicationNumber: new FormControl(''),
      labelerName: new FormControl(''),
      substanceName: new FormControl(''),
      activeNumeratorStrength: new FormControl(''),
      activeIngredUnit: new FormControl(''),
      pharmClasses: new FormControl(''),
    });

    this.medicationForm.controls['medicationName'].valueChanges.subscribe(
      () => {
        this.loadNDCMedications();
      }
    );
  }

  private loadNDCMedications() {
    this.isNDCMedicationLoading = true;

    this.ndcMedicationService
      .getMedicationInformation(
        this.medicationForm.controls['medicationName'].value,
        this.ndcMedicationData.per_page,
        this.ndcMedicationData.page
      )
      .subscribe(
        (response) => {
          this.isNDCMedicationLoading = false;
          if (response && response.items) {
            this.ndcMedicationData.items = response.items;
            this.ndcMedicationData.total = response.total;
          } else {
            this.ndcMedicationData.items = [];
            this.ndcMedicationData.total = 0;
          }
        },
        (error) => {
          this.isNDCMedicationLoading = false;
        }
      );
  }

  isFrequencyLessThanOrEqualTo(intValue: number) {
    let frequency = this.medicationForm.controls['frequency'].value;

    let numb: any = '1';
    if (
      frequency &&
      frequency.indexOf('Once') < 0 &&
      frequency.indexOf('Every') < 0
    ) {
      numb = frequency.match(/\d/g);
      numb = numb != null ? numb.join('') : '0';
    }
    return intValue <= parseInt(numb);
  }

  onFrequencyChange() {
    let frequencySelected = this.medicationForm.get('frequency').value;
    let numb: any = '1';
    if (frequencySelected && frequencySelected.indexOf('Once') < 0) {
      numb = frequencySelected.match(/\d/g);
      numb = numb != null ? numb.join('') : '0';
    }

    // let frequencyNumber = parseInt(numb);
    // for (let i = 2; i <= 12; i++) {
    //   if (i <= frequencyNumber) {
    //     this.medicationForm
    //       .get('time' + i)
    //       .setValidators([Validators.required]);
    //     this.medicationForm.get('time' + i).updateValueAndValidity();
    //   } else {
    //     this.medicationForm.get('time' + i).clearValidators();
    //     this.medicationForm.get('time' + i).setValue('');
    //     this.medicationForm.get('time' + i).updateValueAndValidity();
    //   }
    // }
  }

  timeSlotValues = [
    '12:00 AM',
    '12:15 AM',
    '12:30 AM',
    '12:45 AM',

    '1:00 AM',
    '1:15 AM',
    '1:30 AM',
    '1:45 AM',

    '2:00 AM',
    '2:15 AM',
    '2:30 AM',
    '2:45 AM',

    '3:00 AM',
    '3:15 AM',
    '3:30 AM',
    '3:45 AM',

    '4:00 AM',
    '4:15 AM',
    '4:30 AM',
    '4:45 AM',

    '5:00 AM',
    '5:15 AM',
    '5:30 AM',
    '5:45 AM',

    '6:00 AM',
    '6:15 AM',
    '6:30 AM',
    '6:45 AM',

    '7:00 AM',
    '7:15 AM',
    '7:30 AM',
    '7:45 AM',

    '8:00 AM',
    '8:15 AM',
    '8:30 AM',
    '8:45 AM',

    '9:00 AM',
    '9:15 AM',
    '9:30 AM',
    '9:45 AM',

    '10:00 AM',
    '10:15 AM',
    '10:30 AM',
    '10:45 AM',

    '11:00 AM',
    '11:15 AM',
    '11:30 AM',
    '11:45 AM',

    '12:00 PM',
    '12:15 PM',
    '12:30 PM',
    '12:45 PM',

    '1:00 PM',
    '1:15 PM',
    '1:30 PM',
    '1:45 PM',

    '2:00 PM',
    '2:15 PM',
    '2:30 PM',
    '2:45 PM',

    '3:00 PM',
    '3:15 PM',
    '3:30 PM',
    '3:45 PM',

    '4:00 PM',
    '4:15 PM',
    '4:30 PM',
    '4:45 PM',

    '5:00 PM',
    '5:15 PM',
    '5:30 PM',
    '5:45 PM',

    '6:00 PM',
    '6:15 PM',
    '6:30 PM',
    '6:45 PM',

    '7:00 PM',
    '7:15 PM',
    '7:30 PM',
    '7:45 PM',

    '8:00 PM',
    '8:15 PM',
    '8:30 PM',
    '8:45 PM',

    '9:00 PM',
    '9:15 PM',
    '9:30 PM',
    '9:45 PM',

    '10:00 PM',
    '10:15 PM',
    '10:30 PM',
    '10:45 PM',

    '11:00 PM',
    '11:15 PM',
    '11:30 PM',
    '11:45 PM',
  ];
}
