import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IGamblingAddiction } from 'src/app/_models/assessments/addiction/gamblingaddiction.model';
import {
  GamblingAddictionApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-gambling-addiction',
  templateUrl: './gambling-addiction.component.html',
  styleUrls: ['./gambling-addiction.component.css'],
})
export class GamblingAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public gamblingAddictionApiService: GamblingAddictionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public gamblingAddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  gamblingAddictionAssessment: IGamblingAddiction;
  gamblingAddictionAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;
  showQ2: boolean = true;
  showQ4: boolean = true;
  showQ678: boolean = true;
  showQ8: boolean = true;
  showQ9: boolean = true;
  showQ10: boolean = true;
  showQ11: boolean = true;
  showQ12: boolean = true;
  showQ13: boolean = true;
  showQ14: boolean = true;
  showQ15: boolean = true;
  showQ16: boolean = true;
  showQ17: boolean = true;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.gamblingAddictionForm, this.errors);
    this.loadGamblingAddictionAssessment();
  }

  loadGamblingAddictionAssessment() {
    this.gamblingAddictionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.gamblingAddictionApiService
        .getGamblingAddictionDetails(this.assessmentId)
        .subscribe((gamblingAddictionAssessment) => {
          if (gamblingAddictionAssessment.data) {
            this.gamblingAddictionForm.controls['q1'].setValue(
              gamblingAddictionAssessment.data.q1.answer
            );
            this.gamblingAddictionForm.controls['q2'].setValue(
              gamblingAddictionAssessment.data.q2.answer
            );
            this.gamblingAddictionForm.controls['q3'].setValue(
              gamblingAddictionAssessment.data.q3.answer
            );
            this.gamblingAddictionForm.controls['q4'].setValue(
              gamblingAddictionAssessment.data.q4.answer
            );
            this.gamblingAddictionForm.controls['q5'].setValue(
              gamblingAddictionAssessment.data.q5.answer
            );
            this.gamblingAddictionForm.controls['q6'].setValue(
              gamblingAddictionAssessment.data.q6.answer
            );
            this.gamblingAddictionForm.controls['q7'].setValue(
              gamblingAddictionAssessment.data.q7.answer
            );
            this.gamblingAddictionForm.controls['q8'].setValue(
              gamblingAddictionAssessment.data.q8.answer
            );
            this.gamblingAddictionForm.controls['q9'].setValue(
              gamblingAddictionAssessment.data.q9.answer
            );
            this.gamblingAddictionForm.controls['q10'].setValue(
              gamblingAddictionAssessment.data.q10.answer
            );
            this.gamblingAddictionForm.controls['q11'].setValue(
              gamblingAddictionAssessment.data.q11.answer
            );
            this.gamblingAddictionForm.controls['q12'].setValue(
              gamblingAddictionAssessment.data.q12.answer
            );
            this.gamblingAddictionForm.controls['q13'].setValue(
              gamblingAddictionAssessment.data.q13.answer
            );
            this.gamblingAddictionForm.controls['q14'].setValue(
              gamblingAddictionAssessment.data.q14.answer
            );
            this.gamblingAddictionForm.controls['q15'].setValue(
              gamblingAddictionAssessment.data.q15.answer
            );
            this.gamblingAddictionForm.controls['q16'].setValue(
              gamblingAddictionAssessment.data.q16.answer
            );
            this.gamblingAddictionForm.controls['q17'].setValue(
              gamblingAddictionAssessment.data.q17.answer
            );

            this.askFurtherQuestions();

            this.riskScore = gamblingAddictionAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.gamblingAddictionForm.invalid) {
      this.gamblingAddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.gamblingAddictionAssessment = Object.assign(
      {},
      this.gamblingAddictionAssessment
    );
    this.gamblingAddictionAssessment.patientId =
      this.gamblingAddictionForm.controls['patientId'].value;
    this.gamblingAddictionAssessment.sessionId =
      this.gamblingAddictionForm.controls['sessionId'].value;
    this.gamblingAddictionAssessment.assessmentRequestId =
      this.gamblingAddictionForm.controls['assessmentRequestId'].value;

    this.gamblingAddictionAssessment.q1 = {
      answer: this.gamblingAddictionForm.controls['q1'].value,
    };
    this.gamblingAddictionAssessment.q2 = {
      answer: this.gamblingAddictionForm.controls['q2'].value,
    };
    this.gamblingAddictionAssessment.q3 = {
      answer: this.gamblingAddictionForm.controls['q3'].value,
    };
    this.gamblingAddictionAssessment.q4 = {
      answer: this.gamblingAddictionForm.controls['q4'].value,
    };
    this.gamblingAddictionAssessment.q5 = {
      answer: this.gamblingAddictionForm.controls['q5'].value,
    };
    this.gamblingAddictionAssessment.q6 = {
      answer: this.gamblingAddictionForm.controls['q6'].value,
    };
    this.gamblingAddictionAssessment.q7 = {
      answer: this.gamblingAddictionForm.controls['q7'].value,
    };
    this.gamblingAddictionAssessment.q8 = {
      answer: this.gamblingAddictionForm.controls['q8'].value,
    };
    this.gamblingAddictionAssessment.q9 = {
      answer: this.gamblingAddictionForm.controls['q9'].value,
    };
    this.gamblingAddictionAssessment.q10 = {
      answer: this.gamblingAddictionForm.controls['q10'].value,
    };
    this.gamblingAddictionAssessment.q11 = {
      answer: this.gamblingAddictionForm.controls['q11'].value,
    };
    this.gamblingAddictionAssessment.q12 = {
      answer: this.gamblingAddictionForm.controls['q12'].value,
    };
    this.gamblingAddictionAssessment.q13 = {
      answer: this.gamblingAddictionForm.controls['q13'].value,
    };
    this.gamblingAddictionAssessment.q14 = {
      answer: this.gamblingAddictionForm.controls['q14'].value,
    };
    this.gamblingAddictionAssessment.q15 = {
      answer: this.gamblingAddictionForm.controls['q15'].value,
    };
    this.gamblingAddictionAssessment.q16 = {
      answer: this.gamblingAddictionForm.controls['q16'].value,
    };
    this.gamblingAddictionAssessment.q17 = {
      answer: this.gamblingAddictionForm.controls['q17'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.gamblingAddictionAssessment.accessCode = this.accessCode;

      this.gamblingAddictionApiService
        .addGuestGamblingAddiction(this.gamblingAddictionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Gambling Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Gambling Addiction Assessment'
            );
          }
        );
    } else {
      this.gamblingAddictionApiService
        .addGamblingAddiction(this.gamblingAddictionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Gambling Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Gambling Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Have there ever been periods lasting 2 weeks or longer when you spent a lot of time thinking about your gambling experiences, or planning out future gambling ventures or bets? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Have there ever been periods lasting two weeks or longer when you spend a lot of time thinking about ways of getting money to gamble with? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. Have you ever lied to family members, friends, or others about how much you gamble or how much money you lost on gambling? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message: '4. If so, had this happened three or more times? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Have you ever tried to stop, cut down, or control your gambling? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. On one or more of the times when you tried to stop, cut down, or control your gambling, were you restless or irritable? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. Have you ever tried but not succeeded in stopping, cutting down, or controlling your gambling? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. Has this happened three or more times? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. Have there ever been periods when you needed to gamble with increasing amounts of money or with larger bets than before in order to get the same feeling of excitement? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Have you ever gambled to relieve uncomfortable feelings such as guilt, anxiety, helplessness, or depression? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. Have you ever gambled as a way to escape from personal problems? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. Has there ever been a period when, if you lost money gambling one day, you would often return another day to get even? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Have you ever written a bad check or taken money that didn’t belong to you from family members or anyone else in order to pay for your gambling? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. Has your gambling ever caused serious or repeated problems in your relationships with any of your family members or friends? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. Has your gambling caused you any problems in school, such as missing classes or days of school, or your grades dropping? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. Has your gambling ever caused you to lose a job, have trouble with your job, or miss out on an important job or career opportunity? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. Have you ever needed to ask family members or anyone else to loan you money or otherwise bail you out of a desperate money situation that was largely caused by your gambling? is required',
      },
    ],
  };

  private buildForm() {
    this.gamblingAddictionForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    this.askFurtherQuestions();
    if (!this.gamblingAddictionForm) {
      return;
    }
    const form = this.gamblingAddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public askFurtherQuestions() {
    let q1answer = this.gamblingAddictionForm.controls['q1'].value;
    if ('Yes' === q1answer) {
      this.showQ2 = false;
      this.gamblingAddictionForm.controls['q2'].setValue('');
      this.gamblingAddictionForm.get('q2').clearValidators();
      this.gamblingAddictionForm.get('q2').updateValueAndValidity();
    } else {
      this.showQ2 = true;
      this.gamblingAddictionForm.get('q2').setValidators([Validators.required]);
    }

    let q3answer = this.gamblingAddictionForm.controls['q3'].value;
    if ('No' === q3answer) {
      this.showQ4 = false;
      this.gamblingAddictionForm.controls['q4'].setValue('');
      this.gamblingAddictionForm.get('q4').clearValidators();
      this.gamblingAddictionForm.get('q4').updateValueAndValidity();
    } else {
      this.showQ4 = true;
      this.gamblingAddictionForm.get('q4').setValidators([Validators.required]);
    }

    let q5answer = this.gamblingAddictionForm.controls['q5'].value;
    if ('No' === q5answer) {
      this.showQ678 = false;
      this.gamblingAddictionForm.controls['q6'].setValue('');
      this.gamblingAddictionForm.get('q6').clearValidators();
      this.gamblingAddictionForm.controls['q7'].setValue('');
      this.gamblingAddictionForm.get('q7').clearValidators();
      this.gamblingAddictionForm.controls['q8'].setValue('');
      this.gamblingAddictionForm.get('q8').clearValidators();
      this.gamblingAddictionForm.get('q6').updateValueAndValidity();
      this.gamblingAddictionForm.get('q7').updateValueAndValidity();
      this.gamblingAddictionForm.get('q8').updateValueAndValidity();
      if ('Yes' === q1answer || 'Yes' === q3answer) {
        this.showQ9 = true;
        this.showQ10 = true;
        this.showQ11 = true;
        this.showQ12 = true;
        this.showQ13 = true;
        this.showQ14 = true;
        this.showQ15 = true;
        this.showQ16 = true;
        this.showQ17 = true;
        this.gamblingAddictionForm
          .get('q9')
          .setValidators([Validators.required]);
        this.gamblingAddictionForm
          .get('q10')
          .setValidators([Validators.required]);
        this.gamblingAddictionForm
          .get('q12')
          .setValidators([Validators.required]);
        this.gamblingAddictionForm
          .get('q13')
          .setValidators([Validators.required]);
        this.gamblingAddictionForm
          .get('q14')
          .setValidators([Validators.required]);
        this.gamblingAddictionForm
          .get('q17')
          .setValidators([Validators.required]);
        this.setq8();
        this.setq11();
        this.setq14();
      } else {
        this.showQ9 = false;
        this.showQ10 = false;
        this.showQ11 = false;
        this.showQ12 = false;
        this.showQ13 = false;
        this.showQ14 = false;
        this.showQ15 = false;
        this.showQ16 = false;
        this.showQ17 = false;
        this.gamblingAddictionForm.get('q9').clearValidators();
        this.gamblingAddictionForm.get('q10').clearValidators();
        this.gamblingAddictionForm.get('q11').clearValidators();
        this.gamblingAddictionForm.get('q12').clearValidators();
        this.gamblingAddictionForm.get('q13').clearValidators();
        this.gamblingAddictionForm.get('q14').clearValidators();
        this.gamblingAddictionForm.get('q15').clearValidators();
        this.gamblingAddictionForm.get('q16').clearValidators();
        this.gamblingAddictionForm.get('q17').clearValidators();
        this.gamblingAddictionForm.controls['q9'].setValue('');
        this.gamblingAddictionForm.controls['q10'].setValue('');
        this.gamblingAddictionForm.controls['q11'].setValue('');
        this.gamblingAddictionForm.controls['q12'].setValue('');
        this.gamblingAddictionForm.controls['q13'].setValue('');
        this.gamblingAddictionForm.controls['q14'].setValue('');
        this.gamblingAddictionForm.controls['q15'].setValue('');
        this.gamblingAddictionForm.controls['q16'].setValue('');
        this.gamblingAddictionForm.controls['q17'].setValue('');
        this.gamblingAddictionForm.get('q9').updateValueAndValidity();
        this.gamblingAddictionForm.get('q10').updateValueAndValidity();
        this.gamblingAddictionForm.get('q11').updateValueAndValidity();
        this.gamblingAddictionForm.get('q12').updateValueAndValidity();
        this.gamblingAddictionForm.get('q13').updateValueAndValidity();
        this.gamblingAddictionForm.get('q14').updateValueAndValidity();
        this.gamblingAddictionForm.get('q15').updateValueAndValidity();
        this.gamblingAddictionForm.get('q16').updateValueAndValidity();
        this.gamblingAddictionForm.get('q17').updateValueAndValidity();
      }
    } else {
      this.showQ9 = true;
      this.showQ10 = true;
      this.showQ11 = true;
      this.showQ12 = true;
      this.showQ13 = true;
      this.showQ14 = true;
      this.showQ15 = true;
      this.showQ16 = true;
      this.showQ17 = true;

      this.gamblingAddictionForm.get('q6').setValidators([Validators.required]);
      this.gamblingAddictionForm.get('q7').setValidators([Validators.required]);
      this.gamblingAddictionForm.get('q9').setValidators([Validators.required]);
      this.gamblingAddictionForm
        .get('q10')
        .setValidators([Validators.required]);
      this.gamblingAddictionForm
        .get('q12')
        .setValidators([Validators.required]);
      this.gamblingAddictionForm
        .get('q13')
        .setValidators([Validators.required]);
      this.gamblingAddictionForm
        .get('q14')
        .setValidators([Validators.required]);
      this.gamblingAddictionForm
        .get('q17')
        .setValidators([Validators.required]);
      this.showQ678 = true;

      this.setq8();
      this.setq11();
      this.setq14();
    }
  }

  setq8() {
    let q7answer = this.gamblingAddictionForm.controls['q7'].value;
    if ('No' === q7answer) {
      this.showQ8 = false;
      this.gamblingAddictionForm.controls['q8'].setValue('');
      this.gamblingAddictionForm.get('q8').clearValidators();
    } else {
      this.showQ8 = true;
      this.gamblingAddictionForm.get('q8').setValidators([Validators.required]);
    }
  }

  setq11() {
    let q10answer = this.gamblingAddictionForm.controls['q10'].value;
    if ('Yes' === q10answer) {
      this.showQ11 = false;
      this.gamblingAddictionForm.controls['q11'].setValue('');
      this.gamblingAddictionForm.get('q11').clearValidators();
    } else {
      this.showQ11 = true;
      this.gamblingAddictionForm
        .get('q11')
        .setValidators([Validators.required]);
    }
    this.gamblingAddictionForm.get('q11').updateValueAndValidity();
  }

  setq14() {
    let q14answer = this.gamblingAddictionForm.controls['q14'].value;
    if ('Yes' === q14answer) {
      this.showQ15 = false;
      this.gamblingAddictionForm.controls['q15'].setValue('');
      this.gamblingAddictionForm.get('q15').clearValidators();
      this.showQ16 = false;
      this.gamblingAddictionForm.controls['q16'].setValue('');
      this.gamblingAddictionForm.get('q16').clearValidators();
      this.gamblingAddictionForm.get('q15').updateValueAndValidity();
      this.gamblingAddictionForm.get('q16').updateValueAndValidity();
    } else {
      this.showQ15 = true;
      this.gamblingAddictionForm
        .get('q15')
        .setValidators([Validators.required]);
      let q15answer = this.gamblingAddictionForm.controls['q15'].value;
      if ('Yes' === q15answer) {
        this.showQ16 = false;
        this.gamblingAddictionForm.controls['q16'].setValue('');
        this.gamblingAddictionForm.get('q16').clearValidators();
        this.gamblingAddictionForm.get('q16').updateValueAndValidity();
      } else {
        this.showQ16 = true;
        this.gamblingAddictionForm
          .get('q16')
          .setValidators([Validators.required]);
      }
      this.gamblingAddictionForm.get('q15').updateValueAndValidity();
      this.gamblingAddictionForm.get('q16').updateValueAndValidity();
    }
  }
}
