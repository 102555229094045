import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Case, ITherapySession, Patient } from 'src/app/_models';
import { IMemberGoal } from 'src/app/_models/assessments/patientassessmentgoal.model';
import { ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import {
  GoalInterventionStatus,
  GoalObjectiveStatus,
  GoalProblemStatus,
  GoalStatus,
} from 'src/app/shared/utilities/goals/goalsStatusConstants';

@Component({
  selector: 'app-add-edit-member-goal',
  templateUrl: './add-edit-member-goal.component.html',
  styleUrls: ['./add-edit-member-goal.component.css'],
})
export class AddEditMemberGoalComponent implements OnInit {
  public memberGoalForm: FormGroup;

  // Get constants from the shared repository
  goalsProblemList = GoalProblemStatus;
  goalStatusList = GoalStatus;
  goalObjectiveStatusList = GoalObjectiveStatus;
  goalInterventionStatusList = GoalInterventionStatus;

  patient: Patient;
  case: Case;
  therapySession: ITherapySession;
  patientId: string = '';
  patientName: string = '';
  targetId: string;

  goal: any;
  action: string = '';
  loggedInUserId: string = '';
  organizationId: string = '';

  todaysDate: Date = new Date();
  memberGoal: IMemberGoal;

  processing: boolean = false;
  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  frequencyList = ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly'];

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditMemberGoalComponent>,
    private formBuilder: FormBuilder,
    private goalsApiService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.action = data.action;
      this.patient = data.patient;
      this.case = data.case;
      this.therapySession = data.therapySession;
      this.providersList = data.providersList;
      this.filteredProvidersList = data.providersList;

      if (data.action === 'EDIT') {
        this.goal = data.goal;
      } else if (data.action === 'ADD') {
        this.loggedInUserId = data.loggedInUserId;
        this.patientId = data.patientId;
        this.patientName = data.patientName;
        this.organizationId = data.organizationId;
        this.targetId = data.targetId;
      }
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.memberGoalForm.controls['id'].setValue(this.goal.id);

      this.memberGoalForm.controls['organizationId'].setValue(
        this.goal?.organizationId
      );
      this.memberGoalForm.controls['patientId'].setValue(this.goal?.patientId);
      this.memberGoalForm.controls['patientName'].setValue(
        this.goal?.patientName
      );
      this.memberGoalForm.controls['careProviderId'].setValue(
        this.goal.careProviderId
      );
      this.memberGoalForm.controls['sessionId'].setValue(this.goal?.sessionId);
      this.memberGoalForm.controls['sessionCode'].setValue(
        this.goal?.sessionCode
      );
      this.memberGoalForm.controls['requestorId'].setValue(
        this.goal?.requestorId
      );
      this.memberGoalForm.controls['requestorName'].setValue(
        this.goal?.requestorName
      );
      this.memberGoalForm.controls['status'].setValue(this.goal?.status);
      this.memberGoalForm.controls['description'].setValue(
        this.goal.description
      );

      if (this.goal?.targetId) {
        this.memberGoalForm.controls['targetId'].setValue(this.goal?.targetId);
      }

      for (const objective of this.goal.objectives) {
        const objIndex = this.createObjective(
          objective.objectiveDescription,
          objective?.targetDate ? objective.targetDate : null,
          objective?.status
        );
        for (const intervention of objective.interventions) {
          const preSelectedStaff = this.providersList.filter(
            (provider) => intervention.staffId === provider.id
          );

          this.getInterventions(objIndex).push(
            new FormGroup({
              name: new FormControl(intervention.name, Validators.required),
              frequencyNumber: new FormControl(
                intervention?.frequencyNumber,
                Validators.required
              ),
              frequencyInterval: new FormControl(
                intervention?.frequencyInterval,
                Validators.required
              ),
              staffId: new FormControl(
                intervention.staffId,
                Validators.required
              ),
              staffName: new FormControl(
                intervention.staffName,
                Validators.required
              ),
              status: new FormControl(
                intervention?.status,
                Validators.required
              ),
            })
          );
        }
      }

      if (this.goal.projectedAchievedDate) {
        this.memberGoalForm.controls['projectedAchievedDate'].setValue(
          new Date(this.goal.projectedAchievedDate)
        );
      }

      if (this.goal.actualAchievedDate) {
        this.memberGoalForm.controls['actualAchievedDate'].setValue(
          new Date(this.goal.actualAchievedDate)
        );
      }

      this.memberGoalForm.markAllAsTouched();
    } else {
      this.createObjective('', null, 'In Progress');
      this.memberGoalForm.controls['careProviderId'].setValue(
        this.loggedInUserId
      );
      this.memberGoalForm.controls['organizationId'].setValue(
        this.organizationId
      );
      this.memberGoalForm.controls['requestorId'].setValue(this.loggedInUserId);
    }
  }

  buildForm() {
    this.memberGoalForm = this.formBuilder.group({
      id: new FormControl(null),

      organizationId: new FormControl(null),
      caseId: new FormControl(this.case?.id ? this.case.id : null),
      caseCode: new FormControl(this.case?.caseNumber),

      patientId: new FormControl(this.patientId),
      patientName: new FormControl(this.patientName),
      patientFirstName: new FormControl(this.patient.firstName),
      patientMiddleName: new FormControl(this.patient.middleName),
      patientLastName: new FormControl(this.patient.lastName),

      targetId: new FormControl(this.targetId ? this.targetId : null),

      careProviderId: new FormControl(null),
      sessionId: new FormControl(
        this.therapySession?.id ? this.therapySession.id : null
      ),
      sessionCode: new FormControl(''),
      requestorId: new FormControl(null),
      requestorName: new FormControl(''),

      description: new FormControl(
        { value: '', disabled: this.patient.status != 'Active' },
        Validators.compose([Validators.required])
      ),

      objectives: this.formBuilder.array([]),

      status: new FormControl('Active', Validators.required),
      projectedAchievedDate: new FormControl(null),
      actualAchievedDate: new FormControl(null),
    });
  }

  objectives(): FormArray {
    return this.memberGoalForm.get('objectives') as FormArray;
  }

  createObjective(
    objectiveDesc: string,
    targetDate: Date,
    status: string
  ): number {
    const objective = new FormGroup({
      objectiveDescription: new FormControl(
        { value: objectiveDesc, disabled: this.patient.status !== 'Active' },
        Validators.required
      ),
      targetDate: new FormControl(targetDate, Validators.required),
      status: new FormControl(status, Validators.required),
      interventions: this.formBuilder.array([]),
    });
    this.objectives().push(objective);
    return this.objectives().length - 1;
  }

  removeObjective(index: number) {
    let currentObjectiveSize = this.objectives().value.length;
    if (currentObjectiveSize == 1) {
      return;
    }
    this.objectives().removeAt(index);
  }

  updateGoal() {
    this.memberGoal = Object.assign({}, this.memberGoal);
    this.memberGoal = Object.assign(this.memberGoal, this.memberGoalForm.value);

    this.processing = true;

    if (this.action === 'EDIT') {
      this.goalsApiService.updateMemberGoal(this.memberGoal).subscribe({
        next: (response) => {
          this.processing = false;

          this.toastMessageService.displaySuccessMessage(
            'Goal has been updated'
          );
          this.dialogRef.close('success');
        },
        error: () => {
          this.processing = false;

          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the goal'
          );
        },
      });
    } else {
      this.goalsApiService.addMemberGoal(this.memberGoal).subscribe({
        next: (response) => {
          this.processing = false;

          this.toastMessageService.displaySuccessMessage('Goal has been added');
          this.dialogRef.close('success');
        },
        error: () => {
          this.processing = false;

          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add the goal'
          );
        },
      });
    }
  }

  getInterventions(index: number): FormArray {
    return (this.objectives().at(index) as FormGroup).get(
      'interventions'
    ) as FormArray;
  }

  addIntervention(objectiveIndex: number) {
    const intervention = new FormGroup({
      name: new FormControl('', Validators.required),
      staffId: new FormControl(null, Validators.required),
      staffName: new FormControl('', Validators.required),
      status: new FormControl('In Progress', Validators.required),

      frequencyNumber: new FormControl('', Validators.required),
      frequencyInterval: new FormControl('Day', Validators.required),
    });
    this.getInterventions(objectiveIndex).push(intervention);
  }

  removeIntervention(objectiveIndex: number, interventionIndex: number) {
    this.getInterventions(objectiveIndex).removeAt(interventionIndex);
  }

  providerSelected(
    event: any,
    objectiveIndex: number,
    interventionIndex: number
  ): void {
    const providerId = event.value;
    const intervention = this.getInterventions(objectiveIndex).at(
      interventionIndex
    ) as FormGroup;

    let providerInformation = this.providersList
      .filter((provider) => provider.id === providerId)
      .at(0);

    intervention.controls['staffName'].setValue(
      providerInformation.firstName + ' ' + providerInformation.lastName
    );
  }

  // Search Filter for supervising provider
  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }
}
