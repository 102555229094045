import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { CoreService, ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddAssessmentGoalDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-assessment-goals',
  templateUrl: './assessment-goals.component.html',
  styleUrls: ['./assessment-goals.component.css'],
})
export class AssessmentGoalsComponent implements OnInit {
  selectedAssessmentType: string;

  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;
  action: string = '';
  error = '';
  displayBreadCrumbs = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public assessmentGoalApiService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.displayBreadCrumbs =
      !this.coreService.isSuperOrgAdmin() && !this.coreService.isOrgAdmin();
    this.selectedAssessmentType = this.route.snapshot.paramMap.get('id');
    this.data.per_page = 10;
    this.data.page = 0;
    this.loadAssessmentGoals();
  }

  loadAssessmentGoals() {
    this.isLoadingResults = true;

    this.assessmentGoalApiService
      .getAssessmentGoals(
        this.selectedAssessmentType,
        this.data.per_page,
        this.data.page,
        null
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        () => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve goals.'
          );
        }
      );
  }

  addGoal() {
    this.action = 'ADD';
    const dialogRef = this.dialog.open(AddAssessmentGoalDialogComponent, {
      data: {
        action: this.action,
        selectedAssessmentType: this.selectedAssessmentType,
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAssessmentGoals();
      }
    });
  }

  editGoal(goal: IAssessmentGoal) {
    this.action = 'EDIT';
    const dialogRef = this.dialog.open(AddAssessmentGoalDialogComponent, {
      data: {
        action: this.action,
        selectedAssessmentType: this.selectedAssessmentType,
        goalDetails: goal,
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAssessmentGoals();
      }
    });
  }

  deleteGoal(goal: IAssessmentGoal) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this goal?',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;
        this.assessmentGoalApiService.deleteAssessmentGoal(goal).subscribe(
          (response) => {
            this.isLoadingResults = false;
            this.toastMessageService.displaySuccessMessage(
              'Goal has been deleted'
            );
            // Handling delete pagination
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }
            this.loadAssessmentGoals();
          },
          (error) => {
            this.isLoadingResults = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again'
            );
          }
        );
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadAssessmentGoals();
  }
}
