import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentProgramApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentProgramsList(caseId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('caseId', caseId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/treatmentprogramslist`, { params: params });
    }

    getTreatmentProgramsDetails(treatmentprogramsId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/treatmentprograms/` + treatmentprogramsId);
    }

    addTreatmentPrograms(thisTreatmentProgram: ITreatmentProgram): Observable<ITreatmentProgram> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<ITreatmentProgram>(`${environment.apiUrl}/treatmentprograms`, thisTreatmentProgram, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateTreatmentPrograms(thisTreatmentProgram: ITreatmentProgram): Observable<ITreatmentProgram> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentProgram>(`${environment.apiUrl}/treatmentprograms`, thisTreatmentProgram, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    deleteTreatmentPrograms(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.apiUrl}/treatmentprograms/` + id).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}