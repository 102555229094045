import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import { IReferral } from 'src/app/_models/referrals/referral.model';
import {
  DateTimeZoneService,
  PatientService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services';
import { ReferralService } from 'src/app/_services/referrals/referral.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { ReferralStatus } from 'src/app/shared/utilities/referrals/referralStatus';
import { AddAttemptDialogComponent } from '../add-attempt-dialog/add-attempt-dialog.component';
import { AddEditReferralComponent } from '../add-edit-referral/add-edit-referral.component';
import { ReferralEligibilityTestComponent } from '../referral-eligibility-test/referral-eligibility-test.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './referral-home.component.html',
  styleUrl: './referral-home.component.css',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReferralHomeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Search list
  searchStartDate: Date;
  searchEndDate: Date;
  searchMemberName: string = '';
  searchMemberPhone = '';
  searchMemberDOB: any = '';
  searchProviderName: string = '';
  searchStatus: string = '';

  // GLobal Imports
  allStatus = ReferralStatus;

  // My assigned facilities
  myAssignedFacilities = [];
  data: DataTablesModel = {} as DataTablesModel;

  // Loading Referral Data
  processing: boolean = false;
  apiError: boolean = false;
  careProvidersLoading: boolean = false;
  attemptProcessing: boolean = false;
  allCareProviders: any[];

  displayedColumns = [
    'member',
    'memberInsurance',
    'facility',
    'referral',
    'referredBy',
    'appointment',
    'attempts',
    'status',
    'actions',
  ];
  currentTimezone: string;
  expandedElement: any;

  constructor(
    private referralService: ReferralService,
    private facilitiesSandbox: FacilitiesSandbox,
    public dialog: MatDialog,
    private toastMessageService: ToastMessageService,
    private userApiService: UserApiService,
    private patientAPIService: PatientService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    this.loadFacilities();
    this.loadReferralsList();
    this.loadAllActiveCareProviders();
  }

  // Get all referrals
  loadReferralsList() {
    this.processing = true;
    this.apiError = false;

    this.referralService
      .getReferrals(
        this.searchMemberName,
        this.searchMemberPhone,
        this.searchProviderName,
        this.searchStartDate,
        this.searchEndDate,
        this.searchMemberDOB,
        this.searchStatus,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load referrals'
          );
          this.processing = false;
          this.apiError = true;
        },
      });
  }

  // Get all my assigned facilities from sandbox
  public loadFacilities() {
    this.myAssignedFacilities = [];

    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myAssignedFacilities = response;
      } else {
        this.myAssignedFacilities = [];
      }
    });
  }

  // Get all referrals
  loadAllActiveCareProviders() {
    this.careProvidersLoading = true;

    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.allCareProviders = response.items;
        } else {
          this.allCareProviders = [];
        }
        this.careProvidersLoading = false;
      },
      error: (error) => {
        this.careProvidersLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  // Filter Status
  filterStatusChanged(newStatus) {
    this.searchStatus = newStatus;
    this.searchChanged();
  }

  // Clear Date
  clearDate() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.searchChanged();
  }

  // Search new results
  searchChanged() {
    this.data.page = 0;
    this.loadReferralsList();
  }

  // Add New Referral
  addReferral() {
    let dialogRef = this.dialog.open(AddEditReferralComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
      data: {
        action: 'ADD',
        facilities: this.myAssignedFacilities,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadReferralsList();
      }
    });
  }

  // Edit Existing Referral
  editReferral(referral) {
    let dialogRef = this.dialog.open(AddEditReferralComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
      data: {
        action: 'EDIT',
        facilities: this.myAssignedFacilities,
        referral: referral,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadReferralsList();
      }
    });
  }

  // Add in new attempt
  addAttempt(referral: IReferral) {
    let currentAttemptCount = this.getAttempCount(referral, 'number');
    let attempt =
      currentAttemptCount == 0
        ? 'First'
        : currentAttemptCount == 1
        ? 'Second'
        : 'Third';

    let dialogRef = this.dialog.open(AddAttemptDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        attemptCount: attempt,
        allCareProviders: this.allCareProviders,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        let attemptDetails = response.attemptDetails;
        this.attemptProcessing = true;

        this.referralService
          .addReferralAttempt(referral.id, attempt, attemptDetails)
          .subscribe({
            next: (response) => {
              this.attemptProcessing = false;

              // Update the attempt details here
              if (currentAttemptCount == 0) {
                referral.firstAttempt = attemptDetails;
              } else if (currentAttemptCount == 1) {
                referral.secondAttempt = attemptDetails;
              } else if (currentAttemptCount == 2) {
                referral.thirdAttempt = attemptDetails;
              }

              // Update the attempt details on main object
              this.data.items.map((item) => {
                if (item.id === referral.id) {
                  item = response.data;
                }
              });
            },
            error: (error) => {
              this.attemptProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to add the attempt details'
              );
            },
          });
      }
    });
  }

  editAttempt(attempt: string, referral: IReferral, attempInformation) {
    let dialogRef = this.dialog.open(AddAttemptDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        attemptCount: attempt,
        allCareProviders: this.allCareProviders,
        attemptDetails: attempInformation,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        let attemptDetails = response.attemptDetails;
        this.attemptProcessing = true;

        this.referralService
          .addReferralAttempt(referral.id, attempt, attemptDetails)
          .subscribe({
            next: (response) => {
              this.attemptProcessing = false;

              // Update the attempt details here
              if (attempt === 'First') {
                referral.firstAttempt = attemptDetails;
              } else if (attempt === 'Second') {
                referral.secondAttempt = attemptDetails;
              } else if (attempt === 'Third') {
                referral.thirdAttempt = attemptDetails;
              }

              // Update the attempt details on main object
              this.data.items.map((item) => {
                if (item.id === referral.id) {
                  item = response.data;
                }
              });
            },
            error: (error) => {
              this.attemptProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to update the attempt details'
              );
            },
          });
      }
    });
  }

  // Delete Referral Attempt
  public removeAttempt(attempt: string, referral: IReferral) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this attempt record?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.attemptProcessing = true;
        this.referralService
          .removeReferralAttempt(referral.id, attempt)
          .subscribe({
            next: (response) => {
              this.attemptProcessing = false;
              referral.firstAttempt = response.data.firstAttempt;
              referral.secondAttempt = response.data.secondAttempt;
              referral.thirdAttempt = response.data.thirdAttempt;

              this.data.items.map((item) => {
                if (item.id === referral.id) {
                  item = response.data;
                }
              });
            },
            error: () => {
              this.attemptProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong while removing the attempt'
              );
            },
          });
      }
    });
  }

  // Get next pagination
  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadReferralsList();
  }

  // Get Attempt Count
  getAttempCount(row, type) {
    let attempCount = 0;
    if (row?.firstAttempt) {
      attempCount++;
    }
    if (row?.secondAttempt) {
      attempCount++;
    }
    if (row?.thirdAttempt) {
      attempCount++;
    }
    if (type === 'number') {
      return attempCount;
    } else {
      if (attempCount == 0) {
        return 0;
      } else if (attempCount == 1) {
        return 35;
      } else if (attempCount == 2) {
        return 70;
      } else if (attempCount == 3) {
        return 100;
      }
    }
  }

  // Delete Referral
  public deleteReferral(referral) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this referral?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.referralService.deleteReferral(referral.id).subscribe({
          next: () => {
            this.toastMessageService.displaySuccessMessage(
              'Referral has been deleted'
            );
            this.loadReferralsList();
          },
          error: () => {
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong while deleting the referral'
            );
          },
        });
      }
    });
  }

  openEligibilityCheck(referral: IReferral) {
    // If the insurance is not pre-selected ask the user to add in insurance
    if (!referral.patientInsurancePayorId) {
      this.toastMessageService.displayWarnMessage(
        'Please add in the patient insurance to run the eligibility check'
      );
      this.editReferral(referral);
    } else {
      // ******************************** //
      // Open the eligibility screen **** //
      // ******************************** //
      this.dialog.open(ReferralEligibilityTestComponent, {
        minWidth: '45vw',
        maxHeight: '80vh',
        disableClose: true,
        autoFocus: false,
        data: {
          referralInformation: referral,
        },
      });
    }
  }

  importMember(referral: IReferral) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Please Confirm',
        msg: 'Are you sure you want to import this member?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '20vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.processing = true;
        this.patientAPIService.addReferralPatient(referral).subscribe({
          next: (response) => {
            let patient = response?.data;
            referral.mindwisePatientId = patient?.id;

            this.referralService.markMemberAsImported(referral).subscribe({
              next: (response) => {
                this.processing = false;
                this.toastMessageService.displaySuccessMessage(
                  'Member has been successfully imported'
                );
                this.loadReferralsList();
              },
              error: (error) => {
                this.processing = false;
                this.toastMessageService.displayErrorMessage(
                  'Error: Failed to mark the member as imported'
                );
              },
            });
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to create referral member'
            );
          },
        });
      }
    });
  }
}
