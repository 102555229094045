import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentPlansApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentPlansList(caseId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('caseId', caseId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/treatmentplanslist`, { params: params });
    }

    getTreatmentPlanDetails(treatmentplansId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/treatmentplans/` + treatmentplansId);
    }

    addTreatmentPlan(thisTreatmentPlan: ITreatmentPlan): Observable<ITreatmentPlan> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<ITreatmentPlan>(`${environment.apiUrl}/treatmentplans`, thisTreatmentPlan, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateTreatmentPlan(thisTreatmentPlan: ITreatmentPlan): Observable<ITreatmentPlan> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentPlan>(`${environment.apiUrl}/treatmentplans`, thisTreatmentPlan, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    printReport(caseId: string, planId: string): any {
        return this.httpClient
            .get(
                `${environment.apiUrl}/reports/case/treatment-plan?caseId=${caseId}&planId=${planId}`,
                { responseType: 'blob' }
            )
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }
}