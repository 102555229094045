import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentDischargeSummary } from 'src/app/_models/case/treatment/discharge/treatmentdischargesummary.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentDischargeSummaryApiService } from 'src/app/_services/case/treatment/discharge/treatmentdischargesummary.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-discharge-summary',
  templateUrl: './discharge-summary.component.html',
  styleUrl: './discharge-summary.component.css',
})
export class DischargeSummaryComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() recordType: string;
  @Input() selectedProgram: ITreatmentProgram;

  public action: string;
  public treatmentdischargesummaryForm: FormGroup;
  treatmentdischargesummary: ITreatmentDischargeSummary;
  processing: boolean = false;

  constructor(
    public treatmentdischargesummaryApiService: TreatmentDischargeSummaryApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();

    this.loadTreatmentDischargeSummary();
  }

  loadTreatmentDischargeSummary() {
    this.treatmentdischargesummaryApiService
      .getTreatmentDischargeSummaryDetails(
        this.case.id,
        this.recordType,
        this.selectedProgram?.id
      )
      .subscribe((treatmentdischargesummary) => {
        if (treatmentdischargesummary?.data) {
          this.treatmentdischargesummaryForm.controls['id'].setValue(
            treatmentdischargesummary.data.id
          );
          this.treatmentdischargesummaryForm.controls[
            'organizationId'
          ].setValue(treatmentdischargesummary.data.organizationId);
          this.treatmentdischargesummaryForm.controls['patientId'].setValue(
            treatmentdischargesummary.data.patientId
          );
          this.treatmentdischargesummaryForm.controls['caseId'].setValue(
            treatmentdischargesummary.data.caseId
          );
          this.treatmentdischargesummaryForm.controls['programId'].setValue(
            treatmentdischargesummary.data.programId
          );
          this.treatmentdischargesummaryForm.controls[
            'presentingProblem'
          ].setValue(treatmentdischargesummary.data.presentingProblem);
          this.treatmentdischargesummaryForm.controls[
            'strengthsAbilitiesNeedsPreferences'
          ].setValue(
            treatmentdischargesummary.data.strengthsAbilitiesNeedsPreferences
          );
        }
      });
  }

  onTreatmentDischargeSummaryFormSubmit() {
    if (this.treatmentdischargesummaryForm.invalid) {
      this.treatmentdischargesummaryForm.markAllAsTouched();
      return;
    }
    this.treatmentdischargesummary = Object.assign(
      {},
      this.treatmentdischargesummary
    );
    this.treatmentdischargesummary = Object.assign(
      this.treatmentdischargesummary,
      this.treatmentdischargesummaryForm.value
    );

    this.processing = true;
    this.treatmentdischargesummaryApiService
      .updateTreatmentDischargeSummary(this.treatmentdischargesummary)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentdischargesummaryForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      programId: new FormControl(
        this.selectedProgram?.id,
        Validators.compose([Validators.required])
      ),
      recordType: new FormControl(this.recordType, Validators.required),
      presentingProblem: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      strengthsAbilitiesNeedsPreferences: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });
  }
}
