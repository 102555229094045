<form
  [formGroup]="casetreatmentadmissionlegalForm"
  novalidate
  (ngSubmit)="onCaseTreatmentAdmissionLegalFormSubmit()"
>
  <div class="row">
    <div class="col-md-6">
      <label class="labelText">
        Legal Status <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="legalStatus">
          <mat-option
            *ngFor="let option of legalStatusOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls['legalStatus'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelText">
        # of Arrests in Past 30 Days <span style="color: red">*</span></label
      >
      <mat-form-field appearance="outline">
        <input
          type="number"
          matInput
          formControlName="numberOfArrestsInPast30Days"
          placeholder="Enter number"
          min="0"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls[
              'numberOfArrestsInPast30Days'
            ].invalid
          "
        >
          Must be >= 0
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelText"># of Arrests in Lifetime</label>
      <mat-form-field appearance="outline">
        <input
          type="number"
          matInput
          min="0"
          formControlName="numberOfArrestsInLifetime"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls[
              'numberOfArrestsInLifetime'
            ].invalid
          "
        >
          Must be >= 0
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelText"># of Arrests in Past 12 Months</label>
      <mat-form-field appearance="outline">
        <input
          type="number"
          matInput
          formControlName="numberOfArrestsInPast12Months"
          min="0"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls[
              'numberOfArrestsInPast12Months'
            ].invalid
          "
        >
          Must be >= 0
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelText">
        Have you ever been convicted of DUI? <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="convictedOfDui"
          (selectionChange)="convictedChanged($event)"
        >
          <mat-option *ngFor="let option of convictionOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls['convictedOfDui'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-6"
      *ngIf="
        casetreatmentadmissionlegalForm.get('convictedOfDui').value === 'Yes'
      "
    >
      <label class="labelText">
        Is this conviction current? <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="isConvictionCurrent">
          <mat-option *ngFor="let option of convictionOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls['isConvictionCurrent']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-6"
      *ngIf="
        casetreatmentadmissionlegalForm.get('convictedOfDui').value === 'Yes'
      "
    >
      <label class="labelText">
        Level of Conviction <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="levelOfConviction">
          <mat-option
            *ngFor="let option of levelOfConvictionOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionlegalForm.controls['levelOfConviction']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-6"
      *ngIf="
        casetreatmentadmissionlegalForm.get('convictedOfDui').value === 'Yes'
      "
    >
      <label class="labelText">
        County Name <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="countyName" placeholder="County">
          <mat-option *ngFor="let county of counties" [value]="county">
            {{ county }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="casetreatmentadmissionlegalForm.controls['countyName'].invalid"
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-6"
      *ngIf="
        casetreatmentadmissionlegalForm.get('convictedOfDui').value === 'Yes'
      "
    >
      <label class="labelText">
        Judge Name <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          formControlName="judgeName"
          placeholder="Enter judge name"
        />
        <mat-error
          *ngIf="casetreatmentadmissionlegalForm.controls['judgeName'].invalid"
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12" style="text-align: right" *ngIf="!readOnly">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!casetreatmentadmissionlegalForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
