import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { CaseApiService, DocumentHttpService } from 'src/app/_services';

@Component({
  selector: 'app-case-header',
  templateUrl: './case-header.component.html',
  styleUrls: ['./case-header.component.css'],
})
export class CaseHeaderComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() reload: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription = new Subscription();

  @Output() output = new EventEmitter<any>();

  data: DataTablesModel = {} as DataTablesModel;
  hasDischargeSummaryDocuments = false;

  constructor(
    public caseApiService: CaseApiService,
    public documentApiService: DocumentHttpService
  ) {}

  ngOnInit() {
    this.data.per_page = 10;
    this.data.page = 0;

    this.hasDischargeDocuments(this.data.per_page, this.data.page);

    const reloadSubscription = this.reload.subscribe((response) => {
      if (response) {
        this.hasDischargeDocuments(this.data.per_page, this.data.page);
      }
    });
    this.subscriptions.add(reloadSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public hasDischargeDocuments(pageSize: number, pageIndex: number) {
    this.hasDischargeSummaryDocuments = false;
    this.documentApiService
      .getDischargeDocuments(
        this.case.id,
        this.case.patientId,
        pageSize,
        pageIndex
      )
      .subscribe((response) => {
        if (response && response.items) {
          this.hasDischargeSummaryDocuments = true;
          let payload: any = {};
          payload.eventType = 'HAS_DISCHARGE_DOCUMENTS';
          this.output.emit(payload);
        }
      });
  }

  public triggerEvent(tabName: string, documentType: string) {
    let payload: any = {};
    payload.tabName = tabName;
    payload.documentType = documentType;
    payload.eventType = 'DISCHARGE_DOCUMENT_CLICKED';
    this.output.emit(payload);
  }

  getLOS() {
    if (this.case.id) {
      const startDate = new Date(this.case.admittanceDate);
      const today = new Date();

      startDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      const timeDifference = today.getTime() - startDate.getTime();
      const daysDifference = Math.ceil(
        Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
      );

      if (this.case.status === 'active') {
        return daysDifference;
      } else {
        if (!this.case.closeDate) {
          return null;
        }
        const closeDate = new Date(this.case.closeDate);
        closeDate.setHours(0, 0, 0, 0);
        const timeDifference2 = closeDate.getTime() - startDate.getTime();
        const daysDifference2 = Math.ceil(
          Math.abs(Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)))
        );
        return daysDifference2;
      }
    }
  }
}
