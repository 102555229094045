<div class="dialogTitle" style="padding-left: 10px; padding-right: 10px">
  <p matDialogTitle>Print Assessment</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div
  class="col-md-12"
  *ngIf="isLoadingResults"
  style="display: flex; justify-content: center"
>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>
</div>

<div class="col-md-12" *ngIf="displayNoRecordsMsg">
  <mat-error style="text-align: center"
    >Unable to find the report for the selected assessment
  </mat-error>
</div>

<div style="height: 900px; width: 100%">
  <ng2-pdfjs-viewer [download]="false" #pdfViewerOnDemand></ng2-pdfjs-viewer>
</div>
