import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { TreatmentPlansApiService } from 'src/app/_services/case/treatment/plan/treatmentplan.service';
import { AddEditTreatmentPlansDialogComponent } from './dialog/add-edit-treatment-plans-dialog/add-edit-treatment-plans-dialog.component';
import { ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-care-plans',
  templateUrl: './care-plans.component.html',
  styleUrl: './care-plans.component.css',
})
export class CarePlansComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() displayAddButton: boolean = true;
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() title: string;
  @Output() selectedPlan = new EventEmitter<any>();

  displayedColumns = [
    'planName',
    'planNumber',
    'versionNumber',
    'startDate',
    'endDate',
    'status',
    'actions',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;
  hasOneActivePlan = false;
  patientId: string;

  constructor(
    public dialog: MatDialog,
    public treatmentplansApiService: TreatmentPlansApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadTreatmentPlansList();
  }

  addTreatmentPlans() {
    if (this.hasOneActivePlan) {
      this.toastMessageService.displayWarnMessage(
        'Only one plan can be active at a time. To create a new plan, ensure all existing plans are set to inactive.'
      );
      return;
    }

    const dialogRef = this.dialog.open(AddEditTreatmentPlansDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        treatmentplans: null,
        case: this.case,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentPlansList();
      }
    });
  }

  public editTreatmentPlans(treatmentplans: ITreatmentPlan) {
    const dialogRef = this.dialog.open(AddEditTreatmentPlansDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        treatmentplans,
        case: this.case,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentPlansList();
      }
    });
  }

  public loadTreatmentPlansList() {
    this.isLoadingResults = true;
    this.apiError = false;
    this.hasOneActivePlan = false;

    this.treatmentplansApiService
      .getTreatmentPlansList(this.case.id, this.data.per_page, this.data.page)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
            // Check if atleast 1 plan is active
            response.items.forEach((item) => {
              if (item.status === 'Active') {
                this.hasOneActivePlan = true;
              }
            });
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTreatmentPlansList();
  }

  setSelectedPlan(plan: any) {
    this.selectedPlan.emit(plan);
  }
}
