import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentProgramApiService } from 'src/app/_services/case/treatment/discharge/treatmentprogram.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditTreatmentProgramsDialogComponent } from './dialog/add-edit-treatment-discharge-programs-dialog/add-edit-treatment-discharge-programs-dialog.component';

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html',
  styleUrl: './discharge.component.css',
})
export class DischargeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() case: Case;
  @Input() showAddButton: boolean;
  @Input() patient: Patient;
  @Output() selectedProgram = new EventEmitter<any>();

  displayedColumns = [
    'programName',
    'facilityName',
    'programStaff',
    'daysOnWaitList',
    'startDate',
    'endDate',
    'terminationReason',
    'notes',
    'actions',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;
  patientId: string;

  constructor(
    public dialog: MatDialog,
    public treatmentprogramApiService: TreatmentProgramApiService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadTreatmentProgramsList();
  }

  addTreatmentProgram() {
    const dialogRef = this.dialog.open(
      AddEditTreatmentProgramsDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'ADD',
          treatmentprogram: null,
          case: this.case,
          patient: this.patient,
        },
      }
    );
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentProgramsList();
      }
    });
  }

  public editTreatmentProgram(treatmentprogram: ITreatmentProgram) {
    const dialogRef = this.dialog.open(
      AddEditTreatmentProgramsDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'EDIT',
          treatmentprogram,
          case: this.case,
          patient: this.patient,
        },
      }
    );
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentProgramsList();
      }
    });
  }

  deleteTreatmentProgram(treatmentprogram: ITreatmentProgram) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this record?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.treatmentprogramApiService
          .deleteTreatmentPrograms(treatmentprogram.id)
          .subscribe(() => {
            this.loadTreatmentProgramsList();
          });
      }
    });
  }

  public loadTreatmentProgramsList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.treatmentprogramApiService
      .getTreatmentProgramsList(
        this.case.id,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTreatmentProgramsList();
  }

  setSelectedProgram(program: any) {
    this.selectedProgram.emit(program);
  }
}
