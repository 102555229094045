import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { CaseTreatmentAdmissionSubstanceApiService } from 'src/app/_services/case/treatment/admission/casetreatmentadmissionsubstance.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-case-admission-substance',
  templateUrl: './case-admission-substance.component.html',
  styleUrl: './case-admission-substance.component.css',
})
export class CaseAdmissionSubstanceComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() recordType: string;
  @Input() selectedProgram: ITreatmentProgram;
  @Input() readOnly: boolean = false;

  public casetreatmentadmissionsubstanceForm: FormGroup;
  public processing: boolean = false;

  public substanceCodeOptions = [
    'None',
    'K2',
    'Alcohol',
    'Cocaine',
    'Marijuana',
    'Heroin',
    'Non-Prescription Methadone',
    'Other Opiates and Synthetics',
    'PCP',
    'Hallucinogens',
    'Methamphetamine',
    'Amphetamines',
    'Other stimulants',
    'Benzodiazepine',
    'Tranquilizers',
    'Barbiturates',
    'Other Sedatives or Hypnotics',
    'Inhalants',
    'Over-the-counter',
    'Other Drug Not Otherwise Specified',
    'Drug Unknown',
    'Tobacco',
    'Binge Drinking',
    'Prescription Drugs',
    'School Based Liaison',
    'Lifeline',
  ];

  public severityCodeOptions = [
    'Mild Problem/Dysfunction',
    'Moderate Problem/Dysfnc',
    'N/A',
    'Not a Problem',
    'Severe Problem/Dysfnc',
  ];

  public frequencyCodeOptions = [
    'No Secondary or Tertiary Drug Applicable',
    'No use in the past month',
    '1-3 times in past month',
    '1-2 times in past week',
    '3-6 times in past week',
    'Daily',
    'Unknown',
  ];

  public intakeTypeCodeOptions = [
    'N/A',
    'Oral',
    'Smoking',
    'Inhalation',
    'Injection',
    'Other',
    'No Secondary or Tertiary Drug Applicable',
    'Unknown',
  ];

  public tobaccoUseOptions = [
    'Cigarettes',
    'Combo/more than 1',
    'No Tobacco Use',
    'Cigars or Pipes',
    'Smokeless Tobacco',
  ];

  public smokerStatusOptions = [
    'Current every day smoker',
    'Current some day smoker',
    'Smoker, current status unknown',
    'Former Smoker',
    'Heavy tobacco smoker',
    'Light tobacco smoker',
  ];

  constructor(
    public casetreatmentadmissionsubstanceApiService: CaseTreatmentAdmissionSubstanceApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.buildForm();

    this.loadCaseTreatmentAdmissionSubstance();
  }

  buildForm() {
    this.casetreatmentadmissionsubstanceForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      recordType: new FormControl(this.recordType, Validators.required),
      programId: new FormControl(
        this.selectedProgram?.id,
        this.selectedProgram ? Validators.required : null
      ),

      primarySubstance: new FormControl('', Validators.required),
      primarySubstanceSeverity: new FormControl('', Validators.required),
      primarySubstanceFrequency: new FormControl('', Validators.required),
      primarySubstanceMethod: new FormControl('', Validators.required),
      primarySubstanceDetailedDrugCode: new FormControl(
        '',
        Validators.required
      ),
      primaryAgeFirstUsed: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(97),
      ]),
      primaryDaysSinceLastUse: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),

      secondarySubstance: new FormControl(''),
      secondarySubstanceSeverity: new FormControl(''),
      secondarySubstanceFrequency: new FormControl(''),
      secondarySubstanceMethod: new FormControl(''),
      secondarySubstanceDetailedDrugCode: new FormControl(''),
      secondaryAgeFirstUsed: new FormControl('', [
        Validators.min(0),
        Validators.max(97),
      ]),
      secondaryDaysSinceLastUse: new FormControl('', [Validators.min(0)]),

      tertiarySubstance: new FormControl(''),
      tertiarySubstanceSeverity: new FormControl(''),
      tertiarySubstanceFrequency: new FormControl(''),
      tertiarySubstanceMethod: new FormControl(''),
      tertiarySubstanceDetailedDrugCode: new FormControl(''),
      tertiaryAgeFirstUsed: new FormControl('', [
        Validators.min(0),
        Validators.max(97),
      ]),
      tertiaryDaysSinceLastUse: new FormControl('', [Validators.min(0)]),

      tobaccoUse: new FormControl('', Validators.required),
      smokerStatus: new FormControl('', Validators.required),
      daysAbstinent: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(30),
      ]),
      daysInSupportGroup: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(30),
      ]),
      daysInMeetings: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(30),
      ]),
      daysWaitedToEnterTreatment: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),
    });
  }

  loadCaseTreatmentAdmissionSubstance() {
    this.casetreatmentadmissionsubstanceApiService
      .getCaseTreatmentAdmissionSubstanceDetails(
        this.case.id,
        this.recordType,
        this.selectedProgram?.id
      )
      .subscribe((response) => {
        if (response && response.data) {
          Object.keys(response.data).forEach((key) => {
            if (this.casetreatmentadmissionsubstanceForm.controls[key]) {
              this.casetreatmentadmissionsubstanceForm.controls[key].setValue(
                response.data[key]
              );

              if (this.readOnly) {
                this.casetreatmentadmissionsubstanceForm.controls[
                  key
                ].disable();
              }
            }
          });
        }
      });
  }

  onCaseTreatmentAdmissionSubstanceFormSubmit() {
    if (this.casetreatmentadmissionsubstanceForm.invalid) {
      this.casetreatmentadmissionsubstanceForm.markAllAsTouched();
      return;
    }

    const formData = { ...this.casetreatmentadmissionsubstanceForm.value };
    this.processing = true;

    this.casetreatmentadmissionsubstanceApiService
      .updateCaseTreatmentAdmissionSubstance(formData)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }
}
