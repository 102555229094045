<div style="height: 95vh; width: 95vw; text-align: right">
  <button mat-icon-button color="warn" type="button" (click)="closeVideo()">
    <mat-icon>highlight_off</mat-icon>
  </button>

  <iframe
    [src]="hostUrl"
    [title]="iframeMeeting"
    allow="camera;microphone;display-capture"
    style="width: 100%; height: 95%"
  ></iframe>
</div>
