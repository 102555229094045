import { Component, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CareLog,
  CareLogLane,
  Case,
  Countries,
  LaneStatus,
  MOCK_LANES,
  Patient,
} from 'src/app/_models';
import { CareLogHttpService, NoteHttpService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import {
  mmddyyyyStringFromDate,
  printableTimePeriod,
} from 'src/app/shared/utilities/utilities';
import { CarelogDialogSelectorComponent } from './carelog-dialogs/carelog-dialog-selector/carelog-dialog-selector.component';

@Component({
  selector: 'app-carelog',
  templateUrl: './carelog.component.html',
  styleUrls: ['./carelog.component.css'],
})
export class CarelogComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() goals: any[];
  @Input() memberGoals: any[];
  @Input() hasCaseLogViewAccess: boolean;
  @Input() hasCaseLogAddAccess: boolean;
  @Input() hasCaseLogEditAccess: boolean;
  @Input() hasCaseLogDeleteAccess: boolean;

  availableNoteLane: CareLogLane;
  maxDate: Date;
  currentDate: Date;
  appliedNotes: CareLogLane;
  notes: CareLog[] = [];

  loadingResults: boolean = false;

  availableNotes: any[];

  public formErrors: Record<string, Record<string, string>> = {};

  displayShifts: boolean = false;

  totalShifts: number = 3;
  public shifts = [];
  public currentShift: any;

  constructor(
    public careLogHttpService: CareLogHttpService,
    public notesApiService: NoteHttpService,
    public dialog: MatDialog,
    private _dateAdapter: DateAdapter<any>,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit() {
    this.maxDate = new Date();
    this.currentDate = new Date();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfigResponse) => {
          let orgConfig = orgConfigResponse;
          if (orgConfig?.organizationCountry === Countries.India) {
            this._dateAdapter.setLocale('en-GB');
          }
          this.totalShifts = orgConfig.totalShiftsPerDay;
          if (this.totalShifts < 1) {
            this.shifts = [];
            this.displayShifts = false;
          } else {
            this.displayShifts = true;
          }

          if (this.totalShifts >= 1) {
            this.shifts.push({
              name: orgConfig.firstShiftName,
              time: printableTimePeriod(
                orgConfig.firstShiftStartTime,
                orgConfig.firstShiftEndTime
              ),
              selected: true,
              value: 1,
            });
            this.currentShift = this.shifts[0];
          }
          if (this.totalShifts >= 2) {
            this.shifts.push({
              name: orgConfig.secondShiftName,
              time: printableTimePeriod(
                orgConfig.secondShiftStartTime,
                orgConfig.secondShiftEndTime
              ),
              selected: false,
              value: 2,
            });
          }
          if (this.totalShifts >= 3) {
            this.shifts.push({
              name: orgConfig.thirdShiftName,
              time: printableTimePeriod(
                orgConfig.thirdShiftStartTime,
                orgConfig.thirdShiftEndTime
              ),
              selected: false,
              value: 3,
            });
          }
        });
      }
    });

    // Load Notes
    this.loadShiftNotes(this.case.id, this.case.patientId, '1');
  }

  public setLanes() {
    let availableNoteLane = {
      id: LaneStatus.AVAILABLE,
      title: 'Available Notes',
      notes: [],
    };

    for (let i = 0; i < this.availableNotes?.length; i++) {
      let availableNote = this.availableNotes[i];
      let note: any = {};
      note.message = availableNote.text;
      note.logNumber = availableNote.id;
      note.goals = availableNote.goals;
      note.additionalInput = availableNote.additionalInput;
      availableNoteLane.notes.push(note);
    }

    this.availableNoteLane = availableNoteLane;
    this.appliedNotes = MOCK_LANES[1];
  }

  public loadLanes() {
    this.careLogHttpService
      .getCareLogs(
        this.case.id,
        mmddyyyyStringFromDate(this.currentDate),
        this.currentShift.value
      )
      .subscribe((notes) => {
        this.loadingResults = false;
        if (notes && notes.items) {
          this.notes = notes.items;
          this.appliedNotes.notes = this.notes;
          for (var i = 0; i < this.notes.length; i++) {
            var currentNote = this.notes[i];
            this.availableNoteLane.notes = this.availableNoteLane.notes.filter(
              function (value) {
                return value.logNumber !== currentNote.logNumber;
              }
            );
          }
        } else {
          this.appliedNotes = {
            id: LaneStatus.APPLIED,
            title: 'Applied Notes',
            notes: [],
          };
        }
      });
  }

  addCustomLog() {
    const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
      data: {
        case: this.case,
        logDate: this.currentDate,
        shift: this.currentShift.value,
        patientId: this.patient.id,
        memberGoals: this.memberGoals,
        action: 'ADD',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadLanes();
      }
    });
  }

  reloadLanes() {
    this.loadingResults = true;
    this.setLanes();
    this.loadLanes();
  }

  hasChanges(hasChangeEvent: boolean) {
    this.reloadLanes();
  }

  selectedDate(selectedDate) {
    this.currentDate = new Date(selectedDate);
    this.reloadLanes();
  }

  previousDate() {
    let actualdate = this.currentDate;
    actualdate.setDate(actualdate.getDate() - 1);
    this.currentDate = new Date(actualdate);
    this.reloadLanes();
  }

  nextDate() {
    let actualdate = this.currentDate;
    if (
      new Date(actualdate).setHours(0, 0, 0, 0) >=
      new Date().setHours(0, 0, 0, 0)
    ) {
      alert('Date can not be greater than today');
      return;
    } else {
      actualdate.setDate(actualdate.getDate() + 1);
      this.currentDate = new Date(actualdate);
      this.reloadLanes();
    }
  }

  loadShiftNotes(caseId: string, patientId: string, shift: string) {
    this.notesApiService
      .getShiftNotes(caseId, patientId, shift)
      .subscribe((response) => {
        if (response && response.items) {
          this.availableNotes = response.items;
        }
        this.reloadLanes();
      });
  }
}
