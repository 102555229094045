<div
  class="row"
  style="
    margin: 0px;
    padding: 20px 10px;
    background-color: #eeeeee;
    row-gap: 15px;
  "
>
  <div class="col-md-8">
    <mat-chip-listbox #chipList *ngIf="displayShifts">
      <mat-chip-option
        *ngFor="let shift of shifts"
        [selected]="shift.value === currentShift.value"
        (click)="
          shift.selected = !shift.selected;
          currentShift = shift;
          loadShiftNotes(case.id, case.patientId, shift.value)
        "
      >
        <div>{{ shift.name }}<br />{{ shift.time }}</div>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <div class="col-md-4">
    <div class="row">
      <div class="col-2" style="text-align: right">
        <button
          type="button"
          mat-mini-fab
          style="background: #ffffff; margin-top: 8px; color: #555555"
          (click)="previousDate()"
        >
          <i class="material-icons text_align-center">chevron_left</i>
        </button>
      </div>
      <div class="col-8">
        <mat-form-field appearance="fill">
          <input
            matInput
            #ref
            [matDatepicker]="picker"
            matInput
            id="currentDate"
            [max]="maxDate"
            [(ngModel)]="currentDate"
            [readonly]="true"
            (dateChange)="selectedDate(ref.value)"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-2" style="text-align: left">
        <button
          type="button"
          mat-mini-fab
          style="background: #ffffff; margin-top: 8px; color: #555555"
          (click)="nextDate()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-card
  class="mat-elevation-z8"
  appearance="raised"
  style="margin-bottom: 50px"
>
  <div
    class="row"
    *ngIf="
      hasCaseLogAddAccess &&
      patient.status === 'Active' &&
      case.status === 'active'
    "
  >
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addCustomLog()"
      >
        + Add Care log
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loadingResults"
  ></mat-progress-bar>

  <div class="col-md-12" *ngIf="!hasCaseLogViewAccess">
    <mat-error> Sorry, You don't have access to view Care Log Info </mat-error>
  </div>

  <div
    class="row"
    *ngIf="!loadingResults && availableNoteLane && hasCaseLogViewAccess"
    style="margin-bottom: 50px"
  >
    <!-- To-Do List -->
    <div *ngIf="case.status === 'active'" class="col-md-4 col-sm-12 mt-3">
      <app-carelog-available-list
        class="case-notes-list mat-elevation-z8"
        [(lane)]="availableNoteLane"
        [logDate]="currentDate"
        [shift]="currentShift.value"
        [showGoal]="false"
        [case]="case"
        [patient]="patient"
        [hasCaseLogViewAccess]="hasCaseLogViewAccess"
        [hasCaseLogAddAccess]="hasCaseLogAddAccess"
        [hasCaseLogEditAccess]="hasCaseLogEditAccess"
        [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
        style="width: 100%; background-color: #f0f0f0; min-height: 100%"
        (hasChanges)="hasChanges($event)"
      >
      </app-carelog-available-list>
    </div>
    <!-- Done List -->
    <div
      [class]="
        case.status === 'active' ? 'col-md-8 col-sm-12 mt-3' : 'col-md-12 mt-3'
      "
    >
      <app-carelog-added-list
        class="case-notes-list mat-elevation-z8"
        [(lane)]="appliedNotes"
        [logDate]="currentDate"
        [shift]="currentShift.value"
        [showGoal]="true"
        [goals]="goals"
        [memberGoals]="memberGoals"
        [case]="case"
        [patient]="patient"
        [hasCaseLogViewAccess]="hasCaseLogViewAccess"
        [hasCaseLogAddAccess]="hasCaseLogAddAccess"
        [hasCaseLogEditAccess]="hasCaseLogEditAccess"
        [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
        (hasChanges)="hasChanges($event)"
        style="width: 100%; background-color: #c1d1c9; min-height: 100%"
      >
      </app-carelog-added-list>
    </div>
  </div>
</mat-card>
