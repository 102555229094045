<div class="row">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      > </a
    >&nbsp; / &nbsp;
    <span> <a>Schedules</a>&nbsp; / &nbsp; </span>
    Reschedule Appointment
  </p>
</div>

<!-- Loading Form Groups -->
<form [formGroup]="therapysessionForm" *ngIf="!isLoadingAppointmentDetails">
  <!-- Multiple Mat Stepper for Different Screens -->
  <mat-stepper linear #stepper style="background-color: transparent">
    <!-- Step 1 - Calendar Details -->
    <mat-step
      label="Calendar"
      state="calendaricon"
      [stepControl]="therapysessionForm.controls['title']"
    >
      <div class="row">
        <!-- Headers -->
        <!-- Calendar Components -->
        <div
          class="col-md-3"
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
            max-height: 100vh;
            padding-bottom: 25px;
            overflow-y: auto;
          "
        >
          <!-- Left Calendar Components -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <!-- Calendar Type -->
            <label class="labelHeader calendarTypeContainer">
              Calendar Type
            </label>
            <div class="calendarTypeContainer" style="margin-bottom: 10px">
              <mat-button-toggle-group
                [(ngModel)]="calendarViewType"
                [ngModelOptions]="{ standalone: true }"
                (change)="calendarTypeChanged($event)"
              >
                <mat-button-toggle value="week"> Week </mat-button-toggle>
                <mat-button-toggle value="day"> Day </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <!-- Calendar Time Zone -->
            <label class="labelHeader">Time Zone</label>
            <mat-form-field appearance="outline">
              <mat-select
                [(ngModel)]="selectedCalendarTimeZone"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="timeZoneChanged()"
              >
                <mat-option
                  *ngFor="let zone of calendarTimeZones"
                  [value]="zone.id"
                >
                  {{ zone.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Calendar Increments -->
            <label class="labelHeader">Calendar Increment</label>
            <mat-form-field appearance="outline">
              <mat-select
                [(ngModel)]="timeCellStep"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="timeCellStepChanged()"
              >
                <mat-option
                  *ngFor="let cellstep of timeCellSteps"
                  [value]="cellstep"
                >
                  {{ cellstep }} minutes
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Calendar Days Range -->
            <label class="labelHeader">Calendar Days Range</label>
            <mat-slider
              min="0"
              max="6"
              discrete
              step="1"
              color="primary"
              [displayWith]="formatDaySliderLabel"
            >
              <input
                [value]="calendarStartDay"
                [(ngModel)]="calendarStartDay"
                [ngModelOptions]="{ standalone: true }"
                matSliderStartThumb
                (valueChange)="sliderValueChanged()"
              />
              <input
                [value]="calendarEndDay"
                [(ngModel)]="calendarEndDay"
                [ngModelOptions]="{ standalone: true }"
                matSliderEndThumb
                (valueChange)="sliderValueChanged()"
              />
            </mat-slider>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader">
              Providers ({{ filteredAllResources?.length }})
            </label>
            <!-- Search Box -->
            <div
              style="
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 10px;
                border: 1px solid rgb(0, 0, 0, 0.2);
                background-color: #ffffff;
                padding: 5px;
              "
            >
              <span
                class="material-icons"
                style="text-align: left; font-size: 20px; opacity: 0.5"
              >
                search
              </span>
              <input
                type="search"
                placeholder="Provider Name"
                [(ngModel)]="searchProviderName"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="searchByProviderNameChanged()"
                style="
                  padding-left: 15px;
                  width: 100%;
                  background: transparent;
                  border: 0;
                "
              />
            </div>

            <!-- Quick Actions Box -->
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 15px;
              "
            >
              <button
                mat-button
                color="primary"
                type="button"
                (click)="selectAllProviders()"
              >
                Select All
              </button>
              <button
                mat-button
                color="warn"
                type="button"
                (click)="deselectAllProviders()"
              >
                Uncheck All
              </button>
            </div>
            <!-- Providers List -->
            <div style="max-height: 600px; overflow: auto">
              <div *ngFor="let provider of filteredAllResources">
                <mat-checkbox
                  color="primary"
                  [disabled]="!isResourceFacilityVisible(provider)"
                  [checked]="isResourceVisible(provider)"
                  (change)="selectDeselctResource($event, provider)"
                >
                  {{ provider.name }}
                </mat-checkbox>
              </div>
            </div>
          </mat-card>

          <!-- Facilities  -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader">
              Facilities ({{ facilitiesSelectionList?.length }})
            </label>

            <!-- Search Box -->
            <div
              style="
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 10px;
                border: 1px solid rgb(0, 0, 0, 0.2);
                background-color: #ffffff;
                padding: 5px;
              "
            >
              <span
                class="material-icons"
                style="text-align: left; font-size: 20px; opacity: 0.5"
              >
                search
              </span>
              <input
                type="search"
                placeholder="Facility Name"
                [(ngModel)]="searchFacilityName"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="searchByFacilityNameChanged()"
                style="
                  padding-left: 15px;
                  width: 100%;
                  background: transparent;
                  border: 0;
                "
              />
            </div>
            <!-- Quick Actions -->
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              "
            >
              <button
                mat-button
                color="primary"
                type="button"
                (click)="selectAllFacilities()"
              >
                Select All
              </button>
              <button
                mat-button
                color="warn"
                type="button"
                (click)="deselectAllFacilities()"
              >
                Uncheck All
              </button>
            </div>

            <!-- Facilities List -->
            <div style="max-height: 450px; overflow: auto">
              <div *ngFor="let facility of facilitiesSelectionList">
                <mat-checkbox
                  color="primary"
                  [checked]="isFacilityVisible(facility)"
                  (change)="selectDeselctFacility($event, facility)"
                >
                  {{ facility.facilityName }}
                </mat-checkbox>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- MBSC Calendar Components -->
        <div class="col-md-9" style="max-height: 100vh; overflow-y: auto">
          <mat-error *ngIf="!providersListLoading && providersList.length == 0">
            No providers found. Please contact your administrator.
          </mat-error>

          <!-- Select a provider message -->
          <mat-error
            *ngIf="!providersListLoading && filteredResources.length == 0"
          >
            Please select a provider
          </mat-error>

          <!-- Right Calendar Components -->
          <mbsc-eventcalendar
            #mbscCalendar
            [data]="myEvents"
            [resources]="filteredResources"
            [invalid]="invalids"
            [colors]="invalidColors"
            [options]="mobiscrollCalendarOptions"
            [responsive]="responsiveMobiScrollCalendarView"
            [min]="minimumCalendarDate"
            [max]="maximumCalendarDate"
            [scheduleEventTemplate]="eventTemplate"
            [displayTimezone]="selectedCalendarTimeZone"
          >
            <!-- Custom Event eventTemplate -->
            <ng-template #eventTemplate let-data>
              <div
                class="md-custom-event-cont"
                [ngStyle]="{
                  'border-left': '10px solid ' + data.color,
                  background: data.color
                }"
              >
                <div class="md-custom-event-wrapper">
                  <div class="md-custom-event-details">
                    <div class="md-custom-event-title">
                      <mat-icon
                        *ngIf="data.original.status == 'Canceled'"
                        style="
                          color: red;
                          font-size: 17px;
                          height: 15px !important;
                        "
                      >
                        highlight_off
                      </mat-icon>
                      {{ data.title }}
                    </div>
                    <div class="md-custom-event-time">
                      {{ data.start + ' - ' + data.end }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mbsc-eventcalendar>
        </div>
      </div>

      <!-- Mat Stepper Buttons -->
      <div class="row" style="margin-top: 20px">
        <div class="col-md-12" style="text-align: right">
          <button
            mat-raised-button
            type="button"
            color="primary"
            matStepperNext
          >
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Step 2 -  Session Details -->
    <mat-step
      state="editIcon"
      [stepControl]="therapysessionForm"
      label="Appointment Details"
    >
      <div class="row">
        <!-- Dividing the Sessions Screen into 3 components -->
        <div
          class="col-md-6"
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Member Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Member Details </label>
            <!-- Member Details -->
            <div class="twoGridContainer">
              <!-- Member Name -->
              <label class="labelBold">Member Name</label>
              <label class="labelText">
                {{ rescheduleAppointmentDetails.patientLastName }},{{ ' ' }}
                {{ rescheduleAppointmentDetails.patientFirstName }}{{ ' ' }}
                {{ rescheduleAppointmentDetails.patientMiddleName }}
              </label>
              <!-- Member DOB -->
              <label class="labelBold">Member DOB</label>
              <label class="labelText">
                {{ rescheduleAppointmentDetails.patientDOB }}
              </label>
              <!-- Member Email -->
              <label class="labelBold" *ngIf="patient?.email">Email</label>
              <label class="labelText" *ngIf="patient?.email">
                {{ patient.email }}
              </label>
              <!-- Member Email -->
              <label class="labelBold" *ngIf="patient?.phoneNumber"
                >Phone
              </label>
              <label class="labelText" *ngIf="patient?.phoneNumber">
                {{ patient.phoneNumber | phoneFormat }}
              </label>
            </div>
          </mat-card>

          <!-- Provider Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Provider Details -->
              <div class="col-md-12">
                <label class="labelHeader"> Provider Details </label>
              </div>

              <!-- Select Facility -->
              <div class="col-md-12">
                <div class="twoGridContainer">
                  <!-- Rendering Provider Name -->
                  <label class="labelBold"> Rendering Provider</label>
                  <label class="labelText">
                    {{
                      therapysessionForm.controls['physicianLastName'].value
                    }},{{ ' ' }}
                    {{ therapysessionForm.controls['physicianFirstName'].value
                    }}{{ ' ' }}
                    {{
                      therapysessionForm.controls['physicianMiddleName'].value
                    }}
                  </label>

                  <!-- Provider Email -->
                  <label class="labelBold">Email</label>
                  <label class="labelText">
                    {{ therapysessionForm.controls['physicianEmail'].value }}
                  </label>

                  <!-- Provider Phone -->
                  <label class="labelBold">Phone</label>
                  <label class="labelText">
                    {{
                      therapysessionForm.controls['physicianPhone'].value
                        | phoneFormat
                    }}
                  </label>
                </div>
              </div>
            </div>
          </mat-card>

          <!-- Billing Provider Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Provider Details -->
              <div class="col-md-12">
                <label class="labelHeader"> Billing Details </label>
              </div>

              <!-- Select Facility -->
              <div class="col-md-12">
                <label class="labelBold">
                  Service Location
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="billingProviderRefId"
                    placeholder="Select a facility"
                  >
                    <mat-option
                      *ngFor="let facility of selectedProviderFacilities"
                      [value]="facility.id"
                    >
                      {{ facility.facilityName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      therapysessionForm.controls['billingProviderRefId']
                        .invalid
                    "
                  >
                    Please select one of the facility
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- Appointment Details -->
        <div
          class="col-md-6"
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Session Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Appointment Details </label>
            <div class="row">
              <!-- Visit Type -->
              <div class="col-md-12">
                <label class="labelBold">
                  Please select a visit reason
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="visitReasonId"
                    placeholder="Visit Reasons"
                    (selectionChange)="visitReasonChanged()"
                  >
                    <mat-option
                      *ngFor="let visitReason of visitReasons"
                      [value]="visitReason.id"
                    >
                      {{ visitReason.reasonName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="therapysessionForm.controls['visitReasonId'].invalid"
                  >
                    Please select one of the visit reason
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Session Date, time, duration  -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <label class="labelBold">Start Date</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sessionStartDate"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelBold">Start Time</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sessionStartTime"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelBold">Duration</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        placeholder="Duration"
                        formControlName="sessionDuration"
                        type="text"
                        required
                        maxlength="3"
                        pattern="\d*"
                      />
                      <span matSuffix>min</span>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionDuration'].invalid
                        "
                      >
                        Valid values 1 - 999
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- Session For, Type -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelBold">
                      Appointment For
                      <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="sessionFor"
                        placeholder="Appointment For"
                      >
                        <mat-option value="Individual">Individual</mat-option>
                        <!-- <mat-option value="Group">Group</mat-option>
                        <mat-option value="Couples">Couples</mat-option>
                        <mat-option value="Family">Family</mat-option> -->
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionFor'].invalid
                        "
                      >
                        Appointment For is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <label class="labelBold">
                      Appointment Type
                      <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="sessionType"
                        placeholder="Appointment Type"
                      >
                        <mat-option value="In-Person Appointment">
                          In-Person
                        </mat-option>
                        <mat-option value="Tele-Health Appointment">
                          Tele-Health
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionType'].invalid
                        "
                      >
                        Appointment Type is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- Status / Queue Type -->
              <div class="col-md-12">
                <label class="labelBold">
                  Status
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="queue"
                    placeholder="Status"
                    (selectionChange)="statusChanged($event)"
                  >
                    <mat-option
                      *ngFor="let queue of sessionQueues"
                      [value]="queue"
                    >
                      {{ queue }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="therapysessionForm.controls['queue'].invalid"
                  >
                    Please select one of the status
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Cancellation Reason -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['queue'].value === 'Canceled'
                "
              >
                <label class="labelBold">
                  Cancellation Reason
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    formControlName="cancellationReason"
                    name="cancellationReason"
                  ></textarea>
                  <mat-error
                    *ngIf="
                      therapysessionForm.controls['cancellationReason'].invalid
                    "
                  >
                    Please provide the cancellation reason(s)
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Cancel Series if edit recurring is there -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['editSeries'].value &&
                  therapysessionForm.controls['recurringAppointment'].value &&
                  therapysessionForm.controls['queue'].value === 'Canceled'
                "
              >
                <label class="labelBold">
                  Cancel Future Series <span style="color: red">*</span>
                </label>
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="cancelOccurance"
                ></mat-checkbox>
              </div>
              <!-- Edit Recurring Series -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['recurringAppointment'].value
                "
              >
                <label class="labelBold">
                  Edit Recurring Series <span style="color: red">*</span>
                </label>
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="editSeries"
                ></mat-checkbox>
              </div>

              <!-- Recurring Appointment details -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['recurringAppointment'].value ||
                  therapysessionForm.controls['editSeries'].value
                "
              >
                <div class="row">
                  <!-- Recurring Start Date -->
                  <div class="col-md-6">
                    <label class="labelBold">
                      Recurring Start <span style="color: red">*</span></label
                    >
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="recurringStart"
                        matInput
                        [matDatepicker]="recurringStartPicker"
                        placeholder="Start Date"
                        autoSlashDate
                        [min]="therapysessionForm.controls['start'].value"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="recurringStartPicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #recurringStartPicker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['recurringStart'].invalid
                        "
                      >
                        Please select a valid date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!-- Recurring End Date -->
                  <div class="col-md-6">
                    <label class="labelBold">
                      Recurring End <span style="color: red">*</span></label
                    >
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="recurringEnd"
                        matInput
                        [min]="
                          therapysessionForm.controls['recurringStart'].value
                        "
                        [matDatepicker]="recurringEndPicker"
                        placeholder="End Date"
                        autoSlashDate
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="recurringEndPicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #recurringEndPicker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['recurringEnd'].invalid
                        "
                      >
                        Please select a valid date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!-- Recurring Frequency  -->
                  <div class="col-md-6">
                    <label class="labelBold">
                      Recurring Frequency <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="recurringFrequency">
                        <mat-option value="Daily">Daily</mat-option>
                        <mat-option value="Weekday">
                          Weekday (Mon-Fri)
                        </mat-option>
                        <mat-option value="Custom">Custom</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['recurringFrequency']
                            .invalid
                        "
                      >
                        Please select the frequency
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!-- Recurring Frequency -->
                  <div
                    class="col-md-6"
                    *ngIf="
                      therapysessionForm.controls['recurringFrequency']
                        .value === 'Custom'
                    "
                  >
                    <label class="labelBold">
                      Repeats every <span style="color: red">*</span>
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <mat-form-field appearance="outline">
                          <input
                            formControlName="recurringFrequencyNumber"
                            matInput
                            type="number"
                            min="1"
                          />
                          <mat-error
                            *ngIf="
                              therapysessionForm.controls[
                                'recurringFrequencyNumber'
                              ].invalid
                            "
                          >
                            Minimum number is 1
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field appearance="outline">
                          <mat-select
                            formControlName="recurringFrequencyInterval"
                          >
                            <mat-option value="Day">Day</mat-option>
                            <mat-option value="Week">Week</mat-option>
                            <mat-option value="Month">Month</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              therapysessionForm.controls[
                                'recurringFrequencyInterval'
                              ].invalid
                            "
                          >
                            Please select the interval
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- Recurring Days  -->
                  <div
                    class="col-md-12"
                    *ngIf="
                      therapysessionForm.controls['recurringFrequency']
                        .value === 'Custom' &&
                      therapysessionForm.controls['recurringFrequencyInterval']
                        .value === 'Week'
                    "
                  >
                    <label class="labelBold">
                      Recurring Days <span style="color: red">*</span>
                    </label>
                    <section
                      [formGroup]="therapysessionForm.controls.recurringDays"
                    >
                      <mat-checkbox color="primary" formControlName="sunday">
                        Sunday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="monday">
                        Monday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="tuesday">
                        Tuesday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="wednesday">
                        Wednesday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="thursday">
                        Thursday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="friday">
                        Friday
                      </mat-checkbox>
                      <mat-checkbox color="primary" formControlName="saturday">
                        Saturday
                      </mat-checkbox>
                    </section>
                  </div>

                  <div
                    class="col-md-12"
                    *ngIf="
                      therapysessionForm.controls['recurringFrequency']
                        .value === 'Custom' &&
                      therapysessionForm.controls['recurringFrequencyInterval']
                        .value === 'Month'
                    "
                  >
                    <label class="labelBold">
                      On Day <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="recurringFrequencyMonthNumber"
                        matInput
                        type="number"
                        min="1"
                      />
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls[
                            'recurringFrequencyMonthNumber'
                          ].invalid
                        "
                      >
                        Minimum number is 1
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!-- Recurring Label -->
                  <div
                    class="col-md-12"
                    *ngIf="
                      therapysessionForm.controls['recurringFrequency'].value
                    "
                  >
                    <label>{{ getRecurringLabel() }}</label>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <!-- Associated Care Case Details -->
          <mat-card
            *ngIf="hasCareFeature"
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <div class="col-9">
                <label class="labelHeader"> Associate with Care Case # </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="caseId"
                    placeholder="Cases"
                    (selectionChange)="careCaseChanged($event)"
                  >
                    <mat-option value=""></mat-option>
                    <mat-option
                      *ngFor="let cases of existingCaseList"
                      [value]="cases.id"
                    >
                      {{ cases.caseNumber }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                class="col-3"
                *ngIf="hasCaseAddAccess"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <button
                  mat-icon-button
                  type="button"
                  (click)="addNewCase()"
                  matTooltip="Add a new care case"
                >
                  <mat-icon color="primary">add_circle</mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <!-- Mat Stepper Buttons -->
      <div class="row" style="margin-top: 20px">
        <div
          class="col-md-12"
          style="display: flex; justify-content: space-between"
        >
          <button mat-raised-button type="button" matStepperPrevious>
            Back
          </button>

          <button
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="therapysessionForm.invalid || processing"
            (click)="submitForm()"
          >
            <mat-icon>save</mat-icon>Save
            <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Overriding Stepper Icons -->
    <ng-template matStepperIcon="calendaricon">
      <mat-icon>calendar_month</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="editIcon">
      <mat-icon>edit_note</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="confirmIcon">
      <mat-icon>checklist_rtl</mat-icon>
    </ng-template>
  </mat-stepper>
</form>
