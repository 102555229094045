import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Case, Patient } from 'src/app/_models';
import { ICaseTreatmentAdmissionProfile } from 'src/app/_models/case/treatment/admission/casetreatmentadmissionprofile.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { UserApiService } from 'src/app/_services';
import { CaseTreatmentAdmissionProfileApiService } from 'src/app/_services/case/treatment/admission/casetreatmentadmissionprofile.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-case-admission-profile',
  templateUrl: './case-admission-profile.component.html',
  styleUrl: './case-admission-profile.component.css',
})
export class CaseTreatmentAdmissionProfileComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedProgram: ITreatmentProgram;
  @Input() readOnly: boolean = false;

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  constructor(
    public casetreatmentadmissionprofileApiService: CaseTreatmentAdmissionProfileApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService
  ) {}

  public action: string;
  public casetreatmentadmissionprofileForm: FormGroup;

  casetreatmentadmissionprofile: ICaseTreatmentAdmissionProfile;
  casetreatmentadmissionprofileError: Error = null;

  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.buildForm();
    this.loadActiveProviders();
    this.loadCaseTreatmentAdmission();
  }

  loadCaseTreatmentAdmission() {
    this.casetreatmentadmissionprofileApiService
      .getCaseTreatmentAdmissionProfileDetails(
        this.case.id,
        this.selectedProgram?.id
      )
      .subscribe((casetreatmentadmissionprofile) => {
        if (casetreatmentadmissionprofile?.data) {
          Object.keys(casetreatmentadmissionprofile.data).forEach((key) => {
            if (key === 'admissionDate' || key === 'latestTbTestDate') {
              this.casetreatmentadmissionprofileForm.controls[key].setValue(
                mmddyyyyToDate(casetreatmentadmissionprofile.data[key])
              );
            } else {
              this.casetreatmentadmissionprofileForm.controls[key].setValue(
                casetreatmentadmissionprofile.data[key]
              );
            }

            if (this.readOnly) {
              this.casetreatmentadmissionprofileForm.controls[key].disable();
            }
          });
        }
      });
  }

  onCaseTreatmentAdmissionFormSubmit() {
    if (this.casetreatmentadmissionprofileForm.invalid) {
      this.casetreatmentadmissionprofileForm.markAllAsTouched();
      return;
    }
    this.casetreatmentadmissionprofile = Object.assign(
      {},
      this.casetreatmentadmissionprofileForm.value
    );

    ['admissionDate', 'latestTbTestDate'].forEach((field) => {
      if (this.casetreatmentadmissionprofile[field]) {
        this.casetreatmentadmissionprofile[field] = formatDate(
          new Date(this.casetreatmentadmissionprofile[field])
        );
      }
    });

    this.processing = true;
    this.casetreatmentadmissionprofileApiService
      .updateCaseTreatmentAdmissionProfile(this.casetreatmentadmissionprofile)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.casetreatmentadmissionprofileForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      programId: new FormControl(
        this.selectedProgram?.id,
        this.selectedProgram ? Validators.required : null
      ),
      admissionType: new FormControl('', Validators.required),
      admissionStaffId: new FormControl('', Validators.required),
      admissionStaff: new FormControl('', Validators.required),
      admissionDate: new FormControl('', Validators.required),
      admissionTime: new FormControl('', Validators.required),
      estDurationOfTxDays: new FormControl(null),
      codependentOrCollateral: new FormControl('', Validators.required),
      treatingHereFor: new FormControl(''),
      clientType: new FormControl(''),
      tbTestResults: new FormControl(''),
      latestTbTestDate: new FormControl(''),
      numberOfPriorSaTxAdmissions: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),
      clientReportedHealthStatus: new FormControl(''),
      mentalHealthProblem: new FormControl('', Validators.required),
      useOfMethadone: new FormControl('', Validators.required),
      methadoneType: new FormControl(''),
      education: new FormControl('', Validators.required),
      timesAttendedSelfHelpPrograms: new FormControl('', Validators.required),
    });
  }

  loadActiveProviders() {
    this.providersListLoading = true;

    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  // Search Filter for supervising provider
  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }

  staffSelected(event) {
    const providerId = event.value;

    let providerInformation = this.providersList
      .filter((provider) => provider.id === providerId)
      .at(0);

    this.casetreatmentadmissionprofileForm.controls['admissionStaff'].setValue(
      providerInformation.firstName + ' ' + providerInformation.lastName
    );
  }

  useOfMethadone(event) {
    if (event.value === 'Yes') {
      this.casetreatmentadmissionprofileForm.controls[
        'methadoneType'
      ].addValidators(Validators.required);
      this.casetreatmentadmissionprofileForm.controls[
        'methadoneType'
      ].updateValueAndValidity();
    } else {
      this.casetreatmentadmissionprofileForm.controls[
        'methadoneType'
      ].clearValidators();
      this.casetreatmentadmissionprofileForm.controls[
        'methadoneType'
      ].updateValueAndValidity();
    }
  }
}
