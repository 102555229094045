import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAllergy } from 'src/app/_models/patient/allergy.model';
import { ToastMessageService } from 'src/app/_services';
import { AllergyApiService } from 'src/app/_services/patient/allergy.service';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-allergy-dialog',
  templateUrl: './add-edit-allergy-dialog.component.html',
  styleUrls: ['./add-edit-allergy-dialog.component.css'],
})
export class AddEditAllergyDialogComponent implements OnInit {
  public action: string;
  public allergyForm: FormGroup;
  public patientId: string;

  allergy: IAllergy;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditAllergyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public allergyApiService: AllergyApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.allergy = data.allergy;
    this.patientId = data.patientId;
  }

  public ngOnInit() {
    this.buildForm();
    if (this.allergy) {
      this.allergyForm.controls['id'].setValue(this.allergy.id);
      this.allergyForm.controls['organizationId'].setValue(
        this.allergy.organizationId
      );
      this.allergyForm.controls['patientId'].setValue(this.allergy.patientId);
      this.allergyForm.controls['name'].setValue(this.allergy.name);
      this.allergyForm.controls['status'].setValue(this.allergy.status);
      this.allergyForm.controls['reactionType'].setValue(
        this.allergy.reactionType
      );
      this.allergyForm.controls['reaction'].setValue(this.allergy.reaction);

      if (this.allergy.onsetDate) {
        this.allergyForm.controls['onsetDate'].setValue(
          mmddyyyyToDate(this.allergy.onsetDate)
        );
      }
    }
  }

  onAllergyFormSubmit() {
    if (this.allergyForm.invalid) {
      this.allergyForm.markAllAsTouched();
      return;
    }
    this.allergy = Object.assign({}, this.allergy);
    this.allergy = Object.assign(this.allergy, this.allergyForm.value);

    if (this.allergy.onsetDate) {
      this.allergy.onsetDate = formatDate(new Date(this.allergy.onsetDate));
    }

    this.processing = true;

    if (this.action === 'EDIT') {
      this.allergyApiService.updateAllergy(this.allergy).subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Allergy has been updated'
          );
          this.dialogRef.close('success');
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error occured while editing the allergy'
          );
        }
      );
    } else {
      this.allergyApiService.addAllergy(this.allergy).subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'New allergy has been added'
          );
          this.dialogRef.close('success');
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error occured while adding the allergy'
          );
        }
      );
    }
  }

  private buildForm() {
    this.allergyForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(this.patientId, Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      status: new FormControl('', Validators.compose([Validators.required])),
      reactionType: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      reaction: new FormControl('', Validators.compose([])),
      onsetDate: new FormControl('', Validators.compose([])),
    });
  }
}
