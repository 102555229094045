<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Vital</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <form [formGroup]="vitalsForm">
    <div class="row">
      <div class="col-4">
        <label class="labelBold">Height </label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Height"
            formControlName="height"
            name="height"
            type="number"
            min="0"
          />
          <span matSuffix> in </span>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold">Weight </label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Weight"
            formControlName="weight"
            name="weight"
          />
          <span matSuffix> Lb </span>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold">Blood Sugar </label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Blood Sugar"
            formControlName="bloodSugar"
            name="bloodSugar"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold">Pulse Rate</label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Pulse Rate"
            formControlName="pulse"
            name="pulse"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold">Oxygen Saturation</label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Oxygen Level"
            formControlName="oxygenLevel"
            name="oxygenLevel"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold">Body Temperature </label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Body Temperature"
            formControlName="bodyTemperature"
            name="bodyTemperature"
          />
          <span matSuffix> F </span>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label class="labelBold"> Blood Pressure </label>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>High</mat-label>
          <input
            matInput
            placeholder="High"
            formControlName="bloodPressureHigh"
            name="bloodPressureHigh"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>Low</mat-label>
          <input
            matInput
            placeholder="Low"
            formControlName="bloodPressureLow"
            name="bloodPressureLow"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="labelText">Note</label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Custom Note"
            formControlName="message"
            name="message"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Activity Date/Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Date Time"
            formControlName="activityDateTime"
            [max]="todaysDate"
          />
          <owl-date-time #dt1></owl-date-time>

          <mat-error *ngIf="vitalsForm.controls['activityDateTime'].invalid">
            Please select the activity date and time
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="vitalsForm.hasError('atLeastOneRequired')" style="color: red">
        At least one vital field must be entered.
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="sessionDetails.status === 'Active'"
      >
        <button
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="!vitalsForm.valid || processing"
          (click)="submitVitalForm()"
        >
          Update <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
