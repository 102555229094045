import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Patient } from 'src/app/_models/patient/patient';
import { DateTimeZoneService } from 'src/app/_services/date-time-zone.service';
import { LabOrderApiService } from 'src/app/_services/patient/laborder.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-add-edit-lab-order',
  templateUrl: './add-edit-lab-order.component.html',
  styleUrl: './add-edit-lab-order.component.css'
})

export class AddEditLabOrderComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  patient: Patient;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private labOrderApiService: LabOrderApiService,
    private toastMessageService: ToastMessageService,
    private dialogRef: MatDialogRef<AddEditLabOrderComponent>,
    private dateTimeZoneService: DateTimeZoneService) {

    this.patient = data.patient;
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  currentTimezone: string;
  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  markerForm: FormGroup;
  stepThreeForm: FormGroup;
  items: any[] = [];
  labs: any[] = [];
  areaInfo: any;
  serviceCenters: any[] = [];
  slots: any[] = [];
  selectedServiceCenter: any;
  isLoadingStep2 = false;
  isLoadingStep3 = false;
  isLoadingStep4 = false;
  isLoadingMarkers = false;
  savingLabOrder = false;

  markers: any[] = [];
  filteredMarkers: any[] = [];
  markerSelection = new SelectionModel<any>(true, []);
  displayedMarkerColumns: string[] = ['select', 'name', 'price', 'description'];

  selectedPatientInfo = {
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Gender: '',
    PhoneNumber: '',
    Email: '',
    AddressFirstLine: '',
    AddressCity: '',
    AddressState: '',
    AddressZip: '',
    AddressCountry: '',
    UserId: '',
    ClientUserId: '',
    LabTestId: '',
  };

  selectedLab = {
    name: '',
    address: '',
  };

  selectedAppointmentSlot = {
    start: '',
    end: '',
    booking_key: ''
  };

  ngOnInit(): void {
    this.stepOneForm = this.fb.group({
      zip: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d{5}(-\d{4})?$/),
        ])
      ),
    });
    this.stepTwoForm = this.fb.group({
      selectedLab: [null, Validators.required],
    });
    this.markerForm = this.fb.group({
      markers: [this.markerSelection.selected, [this.atLeastOneMarkerSelectedValidator()]]
    });

    this.stepThreeForm = this.fb.group({
      labSlug: [null, Validators.required],
      appointmentWithVital: [null, Validators.required]
    });
  }

  fetchLabs(): void {
    this.isLoadingStep2 = true;
    let selectedZip = this.stepOneForm.controls['zip'].value;
    this.labOrderApiService.getAllLabs(selectedZip).subscribe(
      (response) => {
        if (response && response.data) {
          this.labs = response.data.labDetails;
          this.areaInfo = response.data.areaInfo;
          this.isLoadingStep2 = false;
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get facilities for the selected organization'
        );
        this.isLoadingStep2 = false;
      }
    );
  }

  selectLab(labId: number, labSlug: string): void {
    this.stepTwoForm.get('selectedLab')?.setValue(labId);

    const labCentralInfo: any = Object.values(this.areaInfo.central_labs).find(
      (lab: any) => lab.lab_id === labId
    );

    if (labCentralInfo) {
      this.selectedLab = labCentralInfo;
    }

    const appointmentWithVital = labCentralInfo.patient_service_centers.appointment_with_vital;
    this.stepThreeForm.get('appointmentWithVital')?.setValue(appointmentWithVital);
    this.stepThreeForm.controls['labSlug'].setValue(labSlug);

    if (appointmentWithVital) {
      this.stepper.next();
      // this.stepper.next();
      this.pickMarkers();
      // this.fetchTimeSlots();
    } else {
      this.stepper.next();
      this.fetchServiceCenters();
    }
  }

  // Fetch service centers for Step #2
  fetchServiceCenters(): void {
    this.isLoadingStep3 = true;
    let labId = this.stepTwoForm.controls['selectedLab'].value;
    let selectedZip = this.stepOneForm.controls['zip'].value;

    this.labOrderApiService.findServiceCenters(labId, selectedZip).subscribe(
      (response) => {
        if (response && response.data) {
          this.serviceCenters = response.data?.patient_service_centers;
          this.stepThreeForm.controls['labSlug'].setValue(response.data?.slug);
          this.isLoadingStep3 = false;
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get facilities for the selected organization'
        );
        this.isLoadingStep3 = false;
      }
    );
  }

  fetchTimeSlots(): void {
    this.isLoadingStep4 = true;
    const payload =
    {
      lab: this.stepThreeForm.controls['labSlug'].value,
      zipCode: this.stepOneForm.controls['zip'].value,
      radius: "50",
      // start_date: new Date().toISOString().split('T')[0],
      site_codes: ""
    }

    this.labOrderApiService.fetchTimeSlots(payload).subscribe(
      (response) => {
        if (response && response.data) {
          this.slots = response.data.slots;
          this.isLoadingStep4 = false;
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get facilities for the selected organization'
        );
        this.isLoadingStep4 = false;
      }
    );
  }

  setAppointmentTime(timeSlot: any, location: any) {
    this.selectedAppointmentSlot = {
      start: timeSlot.start,
      end: timeSlot.end,
      booking_key: timeSlot.booking_key
    };

    // Assuming the lab details are stored with the slot
    this.selectedLab = {
      name: location.name,
      address: `${location.address.first_line}, ${location.address.city}, ${location.address.state} ${location.address.zip_code}`,
    };

    this.pickMarkers();
  }

  pickMarkers(): void {
    this.stepper.next();
    this.isLoadingMarkers = true;
    let labId = this.stepTwoForm.controls['selectedLab'].value;

    this.labOrderApiService.fetchMarkers(labId).subscribe(
      (response) => {
        this.isLoadingMarkers = false;
        if (response && response.data) {
          this.markers = response.data.markers;
          this.filteredMarkers = response.data.markers;
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get markers'
        );
        this.isLoadingMarkers = false;
      }
    );
  }

  navigateToConfirmationScreen(): void {
    this.stepper.next();
    let appointmentWithVital = this.stepThreeForm.controls['appointmentWithVital'].value;
    if (!appointmentWithVital) {
      this.stepper.next();
    }
  }

  navigateToMarkersScreen(): void {
    this.stepper.next();
  }

  onSelectServiceCenter(center: any): void {
    this.selectedServiceCenter = center;
  }

  onSubmit(): void {
    this.savingLabOrder = true;
    const payload =
    {
      FirstName: this.patient.firstName,
      LastName: this.patient.lastName,
      DateOfBirth: this.convertDob(this.patient.dateOfBirth),
      Gender: this.patient.gender,
      PhoneNumber: this.patient.phoneNumber,
      Email: this.patient.email,
      AddressFirstLine: this.patient.addressLine1,
      AddressCity: this.patient.city,
      AddressState: this.patient.state,
      AddressZip: this.patient.zip,
      AddressCountry: "US",
      ClientUserId: this.patient.id,
      BookingKey: this.selectedAppointmentSlot.booking_key,
      MarkerIds: this.markerSelection.selected.map(marker => marker.id)
    }

    this.labOrderApiService.createLabOrder(payload).subscribe(
      (response) => {
        if (response && response.data) {
          this.savingLabOrder = false;
          this.dialogRef.close();
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to create Lab Order'
        );
        this.savingLabOrder = false;
      }
    );
  }

  convertDob(inputDate: string): string {
    const [month, day, year] = inputDate.split('/').map(Number);
    const formattedDate = new Date(year, month - 1, day).toISOString().split('T')[0];
    return formattedDate;
  }

  applyMarkerFilter(event: Event) {
    this.filteredMarkers = this.markers;

    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    // Filter markers
    this.filteredMarkers = this.markers.filter(marker => {
      // Check marker's name, description, and slug
      const markerMatch = marker.name.toLowerCase().includes(filterValue) ||
        marker.description?.toLowerCase().includes(filterValue) ||
        marker.slug?.toLowerCase().includes(filterValue);

      // Check expected_results names
      const expectedResultsMatch = marker.expected_results?.some(result =>
        result.name.toLowerCase().includes(filterValue)
      );

      // Include marker if any condition matches
      return markerMatch || expectedResultsMatch;
    });
  }


  toggleMarkerSelection(row: any) {
    this.markerSelection.toggle(row);
    this.updateMarkerFormState();
  }
  masterMarkerToggle() {
    this.isAllMarkersSelected()
      ? this.markerSelection.clear()
      : this.markers.forEach(row => this.markerSelection.select(row));
    this.updateMarkerFormState();
  }
  isAllMarkersSelected() {
    const numSelected = this.markerSelection.selected.length;
    const numRows = this.markers.length;
    return numSelected === numRows;
  }

  updateMarkerFormState() {
    this.markerForm.controls['markers'].setValue(this.markerSelection.selected);
    this.markerForm.controls['markers'].updateValueAndValidity();
  }

  atLeastOneMarkerSelectedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = control.value && control.value.length > 0;
      return isValid ? null : { noMarkerSelected: true };
    };
  }
}