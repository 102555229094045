import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMysticalExperience } from 'src/app/_models/assessments/personality/mysticalexperience.model';
import {
  MysticalExperienceApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-mystical-experience',
  templateUrl: './mystical-experience.component.html',
  styleUrls: ['./mystical-experience.component.css'],
})
export class MysticalExperienceComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public mysticalExperienceApiService: MysticalExperienceApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public mysticalExperienceForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  mysticalExperienceAssessment: IMysticalExperience;
  mysticalExperienceAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.mysticalExperienceForm, this.errors);

    this.loadMysticalExperienceAssessment();
  }

  loadMysticalExperienceAssessment() {
    this.mysticalExperienceForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.mysticalExperienceApiService
        .getMysticalExperienceDetails(this.assessmentId)
        .subscribe((mysticalExperienceAssessment) => {
          if (mysticalExperienceAssessment.data) {
            this.mysticalExperienceForm.controls['q1'].setValue(
              mysticalExperienceAssessment.data.q1.answer
            );
            this.mysticalExperienceForm.controls['q2'].setValue(
              mysticalExperienceAssessment.data.q2.answer
            );
            this.mysticalExperienceForm.controls['q3'].setValue(
              mysticalExperienceAssessment.data.q3.answer
            );
            this.mysticalExperienceForm.controls['q4'].setValue(
              mysticalExperienceAssessment.data.q4.answer
            );
            this.mysticalExperienceForm.controls['q5'].setValue(
              mysticalExperienceAssessment.data.q5.answer
            );
            this.mysticalExperienceForm.controls['q6'].setValue(
              mysticalExperienceAssessment.data.q6.answer
            );
            this.mysticalExperienceForm.controls['q7'].setValue(
              mysticalExperienceAssessment.data.q7.answer
            );
            this.mysticalExperienceForm.controls['q8'].setValue(
              mysticalExperienceAssessment.data.q8.answer
            );
            this.mysticalExperienceForm.controls['q9'].setValue(
              mysticalExperienceAssessment.data.q9.answer
            );
            this.mysticalExperienceForm.controls['q10'].setValue(
              mysticalExperienceAssessment.data.q10.answer
            );
            this.mysticalExperienceForm.controls['q11'].setValue(
              mysticalExperienceAssessment.data.q11.answer
            );
            this.mysticalExperienceForm.controls['q12'].setValue(
              mysticalExperienceAssessment.data.q12.answer
            );
            this.mysticalExperienceForm.controls['q13'].setValue(
              mysticalExperienceAssessment.data.q13.answer
            );
            this.mysticalExperienceForm.controls['q14'].setValue(
              mysticalExperienceAssessment.data.q14.answer
            );
            this.mysticalExperienceForm.controls['q15'].setValue(
              mysticalExperienceAssessment.data.q15.answer
            );
            this.mysticalExperienceForm.controls['q16'].setValue(
              mysticalExperienceAssessment.data.q16.answer
            );
            this.mysticalExperienceForm.controls['q17'].setValue(
              mysticalExperienceAssessment.data.q17.answer
            );
            this.mysticalExperienceForm.controls['q18'].setValue(
              mysticalExperienceAssessment.data.q18.answer
            );
            this.mysticalExperienceForm.controls['q19'].setValue(
              mysticalExperienceAssessment.data.q19.answer
            );
            this.mysticalExperienceForm.controls['q20'].setValue(
              mysticalExperienceAssessment.data.q20.answer
            );
            this.mysticalExperienceForm.controls['q21'].setValue(
              mysticalExperienceAssessment.data.q21.answer
            );
            this.mysticalExperienceForm.controls['q22'].setValue(
              mysticalExperienceAssessment.data.q22.answer
            );
            this.mysticalExperienceForm.controls['q23'].setValue(
              mysticalExperienceAssessment.data.q23.answer
            );
            this.mysticalExperienceForm.controls['q24'].setValue(
              mysticalExperienceAssessment.data.q24.answer
            );
            this.mysticalExperienceForm.controls['q25'].setValue(
              mysticalExperienceAssessment.data.q25.answer
            );
            this.mysticalExperienceForm.controls['q26'].setValue(
              mysticalExperienceAssessment.data.q26.answer
            );
            this.mysticalExperienceForm.controls['q27'].setValue(
              mysticalExperienceAssessment.data.q27.answer
            );
            this.mysticalExperienceForm.controls['q28'].setValue(
              mysticalExperienceAssessment.data.q28.answer
            );
            this.mysticalExperienceForm.controls['q29'].setValue(
              mysticalExperienceAssessment.data.q29.answer
            );
            this.mysticalExperienceForm.controls['q30'].setValue(
              mysticalExperienceAssessment.data.q30.answer
            );

            this.riskScore = mysticalExperienceAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.mysticalExperienceForm.invalid) {
      this.mysticalExperienceForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.mysticalExperienceAssessment = Object.assign(
      {},
      this.mysticalExperienceAssessment
    );
    this.mysticalExperienceAssessment.patientId =
      this.mysticalExperienceForm.controls['patientId'].value;
    this.mysticalExperienceAssessment.sessionId =
      this.mysticalExperienceForm.controls['sessionId'].value;
    this.mysticalExperienceAssessment.assessmentRequestId =
      this.mysticalExperienceForm.controls['assessmentRequestId'].value;
    this.mysticalExperienceAssessment.q1 = {
      answer: this.mysticalExperienceForm.controls['q1'].value,
    };
    this.mysticalExperienceAssessment.q2 = {
      answer: this.mysticalExperienceForm.controls['q2'].value,
    };
    this.mysticalExperienceAssessment.q3 = {
      answer: this.mysticalExperienceForm.controls['q3'].value,
    };
    this.mysticalExperienceAssessment.q4 = {
      answer: this.mysticalExperienceForm.controls['q4'].value,
    };
    this.mysticalExperienceAssessment.q5 = {
      answer: this.mysticalExperienceForm.controls['q5'].value,
    };
    this.mysticalExperienceAssessment.q6 = {
      answer: this.mysticalExperienceForm.controls['q6'].value,
    };
    this.mysticalExperienceAssessment.q7 = {
      answer: this.mysticalExperienceForm.controls['q7'].value,
    };
    this.mysticalExperienceAssessment.q8 = {
      answer: this.mysticalExperienceForm.controls['q8'].value,
    };
    this.mysticalExperienceAssessment.q9 = {
      answer: this.mysticalExperienceForm.controls['q9'].value,
    };
    this.mysticalExperienceAssessment.q10 = {
      answer: this.mysticalExperienceForm.controls['q10'].value,
    };
    this.mysticalExperienceAssessment.q11 = {
      answer: this.mysticalExperienceForm.controls['q11'].value,
    };
    this.mysticalExperienceAssessment.q12 = {
      answer: this.mysticalExperienceForm.controls['q12'].value,
    };
    this.mysticalExperienceAssessment.q13 = {
      answer: this.mysticalExperienceForm.controls['q13'].value,
    };
    this.mysticalExperienceAssessment.q14 = {
      answer: this.mysticalExperienceForm.controls['q14'].value,
    };
    this.mysticalExperienceAssessment.q15 = {
      answer: this.mysticalExperienceForm.controls['q15'].value,
    };
    this.mysticalExperienceAssessment.q16 = {
      answer: this.mysticalExperienceForm.controls['q16'].value,
    };
    this.mysticalExperienceAssessment.q17 = {
      answer: this.mysticalExperienceForm.controls['q17'].value,
    };
    this.mysticalExperienceAssessment.q18 = {
      answer: this.mysticalExperienceForm.controls['q18'].value,
    };
    this.mysticalExperienceAssessment.q19 = {
      answer: this.mysticalExperienceForm.controls['q19'].value,
    };
    this.mysticalExperienceAssessment.q20 = {
      answer: this.mysticalExperienceForm.controls['q20'].value,
    };
    this.mysticalExperienceAssessment.q21 = {
      answer: this.mysticalExperienceForm.controls['q21'].value,
    };
    this.mysticalExperienceAssessment.q22 = {
      answer: this.mysticalExperienceForm.controls['q22'].value,
    };
    this.mysticalExperienceAssessment.q23 = {
      answer: this.mysticalExperienceForm.controls['q23'].value,
    };
    this.mysticalExperienceAssessment.q24 = {
      answer: this.mysticalExperienceForm.controls['q24'].value,
    };
    this.mysticalExperienceAssessment.q25 = {
      answer: this.mysticalExperienceForm.controls['q25'].value,
    };
    this.mysticalExperienceAssessment.q26 = {
      answer: this.mysticalExperienceForm.controls['q26'].value,
    };
    this.mysticalExperienceAssessment.q27 = {
      answer: this.mysticalExperienceForm.controls['q27'].value,
    };
    this.mysticalExperienceAssessment.q28 = {
      answer: this.mysticalExperienceForm.controls['q28'].value,
    };
    this.mysticalExperienceAssessment.q29 = {
      answer: this.mysticalExperienceForm.controls['q29'].value,
    };
    this.mysticalExperienceAssessment.q30 = {
      answer: this.mysticalExperienceForm.controls['q30'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.mysticalExperienceAssessment.accessCode = this.accessCode;

      this.mysticalExperienceApiService
        .addGuestMysticalExperience(this.mysticalExperienceAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Mystical Experience Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Mystical Experience Assessment'
            );
          }
        );
    } else {
      this.mysticalExperienceApiService
        .addMysticalExperience(this.mysticalExperienceAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Mystical Experience Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Mystical Experience Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Freedom from the limitations of your personal self and feeling a unity or bond with what was felt to be greater than your personal self. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Experience of pure being and pure awareness (beyond the world of sense impressions). is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. Experience of oneness in relation to an “inner world” within. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. Experience of the fusion of your personal self into a larger whole. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message: '5. Experience of unity with ultimate reality. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. Feeling that you experienced eternity or infinity. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. Experience of oneness or unity with objects and/or persons perceived in your surroundings. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. Experience of the insight that “all is One.” is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. Awareness of the life or living presence in all things. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Gain of insightful knowledge experienced at an intuitive level. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. Certainty of encounter with ultimate reality (in the sense of being able to “know” and “see” what is really real at some point during your experience. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. You are convinced now, as you look back on your experience, that in it you encountered ultimate reality (i.e., that you “knew” and “saw” what was really real). is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message: '13. Sense of being at a spiritual height. is required',
      },
    ],
    q14: [{ type: 'required', message: '14. Sense of reverence. is required' }],
    q15: [
      {
        type: 'required',
        message:
          '15. Feeling that you experienced something profoundly sacred and holy. is required',
      },
    ],
    q16: [
      { type: 'required', message: '16. Experience of amazement. is required' },
    ],
    q17: [
      {
        type: 'required',
        message: '17. Feelings of tenderness and gentleness. is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message: '18. Feelings of peace and tranquility. is required',
      },
    ],
    q19: [
      { type: 'required', message: '19. Experience of ecstasy. is required' },
    ],
    q20: [
      {
        type: 'required',
        message: '20. Sense of awe or awesomeness. is required',
      },
    ],
    q21: [{ type: 'required', message: '21. Feelings of joy. is required' }],
    q22: [
      {
        type: 'required',
        message: '22. Loss of your usual sense of time. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. Loss of your usual sense of space. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. Loss of usual awareness of where you were. is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. Sense of being “outside of” time, beyond past and future. is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message: '26. Being in a realm with no space boundaries. is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message: '27. Experience of timelessness. is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. Sense that the experience cannot be described adequately in words. is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          '29. Feeling that you could not do justice to your experience by describing it in words. is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. Feeling that it would be difficult to communicate your own experience to others who have not had similar experiences. is required',
      },
    ],
  };

  private buildForm() {
    this.mysticalExperienceForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.mysticalExperienceForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.mysticalExperienceForm) {
      return;
    }
    const form = this.mysticalExperienceForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
