import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ILabOrder } from 'src/app/_models/patient/laborder.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class LabOrderApiService {
    constructor(private httpClient: HttpClient) { }

    getAllTests(): Observable<any> {
        return this.httpClient.get<any>(`${environment.labUrl}/get-all-tests`);
    }

    getAllLabs(zipCode: string): Observable<any> {
        const params = {
            zipCode: zipCode,
            radius: "50"
        };
        return this.httpClient.post<any>(`${environment.labUrl}/get-area-info`, params);
    }

    fetchMarkers(labId: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('labId', labId);
        return this.httpClient.get<any>(`${environment.labUrl}/get-biomarkers`, { params: params });
    }

    findServiceCenters(labId: string, zipCode: string): Observable<any> {
        const params = {
            zipCode: zipCode,
            labId: labId
        };
        return this.httpClient.post<any>(`${environment.labUrl}/get-psc-info`, params);
    }

    fetchTimeSlots(payload: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.labUrl}/psc-availability`, payload);
    }

    createLabOrder(payload: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.labUrl}/order`, payload);
    }

    setBookingTime(payload: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.labUrl}/psc`, payload);
    }

    getLabOrderList(patientId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(`${environment.labUrl}/laborders`, { params: params });
    }

    getLabOrderDetails(laborderId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.labUrl}/laborder/` + laborderId);
    }

    addLabOrder(thisLabOrder: ILabOrder): Observable<ILabOrder> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<ILabOrder>(`${environment.labUrl}/laborder`, thisLabOrder, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateLabOrder(thisLabOrder: ILabOrder): Observable<ILabOrder> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ILabOrder>(`${environment.labUrl}/laborder`, thisLabOrder, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}