import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IEmpathyQuotient } from 'src/app/_models/assessments/personality/empathyquotient.model';
import {
  EmpathyQuotientApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-empathy-quotient',
  templateUrl: './empathy-quotient.component.html',
  styleUrls: ['./empathy-quotient.component.css'],
})
export class EmpathyQuotientComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public empathyQuotientApiService: EmpathyQuotientApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public empathyQuotientForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  empathyQuotientAssessment: IEmpathyQuotient;
  empathyQuotientAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.empathyQuotientForm, this.errors);

    this.loadEmpathyQuotientAssessment();
  }

  loadEmpathyQuotientAssessment() {
    this.empathyQuotientForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.empathyQuotientApiService
        .getEmpathyQuotientDetails(this.assessmentId)
        .subscribe((empathyQuotientAssessment) => {
          if (empathyQuotientAssessment.data) {
            this.empathyQuotientForm.controls['q1'].setValue(
              empathyQuotientAssessment.data.q1.answer
            );
            this.empathyQuotientForm.controls['q2'].setValue(
              empathyQuotientAssessment.data.q2.answer
            );
            this.empathyQuotientForm.controls['q3'].setValue(
              empathyQuotientAssessment.data.q3.answer
            );
            this.empathyQuotientForm.controls['q4'].setValue(
              empathyQuotientAssessment.data.q4.answer
            );
            this.empathyQuotientForm.controls['q5'].setValue(
              empathyQuotientAssessment.data.q5.answer
            );
            this.empathyQuotientForm.controls['q6'].setValue(
              empathyQuotientAssessment.data.q6.answer
            );
            this.empathyQuotientForm.controls['q7'].setValue(
              empathyQuotientAssessment.data.q7.answer
            );
            this.empathyQuotientForm.controls['q8'].setValue(
              empathyQuotientAssessment.data.q8.answer
            );
            this.empathyQuotientForm.controls['q9'].setValue(
              empathyQuotientAssessment.data.q9.answer
            );
            this.empathyQuotientForm.controls['q10'].setValue(
              empathyQuotientAssessment.data.q10.answer
            );
            this.empathyQuotientForm.controls['q11'].setValue(
              empathyQuotientAssessment.data.q11.answer
            );
            this.empathyQuotientForm.controls['q12'].setValue(
              empathyQuotientAssessment.data.q12.answer
            );
            this.empathyQuotientForm.controls['q13'].setValue(
              empathyQuotientAssessment.data.q13.answer
            );
            this.empathyQuotientForm.controls['q14'].setValue(
              empathyQuotientAssessment.data.q14.answer
            );
            this.empathyQuotientForm.controls['q15'].setValue(
              empathyQuotientAssessment.data.q15.answer
            );
            this.empathyQuotientForm.controls['q16'].setValue(
              empathyQuotientAssessment.data.q16.answer
            );
            this.empathyQuotientForm.controls['q17'].setValue(
              empathyQuotientAssessment.data.q17.answer
            );
            this.empathyQuotientForm.controls['q18'].setValue(
              empathyQuotientAssessment.data.q18.answer
            );
            this.empathyQuotientForm.controls['q19'].setValue(
              empathyQuotientAssessment.data.q19.answer
            );
            this.empathyQuotientForm.controls['q20'].setValue(
              empathyQuotientAssessment.data.q20.answer
            );
            this.empathyQuotientForm.controls['q21'].setValue(
              empathyQuotientAssessment.data.q21.answer
            );
            this.empathyQuotientForm.controls['q22'].setValue(
              empathyQuotientAssessment.data.q22.answer
            );
            this.empathyQuotientForm.controls['q23'].setValue(
              empathyQuotientAssessment.data.q23.answer
            );
            this.empathyQuotientForm.controls['q24'].setValue(
              empathyQuotientAssessment.data.q24.answer
            );
            this.empathyQuotientForm.controls['q25'].setValue(
              empathyQuotientAssessment.data.q25.answer
            );
            this.empathyQuotientForm.controls['q26'].setValue(
              empathyQuotientAssessment.data.q26.answer
            );
            this.empathyQuotientForm.controls['q27'].setValue(
              empathyQuotientAssessment.data.q27.answer
            );
            this.empathyQuotientForm.controls['q28'].setValue(
              empathyQuotientAssessment.data.q28.answer
            );
            this.empathyQuotientForm.controls['q29'].setValue(
              empathyQuotientAssessment.data.q29.answer
            );
            this.empathyQuotientForm.controls['q30'].setValue(
              empathyQuotientAssessment.data.q30.answer
            );
            this.empathyQuotientForm.controls['q31'].setValue(
              empathyQuotientAssessment.data.q31.answer
            );
            this.empathyQuotientForm.controls['q32'].setValue(
              empathyQuotientAssessment.data.q32.answer
            );
            this.empathyQuotientForm.controls['q33'].setValue(
              empathyQuotientAssessment.data.q33.answer
            );
            this.empathyQuotientForm.controls['q34'].setValue(
              empathyQuotientAssessment.data.q34.answer
            );
            this.empathyQuotientForm.controls['q35'].setValue(
              empathyQuotientAssessment.data.q35.answer
            );
            this.empathyQuotientForm.controls['q36'].setValue(
              empathyQuotientAssessment.data.q36.answer
            );
            this.empathyQuotientForm.controls['q37'].setValue(
              empathyQuotientAssessment.data.q37.answer
            );
            this.empathyQuotientForm.controls['q38'].setValue(
              empathyQuotientAssessment.data.q38.answer
            );
            this.empathyQuotientForm.controls['q39'].setValue(
              empathyQuotientAssessment.data.q39.answer
            );
            this.empathyQuotientForm.controls['q40'].setValue(
              empathyQuotientAssessment.data.q40.answer
            );
            this.empathyQuotientForm.controls['q41'].setValue(
              empathyQuotientAssessment.data.q41.answer
            );
            this.empathyQuotientForm.controls['q42'].setValue(
              empathyQuotientAssessment.data.q42.answer
            );
            this.empathyQuotientForm.controls['q43'].setValue(
              empathyQuotientAssessment.data.q43.answer
            );
            this.empathyQuotientForm.controls['q44'].setValue(
              empathyQuotientAssessment.data.q44.answer
            );
            this.empathyQuotientForm.controls['q45'].setValue(
              empathyQuotientAssessment.data.q45.answer
            );
            this.empathyQuotientForm.controls['q46'].setValue(
              empathyQuotientAssessment.data.q46.answer
            );
            this.empathyQuotientForm.controls['q47'].setValue(
              empathyQuotientAssessment.data.q47.answer
            );
            this.empathyQuotientForm.controls['q48'].setValue(
              empathyQuotientAssessment.data.q48.answer
            );
            this.empathyQuotientForm.controls['q49'].setValue(
              empathyQuotientAssessment.data.q49.answer
            );
            this.empathyQuotientForm.controls['q50'].setValue(
              empathyQuotientAssessment.data.q50.answer
            );
            this.empathyQuotientForm.controls['q51'].setValue(
              empathyQuotientAssessment.data.q51.answer
            );
            this.empathyQuotientForm.controls['q52'].setValue(
              empathyQuotientAssessment.data.q52.answer
            );
            this.empathyQuotientForm.controls['q53'].setValue(
              empathyQuotientAssessment.data.q53.answer
            );
            this.empathyQuotientForm.controls['q54'].setValue(
              empathyQuotientAssessment.data.q54.answer
            );
            this.empathyQuotientForm.controls['q55'].setValue(
              empathyQuotientAssessment.data.q55.answer
            );
            this.empathyQuotientForm.controls['q56'].setValue(
              empathyQuotientAssessment.data.q56.answer
            );
            this.empathyQuotientForm.controls['q57'].setValue(
              empathyQuotientAssessment.data.q57.answer
            );
            this.empathyQuotientForm.controls['q58'].setValue(
              empathyQuotientAssessment.data.q58.answer
            );
            this.empathyQuotientForm.controls['q59'].setValue(
              empathyQuotientAssessment.data.q59.answer
            );
            this.empathyQuotientForm.controls['q60'].setValue(
              empathyQuotientAssessment.data.q60.answer
            );

            this.riskScore = empathyQuotientAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.empathyQuotientForm.invalid) {
      this.empathyQuotientForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.empathyQuotientAssessment = Object.assign(
      {},
      this.empathyQuotientAssessment
    );
    this.empathyQuotientAssessment.patientId =
      this.empathyQuotientForm.controls['patientId'].value;
    this.empathyQuotientAssessment.sessionId =
      this.empathyQuotientForm.controls['sessionId'].value;
    this.empathyQuotientAssessment.assessmentRequestId =
      this.empathyQuotientForm.controls['assessmentRequestId'].value;
    this.empathyQuotientAssessment.q1 = {
      answer: this.empathyQuotientForm.controls['q1'].value,
    };
    this.empathyQuotientAssessment.q2 = {
      answer: this.empathyQuotientForm.controls['q2'].value,
    };
    this.empathyQuotientAssessment.q3 = {
      answer: this.empathyQuotientForm.controls['q3'].value,
    };
    this.empathyQuotientAssessment.q4 = {
      answer: this.empathyQuotientForm.controls['q4'].value,
    };
    this.empathyQuotientAssessment.q5 = {
      answer: this.empathyQuotientForm.controls['q5'].value,
    };
    this.empathyQuotientAssessment.q6 = {
      answer: this.empathyQuotientForm.controls['q6'].value,
    };
    this.empathyQuotientAssessment.q7 = {
      answer: this.empathyQuotientForm.controls['q7'].value,
    };
    this.empathyQuotientAssessment.q8 = {
      answer: this.empathyQuotientForm.controls['q8'].value,
    };
    this.empathyQuotientAssessment.q9 = {
      answer: this.empathyQuotientForm.controls['q9'].value,
    };
    this.empathyQuotientAssessment.q10 = {
      answer: this.empathyQuotientForm.controls['q10'].value,
    };
    this.empathyQuotientAssessment.q11 = {
      answer: this.empathyQuotientForm.controls['q11'].value,
    };
    this.empathyQuotientAssessment.q12 = {
      answer: this.empathyQuotientForm.controls['q12'].value,
    };
    this.empathyQuotientAssessment.q13 = {
      answer: this.empathyQuotientForm.controls['q13'].value,
    };
    this.empathyQuotientAssessment.q14 = {
      answer: this.empathyQuotientForm.controls['q14'].value,
    };
    this.empathyQuotientAssessment.q15 = {
      answer: this.empathyQuotientForm.controls['q15'].value,
    };
    this.empathyQuotientAssessment.q16 = {
      answer: this.empathyQuotientForm.controls['q16'].value,
    };
    this.empathyQuotientAssessment.q17 = {
      answer: this.empathyQuotientForm.controls['q17'].value,
    };
    this.empathyQuotientAssessment.q18 = {
      answer: this.empathyQuotientForm.controls['q18'].value,
    };
    this.empathyQuotientAssessment.q19 = {
      answer: this.empathyQuotientForm.controls['q19'].value,
    };
    this.empathyQuotientAssessment.q20 = {
      answer: this.empathyQuotientForm.controls['q20'].value,
    };
    this.empathyQuotientAssessment.q21 = {
      answer: this.empathyQuotientForm.controls['q21'].value,
    };
    this.empathyQuotientAssessment.q22 = {
      answer: this.empathyQuotientForm.controls['q22'].value,
    };
    this.empathyQuotientAssessment.q23 = {
      answer: this.empathyQuotientForm.controls['q23'].value,
    };
    this.empathyQuotientAssessment.q24 = {
      answer: this.empathyQuotientForm.controls['q24'].value,
    };
    this.empathyQuotientAssessment.q25 = {
      answer: this.empathyQuotientForm.controls['q25'].value,
    };
    this.empathyQuotientAssessment.q26 = {
      answer: this.empathyQuotientForm.controls['q26'].value,
    };
    this.empathyQuotientAssessment.q27 = {
      answer: this.empathyQuotientForm.controls['q27'].value,
    };
    this.empathyQuotientAssessment.q28 = {
      answer: this.empathyQuotientForm.controls['q28'].value,
    };
    this.empathyQuotientAssessment.q29 = {
      answer: this.empathyQuotientForm.controls['q29'].value,
    };
    this.empathyQuotientAssessment.q30 = {
      answer: this.empathyQuotientForm.controls['q30'].value,
    };
    this.empathyQuotientAssessment.q31 = {
      answer: this.empathyQuotientForm.controls['q31'].value,
    };
    this.empathyQuotientAssessment.q32 = {
      answer: this.empathyQuotientForm.controls['q32'].value,
    };
    this.empathyQuotientAssessment.q33 = {
      answer: this.empathyQuotientForm.controls['q33'].value,
    };
    this.empathyQuotientAssessment.q34 = {
      answer: this.empathyQuotientForm.controls['q34'].value,
    };
    this.empathyQuotientAssessment.q35 = {
      answer: this.empathyQuotientForm.controls['q35'].value,
    };
    this.empathyQuotientAssessment.q36 = {
      answer: this.empathyQuotientForm.controls['q36'].value,
    };
    this.empathyQuotientAssessment.q37 = {
      answer: this.empathyQuotientForm.controls['q37'].value,
    };
    this.empathyQuotientAssessment.q38 = {
      answer: this.empathyQuotientForm.controls['q38'].value,
    };
    this.empathyQuotientAssessment.q39 = {
      answer: this.empathyQuotientForm.controls['q39'].value,
    };
    this.empathyQuotientAssessment.q40 = {
      answer: this.empathyQuotientForm.controls['q40'].value,
    };
    this.empathyQuotientAssessment.q41 = {
      answer: this.empathyQuotientForm.controls['q41'].value,
    };
    this.empathyQuotientAssessment.q42 = {
      answer: this.empathyQuotientForm.controls['q42'].value,
    };
    this.empathyQuotientAssessment.q43 = {
      answer: this.empathyQuotientForm.controls['q43'].value,
    };
    this.empathyQuotientAssessment.q44 = {
      answer: this.empathyQuotientForm.controls['q44'].value,
    };
    this.empathyQuotientAssessment.q45 = {
      answer: this.empathyQuotientForm.controls['q45'].value,
    };
    this.empathyQuotientAssessment.q46 = {
      answer: this.empathyQuotientForm.controls['q46'].value,
    };
    this.empathyQuotientAssessment.q47 = {
      answer: this.empathyQuotientForm.controls['q47'].value,
    };
    this.empathyQuotientAssessment.q48 = {
      answer: this.empathyQuotientForm.controls['q48'].value,
    };
    this.empathyQuotientAssessment.q49 = {
      answer: this.empathyQuotientForm.controls['q49'].value,
    };
    this.empathyQuotientAssessment.q50 = {
      answer: this.empathyQuotientForm.controls['q50'].value,
    };
    this.empathyQuotientAssessment.q51 = {
      answer: this.empathyQuotientForm.controls['q51'].value,
    };
    this.empathyQuotientAssessment.q52 = {
      answer: this.empathyQuotientForm.controls['q52'].value,
    };
    this.empathyQuotientAssessment.q53 = {
      answer: this.empathyQuotientForm.controls['q53'].value,
    };
    this.empathyQuotientAssessment.q54 = {
      answer: this.empathyQuotientForm.controls['q54'].value,
    };
    this.empathyQuotientAssessment.q55 = {
      answer: this.empathyQuotientForm.controls['q55'].value,
    };
    this.empathyQuotientAssessment.q56 = {
      answer: this.empathyQuotientForm.controls['q56'].value,
    };
    this.empathyQuotientAssessment.q57 = {
      answer: this.empathyQuotientForm.controls['q57'].value,
    };
    this.empathyQuotientAssessment.q58 = {
      answer: this.empathyQuotientForm.controls['q58'].value,
    };
    this.empathyQuotientAssessment.q59 = {
      answer: this.empathyQuotientForm.controls['q59'].value,
    };
    this.empathyQuotientAssessment.q60 = {
      answer: this.empathyQuotientForm.controls['q60'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.empathyQuotientAssessment.accessCode = this.accessCode;

      this.empathyQuotientApiService
        .addGuestEmpathyQuotient(this.empathyQuotientAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Empathy Quotient Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Empathy Quotient Assessment'
            );
          }
        );
    } else {
      this.empathyQuotientApiService
        .addEmpathyQuotient(this.empathyQuotientAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Empathy Quotient Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Empathy Quotient Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. I can easily tell if someone else wants to enter a conversation. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message: '2. I prefer animals to humans. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. I try to keep up with the current trends and fashions. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          "4. I find it difficult to explain to others things that I understand easily, when they don't understand it the first time. is required",
      },
    ],
    q5: [{ type: 'required', message: '5. I dream most nights. is required' }],
    q6: [
      {
        type: 'required',
        message: '6. I really enjoy caring for other people. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. I try to solve my own problems rather than discussing them with others. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. I find it hard to know what to do in a social situation. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. I am at my best first thing in the morning. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. People often tell me that I went too far in driving my point home in a discussion. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          "11. It doesn't bother me too much if I am late meeting a friend. is required",
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. Friendships and relationships are just too difficult, so I tend not to bother with them. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. I would never break a law, no matter how minor. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I often find it difficult to judge if something is rude or polite. is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. In a conversation, I tend to focus on my own thoughts rather than on what my listener might be thinking. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message: '16. I prefer practical jokes to verbal humor. is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. I live life for today rather than the future. is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. When I was a child, I enjoyed cutting up worms to see what would happen. is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          '19. I can pick up quickly if someone says one thing but means another. is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. I tend to have very strong opinions about morality. is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          '21. It is hard for me to see why some things upset people so much. is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          "22. I find it easy to put myself in somebody else's shoes. is required",
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. I think that good manners are the most important thing a parent can teach their child. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '24. I like to do things on the spur of the moment. is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. I am good at predicting how someone will feel. is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message:
          '26. I am quick to spot when someone in a group is feeling awkward or uncomfortable. is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message:
          "27. If I say something that someone else is offended by, I think that that's their problem, not mine. is required",
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          "28. If anyone asked me if I liked their haircut, I would reply truthfully, even if I didn't like it. is required",
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          "29. I can't always see why someone should have felt offended by a remark. is required",
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. People often tell me that I am very unpredictable. is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message:
          '31. I enjoy being the center of attention at any social gathering. is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message: "32. Seeing people cry doesn't really upset me. is required",
      },
    ],
    q33: [
      {
        type: 'required',
        message: '33. I enjoy having discussions about politics. is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message:
          '34. I am very blunt, which some people take to be rudeness, even though this is unintentional. is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message: "35. I don't find social situations confusing. is required",
      },
    ],
    q36: [
      {
        type: 'required',
        message:
          '36. Other people tell me I am good at understanding how they are feeling and what they are thinking. is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message:
          '37. When I talk to people, I tend to talk about their experiences rather than my own. is required',
      },
    ],
    q38: [
      {
        type: 'required',
        message: '38. It upsets me to see an animal in pain. is required',
      },
    ],
    q39: [
      {
        type: 'required',
        message:
          "39. I am able to make decisions without being influenced by people's feelings. is required",
      },
    ],
    q40: [
      {
        type: 'required',
        message:
          "40. I can't relax until I have done everything I had planned to do that day. is required",
      },
    ],
    q41: [
      {
        type: 'required',
        message:
          '41. I can easily tell if someone else is interested or bored with what I am saying. is required',
      },
    ],
    q42: [
      {
        type: 'required',
        message:
          '42. I get upset if I see people suffering on news programs. is required',
      },
    ],
    q43: [
      {
        type: 'required',
        message:
          '43. Friends usually talk to me about their problems as they say that I am very understanding. is required',
      },
    ],
    q44: [
      {
        type: 'required',
        message:
          "44. I can sense if I am intruding, even if the other person doesn't tell me. is required",
      },
    ],
    q45: [
      {
        type: 'required',
        message:
          '45. I often start new hobbies, but quickly become bored with them and move on to something else. is required',
      },
    ],
    q46: [
      {
        type: 'required',
        message:
          '46. People sometimes tell me that I have gone too far with teasing. is required',
      },
    ],
    q47: [
      {
        type: 'required',
        message:
          '47. I would be too nervous to go on a big rollercoaster. is required',
      },
    ],
    q48: [
      {
        type: 'required',
        message:
          "48. Other people often say that I am insensitive, though I don't always see why. is required",
      },
    ],
    q49: [
      {
        type: 'required',
        message:
          '49. If I see a stranger in a group, I think that it is up to them to make an effort to join in. is required',
      },
    ],
    q50: [
      {
        type: 'required',
        message:
          '50. I usually stay emotionally detached when watching a film. is required',
      },
    ],
    q51: [
      {
        type: 'required',
        message:
          '51. I like to be very organized in day-to-day life and often makes lists of the chores I have to do. is required',
      },
    ],
    q52: [
      {
        type: 'required',
        message:
          '52. I can tune into how someone else feels rapidly and intuitively. is required',
      },
    ],
    q53: [
      {
        type: 'required',
        message: "53. I don't like to take risks. is required",
      },
    ],
    q54: [
      {
        type: 'required',
        message:
          '54. I can easily work out what another person might want to talk about. is required',
      },
    ],
    q55: [
      {
        type: 'required',
        message:
          '55. I can tell if someone is masking their true emotion. is required',
      },
    ],
    q56: [
      {
        type: 'required',
        message:
          '56. Before making a decision, I always weigh up the pros and cons. is required',
      },
    ],
    q57: [
      {
        type: 'required',
        message:
          "57. I don't consciously work out the rules of social situations. is required",
      },
    ],
    q58: [
      {
        type: 'required',
        message:
          '58. I am good at predicting what someone will do. is required',
      },
    ],
    q59: [
      {
        type: 'required',
        message:
          "59. I tend to get emotionally involved with a friend's problems. is required",
      },
    ],
    q60: [
      {
        type: 'required',
        message:
          "60. I can usually appreciate the other person's viewpoint, even if I don't agree with it. is required",
      },
    ],
  };

  private buildForm() {
    this.empathyQuotientForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q46: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q47: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q48: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q49: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q50: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q51: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q52: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q53: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q54: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q55: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q56: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q57: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q58: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q59: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q60: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.empathyQuotientForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.empathyQuotientForm) {
      return;
    }
    const form = this.empathyQuotientForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
