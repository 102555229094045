import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { Case, DataTablesModel, IDocument, Patient } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddDocumentDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-case-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() case: Case;
  @Input() patient: Patient;
  @Input() hasCaseDocumentsViewAccess: boolean;
  @Input() hasCaseDocumentsAddAccess: boolean;
  @Input() hasCaseDocumentsDeleteAccess: boolean;

  @Input() documentType: string;
  @Input() loadDischargeDocs: Subject<boolean> = new Subject<boolean>();
  @Output() output = new EventEmitter<any>();

  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  displayedColumns = ['fileName', 'fileType', 'uploadedBy', 'actions'];
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public documentApiService: DocumentHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.data.per_page = 10;
    this.data.page = 0;

    if (this.documentType !== 'DISCHARGE') {
      this.loadDocuments(this.data.per_page, this.data.page);
    } else {
      this.loadDischargeDocuments(this.data.per_page, this.data.page);
    }

    this.loadDischargeDocs.subscribe((loadDischargeDocs) => {
      if (loadDischargeDocs) {
        this.documentType = 'DISCHARGE';
        this.loadDischargeDocuments(this.data.per_page, this.data.page);
      }
    });
  }

  addDocument() {
    const dialogRef = this.dialog.open(AddDocumentDialogComponent, {
      data: {
        action: 'ADD',
        Document: null,
        patientId: this.case.patientId,
        caseId: this.case.id,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((fileType) => {
      if (fileType) {
        this.toastMessageService.displaySuccessMessage(
          'Document has been uploaded.'
        );
        this.documentType = '';
        this.loadDocuments(this.data.per_page, this.data.page);
        if (fileType === 'Discharge') {
          let payload: any = {};
          payload.eventType = 'DISCHARGE_DOCUMENT_ADDED';
          this.output.emit(payload);
        }
      }
    });
  }

  deleteDocument(Document: IDocument) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this document?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.isLoadingResults = true;
        this.documentApiService
          .deleteDocument(this.case.id, Document)
          .subscribe(
            () => {
              this.isLoadingResults = false;
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.toastMessageService.displaySuccessMessage(
                'Document has been deleted.'
              );
              this.loadDocuments(this.data.per_page, this.data.page);
            },
            (error) => {
              this.isLoadingResults = false;
            }
          );
      } else {
        this.isLoadingResults = false;
      }
    });
  }

  downloadFile(caseId: string, fileId: string) {
    this.isLoadingResults = true;

    this.documentApiService.downloadDocument(caseId, fileId).subscribe(
      (response: any) => {
        this.isLoadingResults = false;
        let blob: any = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this.isLoadingResults = false;
      }
    );
  }

  public loadDocuments(pageSize: number, pageIndex: number) {
    this.isLoadingResults = true;
    this.apiError = false;

    this.documentApiService
      .getCaseDocuments(this.case.id, this.case.patientId, pageSize, pageIndex)
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  public loadDischargeDocuments(pageSize: number, pageIndex: number) {
    this.isLoadingResults = true;
    this.apiError = false;

    this.documentApiService
      .getDischargeDocuments(
        this.case.id,
        this.case.patientId,
        pageSize,
        pageIndex
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadDocuments(this.data.per_page, this.data.page);
  }

  onFilesUploaded(filesUploaded: any[]) {
    this.loadDocuments(this.data.per_page, this.data.page);
  }
}
