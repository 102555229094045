import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService, ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-assessment-edit-followup',
  templateUrl: './assessment-edit-followup.component.html',
  styleUrls: ['./assessment-edit-followup.component.css'],
})
export class AssessmentEditFollowupComponent implements OnInit {
  goal: any;

  followUpIndex: number;
  loggedInUserDetails: any;
  followUpNotesForm: FormGroup;
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssessmentEditFollowupComponent>,
    private userService: CoreService,
    private formBuilder: FormBuilder,
    private goalsApiService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {
    this.goal = data.goal;
    this.followUpIndex = data.index;
  }

  ngOnInit(): void {
    this.getLoggedInUser();
    this.buildForm();
  }

  buildForm() {
    this.followUpNotesForm = this.formBuilder.group({
      followUpNote: new FormControl(
        this.goal.followUpDetails[this.followUpIndex].followUpNote,
        Validators.required
      ),
    });
  }

  getLoggedInUser() {
    this.loggedInUserDetails = this.userService.getUserDetails();
  }

  submitFollowUpNotesForm() {
    if (this.followUpNotesForm.invalid) {
      return;
    }

    this.processing = true;

    // Making a copy by value
    let newGoal = JSON.parse(JSON.stringify(this.goal));

    newGoal.followUpDetails[this.followUpIndex].followUpNote =
      this.followUpNotesForm.controls['followUpNote'].value;

    newGoal.followUpDetails[this.followUpIndex].followUpNoteDate = new Date();

    newGoal.followUpDetails[this.followUpIndex].followUpDoneBy =
      this.loggedInUserDetails.firstName +
      ' ' +
      this.loggedInUserDetails.lastName;

    this.goalsApiService.updateMemberGoal(newGoal).subscribe(
      (response) => {
        this.processing = false;
        this.toastMessageService.displaySuccessMessage(
          'Follow up notes have been updated.'
        );
        this.dialogRef.close({
          response: 'success',
          newGoal: newGoal,
        });
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to update follow up notes.'
        );
      }
    );
  }
}
