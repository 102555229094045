import { Component, OnInit } from '@angular/core';
import { Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';
import { EmploymentStatus } from 'src/app/shared/utilities/billing/employmentStatus';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import { PreferredLanguage } from 'src/app/shared/utilities/billing/preferredLanguages';

@Component({
  selector: 'app-patient-self-information',
  templateUrl: './patient-self-information.component.html',
  styleUrl: './patient-self-information.component.css',
})
export class PatientSelfInformationComponent implements OnInit {
  isProcessing: boolean = false;
  showMemberInformation: boolean = false;
  patient: Patient;

  // List of Ids
  employmentStatusList = EmploymentStatus;
  preferredLanguageList = PreferredLanguage;
  maritalStausList = MarriedStatus;

  constructor(
    private patientApiService: PatientService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.loadMyDetails();
  }

  loadMyDetails() {
    this.isProcessing = true;
    this.patientApiService.getMyDetails().subscribe({
      next: (response) => {
        this.patient = response.data;
        this.isProcessing = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load details'
        );
        this.isProcessing = false;
      },
    });
  }

  // Get statsus based on the list
  getEmploymentStatus() {
    let empStatusId = this.patient?.employmentStatus;

    return this.employmentStatusList
      .filter((emp) => emp.id === empStatusId)
      .at(0).description;
  }

  getPreferredLanguage() {
    let preferredLanguageId = this.patient?.preferredLanguage;

    return this.preferredLanguageList
      .filter((emp) => emp.id === preferredLanguageId)
      .at(0).description;
  }

  getMaritalStatus() {
    let maritalStatusId = this.patient?.maritalStatus;

    return this.maritalStausList
      .filter((emp) => emp.id === maritalStatusId)
      .at(0).description;
  }
}
