import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICows } from 'src/app/_models/assessments/addiction/cows.model';
import {
  ToastMessageService,
} from 'src/app/_services';
import { CowsApiService } from 'src/app/_services/assessments/addiction/cows.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-cows',
  templateUrl: './cows.component.html',
  styleUrl: './cows.component.css'
})
export class CowsComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public cowsApiService: CowsApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public cowsForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  cows: ICows;
  cowsError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.cowsForm, this.errors);

    this.loadCowsAssessment();
  }

  loadCowsAssessment() {
    this.cowsForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.cowsApiService
        .getCowsDetails(this.assessmentId)
        .subscribe((cows) => {
          if (cows.data) {

            this.cowsForm.controls['reasonForAssessment'].setValue(cows.data.reasonForAssessment);
            this.cowsForm.controls['restingPulseRate'].setValue(cows.data.restingPulseRate.answer);
            this.cowsForm.controls['giUpset'].setValue(cows.data.giUpset.answer);
            this.cowsForm.controls['sweating'].setValue(cows.data.sweating.answer);
            this.cowsForm.controls['tremor'].setValue(cows.data.tremor.answer);
            this.cowsForm.controls['restlessness'].setValue(cows.data.restlessness.answer);
            this.cowsForm.controls['yawning'].setValue(cows.data.yawning.answer);
            this.cowsForm.controls['pupilSize'].setValue(cows.data.pupilSize.answer);
            this.cowsForm.controls['anxietyIrritability'].setValue(cows.data.anxietyIrritability.answer);
            this.cowsForm.controls['boneJointAches'].setValue(cows.data.boneJointAches.answer);
            this.cowsForm.controls['goosefleshSkin'].setValue(cows.data.goosefleshSkin.answer);
            this.cowsForm.controls['runnyNoseOrTearing'].setValue(cows.data.runnyNoseOrTearing.answer);

            this.riskScore = cows.data.riskScore;
          }
        });
    }
  }
  onCowsFormSubmit() {
    if (this.cowsForm.invalid) {
      this.cowsForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.cows = Object.assign({}, this.cows);
    this.cows.patientId =
      this.cowsForm.controls['patientId'].value;
    this.cows.sessionId =
      this.cowsForm.controls['sessionId'].value;
    this.cows.assessmentRequestId =
      this.cowsForm.controls['assessmentRequestId'].value;

    this.cows.reasonForAssessment = this.cowsForm.controls['reasonForAssessment'].value;
    this.cows.restingPulseRate = {
      answer:
        this.cowsForm.controls['restingPulseRate'].value
    };
    this.cows.giUpset = {
      answer:
        this.cowsForm.controls['giUpset'].value
    };
    this.cows.sweating = {
      answer:
        this.cowsForm.controls['sweating'].value
    };
    this.cows.tremor = {
      answer:
        this.cowsForm.controls['tremor'].value
    };
    this.cows.restlessness = {
      answer:
        this.cowsForm.controls['restlessness'].value
    };
    this.cows.yawning = {
      answer:
        this.cowsForm.controls['yawning'].value
    };
    this.cows.pupilSize = {
      answer:
        this.cowsForm.controls['pupilSize'].value
    };
    this.cows.anxietyIrritability = {
      answer:
        this.cowsForm.controls['anxietyIrritability'].value
    };
    this.cows.boneJointAches = {
      answer:
        this.cowsForm.controls['boneJointAches'].value
    };
    this.cows.goosefleshSkin = {
      answer:
        this.cowsForm.controls['goosefleshSkin'].value
    };
    this.cows.runnyNoseOrTearing = {
      answer:
        this.cowsForm.controls['runnyNoseOrTearing'].value
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.cows.accessCode = this.accessCode;

      this.cowsApiService
        .addGuestLinkCows(this.cows)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Clinical Opiate Withdrawal Scale Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Clinical Opiate Withdrawal Scale Assessment'
            );
          }
        );
    } else {
      this.cowsApiService
        .addCows(this.cows)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Clinical Opiate Withdrawal Scale Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Clinical Opiate Withdrawal Scale Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'reasonForAssessment': [
      { type: 'required', message: 'Reason For Assessment is required' },
    ],
    'restingPulseRate': [
      { type: 'required', message: 'Resting Pulse Rate is required' },
    ],
    'giUpset': [
      { type: 'required', message: 'GI Upset is required' },
    ],
    'sweating': [
      { type: 'required', message: 'Sweating is required' },
    ],
    'tremor': [
      { type: 'required', message: 'Tremor is required' },
    ],
    'restlessness': [
      { type: 'required', message: 'Restlessness is required' },
    ],
    'yawning': [
      { type: 'required', message: 'Yawning is required' },
    ],
    'pupilSize': [
      { type: 'required', message: 'Pupil size is required' },
    ],
    'anxietyIrritability': [
      { type: 'required', message: 'Anxiety or Irritability is required' },
    ],
    'boneJointAches': [
      { type: 'required', message: 'Bone or Joint aches is required' },
    ],
    'goosefleshSkin': [
      { type: 'required', message: 'Gooseflesh skin is required' },
    ],
    'runnyNoseOrTearing': [
      { type: 'required', message: 'Runny nose or tearing is required' },
    ],
  };

  private buildForm() {
    this.cowsForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      reasonForAssessment: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      restingPulseRate: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      giUpset: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sweating: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      tremor: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      restlessness: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      yawning: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      pupilSize: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      anxietyIrritability: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      boneJointAches: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      goosefleshSkin: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      runnyNoseOrTearing: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.cowsForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.cowsForm) {
      return;
    }
    const form = this.cowsForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
