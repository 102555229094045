<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">
      Add {{ attemptCount }} Referral
    </p>
    <p matDialogTitle *ngIf="action === 'EDIT'">
      Edit {{ attemptCount }} Referral
    </p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="addAttemptForm">
    <div class="row">
      <!-- Attempt Date & Time -->
      <div class="col-md-6">
        <label class="labelText">
          Attempt Date and Time <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <input
            formControlName="attemptDateAndTime"
            matInput
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Date Time"
          />
          <owl-date-time #dt1></owl-date-time>
          <mat-error
            *ngIf="addAttemptForm.controls['attemptDateAndTime'].invalid"
          >
            Please enter a valid attempt Date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Attempt Status -->
      <div class="col-md-6">
        <label class="labelText">
          Attempt Result <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="attemptResult"
            placeholder="Attempt Result"
          >
            <mat-option
              *ngFor="let result of attemptResultsList"
              [value]="result"
            >
              {{ result }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addAttemptForm.controls['attemptResult'].invalid">
            Please select the attempt result
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Appointment With -->
      <div class="col-md-12">
        <label class="labelBold">
          Attempted By <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="recordedByUserId"
            (selectionChange)="userSelected()"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Name"
                noEntriesFoundLabel="No matching user found"
                [(ngModel)]="userName"
                (ngModelChange)="filterAllActiveCaseManagers()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let user of filteredCareProvidersList"
              [value]="user.id"
            >
              {{ user.lastName }}, {{ user.firstName }}
              <span *ngIf="user.emailAddress"> | </span>
              <span *ngIf="user.emailAddress" style="font-style: italic">
                {{ user.emailAddress }}
              </span>
              <span *ngIf="user.phoneNumber"> | </span>
              <span *ngIf="user.phoneNumber" style="font-style: italic">
                {{ user.phoneNumber | phoneFormat }}
              </span>
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="addAttemptForm.controls['recordedByUserId'].invalid"
          >
            Please select the user who made this attempt
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Attempt Notes -->
      <div class="col-md-12">
        <label class="labelBold">Attempt Notes</label>
        <mat-form-field appearance="outline">
          <textarea
            rows="3"
            matInput
            formControlName="attemptNote"
            spellcheck="true"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>
      </div>

      <!-- Submit -->
      <div class="col-md-12" style="text-align: right">
        <button
          mat-raised-button
          type="button"
          color="primary"
          [disabled]="addAttemptForm.invalid"
          (click)="addRecord()"
        >
          {{ action === 'ADD' ? 'Add Record' : 'Update Record' }}
        </button>
      </div>
    </div>
  </form>
</div>
