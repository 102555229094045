<div class="padding:10px">
  <form
    [formGroup]="treatmentplanprofileForm"
    (ngSubmit)="onTreatmentPlanProfileFormSubmit()"
  >
    <div class="row">
      <!-- Programs -->
      <div class="col-md-12">
        <label class="labelText">
          Programs <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="programs"
            multiple
            placeholder="Programs"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-option
                  class="mat-elevation-z2 mat-chip-active"
                  *ngFor="
                    let record of treatmentplanprofileForm.controls['programs']
                      .value;
                    let i = index
                  "
                  (removed)="removeSelectedOption(record, i)"
                >
                  {{ record }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </mat-chip-listbox>
            </mat-select-trigger>
            <ng-container>
              <mat-option
                *ngFor="let availableOption of programOptions"
                [value]="availableOption"
              >
                {{ availableOption }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="treatmentplanprofileForm.controls['programs'].invalid"
          >
            Please select atleast one Program
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Plan Period Days -->
      <div class="col-md-6">
        <label class="labelText">
          Plan Period Days <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Plan Period Days"
            formControlName="planPeriodDays"
            name="planPeriodDays"
            required
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="treatmentplanprofileForm.controls['planPeriodDays'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Next Review Date -->
      <div class="col-md-6">
        <label class="labelText">
          Next Review Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="nextReviewDate"
            matInput
            [matDatepicker]="nextReviewDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="nextReviewDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #nextReviewDatePicker></mat-datepicker>
          <mat-error
            *ngIf="treatmentplanprofileForm.controls['nextReviewDate'].invalid"
          >
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Next Review Date -->
      <div class="col-md-6">
        <label class="labelText">
          Client Participated In Tx Plan Development
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="clientParticipatedInTxPlanDevelopment"
            placeholder="Client Participated In Tx Plan Development"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentplanprofileForm.controls[
                'clientParticipatedInTxPlanDevelopment'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="selectedPlan.status === 'Active'"
      >
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentplanprofileForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
