<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Document Preview</p>

    <button mat-mini-fab style="background-color: transparent; color: #555555" aria-label="close dialog"
      mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content style="height: 900px; width: 100%">
    <ng2-pdfjs-viewer [download]="true" #pdfViewerOnDemand></ng2-pdfjs-viewer>
    <ng-container *ngIf="fileType === 'image'">
      <img [src]="imageUrl" alt="Document Image" style="max-width: 100%; max-height: 400px;" />
    </ng-container>
    <ng-container *ngIf="fileType === 'html'">
      <div [innerHTML]="htmlContent" style="overflow: auto; max-width: 100%; max-height: 400px;"></div>
    </ng-container>
  </div>
</div>