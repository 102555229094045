import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { subMonths } from 'date-fns';
import { SwycEighteenMonths, SwycFifteenMonths, SwycFortyEightMonths, SwycFourMonths, SwycNineMonths, SwycSixMonths, SwycSixtyMonths, SwycThirtyMonths, SwycThirtySixMonths, SwycTwelveMonths, SwycTwentyFourMonths, SwycTwoMonths } from 'src/app/_models/assessments/general/swyc.model';
import {
  PatientService,
  ToastMessageService
} from 'src/app/_services/';
import {
  SwycApiService
} from 'src/app/_services/assessments/general/swyc.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs, calculateAgeInMonths, formatDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-survey-wellbeing-of-young-children',
  templateUrl: './survey-wellbeing-of-young-children.component.html',
  styleUrls: ['./survey-wellbeing-of-young-children.component.css']
})
export class SurveyOfWellbeingOfYoungChildrenComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public swycAssessmentApiService: SwycApiService,
    public patientApiService: PatientService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public swycForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  // swycAssessment: ISwyc;
  swycTwoMonths: SwycTwoMonths;
  swycFourMonths: SwycFourMonths;
  swycSixMonths: SwycSixMonths;
  swycNineMonths: SwycNineMonths;
  swycTwelveMonths: SwycTwelveMonths;
  swycFifteenMonths: SwycFifteenMonths;
  swycEighteenMonths: SwycEighteenMonths;
  swycTwentyFourMonths: SwycTwentyFourMonths;
  swycThirtyMonths: SwycThirtyMonths;
  swycThirtySixMonths: SwycThirtySixMonths;
  swycFortyEightMonths: SwycFortyEightMonths;
  swycSixtyMonths: SwycSixtyMonths;

  swycAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = true;
  disableFormElements: boolean = false;
  selectedAgeGroup: string;
  selectedDob: string;
  hideHeader: boolean = false;

  initial = new FormControl('');
  details = new FormControl('');
  todaysDate = new Date();
  minimumDate = subMonths(new Date(), 60);

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }

    if (!this.assessmentId) {
      this.hideHeader = false;
      this.processing = false;

      this.selectedAgeGroup = "2months";
      this.buildForm(this.selectedAgeGroup);
      this.errorHandler.handleErrors(this.swycForm, this.errors);

    } else {
      this.hideHeader = true;
      this.loadSwyc();
    }
  }

  loadSwyc() {
    if (this.assessmentId) {
      this.swycAssessmentApiService
        .getSwycDetails(this.assessmentId)
        .subscribe((swycAssessment) => {
          if (swycAssessment.data) {
            this.selectedAgeGroup = swycAssessment.data.ageGroup;
            this.buildForm(swycAssessment.data.ageGroup);
            this.setSwycData(swycAssessment.data);
            this.riskScore = swycAssessment.data.riskScore;
            this.errorHandler.handleErrors(this.swycForm, this.errors);
            this.processing = false;
          }
        });
    }
  }
  onSwycFormSubmit() {
    if (this.swycForm.invalid) {
      this.swycForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    let formObjectToSubmit = this.getFormObjectToSubmit(this.selectedAgeGroup);
    // Determine if it is a guest url or regular
    if (this.accessCode) {
      formObjectToSubmit.accessCode = this.accessCode;

      this.swycAssessmentApiService
        .addGuestSwyc(formObjectToSubmit)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Survey of Wellbeing of Young Children Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Survey of Wellbeing of Young Children Assessment'
            );
          }
        );
    } else {
      this.swycAssessmentApiService
        .addSwyc(formObjectToSubmit)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Survey of Wellbeing of Young Children Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Survey of Wellbeing of Young Children Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
  };

  private buildForm(selectedAgeGroup: string) {

    let formattedDob = formatDate(new Date(this.selectedDob));
    let previouslySelectedChildDob = this.swycForm?.controls['childDob']?.value;

    if (selectedAgeGroup == "2months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(previouslySelectedChildDob),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42TwoMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),

      });
    } else if (selectedAgeGroup == "4months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42FourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "6months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42SixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "9months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34NineMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "12months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34TwelveMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "15months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34FifteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "18months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q43EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q44EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q45EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q46EighteenMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "24months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q43TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q44TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q45TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q46TwentyFourMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "30months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q40ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q41ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q42ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q43ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q44ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q45ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q46ThirtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "36months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39ThirtySixMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "48months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39FortyEightMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else if (selectedAgeGroup == "60months") {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          previouslySelectedChildDob, Validators.compose([])
        ),
        ageGroup: new FormControl(
          this.selectedAgeGroup,
          Validators.compose([Validators.required])
        ),
        q1SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q2SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q3SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q4SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q5SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q6SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q7SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q8SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q9SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q10SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q11SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q12SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q13SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q14SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q15SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q16SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q17SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q18SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q19SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q20SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q21SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q22SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q23SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q24SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q25SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q26SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q27SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q28SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q29SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q30SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q31SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q32SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q33SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q34SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q35SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q36SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q37SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q38SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
        q39SixtyMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
          Validators.required,
        ])),
      });
    } else {
      this.swycForm = this.formBuilder.group({
        id: new FormControl(null, Validators.compose([])),
        organizationId: new FormControl('', Validators.compose([])),
        patientId: new FormControl(
          { value: this.patientId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        sessionId: new FormControl({
          value: this.sessionId,
          disabled: this.disableFormElements,
        }),
        assessmentRequestId: new FormControl(
          { value: this.assessmentRequestId, disabled: this.disableFormElements },
          Validators.compose([])
        ),
        childDob: new FormControl(
          null
        ),
        ageGroup: new FormControl(
          null,
          Validators.compose([Validators.required])
        )
      });
    }
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  onChangeDob(stepper: MatStepper) {
    this.selectedDob = this.swycForm.controls['childDob'].value;
    let ageInMonths = calculateAgeInMonths(this.selectedDob);
    if (ageInMonths >= 1 && ageInMonths <= 3) {
      this.selectedAgeGroup = "2months";
    } else if (ageInMonths >= 4 && ageInMonths <= 5) {
      this.selectedAgeGroup = "4months";
    } else if (ageInMonths >= 6 && ageInMonths <= 8) {
      this.selectedAgeGroup = "6months";
    } else if (ageInMonths >= 9 && ageInMonths <= 11) {
      this.selectedAgeGroup = "9months";
    } else if (ageInMonths >= 12 && ageInMonths <= 14) {
      this.selectedAgeGroup = "12months";
    } else if (ageInMonths >= 15 && ageInMonths <= 17) {
      this.selectedAgeGroup = "15months";
    } else if (ageInMonths >= 18 && ageInMonths <= 22) {
      this.selectedAgeGroup = "18months";
    } else if (ageInMonths >= 23 && ageInMonths <= 28) {
      this.selectedAgeGroup = "24months";
    } else if (ageInMonths >= 29 && ageInMonths <= 34) {
      this.selectedAgeGroup = "30months";
    } else if (ageInMonths >= 35 && ageInMonths <= 46) {
      this.selectedAgeGroup = "36months";
    } else if (ageInMonths >= 47 && ageInMonths <= 58) {
      this.selectedAgeGroup = "48months";
    } else if (ageInMonths >= 59 && ageInMonths <= 65) {
      this.selectedAgeGroup = "60months";
    } else {
      this.selectedAgeGroup = "ineligible";
    }
    this.buildForm(this.selectedAgeGroup);
    if (this.selectedAgeGroup !== "ineligible") {
      this.swycForm.valueChanges.subscribe(() =>
        this.onValueChanged()
      );
    }
    stepper.next();
  }

  private onValueChanged() {
    // for (let el in this.swycForm.controls) {
    //   if (this.swycForm.controls[el].errors) {
    //     console.log(el)
    //   }
    // }

    if (!this.swycForm) {
      return;
    }
    const form = this.swycForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  private setSwycData(data) {
    if (data.swycTwoMonths) {
      this.swycForm.controls['q1TwoMonths'].setValue(data.swycTwoMonths.q1TwoMonths.answer);
      this.swycForm.controls['q2TwoMonths'].setValue(data.swycTwoMonths.q2TwoMonths.answer);
      this.swycForm.controls['q3TwoMonths'].setValue(data.swycTwoMonths.q3TwoMonths.answer);
      this.swycForm.controls['q4TwoMonths'].setValue(data.swycTwoMonths.q4TwoMonths.answer);
      this.swycForm.controls['q5TwoMonths'].setValue(data.swycTwoMonths.q5TwoMonths.answer);
      this.swycForm.controls['q6TwoMonths'].setValue(data.swycTwoMonths.q6TwoMonths.answer);
      this.swycForm.controls['q7TwoMonths'].setValue(data.swycTwoMonths.q7TwoMonths.answer);
      this.swycForm.controls['q8TwoMonths'].setValue(data.swycTwoMonths.q8TwoMonths.answer);
      this.swycForm.controls['q9TwoMonths'].setValue(data.swycTwoMonths.q9TwoMonths.answer);
      this.swycForm.controls['q10TwoMonths'].setValue(data.swycTwoMonths.q10TwoMonths.answer);
      this.swycForm.controls['q11TwoMonths'].setValue(data.swycTwoMonths.q11TwoMonths.answer);
      this.swycForm.controls['q12TwoMonths'].setValue(data.swycTwoMonths.q12TwoMonths.answer);
      this.swycForm.controls['q13TwoMonths'].setValue(data.swycTwoMonths.q13TwoMonths.answer);
      this.swycForm.controls['q14TwoMonths'].setValue(data.swycTwoMonths.q14TwoMonths.answer);
      this.swycForm.controls['q15TwoMonths'].setValue(data.swycTwoMonths.q15TwoMonths.answer);
      this.swycForm.controls['q16TwoMonths'].setValue(data.swycTwoMonths.q16TwoMonths.answer);
      this.swycForm.controls['q17TwoMonths'].setValue(data.swycTwoMonths.q17TwoMonths.answer);
      this.swycForm.controls['q18TwoMonths'].setValue(data.swycTwoMonths.q18TwoMonths.answer);
      this.swycForm.controls['q19TwoMonths'].setValue(data.swycTwoMonths.q19TwoMonths.answer);
      this.swycForm.controls['q20TwoMonths'].setValue(data.swycTwoMonths.q20TwoMonths.answer);
      this.swycForm.controls['q21TwoMonths'].setValue(data.swycTwoMonths.q21TwoMonths.answer);
      this.swycForm.controls['q22TwoMonths'].setValue(data.swycTwoMonths.q22TwoMonths.answer);
      this.swycForm.controls['q23TwoMonths'].setValue(data.swycTwoMonths.q23TwoMonths.answer);
      this.swycForm.controls['q24TwoMonths'].setValue(data.swycTwoMonths.q24TwoMonths.answer);
      this.swycForm.controls['q25TwoMonths'].setValue(data.swycTwoMonths.q25TwoMonths.answer);
      this.swycForm.controls['q26TwoMonths'].setValue(data.swycTwoMonths.q26TwoMonths.answer);
      this.swycForm.controls['q27TwoMonths'].setValue(data.swycTwoMonths.q27TwoMonths.answer);
      this.swycForm.controls['q28TwoMonths'].setValue(data.swycTwoMonths.q28TwoMonths.answer);
      this.swycForm.controls['q29TwoMonths'].setValue(data.swycTwoMonths.q29TwoMonths.answer);
      this.swycForm.controls['q30TwoMonths'].setValue(data.swycTwoMonths.q30TwoMonths.answer);
      this.swycForm.controls['q31TwoMonths'].setValue(data.swycTwoMonths.q31TwoMonths.answer);
      this.swycForm.controls['q32TwoMonths'].setValue(data.swycTwoMonths.q32TwoMonths.answer);
      this.swycForm.controls['q33TwoMonths'].setValue(data.swycTwoMonths.q33TwoMonths.answer);
      this.swycForm.controls['q34TwoMonths'].setValue(data.swycTwoMonths.q34TwoMonths.answer);
      this.swycForm.controls['q35TwoMonths'].setValue(data.swycTwoMonths.q35TwoMonths.answer);
      this.swycForm.controls['q36TwoMonths'].setValue(data.swycTwoMonths.q36TwoMonths.answer);
      this.swycForm.controls['q37TwoMonths'].setValue(data.swycTwoMonths.q37TwoMonths.answer);
      this.swycForm.controls['q38TwoMonths'].setValue(data.swycTwoMonths.q38TwoMonths.answer);
      this.swycForm.controls['q39TwoMonths'].setValue(data.swycTwoMonths.q39TwoMonths.answer);
      this.swycForm.controls['q40TwoMonths'].setValue(data.swycTwoMonths.q40TwoMonths.answer);
      this.swycForm.controls['q41TwoMonths'].setValue(data.swycTwoMonths.q41TwoMonths.answer);
      this.swycForm.controls['q42TwoMonths'].setValue(data.swycTwoMonths.q42TwoMonths.answer);
    } else if (data.swycFourMonths) {
      this.swycForm.controls['q1FourMonths'].setValue(data.swycFourMonths.q1FourMonths.answer);
      this.swycForm.controls['q2FourMonths'].setValue(data.swycFourMonths.q2FourMonths.answer);
      this.swycForm.controls['q3FourMonths'].setValue(data.swycFourMonths.q3FourMonths.answer);
      this.swycForm.controls['q4FourMonths'].setValue(data.swycFourMonths.q4FourMonths.answer);
      this.swycForm.controls['q5FourMonths'].setValue(data.swycFourMonths.q5FourMonths.answer);
      this.swycForm.controls['q6FourMonths'].setValue(data.swycFourMonths.q6FourMonths.answer);
      this.swycForm.controls['q7FourMonths'].setValue(data.swycFourMonths.q7FourMonths.answer);
      this.swycForm.controls['q8FourMonths'].setValue(data.swycFourMonths.q8FourMonths.answer);
      this.swycForm.controls['q9FourMonths'].setValue(data.swycFourMonths.q9FourMonths.answer);
      this.swycForm.controls['q10FourMonths'].setValue(data.swycFourMonths.q10FourMonths.answer);
      this.swycForm.controls['q11FourMonths'].setValue(data.swycFourMonths.q11FourMonths.answer);
      this.swycForm.controls['q12FourMonths'].setValue(data.swycFourMonths.q12FourMonths.answer);
      this.swycForm.controls['q13FourMonths'].setValue(data.swycFourMonths.q13FourMonths.answer);
      this.swycForm.controls['q14FourMonths'].setValue(data.swycFourMonths.q14FourMonths.answer);
      this.swycForm.controls['q15FourMonths'].setValue(data.swycFourMonths.q15FourMonths.answer);
      this.swycForm.controls['q16FourMonths'].setValue(data.swycFourMonths.q16FourMonths.answer);
      this.swycForm.controls['q17FourMonths'].setValue(data.swycFourMonths.q17FourMonths.answer);
      this.swycForm.controls['q18FourMonths'].setValue(data.swycFourMonths.q18FourMonths.answer);
      this.swycForm.controls['q19FourMonths'].setValue(data.swycFourMonths.q19FourMonths.answer);
      this.swycForm.controls['q20FourMonths'].setValue(data.swycFourMonths.q20FourMonths.answer);
      this.swycForm.controls['q21FourMonths'].setValue(data.swycFourMonths.q21FourMonths.answer);
      this.swycForm.controls['q22FourMonths'].setValue(data.swycFourMonths.q22FourMonths.answer);
      this.swycForm.controls['q23FourMonths'].setValue(data.swycFourMonths.q23FourMonths.answer);
      this.swycForm.controls['q24FourMonths'].setValue(data.swycFourMonths.q24FourMonths.answer);
      this.swycForm.controls['q25FourMonths'].setValue(data.swycFourMonths.q25FourMonths.answer);
      this.swycForm.controls['q26FourMonths'].setValue(data.swycFourMonths.q26FourMonths.answer);
      this.swycForm.controls['q27FourMonths'].setValue(data.swycFourMonths.q27FourMonths.answer);
      this.swycForm.controls['q28FourMonths'].setValue(data.swycFourMonths.q28FourMonths.answer);
      this.swycForm.controls['q29FourMonths'].setValue(data.swycFourMonths.q29FourMonths.answer);
      this.swycForm.controls['q30FourMonths'].setValue(data.swycFourMonths.q30FourMonths.answer);
      this.swycForm.controls['q31FourMonths'].setValue(data.swycFourMonths.q31FourMonths.answer);
      this.swycForm.controls['q32FourMonths'].setValue(data.swycFourMonths.q32FourMonths.answer);
      this.swycForm.controls['q33FourMonths'].setValue(data.swycFourMonths.q33FourMonths.answer);
      this.swycForm.controls['q34FourMonths'].setValue(data.swycFourMonths.q34FourMonths.answer);
      this.swycForm.controls['q35FourMonths'].setValue(data.swycFourMonths.q35FourMonths.answer);
      this.swycForm.controls['q36FourMonths'].setValue(data.swycFourMonths.q36FourMonths.answer);
      this.swycForm.controls['q37FourMonths'].setValue(data.swycFourMonths.q37FourMonths.answer);
      this.swycForm.controls['q38FourMonths'].setValue(data.swycFourMonths.q38FourMonths.answer);
      this.swycForm.controls['q39FourMonths'].setValue(data.swycFourMonths.q39FourMonths.answer);
      this.swycForm.controls['q40FourMonths'].setValue(data.swycFourMonths.q40FourMonths.answer);
      this.swycForm.controls['q41FourMonths'].setValue(data.swycFourMonths.q41FourMonths.answer);
      this.swycForm.controls['q42FourMonths'].setValue(data.swycFourMonths.q42FourMonths.answer);
    } else if (data.swycSixMonths) {
      this.swycForm.controls['q1SixMonths'].setValue(data.swycSixMonths.q1SixMonths.answer);
      this.swycForm.controls['q2SixMonths'].setValue(data.swycSixMonths.q2SixMonths.answer);
      this.swycForm.controls['q3SixMonths'].setValue(data.swycSixMonths.q3SixMonths.answer);
      this.swycForm.controls['q4SixMonths'].setValue(data.swycSixMonths.q4SixMonths.answer);
      this.swycForm.controls['q5SixMonths'].setValue(data.swycSixMonths.q5SixMonths.answer);
      this.swycForm.controls['q6SixMonths'].setValue(data.swycSixMonths.q6SixMonths.answer);
      this.swycForm.controls['q7SixMonths'].setValue(data.swycSixMonths.q7SixMonths.answer);
      this.swycForm.controls['q8SixMonths'].setValue(data.swycSixMonths.q8SixMonths.answer);
      this.swycForm.controls['q9SixMonths'].setValue(data.swycSixMonths.q9SixMonths.answer);
      this.swycForm.controls['q10SixMonths'].setValue(data.swycSixMonths.q10SixMonths.answer);
      this.swycForm.controls['q11SixMonths'].setValue(data.swycSixMonths.q11SixMonths.answer);
      this.swycForm.controls['q12SixMonths'].setValue(data.swycSixMonths.q12SixMonths.answer);
      this.swycForm.controls['q13SixMonths'].setValue(data.swycSixMonths.q13SixMonths.answer);
      this.swycForm.controls['q14SixMonths'].setValue(data.swycSixMonths.q14SixMonths.answer);
      this.swycForm.controls['q15SixMonths'].setValue(data.swycSixMonths.q15SixMonths.answer);
      this.swycForm.controls['q16SixMonths'].setValue(data.swycSixMonths.q16SixMonths.answer);
      this.swycForm.controls['q17SixMonths'].setValue(data.swycSixMonths.q17SixMonths.answer);
      this.swycForm.controls['q18SixMonths'].setValue(data.swycSixMonths.q18SixMonths.answer);
      this.swycForm.controls['q19SixMonths'].setValue(data.swycSixMonths.q19SixMonths.answer);
      this.swycForm.controls['q20SixMonths'].setValue(data.swycSixMonths.q20SixMonths.answer);
      this.swycForm.controls['q21SixMonths'].setValue(data.swycSixMonths.q21SixMonths.answer);
      this.swycForm.controls['q22SixMonths'].setValue(data.swycSixMonths.q22SixMonths.answer);
      this.swycForm.controls['q23SixMonths'].setValue(data.swycSixMonths.q23SixMonths.answer);
      this.swycForm.controls['q24SixMonths'].setValue(data.swycSixMonths.q24SixMonths.answer);
      this.swycForm.controls['q25SixMonths'].setValue(data.swycSixMonths.q25SixMonths.answer);
      this.swycForm.controls['q26SixMonths'].setValue(data.swycSixMonths.q26SixMonths.answer);
      this.swycForm.controls['q27SixMonths'].setValue(data.swycSixMonths.q27SixMonths.answer);
      this.swycForm.controls['q28SixMonths'].setValue(data.swycSixMonths.q28SixMonths.answer);
      this.swycForm.controls['q29SixMonths'].setValue(data.swycSixMonths.q29SixMonths.answer);
      this.swycForm.controls['q30SixMonths'].setValue(data.swycSixMonths.q30SixMonths.answer);
      this.swycForm.controls['q31SixMonths'].setValue(data.swycSixMonths.q31SixMonths.answer);
      this.swycForm.controls['q32SixMonths'].setValue(data.swycSixMonths.q32SixMonths.answer);
      this.swycForm.controls['q33SixMonths'].setValue(data.swycSixMonths.q33SixMonths.answer);
      this.swycForm.controls['q34SixMonths'].setValue(data.swycSixMonths.q34SixMonths.answer);
      this.swycForm.controls['q35SixMonths'].setValue(data.swycSixMonths.q35SixMonths.answer);
      this.swycForm.controls['q36SixMonths'].setValue(data.swycSixMonths.q36SixMonths.answer);
      this.swycForm.controls['q37SixMonths'].setValue(data.swycSixMonths.q37SixMonths.answer);
      this.swycForm.controls['q38SixMonths'].setValue(data.swycSixMonths.q38SixMonths.answer);
      this.swycForm.controls['q39SixMonths'].setValue(data.swycSixMonths.q39SixMonths.answer);
      this.swycForm.controls['q40SixMonths'].setValue(data.swycSixMonths.q40SixMonths.answer);
      this.swycForm.controls['q41SixMonths'].setValue(data.swycSixMonths.q41SixMonths.answer);
      this.swycForm.controls['q42SixMonths'].setValue(data.swycSixMonths.q42SixMonths.answer);
    } else if (data.swycNineMonths) {
      this.swycForm.controls['q1NineMonths'].setValue(data.swycNineMonths.q1NineMonths.answer);
      this.swycForm.controls['q2NineMonths'].setValue(data.swycNineMonths.q2NineMonths.answer);
      this.swycForm.controls['q3NineMonths'].setValue(data.swycNineMonths.q3NineMonths.answer);
      this.swycForm.controls['q4NineMonths'].setValue(data.swycNineMonths.q4NineMonths.answer);
      this.swycForm.controls['q5NineMonths'].setValue(data.swycNineMonths.q5NineMonths.answer);
      this.swycForm.controls['q6NineMonths'].setValue(data.swycNineMonths.q6NineMonths.answer);
      this.swycForm.controls['q7NineMonths'].setValue(data.swycNineMonths.q7NineMonths.answer);
      this.swycForm.controls['q8NineMonths'].setValue(data.swycNineMonths.q8NineMonths.answer);
      this.swycForm.controls['q9NineMonths'].setValue(data.swycNineMonths.q9NineMonths.answer);
      this.swycForm.controls['q10NineMonths'].setValue(data.swycNineMonths.q10NineMonths.answer);
      this.swycForm.controls['q11NineMonths'].setValue(data.swycNineMonths.q11NineMonths.answer);
      this.swycForm.controls['q12NineMonths'].setValue(data.swycNineMonths.q12NineMonths.answer);
      this.swycForm.controls['q13NineMonths'].setValue(data.swycNineMonths.q13NineMonths.answer);
      this.swycForm.controls['q14NineMonths'].setValue(data.swycNineMonths.q14NineMonths.answer);
      this.swycForm.controls['q15NineMonths'].setValue(data.swycNineMonths.q15NineMonths.answer);
      this.swycForm.controls['q16NineMonths'].setValue(data.swycNineMonths.q16NineMonths.answer);
      this.swycForm.controls['q17NineMonths'].setValue(data.swycNineMonths.q17NineMonths.answer);
      this.swycForm.controls['q18NineMonths'].setValue(data.swycNineMonths.q18NineMonths.answer);
      this.swycForm.controls['q19NineMonths'].setValue(data.swycNineMonths.q19NineMonths.answer);
      this.swycForm.controls['q20NineMonths'].setValue(data.swycNineMonths.q20NineMonths.answer);
      this.swycForm.controls['q21NineMonths'].setValue(data.swycNineMonths.q21NineMonths.answer);
      this.swycForm.controls['q22NineMonths'].setValue(data.swycNineMonths.q22NineMonths.answer);
      this.swycForm.controls['q23NineMonths'].setValue(data.swycNineMonths.q23NineMonths.answer);
      this.swycForm.controls['q24NineMonths'].setValue(data.swycNineMonths.q24NineMonths.answer);
      this.swycForm.controls['q25NineMonths'].setValue(data.swycNineMonths.q25NineMonths.answer);
      this.swycForm.controls['q26NineMonths'].setValue(data.swycNineMonths.q26NineMonths.answer);
      this.swycForm.controls['q27NineMonths'].setValue(data.swycNineMonths.q27NineMonths.answer);
      this.swycForm.controls['q28NineMonths'].setValue(data.swycNineMonths.q28NineMonths.answer);
      this.swycForm.controls['q29NineMonths'].setValue(data.swycNineMonths.q29NineMonths.answer);
      this.swycForm.controls['q30NineMonths'].setValue(data.swycNineMonths.q30NineMonths.answer);
      this.swycForm.controls['q31NineMonths'].setValue(data.swycNineMonths.q31NineMonths.answer);
      this.swycForm.controls['q32NineMonths'].setValue(data.swycNineMonths.q32NineMonths.answer);
      this.swycForm.controls['q33NineMonths'].setValue(data.swycNineMonths.q33NineMonths.answer);
      this.swycForm.controls['q34NineMonths'].setValue(data.swycNineMonths.q34NineMonths.answer);
    } else if (data.swycTwelveMonths) {
      this.swycForm.controls['q1TwelveMonths'].setValue(data.swycTwelveMonths.q1TwelveMonths.answer);
      this.swycForm.controls['q2TwelveMonths'].setValue(data.swycTwelveMonths.q2TwelveMonths.answer);
      this.swycForm.controls['q3TwelveMonths'].setValue(data.swycTwelveMonths.q3TwelveMonths.answer);
      this.swycForm.controls['q4TwelveMonths'].setValue(data.swycTwelveMonths.q4TwelveMonths.answer);
      this.swycForm.controls['q5TwelveMonths'].setValue(data.swycTwelveMonths.q5TwelveMonths.answer);
      this.swycForm.controls['q6TwelveMonths'].setValue(data.swycTwelveMonths.q6TwelveMonths.answer);
      this.swycForm.controls['q7TwelveMonths'].setValue(data.swycTwelveMonths.q7TwelveMonths.answer);
      this.swycForm.controls['q8TwelveMonths'].setValue(data.swycTwelveMonths.q8TwelveMonths.answer);
      this.swycForm.controls['q9TwelveMonths'].setValue(data.swycTwelveMonths.q9TwelveMonths.answer);
      this.swycForm.controls['q10TwelveMonths'].setValue(data.swycTwelveMonths.q10TwelveMonths.answer);
      this.swycForm.controls['q11TwelveMonths'].setValue(data.swycTwelveMonths.q11TwelveMonths.answer);
      this.swycForm.controls['q12TwelveMonths'].setValue(data.swycTwelveMonths.q12TwelveMonths.answer);
      this.swycForm.controls['q13TwelveMonths'].setValue(data.swycTwelveMonths.q13TwelveMonths.answer);
      this.swycForm.controls['q14TwelveMonths'].setValue(data.swycTwelveMonths.q14TwelveMonths.answer);
      this.swycForm.controls['q15TwelveMonths'].setValue(data.swycTwelveMonths.q15TwelveMonths.answer);
      this.swycForm.controls['q16TwelveMonths'].setValue(data.swycTwelveMonths.q16TwelveMonths.answer);
      this.swycForm.controls['q17TwelveMonths'].setValue(data.swycTwelveMonths.q17TwelveMonths.answer);
      this.swycForm.controls['q18TwelveMonths'].setValue(data.swycTwelveMonths.q18TwelveMonths.answer);
      this.swycForm.controls['q19TwelveMonths'].setValue(data.swycTwelveMonths.q19TwelveMonths.answer);
      this.swycForm.controls['q20TwelveMonths'].setValue(data.swycTwelveMonths.q20TwelveMonths.answer);
      this.swycForm.controls['q21TwelveMonths'].setValue(data.swycTwelveMonths.q21TwelveMonths.answer);
      this.swycForm.controls['q22TwelveMonths'].setValue(data.swycTwelveMonths.q22TwelveMonths.answer);
      this.swycForm.controls['q23TwelveMonths'].setValue(data.swycTwelveMonths.q23TwelveMonths.answer);
      this.swycForm.controls['q24TwelveMonths'].setValue(data.swycTwelveMonths.q24TwelveMonths.answer);
      this.swycForm.controls['q25TwelveMonths'].setValue(data.swycTwelveMonths.q25TwelveMonths.answer);
      this.swycForm.controls['q26TwelveMonths'].setValue(data.swycTwelveMonths.q26TwelveMonths.answer);
      this.swycForm.controls['q27TwelveMonths'].setValue(data.swycTwelveMonths.q27TwelveMonths.answer);
      this.swycForm.controls['q28TwelveMonths'].setValue(data.swycTwelveMonths.q28TwelveMonths.answer);
      this.swycForm.controls['q29TwelveMonths'].setValue(data.swycTwelveMonths.q29TwelveMonths.answer);
      this.swycForm.controls['q30TwelveMonths'].setValue(data.swycTwelveMonths.q30TwelveMonths.answer);
      this.swycForm.controls['q31TwelveMonths'].setValue(data.swycTwelveMonths.q31TwelveMonths.answer);
      this.swycForm.controls['q32TwelveMonths'].setValue(data.swycTwelveMonths.q32TwelveMonths.answer);
      this.swycForm.controls['q33TwelveMonths'].setValue(data.swycTwelveMonths.q33TwelveMonths.answer);
      this.swycForm.controls['q34TwelveMonths'].setValue(data.swycTwelveMonths.q34TwelveMonths.answer);
    } else if (data.swycFifteenMonths) {
      this.swycForm.controls['q1FifteenMonths'].setValue(data.swycFifteenMonths.q1FifteenMonths.answer);
      this.swycForm.controls['q2FifteenMonths'].setValue(data.swycFifteenMonths.q2FifteenMonths.answer);
      this.swycForm.controls['q3FifteenMonths'].setValue(data.swycFifteenMonths.q3FifteenMonths.answer);
      this.swycForm.controls['q4FifteenMonths'].setValue(data.swycFifteenMonths.q4FifteenMonths.answer);
      this.swycForm.controls['q5FifteenMonths'].setValue(data.swycFifteenMonths.q5FifteenMonths.answer);
      this.swycForm.controls['q6FifteenMonths'].setValue(data.swycFifteenMonths.q6FifteenMonths.answer);
      this.swycForm.controls['q7FifteenMonths'].setValue(data.swycFifteenMonths.q7FifteenMonths.answer);
      this.swycForm.controls['q8FifteenMonths'].setValue(data.swycFifteenMonths.q8FifteenMonths.answer);
      this.swycForm.controls['q9FifteenMonths'].setValue(data.swycFifteenMonths.q9FifteenMonths.answer);
      this.swycForm.controls['q10FifteenMonths'].setValue(data.swycFifteenMonths.q10FifteenMonths.answer);
      this.swycForm.controls['q11FifteenMonths'].setValue(data.swycFifteenMonths.q11FifteenMonths.answer);
      this.swycForm.controls['q12FifteenMonths'].setValue(data.swycFifteenMonths.q12FifteenMonths.answer);
      this.swycForm.controls['q13FifteenMonths'].setValue(data.swycFifteenMonths.q13FifteenMonths.answer);
      this.swycForm.controls['q14FifteenMonths'].setValue(data.swycFifteenMonths.q14FifteenMonths.answer);
      this.swycForm.controls['q15FifteenMonths'].setValue(data.swycFifteenMonths.q15FifteenMonths.answer);
      this.swycForm.controls['q16FifteenMonths'].setValue(data.swycFifteenMonths.q16FifteenMonths.answer);
      this.swycForm.controls['q17FifteenMonths'].setValue(data.swycFifteenMonths.q17FifteenMonths.answer);
      this.swycForm.controls['q18FifteenMonths'].setValue(data.swycFifteenMonths.q18FifteenMonths.answer);
      this.swycForm.controls['q19FifteenMonths'].setValue(data.swycFifteenMonths.q19FifteenMonths.answer);
      this.swycForm.controls['q20FifteenMonths'].setValue(data.swycFifteenMonths.q20FifteenMonths.answer);
      this.swycForm.controls['q21FifteenMonths'].setValue(data.swycFifteenMonths.q21FifteenMonths.answer);
      this.swycForm.controls['q22FifteenMonths'].setValue(data.swycFifteenMonths.q22FifteenMonths.answer);
      this.swycForm.controls['q23FifteenMonths'].setValue(data.swycFifteenMonths.q23FifteenMonths.answer);
      this.swycForm.controls['q24FifteenMonths'].setValue(data.swycFifteenMonths.q24FifteenMonths.answer);
      this.swycForm.controls['q25FifteenMonths'].setValue(data.swycFifteenMonths.q25FifteenMonths.answer);
      this.swycForm.controls['q26FifteenMonths'].setValue(data.swycFifteenMonths.q26FifteenMonths.answer);
      this.swycForm.controls['q27FifteenMonths'].setValue(data.swycFifteenMonths.q27FifteenMonths.answer);
      this.swycForm.controls['q28FifteenMonths'].setValue(data.swycFifteenMonths.q28FifteenMonths.answer);
      this.swycForm.controls['q29FifteenMonths'].setValue(data.swycFifteenMonths.q29FifteenMonths.answer);
      this.swycForm.controls['q30FifteenMonths'].setValue(data.swycFifteenMonths.q30FifteenMonths.answer);
      this.swycForm.controls['q31FifteenMonths'].setValue(data.swycFifteenMonths.q31FifteenMonths.answer);
      this.swycForm.controls['q32FifteenMonths'].setValue(data.swycFifteenMonths.q32FifteenMonths.answer);
      this.swycForm.controls['q33FifteenMonths'].setValue(data.swycFifteenMonths.q33FifteenMonths.answer);
      this.swycForm.controls['q34FifteenMonths'].setValue(data.swycFifteenMonths.q34FifteenMonths.answer);
    } else if (data.swycEighteenMonths) {
      this.swycForm.controls['q1EighteenMonths'].setValue(data.swycEighteenMonths.q1EighteenMonths.answer);
      this.swycForm.controls['q2EighteenMonths'].setValue(data.swycEighteenMonths.q2EighteenMonths.answer);
      this.swycForm.controls['q3EighteenMonths'].setValue(data.swycEighteenMonths.q3EighteenMonths.answer);
      this.swycForm.controls['q4EighteenMonths'].setValue(data.swycEighteenMonths.q4EighteenMonths.answer);
      this.swycForm.controls['q5EighteenMonths'].setValue(data.swycEighteenMonths.q5EighteenMonths.answer);
      this.swycForm.controls['q6EighteenMonths'].setValue(data.swycEighteenMonths.q6EighteenMonths.answer);
      this.swycForm.controls['q7EighteenMonths'].setValue(data.swycEighteenMonths.q7EighteenMonths.answer);
      this.swycForm.controls['q8EighteenMonths'].setValue(data.swycEighteenMonths.q8EighteenMonths.answer);
      this.swycForm.controls['q9EighteenMonths'].setValue(data.swycEighteenMonths.q9EighteenMonths.answer);
      this.swycForm.controls['q10EighteenMonths'].setValue(data.swycEighteenMonths.q10EighteenMonths.answer);
      this.swycForm.controls['q11EighteenMonths'].setValue(data.swycEighteenMonths.q11EighteenMonths.answer);
      this.swycForm.controls['q12EighteenMonths'].setValue(data.swycEighteenMonths.q12EighteenMonths.answer);
      this.swycForm.controls['q13EighteenMonths'].setValue(data.swycEighteenMonths.q13EighteenMonths.answer);
      this.swycForm.controls['q14EighteenMonths'].setValue(data.swycEighteenMonths.q14EighteenMonths.answer);
      this.swycForm.controls['q15EighteenMonths'].setValue(data.swycEighteenMonths.q15EighteenMonths.answer);
      this.swycForm.controls['q16EighteenMonths'].setValue(data.swycEighteenMonths.q16EighteenMonths.answer);
      this.swycForm.controls['q17EighteenMonths'].setValue(data.swycEighteenMonths.q17EighteenMonths.answer);
      this.swycForm.controls['q18EighteenMonths'].setValue(data.swycEighteenMonths.q18EighteenMonths.answer);
      this.swycForm.controls['q19EighteenMonths'].setValue(data.swycEighteenMonths.q19EighteenMonths.answer);
      this.swycForm.controls['q20EighteenMonths'].setValue(data.swycEighteenMonths.q20EighteenMonths.answer);
      this.swycForm.controls['q21EighteenMonths'].setValue(data.swycEighteenMonths.q21EighteenMonths.answer);
      this.swycForm.controls['q22EighteenMonths'].setValue(data.swycEighteenMonths.q22EighteenMonths.answer);
      this.swycForm.controls['q23EighteenMonths'].setValue(data.swycEighteenMonths.q23EighteenMonths.answer);
      this.swycForm.controls['q24EighteenMonths'].setValue(data.swycEighteenMonths.q24EighteenMonths.answer);
      this.swycForm.controls['q25EighteenMonths'].setValue(data.swycEighteenMonths.q25EighteenMonths.answer);
      this.swycForm.controls['q26EighteenMonths'].setValue(data.swycEighteenMonths.q26EighteenMonths.answer);
      this.swycForm.controls['q27EighteenMonths'].setValue(data.swycEighteenMonths.q27EighteenMonths.answer);
      this.swycForm.controls['q28EighteenMonths'].setValue(data.swycEighteenMonths.q28EighteenMonths.answer);
      this.swycForm.controls['q29EighteenMonths'].setValue(data.swycEighteenMonths.q29EighteenMonths.answer);
      this.swycForm.controls['q30EighteenMonths'].setValue(data.swycEighteenMonths.q30EighteenMonths.answer);
      this.swycForm.controls['q31EighteenMonths'].setValue(data.swycEighteenMonths.q31EighteenMonths.answer);
      this.swycForm.controls['q32EighteenMonths'].setValue(data.swycEighteenMonths.q32EighteenMonths.answer);
      this.swycForm.controls['q33EighteenMonths'].setValue(data.swycEighteenMonths.q33EighteenMonths.answer);
      this.swycForm.controls['q34EighteenMonths'].setValue(data.swycEighteenMonths.q34EighteenMonths.answer);
      this.swycForm.controls['q35EighteenMonths'].setValue(data.swycEighteenMonths.q35EighteenMonths.answer);
      this.swycForm.controls['q36EighteenMonths'].setValue(data.swycEighteenMonths.q36EighteenMonths.answer);
      this.swycForm.controls['q37EighteenMonths'].setValue(data.swycEighteenMonths.q37EighteenMonths.answer);
      this.swycForm.controls['q38EighteenMonths'].setValue(data.swycEighteenMonths.q38EighteenMonths.answer);
      this.swycForm.controls['q39EighteenMonths'].setValue(data.swycEighteenMonths.q39EighteenMonths.answer);
      this.swycForm.controls['q40EighteenMonths'].setValue(data.swycEighteenMonths.q40EighteenMonths.answer);
      this.swycForm.controls['q41EighteenMonths'].setValue(data.swycEighteenMonths.q41EighteenMonths.answer);
      this.swycForm.controls['q42EighteenMonths'].setValue(data.swycEighteenMonths.q42EighteenMonths.answer);
      this.swycForm.controls['q43EighteenMonths'].setValue(data.swycEighteenMonths.q43EighteenMonths.answer);
      this.swycForm.controls['q44EighteenMonths'].setValue(data.swycEighteenMonths.q44EighteenMonths.answer);
      this.swycForm.controls['q45EighteenMonths'].setValue(data.swycEighteenMonths.q45EighteenMonths.answer);
      this.swycForm.controls['q46EighteenMonths'].setValue(data.swycEighteenMonths.q46EighteenMonths.answer);
    } else if (data.swycTwentyFourMonths) {
      this.swycForm.controls['q1TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q1TwentyFourMonths.answer);
      this.swycForm.controls['q2TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q2TwentyFourMonths.answer);
      this.swycForm.controls['q3TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q3TwentyFourMonths.answer);
      this.swycForm.controls['q4TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q4TwentyFourMonths.answer);
      this.swycForm.controls['q5TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q5TwentyFourMonths.answer);
      this.swycForm.controls['q6TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q6TwentyFourMonths.answer);
      this.swycForm.controls['q7TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q7TwentyFourMonths.answer);
      this.swycForm.controls['q8TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q8TwentyFourMonths.answer);
      this.swycForm.controls['q9TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q9TwentyFourMonths.answer);
      this.swycForm.controls['q10TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q10TwentyFourMonths.answer);
      this.swycForm.controls['q11TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q11TwentyFourMonths.answer);
      this.swycForm.controls['q12TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q12TwentyFourMonths.answer);
      this.swycForm.controls['q13TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q13TwentyFourMonths.answer);
      this.swycForm.controls['q14TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q14TwentyFourMonths.answer);
      this.swycForm.controls['q15TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q15TwentyFourMonths.answer);
      this.swycForm.controls['q16TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q16TwentyFourMonths.answer);
      this.swycForm.controls['q17TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q17TwentyFourMonths.answer);
      this.swycForm.controls['q18TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q18TwentyFourMonths.answer);
      this.swycForm.controls['q19TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q19TwentyFourMonths.answer);
      this.swycForm.controls['q20TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q20TwentyFourMonths.answer);
      this.swycForm.controls['q21TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q21TwentyFourMonths.answer);
      this.swycForm.controls['q22TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q22TwentyFourMonths.answer);
      this.swycForm.controls['q23TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q23TwentyFourMonths.answer);
      this.swycForm.controls['q24TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q24TwentyFourMonths.answer);
      this.swycForm.controls['q25TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q25TwentyFourMonths.answer);
      this.swycForm.controls['q26TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q26TwentyFourMonths.answer);
      this.swycForm.controls['q27TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q27TwentyFourMonths.answer);
      this.swycForm.controls['q28TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q28TwentyFourMonths.answer);
      this.swycForm.controls['q29TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q29TwentyFourMonths.answer);
      this.swycForm.controls['q30TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q30TwentyFourMonths.answer);
      this.swycForm.controls['q31TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q31TwentyFourMonths.answer);
      this.swycForm.controls['q32TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q32TwentyFourMonths.answer);
      this.swycForm.controls['q33TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q33TwentyFourMonths.answer);
      this.swycForm.controls['q34TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q34TwentyFourMonths.answer);
      this.swycForm.controls['q35TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q35TwentyFourMonths.answer);
      this.swycForm.controls['q36TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q36TwentyFourMonths.answer);
      this.swycForm.controls['q37TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q37TwentyFourMonths.answer);
      this.swycForm.controls['q38TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q38TwentyFourMonths.answer);
      this.swycForm.controls['q39TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q39TwentyFourMonths.answer);
      this.swycForm.controls['q40TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q40TwentyFourMonths.answer);
      this.swycForm.controls['q41TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q41TwentyFourMonths.answer);
      this.swycForm.controls['q42TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q42TwentyFourMonths.answer);
      this.swycForm.controls['q43TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q43TwentyFourMonths.answer);
      this.swycForm.controls['q44TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q44TwentyFourMonths.answer);
      this.swycForm.controls['q45TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q45TwentyFourMonths.answer);
      this.swycForm.controls['q46TwentyFourMonths'].setValue(data.swycTwentyFourMonths.q46TwentyFourMonths.answer);
    } else if (data.swycThirtyMonths) {
      this.swycForm.controls['q1ThirtyMonths'].setValue(data.swycThirtyMonths.q1ThirtyMonths.answer);
      this.swycForm.controls['q2ThirtyMonths'].setValue(data.swycThirtyMonths.q2ThirtyMonths.answer);
      this.swycForm.controls['q3ThirtyMonths'].setValue(data.swycThirtyMonths.q3ThirtyMonths.answer);
      this.swycForm.controls['q4ThirtyMonths'].setValue(data.swycThirtyMonths.q4ThirtyMonths.answer);
      this.swycForm.controls['q5ThirtyMonths'].setValue(data.swycThirtyMonths.q5ThirtyMonths.answer);
      this.swycForm.controls['q6ThirtyMonths'].setValue(data.swycThirtyMonths.q6ThirtyMonths.answer);
      this.swycForm.controls['q7ThirtyMonths'].setValue(data.swycThirtyMonths.q7ThirtyMonths.answer);
      this.swycForm.controls['q8ThirtyMonths'].setValue(data.swycThirtyMonths.q8ThirtyMonths.answer);
      this.swycForm.controls['q9ThirtyMonths'].setValue(data.swycThirtyMonths.q9ThirtyMonths.answer);
      this.swycForm.controls['q10ThirtyMonths'].setValue(data.swycThirtyMonths.q10ThirtyMonths.answer);
      this.swycForm.controls['q11ThirtyMonths'].setValue(data.swycThirtyMonths.q11ThirtyMonths.answer);
      this.swycForm.controls['q12ThirtyMonths'].setValue(data.swycThirtyMonths.q12ThirtyMonths.answer);
      this.swycForm.controls['q13ThirtyMonths'].setValue(data.swycThirtyMonths.q13ThirtyMonths.answer);
      this.swycForm.controls['q14ThirtyMonths'].setValue(data.swycThirtyMonths.q14ThirtyMonths.answer);
      this.swycForm.controls['q15ThirtyMonths'].setValue(data.swycThirtyMonths.q15ThirtyMonths.answer);
      this.swycForm.controls['q16ThirtyMonths'].setValue(data.swycThirtyMonths.q16ThirtyMonths.answer);
      this.swycForm.controls['q17ThirtyMonths'].setValue(data.swycThirtyMonths.q17ThirtyMonths.answer);
      this.swycForm.controls['q18ThirtyMonths'].setValue(data.swycThirtyMonths.q18ThirtyMonths.answer);
      this.swycForm.controls['q19ThirtyMonths'].setValue(data.swycThirtyMonths.q19ThirtyMonths.answer);
      this.swycForm.controls['q20ThirtyMonths'].setValue(data.swycThirtyMonths.q20ThirtyMonths.answer);
      this.swycForm.controls['q21ThirtyMonths'].setValue(data.swycThirtyMonths.q21ThirtyMonths.answer);
      this.swycForm.controls['q22ThirtyMonths'].setValue(data.swycThirtyMonths.q22ThirtyMonths.answer);
      this.swycForm.controls['q23ThirtyMonths'].setValue(data.swycThirtyMonths.q23ThirtyMonths.answer);
      this.swycForm.controls['q24ThirtyMonths'].setValue(data.swycThirtyMonths.q24ThirtyMonths.answer);
      this.swycForm.controls['q25ThirtyMonths'].setValue(data.swycThirtyMonths.q25ThirtyMonths.answer);
      this.swycForm.controls['q26ThirtyMonths'].setValue(data.swycThirtyMonths.q26ThirtyMonths.answer);
      this.swycForm.controls['q27ThirtyMonths'].setValue(data.swycThirtyMonths.q27ThirtyMonths.answer);
      this.swycForm.controls['q28ThirtyMonths'].setValue(data.swycThirtyMonths.q28ThirtyMonths.answer);
      this.swycForm.controls['q29ThirtyMonths'].setValue(data.swycThirtyMonths.q29ThirtyMonths.answer);
      this.swycForm.controls['q30ThirtyMonths'].setValue(data.swycThirtyMonths.q30ThirtyMonths.answer);
      this.swycForm.controls['q31ThirtyMonths'].setValue(data.swycThirtyMonths.q31ThirtyMonths.answer);
      this.swycForm.controls['q32ThirtyMonths'].setValue(data.swycThirtyMonths.q32ThirtyMonths.answer);
      this.swycForm.controls['q33ThirtyMonths'].setValue(data.swycThirtyMonths.q33ThirtyMonths.answer);
      this.swycForm.controls['q34ThirtyMonths'].setValue(data.swycThirtyMonths.q34ThirtyMonths.answer);
      this.swycForm.controls['q35ThirtyMonths'].setValue(data.swycThirtyMonths.q35ThirtyMonths.answer);
      this.swycForm.controls['q36ThirtyMonths'].setValue(data.swycThirtyMonths.q36ThirtyMonths.answer);
      this.swycForm.controls['q37ThirtyMonths'].setValue(data.swycThirtyMonths.q37ThirtyMonths.answer);
      this.swycForm.controls['q38ThirtyMonths'].setValue(data.swycThirtyMonths.q38ThirtyMonths.answer);
      this.swycForm.controls['q39ThirtyMonths'].setValue(data.swycThirtyMonths.q39ThirtyMonths.answer);
      this.swycForm.controls['q40ThirtyMonths'].setValue(data.swycThirtyMonths.q40ThirtyMonths.answer);
      this.swycForm.controls['q41ThirtyMonths'].setValue(data.swycThirtyMonths.q41ThirtyMonths.answer);
      this.swycForm.controls['q42ThirtyMonths'].setValue(data.swycThirtyMonths.q42ThirtyMonths.answer);
      this.swycForm.controls['q43ThirtyMonths'].setValue(data.swycThirtyMonths.q43ThirtyMonths.answer);
      this.swycForm.controls['q44ThirtyMonths'].setValue(data.swycThirtyMonths.q44ThirtyMonths.answer);
      this.swycForm.controls['q45ThirtyMonths'].setValue(data.swycThirtyMonths.q45ThirtyMonths.answer);
      this.swycForm.controls['q46ThirtyMonths'].setValue(data.swycThirtyMonths.q46ThirtyMonths.answer);
    } else if (data.swycThirtySixMonths) {
      this.swycForm.controls['q1ThirtySixMonths'].setValue(data.swycThirtySixMonths.q1ThirtySixMonths.answer);
      this.swycForm.controls['q2ThirtySixMonths'].setValue(data.swycThirtySixMonths.q2ThirtySixMonths.answer);
      this.swycForm.controls['q3ThirtySixMonths'].setValue(data.swycThirtySixMonths.q3ThirtySixMonths.answer);
      this.swycForm.controls['q4ThirtySixMonths'].setValue(data.swycThirtySixMonths.q4ThirtySixMonths.answer);
      this.swycForm.controls['q5ThirtySixMonths'].setValue(data.swycThirtySixMonths.q5ThirtySixMonths.answer);
      this.swycForm.controls['q6ThirtySixMonths'].setValue(data.swycThirtySixMonths.q6ThirtySixMonths.answer);
      this.swycForm.controls['q7ThirtySixMonths'].setValue(data.swycThirtySixMonths.q7ThirtySixMonths.answer);
      this.swycForm.controls['q8ThirtySixMonths'].setValue(data.swycThirtySixMonths.q8ThirtySixMonths.answer);
      this.swycForm.controls['q9ThirtySixMonths'].setValue(data.swycThirtySixMonths.q9ThirtySixMonths.answer);
      this.swycForm.controls['q10ThirtySixMonths'].setValue(data.swycThirtySixMonths.q10ThirtySixMonths.answer);
      this.swycForm.controls['q11ThirtySixMonths'].setValue(data.swycThirtySixMonths.q11ThirtySixMonths.answer);
      this.swycForm.controls['q12ThirtySixMonths'].setValue(data.swycThirtySixMonths.q12ThirtySixMonths.answer);
      this.swycForm.controls['q13ThirtySixMonths'].setValue(data.swycThirtySixMonths.q13ThirtySixMonths.answer);
      this.swycForm.controls['q14ThirtySixMonths'].setValue(data.swycThirtySixMonths.q14ThirtySixMonths.answer);
      this.swycForm.controls['q15ThirtySixMonths'].setValue(data.swycThirtySixMonths.q15ThirtySixMonths.answer);
      this.swycForm.controls['q16ThirtySixMonths'].setValue(data.swycThirtySixMonths.q16ThirtySixMonths.answer);
      this.swycForm.controls['q17ThirtySixMonths'].setValue(data.swycThirtySixMonths.q17ThirtySixMonths.answer);
      this.swycForm.controls['q18ThirtySixMonths'].setValue(data.swycThirtySixMonths.q18ThirtySixMonths.answer);
      this.swycForm.controls['q19ThirtySixMonths'].setValue(data.swycThirtySixMonths.q19ThirtySixMonths.answer);
      this.swycForm.controls['q20ThirtySixMonths'].setValue(data.swycThirtySixMonths.q20ThirtySixMonths.answer);
      this.swycForm.controls['q21ThirtySixMonths'].setValue(data.swycThirtySixMonths.q21ThirtySixMonths.answer);
      this.swycForm.controls['q22ThirtySixMonths'].setValue(data.swycThirtySixMonths.q22ThirtySixMonths.answer);
      this.swycForm.controls['q23ThirtySixMonths'].setValue(data.swycThirtySixMonths.q23ThirtySixMonths.answer);
      this.swycForm.controls['q24ThirtySixMonths'].setValue(data.swycThirtySixMonths.q24ThirtySixMonths.answer);
      this.swycForm.controls['q25ThirtySixMonths'].setValue(data.swycThirtySixMonths.q25ThirtySixMonths.answer);
      this.swycForm.controls['q26ThirtySixMonths'].setValue(data.swycThirtySixMonths.q26ThirtySixMonths.answer);
      this.swycForm.controls['q27ThirtySixMonths'].setValue(data.swycThirtySixMonths.q27ThirtySixMonths.answer);
      this.swycForm.controls['q28ThirtySixMonths'].setValue(data.swycThirtySixMonths.q28ThirtySixMonths.answer);
      this.swycForm.controls['q29ThirtySixMonths'].setValue(data.swycThirtySixMonths.q29ThirtySixMonths.answer);
      this.swycForm.controls['q30ThirtySixMonths'].setValue(data.swycThirtySixMonths.q30ThirtySixMonths.answer);
      this.swycForm.controls['q31ThirtySixMonths'].setValue(data.swycThirtySixMonths.q31ThirtySixMonths.answer);
      this.swycForm.controls['q32ThirtySixMonths'].setValue(data.swycThirtySixMonths.q32ThirtySixMonths.answer);
      this.swycForm.controls['q33ThirtySixMonths'].setValue(data.swycThirtySixMonths.q33ThirtySixMonths.answer);
      this.swycForm.controls['q34ThirtySixMonths'].setValue(data.swycThirtySixMonths.q34ThirtySixMonths.answer);
      this.swycForm.controls['q35ThirtySixMonths'].setValue(data.swycThirtySixMonths.q35ThirtySixMonths.answer);
      this.swycForm.controls['q36ThirtySixMonths'].setValue(data.swycThirtySixMonths.q36ThirtySixMonths.answer);
      this.swycForm.controls['q37ThirtySixMonths'].setValue(data.swycThirtySixMonths.q37ThirtySixMonths.answer);
      this.swycForm.controls['q38ThirtySixMonths'].setValue(data.swycThirtySixMonths.q38ThirtySixMonths.answer);
      this.swycForm.controls['q39ThirtySixMonths'].setValue(data.swycThirtySixMonths.q39ThirtySixMonths.answer);
    } else if (data.swycFortyEightMonths) {
      this.swycForm.controls['q1FortyEightMonths'].setValue(data.swycFortyEightMonths.q1FortyEightMonths.answer);
      this.swycForm.controls['q2FortyEightMonths'].setValue(data.swycFortyEightMonths.q2FortyEightMonths.answer);
      this.swycForm.controls['q3FortyEightMonths'].setValue(data.swycFortyEightMonths.q3FortyEightMonths.answer);
      this.swycForm.controls['q4FortyEightMonths'].setValue(data.swycFortyEightMonths.q4FortyEightMonths.answer);
      this.swycForm.controls['q5FortyEightMonths'].setValue(data.swycFortyEightMonths.q5FortyEightMonths.answer);
      this.swycForm.controls['q6FortyEightMonths'].setValue(data.swycFortyEightMonths.q6FortyEightMonths.answer);
      this.swycForm.controls['q7FortyEightMonths'].setValue(data.swycFortyEightMonths.q7FortyEightMonths.answer);
      this.swycForm.controls['q8FortyEightMonths'].setValue(data.swycFortyEightMonths.q8FortyEightMonths.answer);
      this.swycForm.controls['q9FortyEightMonths'].setValue(data.swycFortyEightMonths.q9FortyEightMonths.answer);
      this.swycForm.controls['q10FortyEightMonths'].setValue(data.swycFortyEightMonths.q10FortyEightMonths.answer);
      this.swycForm.controls['q11FortyEightMonths'].setValue(data.swycFortyEightMonths.q11FortyEightMonths.answer);
      this.swycForm.controls['q12FortyEightMonths'].setValue(data.swycFortyEightMonths.q12FortyEightMonths.answer);
      this.swycForm.controls['q13FortyEightMonths'].setValue(data.swycFortyEightMonths.q13FortyEightMonths.answer);
      this.swycForm.controls['q14FortyEightMonths'].setValue(data.swycFortyEightMonths.q14FortyEightMonths.answer);
      this.swycForm.controls['q15FortyEightMonths'].setValue(data.swycFortyEightMonths.q15FortyEightMonths.answer);
      this.swycForm.controls['q16FortyEightMonths'].setValue(data.swycFortyEightMonths.q16FortyEightMonths.answer);
      this.swycForm.controls['q17FortyEightMonths'].setValue(data.swycFortyEightMonths.q17FortyEightMonths.answer);
      this.swycForm.controls['q18FortyEightMonths'].setValue(data.swycFortyEightMonths.q18FortyEightMonths.answer);
      this.swycForm.controls['q19FortyEightMonths'].setValue(data.swycFortyEightMonths.q19FortyEightMonths.answer);
      this.swycForm.controls['q20FortyEightMonths'].setValue(data.swycFortyEightMonths.q20FortyEightMonths.answer);
      this.swycForm.controls['q21FortyEightMonths'].setValue(data.swycFortyEightMonths.q21FortyEightMonths.answer);
      this.swycForm.controls['q22FortyEightMonths'].setValue(data.swycFortyEightMonths.q22FortyEightMonths.answer);
      this.swycForm.controls['q23FortyEightMonths'].setValue(data.swycFortyEightMonths.q23FortyEightMonths.answer);
      this.swycForm.controls['q24FortyEightMonths'].setValue(data.swycFortyEightMonths.q24FortyEightMonths.answer);
      this.swycForm.controls['q25FortyEightMonths'].setValue(data.swycFortyEightMonths.q25FortyEightMonths.answer);
      this.swycForm.controls['q26FortyEightMonths'].setValue(data.swycFortyEightMonths.q26FortyEightMonths.answer);
      this.swycForm.controls['q27FortyEightMonths'].setValue(data.swycFortyEightMonths.q27FortyEightMonths.answer);
      this.swycForm.controls['q28FortyEightMonths'].setValue(data.swycFortyEightMonths.q28FortyEightMonths.answer);
      this.swycForm.controls['q29FortyEightMonths'].setValue(data.swycFortyEightMonths.q29FortyEightMonths.answer);
      this.swycForm.controls['q30FortyEightMonths'].setValue(data.swycFortyEightMonths.q30FortyEightMonths.answer);
      this.swycForm.controls['q31FortyEightMonths'].setValue(data.swycFortyEightMonths.q31FortyEightMonths.answer);
      this.swycForm.controls['q32FortyEightMonths'].setValue(data.swycFortyEightMonths.q32FortyEightMonths.answer);
      this.swycForm.controls['q33FortyEightMonths'].setValue(data.swycFortyEightMonths.q33FortyEightMonths.answer);
      this.swycForm.controls['q34FortyEightMonths'].setValue(data.swycFortyEightMonths.q34FortyEightMonths.answer);
      this.swycForm.controls['q35FortyEightMonths'].setValue(data.swycFortyEightMonths.q35FortyEightMonths.answer);
      this.swycForm.controls['q36FortyEightMonths'].setValue(data.swycFortyEightMonths.q36FortyEightMonths.answer);
      this.swycForm.controls['q37FortyEightMonths'].setValue(data.swycFortyEightMonths.q37FortyEightMonths.answer);
      this.swycForm.controls['q38FortyEightMonths'].setValue(data.swycFortyEightMonths.q38FortyEightMonths.answer);
      this.swycForm.controls['q39FortyEightMonths'].setValue(data.swycFortyEightMonths.q39FortyEightMonths.answer);
    } else if (data.swycSixtyMonths) {
      this.swycForm.controls['q1SixtyMonths'].setValue(data.swycSixtyMonths.q1SixtyMonths.answer);
      this.swycForm.controls['q2SixtyMonths'].setValue(data.swycSixtyMonths.q2SixtyMonths.answer);
      this.swycForm.controls['q3SixtyMonths'].setValue(data.swycSixtyMonths.q3SixtyMonths.answer);
      this.swycForm.controls['q4SixtyMonths'].setValue(data.swycSixtyMonths.q4SixtyMonths.answer);
      this.swycForm.controls['q5SixtyMonths'].setValue(data.swycSixtyMonths.q5SixtyMonths.answer);
      this.swycForm.controls['q6SixtyMonths'].setValue(data.swycSixtyMonths.q6SixtyMonths.answer);
      this.swycForm.controls['q7SixtyMonths'].setValue(data.swycSixtyMonths.q7SixtyMonths.answer);
      this.swycForm.controls['q8SixtyMonths'].setValue(data.swycSixtyMonths.q8SixtyMonths.answer);
      this.swycForm.controls['q9SixtyMonths'].setValue(data.swycSixtyMonths.q9SixtyMonths.answer);
      this.swycForm.controls['q10SixtyMonths'].setValue(data.swycSixtyMonths.q10SixtyMonths.answer);
      this.swycForm.controls['q11SixtyMonths'].setValue(data.swycSixtyMonths.q11SixtyMonths.answer);
      this.swycForm.controls['q12SixtyMonths'].setValue(data.swycSixtyMonths.q12SixtyMonths.answer);
      this.swycForm.controls['q13SixtyMonths'].setValue(data.swycSixtyMonths.q13SixtyMonths.answer);
      this.swycForm.controls['q14SixtyMonths'].setValue(data.swycSixtyMonths.q14SixtyMonths.answer);
      this.swycForm.controls['q15SixtyMonths'].setValue(data.swycSixtyMonths.q15SixtyMonths.answer);
      this.swycForm.controls['q16SixtyMonths'].setValue(data.swycSixtyMonths.q16SixtyMonths.answer);
      this.swycForm.controls['q17SixtyMonths'].setValue(data.swycSixtyMonths.q17SixtyMonths.answer);
      this.swycForm.controls['q18SixtyMonths'].setValue(data.swycSixtyMonths.q18SixtyMonths.answer);
      this.swycForm.controls['q19SixtyMonths'].setValue(data.swycSixtyMonths.q19SixtyMonths.answer);
      this.swycForm.controls['q20SixtyMonths'].setValue(data.swycSixtyMonths.q20SixtyMonths.answer);
      this.swycForm.controls['q21SixtyMonths'].setValue(data.swycSixtyMonths.q21SixtyMonths.answer);
      this.swycForm.controls['q22SixtyMonths'].setValue(data.swycSixtyMonths.q22SixtyMonths.answer);
      this.swycForm.controls['q23SixtyMonths'].setValue(data.swycSixtyMonths.q23SixtyMonths.answer);
      this.swycForm.controls['q24SixtyMonths'].setValue(data.swycSixtyMonths.q24SixtyMonths.answer);
      this.swycForm.controls['q25SixtyMonths'].setValue(data.swycSixtyMonths.q25SixtyMonths.answer);
      this.swycForm.controls['q26SixtyMonths'].setValue(data.swycSixtyMonths.q26SixtyMonths.answer);
      this.swycForm.controls['q27SixtyMonths'].setValue(data.swycSixtyMonths.q27SixtyMonths.answer);
      this.swycForm.controls['q28SixtyMonths'].setValue(data.swycSixtyMonths.q28SixtyMonths.answer);
      this.swycForm.controls['q29SixtyMonths'].setValue(data.swycSixtyMonths.q29SixtyMonths.answer);
      this.swycForm.controls['q30SixtyMonths'].setValue(data.swycSixtyMonths.q30SixtyMonths.answer);
      this.swycForm.controls['q31SixtyMonths'].setValue(data.swycSixtyMonths.q31SixtyMonths.answer);
      this.swycForm.controls['q32SixtyMonths'].setValue(data.swycSixtyMonths.q32SixtyMonths.answer);
      this.swycForm.controls['q33SixtyMonths'].setValue(data.swycSixtyMonths.q33SixtyMonths.answer);
      this.swycForm.controls['q34SixtyMonths'].setValue(data.swycSixtyMonths.q34SixtyMonths.answer);
      this.swycForm.controls['q35SixtyMonths'].setValue(data.swycSixtyMonths.q35SixtyMonths.answer);
      this.swycForm.controls['q36SixtyMonths'].setValue(data.swycSixtyMonths.q36SixtyMonths.answer);
      this.swycForm.controls['q37SixtyMonths'].setValue(data.swycSixtyMonths.q37SixtyMonths.answer);
      this.swycForm.controls['q38SixtyMonths'].setValue(data.swycSixtyMonths.q38SixtyMonths.answer);
      this.swycForm.controls['q39SixtyMonths'].setValue(data.swycSixtyMonths.q39SixtyMonths.answer);
    }
  }


  private getFormObjectToSubmit(selectedAgeGroup: string): any {
    let output: any = {};
    switch (selectedAgeGroup) {
      case "2months":
        this.swycTwoMonths = Object.assign({}, this.swycTwoMonths);
        this.swycTwoMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycTwoMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycTwoMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycTwoMonths.ageGroup = this.selectedAgeGroup;
        this.swycTwoMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));

        this.swycTwoMonths.q1TwoMonths = { answer: this.swycForm.controls['q1TwoMonths'].value };
        this.swycTwoMonths.q2TwoMonths = { answer: this.swycForm.controls['q2TwoMonths'].value };
        this.swycTwoMonths.q3TwoMonths = { answer: this.swycForm.controls['q3TwoMonths'].value };
        this.swycTwoMonths.q4TwoMonths = { answer: this.swycForm.controls['q4TwoMonths'].value };
        this.swycTwoMonths.q5TwoMonths = { answer: this.swycForm.controls['q5TwoMonths'].value };
        this.swycTwoMonths.q6TwoMonths = { answer: this.swycForm.controls['q6TwoMonths'].value };
        this.swycTwoMonths.q7TwoMonths = { answer: this.swycForm.controls['q7TwoMonths'].value };
        this.swycTwoMonths.q8TwoMonths = { answer: this.swycForm.controls['q8TwoMonths'].value };
        this.swycTwoMonths.q9TwoMonths = { answer: this.swycForm.controls['q9TwoMonths'].value };
        this.swycTwoMonths.q10TwoMonths = { answer: this.swycForm.controls['q10TwoMonths'].value };
        this.swycTwoMonths.q11TwoMonths = { answer: this.swycForm.controls['q11TwoMonths'].value };
        this.swycTwoMonths.q12TwoMonths = { answer: this.swycForm.controls['q12TwoMonths'].value };
        this.swycTwoMonths.q13TwoMonths = { answer: this.swycForm.controls['q13TwoMonths'].value };
        this.swycTwoMonths.q14TwoMonths = { answer: this.swycForm.controls['q14TwoMonths'].value };
        this.swycTwoMonths.q15TwoMonths = { answer: this.swycForm.controls['q15TwoMonths'].value };
        this.swycTwoMonths.q16TwoMonths = { answer: this.swycForm.controls['q16TwoMonths'].value };
        this.swycTwoMonths.q17TwoMonths = { answer: this.swycForm.controls['q17TwoMonths'].value };
        this.swycTwoMonths.q18TwoMonths = { answer: this.swycForm.controls['q18TwoMonths'].value };
        this.swycTwoMonths.q19TwoMonths = { answer: this.swycForm.controls['q19TwoMonths'].value };
        this.swycTwoMonths.q20TwoMonths = { answer: this.swycForm.controls['q20TwoMonths'].value };
        this.swycTwoMonths.q21TwoMonths = { answer: this.swycForm.controls['q21TwoMonths'].value };
        this.swycTwoMonths.q22TwoMonths = { answer: this.swycForm.controls['q22TwoMonths'].value };
        this.swycTwoMonths.q23TwoMonths = { answer: this.swycForm.controls['q23TwoMonths'].value };
        this.swycTwoMonths.q24TwoMonths = { answer: this.swycForm.controls['q24TwoMonths'].value };
        this.swycTwoMonths.q25TwoMonths = { answer: this.swycForm.controls['q25TwoMonths'].value };
        this.swycTwoMonths.q26TwoMonths = { answer: this.swycForm.controls['q26TwoMonths'].value };
        this.swycTwoMonths.q27TwoMonths = { answer: this.swycForm.controls['q27TwoMonths'].value };
        this.swycTwoMonths.q28TwoMonths = { answer: this.swycForm.controls['q28TwoMonths'].value };
        this.swycTwoMonths.q29TwoMonths = { answer: this.swycForm.controls['q29TwoMonths'].value };
        this.swycTwoMonths.q30TwoMonths = { answer: this.swycForm.controls['q30TwoMonths'].value };
        this.swycTwoMonths.q31TwoMonths = { answer: this.swycForm.controls['q31TwoMonths'].value };
        this.swycTwoMonths.q32TwoMonths = { answer: this.swycForm.controls['q32TwoMonths'].value };
        this.swycTwoMonths.q33TwoMonths = { answer: this.swycForm.controls['q33TwoMonths'].value };
        this.swycTwoMonths.q34TwoMonths = { answer: this.swycForm.controls['q34TwoMonths'].value };
        this.swycTwoMonths.q35TwoMonths = { answer: this.swycForm.controls['q35TwoMonths'].value };
        this.swycTwoMonths.q36TwoMonths = { answer: this.swycForm.controls['q36TwoMonths'].value };
        this.swycTwoMonths.q37TwoMonths = { answer: this.swycForm.controls['q37TwoMonths'].value };
        this.swycTwoMonths.q38TwoMonths = { answer: this.swycForm.controls['q38TwoMonths'].value };
        this.swycTwoMonths.q39TwoMonths = { answer: this.swycForm.controls['q39TwoMonths'].value };
        this.swycTwoMonths.q40TwoMonths = { answer: this.swycForm.controls['q40TwoMonths'].value };
        this.swycTwoMonths.q41TwoMonths = { answer: this.swycForm.controls['q41TwoMonths'].value };
        this.swycTwoMonths.q42TwoMonths = { answer: this.swycForm.controls['q42TwoMonths'].value };

        output = this.swycTwoMonths;
        break;

      case "4months":
        this.swycFourMonths = Object.assign({}, this.swycFourMonths);
        this.swycFourMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycFourMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycFourMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycFourMonths.ageGroup = this.selectedAgeGroup;
        this.swycFourMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));

        this.swycFourMonths.q1FourMonths = { answer: this.swycForm.controls['q1FourMonths'].value };
        this.swycFourMonths.q2FourMonths = { answer: this.swycForm.controls['q2FourMonths'].value };
        this.swycFourMonths.q3FourMonths = { answer: this.swycForm.controls['q3FourMonths'].value };
        this.swycFourMonths.q4FourMonths = { answer: this.swycForm.controls['q4FourMonths'].value };
        this.swycFourMonths.q5FourMonths = { answer: this.swycForm.controls['q5FourMonths'].value };
        this.swycFourMonths.q6FourMonths = { answer: this.swycForm.controls['q6FourMonths'].value };
        this.swycFourMonths.q7FourMonths = { answer: this.swycForm.controls['q7FourMonths'].value };
        this.swycFourMonths.q8FourMonths = { answer: this.swycForm.controls['q8FourMonths'].value };
        this.swycFourMonths.q9FourMonths = { answer: this.swycForm.controls['q9FourMonths'].value };
        this.swycFourMonths.q10FourMonths = { answer: this.swycForm.controls['q10FourMonths'].value };
        this.swycFourMonths.q11FourMonths = { answer: this.swycForm.controls['q11FourMonths'].value };
        this.swycFourMonths.q12FourMonths = { answer: this.swycForm.controls['q12FourMonths'].value };
        this.swycFourMonths.q13FourMonths = { answer: this.swycForm.controls['q13FourMonths'].value };
        this.swycFourMonths.q14FourMonths = { answer: this.swycForm.controls['q14FourMonths'].value };
        this.swycFourMonths.q15FourMonths = { answer: this.swycForm.controls['q15FourMonths'].value };
        this.swycFourMonths.q16FourMonths = { answer: this.swycForm.controls['q16FourMonths'].value };
        this.swycFourMonths.q17FourMonths = { answer: this.swycForm.controls['q17FourMonths'].value };
        this.swycFourMonths.q18FourMonths = { answer: this.swycForm.controls['q18FourMonths'].value };
        this.swycFourMonths.q19FourMonths = { answer: this.swycForm.controls['q19FourMonths'].value };
        this.swycFourMonths.q20FourMonths = { answer: this.swycForm.controls['q20FourMonths'].value };
        this.swycFourMonths.q21FourMonths = { answer: this.swycForm.controls['q21FourMonths'].value };
        this.swycFourMonths.q22FourMonths = { answer: this.swycForm.controls['q22FourMonths'].value };
        this.swycFourMonths.q23FourMonths = { answer: this.swycForm.controls['q23FourMonths'].value };
        this.swycFourMonths.q24FourMonths = { answer: this.swycForm.controls['q24FourMonths'].value };
        this.swycFourMonths.q25FourMonths = { answer: this.swycForm.controls['q25FourMonths'].value };
        this.swycFourMonths.q26FourMonths = { answer: this.swycForm.controls['q26FourMonths'].value };
        this.swycFourMonths.q27FourMonths = { answer: this.swycForm.controls['q27FourMonths'].value };
        this.swycFourMonths.q28FourMonths = { answer: this.swycForm.controls['q28FourMonths'].value };
        this.swycFourMonths.q29FourMonths = { answer: this.swycForm.controls['q29FourMonths'].value };
        this.swycFourMonths.q30FourMonths = { answer: this.swycForm.controls['q30FourMonths'].value };
        this.swycFourMonths.q31FourMonths = { answer: this.swycForm.controls['q31FourMonths'].value };
        this.swycFourMonths.q32FourMonths = { answer: this.swycForm.controls['q32FourMonths'].value };
        this.swycFourMonths.q33FourMonths = { answer: this.swycForm.controls['q33FourMonths'].value };
        this.swycFourMonths.q34FourMonths = { answer: this.swycForm.controls['q34FourMonths'].value };
        this.swycFourMonths.q35FourMonths = { answer: this.swycForm.controls['q35FourMonths'].value };
        this.swycFourMonths.q36FourMonths = { answer: this.swycForm.controls['q36FourMonths'].value };
        this.swycFourMonths.q37FourMonths = { answer: this.swycForm.controls['q37FourMonths'].value };
        this.swycFourMonths.q38FourMonths = { answer: this.swycForm.controls['q38FourMonths'].value };
        this.swycFourMonths.q39FourMonths = { answer: this.swycForm.controls['q39FourMonths'].value };
        this.swycFourMonths.q40FourMonths = { answer: this.swycForm.controls['q40FourMonths'].value };
        this.swycFourMonths.q41FourMonths = { answer: this.swycForm.controls['q41FourMonths'].value };
        this.swycFourMonths.q42FourMonths = { answer: this.swycForm.controls['q42FourMonths'].value };

        output = this.swycFourMonths;
        break;

      case "6months":
        this.swycSixMonths = Object.assign({}, this.swycSixMonths);
        this.swycSixMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycSixMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycSixMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycSixMonths.ageGroup = this.selectedAgeGroup;
        this.swycSixMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycSixMonths.q1SixMonths = { answer: this.swycForm.controls['q1SixMonths'].value };
        this.swycSixMonths.q2SixMonths = { answer: this.swycForm.controls['q2SixMonths'].value };
        this.swycSixMonths.q3SixMonths = { answer: this.swycForm.controls['q3SixMonths'].value };
        this.swycSixMonths.q4SixMonths = { answer: this.swycForm.controls['q4SixMonths'].value };
        this.swycSixMonths.q5SixMonths = { answer: this.swycForm.controls['q5SixMonths'].value };
        this.swycSixMonths.q6SixMonths = { answer: this.swycForm.controls['q6SixMonths'].value };
        this.swycSixMonths.q7SixMonths = { answer: this.swycForm.controls['q7SixMonths'].value };
        this.swycSixMonths.q8SixMonths = { answer: this.swycForm.controls['q8SixMonths'].value };
        this.swycSixMonths.q9SixMonths = { answer: this.swycForm.controls['q9SixMonths'].value };
        this.swycSixMonths.q10SixMonths = { answer: this.swycForm.controls['q10SixMonths'].value };
        this.swycSixMonths.q11SixMonths = { answer: this.swycForm.controls['q11SixMonths'].value };
        this.swycSixMonths.q12SixMonths = { answer: this.swycForm.controls['q12SixMonths'].value };
        this.swycSixMonths.q13SixMonths = { answer: this.swycForm.controls['q13SixMonths'].value };
        this.swycSixMonths.q14SixMonths = { answer: this.swycForm.controls['q14SixMonths'].value };
        this.swycSixMonths.q15SixMonths = { answer: this.swycForm.controls['q15SixMonths'].value };
        this.swycSixMonths.q16SixMonths = { answer: this.swycForm.controls['q16SixMonths'].value };
        this.swycSixMonths.q17SixMonths = { answer: this.swycForm.controls['q17SixMonths'].value };
        this.swycSixMonths.q18SixMonths = { answer: this.swycForm.controls['q18SixMonths'].value };
        this.swycSixMonths.q19SixMonths = { answer: this.swycForm.controls['q19SixMonths'].value };
        this.swycSixMonths.q20SixMonths = { answer: this.swycForm.controls['q20SixMonths'].value };
        this.swycSixMonths.q21SixMonths = { answer: this.swycForm.controls['q21SixMonths'].value };
        this.swycSixMonths.q22SixMonths = { answer: this.swycForm.controls['q22SixMonths'].value };
        this.swycSixMonths.q23SixMonths = { answer: this.swycForm.controls['q23SixMonths'].value };
        this.swycSixMonths.q24SixMonths = { answer: this.swycForm.controls['q24SixMonths'].value };
        this.swycSixMonths.q25SixMonths = { answer: this.swycForm.controls['q25SixMonths'].value };
        this.swycSixMonths.q26SixMonths = { answer: this.swycForm.controls['q26SixMonths'].value };
        this.swycSixMonths.q27SixMonths = { answer: this.swycForm.controls['q27SixMonths'].value };
        this.swycSixMonths.q28SixMonths = { answer: this.swycForm.controls['q28SixMonths'].value };
        this.swycSixMonths.q29SixMonths = { answer: this.swycForm.controls['q29SixMonths'].value };
        this.swycSixMonths.q30SixMonths = { answer: this.swycForm.controls['q30SixMonths'].value };
        this.swycSixMonths.q31SixMonths = { answer: this.swycForm.controls['q31SixMonths'].value };
        this.swycSixMonths.q32SixMonths = { answer: this.swycForm.controls['q32SixMonths'].value };
        this.swycSixMonths.q33SixMonths = { answer: this.swycForm.controls['q33SixMonths'].value };
        this.swycSixMonths.q34SixMonths = { answer: this.swycForm.controls['q34SixMonths'].value };
        this.swycSixMonths.q35SixMonths = { answer: this.swycForm.controls['q35SixMonths'].value };
        this.swycSixMonths.q36SixMonths = { answer: this.swycForm.controls['q36SixMonths'].value };
        this.swycSixMonths.q37SixMonths = { answer: this.swycForm.controls['q37SixMonths'].value };
        this.swycSixMonths.q38SixMonths = { answer: this.swycForm.controls['q38SixMonths'].value };
        this.swycSixMonths.q39SixMonths = { answer: this.swycForm.controls['q39SixMonths'].value };
        this.swycSixMonths.q40SixMonths = { answer: this.swycForm.controls['q40SixMonths'].value };
        this.swycSixMonths.q41SixMonths = { answer: this.swycForm.controls['q41SixMonths'].value };
        this.swycSixMonths.q42SixMonths = { answer: this.swycForm.controls['q42SixMonths'].value };
        output = this.swycSixMonths;
        break;
      case "9months":
        this.swycNineMonths = Object.assign({}, this.swycNineMonths);
        this.swycNineMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycNineMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycNineMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycNineMonths.ageGroup = this.selectedAgeGroup;
        this.swycNineMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycNineMonths.q1NineMonths = { answer: this.swycForm.controls['q1NineMonths'].value };
        this.swycNineMonths.q2NineMonths = { answer: this.swycForm.controls['q2NineMonths'].value };
        this.swycNineMonths.q3NineMonths = { answer: this.swycForm.controls['q3NineMonths'].value };
        this.swycNineMonths.q4NineMonths = { answer: this.swycForm.controls['q4NineMonths'].value };
        this.swycNineMonths.q5NineMonths = { answer: this.swycForm.controls['q5NineMonths'].value };
        this.swycNineMonths.q6NineMonths = { answer: this.swycForm.controls['q6NineMonths'].value };
        this.swycNineMonths.q7NineMonths = { answer: this.swycForm.controls['q7NineMonths'].value };
        this.swycNineMonths.q8NineMonths = { answer: this.swycForm.controls['q8NineMonths'].value };
        this.swycNineMonths.q9NineMonths = { answer: this.swycForm.controls['q9NineMonths'].value };
        this.swycNineMonths.q10NineMonths = { answer: this.swycForm.controls['q10NineMonths'].value };
        this.swycNineMonths.q11NineMonths = { answer: this.swycForm.controls['q11NineMonths'].value };
        this.swycNineMonths.q12NineMonths = { answer: this.swycForm.controls['q12NineMonths'].value };
        this.swycNineMonths.q13NineMonths = { answer: this.swycForm.controls['q13NineMonths'].value };
        this.swycNineMonths.q14NineMonths = { answer: this.swycForm.controls['q14NineMonths'].value };
        this.swycNineMonths.q15NineMonths = { answer: this.swycForm.controls['q15NineMonths'].value };
        this.swycNineMonths.q16NineMonths = { answer: this.swycForm.controls['q16NineMonths'].value };
        this.swycNineMonths.q17NineMonths = { answer: this.swycForm.controls['q17NineMonths'].value };
        this.swycNineMonths.q18NineMonths = { answer: this.swycForm.controls['q18NineMonths'].value };
        this.swycNineMonths.q19NineMonths = { answer: this.swycForm.controls['q19NineMonths'].value };
        this.swycNineMonths.q20NineMonths = { answer: this.swycForm.controls['q20NineMonths'].value };
        this.swycNineMonths.q21NineMonths = { answer: this.swycForm.controls['q21NineMonths'].value };
        this.swycNineMonths.q22NineMonths = { answer: this.swycForm.controls['q22NineMonths'].value };
        this.swycNineMonths.q23NineMonths = { answer: this.swycForm.controls['q23NineMonths'].value };
        this.swycNineMonths.q24NineMonths = { answer: this.swycForm.controls['q24NineMonths'].value };
        this.swycNineMonths.q25NineMonths = { answer: this.swycForm.controls['q25NineMonths'].value };
        this.swycNineMonths.q26NineMonths = { answer: this.swycForm.controls['q26NineMonths'].value };
        this.swycNineMonths.q27NineMonths = { answer: this.swycForm.controls['q27NineMonths'].value };
        this.swycNineMonths.q28NineMonths = { answer: this.swycForm.controls['q28NineMonths'].value };
        this.swycNineMonths.q29NineMonths = { answer: this.swycForm.controls['q29NineMonths'].value };
        this.swycNineMonths.q30NineMonths = { answer: this.swycForm.controls['q30NineMonths'].value };
        this.swycNineMonths.q31NineMonths = { answer: this.swycForm.controls['q31NineMonths'].value };
        this.swycNineMonths.q32NineMonths = { answer: this.swycForm.controls['q32NineMonths'].value };
        this.swycNineMonths.q33NineMonths = { answer: this.swycForm.controls['q33NineMonths'].value };
        this.swycNineMonths.q34NineMonths = { answer: this.swycForm.controls['q34NineMonths'].value };
        output = this.swycNineMonths;
        break;
      case "12months":
        this.swycTwelveMonths = Object.assign({}, this.swycTwelveMonths);
        this.swycTwelveMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycTwelveMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycTwelveMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycTwelveMonths.ageGroup = this.selectedAgeGroup;
        this.swycTwelveMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycTwelveMonths.q1TwelveMonths = { answer: this.swycForm.controls['q1TwelveMonths'].value };
        this.swycTwelveMonths.q2TwelveMonths = { answer: this.swycForm.controls['q2TwelveMonths'].value };
        this.swycTwelveMonths.q3TwelveMonths = { answer: this.swycForm.controls['q3TwelveMonths'].value };
        this.swycTwelveMonths.q4TwelveMonths = { answer: this.swycForm.controls['q4TwelveMonths'].value };
        this.swycTwelveMonths.q5TwelveMonths = { answer: this.swycForm.controls['q5TwelveMonths'].value };
        this.swycTwelveMonths.q6TwelveMonths = { answer: this.swycForm.controls['q6TwelveMonths'].value };
        this.swycTwelveMonths.q7TwelveMonths = { answer: this.swycForm.controls['q7TwelveMonths'].value };
        this.swycTwelveMonths.q8TwelveMonths = { answer: this.swycForm.controls['q8TwelveMonths'].value };
        this.swycTwelveMonths.q9TwelveMonths = { answer: this.swycForm.controls['q9TwelveMonths'].value };
        this.swycTwelveMonths.q10TwelveMonths = { answer: this.swycForm.controls['q10TwelveMonths'].value };
        this.swycTwelveMonths.q11TwelveMonths = { answer: this.swycForm.controls['q11TwelveMonths'].value };
        this.swycTwelveMonths.q12TwelveMonths = { answer: this.swycForm.controls['q12TwelveMonths'].value };
        this.swycTwelveMonths.q13TwelveMonths = { answer: this.swycForm.controls['q13TwelveMonths'].value };
        this.swycTwelveMonths.q14TwelveMonths = { answer: this.swycForm.controls['q14TwelveMonths'].value };
        this.swycTwelveMonths.q15TwelveMonths = { answer: this.swycForm.controls['q15TwelveMonths'].value };
        this.swycTwelveMonths.q16TwelveMonths = { answer: this.swycForm.controls['q16TwelveMonths'].value };
        this.swycTwelveMonths.q17TwelveMonths = { answer: this.swycForm.controls['q17TwelveMonths'].value };
        this.swycTwelveMonths.q18TwelveMonths = { answer: this.swycForm.controls['q18TwelveMonths'].value };
        this.swycTwelveMonths.q19TwelveMonths = { answer: this.swycForm.controls['q19TwelveMonths'].value };
        this.swycTwelveMonths.q20TwelveMonths = { answer: this.swycForm.controls['q20TwelveMonths'].value };
        this.swycTwelveMonths.q21TwelveMonths = { answer: this.swycForm.controls['q21TwelveMonths'].value };
        this.swycTwelveMonths.q22TwelveMonths = { answer: this.swycForm.controls['q22TwelveMonths'].value };
        this.swycTwelveMonths.q23TwelveMonths = { answer: this.swycForm.controls['q23TwelveMonths'].value };
        this.swycTwelveMonths.q24TwelveMonths = { answer: this.swycForm.controls['q24TwelveMonths'].value };
        this.swycTwelveMonths.q25TwelveMonths = { answer: this.swycForm.controls['q25TwelveMonths'].value };
        this.swycTwelveMonths.q26TwelveMonths = { answer: this.swycForm.controls['q26TwelveMonths'].value };
        this.swycTwelveMonths.q27TwelveMonths = { answer: this.swycForm.controls['q27TwelveMonths'].value };
        this.swycTwelveMonths.q28TwelveMonths = { answer: this.swycForm.controls['q28TwelveMonths'].value };
        this.swycTwelveMonths.q29TwelveMonths = { answer: this.swycForm.controls['q29TwelveMonths'].value };
        this.swycTwelveMonths.q30TwelveMonths = { answer: this.swycForm.controls['q30TwelveMonths'].value };
        this.swycTwelveMonths.q31TwelveMonths = { answer: this.swycForm.controls['q31TwelveMonths'].value };
        this.swycTwelveMonths.q32TwelveMonths = { answer: this.swycForm.controls['q32TwelveMonths'].value };
        this.swycTwelveMonths.q33TwelveMonths = { answer: this.swycForm.controls['q33TwelveMonths'].value };
        this.swycTwelveMonths.q34TwelveMonths = { answer: this.swycForm.controls['q34TwelveMonths'].value };
        output = this.swycTwelveMonths;
        break;
      case "15months":
        this.swycFifteenMonths = Object.assign({}, this.swycFifteenMonths);
        this.swycFifteenMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycFifteenMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycFifteenMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycFifteenMonths.ageGroup = this.selectedAgeGroup;
        this.swycFifteenMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycFifteenMonths.q1FifteenMonths = { answer: this.swycForm.controls['q1FifteenMonths'].value };
        this.swycFifteenMonths.q2FifteenMonths = { answer: this.swycForm.controls['q2FifteenMonths'].value };
        this.swycFifteenMonths.q3FifteenMonths = { answer: this.swycForm.controls['q3FifteenMonths'].value };
        this.swycFifteenMonths.q4FifteenMonths = { answer: this.swycForm.controls['q4FifteenMonths'].value };
        this.swycFifteenMonths.q5FifteenMonths = { answer: this.swycForm.controls['q5FifteenMonths'].value };
        this.swycFifteenMonths.q6FifteenMonths = { answer: this.swycForm.controls['q6FifteenMonths'].value };
        this.swycFifteenMonths.q7FifteenMonths = { answer: this.swycForm.controls['q7FifteenMonths'].value };
        this.swycFifteenMonths.q8FifteenMonths = { answer: this.swycForm.controls['q8FifteenMonths'].value };
        this.swycFifteenMonths.q9FifteenMonths = { answer: this.swycForm.controls['q9FifteenMonths'].value };
        this.swycFifteenMonths.q10FifteenMonths = { answer: this.swycForm.controls['q10FifteenMonths'].value };
        this.swycFifteenMonths.q11FifteenMonths = { answer: this.swycForm.controls['q11FifteenMonths'].value };
        this.swycFifteenMonths.q12FifteenMonths = { answer: this.swycForm.controls['q12FifteenMonths'].value };
        this.swycFifteenMonths.q13FifteenMonths = { answer: this.swycForm.controls['q13FifteenMonths'].value };
        this.swycFifteenMonths.q14FifteenMonths = { answer: this.swycForm.controls['q14FifteenMonths'].value };
        this.swycFifteenMonths.q15FifteenMonths = { answer: this.swycForm.controls['q15FifteenMonths'].value };
        this.swycFifteenMonths.q16FifteenMonths = { answer: this.swycForm.controls['q16FifteenMonths'].value };
        this.swycFifteenMonths.q17FifteenMonths = { answer: this.swycForm.controls['q17FifteenMonths'].value };
        this.swycFifteenMonths.q18FifteenMonths = { answer: this.swycForm.controls['q18FifteenMonths'].value };
        this.swycFifteenMonths.q19FifteenMonths = { answer: this.swycForm.controls['q19FifteenMonths'].value };
        this.swycFifteenMonths.q20FifteenMonths = { answer: this.swycForm.controls['q20FifteenMonths'].value };
        this.swycFifteenMonths.q21FifteenMonths = { answer: this.swycForm.controls['q21FifteenMonths'].value };
        this.swycFifteenMonths.q22FifteenMonths = { answer: this.swycForm.controls['q22FifteenMonths'].value };
        this.swycFifteenMonths.q23FifteenMonths = { answer: this.swycForm.controls['q23FifteenMonths'].value };
        this.swycFifteenMonths.q24FifteenMonths = { answer: this.swycForm.controls['q24FifteenMonths'].value };
        this.swycFifteenMonths.q25FifteenMonths = { answer: this.swycForm.controls['q25FifteenMonths'].value };
        this.swycFifteenMonths.q26FifteenMonths = { answer: this.swycForm.controls['q26FifteenMonths'].value };
        this.swycFifteenMonths.q27FifteenMonths = { answer: this.swycForm.controls['q27FifteenMonths'].value };
        this.swycFifteenMonths.q28FifteenMonths = { answer: this.swycForm.controls['q28FifteenMonths'].value };
        this.swycFifteenMonths.q29FifteenMonths = { answer: this.swycForm.controls['q29FifteenMonths'].value };
        this.swycFifteenMonths.q30FifteenMonths = { answer: this.swycForm.controls['q30FifteenMonths'].value };
        this.swycFifteenMonths.q31FifteenMonths = { answer: this.swycForm.controls['q31FifteenMonths'].value };
        this.swycFifteenMonths.q32FifteenMonths = { answer: this.swycForm.controls['q32FifteenMonths'].value };
        this.swycFifteenMonths.q33FifteenMonths = { answer: this.swycForm.controls['q33FifteenMonths'].value };
        this.swycFifteenMonths.q34FifteenMonths = { answer: this.swycForm.controls['q34FifteenMonths'].value };
        output = this.swycFifteenMonths;
        break;
      case "18months":
        this.swycEighteenMonths = Object.assign({}, this.swycEighteenMonths);
        this.swycEighteenMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycEighteenMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycEighteenMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycEighteenMonths.ageGroup = this.selectedAgeGroup;
        this.swycEighteenMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycEighteenMonths.q1EighteenMonths = { answer: this.swycForm.controls['q1EighteenMonths'].value };
        this.swycEighteenMonths.q2EighteenMonths = { answer: this.swycForm.controls['q2EighteenMonths'].value };
        this.swycEighteenMonths.q3EighteenMonths = { answer: this.swycForm.controls['q3EighteenMonths'].value };
        this.swycEighteenMonths.q4EighteenMonths = { answer: this.swycForm.controls['q4EighteenMonths'].value };
        this.swycEighteenMonths.q5EighteenMonths = { answer: this.swycForm.controls['q5EighteenMonths'].value };
        this.swycEighteenMonths.q6EighteenMonths = { answer: this.swycForm.controls['q6EighteenMonths'].value };
        this.swycEighteenMonths.q7EighteenMonths = { answer: this.swycForm.controls['q7EighteenMonths'].value };
        this.swycEighteenMonths.q8EighteenMonths = { answer: this.swycForm.controls['q8EighteenMonths'].value };
        this.swycEighteenMonths.q9EighteenMonths = { answer: this.swycForm.controls['q9EighteenMonths'].value };
        this.swycEighteenMonths.q10EighteenMonths = { answer: this.swycForm.controls['q10EighteenMonths'].value };
        this.swycEighteenMonths.q11EighteenMonths = { answer: this.swycForm.controls['q11EighteenMonths'].value };
        this.swycEighteenMonths.q12EighteenMonths = { answer: this.swycForm.controls['q12EighteenMonths'].value };
        this.swycEighteenMonths.q13EighteenMonths = { answer: this.swycForm.controls['q13EighteenMonths'].value };
        this.swycEighteenMonths.q14EighteenMonths = { answer: this.swycForm.controls['q14EighteenMonths'].value };
        this.swycEighteenMonths.q15EighteenMonths = { answer: this.swycForm.controls['q15EighteenMonths'].value };
        this.swycEighteenMonths.q16EighteenMonths = { answer: this.swycForm.controls['q16EighteenMonths'].value };
        this.swycEighteenMonths.q17EighteenMonths = { answer: this.swycForm.controls['q17EighteenMonths'].value };
        this.swycEighteenMonths.q18EighteenMonths = { answer: this.swycForm.controls['q18EighteenMonths'].value };
        this.swycEighteenMonths.q19EighteenMonths = { answer: this.swycForm.controls['q19EighteenMonths'].value };
        this.swycEighteenMonths.q20EighteenMonths = { answer: this.swycForm.controls['q20EighteenMonths'].value };
        this.swycEighteenMonths.q21EighteenMonths = { answer: this.swycForm.controls['q21EighteenMonths'].value };
        this.swycEighteenMonths.q22EighteenMonths = { answer: this.swycForm.controls['q22EighteenMonths'].value };
        this.swycEighteenMonths.q23EighteenMonths = { answer: this.swycForm.controls['q23EighteenMonths'].value };
        this.swycEighteenMonths.q24EighteenMonths = { answer: this.swycForm.controls['q24EighteenMonths'].value };
        this.swycEighteenMonths.q25EighteenMonths = { answer: this.swycForm.controls['q25EighteenMonths'].value };
        this.swycEighteenMonths.q26EighteenMonths = { answer: this.swycForm.controls['q26EighteenMonths'].value };
        this.swycEighteenMonths.q27EighteenMonths = { answer: this.swycForm.controls['q27EighteenMonths'].value };
        this.swycEighteenMonths.q28EighteenMonths = { answer: this.swycForm.controls['q28EighteenMonths'].value };
        this.swycEighteenMonths.q29EighteenMonths = { answer: this.swycForm.controls['q29EighteenMonths'].value };
        this.swycEighteenMonths.q30EighteenMonths = { answer: this.swycForm.controls['q30EighteenMonths'].value };
        this.swycEighteenMonths.q31EighteenMonths = { answer: this.swycForm.controls['q31EighteenMonths'].value };
        this.swycEighteenMonths.q32EighteenMonths = { answer: this.swycForm.controls['q32EighteenMonths'].value };
        this.swycEighteenMonths.q33EighteenMonths = { answer: this.swycForm.controls['q33EighteenMonths'].value };
        this.swycEighteenMonths.q34EighteenMonths = { answer: this.swycForm.controls['q34EighteenMonths'].value };
        this.swycEighteenMonths.q35EighteenMonths = { answer: this.swycForm.controls['q35EighteenMonths'].value };
        this.swycEighteenMonths.q36EighteenMonths = { answer: this.swycForm.controls['q36EighteenMonths'].value };
        this.swycEighteenMonths.q37EighteenMonths = { answer: this.swycForm.controls['q37EighteenMonths'].value };
        this.swycEighteenMonths.q38EighteenMonths = { answer: this.swycForm.controls['q38EighteenMonths'].value };
        this.swycEighteenMonths.q39EighteenMonths = { answer: this.swycForm.controls['q39EighteenMonths'].value };
        this.swycEighteenMonths.q40EighteenMonths = { answer: this.swycForm.controls['q40EighteenMonths'].value };
        this.swycEighteenMonths.q41EighteenMonths = { answer: this.swycForm.controls['q41EighteenMonths'].value };
        this.swycEighteenMonths.q42EighteenMonths = { answer: this.swycForm.controls['q42EighteenMonths'].value };
        this.swycEighteenMonths.q43EighteenMonths = { answer: this.swycForm.controls['q43EighteenMonths'].value };
        this.swycEighteenMonths.q44EighteenMonths = { answer: this.swycForm.controls['q44EighteenMonths'].value };
        this.swycEighteenMonths.q45EighteenMonths = { answer: this.swycForm.controls['q45EighteenMonths'].value };
        this.swycEighteenMonths.q46EighteenMonths = { answer: this.swycForm.controls['q46EighteenMonths'].value };
        output = this.swycEighteenMonths;
        break;
      case "24months":
        this.swycTwentyFourMonths = Object.assign({}, this.swycTwentyFourMonths);
        this.swycTwentyFourMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycTwentyFourMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycTwentyFourMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycTwentyFourMonths.ageGroup = this.selectedAgeGroup;
        this.swycTwentyFourMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycTwentyFourMonths.q1TwentyFourMonths = { answer: this.swycForm.controls['q1TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q2TwentyFourMonths = { answer: this.swycForm.controls['q2TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q3TwentyFourMonths = { answer: this.swycForm.controls['q3TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q4TwentyFourMonths = { answer: this.swycForm.controls['q4TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q5TwentyFourMonths = { answer: this.swycForm.controls['q5TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q6TwentyFourMonths = { answer: this.swycForm.controls['q6TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q7TwentyFourMonths = { answer: this.swycForm.controls['q7TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q8TwentyFourMonths = { answer: this.swycForm.controls['q8TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q9TwentyFourMonths = { answer: this.swycForm.controls['q9TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q10TwentyFourMonths = { answer: this.swycForm.controls['q10TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q11TwentyFourMonths = { answer: this.swycForm.controls['q11TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q12TwentyFourMonths = { answer: this.swycForm.controls['q12TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q13TwentyFourMonths = { answer: this.swycForm.controls['q13TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q14TwentyFourMonths = { answer: this.swycForm.controls['q14TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q15TwentyFourMonths = { answer: this.swycForm.controls['q15TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q16TwentyFourMonths = { answer: this.swycForm.controls['q16TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q17TwentyFourMonths = { answer: this.swycForm.controls['q17TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q18TwentyFourMonths = { answer: this.swycForm.controls['q18TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q19TwentyFourMonths = { answer: this.swycForm.controls['q19TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q20TwentyFourMonths = { answer: this.swycForm.controls['q20TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q21TwentyFourMonths = { answer: this.swycForm.controls['q21TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q22TwentyFourMonths = { answer: this.swycForm.controls['q22TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q23TwentyFourMonths = { answer: this.swycForm.controls['q23TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q24TwentyFourMonths = { answer: this.swycForm.controls['q24TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q25TwentyFourMonths = { answer: this.swycForm.controls['q25TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q26TwentyFourMonths = { answer: this.swycForm.controls['q26TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q27TwentyFourMonths = { answer: this.swycForm.controls['q27TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q28TwentyFourMonths = { answer: this.swycForm.controls['q28TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q29TwentyFourMonths = { answer: this.swycForm.controls['q29TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q30TwentyFourMonths = { answer: this.swycForm.controls['q30TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q31TwentyFourMonths = { answer: this.swycForm.controls['q31TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q32TwentyFourMonths = { answer: this.swycForm.controls['q32TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q33TwentyFourMonths = { answer: this.swycForm.controls['q33TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q34TwentyFourMonths = { answer: this.swycForm.controls['q34TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q35TwentyFourMonths = { answer: this.swycForm.controls['q35TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q36TwentyFourMonths = { answer: this.swycForm.controls['q36TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q37TwentyFourMonths = { answer: this.swycForm.controls['q37TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q38TwentyFourMonths = { answer: this.swycForm.controls['q38TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q39TwentyFourMonths = { answer: this.swycForm.controls['q39TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q40TwentyFourMonths = { answer: this.swycForm.controls['q40TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q41TwentyFourMonths = { answer: this.swycForm.controls['q41TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q42TwentyFourMonths = { answer: this.swycForm.controls['q42TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q43TwentyFourMonths = { answer: this.swycForm.controls['q43TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q44TwentyFourMonths = { answer: this.swycForm.controls['q44TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q45TwentyFourMonths = { answer: this.swycForm.controls['q45TwentyFourMonths'].value };
        this.swycTwentyFourMonths.q46TwentyFourMonths = { answer: this.swycForm.controls['q46TwentyFourMonths'].value };
        output = this.swycTwentyFourMonths;
        break;
      case "30months":
        this.swycThirtyMonths = Object.assign({}, this.swycThirtyMonths);
        this.swycThirtyMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycThirtyMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycThirtyMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycThirtyMonths.ageGroup = this.selectedAgeGroup;
        this.swycThirtyMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycThirtyMonths.q1ThirtyMonths = { answer: this.swycForm.controls['q1ThirtyMonths'].value };
        this.swycThirtyMonths.q2ThirtyMonths = { answer: this.swycForm.controls['q2ThirtyMonths'].value };
        this.swycThirtyMonths.q3ThirtyMonths = { answer: this.swycForm.controls['q3ThirtyMonths'].value };
        this.swycThirtyMonths.q4ThirtyMonths = { answer: this.swycForm.controls['q4ThirtyMonths'].value };
        this.swycThirtyMonths.q5ThirtyMonths = { answer: this.swycForm.controls['q5ThirtyMonths'].value };
        this.swycThirtyMonths.q6ThirtyMonths = { answer: this.swycForm.controls['q6ThirtyMonths'].value };
        this.swycThirtyMonths.q7ThirtyMonths = { answer: this.swycForm.controls['q7ThirtyMonths'].value };
        this.swycThirtyMonths.q8ThirtyMonths = { answer: this.swycForm.controls['q8ThirtyMonths'].value };
        this.swycThirtyMonths.q9ThirtyMonths = { answer: this.swycForm.controls['q9ThirtyMonths'].value };
        this.swycThirtyMonths.q10ThirtyMonths = { answer: this.swycForm.controls['q10ThirtyMonths'].value };
        this.swycThirtyMonths.q11ThirtyMonths = { answer: this.swycForm.controls['q11ThirtyMonths'].value };
        this.swycThirtyMonths.q12ThirtyMonths = { answer: this.swycForm.controls['q12ThirtyMonths'].value };
        this.swycThirtyMonths.q13ThirtyMonths = { answer: this.swycForm.controls['q13ThirtyMonths'].value };
        this.swycThirtyMonths.q14ThirtyMonths = { answer: this.swycForm.controls['q14ThirtyMonths'].value };
        this.swycThirtyMonths.q15ThirtyMonths = { answer: this.swycForm.controls['q15ThirtyMonths'].value };
        this.swycThirtyMonths.q16ThirtyMonths = { answer: this.swycForm.controls['q16ThirtyMonths'].value };
        this.swycThirtyMonths.q17ThirtyMonths = { answer: this.swycForm.controls['q17ThirtyMonths'].value };
        this.swycThirtyMonths.q18ThirtyMonths = { answer: this.swycForm.controls['q18ThirtyMonths'].value };
        this.swycThirtyMonths.q19ThirtyMonths = { answer: this.swycForm.controls['q19ThirtyMonths'].value };
        this.swycThirtyMonths.q20ThirtyMonths = { answer: this.swycForm.controls['q20ThirtyMonths'].value };
        this.swycThirtyMonths.q21ThirtyMonths = { answer: this.swycForm.controls['q21ThirtyMonths'].value };
        this.swycThirtyMonths.q22ThirtyMonths = { answer: this.swycForm.controls['q22ThirtyMonths'].value };
        this.swycThirtyMonths.q23ThirtyMonths = { answer: this.swycForm.controls['q23ThirtyMonths'].value };
        this.swycThirtyMonths.q24ThirtyMonths = { answer: this.swycForm.controls['q24ThirtyMonths'].value };
        this.swycThirtyMonths.q25ThirtyMonths = { answer: this.swycForm.controls['q25ThirtyMonths'].value };
        this.swycThirtyMonths.q26ThirtyMonths = { answer: this.swycForm.controls['q26ThirtyMonths'].value };
        this.swycThirtyMonths.q27ThirtyMonths = { answer: this.swycForm.controls['q27ThirtyMonths'].value };
        this.swycThirtyMonths.q28ThirtyMonths = { answer: this.swycForm.controls['q28ThirtyMonths'].value };
        this.swycThirtyMonths.q29ThirtyMonths = { answer: this.swycForm.controls['q29ThirtyMonths'].value };
        this.swycThirtyMonths.q30ThirtyMonths = { answer: this.swycForm.controls['q30ThirtyMonths'].value };
        this.swycThirtyMonths.q31ThirtyMonths = { answer: this.swycForm.controls['q31ThirtyMonths'].value };
        this.swycThirtyMonths.q32ThirtyMonths = { answer: this.swycForm.controls['q32ThirtyMonths'].value };
        this.swycThirtyMonths.q33ThirtyMonths = { answer: this.swycForm.controls['q33ThirtyMonths'].value };
        this.swycThirtyMonths.q34ThirtyMonths = { answer: this.swycForm.controls['q34ThirtyMonths'].value };
        this.swycThirtyMonths.q35ThirtyMonths = { answer: this.swycForm.controls['q35ThirtyMonths'].value };
        this.swycThirtyMonths.q36ThirtyMonths = { answer: this.swycForm.controls['q36ThirtyMonths'].value };
        this.swycThirtyMonths.q37ThirtyMonths = { answer: this.swycForm.controls['q37ThirtyMonths'].value };
        this.swycThirtyMonths.q38ThirtyMonths = { answer: this.swycForm.controls['q38ThirtyMonths'].value };
        this.swycThirtyMonths.q39ThirtyMonths = { answer: this.swycForm.controls['q39ThirtyMonths'].value };
        this.swycThirtyMonths.q40ThirtyMonths = { answer: this.swycForm.controls['q40ThirtyMonths'].value };
        this.swycThirtyMonths.q41ThirtyMonths = { answer: this.swycForm.controls['q41ThirtyMonths'].value };
        this.swycThirtyMonths.q42ThirtyMonths = { answer: this.swycForm.controls['q42ThirtyMonths'].value };
        this.swycThirtyMonths.q43ThirtyMonths = { answer: this.swycForm.controls['q43ThirtyMonths'].value };
        this.swycThirtyMonths.q44ThirtyMonths = { answer: this.swycForm.controls['q44ThirtyMonths'].value };
        this.swycThirtyMonths.q45ThirtyMonths = { answer: this.swycForm.controls['q45ThirtyMonths'].value };
        this.swycThirtyMonths.q46ThirtyMonths = { answer: this.swycForm.controls['q46ThirtyMonths'].value };
        output = this.swycThirtyMonths;
        break;
      case "36months":
        this.swycThirtySixMonths = Object.assign({}, this.swycThirtySixMonths);
        this.swycThirtySixMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycThirtySixMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycThirtySixMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycThirtySixMonths.ageGroup = this.selectedAgeGroup;
        this.swycThirtySixMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycThirtySixMonths.q1ThirtySixMonths = { answer: this.swycForm.controls['q1ThirtySixMonths'].value };
        this.swycThirtySixMonths.q2ThirtySixMonths = { answer: this.swycForm.controls['q2ThirtySixMonths'].value };
        this.swycThirtySixMonths.q3ThirtySixMonths = { answer: this.swycForm.controls['q3ThirtySixMonths'].value };
        this.swycThirtySixMonths.q4ThirtySixMonths = { answer: this.swycForm.controls['q4ThirtySixMonths'].value };
        this.swycThirtySixMonths.q5ThirtySixMonths = { answer: this.swycForm.controls['q5ThirtySixMonths'].value };
        this.swycThirtySixMonths.q6ThirtySixMonths = { answer: this.swycForm.controls['q6ThirtySixMonths'].value };
        this.swycThirtySixMonths.q7ThirtySixMonths = { answer: this.swycForm.controls['q7ThirtySixMonths'].value };
        this.swycThirtySixMonths.q8ThirtySixMonths = { answer: this.swycForm.controls['q8ThirtySixMonths'].value };
        this.swycThirtySixMonths.q9ThirtySixMonths = { answer: this.swycForm.controls['q9ThirtySixMonths'].value };
        this.swycThirtySixMonths.q10ThirtySixMonths = { answer: this.swycForm.controls['q10ThirtySixMonths'].value };
        this.swycThirtySixMonths.q11ThirtySixMonths = { answer: this.swycForm.controls['q11ThirtySixMonths'].value };
        this.swycThirtySixMonths.q12ThirtySixMonths = { answer: this.swycForm.controls['q12ThirtySixMonths'].value };
        this.swycThirtySixMonths.q13ThirtySixMonths = { answer: this.swycForm.controls['q13ThirtySixMonths'].value };
        this.swycThirtySixMonths.q14ThirtySixMonths = { answer: this.swycForm.controls['q14ThirtySixMonths'].value };
        this.swycThirtySixMonths.q15ThirtySixMonths = { answer: this.swycForm.controls['q15ThirtySixMonths'].value };
        this.swycThirtySixMonths.q16ThirtySixMonths = { answer: this.swycForm.controls['q16ThirtySixMonths'].value };
        this.swycThirtySixMonths.q17ThirtySixMonths = { answer: this.swycForm.controls['q17ThirtySixMonths'].value };
        this.swycThirtySixMonths.q18ThirtySixMonths = { answer: this.swycForm.controls['q18ThirtySixMonths'].value };
        this.swycThirtySixMonths.q19ThirtySixMonths = { answer: this.swycForm.controls['q19ThirtySixMonths'].value };
        this.swycThirtySixMonths.q20ThirtySixMonths = { answer: this.swycForm.controls['q20ThirtySixMonths'].value };
        this.swycThirtySixMonths.q21ThirtySixMonths = { answer: this.swycForm.controls['q21ThirtySixMonths'].value };
        this.swycThirtySixMonths.q22ThirtySixMonths = { answer: this.swycForm.controls['q22ThirtySixMonths'].value };
        this.swycThirtySixMonths.q23ThirtySixMonths = { answer: this.swycForm.controls['q23ThirtySixMonths'].value };
        this.swycThirtySixMonths.q24ThirtySixMonths = { answer: this.swycForm.controls['q24ThirtySixMonths'].value };
        this.swycThirtySixMonths.q25ThirtySixMonths = { answer: this.swycForm.controls['q25ThirtySixMonths'].value };
        this.swycThirtySixMonths.q26ThirtySixMonths = { answer: this.swycForm.controls['q26ThirtySixMonths'].value };
        this.swycThirtySixMonths.q27ThirtySixMonths = { answer: this.swycForm.controls['q27ThirtySixMonths'].value };
        this.swycThirtySixMonths.q28ThirtySixMonths = { answer: this.swycForm.controls['q28ThirtySixMonths'].value };
        this.swycThirtySixMonths.q29ThirtySixMonths = { answer: this.swycForm.controls['q29ThirtySixMonths'].value };
        this.swycThirtySixMonths.q30ThirtySixMonths = { answer: this.swycForm.controls['q30ThirtySixMonths'].value };
        this.swycThirtySixMonths.q31ThirtySixMonths = { answer: this.swycForm.controls['q31ThirtySixMonths'].value };
        this.swycThirtySixMonths.q32ThirtySixMonths = { answer: this.swycForm.controls['q32ThirtySixMonths'].value };
        this.swycThirtySixMonths.q33ThirtySixMonths = { answer: this.swycForm.controls['q33ThirtySixMonths'].value };
        this.swycThirtySixMonths.q34ThirtySixMonths = { answer: this.swycForm.controls['q34ThirtySixMonths'].value };
        this.swycThirtySixMonths.q35ThirtySixMonths = { answer: this.swycForm.controls['q35ThirtySixMonths'].value };
        this.swycThirtySixMonths.q36ThirtySixMonths = { answer: this.swycForm.controls['q36ThirtySixMonths'].value };
        this.swycThirtySixMonths.q37ThirtySixMonths = { answer: this.swycForm.controls['q37ThirtySixMonths'].value };
        this.swycThirtySixMonths.q38ThirtySixMonths = { answer: this.swycForm.controls['q38ThirtySixMonths'].value };
        this.swycThirtySixMonths.q39ThirtySixMonths = { answer: this.swycForm.controls['q39ThirtySixMonths'].value };
        output = this.swycThirtySixMonths;
        break;
      case "48months":
        this.swycFortyEightMonths = Object.assign({}, this.swycFortyEightMonths);
        this.swycFortyEightMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycFortyEightMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycFortyEightMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycFortyEightMonths.ageGroup = this.selectedAgeGroup;
        this.swycFortyEightMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycFortyEightMonths.q1FortyEightMonths = { answer: this.swycForm.controls['q1FortyEightMonths'].value };
        this.swycFortyEightMonths.q2FortyEightMonths = { answer: this.swycForm.controls['q2FortyEightMonths'].value };
        this.swycFortyEightMonths.q3FortyEightMonths = { answer: this.swycForm.controls['q3FortyEightMonths'].value };
        this.swycFortyEightMonths.q4FortyEightMonths = { answer: this.swycForm.controls['q4FortyEightMonths'].value };
        this.swycFortyEightMonths.q5FortyEightMonths = { answer: this.swycForm.controls['q5FortyEightMonths'].value };
        this.swycFortyEightMonths.q6FortyEightMonths = { answer: this.swycForm.controls['q6FortyEightMonths'].value };
        this.swycFortyEightMonths.q7FortyEightMonths = { answer: this.swycForm.controls['q7FortyEightMonths'].value };
        this.swycFortyEightMonths.q8FortyEightMonths = { answer: this.swycForm.controls['q8FortyEightMonths'].value };
        this.swycFortyEightMonths.q9FortyEightMonths = { answer: this.swycForm.controls['q9FortyEightMonths'].value };
        this.swycFortyEightMonths.q10FortyEightMonths = { answer: this.swycForm.controls['q10FortyEightMonths'].value };
        this.swycFortyEightMonths.q11FortyEightMonths = { answer: this.swycForm.controls['q11FortyEightMonths'].value };
        this.swycFortyEightMonths.q12FortyEightMonths = { answer: this.swycForm.controls['q12FortyEightMonths'].value };
        this.swycFortyEightMonths.q13FortyEightMonths = { answer: this.swycForm.controls['q13FortyEightMonths'].value };
        this.swycFortyEightMonths.q14FortyEightMonths = { answer: this.swycForm.controls['q14FortyEightMonths'].value };
        this.swycFortyEightMonths.q15FortyEightMonths = { answer: this.swycForm.controls['q15FortyEightMonths'].value };
        this.swycFortyEightMonths.q16FortyEightMonths = { answer: this.swycForm.controls['q16FortyEightMonths'].value };
        this.swycFortyEightMonths.q17FortyEightMonths = { answer: this.swycForm.controls['q17FortyEightMonths'].value };
        this.swycFortyEightMonths.q18FortyEightMonths = { answer: this.swycForm.controls['q18FortyEightMonths'].value };
        this.swycFortyEightMonths.q19FortyEightMonths = { answer: this.swycForm.controls['q19FortyEightMonths'].value };
        this.swycFortyEightMonths.q20FortyEightMonths = { answer: this.swycForm.controls['q20FortyEightMonths'].value };
        this.swycFortyEightMonths.q21FortyEightMonths = { answer: this.swycForm.controls['q21FortyEightMonths'].value };
        this.swycFortyEightMonths.q22FortyEightMonths = { answer: this.swycForm.controls['q22FortyEightMonths'].value };
        this.swycFortyEightMonths.q23FortyEightMonths = { answer: this.swycForm.controls['q23FortyEightMonths'].value };
        this.swycFortyEightMonths.q24FortyEightMonths = { answer: this.swycForm.controls['q24FortyEightMonths'].value };
        this.swycFortyEightMonths.q25FortyEightMonths = { answer: this.swycForm.controls['q25FortyEightMonths'].value };
        this.swycFortyEightMonths.q26FortyEightMonths = { answer: this.swycForm.controls['q26FortyEightMonths'].value };
        this.swycFortyEightMonths.q27FortyEightMonths = { answer: this.swycForm.controls['q27FortyEightMonths'].value };
        this.swycFortyEightMonths.q28FortyEightMonths = { answer: this.swycForm.controls['q28FortyEightMonths'].value };
        this.swycFortyEightMonths.q29FortyEightMonths = { answer: this.swycForm.controls['q29FortyEightMonths'].value };
        this.swycFortyEightMonths.q30FortyEightMonths = { answer: this.swycForm.controls['q30FortyEightMonths'].value };
        this.swycFortyEightMonths.q31FortyEightMonths = { answer: this.swycForm.controls['q31FortyEightMonths'].value };
        this.swycFortyEightMonths.q32FortyEightMonths = { answer: this.swycForm.controls['q32FortyEightMonths'].value };
        this.swycFortyEightMonths.q33FortyEightMonths = { answer: this.swycForm.controls['q33FortyEightMonths'].value };
        this.swycFortyEightMonths.q34FortyEightMonths = { answer: this.swycForm.controls['q34FortyEightMonths'].value };
        this.swycFortyEightMonths.q35FortyEightMonths = { answer: this.swycForm.controls['q35FortyEightMonths'].value };
        this.swycFortyEightMonths.q36FortyEightMonths = { answer: this.swycForm.controls['q36FortyEightMonths'].value };
        this.swycFortyEightMonths.q37FortyEightMonths = { answer: this.swycForm.controls['q37FortyEightMonths'].value };
        this.swycFortyEightMonths.q38FortyEightMonths = { answer: this.swycForm.controls['q38FortyEightMonths'].value };
        this.swycFortyEightMonths.q39FortyEightMonths = { answer: this.swycForm.controls['q39FortyEightMonths'].value };
        output = this.swycFortyEightMonths;
        break;
      case "60months":
        this.swycSixtyMonths = Object.assign({}, this.swycSixtyMonths);
        this.swycSixtyMonths.patientId =
          this.swycForm.controls['patientId'].value;
        this.swycSixtyMonths.sessionId =
          this.swycForm.controls['sessionId'].value;
        this.swycSixtyMonths.assessmentRequestId =
          this.swycForm.controls['assessmentRequestId'].value;

        this.swycSixtyMonths.ageGroup = this.selectedAgeGroup;
        this.swycSixtyMonths.childDob = formatDate(new Date(this.swycForm.controls['childDob'].value));
        this.swycSixtyMonths.q1SixtyMonths = { answer: this.swycForm.controls['q1SixtyMonths'].value };
        this.swycSixtyMonths.q2SixtyMonths = { answer: this.swycForm.controls['q2SixtyMonths'].value };
        this.swycSixtyMonths.q3SixtyMonths = { answer: this.swycForm.controls['q3SixtyMonths'].value };
        this.swycSixtyMonths.q4SixtyMonths = { answer: this.swycForm.controls['q4SixtyMonths'].value };
        this.swycSixtyMonths.q5SixtyMonths = { answer: this.swycForm.controls['q5SixtyMonths'].value };
        this.swycSixtyMonths.q6SixtyMonths = { answer: this.swycForm.controls['q6SixtyMonths'].value };
        this.swycSixtyMonths.q7SixtyMonths = { answer: this.swycForm.controls['q7SixtyMonths'].value };
        this.swycSixtyMonths.q8SixtyMonths = { answer: this.swycForm.controls['q8SixtyMonths'].value };
        this.swycSixtyMonths.q9SixtyMonths = { answer: this.swycForm.controls['q9SixtyMonths'].value };
        this.swycSixtyMonths.q10SixtyMonths = { answer: this.swycForm.controls['q10SixtyMonths'].value };
        this.swycSixtyMonths.q11SixtyMonths = { answer: this.swycForm.controls['q11SixtyMonths'].value };
        this.swycSixtyMonths.q12SixtyMonths = { answer: this.swycForm.controls['q12SixtyMonths'].value };
        this.swycSixtyMonths.q13SixtyMonths = { answer: this.swycForm.controls['q13SixtyMonths'].value };
        this.swycSixtyMonths.q14SixtyMonths = { answer: this.swycForm.controls['q14SixtyMonths'].value };
        this.swycSixtyMonths.q15SixtyMonths = { answer: this.swycForm.controls['q15SixtyMonths'].value };
        this.swycSixtyMonths.q16SixtyMonths = { answer: this.swycForm.controls['q16SixtyMonths'].value };
        this.swycSixtyMonths.q17SixtyMonths = { answer: this.swycForm.controls['q17SixtyMonths'].value };
        this.swycSixtyMonths.q18SixtyMonths = { answer: this.swycForm.controls['q18SixtyMonths'].value };
        this.swycSixtyMonths.q19SixtyMonths = { answer: this.swycForm.controls['q19SixtyMonths'].value };
        this.swycSixtyMonths.q20SixtyMonths = { answer: this.swycForm.controls['q20SixtyMonths'].value };
        this.swycSixtyMonths.q21SixtyMonths = { answer: this.swycForm.controls['q21SixtyMonths'].value };
        this.swycSixtyMonths.q22SixtyMonths = { answer: this.swycForm.controls['q22SixtyMonths'].value };
        this.swycSixtyMonths.q23SixtyMonths = { answer: this.swycForm.controls['q23SixtyMonths'].value };
        this.swycSixtyMonths.q24SixtyMonths = { answer: this.swycForm.controls['q24SixtyMonths'].value };
        this.swycSixtyMonths.q25SixtyMonths = { answer: this.swycForm.controls['q25SixtyMonths'].value };
        this.swycSixtyMonths.q26SixtyMonths = { answer: this.swycForm.controls['q26SixtyMonths'].value };
        this.swycSixtyMonths.q27SixtyMonths = { answer: this.swycForm.controls['q27SixtyMonths'].value };
        this.swycSixtyMonths.q28SixtyMonths = { answer: this.swycForm.controls['q28SixtyMonths'].value };
        this.swycSixtyMonths.q29SixtyMonths = { answer: this.swycForm.controls['q29SixtyMonths'].value };
        this.swycSixtyMonths.q30SixtyMonths = { answer: this.swycForm.controls['q30SixtyMonths'].value };
        this.swycSixtyMonths.q31SixtyMonths = { answer: this.swycForm.controls['q31SixtyMonths'].value };
        this.swycSixtyMonths.q32SixtyMonths = { answer: this.swycForm.controls['q32SixtyMonths'].value };
        this.swycSixtyMonths.q33SixtyMonths = { answer: this.swycForm.controls['q33SixtyMonths'].value };
        this.swycSixtyMonths.q34SixtyMonths = { answer: this.swycForm.controls['q34SixtyMonths'].value };
        this.swycSixtyMonths.q35SixtyMonths = { answer: this.swycForm.controls['q35SixtyMonths'].value };
        this.swycSixtyMonths.q36SixtyMonths = { answer: this.swycForm.controls['q36SixtyMonths'].value };
        this.swycSixtyMonths.q37SixtyMonths = { answer: this.swycForm.controls['q37SixtyMonths'].value };
        this.swycSixtyMonths.q38SixtyMonths = { answer: this.swycForm.controls['q38SixtyMonths'].value };
        this.swycSixtyMonths.q39SixtyMonths = { answer: this.swycForm.controls['q39SixtyMonths'].value };
        output = this.swycSixtyMonths;
        break;
    }
    return output;
  }
}
