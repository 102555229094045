<div class="dialogTitle" style="padding-left: 10px; padding-right: 10px">
  <p matDialogTitle>Care Document</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="questionAnswers">
  <form [formGroup]="answerForm">
    <mat-card
      appearance="raised"
      class="pad20 mat-elevation-z8"
      *ngFor="let questionAnswer of questionAnswers"
    >
      <label class="col-md-12 asterix-after">{{ questionAnswer.title }}</label>
      <div class="col-md-12">
        <mat-tab-group>
          <mat-tab
            [label]="answer.value"
            *ngFor="let answer of questionAnswer.answers"
          >
            <div class="col-md-12">
              <div class="row" *ngIf="hasAssessmentGuidanceEditAccess">
                <div class="col-md-12">
                  <button
                    mat-raised-button
                    class="btn btn-success pull-right"
                    (click)="addGuidanceRecord(answer.id, '')"
                  >
                    Add Record
                  </button>
                </div>
              </div>
              <mat-divider></mat-divider>
              <ng-container [formArrayName]="answer.id">
                <ng-container
                  *ngFor="
                    let guidanceForm of guidanceRecords(answer.id).controls;
                    let index = index
                  "
                >
                  <div class="row" [formGroupName]="index">
                    <div
                      [class]="
                        hasAssessmentGuidanceEditAccess
                          ? 'col-md-11'
                          : 'col-md-12'
                      "
                    >
                      <mat-form-field appearance="outline">
                        <textarea
                          matInput
                          placeholder="Write a Solution"
                          formControlName="guidance"
                          name="guidance"
                          (change)="onSubmit()"
                        ></textarea>
                      </mat-form-field>
                    </div>
                    <div
                      class="col-md-1"
                      *ngIf="hasAssessmentGuidanceEditAccess"
                    >
                      <button
                        mat-raised-button
                        (click)="removeGuidanceRecord(answer.id, index)"
                        class="btn btn-just-icon btn-danger btn-fab btn-round pull-right"
                      >
                        <i class="material-icons text_align-center">delete</i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card>

    <div
      class="row"
      style="justify-content: flex-end; padding-right: 20px; margin: 20px"
      *ngIf="hasAssessmentGuidanceEditAccess"
    >
      <button
        mat-raised-button
        color="warn"
        (click)="onSubmit()"
        [disabled]="processing"
      >
        Save
      </button>
    </div>
  </form>
</div>
