<div
  class="main-content"
  style="background-color: #eee"
  *ngIf="!isLoadingResults"
>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>
  <div class="container-fluid" *ngIf="!isLoadingResults">
    <br />
    <div class="row">
      <div class="col-md-4">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Close</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  height="100%"
                  [data]="closeChartData"
                  [options]="closeChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="closeChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-4">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Depend</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="dependChartData"
                  [options]="dependChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="detailChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-4">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Anxiety</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="anxietyChartData"
                  [options]="anxietyChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="anxietyChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br />
    <div class="row">
      <ng-container *ngFor="let scoreDetail of scoreDetails">
        <div class="col-md-4" style="margin-bottom: 20px">
          <mat-card>
            <mat-card-header>
              <h4 class="header text-center">{{ scoreDetail.title }}</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="scoreDetail"
                    [options]="assessmentDetailChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="detailChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
            <small
              ><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small
            ><br />
            <small
              ><b>Category:</b> <i>{{ scoreDetail.categoryName }}</i></small
            >
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>
