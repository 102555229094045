<div class="row tabPanelWrapper mat-elevation-z8">
  <div class="col">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        (click)="activeTab = 'Text Shortcuts'"
        [active]="activeTab === 'Text Shortcuts'"
      >
        Text Shortcuts
      </a>

      <a
        mat-tab-link
        (click)="activeTab = 'Templates'"
        [active]="activeTab == 'Templates'"
      >
        Templates
      </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
      <div class="row" style="position: relative">
        <!-- Close Tab -->
        <div class="tabPanelCloseAction">
          <button
            mat-mini-fab
            color="primary"
            type="button"
            (click)="closePanel()"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <!--  Tab Header -->
        <div class="col-md-12" style="margin-top: 20px">
          <label class="labelPointer">
            Selected Section -
            <span class="labelBold">{{ selectedSectionName }}</span>
          </label>
        </div>

        <!--  Tab Contents For Shortcuts-->
        <div
          class="col-md-12"
          *ngIf="activeTab === 'Text Shortcuts'"
          style="padding-bottom: 30px"
        >
          <!-- Loader If it is still loading the list -->
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="isLoadingShortCutsTemplates"
          ></mat-progress-bar>
          <!-- Search Bar -->
          <div
            class="col-md-12"
            style="
              display: flex;
              align-items: center;
              padding: 5px;
              border-radius: 10px;
              border: 1px solid rgb(0, 0, 0, 0.2);
              background-color: #ffffff;
              margin-top: 15px;
            "
          >
            <span
              class="material-icons"
              style="text-align: left; font-size: 20px; opacity: 0.5"
            >
              search
            </span>
            <input
              type="search"
              placeholder="Shortcut Description ..."
              [(ngModel)]="searchShortcuts"
              (ngModelChange)="searchChanged()"
              style="
                padding-left: 15px;
                width: 100%;
                background: transparent;
                border: 0;
              "
            />
          </div>

          <!-- Mat Tab Group -->
          <mat-tab-group dynamicHeight style="margin-top: 5px">
            <!-- My Favourites List -->
            <mat-tab *ngIf="!searchShortcuts">
              <ng-template mat-tab-label>
                <mat-icon>favorite</mat-icon>
                &nbsp;My favorites
              </ng-template>

              <!-- Show the shorcuts -->
              <mat-card
                appearance="raised"
                class="shortCutCard mat-elevation-z8"
                *ngFor="let shortcut of favList"
              >
                <!-- Header -->
                <div class="shortCutCardHeader">
                  <button
                    mat-icon-button
                    type="button"
                    color="primary"
                    (click)="addTextShortcutToEditor(shortcut)"
                  >
                    <mat-icon>add_circle</mat-icon>
                  </button>
                  <label class="labelBold">{{ shortcut.shortDesc }}</label>

                  <mat-icon
                    [ngClass]="
                      shortcut.isFavorite ? 'favorite' : 'not-favorite'
                    "
                    (click)="toggleFavorite('TEXT_SHORTCUTS', shortcut)"
                    >star</mat-icon
                  >

                  <!-- expand -->
                  <button
                    mat-icon-button
                    [matTooltip]="expandShortCuts ? 'Collapse' : 'Expand'"
                    style="margin-left: auto"
                    type="button"
                    (click)="expandCollapse(shortcut)"
                  >
                    <mat-icon>
                      {{
                        expandShortCuts && expandedId === shortcut.id
                          ? 'expand_less'
                          : 'expand_more'
                      }}
                    </mat-icon>
                  </button>
                </div>
                <!-- Minimal Content -->
                <div
                  [class]="
                    expandShortCuts && expandedId == shortcut.id
                      ? 'expandedShortCutContent'
                      : 'shortCutCardContent'
                  "
                >
                  <label [innerHTML]="getContent(shortcut)"> </label>
                </div>
              </mat-card>
            </mat-tab>

            <!-- Categories List -->
            <mat-tab *ngIf="!searchShortcuts">
              <ng-template mat-tab-label>
                <mat-icon>list</mat-icon>
                &nbsp;Categories
              </ng-template>
              <!-- First Displaying all the categories -->
              <div
                class="col-md-12"
                style="margin-top: 20px"
                *ngIf="!selectedShortCutCategory"
              >
                <label>Please select a category</label>
              </div>
              <!-- Bread Crumbs Concept -->
              <div
                class="col-md-12"
                style="margin-top: 20px"
                *ngIf="selectedShortCutCategory"
              >
                <label class="labelBold">
                  <button
                    mat-button
                    color="primary"
                    button="primary"
                    (click)="deSelectShortCutCategory()"
                  >
                    Categories
                  </button>
                  / &nbsp;
                  {{ selectedShortCutCategory }}
                </label>
              </div>
              <!-- Display Category Selection when a category is not selected -->
              <div *ngIf="!selectedShortCutCategory">
                <div
                  class="col-md-12"
                  *ngFor="let category of shortCutsCategoriesList"
                >
                  <hr />
                  <button
                    color="primary"
                    mat-button
                    type="button"
                    (click)="selectShortcutCategory(category.name, category.id)"
                  >
                    {{ category.name }} ({{ category.shortCode }})
                  </button>
                </div>
              </div>
              <!-- Display Category Shortcuts when a category is  selected -->
              <div *ngIf="selectedShortCutCategory">
                <mat-card
                  appearance="raised"
                  class="shortCutCard mat-elevation-z8"
                  *ngFor="let shortcut of categoryShortCuts"
                >
                  <!-- Header -->
                  <div class="shortCutCardHeader">
                    <button
                      mat-icon-button
                      type="button"
                      color="primary"
                      (click)="addTextShortcutToEditor(shortcut)"
                    >
                      <mat-icon>add_circle</mat-icon>
                    </button>
                    <label class="labelBold">{{ shortcut.shortDesc }}</label>

                    <mat-icon
                      [class]="
                        shortcut.isFavorite ? 'favorite' : 'not-favorite'
                      "
                      (click)="toggleFavorite('TEXT_SHORTCUTS', shortcut)"
                      >star</mat-icon
                    >

                    <!-- expand -->
                    <button
                      mat-icon-button
                      [matTooltip]="expandShortCuts ? 'Collapse' : 'Expand'"
                      style="margin-left: auto"
                      type="button"
                      (click)="expandCollapse(shortcut)"
                    >
                      <mat-icon>
                        {{
                          expandShortCuts && expandedId === shortcut.id
                            ? 'expand_less'
                            : 'expand_more'
                        }}
                      </mat-icon>
                    </button>
                  </div>
                  <!-- Minimal Content -->
                  <div
                    [class]="
                      expandShortCuts && expandedId == shortcut.id
                        ? 'expandedShortCutContent'
                        : 'shortCutCardContent'
                    "
                  >
                    <label [innerHTML]="getContent(shortcut)"> </label>
                  </div>
                </mat-card>
              </div>
            </mat-tab>

            <!-- Search List -->
            <mat-tab *ngIf="searchShortcuts">
              <ng-template mat-tab-label>
                <mat-icon>search</mat-icon>
                &nbsp;Search Results
              </ng-template>

              <!-- Show the shorcuts -->
              <mat-card
                appearance="raised"
                class="shortCutCard mat-elevation-z8"
                *ngFor="let shortcut of searchList"
              >
                <!-- Header -->
                <div class="shortCutCardHeader">
                  <button
                    mat-icon-button
                    type="button"
                    color="primary"
                    (click)="addTextShortcutToEditor(shortcut)"
                  >
                    <mat-icon>add_circle</mat-icon>
                  </button>
                  <label class="labelBold">{{ shortcut.shortDesc }}</label>

                  <mat-icon
                    [ngClass]="
                      shortcut.isFavorite ? 'favorite' : 'not-favorite'
                    "
                    (click)="toggleFavorite('TEXT_SHORTCUTS', shortcut)"
                  >
                    star
                  </mat-icon>

                  <!-- expand -->
                  <button
                    mat-icon-button
                    [matTooltip]="expandShortCuts ? 'Collapse' : 'Expand'"
                    style="margin-left: auto"
                    type="button"
                    (click)="expandCollapse(shortcut)"
                  >
                    <mat-icon>
                      {{
                        expandShortCuts && expandedId === shortcut.id
                          ? 'expand_less'
                          : 'expand_more'
                      }}
                    </mat-icon>
                  </button>
                </div>
                <!-- Minimal Content -->
                <div
                  [class]="
                    expandShortCuts && expandedId == shortcut.id
                      ? 'expandedShortCutContent'
                      : 'shortCutCardContent'
                  "
                >
                  <label [innerHTML]="getContent(shortcut)"> </label>
                </div>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!--  For Templates -->
        <div
          class="col-md-12"
          *ngIf="activeTab === 'Templates'"
          style="padding-bottom: 30px; padding-top: 15px"
        >
          <!-- Loader If it is still loading the list -->
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="isLoadingShortCutsTemplates"
          ></mat-progress-bar>
          <!-- Display Template Contents -->
          <mat-card
            appearance="raised"
            class="shortCutCard mat-elevation-z8"
            *ngFor="let template of templates"
          >
            <!-- Header -->
            <div class="shortCutCardHeader">
              <button
                mat-icon-button
                type="button"
                color="primary"
                (click)="addTemplateToEditor(template)"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
              <label class="labelBold">{{ template.templateName }}</label>

              <!-- expand -->
              <button
                mat-icon-button
                [matTooltip]="expandShortCuts ? 'Collapse' : 'Expand'"
                style="margin-left: auto"
                type="button"
                (click)="expandCollapse(template)"
              >
                <mat-icon>
                  {{
                    expandShortCuts && expandedId === template.id
                      ? 'expand_less'
                      : 'expand_more'
                  }}
                </mat-icon>
              </button>
            </div>
            <!-- Minimal Content -->
            <div
              [class]="
                expandShortCuts && expandedId == template.id
                  ? 'expandedShortCutContent'
                  : 'shortCutCardContent'
              "
            >
              <label> {{ template.text }}</label>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>
