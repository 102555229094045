<div style="margin-top: 15px; padding: 10px">
  <form
    [formGroup]="casetreatmentadmissiondiagnosisForm"
    novalidate
    (ngSubmit)="onCaseTreatmentAdmissionDiagnosisFormSubmit()"
  >
    <div class="row">
      <div class="col-md-6">
        <mat-card
          class="mat-elevation-z8"
          style="position: relative; border-radius: 10px"
        >
          <label class="labelBold">
            Primary Diagnosis<span style="color: red">*</span>
            <span
              class="material-icons"
              style="
                padding-left: 10px;
                color: gray;
                font-size: 17px;
                cursor: pointer;
                z-index: 2;
              "
              *ngIf="
                casetreatmentadmissiondiagnosisForm.controls['primaryDiagnosis']
                  .value !== ''
              "
              (click)="selectDiagnosis('primaryDiagnosis')"
              type="button"
            >
              edit
            </span>
          </label>
          <span
            style="text-align: right"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['primaryDiagnosis']
                .value === ''
            "
          >
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="selectDiagnosis('primaryDiagnosis')"
            >
              Select Primary Diagnosis
            </button>
          </span>
          <div
            class="twoGridContainer pad20"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['primaryDiagnosis']
                .value !== ''
            "
          >
            <label class="labelBold">Code</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls['primaryDiagnosis']
                  .value
              }}
            </label>

            <label class="labelBold">Description</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls[
                  'primaryDiagnosisDescription'
                ].value
              }}
            </label>
          </div>
        </mat-card>

        <mat-card
          class="mat-elevation-z8 pad20"
          style="position: relative; border-radius: 10px"
        >
          <label class="labelBold">
            Secondary Diagnosis
            <span
              class="material-icons"
              style="
                padding-left: 10px;
                color: gray;
                font-size: 17px;
                cursor: pointer;
                z-index: 2;
              "
              *ngIf="
                casetreatmentadmissiondiagnosisForm.controls[
                  'secondaryDiagnosis'
                ].value !== ''
              "
              (click)="selectDiagnosis('secondaryDiagnosis')"
              type="button"
            >
              edit
            </span>
            <button
              mat-icon-button
              type="button"
              *ngIf="
                casetreatmentadmissiondiagnosisForm.controls[
                  'secondaryDiagnosis'
                ].value !== ''
              "
              (click)="removeDiagnosis('secondary')"
            >
              <mat-icon style="font-size: 22px; padding-top: 5px" color="warn">
                delete_forever
              </mat-icon>
            </button>
          </label>

          <span
            style="text-align: right"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['secondaryDiagnosis']
                .value === ''
            "
          >
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="selectDiagnosis('secondaryDiagnosis')"
            >
              Select Secondary Diagnosis
            </button>
          </span>
          <div
            class="twoGridContainer pad20"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['secondaryDiagnosis']
                .value !== ''
            "
          >
            <label class="labelBold">Code</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls[
                  'secondaryDiagnosis'
                ].value
              }}
            </label>

            <label class="labelBold">Description</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls[
                  'secondaryDiagnosisDescription'
                ].value
              }}
            </label>
          </div>
        </mat-card>
        <mat-card
          class="mat-elevation-z8 pad20"
          style="position: relative; border-radius: 10px"
        >
          <label class="labelBold">
            Tertiary Diagnosis
            <span
              class="material-icons"
              style="
                padding-left: 10px;
                color: gray;
                font-size: 17px;
                cursor: pointer;
                z-index: 2;
              "
              *ngIf="
                casetreatmentadmissiondiagnosisForm.controls[
                  'tertiaryDiagnosis'
                ].value !== ''
              "
              (click)="selectDiagnosis('tertiaryDiagnosis')"
              type="button"
            >
              edit
            </span>
            <button
              mat-icon-button
              type="button"
              *ngIf="
                casetreatmentadmissiondiagnosisForm.controls[
                  'tertiaryDiagnosis'
                ].value !== ''
              "
              (click)="removeDiagnosis('tertiary')"
            >
              <mat-icon style="font-size: 22px; padding-top: 5px" color="warn">
                delete_forever
              </mat-icon>
            </button>
          </label>

          <span
            style="text-align: right"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['tertiaryDiagnosis']
                .value === ''
            "
          >
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="selectDiagnosis('tertiaryDiagnosis')"
            >
              Select Tertiary Diagnosis
            </button>
          </span>
          <div
            class="twoGridContainer pad20"
            *ngIf="
              casetreatmentadmissiondiagnosisForm.controls['tertiaryDiagnosis']
                .value !== ''
            "
          >
            <label class="labelBold">Code</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls[
                  'tertiaryDiagnosis'
                ].value
              }}
            </label>

            <label class="labelBold">Description</label>
            <label class="labelText">
              {{
                casetreatmentadmissiondiagnosisForm.controls[
                  'tertiaryDiagnosisDescription'
                ].value
              }}
            </label>
          </div>
        </mat-card>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <span class="labelText">
              Effective Date<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="effectiveDate"
                [matDatepicker]="effectiveDatePicker"
                placeholder="Effective Date"
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="effectiveDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #effectiveDatePicker></mat-datepicker>
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls['effectiveDate']
                    .invalid
                "
              >
                Please select a valid date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <span class="labelText"
              >Effective Time<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Effective Time"
                [ngxTimepicker]="effectiveTimePicker"
                formControlName="effectiveTime"
              />
              <ngx-material-timepicker
                #effectiveTimePicker
              ></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls['effectiveTime']
                    .invalid
                "
              >
                Please select a valid time
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <span class="labelText">
              Expiration Date<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="expirationDate"
                [matDatepicker]="expirationDatePicker"
                placeholder="Expiration Date"
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="expirationDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #expirationDatePicker></mat-datepicker>
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls['expirationDate']
                    .invalid
                "
              >
                Please select a valid date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <span class="labelText"
              >Expiration Time<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Expiration Time"
                [ngxTimepicker]="expirationTimePicker"
                formControlName="expirationTime"
              />
              <ngx-material-timepicker
                #expirationTimePicker
              ></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls['expirationTime']
                    .invalid
                "
              >
                Please select a valid time
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <span class="labelText"
              >Diagnosing Clinician<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="diagnosingClinicianId"
                placeholder="Diagnosing Clinician"
                (selectionChange)="selectedClinician($event)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Clinician Name"
                    noEntriesFoundLabel="No matching clinician found"
                    [(ngModel)]="providerName"
                    (ngModelChange)="filterProvider()"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let provider of filteredProvidersList"
                  [value]="provider.id"
                >
                  {{ provider.lastName }}, {{ provider.firstName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls[
                    'diagnosingClinicianId'
                  ].invalid
                "
              >
                Please select the diagnosing clinician
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <span class="labelText"
              >Gaf Score<span style="color: red">*</span></span
            >
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Gaf Score"
                formControlName="gafScore"
                name="gafScore"
                required
              />
              <mat-error
                *ngIf="
                  casetreatmentadmissiondiagnosisForm.controls['gafScore']
                    .invalid
                "
              >
                Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-md-12 pad20">
        <span class="labelText">Diagnosis Comments</span>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="diagnosisComments"
            matInput
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!casetreatmentadmissiondiagnosisForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
