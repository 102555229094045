<div class="col-md-12">
  <form
    [formGroup]="casetreatmentadmissionfinancialForm"
    novalidate
    (ngSubmit)="onCaseTreatmentAdmissionFinancialFormSubmit()"
  >
    <div class="row">
      <!-- Employment Status -->
      <div class="col-md-6">
        <label class="labelText">
          Employment Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="employmentStatus"
            placeholder="Employment Status"
          >
            <mat-option value="Full time"> Full time </mat-option>
            <mat-option value="Armed Forces"> Armed Forces </mat-option>
            <mat-option value="Part-time"> Part-time </mat-option>
            <mat-option value="Seeking employment"
              >Seeking employment</mat-option
            >
            <mat-option value="On layoff"> On layoff </mat-option>
            <mat-option value="Homemaker"> Homemaker </mat-option>
            <mat-option value="Student">Student</mat-option>
            <mat-option value="Retired">Retired</mat-option>
            <mat-option value="Inmate of Institution">
              Inmate of Institution
            </mat-option>
            <mat-option value="Unable to work due to Drv, HM or A&D disorder"
              >Unable to work due to Drv, HM or A&D disorder</mat-option
            >
            <mat-option value="Unable to work due to other disorder"
              >Unable to work due to other disorder</mat-option
            >
            <mat-option value="Other">Other</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls['employmentStatus']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Months Employed In Last 6 Months -->
      <div class="col-md-6">
        <label class="labelText">Months Employed In Last 6 Months</label>
        <mat-form-field appearance="outline">
          <input
            type="number"
            matInput
            formControlName="monthsEmployedInLast6Months"
          />
        </mat-form-field>
      </div>

      <!--   Primary Income Source -->
      <div class="col-md-6">
        <label class="labelText">
          Primary Income Source <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="primaryIncomeSource"
            placeholder="Primary Source of Income"
          >
            <mat-option value="Salaries and Wages">
              Salaries and Wages
            </mat-option>
            <mat-option value="Self Employment Earnings">
              Self Employment Earnings
            </mat-option>
            <mat-option value="Public Assist (incl. AFDC)">
              Public Assist (incl. AFDC)
            </mat-option>
            <mat-option value="Social Security">Social Security</mat-option>
            <mat-option value="Veteran's Payments">
              Veteran's Payments
            </mat-option>
            <mat-option value="Supplemental Security Income (SSI)">
              Supplemental Security Income (SSI)
            </mat-option>
            <mat-option value="Alimony">Alimony</mat-option>
            <mat-option value="Child Support">Child Support</mat-option>
            <mat-option value="No Source of Income">
              No Source of Income
            </mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'primaryIncomeSource'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Annual Household Income -->
      <div class="col-md-6">
        <label class="labelText">
          Annual Household Income <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="annualHouseholdIncome"
            placeholder="Annual Household income"
          >
            <mat-option value="$0 - $999">$0 - $999</mat-option>
            <mat-option value="$1,000 - $4,999">$1,000 - $4,999</mat-option>
            <mat-option value="$5,000 - $9,999">$5,000 - $9,999</mat-option>
            <mat-option value="$10,000 - $19,999">$10,000 - $19,999</mat-option>
            <mat-option value="$20,000 - $29,999">$20,000 - $29,999</mat-option>
            <mat-option value="$30,000 - $39,000">$30,000 - $39,000</mat-option>
            <mat-option value="$40,000 - $49,000">$40,000 - $49,000</mat-option>
            <mat-option value="$50,000 and over">$50,000 and over</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'annualHouseholdIncome'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--   Insurance Type -->
      <div class="col-md-6">
        <label class="labelText">
          Insurance Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="insuranceType"
            placeholder="Insurance Type"
          >
            <mat-option value="Blue Cross Blue Shield">
              Blue Cross Blue Shield
            </mat-option>
            <mat-option value="Health Maintenance Organization">
              Health Maintenance Organization
            </mat-option>
            <mat-option value="Medicaid">Medicaid</mat-option>
            <mat-option value="Medicaid/Medicare">Medicaid/Medicare</mat-option>
            <mat-option value="Medicare">Medicare</mat-option>
            <mat-option value="Medicare/SaftetyNet">
              Medicare/SaftetyNet
            </mat-option>
            <mat-option value="Private Insurance">Private Insurance</mat-option>
            <mat-option value="No Insurance">No Insurance</mat-option>
            <mat-option value="Other">Other</mat-option>

            <mat-option value="Unknown">Unknown</mat-option>
            <mat-option value="VA Insurance">VA Insurance</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls['insuranceType']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6"></div>

      <!-- Household Composition -->
      <div class="col-md-6">
        <label class="labelText">Household Composition</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="householdComposition">
            <mat-option value="Client Lives Alone">
              Client Lives Alone
            </mat-option>
            <mat-option value="Client Lives With Children">
              Client Lives With Children
            </mat-option>
            <mat-option value="Client Lives With Non-relatives">
              Client Lives With Non-relatives
            </mat-option>
            <mat-option value="Client Lives With Adolescents">
              Client Lives With Adolescents
            </mat-option>
            <mat-option value="Client Lives With Relatives">
              Client Lives With Relatives
            </mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Marital Status -->
      <div class="col-md-6">
        <label class="labelText">
          Marital Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="maritalStatus"
            placeholder="Marital Status"
          >
            <mat-option value="Never Married">Never Married</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Remarried">Remarried</mat-option>
            <mat-option value="Separated">Separated</mat-option>
            <mat-option value="Divorced/Annulled">Divorced/Annulled</mat-option>
            <mat-option value="Widowed">Widowed</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls['maritalStatus']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Living Arrangement -->
      <div class="col-md-6">
        <label class="labelText">
          Living Arrangement <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="livingArrangement"
            placeholder="Living Arrangement"
          >
            <mat-option value="Street, shelter, no permanent residence">
              Street, shelter, no permanent residence
            </mat-option>
            <mat-option value="Supervised Housing - RTF (residential Tx)">
              Supervised Housing - RTF (residential Tx)
            </mat-option>
            <mat-option
              value="Supervised Housing - SLF (supported living, mental health org)"
            >
              Supervised Housing - SLF (supported living, mental health org)
            </mat-option>
            <mat-option value="Other supervised housing">
              Other supervised housing
            </mat-option>
            <mat-option value="Jail, correction facility">
              Jail, correction facility
            </mat-option>
            <mat-option value="Mental Health Institution">
              Mental Health Institution
            </mat-option>
            <mat-option value="Nursing Home">Nursing Home</mat-option>
            <mat-option value="Other Institution">Other Institution</mat-option>
            <mat-option value="Private residence">Private residence</mat-option>
            <mat-option
              value="Supported housing (private residence with staff)"
            >
              Supported housing (private residence with staff)
            </mat-option>
            <mat-option value="Other">Other</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls['livingArrangement']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- # of Dependents -->
      <div class="col-md-6">
        <label class="labelText">
          # of Dependents <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            placeholder="Number of dependents"
            type="number"
            matInput
            formControlName="numberOfDependentsSupported"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'numberOfDependentsSupported'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- # of People Living with Client -->
      <div class="col-md-6">
        <label class="labelText">
          # of People Living With Client <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            placeholder="Number of people living with client"
            type="number"
            matInput
            formControlName="numberOfPeopleLivingWithClient"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'numberOfPeopleLivingWithClient'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- # of nOfChildrenLivingWithClient -->
      <div class="col-md-6">
        <label class="labelText"> # of Children Living With Client </label>
        <mat-form-field appearance="outline">
          <input
            type="number"
            matInput
            formControlName="numberOfChildrenLivingWithClient"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'numberOfChildrenLivingWithClient'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- # nOfChildrenAbove18 -->
      <div class="col-md-6">
        <label class="labelText">
          # Children Above 18 <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            placeholder="Number of children above 18"
            type="number"
            matInput
            formControlName="numberOfChildrenEighteenOrAbove"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'numberOfChildrenEighteenOrAbove'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- # nOfChildrenUnder18 -->
      <div class="col-md-6">
        <label class="labelText">
          # Children Under 18 <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            placeholder="Number of children under 18"
            type="number"
            matInput
            formControlName="numberOfChildrenBelowEighteen"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionfinancialForm.controls[
                'numberOfChildrenBelowEighteen'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!casetreatmentadmissionfinancialForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
