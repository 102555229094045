<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Treatment Review</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Treatment Review</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="treatmentreviewForm"
    (ngSubmit)="onTreatmentReviewsFormSubmit()"
  >
    <div class="row">
      <!-- Review Date -->
      <div class="col-md-6">
        <label class="labelText">
          Review Date
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="reviewDate"
            matInput
            [matDatepicker]="reviewDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="reviewDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #reviewDatePicker></mat-datepicker>
          <mat-error *ngIf="treatmentreviewForm.controls['reviewDate'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6"></div>

      <!-- Review Start -->
      <div class="col-md-6">
        <label class="labelText">
          Review Period Start <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="reviewPeriodStart"
            matInput
            [matDatepicker]="reviewPeriodStartPicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="reviewPeriodStartPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #reviewPeriodStartPicker></mat-datepicker>
          <mat-error
            *ngIf="treatmentreviewForm.controls['reviewPeriodStart'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Review End -->
      <div class="col-md-6">
        <label class="labelText">
          Review Period End <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="reviewPeriodEnd"
            matInput
            [matDatepicker]="reviewPeriodEndPicker"
            autoSlashDate
            [min]="treatmentreviewForm.controls['reviewPeriodStart'].value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="reviewPeriodEndPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #reviewPeriodEndPicker></mat-datepicker>
          <mat-error
            *ngIf="treatmentreviewForm.controls['reviewPeriodEnd'].invalid"
          >
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Review Status -->
      <div class="col-md-6">
        <label class="labelText">
          Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Completed">Completed</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
          <mat-error *ngIf="treatmentreviewForm.controls['status'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentreviewForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
