import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { MbscModule } from '@mobiscroll/angular';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {
  DateAdapter as CalendarDateAdapter,
  CalendarModule,
} from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgChartsModule } from 'ng2-charts';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AngularMaterialModule } from '../../material-imports';
import { AddEditCaseDialogComponent } from '../cases/case-dialogs/case-add-edit/case-add-edit.component';
import { CasesModule } from '../cases/cases.module';
import { MyAssessmentsComponent } from '../patient-details/patient-components/assessments/my-assessments/my-assessments.component';
import { PatientsModule } from '../patients/patients.module';
import { MemberAllergiesComponent } from './patient-components/allergies/allergies.component';
import { AddEditAllergyDialogComponent } from './patient-components/allergies/dialogs/add-edit-allergy-dialog/add-edit-allergy-dialog.component';
import { AssessmentChartDialogInterfaceComponent } from './patient-components/assessments/assessment-chart-dialog-interface/assessment-chart-dialog-interface.component';
import { AssessmentChartViewerComponent } from './patient-components/assessments/assessment-chart-viewer/assessment-chart-viewer.component';
import { AssessmentDialogInterfaceComponent } from './patient-components/assessments/assessment-dialog-interface/assessment-dialog-interface.component';
import { AssessmentHistoryComponent } from './patient-components/assessments/assessment-history/assessment-history.component';
import { AssessmentMainDialogComponent } from './patient-components/assessments/assessment-main-dialog/assessment-main-dialog.component';
import { AssessmentMobileInputComponent } from './patient-components/assessments/assessment-mobile-input/assessment-mobile-input.component';
import { AssessmentViewerComponent } from './patient-components/assessments/assessment-viewer/assessment-viewer.component';
import { AlcoholAddictionChartsComponent } from './patient-components/assessments/charts/addiction/alcohol-addiction-charts/alcohol-addiction-charts.component';
import { CiwaChartsComponent } from './patient-components/assessments/charts/addiction/ciwa-charts/ciwa-charts.component';
import { CowsChartsComponent } from './patient-components/assessments/charts/addiction/cows-charts/cows-charts.component';
import { CssrsChartsComponent } from './patient-components/assessments/charts/addiction/cssrs-charts/cssrs-charts.component';
import { Dsm5Level2SubstanceUseChartsComponent } from './patient-components/assessments/charts/addiction/dsm5-level2-substance-use-charts/dsm5-level2-substance-use-charts.component';
import { GamblingAddictionChartsComponent } from './patient-components/assessments/charts/addiction/gambling-addiction-charts/gambling-addiction-charts.component';
import { GamingAddictionChartsComponent } from './patient-components/assessments/charts/addiction/gaming-addiction-charts/gaming-addiction-charts.component';
import { InternetAddictionChartsComponent } from './patient-components/assessments/charts/addiction/internet-addiction-charts/internet-addiction-charts.component';
import { SexualAddictionChartsComponent } from './patient-components/assessments/charts/addiction/sexual-addiction-charts/sexual-addiction-charts.component';
import { ShoppingAddictionChartsComponent } from './patient-components/assessments/charts/addiction/shopping-addiction-charts/shopping-addiction-charts.component';
import { TapsChartsComponent } from './patient-components/assessments/charts/addiction/taps-charts/taps-charts.component';
import { AdhdDiagnosticRatingChartsComponent } from './patient-components/assessments/charts/adhd/adhd-diagnostic-rating-charts/adhd-diagnostic-rating-charts.component';
import { AttentionAwarenessChartsComponent } from './patient-components/assessments/charts/adhd/attention-awareness-charts/attention-awareness-charts.component';
import { SelfReportScaleChartsComponent } from './patient-components/assessments/charts/adhd/self-report-scale-charts/self-report-scale-charts.component';
import { AggressionQuestionnaireChartsComponent } from './patient-components/assessments/charts/aggression/aggression-questionnaire-charts/aggression-questionnaire-charts.component';
import { ChildrensAnxietyChartsComponent } from './patient-components/assessments/charts/anxiety/childrens-anxiety-charts/childrens-anxiety-charts.component';
import { GeneralizedAnxietyChartsComponent } from './patient-components/assessments/charts/anxiety/generalized-anxiety-charts/generalized-anxiety-charts.component';
import { HamiltonAnxietyChartsComponent } from './patient-components/assessments/charts/anxiety/hamilton-anxiety-charts/hamilton-anxiety-charts.component';
import { LiebowitzAnxietyChartsComponent } from './patient-components/assessments/charts/anxiety/liebowitz-anxiety-charts/liebowitz-anxiety-charts.component';
import { PanicAgoraphobiaChartsComponent } from './patient-components/assessments/charts/anxiety/panic-agoraphobia-charts/panic-agoraphobia-charts.component';
import { PennStateWorryChartsComponent } from './patient-components/assessments/charts/anxiety/penn-state-worry-charts/penn-state-worry-charts.component';
import { ShortHealthAxietyChartsComponent } from './patient-components/assessments/charts/anxiety/short-health-anxiety-charts/short-health-anxiety-charts.component';
import { SocialPhobiaChartsComponent } from './patient-components/assessments/charts/anxiety/social-phobia-charts/social-phobia-charts.component';
import { TaylorManifestAnxietyChartsComponent } from './patient-components/assessments/charts/anxiety/taylor-manifest-anxiety-charts/taylor-manifest-anxiety-charts.component';
import { ZungChartsComponent } from './patient-components/assessments/charts/anxiety/zung-charts/zung-charts.component';
import { AutismSpectrumQuotientChartsComponent } from './patient-components/assessments/charts/autism/autism-spectrum-quotient-charts/autism-spectrum-quotient-charts.component';
import { AutismSpectrumScreeningChartsComponent } from './patient-components/assessments/charts/autism/autism-spectrum-screening-charts/autism-spectrum-screening-charts.component';
import { ChildhoodAutismSpectrumChartsComponent } from './patient-components/assessments/charts/autism/childhood-autism-spectrum-charts/childhood-autism-spectrum-charts.component';
import { InfantToddlerChartsComponent } from './patient-components/assessments/charts/autism/infant-toddler-charts/infant-toddler-charts.component';
import { QuantitativeChecklistForAutismChartsComponent } from './patient-components/assessments/charts/autism/quantitative-checklist-for-autism-charts/quantitative-checklist-for-autism-charts.component';
import { RitvoAutismAspergerChartsComponent } from './patient-components/assessments/charts/autism/ritvo-autism-asperger-charts/ritvo-autism-asperger-charts.component';
import { AltmanSelfRatingChartsComponent } from './patient-components/assessments/charts/bipolar/altman-self-rating-charts/altman-self-rating-charts.component';
import { YoungManiaRatingChartsComponent } from './patient-components/assessments/charts/bipolar/young-mania-rating-charts/young-mania-rating-charts.component';
import { AdolescentDepressionChartsComponent } from './patient-components/assessments/charts/depression/adolescent-depression-charts/adolescent-depression-charts.component';
import { GeriatricChartsComponent } from './patient-components/assessments/charts/depression/geriatric-charts/geriatric-charts.component';
import { MajorDepressionInventoryChartsComponent } from './patient-components/assessments/charts/depression/major-depression-inventory-charts/major-depression-inventory-charts.component';
import { MontgomeryAsbergDepressionRatingChartsComponent } from './patient-components/assessments/charts/depression/montgomery-asberg-depression-rating-charts/montgomery-asberg-depression-rating-charts.component';
import { PatientHealthChartsComponent } from './patient-components/assessments/charts/depression/patient-health-charts/patient-health-charts.component';
import { PostnatalDepressionChartsComponent } from './patient-components/assessments/charts/depression/postnatal-depression-charts/postnatal-depression-charts.component';
import { ZungDepressionChartsComponent } from './patient-components/assessments/charts/depression/zung-depression-charts/zung-depression-charts.component';
import { BingeEatingChartsComponent } from './patient-components/assessments/charts/eating/binge-eating-charts/binge-eating-charts.component';
import { EatingAttitudesChartsComponent } from './patient-components/assessments/charts/eating/eating-attitudes-charts/eating-attitudes-charts.component';
import { CdriscChartsComponent } from './patient-components/assessments/charts/general/cdrisc-charts/cdrisc-charts.component';
import { Core10ChartsComponent } from './patient-components/assessments/charts/general/core10-charts/core10-charts.component';
import { DifferentionOfSelfInventoryChartsComponent } from './patient-components/assessments/charts/general/differention-of-self-inventory-charts/differention-of-self-inventory-charts.component';
import { Dsm5L1AdultChartsComponent } from './patient-components/assessments/charts/general/dsm5-l1-adult-charts/dsm5-l1-adult-charts.component';
import { Dsm5L1EleventoseventeenChartsComponent } from './patient-components/assessments/charts/general/dsm5-l1-eleventoseventeen-charts/dsm5-l1-eleventoseventeen-charts.component';
import { Dsm5L1SixtoseventeenChartsComponent } from './patient-components/assessments/charts/general/dsm5-l1-sixtoseventeen-charts/dsm5-l1-sixtoseventeen-charts.component';
import { SurveyOfWellbeingOfYoungChildrenChartsComponent } from './patient-components/assessments/charts/general/swyc/survey-wellbeing-of-young-children-charts/survey-wellbeing-of-young-children-charts.component';
import { YpCore10ChartsComponent } from './patient-components/assessments/charts/general/yp-core10-charts/yp-core10-charts.component';
import { ObsessiveCompulsiveChartsComponent } from './patient-components/assessments/charts/ocd/obsessive-compulsive-charts/obsessive-compulsive-charts.component';
import { BigFivePersonalityChartsComponent } from './patient-components/assessments/charts/personality/big-five-personality-charts/big-five-personality-charts.component';
import { EmpathyQuotientChartsComponent } from './patient-components/assessments/charts/personality/empathy-quotient-charts/empathy-quotient-charts.component';
import { HarePsychopathyChartsComponent } from './patient-components/assessments/charts/personality/hare-psychopathy-charts/hare-psychopathy-charts.component';
import { PersonalityTypeChartsComponent } from './patient-components/assessments/charts/personality/personality-type-charts/personality-type-charts.component';
import { RevisedMysticalExperienceChartsComponent } from './patient-components/assessments/charts/personality/revised-mystical-experience-charts/revised-mystical-experience-charts.component';
import { TorontoEmpathyChartsComponent } from './patient-components/assessments/charts/personality/toronto-empathy-charts/toronto-empathy-charts.component';
import { AasChartsComponent } from './patient-components/assessments/charts/relationship-family/aas-charts/aas-charts.component';
import { AcesChartsComponent } from './patient-components/assessments/charts/relationship-family/aces-charts/aces-charts.component';
import { ApgarChartsComponent } from './patient-components/assessments/charts/relationship-family/apgar-charts/apgar-charts.component';
import { CprsChartsComponent } from './patient-components/assessments/charts/relationship-family/cprs-charts/cprs-charts.component';
import { CsiChartsComponent } from './patient-components/assessments/charts/relationship-family/csi-charts/csi-charts.component';
import { GenogramChartComponent } from './patient-components/assessments/charts/relationship-family/genogram-chart/genogram-chart.component';
import { InventoryOfComplicatedGriefChartsComponent } from './patient-components/assessments/charts/stress/inventory-of-complicated-grief-charts/inventory-of-complicated-grief-charts.component';
import { PtsdScreeningChartsComponent } from './patient-components/assessments/charts/stress/ptsd-screening-charts/ptsd-screening-charts.component';
import { TraumaRelatedShameChartsComponent } from './patient-components/assessments/charts/stress/trauma-related-shame-charts/trauma-related-shame-charts.component';
import { SrsChartsComponent } from './patient-components/assessments/charts/therapeutic-alliance/srs-charts/srs-charts.component';
import { AlcoholAddictionComponent } from './patient-components/assessments/dialogs/addiction/alcohol-addiction/alcohol-addiction.component';
import { AsiAlcoholDrugsComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-alcohol-drugs/asi-alcohol-drugs.component';
import { AsiEmploymentSupportComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-employment-support/asi-employment-support.component';
import { AsiFamilyHistoryComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-family-history/asi-family-history.component';
import { AsiFamilySocialRelationshipsComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-family-social-relationships/asi-family-social-relationships.component';
import { AsiGeneralComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-general/asi-general.component';
import { AsiHomeComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-home/asi-home.component';
import { AsiLegalComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-legal/asi-legal.component';
import { AsiMedicalComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-medical/asi-medical.component';
import { AsiPsychiatricComponent } from './patient-components/assessments/dialogs/addiction/asi/asi-psychiatric/asi-psychiatric.component';
import { Dsm5Level2SubstanceUseComponent } from './patient-components/assessments/dialogs/addiction/dsm5-level2-substance-use/dsm5-level2-substance-use.component';
import { GamblingAddictionComponent } from './patient-components/assessments/dialogs/addiction/gambling-addiction/gambling-addiction.component';
import { GamingAddictionComponent } from './patient-components/assessments/dialogs/addiction/gaming-addiction/gaming-addiction.component';
import { InternetAddictionComponent } from './patient-components/assessments/dialogs/addiction/internet-addiction/internet-addiction.component';
import { SexualAddictionComponent } from './patient-components/assessments/dialogs/addiction/sexual-addiction/sexual-addiction.component';
import { ShoppingAddictionComponent } from './patient-components/assessments/dialogs/addiction/shopping-addiction/shopping-addiction.component';
import { TapsAssessmentComponent } from './patient-components/assessments/dialogs/addiction/taps-assessment/taps-assessment.component';
import { AdhdDiagnosisRatingComponent } from './patient-components/assessments/dialogs/adhd/adhd-diagnosis-rating/adhd-diagnosis-rating.component';
import { AttentionAwarenessScaleComponent } from './patient-components/assessments/dialogs/adhd/attention-awareness-scale/attention-awareness-scale.component';
import { SelfReportScaleComponent } from './patient-components/assessments/dialogs/adhd/self-report-scale/self-report-scale.component';
import { AggressionQuestionnaireComponent } from './patient-components/assessments/dialogs/aggression/aggression-questionnaire/aggression-questionnaire.component';
import { ChildrensAnxietyComponent } from './patient-components/assessments/dialogs/anxiety/childrens-anxiety/childrens-anxiety.component';
import { GeneralizedAnxietyComponent } from './patient-components/assessments/dialogs/anxiety/generalized-anxiety/generalized-anxiety.component';
import { HamiltonAnxietyComponent } from './patient-components/assessments/dialogs/anxiety/hamilton-anxiety/hamilton-anxiety.component';
import { LiebowitzAnxietyComponent } from './patient-components/assessments/dialogs/anxiety/liebowitz-anxiety/liebowitz-anxiety.component';
import { PanicAgoraphobiaComponent } from './patient-components/assessments/dialogs/anxiety/panic-agoraphobia/panic-agoraphobia.component';
import { PennStateWorryComponent } from './patient-components/assessments/dialogs/anxiety/penn-state-worry/penn-state-worry.component';
import { ShortHealthAnxietyComponent } from './patient-components/assessments/dialogs/anxiety/short-health-anxiety/short-health-anxiety.component';
import { SocialPhobiaComponent } from './patient-components/assessments/dialogs/anxiety/social-phobia/social-phobia.component';
import { TaylorManifestComponent } from './patient-components/assessments/dialogs/anxiety/taylor-manifest/taylor-manifest.component';
import { ZungSelfRatingScaleDialogComponent } from './patient-components/assessments/dialogs/anxiety/zung-self-rating-scale-dialog/zung-self-rating-scale-dialog.component';
import { AutismSpectrumQuotientComponent } from './patient-components/assessments/dialogs/autism/autism-spectrum-quotient/autism-spectrum-quotient.component';
import { AutismSpectrumScreeningComponent } from './patient-components/assessments/dialogs/autism/autism-spectrum-screening/autism-spectrum-screening.component';
import { ChildhoodAutismSpectrumComponent } from './patient-components/assessments/dialogs/autism/childhood-autism-spectrum/childhood-autism-spectrum.component';
import { InfantToddlerComponent } from './patient-components/assessments/dialogs/autism/infant-toddler/infant-toddler.component';
import { QuantitativeChecklistComponent } from './patient-components/assessments/dialogs/autism/quantitative-checklist/quantitative-checklist.component';
import { RitvoAspergerComponent } from './patient-components/assessments/dialogs/autism/ritvo-asperger/ritvo-asperger.component';
import { AltmanManiaComponent } from './patient-components/assessments/dialogs/bipolar/altman-mania/altman-mania.component';
import { YoungManiaComponent } from './patient-components/assessments/dialogs/bipolar/young-mania/young-mania.component';
import { AdolescentDepressionComponent } from './patient-components/assessments/dialogs/depression/adolescent-depression/adolescent-depression.component';
import { GeriatricDepressionScaleDialogComponent } from './patient-components/assessments/dialogs/depression/geriatric-depression-scale-dialog/geriatric-depression-scale-dialog.component';
import { MajorDepressionComponent } from './patient-components/assessments/dialogs/depression/major-depression/major-depression.component';
import { PatientHealthQuestionnaireDialogComponent } from './patient-components/assessments/dialogs/depression/patient-health-questionnaire-dialog/patient-health-questionnaire-dialog.component';
import { PostnatalDepressionComponent } from './patient-components/assessments/dialogs/depression/postnatal-depression/postnatal-depression.component';
import { ZungDepressionScaleComponent } from './patient-components/assessments/dialogs/depression/zung-depression-scale/zung-depression-scale.component';
import { BingeEatingScaleComponent } from './patient-components/assessments/dialogs/eating-disorder/binge-eating-scale/binge-eating-scale.component';
import { EatingAttitudesComponent } from './patient-components/assessments/dialogs/eating-disorder/eating-attitudes/eating-attitudes.component';
import { CdriscAssessmentDialogComponent } from './patient-components/assessments/dialogs/general/cdrisc-assessment-dialog/cdrisc-assessment-dialog.component';
import { Core10AssessmentDialogComponent } from './patient-components/assessments/dialogs/general/core10-assessment-dialog/core10-assessment-dialog.component';
import { CrossCuttingLevelOneAdultComponent } from './patient-components/assessments/dialogs/general/cross-cutting-level-one-adult/cross-cutting-level-one-adult.component';
import { CrossCuttingLevelOneElevenToSeventeenComponent } from './patient-components/assessments/dialogs/general/cross-cutting-level-one-eleven-to-seventeen/cross-cutting-level-one-eleven-to-seventeen.component';
import { CrossCuttingLevelOneSixToSeventeenComponent } from './patient-components/assessments/dialogs/general/cross-cutting-level-one-six-to-seventeen/cross-cutting-level-one-six-to-seventeen.component';
import { DifferentionOfSelfInventoryComponent } from './patient-components/assessments/dialogs/general/differention-of-self-inventory/differention-of-self-inventory.component';
import { SurveyOfWellbeingOfYoungChildrenComponent } from './patient-components/assessments/dialogs/general/swyc/survey-wellbeing-of-young-children/survey-wellbeing-of-young-children.component';
import { Ypcore10AssessmentDialogComponent } from './patient-components/assessments/dialogs/general/ypcore10-assessment-dialog/ypcore10-assessment-dialog.component';
import { ObsessiveCompulsiveInventoryComponent } from './patient-components/assessments/dialogs/ocd/obsessive-compulsive-inventory/obsessive-compulsive-inventory.component';
import { BigFiveComponent } from './patient-components/assessments/dialogs/personality/big-five/big-five.component';
import { EmpathyQuotientComponent } from './patient-components/assessments/dialogs/personality/empathy-quotient/empathy-quotient.component';
import { HarePsychopathyComponent } from './patient-components/assessments/dialogs/personality/hare-psychopathy/hare-psychopathy.component';
import { MysticalExperienceComponent } from './patient-components/assessments/dialogs/personality/mystical-experience/mystical-experience.component';
import { PersonalityTypeComponent } from './patient-components/assessments/dialogs/personality/personality-type/personality-type.component';
import { TorontoEmpathyDialogComponent } from './patient-components/assessments/dialogs/personality/toronto-empathy-dialog/toronto-empathy-dialog.component';
import { AasAssessmentDialogComponent } from './patient-components/assessments/dialogs/relationship-family/aas-assessment-dialog/aas-assessment-dialog.component';
import { AcesAssessmentDialogComponent } from './patient-components/assessments/dialogs/relationship-family/aces-assessment-dialog/aces-assessment-dialog.component';
import { ApgarAssessmentDialogComponent } from './patient-components/assessments/dialogs/relationship-family/apgar-assessment-dialog/apgar-assessment-dialog.component';
import { CprsAssessmentDialogComponent } from './patient-components/assessments/dialogs/relationship-family/cprs-assessment-dialog/cprs-assessment-dialog.component';
import { CsiAssessmentDialogComponent } from './patient-components/assessments/dialogs/relationship-family/csi-assessment-dialog/csi-assessment-dialog.component';
import { GenogramComponent } from './patient-components/assessments/dialogs/relationship-family/genogram/genogram.component';
import { ComplicatedGriefComponent } from './patient-components/assessments/dialogs/stress/complicated-grief/complicated-grief.component';
import { PtsdScreeningComponent } from './patient-components/assessments/dialogs/stress/ptsd-screening/ptsd-screening.component';
import { TraumaRelatedShameComponent } from './patient-components/assessments/dialogs/stress/trauma-related-shame/trauma-related-shame.component';
import { SrsAssessmentDialogComponent } from './patient-components/assessments/dialogs/therapeutic-alliance/srs-assessment-dialog/srs-assessment-dialog.component';
import { MoodAssessmentReportComponent } from './patient-components/assessments/mood/mood-assessment-report/mood-assessment-report.component';
import { AddPatientGoalDialog } from './patient-components/assessments/patient-assessment-goals/dialog/add/add.component';
import { PatientAssessmentGoalsComponent } from './patient-components/assessments/patient-assessment-goals/patient-assessment-goals.component';
import { PrintGuidanceComponent } from './patient-components/assessments/print-guidance/print-guidance.component';
import { ReoccurringAssessmentComponent } from './patient-components/assessments/reoccurring-assessment/reoccurring-assessment.component';
import { RequestAssessmentComponent } from './patient-components/assessments/request-assessment/request-assessment.component';
import { ViewGuidanceComponent } from './patient-components/assessments/view-guidance/view-guidance.component';
import { AssessmentEditFollowupComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/assessment-follow-up/assessment-edit-followup/assessment-edit-followup.component';
import { AssessmentFollowUpComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/assessment-follow-up/assessment-follow-up.component';
import { AddAssessmentGoalNotesComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/assessment-goal-notes/add-assessment-goal-notes/add-assessment-goal-notes.component';
import { AssessmentGoalNotesComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/assessment-goal-notes/assessment-goal-notes.component';
import { EditAssessmentGoalComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/edit-assessment-goal/edit-assessment-goal.component';
import { GoalNotesByPatientComponent } from './patient-components/assessments/view-patient-assessment-goals/dialogs/goal-notes-by-patient/goal-notes-by-patient.component';
import { ViewPatientAssessmentGoalsComponent } from './patient-components/assessments/view-patient-assessment-goals/view-patient-assessment-goals.component';
import { ViewSummaryComponent } from './patient-components/assessments/view-summary/view-summary.component';
import { MemberCasesComponent } from './patient-components/case-list/case-list.component';
import { AddDiagnosisComponent } from './patient-components/diagnosis-codes/add-diagnosis/add-diagnosis.component';
import { DiagnosisCodesComponent } from './patient-components/diagnosis-codes/diagnosis-codes.component';
import { EditDiagnosisComponent } from './patient-components/diagnosis-codes/edit-diagnosis/edit-diagnosis.component';
import { DoseSpotComponent } from './patient-components/erx/dose-spot/dose-spot.component';
import { ErxComponent } from './patient-components/erx/erx.component';

import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { MemberGoalsComponent } from '../modules/member-goals/member-goals/member-goals.component';
import { CiwaComponent } from './patient-components/assessments/dialogs/addiction/ciwa/ciwa.component';
import { CowsComponent } from './patient-components/assessments/dialogs/addiction/cows/cows.component';
import { CssrsComponent } from './patient-components/assessments/dialogs/addiction/cssrs/cssrs.component';
import { EligibilityRecordsComponent } from './patient-components/eligibility-records/eligibility-records.component';
import { ManualEligibilityModalComponent } from './patient-components/eligibility-records/manual-eligibility-modal/manual-eligibility-modal.component';
import { GroupLevelNotesComponent } from './patient-components/group-sessions/group-level-notes/group-level-notes.component';
import { GroupMemberBillingComponent } from './patient-components/group-sessions/group-member-billing/group-member-billing.component';
import { GroupMemberCollectPaymentsComponent } from './patient-components/group-sessions/group-member-collect-payments/group-member-collect-payments.component';
import { GroupMemberDiagnosisComponent } from './patient-components/group-sessions/group-member-diagnosis/group-member-diagnosis.component';
import { GroupMemberGoalsComponent } from './patient-components/group-sessions/group-member-goals/group-member-goals.component';
import { GroupMemberLevelNotesComponent } from './patient-components/group-sessions/group-member-level-notes/group-member-level-notes.component';
import { GroupMemberMedicationsComponent } from './patient-components/group-sessions/group-member-medications/group-member-medications.component';
import { GroupMemberVitalsComponent } from './patient-components/group-sessions/group-member-vitals/group-member-vitals.component';
import { GroupSessionAssessmentComponent } from './patient-components/group-sessions/group-session-assessment/group-session-assessment.component';
import { GroupSessionPrintNotesComponent } from './patient-components/group-sessions/group-session-print-notes/group-session-print-notes.component';
import { GroupSessionsHandleMembersComponent } from './patient-components/group-sessions/group-sessions-handle-members/group-sessions-handle-members.component';
import { GroupSessionsComponent } from './patient-components/group-sessions/group-sessions.component';
import { FamilyHistoryComponent } from './patient-components/history/family-history/family-history.component';
import { HistoryComponent } from './patient-components/history/history.component';
import { MedicalHistoryComponent } from './patient-components/history/medical-history/medical-history.component';
import { AddEditMedicationDialogComponent } from './patient-components/medications/dialogs/add-edit-medication-dialog/add-edit-medication-dialog.component';
import { MedicationsComponent } from './patient-components/medications/medications.component';
import { AcknowledgeMemberAlertsComponent } from './patient-components/member-alerts/acknowledge-member-alerts/acknowledge-member-alerts.component';
import { CpCompleteRequestedDocumentsComponent } from './patient-components/member-alerts/cp-complete-requested-documents/cp-complete-requested-documents.component';
import { MemberAddEditAlertsComponent } from './patient-components/member-alerts/member-add-edit-alerts/member-add-edit-alerts.component';
import { MemberAlertsComponent } from './patient-components/member-alerts/member-alerts.component';
import { FilePreviewComponent } from './patient-components/member-alerts/verify-submitted-documents/file-preview/file-preview.component';
import { MemberClaimsComponent } from './patient-components/member-claims/member-claims.component';
import { AddMemberDocumentComponent } from './patient-components/member-documents/add/add-member-document/add-member-document.component';
import { MemberFileUploadComponent } from './patient-components/member-documents/add/member-file-upload/member-file-upload.component';
import { PreviewMemberDocumentComponent } from './patient-components/member-documents/dialogs/preview-member-document/preview-member-document.component';
import { MemberDocumentsComponent } from './patient-components/member-documents/member-documents.component';
import { RequestIntakeDocumentsComponent } from './patient-components/member-documents/request-intake-documents/request-intake-documents.component';
import { BehaviorIncidentReportComponent } from './patient-components/member-reports/behavior-incident-report/behavior-incident-report.component';
import { BehaviorPredictionComponent } from './patient-components/member-reports/behavior-incident-report/behavior-prediction/behavior-prediction.component';
import { ViewIncidentLogsComponent } from './patient-components/member-reports/behavior-incident-report/view-incident-logs/view-incident-logs.component';
import { HourlyMoodReportComponent } from './patient-components/member-reports/hourly-mood-report/hourly-mood-report.component';
import { ViewMoodLogComponent } from './patient-components/member-reports/hourly-mood-report/view-mood-log/view-mood-log.component';
import { MemberReportComponent } from './patient-components/member-reports/member-report.component';
import { AddEditLabOrderComponent } from './patient-components/member-tab/member-tab-sections/lab-orders/dialogs/add-edit-lab-order/add-edit-lab-order.component';
import { LabOrdersComponent } from './patient-components/member-tab/member-tab-sections/lab-orders/lab-orders.component';
import { MemberAssessmentsComponent } from './patient-components/member-tab/member-tab-sections/member-assessments/member-assessments.component';
import { MemberAddConsentDocumentsComponent } from './patient-components/member-tab/member-tab-sections/member-consent-documents/member-add-consent-documents/member-add-consent-documents.component';
import { MemberConsentDocumentsComponent } from './patient-components/member-tab/member-tab-sections/member-consent-documents/member-consent-documents.component';
import { ViewMemberSignedConsentDocumentComponent } from './patient-components/member-tab/member-tab-sections/member-consent-documents/view-member-signed-consent-document/view-member-signed-consent-document.component';
import { MemberContactsComponent } from './patient-components/member-tab/member-tab-sections/member-contacts/member-contacts.component';
import { AddContactDialogComponent } from './patient-components/member-tab/member-tab-sections/member-contacts/personal-contacts/dialogs/add/add.component';
import { MemberPersonalContactsComponent } from './patient-components/member-tab/member-tab-sections/member-contacts/personal-contacts/personal-contacts.component';
import { AddPhysicianDialogComponent } from './patient-components/member-tab/member-tab-sections/member-contacts/physicians/dialogs/add/add.component';
import { MemberPhysiciansComponent } from './patient-components/member-tab/member-tab-sections/member-contacts/physicians/physicians.component';
import { AddEditMemberInsuranceComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/add-edit-member-insurance/add-edit-member-insurance.component';
import { InsuranceCardComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/insurance-card/insurance-card.component';
import { RunEligibilityComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/insurance-card/run-eligibility/run-eligibility.component';
import { EligibilityTransactionDetailsDialogComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/insurance-card/view-eligibility-reports/dialogs/eligibility-transaction-details-dialog/eligibility-transaction-details-dialog.component';
import { ViewEligibilityReportsComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/insurance-card/view-eligibility-reports/view-eligibility-reports.component';
import { MemberInsuranceComponent } from './patient-components/member-tab/member-tab-sections/member-insurance/member-insurance.component';
import { MemberPaymentsCardsComponent } from './patient-components/member-tab/member-tab-sections/member-payments-cards/member-payments-cards.component';
import { CollectMemberPaymentComponent } from './patient-components/member-tab/member-tab-sections/member-payments-transactions/collect-member-payment/collect-member-payment.component';
import { MemberPaymentsTransactionsComponent } from './patient-components/member-tab/member-tab-sections/member-payments-transactions/member-payments-transactions.component';
import { ViewMemberPaymentNotesComponent } from './patient-components/member-tab/member-tab-sections/member-payments-transactions/view-member-payment-notes/view-member-payment-notes.component';
import { MemberPaymentsComponent } from './patient-components/member-tab/member-tab-sections/member-payments/member-payments.component';
import { AddEditMemberpharmacyDialogComponent } from './patient-components/member-tab/member-tab-sections/member-pharmacy/dialogs/add-edit-memberpharmacy-dialog/add-edit-memberpharmacy-dialog.component';
import { MemberPharmacyComponent } from './patient-components/member-tab/member-tab-sections/member-pharmacy/member-pharmacy.component';
import { MemberTabComponent } from './patient-components/member-tab/member-tab.component';
import { MyAssignedCasesComponent } from './patient-components/my-assigned-cases/my-assigned-cases.component';
import { AssignAccessComponent } from './patient-components/profile/dialogs/assign-access/assign-access.component';
import { MemberProfileComponent } from './patient-components/profile/profile.component';
import { AddInSessionInterview } from './patient-components/sessions/add-insession-interview/add-insession-interview.component';
import { BpsaClinicalComponent } from './patient-components/sessions/bpsa/bpsa-clinical/bpsa-clinical.component';
import { AddConsentDocumentComponent } from './patient-components/sessions/bpsa/bpsa-clinical/clinician-informed-consent/add-consent-document/add-consent-document.component';
import { ClinicianInformedConsentComponent } from './patient-components/sessions/bpsa/bpsa-clinical/clinician-informed-consent/clinician-informed-consent.component';
import { SignAndUnlockComponent } from './patient-components/sessions/bpsa/bpsa-clinical/clinician-informed-consent/sign-and-unlock/sign-and-unlock.component';
import { CulturalSpirutualLeisureStrengthsComponent } from './patient-components/sessions/bpsa/bpsa-clinical/cultural-spirutual-leisure-strengths/cultural-spirutual-leisure-strengths.component';
import { EducationalAndVocationalHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/educational-and-vocational-history/educational-and-vocational-history.component';
import { FinancialMaritalFamilyLegalHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/financial-marital-family-legal-history/financial-marital-family-legal-history.component';
import { MemberFamilyHistoryListComponent } from './patient-components/sessions/bpsa/bpsa-clinical/member-family-history-list/member-family-history-list.component';
import { MemberFamilyHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/member-family-history/member-family-history.component';
import { MemberHealthHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/member-health-history/member-health-history.component';
import { MemberMedicalHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/member-medical-history/member-medical-history.component';
import { SubstanceMentalTraumaHistoryComponent } from './patient-components/sessions/bpsa/bpsa-clinical/substance-mental-trauma-history/substance-mental-trauma-history.component';
import { BpsaIntakeComponent } from './patient-components/sessions/bpsa/bpsa-intake/bpsa-intake.component';
import { IntakeAssignClinicianComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-assign-clinician/intake-assign-clinician.component';
import { IntakeClinicalGuidanceReasoningComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-clinical-guidance-reasoning/intake-clinical-guidance-reasoning.component';
import { IntakeHeaderComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-header/intake-header.component';
import { IntakeHealthInfoComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-health-info/intake-health-info.component';
import { IntakeInsuranceInfoComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-insurance-info/intake-insurance-info.component';
import { IntakeSearchAddInsuranceComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-insurance-info/intake-search-add-insurance/intake-search-add-insurance.component';
import { IntakeMemberDataComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-member-data/intake-member-data.component';
import { IntakePaymentInfoComponent } from './patient-components/sessions/bpsa/bpsa-intake/intake-payment-info/intake-payment-info.component';
import { SessionAssignComponent } from './patient-components/sessions/dialogs/session-assign/session-assign.component';
import { StartPhoneNoteComponent } from './patient-components/sessions/dialogs/start-phone-note/start-phone-note.component';
import { SessionAssessmentsComponent } from './patient-components/sessions/session-assessments/session-assessments.component';
import { ArchiveClaimConfirmationComponent } from './patient-components/sessions/session-billing/archive-claim-confirmation/archive-claim-confirmation.component';
import { EditBillingRenderingProviderComponent } from './patient-components/sessions/session-billing/edit-billing-rendering-provider/edit-billing-rendering-provider.component';
import { EditPriorAuthComponent } from './patient-components/sessions/session-billing/edit-prior-auth/edit-prior-auth.component';
import { EditReferringProviderComponent } from './patient-components/sessions/session-billing/edit-referring-provider/edit-referring-provider.component';
import { EditSupervisingProviderComponent } from './patient-components/sessions/session-billing/edit-supervising-provider/edit-supervising-provider.component';
import { SearchSupervisingProviderComponent } from './patient-components/sessions/session-billing/edit-supervising-provider/search-supervising-provider/search-supervising-provider.component';
import { LookupNpiComponent } from './patient-components/sessions/session-billing/lookup-npi/lookup-npi.component';
import { SelectReferringProviderComponent } from './patient-components/sessions/session-billing/select-referring-provider/select-referring-provider.component';
import { SessionBillingComponent } from './patient-components/sessions/session-billing/session-billing.component';
import { ExistingDiagnosisComponent } from './patient-components/sessions/session-codes/session-diagnosis-codes/existing-diagnosis/existing-diagnosis.component';
import { SessionDiagnosisCodesComponent } from './patient-components/sessions/session-codes/session-diagnosis-codes/session-diagnosis-codes.component';
import { EditConfirmProcedureCodeComponent } from './patient-components/sessions/session-codes/session-procedural-codes/edit-confirm-procedure-code/edit-confirm-procedure-code.component';
import { SearchAddProcedureCodeComponent } from './patient-components/sessions/session-codes/session-procedural-codes/search-add-procedure-code/search-add-procedure-code.component';
import { SessionProceduralCodesComponent } from './patient-components/sessions/session-codes/session-procedural-codes/session-procedural-codes.component';
import { CloseSessionComponent } from './patient-components/sessions/session-details/close-session/close-session.component';
import { SessionDetailsComponent } from './patient-components/sessions/session-details/session-details.component';
import { SessionDocumentsComponent } from './patient-components/sessions/session-documents/session-documents.component';
import { SessionGeneralNotesComponent } from './patient-components/sessions/session-general-notes/session-general-notes.component';
import { AddedGoalsComponent } from './patient-components/sessions/session-goals-by-category/added-goals/added-goals.component';
import { AvailableGoalsComponent } from './patient-components/sessions/session-goals-by-category/available-goals/available-goals.component';
import { AddEditSessionGoalComponent } from './patient-components/sessions/session-goals-by-category/goal-card/add-edit-session-goal/add-edit-session-goal.component';
import { GoalCardComponent } from './patient-components/sessions/session-goals-by-category/goal-card/goal-card.component';
import { SessionGoalsByCategoryComponent } from './patient-components/sessions/session-goals-by-category/session-goals-by-category.component';
import { SessionGoalsComponent } from './patient-components/sessions/session-goals/session-goals.component';
import { ClinicalShortcutTemplatesComponent } from './patient-components/sessions/session-notes/clinical-shortcut-templates/clinical-shortcut-templates.component';
import { ChangeNoteTypeDialogComponent } from './patient-components/sessions/session-notes/dialogs/change-note-type-dialog/change-note-type-dialog.component';
import { PrintDialogComponent } from './patient-components/sessions/session-notes/dialogs/print-dialog/print-dialog.component';
import { LoadExistingClinicalNoteComponent } from './patient-components/sessions/session-notes/load-existing-clinical-note/load-existing-clinical-note.component';
import { SessionAddClinicalNoteComponent } from './patient-components/sessions/session-notes/session-add-clinical-note/session-add-clinical-note.component';
import { SessionClinicalNotesComponent } from './patient-components/sessions/session-notes/session-clinical-notes/session-clinical-notes.component';
import { TemplateDialogComponent } from './patient-components/sessions/session-notes/template-dialog/template-dialog.component';
import { SessionObjectivesComponent } from './patient-components/sessions/session-objectives/session-objectives.component';
import { BpsaSummaryDocumentComponent } from './patient-components/sessions/session-print/bpsa-summary-document/bpsa-summary-document.component';
import { ClinicalNotesComponent } from './patient-components/sessions/session-print/clinical-notes/clinical-notes.component';
import { IntakeDocumentsComponent } from './patient-components/sessions/session-print/intake-documents/intake-documents.component';
import { SessionPrintComponent } from './patient-components/sessions/session-print/session-print.component';
import { SubjectiveObjectiveComponent } from './patient-components/sessions/session-print/subjective-objective/subjective-objective.component';
import { TreatmentPlanComponent } from './patient-components/sessions/session-print/treatment-plan/treatment-plan.component';
import { SessionSubjectivesComponent } from './patient-components/sessions/session-subjectives/session-subjectives.component';
import { SessionVitalsComponent } from './patient-components/sessions/session-vitals/session-vitals.component';
import { SessionsComponent } from './patient-components/sessions/sessions.component';
import { AddTherapyNotesDialogComponent } from './patient-components/sessions/therapy-notes/dialogs/add-therapy-notes-dialog/add-therapy-notes-dialog.component';
import { DeleteTherapyNotesDialogComponent } from './patient-components/sessions/therapy-notes/dialogs/delete-therapy-notes-dialog/delete-therapy-notes-dialog.component';
import { TherapyNotesComponent } from './patient-components/sessions/therapy-notes/therapy-notes.component';
import { TherapyComponent } from './patient-components/sessions/therapy/therapy.component';
import { PatientDetailsComponent } from './patient-details.component';
import { PatientDetailRoutes } from './patient-details.routing';
import { MemberGoalsModule } from '../modules/member-goals/member-goals.module';

@NgModule({
  imports: [
    PatientsModule,
    MbscModule,
    MemberGoalsModule,
    CommonModule,
    RouterModule.forChild(PatientDetailRoutes),
    FormsModule,
    AngularMaterialModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgChartsModule,
    PdfJsViewerModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgProgressModule,
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({
      locale: 'en',
      headerDateFormat: 'daysRange',
      logEnabled: true,
    }),
    CasesModule,
    PipesModule,
    NgxGpAutocompleteModule,
    RxReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEditorModule,
    IntakeHeaderComponent,
    CustomDirectivesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    PatientDetailsComponent,
    MemberProfileComponent,
    MemberAllergiesComponent,
    MemberPersonalContactsComponent,
    MemberContactsComponent,
    MemberCasesComponent,
    MemberPhysiciansComponent,
    AddEditCaseDialogComponent,
    AddPhysicianDialogComponent,
    AddContactDialogComponent,
    MedicationsComponent,
    AddEditMedicationDialogComponent,
    MemberPharmacyComponent,
    MedicalHistoryComponent,
    Core10AssessmentDialogComponent,
    GeriatricDepressionScaleDialogComponent,
    ZungSelfRatingScaleDialogComponent,
    AssessmentHistoryComponent,
    Core10ChartsComponent,
    ZungChartsComponent,
    GeriatricChartsComponent,
    FamilyHistoryComponent,
    PatientHealthQuestionnaireDialogComponent,
    InternetAddictionComponent,
    TorontoEmpathyDialogComponent,
    PatientHealthChartsComponent,
    InternetAddictionChartsComponent,
    TorontoEmpathyChartsComponent,
    AssessmentMainDialogComponent,
    ShoppingAddictionComponent,
    GamingAddictionComponent,
    AlcoholAddictionComponent,
    SexualAddictionComponent,
    ComplicatedGriefComponent,
    ObsessiveCompulsiveInventoryComponent,
    ZungDepressionScaleComponent,
    BingeEatingScaleComponent,
    GamingAddictionChartsComponent,
    AlcoholAddictionChartsComponent,
    SexualAddictionChartsComponent,
    ShoppingAddictionChartsComponent,
    GamblingAddictionChartsComponent,
    SelfReportScaleChartsComponent,
    AdhdDiagnosticRatingChartsComponent,
    AggressionQuestionnaireChartsComponent,
    GeneralizedAnxietyChartsComponent,
    HamiltonAnxietyChartsComponent,
    LiebowitzAnxietyChartsComponent,
    PanicAgoraphobiaChartsComponent,
    PennStateWorryChartsComponent,
    ShortHealthAxietyChartsComponent,
    SocialPhobiaChartsComponent,
    ChildrensAnxietyChartsComponent,
    TaylorManifestAnxietyChartsComponent,
    AutismSpectrumQuotientChartsComponent,
    AutismSpectrumScreeningChartsComponent,
    ChildhoodAutismSpectrumChartsComponent,
    InfantToddlerChartsComponent,
    QuantitativeChecklistForAutismChartsComponent,
    RitvoAutismAspergerChartsComponent,
    AltmanSelfRatingChartsComponent,
    YoungManiaRatingChartsComponent,
    PostnatalDepressionChartsComponent,
    AdolescentDepressionChartsComponent,
    MajorDepressionInventoryChartsComponent,
    MemberDocumentsComponent,
    AddMemberDocumentComponent,
    MontgomeryAsbergDepressionRatingChartsComponent,
    ZungDepressionChartsComponent,
    BingeEatingChartsComponent,
    EatingAttitudesChartsComponent,
    ObsessiveCompulsiveChartsComponent,
    BigFivePersonalityChartsComponent,
    EmpathyQuotientChartsComponent,
    HarePsychopathyChartsComponent,
    PersonalityTypeChartsComponent,
    RevisedMysticalExperienceChartsComponent,
    InventoryOfComplicatedGriefChartsComponent,
    PtsdScreeningChartsComponent,
    TraumaRelatedShameChartsComponent,
    ViewGuidanceComponent,
    GamblingAddictionComponent,
    AdhdDiagnosisRatingComponent,
    SelfReportScaleComponent,
    AggressionQuestionnaireComponent,
    ChildrensAnxietyComponent,
    GeneralizedAnxietyComponent,
    HamiltonAnxietyComponent,
    LiebowitzAnxietyComponent,
    PanicAgoraphobiaComponent,
    PennStateWorryComponent,
    ShortHealthAnxietyComponent,
    SocialPhobiaComponent,
    TaylorManifestComponent,
    AutismSpectrumQuotientComponent,
    AutismSpectrumScreeningComponent,
    ChildhoodAutismSpectrumComponent,
    InfantToddlerComponent,
    QuantitativeChecklistComponent,
    RitvoAspergerComponent,
    AltmanManiaComponent,
    YoungManiaComponent,
    AdolescentDepressionComponent,
    MajorDepressionComponent,
    PostnatalDepressionComponent,
    EatingAttitudesComponent,
    BigFiveComponent,
    EmpathyQuotientComponent,
    HarePsychopathyComponent,
    PersonalityTypeComponent,
    MysticalExperienceComponent,
    PtsdScreeningComponent,
    TraumaRelatedShameComponent,
    PrintGuidanceComponent,
    RequestAssessmentComponent,
    MyAssessmentsComponent,
    ViewSummaryComponent,
    ReoccurringAssessmentComponent,
    PatientAssessmentGoalsComponent,
    AddPatientGoalDialog,
    ViewPatientAssessmentGoalsComponent,
    AssessmentGoalNotesComponent,
    AddAssessmentGoalNotesComponent,
    EditAssessmentGoalComponent,
    AssessmentFollowUpComponent,
    AssessmentEditFollowupComponent,
    GoalNotesByPatientComponent,
    AssessmentViewerComponent,
    AssessmentDialogInterfaceComponent,
    AssessmentChartViewerComponent,
    AssessmentChartDialogInterfaceComponent,
    DiagnosisCodesComponent,
    AddDiagnosisComponent,
    AssessmentMobileInputComponent,
    SessionsComponent,
    SessionDetailsComponent,
    SessionAssessmentsComponent,
    SessionGoalsComponent,
    SessionPrintComponent,
    CloseSessionComponent,
    SubjectiveObjectiveComponent,
    CrossCuttingLevelOneSixToSeventeenComponent,
    CrossCuttingLevelOneElevenToSeventeenComponent,
    CrossCuttingLevelOneAdultComponent,
    Dsm5L1AdultChartsComponent,
    Dsm5L1SixtoseventeenChartsComponent,
    Dsm5L1EleventoseventeenChartsComponent,
    TherapyComponent,
    SessionAssignComponent,
    SessionDocumentsComponent,
    SessionGoalsByCategoryComponent,
    AvailableGoalsComponent,
    AddedGoalsComponent,
    GoalCardComponent,
    AddEditSessionGoalComponent,
    MemberAssessmentsComponent,
    MemberFileUploadComponent,
    MoodAssessmentReportComponent,
    TreatmentPlanComponent,
    BpsaSummaryDocumentComponent,
    AsiGeneralComponent,
    AsiMedicalComponent,
    AsiEmploymentSupportComponent,
    AsiFamilyHistoryComponent,
    AsiFamilySocialRelationshipsComponent,
    AsiLegalComponent,
    AsiPsychiatricComponent,
    AsiAlcoholDrugsComponent,
    HourlyMoodReportComponent,
    HistoryComponent,
    ViewMoodLogComponent,
    AddInSessionInterview,
    AsiHomeComponent,
    DoseSpotComponent,
    MemberReportComponent,
    BehaviorIncidentReportComponent,
    ViewIncidentLogsComponent,
    BehaviorPredictionComponent,
    Ypcore10AssessmentDialogComponent,
    AcesAssessmentDialogComponent,
    CprsAssessmentDialogComponent,
    ApgarAssessmentDialogComponent,
    CsiAssessmentDialogComponent,
    YpCore10ChartsComponent,
    CprsChartsComponent,
    AcesChartsComponent,
    CdriscAssessmentDialogComponent,
    CdriscChartsComponent,
    AasAssessmentDialogComponent,
    AasChartsComponent,
    SrsChartsComponent,
    SrsAssessmentDialogComponent,
    TapsAssessmentComponent,
    TapsChartsComponent,
    AttentionAwarenessScaleComponent,
    AttentionAwarenessChartsComponent,
    DifferentionOfSelfInventoryComponent,
    DifferentionOfSelfInventoryChartsComponent,
    Dsm5Level2SubstanceUseChartsComponent,
    Dsm5Level2SubstanceUseComponent,
    SurveyOfWellbeingOfYoungChildrenChartsComponent,
    SurveyOfWellbeingOfYoungChildrenComponent,
    CsiChartsComponent,
    ApgarChartsComponent,
    GenogramChartComponent,
    GenogramComponent,
    MemberInsuranceComponent,
    MemberTabComponent,
    AddEditMemberInsuranceComponent,
    SessionSubjectivesComponent,
    SessionObjectivesComponent,
    SessionDiagnosisCodesComponent,
    SessionProceduralCodesComponent,
    EditDiagnosisComponent,
    ExistingDiagnosisComponent,
    InsuranceCardComponent,
    SearchAddProcedureCodeComponent,
    EditConfirmProcedureCodeComponent,
    SessionBillingComponent,
    BpsaIntakeComponent,
    BpsaClinicalComponent,
    IntakeMemberDataComponent,
    IntakeInsuranceInfoComponent,
    IntakePaymentInfoComponent,
    IntakeHealthInfoComponent,
    IntakeClinicalGuidanceReasoningComponent,
    ClinicianInformedConsentComponent,
    MemberFamilyHistoryComponent,
    EducationalAndVocationalHistoryComponent,
    FinancialMaritalFamilyLegalHistoryComponent,
    MemberMedicalHistoryComponent,
    SubstanceMentalTraumaHistoryComponent,
    CulturalSpirutualLeisureStrengthsComponent,
    AddConsentDocumentComponent,
    IntakeAssignClinicianComponent,
    MemberHealthHistoryComponent,
    MemberFamilyHistoryListComponent,
    IntakeSearchAddInsuranceComponent,
    SessionGeneralNotesComponent,
    MemberClaimsComponent,
    EditBillingRenderingProviderComponent,
    EditSupervisingProviderComponent,
    SearchSupervisingProviderComponent,
    AddEditAllergyDialogComponent,
    EditPriorAuthComponent,
    EditReferringProviderComponent,
    LookupNpiComponent,
    ErxComponent,
    SignAndUnlockComponent,
    RunEligibilityComponent,
    ViewEligibilityReportsComponent,
    SelectReferringProviderComponent,
    TemplateDialogComponent,
    ClinicalNotesComponent,
    SessionAddClinicalNoteComponent,
    SessionClinicalNotesComponent,
    EligibilityTransactionDetailsDialogComponent,
    LoadExistingClinicalNoteComponent,
    MemberPaymentsComponent,
    MemberPaymentsCardsComponent,
    MemberPaymentsTransactionsComponent,
    CollectMemberPaymentComponent,
    ViewMemberPaymentNotesComponent,
    MemberAlertsComponent,
    MemberConsentDocumentsComponent,
    ViewMemberSignedConsentDocumentComponent,
    MemberAddConsentDocumentsComponent,
    CowsComponent,
    CssrsComponent,
    CiwaComponent,
    CiwaChartsComponent,
    CowsChartsComponent,
    CssrsChartsComponent,
    MemberAddEditAlertsComponent,
    PrintDialogComponent,
    PreviewMemberDocumentComponent,
    ChangeNoteTypeDialogComponent,
    TherapyNotesComponent,
    AddTherapyNotesDialogComponent,
    DeleteTherapyNotesDialogComponent,
    AssignAccessComponent,
    SessionVitalsComponent,
    StartPhoneNoteComponent,
    RequestIntakeDocumentsComponent,
    IntakeDocumentsComponent,
    AcknowledgeMemberAlertsComponent,
    ArchiveClaimConfirmationComponent,
    CpCompleteRequestedDocumentsComponent,
    EligibilityRecordsComponent,
    ManualEligibilityModalComponent,
    AddEditMemberpharmacyDialogComponent,
    GroupSessionsComponent,
    GroupSessionsHandleMembersComponent,
    ClinicalShortcutTemplatesComponent,
    GroupLevelNotesComponent,
    GroupMemberLevelNotesComponent,
    GroupMemberVitalsComponent,
    GroupMemberDiagnosisComponent,
    GroupMemberMedicationsComponent,
    GroupSessionAssessmentComponent,
    GroupMemberGoalsComponent,
    GroupSessionPrintNotesComponent,
    GroupMemberCollectPaymentsComponent,
    MyAssignedCasesComponent,
    GroupMemberBillingComponent,
    FilePreviewComponent,
    LabOrdersComponent,
    AddEditLabOrderComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    AssessmentViewerComponent,
    AssessmentChartViewerComponent,
    AssessmentMobileInputComponent,
    InsuranceCardComponent,
    SessionBillingComponent,
    AssessmentHistoryComponent,
    MemberInsuranceComponent,
    MedicalHistoryComponent,
    FamilyHistoryComponent,
    MemberConsentDocumentsComponent,
    ViewMemberSignedConsentDocumentComponent,
    MemberAddConsentDocumentsComponent,
    SessionsComponent,
    SessionBillingComponent,
    GroupSessionsComponent,
    ClinicalShortcutTemplatesComponent,
    GroupLevelNotesComponent,
    GroupMemberLevelNotesComponent,
    MyAssignedCasesComponent,
    GroupMemberBillingComponent,
    SessionGeneralNotesComponent,
    EligibilityRecordsComponent,
    MemberGoalsComponent,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyBRQux8xvWFFuuvjyrYuUpkb8s5mqtIni0',
        libraries: ['places'],
      }),
    },
  ],
})
export class PatientDetailModule {}
