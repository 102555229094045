<div style="margin-top: 10px; padding: 10px">
  <div class="row" *ngIf="selectedPlan.status === 'Active'">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addTreatmentPlanTarget()"
      >
        + Add Target
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No Targets found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>
    <mat-error *ngIf="apiError">
      An error occurred while retrieving Target records
    </mat-error>
  </div>

  <div
    class="mat-elevation-z8 table-responsive"
    *ngIf="data.total > 0"
    style="margin-top: 15px"
  >
    <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
      <ng-container matColumnDef="sequenceNumber">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let row">{{ row.sequenceNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>
      <ng-container matColumnDef="dimensions">
        <th mat-header-cell *matHeaderCellDef>Dimensions</th>
        <td mat-cell *matCellDef="let row">
          <ul>
            <li *ngFor="let dimension of row.dimensions">
              {{ dimension }}
            </li>
          </ul>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
      </ng-container>

      <ng-container matColumnDef="goals">
        <th mat-header-cell *matHeaderCellDef>Goals</th>
        <td mat-cell *matCellDef="let row">
          <button
            matTooltip="View Goals"
            type="button"
            mat-raised-button
            class="btn btn-just-icon btn-white btn-fab btn-round"
            color="primary"
            (click)="openGoalsInModalWindow(row)"
          >
            <i class="material-icons text_align-center">flag_circle</i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Action
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <button
            type="button"
            mat-raised-button
            class="btn btn-just-icon btn-white btn-fab btn-round"
            color="accent"
            (click)="editTreatmentPlanTarget(row)"
          >
            <i class="material-icons text_align-center">edit</i>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    ></mat-paginator>
  </div>
</div>
