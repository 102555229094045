<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Plan</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Plan</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="treatmentplansForm"
    (ngSubmit)="onTreatmentPlansFormSubmit()"
  >
    <div class="row">
      <!-- Plan Name -->
      <div class="col-md-12">
        <label class="labelText">
          Plan Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Plan Name"
            formControlName="planName"
            name="planName"
            required
          />
          <mat-error *ngIf="treatmentplansForm.controls['planName'].invalid">
            Plan name is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Plan Number -->
      <div class="col-md-6">
        <label class="labelText">
          Plan Number <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Plan Number"
            formControlName="planNumber"
            name="planNumber"
            required
            type="number"
            min="0"
          />
          <mat-error *ngIf="treatmentplansForm.controls['planNumber'].invalid">
            Plan number is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Plan Version -->
      <div class="col-md-6">
        <label class="labelText">
          Version <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Version"
            formControlName="versionNumber"
            name="versionNumber"
            required
          />
          <mat-error
            *ngIf="treatmentplansForm.controls['versionNumber'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Start Date  -->
      <div class="col-md-6">
        <label class="labelText">
          Start Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="treatmentplansForm.controls['startDate'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- End Date  -->
      <div class="col-md-6">
        <label class="labelText">
          End Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="endDate"
            matInput
            [matDatepicker]="endDatePicker"
            autoSlashDate
            [min]="treatmentplansForm.controls['startDate'].value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error *ngIf="treatmentplansForm.controls['endDate'].invalid">
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!--Status  -->
      <div class="col-md-12">
        <label class="labelText">
          Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
          <mat-error *ngIf="treatmentplansForm.controls['status'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Submit   -->
      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentplansForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
