<!-- BreadCrumb -->
<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <span
      [routerLink]="
        parent === 'claims'
          ? '/claims'
          : ['/main/member/', claimDetails?.patientId, 'CLAIMS']
      "
      style="color: #2196f3; cursor: pointer; font-weight: 500"
    >
      Claims
    </span>
    &nbsp; / &nbsp; {{ claimDetails?.claimCode }}
  </p>
</div>

<!-- Progress bar -->
<div *ngIf="isLoadingDetails">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingDetails"
  ></mat-progress-bar>
</div>

<!-- Claim Details Calling Header Card to display the Data -->
<div class="row" style="margin: 10px" *ngIf="claimDetails?.claimCode">
  <div class="col-md-12">
    <label class="labelHeader"> Claim Overview </label>
    <app-claims-overview-header
      [therapySession]="claimDetails?.therapySession"
    ></app-claims-overview-header>
  </div>
</div>

<!-- Additional Claim Action Buttons-->
<div class="row">
  <div
    class="col-md-12"
    style="display: flex; gap: 15px; margin: 20px; flex-wrap: wrap"
  >
    <button
      mat-raised-button
      type="button"
      *ngIf="claimDetails?.initialERAReceived"
      (click)="viewPaymentHistory()"
    >
      <mat-icon style="color: green"> local_atm </mat-icon>
      View Payments
    </button>

    <button type="button" mat-raised-button (click)="viewHistory()">
      <mat-icon style="color: #2196f3">history</mat-icon> View History
    </button>

    <!-- <button
      type="button"
      mat-raised-button
      (click)="fileOtherClaim()"
      *ngIf="
        claimDetails?.initialERAReceived && claimDetails?.secondaryClaimCode
      "
    >
      <mat-icon style="color: #d69f20">difference</mat-icon> View Secondary
      Claim
    </button> -->
  </div>
</div>

<!-- For Primary Billing Sequence -->
<div *ngIf="claimDetails?.claimCode && claimDetails?.billingSequence === 'P'">
  <app-session-billing
    [patient]="patient"
    [claimSubmittedToPayer]="claimDetails?.claimSubmittedToPayer"
    [billingStatus]="claimDetails?.billingStatus"
    [externalClaimsErrors]="claimDetails?.externalClaimsErrors"
    [therapySession]="claimDetails?.therapySession"
    parentClass="Claims"
    [isBPSAEnforced]="isBPSAEnforced"
    [hasClaimsViewAccess]="hasClaimsViewAccess"
    [hasClaimsEditAccess]="hasClaimsEditAccess"
    (updatedTherapySession)="reloadTherapySession($event)"
  ></app-session-billing>
</div>
