import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { Case } from 'src/app/_models/case/case';
import { DataTablesModel } from 'src/app/_models/data-tables-modal';
import { CaseTreatmentAsamApiService } from 'src/app/_services/case/treatment/admission/casetreatmentasam.service';
import { greenChart, hexToRGBA, orangeChart, pinkChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-treatment-asam-report',
  templateUrl: './treatment-asam-report.component.html',
  styleUrl: './treatment-asam-report.component.css'
})
export class TreatmentAsamReportComponent implements OnInit {
  @Input() case: Case;

  data: DataTablesModel = {} as DataTablesModel;

  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };
  dimension2ChartData: ChartConfiguration<'line'>['data']
  dimension3ChartData: ChartConfiguration<'line'>['data']
  dimension4ChartData: ChartConfiguration<'line'>['data']
  dimension5ChartData: ChartConfiguration<'line'>['data']
  dimension6ChartData: ChartConfiguration<'line'>['data']

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 0.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          yellowBg: {
            type: 'box',
            yMin: 0.5,
            yMax: 1.5,
            backgroundColor: hexToRGBA(yellowChart.backgroundColor, 1),
            borderColor: yellowChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          orangeBg: {
            type: 'box',
            yMin: 1.5,
            yMax: 2.5,
            backgroundColor: hexToRGBA(orangeChart.backgroundColor, 1),
            borderColor: orangeChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          pinkBg: {
            type: 'box',
            yMin: 2.5,
            yMax: 3.5,
            backgroundColor: hexToRGBA(pinkChart.backgroundColor, 1),
            borderColor: pinkChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 3.5,
            yMax: 4,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 20,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 1
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 1
        },
        min: 0,
        max: 4,
      }
    }
  };
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  isLoadingResults = false;
  apiError = false;
  action: string = '';

  constructor(
    public casetreatmentasamApiService: CaseTreatmentAsamApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 20;
    this.dimension2ChartData = JSON.parse(JSON.stringify(this.lineChartData));
    this.dimension3ChartData = JSON.parse(JSON.stringify(this.lineChartData));
    this.dimension4ChartData = JSON.parse(JSON.stringify(this.lineChartData));
    this.dimension5ChartData = JSON.parse(JSON.stringify(this.lineChartData));
    this.dimension6ChartData = JSON.parse(JSON.stringify(this.lineChartData));

    this.processData();
  }
  public processData() {
    let acuteIntoxicationRiskScores = [];
    let dimension2RiskScores = [];
    let dimension3RiskScores = [];
    let dimension4RiskScores = [];
    let dimension5RiskScores = [];
    let dimension6RiskScores = [];
    let labels = [];
    this.scoreDetails = [];

    this.isLoadingResults = true;
    this.error = '';
    this.casetreatmentasamApiService.getCaseTreatmentAsamList(this.case.id, this.case.patientId, "Y", this.data.per_page, this.data.page).subscribe(response => {
      this.isLoadingResults = false;
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        for (let item of this.data.items) {
          if (this.data.items.length === 1) {
            labels.push("");
            acuteIntoxicationRiskScores.push(item.acuteIntoxicationLevelOfRisk ? item.acuteIntoxicationLevelOfRisk : 0);
            dimension2RiskScores.push(item.bioMedicalLevelOfRisk ? item.bioMedicalLevelOfRisk : 0);
            dimension3RiskScores.push(item.emcLevelOfRisk ? item.emcLevelOfRisk : 0);
            dimension4RiskScores.push(item.readinessToChangeLevelOfRisk ? item.readinessToChangeLevelOfRisk : 0);
            dimension5RiskScores.push(item.relapsePotentialLevelOfRisk ? item.relapsePotentialLevelOfRisk : 0);
            dimension6RiskScores.push(item.recoveryLevelOfRisk ? item.recoveryLevelOfRisk : 0);
          }
          acuteIntoxicationRiskScores.push(item.acuteIntoxicationLevelOfRisk ? item.acuteIntoxicationLevelOfRisk : 0);
          dimension2RiskScores.push(item.bioMedicalLevelOfRisk ? item.bioMedicalLevelOfRisk : 0);
          dimension3RiskScores.push(item.emcLevelOfRisk ? item.emcLevelOfRisk : 0);
          dimension4RiskScores.push(item.readinessToChangeLevelOfRisk ? item.readinessToChangeLevelOfRisk : 0);
          dimension5RiskScores.push(item.relapsePotentialLevelOfRisk ? item.relapsePotentialLevelOfRisk : 0);
          dimension6RiskScores.push(item.recoveryLevelOfRisk ? item.recoveryLevelOfRisk : 0);

          let stillUtc = moment.utc(item.createdDate).toDate();
          let assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (let key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                let scoreObj = item[key];
                let scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (this.data.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                let returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": this.getChartColor(scores) };
                this.scoreMap.set(key, returnObj);
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
        });
        this.lineChartData.datasets[0].data = acuteIntoxicationRiskScores;
        this.lineChartData.labels = labels;

        this.dimension2ChartData.datasets[0].data = dimension2RiskScores;
        this.dimension2ChartData.labels = labels;

        this.dimension3ChartData.datasets[0].data = dimension3RiskScores;
        this.dimension3ChartData.labels = labels;

        this.dimension4ChartData.datasets[0].data = dimension4RiskScores;
        this.dimension4ChartData.labels = labels;

        this.dimension5ChartData.datasets[0].data = dimension5RiskScores;
        this.dimension5ChartData.labels = labels;

        this.dimension6ChartData.datasets[0].data = dimension6RiskScores;
        this.dimension6ChartData.labels = labels;


      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    let score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return pinkChart;
    } else if (score >= 2) {
      return orangeChart;
    } else if (score >= 1) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
}