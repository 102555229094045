<div
  *ngIf="hasCaseTaskViewAccess"
  style="padding: 20px; background-color: #ffffff"
>
  <div
    class="row"
    *ngIf="
      hasCaseTaskAddAccess &&
      patient.status === 'Active' &&
      case.status === 'active'
    "
    style="margin-bottom: 15px"
  >
    <div class="col-md-12" style="text-align: right">
      <button type="button" mat-raised-button color="warn" (click)="addNote()">
        + Add Task
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <div *ngIf="data.total === 0">
    <mat-error> No task found </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div *ngIf="isLoadingResults || apiError">
      <mat-error *ngIf="apiError">
        An error occurred while retrieving tasks
      </mat-error>
    </div>

    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef>Task</th>
          <td mat-cell *matCellDef="let row">{{ row.text }}</td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let row">{{ row.category }}</td>
        </ng-container>
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef>Priority</th>
          <td mat-cell *matCellDef="let row">{{ row.priority }}</td>
        </ng-container>
        <ng-container matColumnDef="shift">
          <th mat-header-cell *matHeaderCellDef>Shift</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.shift === '1'">{{
              orgConfig.firstShiftName ? orgConfig.firstShiftName : '1st'
            }}</span>
            <span *ngIf="row.shift === '2'">{{
              orgConfig.secondShiftName ? orgConfig.secondShiftName : '2nd'
            }}</span>
            <span *ngIf="row.shift === '3'">{{
              orgConfig.thirdShiftName ? orgConfig.thirdShiftName : '3rd'
            }}</span>
            <span
              *ngIf="
                row.shift !== '1' && row.shift !== '2' && row.shift !== '3'
              "
              >{{ row.shift }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="additionalInput">
          <th mat-header-cell *matHeaderCellDef>Ask For</th>
          <td mat-cell *matCellDef="let row">{{ row.additionalInput }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button
              *ngIf="case.status === 'active'"
              type="button"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="editNote(record)"
                *ngIf="hasCaseTaskEditAccess"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="deleteNote(record)"
                *ngIf="hasCaseTaskDeleteAccess"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [showFirstLastButtons]="true"
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-card appearance="raised" *ngIf="!hasCaseTaskViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error> Sorry, You don't have access to view tasks </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>
