import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICore10Assessment } from 'src/app/_models/assessments/general/core10assessment.model';
import {
  Core10AssessmentApiService,
  ToastMessageService
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-core10-assessment-dialog',
  templateUrl: './core10-assessment-dialog.component.html',
  styleUrls: ['./core10-assessment-dialog.component.css'],
})
export class Core10AssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public core10assessmentApiService: Core10AssessmentApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public core10assessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  core10assessment: ICore10Assessment;
  core10assessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.core10assessmentForm, this.errors);

    this.loadCore10Assessment();
  }

  loadCore10Assessment() {
    this.core10assessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.core10assessmentApiService
        .getCore10AssessmentDetails(this.assessmentId)
        .subscribe((core10assessment) => {
          if (core10assessment.data) {
            this.core10assessmentForm.controls['tenseAnxiousNervous'].setValue(
              core10assessment.data.tenseAnxiousNervous.answer
            );
            this.core10assessmentForm.controls['someoneForSupport'].setValue(
              core10assessment.data.someoneForSupport.answer
            );
            this.core10assessmentForm.controls['ableToCope'].setValue(
              core10assessment.data.ableToCope.answer
            );
            this.core10assessmentForm.controls['talkingToPeople'].setValue(
              core10assessment.data.talkingToPeople.answer
            );
            this.core10assessmentForm.controls['panicOrTerror'].setValue(
              core10assessment.data.panicOrTerror.answer
            );
            this.core10assessmentForm.controls['plannedToEndLife'].setValue(
              core10assessment.data.plannedToEndLife.answer
            );
            this.core10assessmentForm.controls[
              'difficultySleepingOrStaying'
            ].setValue(
              core10assessment.data.difficultySleepingOrStaying.answer
            );
            this.core10assessmentForm.controls['despairingOrHopeless'].setValue(
              core10assessment.data.despairingOrHopeless.answer
            );
            this.core10assessmentForm.controls['feelUnhappy'].setValue(
              core10assessment.data.feelUnhappy.answer
            );
            this.core10assessmentForm.controls[
              'unwantedImagesOrMemories'
            ].setValue(core10assessment.data.unwantedImagesOrMemories.answer);
            this.riskScore = core10assessment.data.riskScore;
          }
        });
    }
  }
  onCore10AssessmentFormSubmit() {
    if (this.core10assessmentForm.invalid) {
      this.core10assessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.core10assessment = Object.assign({}, this.core10assessment);
    this.core10assessment.patientId =
      this.core10assessmentForm.controls['patientId'].value;
    this.core10assessment.sessionId =
      this.core10assessmentForm.controls['sessionId'].value;
    this.core10assessment.assessmentRequestId =
      this.core10assessmentForm.controls['assessmentRequestId'].value;
    this.core10assessment.tenseAnxiousNervous = {
      answer: this.core10assessmentForm.controls['tenseAnxiousNervous'].value,
    };
    this.core10assessment.someoneForSupport = {
      answer: this.core10assessmentForm.controls['someoneForSupport'].value,
    };
    this.core10assessment.ableToCope = {
      answer: this.core10assessmentForm.controls['ableToCope'].value,
    };
    this.core10assessment.talkingToPeople = {
      answer: this.core10assessmentForm.controls['talkingToPeople'].value,
    };
    this.core10assessment.panicOrTerror = {
      answer: this.core10assessmentForm.controls['panicOrTerror'].value,
    };
    this.core10assessment.plannedToEndLife = {
      answer: this.core10assessmentForm.controls['plannedToEndLife'].value,
    };
    this.core10assessment.difficultySleepingOrStaying = {
      answer:
        this.core10assessmentForm.controls['difficultySleepingOrStaying'].value,
    };
    this.core10assessment.despairingOrHopeless = {
      answer: this.core10assessmentForm.controls['despairingOrHopeless'].value,
    };
    this.core10assessment.feelUnhappy = {
      answer: this.core10assessmentForm.controls['feelUnhappy'].value,
    };
    this.core10assessment.unwantedImagesOrMemories = {
      answer:
        this.core10assessmentForm.controls['unwantedImagesOrMemories'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.core10assessment.accessCode = this.accessCode;

      this.core10assessmentApiService
        .addGuestCore10Assessment(this.core10assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    } else {
      this.core10assessmentApiService
        .addCore10Assessment(this.core10assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    tenseAnxiousNervous: [
      {
        type: 'required',
        message: 'I have felt tense, anxious or nervous is required',
      },
    ],
    someoneForSupport: [
      {
        type: 'required',
        message:
          'I have felt I have someone to turn to for support when needed is required',
      },
    ],
    ableToCope: [
      {
        type: 'required',
        message: 'I have felt able to cope when things go wrong is required',
      },
    ],
    talkingToPeople: [
      {
        type: 'required',
        message: 'Talking to people has felt too much for me is required',
      },
    ],
    panicOrTerror: [
      { type: 'required', message: 'I have felt panic or terror is required' },
    ],
    plannedToEndLife: [
      { type: 'required', message: 'I made plans to end my life is required' },
    ],
    difficultySleepingOrStaying: [
      {
        type: 'required',
        message:
          'I have had difficulty getting to sleep or staying asleep is required',
      },
    ],
    despairingOrHopeless: [
      {
        type: 'required',
        message: 'I have felt despairing or hopeless is required',
      },
    ],
    feelUnhappy: [
      { type: 'required', message: 'I have felt unhappy is required' },
    ],
    unwantedImagesOrMemories: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
  };

  private buildForm() {
    this.core10assessmentForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      tenseAnxiousNervous: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      someoneForSupport: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      ableToCope: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      talkingToPeople: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      panicOrTerror: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      plannedToEndLife: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      difficultySleepingOrStaying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      despairingOrHopeless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelUnhappy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      unwantedImagesOrMemories: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
    this.core10assessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.core10assessmentForm) {
      return;
    }
    const form = this.core10assessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
