import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Case, DataTablesModel } from 'src/app/_models';
import {
  CaseApiService,
  IcdCodeService,
  PatientInsuranceApiService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services/';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-case-add-edit-dialog',
  templateUrl: './case-add-edit.component.html',
  styleUrls: ['./case-add-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditCaseDialogComponent implements OnInit {
  patientId: string;
  facilityId: string;
  action: string;

  // Careproviders
  searchCareProvider: string = '';
  allCareProviders: any[];
  filteredCareProvidersList: any[];
  careProvidersLoading: boolean = false;

  // icd Code Data
  icdCodeData: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = false;
  icdLoading: boolean = false;

  // serviceTypes
  searchServiceType: string = '';
  serviceTypes: any[];
  filteredServiceTypes: any[];
  serviceTypesLoading: boolean = false;

  // active grants & insurance
  activeGrants: any[];
  activeInsurance: any[];

  // All Active Care Provider
  userName: string = '';
  allCaseManagers: any = [];
  filteredCaseManagers: any = [];
  caseManagersListLoading: boolean = false;

  public caseForm: FormGroup;
  case: Case;
  processing: boolean = false;

  todaysDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<AddEditCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public caseApiService: CaseApiService,
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private patientInsuranceApiService: PatientInsuranceApiService,
    private toastMessageService: ToastMessageService,
    public icdCodeService: IcdCodeService
  ) {
    this.action = data.action;
    this.case = data.case;
    this.patientId = data.patientId;
    this.facilityId = data.facilityId;
  }

  public ngOnInit() {
    this.icdCodeData.per_page = 25;
    this.icdCodeData.page = 0;

    this.loadServiceOptions();
    this.loadAllActiveCareProviders();
    this.loadAllActiveProvidersAtMyAssignedFacility();
    this.loadAllActiveGrants();
    this.loadAvailableInsuranceData();

    this.buildForm();

    if (this.action === 'EDIT') {
      this.caseForm.controls['id'].setValue(this.case.id);
      this.caseForm.controls['organizationId'].setValue(
        this.case.organizationId
      );
      this.caseForm.controls['patientId'].setValue(this.case.patientId);
      this.caseForm.controls['primaryDiagnosisCode'].setValue(
        this.case.primaryDiagnosisCode
      );
      this.caseForm.controls['description'].setValue(this.case.description);

      this.caseForm.controls['caseManagerId'].setValue(this.case.caseManagerId);
      this.caseForm.controls['caseManager'].setValue(this.case.caseManager);

      this.caseForm.controls['status'].setValue(this.case.status);
      this.caseForm.controls['selectedServices'].setValue(this.case.services);
      this.caseForm.controls['startDate'].setValue(
        mmddyyyyToDate(this.case.startDate)
      );

      // Admittance Date Conversion
      if (this.case.admittanceDate) {
        this.caseForm.controls['admittanceDate'].setValue(
          mmddyyyyToDate(this.case.admittanceDate)
        );
      }

      if (this.case.closeDate) {
        this.caseForm.controls['closeDate'].setValue(
          mmddyyyyToDate(this.case.closeDate)
        );
      }

      // New field values
      this.caseForm.controls['roomNumber'].setValue(this.case.roomNumber);
      if (this.case.expectedDischargeDate) {
        this.caseForm.controls['expectedDischargeDate'].setValue(
          mmddyyyyToDate(this.case.expectedDischargeDate)
        );
      }
      this.caseForm.controls['dischargedReasons'].setValue(
        this.case.dischargedReasons
      );
      this.caseForm.controls['transferredReasons'].setValue(
        this.case.transferredReasons
      );
      this.caseForm.controls['comments'].setValue(this.case.comments);
      // Grant providers and member insurance
      if (this.case.attachedGrantProviders) {
        this.caseForm.controls['attachedGrantProviders'].setValue(
          this.case.attachedGrantProviders
        );
      }
      if (this.case.attachedMemberInsurances) {
        this.caseForm.controls['attachedMemberInsurances'].setValue(
          this.case.attachedMemberInsurances
        );
      }
      if (this.case.additionalCaseManagers) {
        this.caseForm.controls['additionalCaseManagers'].setValue(
          this.case.additionalCaseManagers
        );
      }
    }
  }

  private buildForm() {
    this.caseForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      patientId: new FormControl(this.patientId),
      description: new FormControl(''),

      primaryDiagnosisCode: new FormControl(''),

      caseManager: new FormControl(''),
      caseManagerId: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(2)])
      ),

      status: new FormControl('active', Validators.required),
      selectedServices: new FormControl(null, Validators.required),
      startDate: new FormControl(null),
      closeDate: new FormControl(null),

      // Extra Fields
      roomNumber: new FormControl(''),
      admittanceDate: new FormControl(null),
      expectedDischargeDate: new FormControl(null),
      transferredReasons: new FormControl([]),
      dischargedReasons: new FormControl([]),
      comments: new FormControl(''),

      attachedGrantProviders: new FormControl([]),
      attachedMemberInsurances: new FormControl([]),

      // Additional Case Manager
      additionalCaseManagers: new FormControl([]),
    });

    this.caseForm.controls['primaryDiagnosisCode'].valueChanges.subscribe(
      () => {
        this.loadIcdList();
      }
    );
  }

  private loadIcdList() {
    this.icdLoading = true;

    this.icdCodeService
      .getICDCodes(
        this.caseForm.controls['primaryDiagnosisCode'].value,
        this.icdCodeData.per_page,
        this.icdCodeData.page
      )
      .subscribe({
        next: (response) => {
          this.icdLoading = false;
          if (response && response.items) {
            this.icdCodeData.items = response.items;
            this.icdCodeData.total = response.total;
          } else {
            this.icdCodeData.items = [];
            this.icdCodeData.total = 0;
          }
        },
        error: (error) => {
          this.icdLoading = false;
        },
      });
  }

  // Loading all active care providers
  loadAllActiveCareProviders() {
    this.careProvidersLoading = true;

    this.userApiService.getAllActiveCareProviders(this.facilityId).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.allCareProviders = response.items;
          this.filteredCareProvidersList = response.items;
        } else {
          this.allCareProviders = [];
          this.filteredCareProvidersList = [];
        }
        this.careProvidersLoading = false;
      },
      error: (error) => {
        this.careProvidersLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  // Load all available patient insurance
  loadAvailableInsuranceData() {
    this.patientInsuranceApiService
      .getMemberInsurances(this.patientId, null, null, null, 'Active', 30, 0)
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.activeInsurance = response.items;
          } else {
            this.activeInsurance = [];
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get member insurance details.'
          );
        },
      });
  }

  // Load all active staff members for my facility
  loadAllActiveProvidersAtMyAssignedFacility() {
    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.allCaseManagers = response.items;
          this.filteredCaseManagers = response.items;
        } else {
          this.allCaseManagers = [];
          this.filteredCaseManagers = [];
        }
        this.caseManagersListLoading = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load all care providers'
        );
        this.caseManagersListLoading = false;
      },
    });
  }

  // Filter case managers
  filterAllActiveCaseManagers() {
    if (this.userName) {
      this.filteredCaseManagers = this.allCaseManagers.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast.toLowerCase().includes(this.userName.toLowerCase()) ||
          concatLastFirst.toLowerCase().includes(this.userName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredCaseManagers = [...this.allCaseManagers];
    }
  }

  // Load all active grants avalable for this organization
  loadAllActiveGrants() {
    this.caseApiService.getAllActiveGrantProviders().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.activeGrants = response.items;
        } else {
          this.activeGrants = [];
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load available grants'
        );
      },
    });
  }

  // Filter case managers
  filterCaseManager() {
    if (this.searchCareProvider) {
      this.filteredCareProvidersList = this.allCareProviders.filter(
        (provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase()) ||
            concatLastFirst
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredCareProvidersList = [...this.allCareProviders];
    }
  }

  // loading all active service options
  loadServiceOptions() {
    this.serviceTypesLoading = true;

    this.caseApiService.getAllActiveServiceOptions().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.serviceTypes = response.items;
          this.filteredServiceTypes = response.items;
        } else {
          this.serviceTypes = [];
          this.filteredServiceTypes = [];
        }
        this.serviceTypesLoading = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load service types'
        );
        this.serviceTypesLoading = false;
      },
    });
  }

  // Filtering service type
  filterServiceType() {
    if (this.searchServiceType) {
      this.filteredServiceTypes = this.serviceTypes.filter((serviceType) => {
        if (
          serviceType.displayName
            .toLowerCase()
            .includes(this.searchServiceType.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredServiceTypes = [...this.serviceTypes];
    }
  }

  // Set case manager name
  public selectedValue(event: MatSelectChange) {
    this.caseForm.controls['caseManager'].setValue(event.source.triggerValue);
  }

  onCaseFormSubmit() {
    this.case = Object.assign({}, this.case);
    this.case = Object.assign(this.case, this.caseForm.value);

    // Admittance Date Conversion
    if (this.case.admittanceDate && this.case.admittanceDate !== '') {
      this.case.admittanceDate = formatDate(new Date(this.case.admittanceDate));
    } else {
      // Admittance date to be today if it is empty
      this.case.admittanceDate = formatDate(new Date());
    }

    // Dates conversion
    if (this.case.startDate && this.case.startDate !== '') {
      this.case.startDate = formatDate(new Date(this.case.startDate));
    }

    // Check if the case is closed
    if (this.case.status != 'active') {
      if (
        !this.case.closeDate ||
        this.case.closeDate === '' ||
        this.case.closeDate == null
      ) {
        this.case.closeDate = formatDate(new Date());
      }
    }
    // Case Close Date
    if (
      this.case.closeDate &&
      this.case.closeDate !== '' &&
      this.case.closeDate !== null
    ) {
      this.case.closeDate = formatDate(new Date(this.case.closeDate));
    }

    // Case Expected Discharge Date
    if (
      this.case.expectedDischargeDate &&
      this.case.expectedDischargeDate !== ''
    ) {
      this.case.expectedDischargeDate = formatDate(
        new Date(this.case.expectedDischargeDate)
      );
    }

    this.processing = true;

    if (this.action === 'EDIT') {
      this.caseApiService.updateCase(this.case).subscribe({
        next: (response) => {
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Case ' + this.case.caseNumber + ' has been updated.'
          );
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update case details'
          );
        },
      });
    } else {
      let selectedServices = this.caseForm.controls['selectedServices'].value;
      let selectedServicesArry = [];
      let selectedServicesIdArry = [];
      if (selectedServices.length > 0) {
        for (let i = 0; i < selectedServices.length; i++) {
          selectedServicesArry.push(selectedServices[i].displayName);
          selectedServicesIdArry.push(selectedServices[i].id);
        }
      } else {
        selectedServicesArry.push(selectedServices.displayName);
        selectedServicesIdArry.push(selectedServices.id);
      }

      this.case.services = selectedServicesArry;
      this.case.serviceIds = selectedServicesIdArry;

      this.caseApiService.addCase(this.case).subscribe({
        next: () => {
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'New case has been added.'
          );
        },
        error: () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add new case details'
          );
        },
      });
    }
  }

  // Remove Insurance Provider
  removeInsuranceProvider(provider, index) {
    let control = this.caseForm.get('attachedMemberInsurances').value as [];
    control.splice(index, 1);
    this.caseForm.controls['attachedMemberInsurances'].setValue(control);
  }

  // Remove Grant Provider
  removeGrantProvider(provider, index) {
    let control = this.caseForm.get('attachedGrantProviders').value as [];
    control.splice(index, 1);
    this.caseForm.controls['attachedGrantProviders'].setValue(control);
  }

  // Remove Additional Care Provider
  removeAdditionalManagers(manager, index) {
    let control = this.caseForm.get('additionalCaseManagers').value as [];
    control.splice(index, 1);
    this.caseForm.controls['additionalCaseManagers'].setValue(control);
  }

  // Compare Operator for service type
  compare(o1, o2) {
    return o1.displayName === o2[0];
  }

  // Compare function for grants
  compareGrants(o1, o2) {
    return o1.id === o2.id;
  }

  // Compare Additional Case Managers
  compareAdditionalCaseManagers(o1, o2) {
    return o1.id === o2.id;
  }
}
