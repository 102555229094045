import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ICaseTreatmentAsam } from 'src/app/_models/case/treatment/admission/casetreatmentasam.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CaseTreatmentAsamApiService {
  constructor(private httpClient: HttpClient) {}
  getCaseTreatmentAsamList(
    caseId: string,
    patientId: string,
    recentRecords: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('caseId', caseId);
    params = params.append('patientId', patientId);
    params = params.append('recentRecords', recentRecords);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/casetreatmentasamlist`,
      { params: params }
    );
  }

  getCaseTreatmentAsamDetails(
    recordId: string,
    caseId: string,
    reviewId: string,
    recordType: string,
    programId: string
  ): Observable<ResponseModal> {
    let params = new HttpParams();
    if (recordId) params = params.append('id', recordId);
    if (reviewId) params = params.append('reviewId', reviewId);
    if (caseId) params = params.append('caseId', caseId);
    if (recordType) params = params.append('recordType', recordType);
    if (programId) params = params.append('programId', programId);

    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/casetreatmentasam`,
      { params: params }
    );
  }

  getCaseTreatmentAsamDetailsById(
    casetreatmentasamId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/casetreatmentasambyid/` + casetreatmentasamId
    );
  }

  addCaseTreatmentAsam(
    thisCaseTreatmentAsam: ICaseTreatmentAsam
  ): Observable<ICaseTreatmentAsam> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICaseTreatmentAsam>(
        `${environment.apiUrl}/casetreatmentasam`,
        thisCaseTreatmentAsam,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateCaseTreatmentAsam(
    thisCaseTreatmentAsam: ICaseTreatmentAsam
  ): Observable<ICaseTreatmentAsam> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ICaseTreatmentAsam>(
        `${environment.apiUrl}/casetreatmentasam`,
        thisCaseTreatmentAsam,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
