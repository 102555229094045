<div class="container">
  <div class="dialogTitle" style="margin-bottom: 25px">
    <p matDialogTitle>Goal & Objectives</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="memberGoalForm">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Goal Description <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder=""
            formControlName="description"
            name="description"
            rows="3"
          ></textarea>
          <mat-error *ngIf="memberGoalForm.controls['description'].invalid">
            Goal description is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> Projected Achievement Date</label>
        <mat-form-field appearance="outline">
          <input
            formControlName="projectedAchievedDate"
            matInput
            [matDatepicker]="projectedAchievedDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="projectedAchievedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #projectedAchievedDatePicker></mat-datepicker>

          <mat-error
            *ngIf="memberGoalForm.controls['projectedAchievedDate'].invalid"
          >
            Please enter a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> Actual Achievement Date</label>
        <mat-form-field appearance="outline">
          <input
            formControlName="actualAchievedDate"
            matInput
            [matDatepicker]="actualAchievedDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="actualAchievedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #actualAchievedDatePicker></mat-datepicker>

          <mat-error
            *ngIf="memberGoalForm.controls['actualAchievedDate'].invalid"
          >
            Please enter a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Goal Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status" required>
            <mat-option [value]="status" *ngFor="let status of goalStatusList">
              {{ status }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="memberGoalForm.controls['status'].invalid">
            Please select a status
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="margin-bottom: 12px"
        *ngIf="patient.status === 'Active'"
      >
        <button
          type="button"
          class="btn btn-success pull-right"
          (click)="createObjective('', null, '')"
        >
          + Add Objective
        </button>
      </div>

      <div class="col-md-12" formArrayName="objectives">
        <ng-container
          *ngFor="
            let objective of objectives().controls;
            let objectiveIndex = index
          "
        >
          <mat-card
            appearance="outlined"
            class="mat-elevation-z8"
            style="width: 100%; position: relative; margin-bottom: 20px"
          >
            <div
              style="position: absolute; right: 10px; z-index: 1050"
              *ngIf="objectives().length > 1 && patient.status === 'Active'"
            >
              <mat-icon
                id="deleteButton"
                style="
                  color: red;
                  font-size: 19px;
                  cursor: pointer;
                  opacity: 0.5;
                "
                (click)="removeObjective(objectiveIndex)"
                >delete_forever</mat-icon
              >
            </div>

            <div class="input-wrapper" [formGroupName]="objectiveIndex">
              <label class="labelText pull-left">
                Objective <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder=""
                  formControlName="objectiveDescription"
                  required
                />
                <mat-error
                  *ngIf="objective.controls.objectiveDescription.invalid"
                >
                  Please describe the objective
                </mat-error>
              </mat-form-field>

              <div class="row">
                <div class="col-md-6">
                  <label class="labelText">
                    Target Date <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="targetDate"
                      matInput
                      [matDatepicker]="targetDatePicker"
                      placeholder="Target Date"
                      required
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="targetDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #targetDatePicker></mat-datepicker>
                    <mat-error *ngIf="objective.controls.targetDate.invalid">
                      Please select a valid target date
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <label class="labelText">
                    Status <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="status"
                      placeholder="Status"
                      required
                    >
                      <mat-option
                        [value]="status"
                        *ngFor="let status of goalObjectiveStatusList"
                      >
                        {{ status }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="objective.controls.status.invalid">
                      Please select the status
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div formArrayName="interventions">
                <div
                  *ngFor="
                    let intervention of getInterventions(objectiveIndex)
                      .controls;
                    let interventionIndex = index
                  "
                  [formGroupName]="interventionIndex"
                  style="margin-bottom: 12px; background-color: #f5f5f5"
                  appearance="outlined"
                  class="mat-elevation-z8"
                >
                  <div class="row" style="padding: 15px">
                    <div class="col-11">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="labelText">
                            Intervention Name <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <input
                              matInput
                              placeholder="Intervention Name"
                              formControlName="name"
                              required
                            />
                            <mat-error
                              *ngIf="intervention.controls['name'].invalid"
                            >
                              Required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-6">
                          <label class="labelBold">
                            Staff Member
                            <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="staffId"
                              placeholder="Staff Member"
                              (selectionChange)="
                                providerSelected(
                                  $event,
                                  objectiveIndex,
                                  interventionIndex
                                )
                              "
                            >
                              <mat-option>
                                <ngx-mat-select-search
                                  placeholderLabel="Staff Name"
                                  noEntriesFoundLabel="No matching staff found"
                                  [(ngModel)]="providerName"
                                  (ngModelChange)="filterProvider()"
                                  [ngModelOptions]="{ standalone: true }"
                                ></ngx-mat-select-search>
                              </mat-option>
                              <mat-option
                                *ngFor="let provider of filteredProvidersList"
                                [value]="provider.id"
                              >
                                {{ provider.lastName }},
                                {{ provider.firstName }}
                              </mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="intervention.controls['staffId'].invalid"
                            >
                              Required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-6">
                          <label class="labelText">
                            Status <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="status"
                              placeholder="Status"
                              required
                            >
                              <mat-option
                                [value]="status"
                                *ngFor="
                                  let status of goalInterventionStatusList
                                "
                              >
                                {{ status }}
                              </mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="intervention.controls['status'].invalid"
                            >
                              Required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-6">
                          <label class="labelText">
                            Frequency<span style="color: red">*</span>
                          </label>
                          <div class="row">
                            <div class="col-6">
                              <mat-form-field appearance="outline">
                                <input
                                  formControlName="frequencyNumber"
                                  matInput
                                  type="number"
                                  min="1"
                                />
                                <mat-error
                                  *ngIf="
                                    intervention.controls['frequencyNumber']
                                      .invalid
                                  "
                                >
                                  Minimum number is 1
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <div class="col-6">
                              <mat-form-field appearance="outline">
                                <mat-select formControlName="frequencyInterval">
                                  <mat-option value="Day">Day</mat-option>
                                  <mat-option value="Week">Week</mat-option>
                                  <mat-option value="Month">Month</mat-option>
                                </mat-select>
                                <mat-error
                                  *ngIf="
                                    intervention.controls['frequencyInterval']
                                      .invalid
                                  "
                                >
                                  Required
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-1" style="text-align: right">
                      <button
                        type="button"
                        mat-icon-button
                        color="warn"
                        (click)="
                          removeIntervention(objectiveIndex, interventionIndex)
                        "
                        style="margin-top: 24px"
                        matTooltip="Remove Intervention"
                      >
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" style="text-align: right">
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="addIntervention(objectiveIndex)"
                  >
                    + Intervention
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!memberGoalForm.valid || processing"
          (click)="updateGoal()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
