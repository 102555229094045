<div class="col-md-12">
  <form
    [formGroup]="casetreatmentadmissionprofileForm"
    novalidate
    (ngSubmit)="onCaseTreatmentAdmissionFormSubmit()"
  >
    <div class="row">
      <!-- Admission Type -->
      <div class="col-md-6">
        <label class="labelText">
          Admission Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="admissionType"
            placeholder="Please select admission type"
          >
            <mat-option value="First Admission">First Admission</mat-option>
            <mat-option value="Change in service">Change in service</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Transfer">Transfer</mat-option>
            <mat-option value="None">None</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['admissionType']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Admission Staff -->
      <div class="col-md-6">
        <label class="labelText">
          Admission Staff <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="admissionStaffId"
            placeholder="Please select a staff"
            (selectionChange)="staffSelected($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Provider Name"
                noEntriesFoundLabel="No matching provider found"
                [(ngModel)]="providerName"
                (ngModelChange)="filterProvider()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              [value]="supervisor.id"
              *ngFor="let supervisor of filteredProvidersList"
            >
              {{ supervisor.lastName }}, {{ supervisor.firstName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['admissionStaffId']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Admission Date -->
      <div class="col-md-6">
        <label class="labelText">
          Admission Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="admissionDate"
            [matDatepicker]="admissionDatePicker"
            placeholder="Admission Date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="admissionDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #admissionDatePicker></mat-datepicker>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['admissionDate']
                .invalid
            "
          >
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Admission Time -->
      <div class="col-md-6">
        <label class="labelText">
          Admission Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Admission Time"
            [ngxTimepicker]="admissionTimePicker"
            formControlName="admissionTime"
          />
          <ngx-material-timepicker
            #admissionTimePicker
          ></ngx-material-timepicker>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['admissionTime']
                .invalid
            "
          >
            Please select a valid time
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Est Duration of TX (Days) -->
      <div class="col-md-6">
        <label class="labelText">Est Duration of TX (Days)</label>
        <mat-form-field appearance="outline">
          <input
            formControlName="estDurationOfTxDays"
            type="number"
            matInput
            min="0"
            placeholder="Est Duration of TX (Days)"
          />
          <span matSuffix> days </span>
        </mat-form-field>
      </div>

      <!-- Treating Here For -->
      <div class="col-md-6">
        <label class="labelText">Treating Here For</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="treatingHereFor">
            <mat-option value="Drug Court">Drug Court</mat-option>
            <mat-option value="MH/SA">MH/SA</mat-option>
            <mat-option value="SA">SA</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Client Type -->
      <div class="col-md-6">
        <label class="labelText">Client Type</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="clientType">
            <mat-option value="Outpatient MH">Outpatient MH</mat-option>
            <mat-option value="In Patient MH">In Patient MH</mat-option>
            <mat-option value="In Patient SA">In Patient SA</mat-option>
            <mat-option value="Outpatient SA">Outpatient SA</mat-option>
            <mat-option value="Medication Management">
              Medication Management
            </mat-option>
            <mat-option value="Supported Living">Supported Living</mat-option>
            <mat-option value="None">None</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Codependent/Collateral -->
      <div class="col-md-6">
        <label class="labelText">
          Codependent/Collateral <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="codependentOrCollateral"
            placeholder="Codependent/Collateral"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls[
                'codependentOrCollateral'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- TB Test Results -->
      <div class="col-md-6">
        <label class="labelText">TB Test Results</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="tbTestResults">
            <mat-option value="Positive">Positive</mat-option>
            <mat-option value="Negative">Negative</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- TB Test Date -->
      <div class="col-md-6">
        <label class="labelText">Latest TB Test Date</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="latestTbTestDate"
            [matDatepicker]="latestTbTestDatePicker"
            placeholder="Latest TB Test Date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="latestTbTestDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #latestTbTestDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- # of Prior SA TX Admissions -->
      <div class="col-md-6">
        <label class="labelText">
          # of Prior SA TX Admissions <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            type="number"
            min="0"
            matInput
            formControlName="numberOfPriorSaTxAdmissions"
            placeholder="# of Prior SA TX Admissions"
          />
        </mat-form-field>
      </div>

      <div class="col-md-6"></div>

      <!-- Client Reported Health Status -->
      <div class="col-md-6">
        <label class="labelText">Client Reported Health Status</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="clientReportedHealthStatus">
            <mat-option value="Excellent">Excellent</mat-option>
            <mat-option value="Fair">Fair</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
            <mat-option value="Good">Good</mat-option>
            <mat-option value="Very Good">Very Good</mat-option>
            <mat-option value="Poor">Poor</mat-option>
            <mat-option value="Refused">Refused</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Mental Health Problem  -->
      <div class="col-md-6">
        <label class="labelText">
          Mental Health Problem <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="mentalHealthProblem"
            placeholder="Mental Health Problem"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['mentalHealthProblem']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--   Use of Methadone planned as part of Treatment  -->
      <div class="col-md-6">
        <label class="labelText">
          Use of Methadone planned as part of Treatment
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="useOfMethadone"
            placeholder="Use of Methadone planned as part of Treatment"
            (selectionChange)="useOfMethadone($event)"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['useOfMethadone']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Methadone Type -->
      <div class="col-md-6">
        <ng-container
          *ngIf="
            casetreatmentadmissionprofileForm.get('useOfMethadone').value ===
            'Yes'
          "
        >
          <label class="labelText">
            Methadone Type <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="methadoneType">
              <mat-option value="Methadone">Methadone</mat-option>
              <mat-option value="LAAM">LAAM</mat-option>
              <mat-option value="Buprenorphine">Buprenorphine</mat-option>
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                casetreatmentadmissionprofileForm.controls['methadoneType']
                  .invalid
              "
            >
              Required
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="col-md-6">
        <label class="labelText">
          Education Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="education"
            placeholder="Education Status"
          >
            <mat-option value="Less than one grade completed">
              Less than one grade completed
            </mat-option>
            <mat-option value="1st Grade">1st Grade</mat-option>
            <mat-option value="2nd Grade">2nd Grade</mat-option>
            <mat-option value="3rd Grade">3rd Grade</mat-option>
            <mat-option value="4th Grade">4th Grade</mat-option>
            <mat-option value="5th Grade">5th Grade</mat-option>
            <mat-option value="6th Grade">6th Grade</mat-option>
            <mat-option value="7th Grade">7th Grade</mat-option>
            <mat-option value="8th Grade">8th Grade</mat-option>
            <mat-option value="9th Grade">9th Grade</mat-option>
            <mat-option value="10th Grade">10th Grade</mat-option>
            <mat-option value="11th Grade">11th Grade</mat-option>
            <mat-option value="Grade 12, HS diploma, GED">
              Grade 12, HS diploma, GED
            </mat-option>
            <mat-option value="Some college, no degree">
              Some college, no degree
            </mat-option>
            <mat-option value="Two year college/associate degree">
              Two year college/associate degree
            </mat-option>
            <mat-option value="Four year college degree">
              Four year college degree
            </mat-option>
            <mat-option value="Graduate work, no degree">
              Graduate work, no degree
            </mat-option>
            <mat-option value="Graduate professional degree or more">
              Graduate professional degree or more
            </mat-option>
            <mat-option value="Vocational Training Beyond High School">
              Vocational Training Beyond High School
            </mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls['education'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText">
          # of times attended self-help programs
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            type="number"
            matInput
            formControlName="timesAttendedSelfHelpPrograms"
            placeholder="# of times attended self-help programs"
          />
          <mat-error
            *ngIf="
              casetreatmentadmissionprofileForm.controls[
                'timesAttendedSelfHelpPrograms'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!readOnly" class="pad20 pull-right">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!casetreatmentadmissionprofileForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
