import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITorontoEmpathyAssessment } from 'src/app/_models/assessments/personality/torontoempathyassessment.model';
import {
  ToastMessageService,
  TorontoEmpathyAssessmentApiService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-toronto-empathy-dialog',
  templateUrl: './toronto-empathy-dialog.component.html',
  styleUrls: ['./toronto-empathy-dialog.component.css'],
})
export class TorontoEmpathyDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public torontoempathyassessmentApiService: TorontoEmpathyAssessmentApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public torontoEmpathyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  torontoempathyassessment: ITorontoEmpathyAssessment;
  torontoempathyassessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.torontoEmpathyForm, this.errors);

    this.loadTorontoEmpathyAssessment();
  }

  loadTorontoEmpathyAssessment() {
    this.torontoEmpathyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.torontoempathyassessmentApiService
        .getTorontoEmpathyAssessmentDetails(this.assessmentId)
        .subscribe((torontoempathyassessment) => {
          if (torontoempathyassessment.data) {
            this.torontoEmpathyForm.controls['getExcitedWhenOthersDo'].setValue(
              torontoempathyassessment.data.getExcitedWhenOthersDo.answer
            );
            this.torontoEmpathyForm.controls[
              'othersMisfortunesDoNotDisturbMe'
            ].setValue(
              torontoempathyassessment.data.othersMisfortunesDoNotDisturbMe
                .answer
            );
            this.torontoEmpathyForm.controls[
              'upsetWhenOthersAreDisrespected'
            ].setValue(
              torontoempathyassessment.data.upsetWhenOthersAreDisrespected
                .answer
            );
            this.torontoEmpathyForm.controls[
              'unaffectedWhenOthersAreHappy'
            ].setValue(
              torontoempathyassessment.data.unaffectedWhenOthersAreHappy.answer
            );
            this.torontoEmpathyForm.controls[
              'enjoyMakingOthersFeelBetter'
            ].setValue(
              torontoempathyassessment.data.enjoyMakingOthersFeelBetter.answer
            );
            this.torontoEmpathyForm.controls[
              'feelForOthersLessForunate'
            ].setValue(
              torontoempathyassessment.data.feelForOthersLessForunate.answer
            );

            this.torontoEmpathyForm.controls[
              'dontListenToFriendsProblems'
            ].setValue(
              torontoempathyassessment.data.dontListenToFriendsProblems.answer
            );
            this.torontoEmpathyForm.controls[
              'canSenseWhenOthersBeingSad'
            ].setValue(
              torontoempathyassessment.data.canSenseWhenOthersBeingSad.answer
            );
            this.torontoEmpathyForm.controls['inTuneWithsOthersMoods'].setValue(
              torontoempathyassessment.data.inTuneWithsOthersMoods.answer
            );
            this.torontoEmpathyForm.controls[
              'dontFeelForPeopleCausingTheirOwnIllness'
            ].setValue(
              torontoempathyassessment.data
                .dontFeelForPeopleCausingTheirOwnIllness.answer
            );
            this.torontoEmpathyForm.controls[
              'getIrritatedWhenSomeoneCries'
            ].setValue(
              torontoempathyassessment.data.getIrritatedWhenSomeoneCries.answer
            );
            this.torontoEmpathyForm.controls[
              'dontCareAboutOthersFeelings'
            ].setValue(
              torontoempathyassessment.data.dontCareAboutOthersFeelings.answer
            );
            this.torontoEmpathyForm.controls['urgeToHelpSomeoneUpset'].setValue(
              torontoempathyassessment.data.urgeToHelpSomeoneUpset.answer
            );
            this.torontoEmpathyForm.controls[
              'dontFeelForUnfairlyTreatedPeople'
            ].setValue(
              torontoempathyassessment.data.dontFeelForUnfairlyTreatedPeople
                .answer
            );
            this.torontoEmpathyForm.controls[
              'feelSillyForPeopleToCryOutOfHappiness'
            ].setValue(
              torontoempathyassessment.data
                .feelSillyForPeopleToCryOutOfHappiness.answer
            );
            this.torontoEmpathyForm.controls[
              'protectiveTowardsSomeoneTakenAdvantageOf'
            ].setValue(
              torontoempathyassessment.data
                .protectiveTowardsSomeoneTakenAdvantageOf.answer
            );
            this.riskScore = torontoempathyassessment.data.riskScore;
          }
        });
    }
  }

  onTorontoEmpathyAssessmentFormSubmit() {
    if (this.torontoEmpathyForm.invalid) {
      this.torontoEmpathyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.torontoempathyassessment = Object.assign(
      {},
      this.torontoempathyassessment
    );
    this.torontoempathyassessment.patientId =
      this.torontoEmpathyForm.controls['patientId'].value;
    this.torontoempathyassessment.sessionId =
      this.torontoEmpathyForm.controls['sessionId'].value;
    this.torontoempathyassessment.assessmentRequestId =
      this.torontoEmpathyForm.controls['assessmentRequestId'].value;
    this.torontoempathyassessment.getExcitedWhenOthersDo = {
      answer: this.torontoEmpathyForm.controls['getExcitedWhenOthersDo'].value,
    };
    this.torontoempathyassessment.othersMisfortunesDoNotDisturbMe = {
      answer:
        this.torontoEmpathyForm.controls['othersMisfortunesDoNotDisturbMe']
          .value,
    };
    this.torontoempathyassessment.upsetWhenOthersAreDisrespected = {
      answer:
        this.torontoEmpathyForm.controls['upsetWhenOthersAreDisrespected']
          .value,
    };
    this.torontoempathyassessment.unaffectedWhenOthersAreHappy = {
      answer:
        this.torontoEmpathyForm.controls['unaffectedWhenOthersAreHappy'].value,
    };
    this.torontoempathyassessment.enjoyMakingOthersFeelBetter = {
      answer:
        this.torontoEmpathyForm.controls['enjoyMakingOthersFeelBetter'].value,
    };
    this.torontoempathyassessment.feelForOthersLessForunate = {
      answer:
        this.torontoEmpathyForm.controls['feelForOthersLessForunate'].value,
    };
    this.torontoempathyassessment.dontListenToFriendsProblems = {
      answer:
        this.torontoEmpathyForm.controls['dontListenToFriendsProblems'].value,
    };
    this.torontoempathyassessment.canSenseWhenOthersBeingSad = {
      answer:
        this.torontoEmpathyForm.controls['canSenseWhenOthersBeingSad'].value,
    };
    this.torontoempathyassessment.inTuneWithsOthersMoods = {
      answer: this.torontoEmpathyForm.controls['inTuneWithsOthersMoods'].value,
    };
    this.torontoempathyassessment.dontFeelForPeopleCausingTheirOwnIllness = {
      answer:
        this.torontoEmpathyForm.controls[
          'dontFeelForPeopleCausingTheirOwnIllness'
        ].value,
    };
    this.torontoempathyassessment.getIrritatedWhenSomeoneCries = {
      answer:
        this.torontoEmpathyForm.controls['getIrritatedWhenSomeoneCries'].value,
    };
    this.torontoempathyassessment.dontCareAboutOthersFeelings = {
      answer:
        this.torontoEmpathyForm.controls['dontCareAboutOthersFeelings'].value,
    };
    this.torontoempathyassessment.urgeToHelpSomeoneUpset = {
      answer: this.torontoEmpathyForm.controls['urgeToHelpSomeoneUpset'].value,
    };
    this.torontoempathyassessment.dontFeelForUnfairlyTreatedPeople = {
      answer:
        this.torontoEmpathyForm.controls['dontFeelForUnfairlyTreatedPeople']
          .value,
    };
    this.torontoempathyassessment.feelSillyForPeopleToCryOutOfHappiness = {
      answer:
        this.torontoEmpathyForm.controls[
          'feelSillyForPeopleToCryOutOfHappiness'
        ].value,
    };
    this.torontoempathyassessment.protectiveTowardsSomeoneTakenAdvantageOf = {
      answer:
        this.torontoEmpathyForm.controls[
          'protectiveTowardsSomeoneTakenAdvantageOf'
        ].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.torontoempathyassessment.accessCode = this.accessCode;

      this.torontoempathyassessmentApiService
        .addGuestTorontoEmpathyAssessment(this.torontoempathyassessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Toronto Empathy Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Toronto Empathy Assessment'
            );
          }
        );
    } else {
      this.torontoempathyassessmentApiService
        .addTorontoEmpathyAssessment(this.torontoempathyassessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Toronto Empathy Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Toronto Empathy Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    getExcitedWhenOthersDo: [
      {
        type: 'required',
        message: 'I have felt tense, anxious or nervous is required',
      },
    ],
    othersMisfortunesDoNotDisturbMe: [
      {
        type: 'required',
        message:
          'I have felt I have someone to turn to for support when needed is required',
      },
    ],
    upsetWhenOthersAreDisrespected: [
      {
        type: 'required',
        message: 'I have felt able to cope when things go wrong is required',
      },
    ],
    unaffectedWhenOthersAreHappy: [
      {
        type: 'required',
        message: 'Talking to people has felt too much for me is required',
      },
    ],
    enjoyMakingOthersFeelBetter: [
      { type: 'required', message: 'I have felt panic or terror is required' },
    ],
    feelForOthersLessForunate: [
      { type: 'required', message: 'I made plans to end my life is required' },
    ],
    dontListenToFriendsProblems: [
      {
        type: 'required',
        message:
          'I have had difficulty getting to sleep or staying asleep is required',
      },
    ],
    canSenseWhenOthersBeingSad: [
      {
        type: 'required',
        message: 'I have felt despairing or hopeless is required',
      },
    ],
    inTuneWithsOthersMoods: [
      { type: 'required', message: 'I have felt unhappy is required' },
    ],
    dontFeelForPeopleCausingTheirOwnIllness: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    getIrritatedWhenSomeoneCries: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    dontCareAboutOthersFeelings: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    urgeToHelpSomeoneUpset: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    dontFeelForUnfairlyTreatedPeople: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    feelSillyForPeopleToCryOutOfHappiness: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    protectiveTowardsSomeoneTakenAdvantageOf: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
  };

  private buildForm() {
    this.torontoEmpathyForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      getExcitedWhenOthersDo: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      othersMisfortunesDoNotDisturbMe: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      upsetWhenOthersAreDisrespected: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      unaffectedWhenOthersAreHappy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      enjoyMakingOthersFeelBetter: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelForOthersLessForunate: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      dontListenToFriendsProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      canSenseWhenOthersBeingSad: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      inTuneWithsOthersMoods: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      dontFeelForPeopleCausingTheirOwnIllness: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      getIrritatedWhenSomeoneCries: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      dontCareAboutOthersFeelings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      urgeToHelpSomeoneUpset: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      dontFeelForUnfairlyTreatedPeople: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelSillyForPeopleToCryOutOfHappiness: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      protectiveTowardsSomeoneTakenAdvantageOf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
    this.torontoEmpathyForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.torontoEmpathyForm) {
      return;
    }
    const form = this.torontoEmpathyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
