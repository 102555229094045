import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Case, Patient } from 'src/app/_models';
import { CaseApiService, GoalHttpService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { environment } from 'src/environments/environment';
import { AddEditCaseDialogComponent } from '../case-dialogs/case-add-edit/case-add-edit.component';

@Component({
  selector: 'app-case-details-v2',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.css'],
})
export class CaseDetailsComponent implements OnInit {
  @Input() caseId: string;
  @Input() activeTab: string;
  @Input() patient: Patient;

  @Input() hasCaseEditAccess: boolean;
  @Input() hasCaseTaskViewAccess: boolean;
  @Input() hasCaseTaskAddAccess: boolean;
  @Input() hasCaseTaskEditAccess: boolean;
  @Input() hasCaseTaskDeleteAccess: boolean;

  @Input() hasCaseNotesViewAccess: boolean;
  @Input() hasCaseNotesAddAccess: boolean;
  @Input() hasCaseNotesEditAccess: boolean;
  @Input() hasCaseNotesDeleteAccess: boolean;

  @Input() hasCaseDocumentsViewAccess: boolean;
  @Input() hasCaseDocumentsAddAccess: boolean;
  @Input() hasCaseDocumentsDeleteAccess: boolean;

  @Input() hasCaseLogViewAccess: boolean;
  @Input() hasCaseLogAddAccess: boolean;
  @Input() hasCaseLogEditAccess: boolean;
  @Input() hasCaseLogDeleteAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;
  @Output() output = new EventEmitter<any>();

  orgConfig: any;
  case: Case;
  goals: any[];
  memberGoals: any[];

  documentType: string;
  hasDischargeSummaryDocuments = false;

  isTreatmentSectionEnabled = environment.treatmentSectionEnabled;

  isTNwitsMember = false;
  hasEditChanges = false;
  reloadHeader: Subject<boolean> = new Subject<boolean>();
  loadDischargeDocs: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public apiService: CaseApiService,
    public goalApiService: GoalHttpService,
    public memberGoalHttpService: MemberGoalsHttpService,
    protected permissionsSandbox: PermissionsSandbox,
    private route: ActivatedRoute,

    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('id');
    }

    this.getCaseDetails(this.caseId);

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          this.isTNwitsMember = orgConfig.dropDownOptions === 'TNWITS';
        });
      }
    });

    if (!this.activeTab) {
      if (this.hasCaseNotesViewAccess) {
        this.activeTab = 'CARE-LOG';
      } else if (this.hasGoalsViewAccess) {
        this.activeTab = 'GOALS';
      } else if (this.hasCaseTaskViewAccess) {
        this.activeTab = 'TASKS';
      } else if (this.hasCaseNotesViewAccess) {
        this.activeTab = 'CASE-NOTES';
      } else if (this.hasCaseDocumentsViewAccess) {
        this.activeTab = 'DOCUMENTS';
      } else {
        this.activeTab = 'PRINT';
      }
    }
  }

  getCaseDetails(id: string) {
    this.apiService.getCaseDetails(id).subscribe((response) => {
      this.case = response.data;
      this.loadGoals();
      this.loadAllMemberGoals();
    });
  }

  public loadGoals() {
    this.goalApiService
      .getGoals(this.case.id, this.case.patientId, true, 1000, 0)
      .subscribe((response) => {
        if (response && response.items) {
          this.goals = response.items;
          this.goals = this.goals.filter((g) => g.status === 'active');
        }
      });
  }

  loadAllMemberGoals() {
    this.memberGoalHttpService
      .getPatientGoals(
        null,
        null,
        this.case.patientId,
        null,
        1000,
        0,
        null,
        null,
        null
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.memberGoals = response.items;
          }
        },
      });
  }

  onLoadTabTriggerEvent(payload: any) {
    if (payload.eventType === 'HAS_DISCHARGE_DOCUMENTS') {
      this.hasDischargeSummaryDocuments = true;
    } else {
      this.activeTab = payload.tabName;
      this.documentType = payload.documentType;
      this.loadDischargeDocs.next(true);
    }
  }

  onDocumentAddedEvent(payload: any) {
    if (
      payload.eventType === 'DISCHARGE_DOCUMENT_ADDED' &&
      !this.hasDischargeSummaryDocuments
    ) {
      this.hasDischargeSummaryDocuments = true;
      this.reloadHeader.next(true);
    }
  }

  reloadGoalsEvent(event) {
    this.loadAllMemberGoals();
  }

  public deSelectCaseTab() {
    let payload: any = {};
    payload.eventType = 'DESELECT_CASE';
    if (this.hasEditChanges) {
      payload.eventType = 'DESELECT_RELOAD_CASE';
    }

    this.output.emit(payload);
  }

  changeTab(selectedTab: string) {
    this.router.navigate(['/main/member/' + this.patient.id + '/CARE'], {
      queryParams: {
        secondaryId: this.caseId,
        secondaryTab: selectedTab,
      },
    });
  }

  public editCase() {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'EDIT',
        case: this.case,
        patientId: this.patient.id,
        facilityId: this.patient.facilityId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.getCaseDetails(this.caseId);
        this.hasEditChanges = true;
      }
    });
  }
}
