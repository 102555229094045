<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp; Referrals
  </p>
</div>

<!-- Search Box -->
<div
  class="row"
  style="
    margin-left: 5px;
    margin-right: 5px;
    row-gap: 15px;
    margin-bottom: 15px;
  "
>
  <div class="col-sm-12">
    <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
      <!-- Date Picker -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          min-width: 300px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          calendar_month
        </span>
        <mat-date-range-input
          [rangePicker]="picker"
          matTooltip="To search, please select the date range and click on the search icon"
        >
          <input
            matStartDate
            placeholder="Date Range"
            (click)="picker.open()"
            [(ngModel)]="searchStartDate"
          />
          <input
            matEndDate
            [(ngModel)]="searchEndDate"
            (click)="picker.open()"
          />
        </mat-date-range-input>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="searchChanged()"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="clearDate()"
        >
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-date-range-picker
          #picker
          [disabled]="false"
        ></mat-date-range-picker>
      </div>

      <!-- Member Name -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          person
        </span>
        <input
          type="search"
          placeholder="Member Name"
          [(ngModel)]="searchMemberName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <!-- Member DOB -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          perm_contact_calendar
        </span>
        <input
          type="search"
          placeholder="Member DOB"
          [(ngModel)]="searchMemberDOB"
          (ngModelChange)="searchChanged()"
          [matDatepicker]="dobPicker"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
          autoSlashDate
          (click)="dobPicker.open()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dobPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #dobPicker></mat-datepicker>
      </div>

      <!-- Member Phone -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          phone
        </span>
        <input
          type="search"
          placeholder="Member Phone"
          [(ngModel)]="searchMemberPhone"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <!-- Referring Provider -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Referring Provider"
          [(ngModel)]="searchProviderName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <!-- Status -->
      <div
        class="col"
        style="display: flex; justify-content: end; gap: 15px; flex-wrap: wrap"
      >
        <!-- Status Button -->
        <button mat-raised-button [matMenuTriggerFor]="filterMenu">
          <span>
            Status
            <span *ngIf="searchStatus != ''"> : {{ searchStatus }} </span>
          </span>
          <mat-icon>filter_list</mat-icon>
        </button>

        <mat-menu #filterMenu="matMenu">
          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterStatusChanged('')"
          >
            All
          </button>

          <button
            *ngFor="let search of allStatus"
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterStatusChanged(search)"
          >
            {{ search }}
          </button>

          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterStatusChanged('Member Imported')"
          >
            Member Imported
          </button>
        </mat-menu>

        <!-- Add Referral -->
        <button
          mat-raised-button
          color="warn"
          type="button"
          (click)="addReferral()"
        >
          + Referral
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="processing || apiError">
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving referrals
  </mat-error>
</div>

<div *ngIf="data.total === 0 && !processing">
  <mat-error> No referral found </mat-error>
</div>

<div class="mat-elevation-z8" *ngIf="data.total > 0" style="margin-top: 25px">
  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="data.items"
      class="mat-elevation-z8"
      multiTemplateDataRows
    >
      <!-- Patient Details -->
      <ng-container matColumnDef="member">
        <th mat-header-cell *matHeaderCellDef>Member</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex">
            <!-- Member Name -->
            <label class="labelBold">
              {{ row.patientLastName }}, {{ row.patientFirstName }}
              {{ row?.patientMiddleName }}
            </label>

            <!-- Gender/Date of Birth Box -->
            <div *ngIf="row?.patientGender || row?.patientDateOfBirth">
              (
              <label *ngIf="row?.patientGender" style="padding: 0; margin: 0">
                {{ row?.patientGender }},&nbsp;
              </label>
              <label
                *ngIf="row?.patientDateOfBirth"
                style="padding: 0; margin: 0"
              >
                {{ row?.patientDateOfBirth }}
              </label>
              )
            </div>

            <!-- Member Email -->
            <label class="justicyCenterLabel" *ngIf="row?.patientPhoneNumber">
              <mat-icon>phone</mat-icon>
              {{ row?.patientPhoneNumber | phoneFormat }}
            </label>

            <!-- Member Email -->
            <label class="justicyCenterLabel" *ngIf="row?.patientEmail">
              <mat-icon>email</mat-icon> {{ row?.patientEmail }}
            </label>

            <!-- Member Address -->
            <label class="justicyCenterLabel" *ngIf="row?.patientAddressLine1">
              <mat-icon>home</mat-icon> {{ row?.patientAddressLine1 }}
              {{ row?.patientAddressLine2 }}
            </label>
            <label
              class="justicyCenterLabel"
              *ngIf="row?.patientCity || row?.patientState || row?.patientZip"
            >
              {{ row?.patientCity }} {{ row?.patientState }}
              {{ row?.patientZip }}
            </label>

            <!-- Member Contact Note -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.patientContactNotes"
              style="font-style: italic"
            >
              <mat-icon>sticky_note_2</mat-icon>
              Notes : &nbsp;
              {{ row?.patientContactNotes }}
            </label>
          </div>
        </td>
      </ng-container>

      <!-- Member Insurance Details -->
      <ng-container matColumnDef="memberInsurance">
        <th mat-header-cell *matHeaderCellDef>Member Insurance</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex" *ngIf="row?.patientInsurancePayorId">
            <!-- Insurance Name -->
            <label class="labelBold">
              {{ row?.patientInsurancePayorDetails?.standardPayerName }}
              <span *ngIf="row?.patientInsurancePayorDetails?.claimPayerCode">
                ( {{ row?.patientInsurancePayorDetails?.claimPayerCode }} )
              </span>
            </label>

            <!-- Insurance Type -->
            <label *ngIf="row?.healthInsuranceType" style="font-style: italic">
              {{ row?.healthInsuranceType }}
            </label>

            <!-- Insurance Address -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.patientInsurancePayorDetails?.addressLine1"
            >
              <mat-icon>add_home_work</mat-icon>
              {{ row?.patientInsurancePayorDetails?.addressLine1 }}
              {{ row?.patientInsurancePayorDetails?.addressLine2 }}
            </label>
            <label
              class="justicyCenterLabel"
              *ngIf="
                row?.patientInsurancePayorDetails?.city ||
                row?.patientInsurancePayorDetails?.state ||
                row?.patientInsurancePayorDetails?.zip
              "
            >
              {{ row?.patientInsurancePayorDetails?.city }}
              {{ row?.patientInsurancePayorDetails?.state }}
              {{ row?.patientInsurancePayorDetails?.zip }}
            </label>
          </div>

          <!-- Eligibility Checks -->
          <button
            mat-raised-button
            type="button"
            color="primary"
            style="margin-top: 8px; border-radius: 8px"
            (click)="$event.stopPropagation(); openEligibilityCheck(row)"
          >
            Eligibility Checks
          </button>
        </td>
      </ng-container>

      <!-- Facility Details -->
      <ng-container matColumnDef="facility">
        <th mat-header-cell *matHeaderCellDef>Facility</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex">
            <!-- Facility Name -->
            <label class="labelBold">
              {{ row?.facilityDetails?.facilityName }}
            </label>

            <!-- Facility Phone -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.facilityDetails?.phoneNumber"
            >
              <mat-icon>phone</mat-icon>
              {{ row?.facilityDetails?.phoneNumber | phoneFormat }}
            </label>

            <!-- Facility Address -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.facilityDetails?.address"
            >
              <mat-icon>business</mat-icon> {{ row?.facilityDetails?.address }}
              {{ row?.facilityDetails?.addressLine2 }}
            </label>
            <label
              class="justicyCenterLabel"
              *ngIf="
                row?.facilityDetails?.city ||
                row?.facilityDetails?.state ||
                row?.facilityDetails?.zip
              "
            >
              {{ row?.facilityDetails?.city }} {{ row?.facilityDetails?.state }}
              {{ row?.facilityDetails?.zip }}
            </label>
          </div>
        </td>
      </ng-container>

      <!-- Referral Details -->
      <ng-container matColumnDef="referral">
        <th mat-header-cell *matHeaderCellDef>Referral Information</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex">
            <!-- Referal Date -->
            <div>
              <label class="labelBold"> Received on : &nbsp;</label>
              <label> {{ row?.dateReceived | date : 'MM/dd/y' }}</label>
            </div>

            <!-- Services Requested -->
            <div>
              <label class="labelBold"> Services Requested : &nbsp;</label>
              <label> {{ row?.servicesRequested }}</label>
            </div>

            <!-- On Stimulant -->
            <div *ngIf="row?.onStimulant">
              <label class="labelBold"> On Stimulant : &nbsp;</label>
              <label> {{ row?.onStimulant }}</label>
            </div>

            <!-- Referral Method -->
            <div *ngIf="row?.referralMethod">
              <label class="labelBold"> Referral Method : &nbsp;</label>
              <label> {{ row?.referralMethod }}</label>
            </div>

            <!-- Referral Method -->
            <div *ngIf="row?.referralNote">
              <label class="labelBold"> Referral Notes : &nbsp;</label>
              <label style="font-style: italic"> {{ row?.referralNote }}</label>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Referred By Details -->
      <ng-container matColumnDef="referredBy">
        <th mat-header-cell *matHeaderCellDef>Referred By</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex" *ngIf="row?.referringProviderId">
            <!-- RP Name -->
            <label class="labelBold">
              {{ row?.referringProviderDetails?.lastName }},
              {{ row?.referringProviderDetails?.firstName }}
              {{ row?.referringProviderDetails?.middleName }}
            </label>

            <!-- NPI -->
            <label
              *ngIf="row?.referringProviderDetails?.individualNPI"
              style="padding: 0; margin: 0"
            >
              ( NPI - {{ row?.referringProviderDetails?.individualNPI }} )
            </label>

            <!-- RP Phone -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.referringProviderDetails?.phoneNumber"
            >
              <mat-icon>phone</mat-icon>
              {{ row?.referringProviderDetails?.phoneNumber | phoneFormat }}
            </label>

            <!-- RP Email -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.referringProviderDetails?.emailAddress"
            >
              <mat-icon>email</mat-icon>
              {{ row?.referringProviderDetails?.emailAddress }}
            </label>

            <!-- RP Address -->
            <label
              class="justicyCenterLabel"
              *ngIf="row?.referringProviderDetails?.addressLine1"
            >
              <mat-icon>home</mat-icon>
              {{ row?.referringProviderDetails?.addressLine1 }}
              {{ row?.referringProviderDetails?.addressLine2 }}
            </label>
            <label
              class="justicyCenterLabel"
              *ngIf="
                row?.referringProviderDetails?.city ||
                row?.referringProviderDetails?.state ||
                row?.referringProviderDetails?.zip
              "
            >
              {{ row?.referringProviderDetails?.city }}
              {{ row?.referringProviderDetails?.state }}
              {{ row?.referringProviderDetails?.zip }}
            </label>
          </div>
        </td>
      </ng-container>

      <!-- Appointment Details -->
      <ng-container matColumnDef="appointment">
        <th mat-header-cell *matHeaderCellDef>Appointment Details</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnFlex">
            <!-- Appointment On -->
            <div *ngIf="row?.appointmentDate">
              <label class="labelBold"> Appointment : &nbsp;</label>
              <label>
                {{
                  row?.appointmentDate
                    | date : 'MM/dd/y hh:mm a' : currentTimezone
                }}
                {{ currentTimezone }}
              </label>
            </div>

            <!-- Appointment With -->
            <div *ngIf="row?.appointmentWithUserId">
              <label class="labelBold"> Appointment With : &nbsp;</label>
              <label>
                {{ row?.appointmentWith?.lastName }},
                {{ row?.appointmentWith?.firstName }}
                {{ row?.appointmentWith?.middleName }}
              </label>
            </div>

            <!-- Appointment Staff Email  -->
            <div *ngIf="row?.appointmentWith?.emailAddress">
              <label class="labelBold"> Staff Email : &nbsp;</label>
              <label>
                {{ row?.appointmentWith?.emailAddress }}
              </label>
            </div>

            <!-- Appointment Staff Phone  -->
            <div *ngIf="row?.appointmentWith?.phoneNumber">
              <label class="labelBold"> Staff Phone : &nbsp;</label>
              <label>
                {{ row?.appointmentWith?.phoneNumber | phoneFormat }}
              </label>
            </div>

            <!-- Appointment Note-->
            <div *ngIf="row?.appointmentNotes">
              <label class="labelBold"> Notes : &nbsp;</label>
              <label style="font-style: italic">
                {{ row?.appointmentNotes }}
              </label>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Contact Attempt Details -->
      <ng-container matColumnDef="attempts">
        <th mat-header-cell *matHeaderCellDef>Contact Attempts</th>
        <td mat-cell *matCellDef="let row" style="min-width: 110px">
          <!-- Linear Progress Bar -->
          <label class="labelBold justicyCenterLabel">
            {{ getAttempCount(row, 'number') }} of 3 attempts
            <button
              mat-icon-button
              type="button"
              (click)="
                expandedElement = expandedElement === row ? null : row;
                $event.stopPropagation()
              "
            >
              @if (expandedElement === row) {
              <mat-icon>keyboard_arrow_up</mat-icon>
              } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
              }
            </button>
          </label>

          <mat-progress-bar
            class="attemptProgressBar"
            mode="determinate"
            [value]="getAttempCount(row, 'percentage')"
          >
          </mat-progress-bar>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <span
            [class]="
              row.status === 'Initial' || row.status === 'No Attempt'
                ? 'newStatus'
                : row.status === '1st Attempt' ||
                  row.status === '2nd Attempt' ||
                  row.status === '3rd Attempt' ||
                  row.status === 'Insurance Pending'
                ? 'warningStatus'
                : row.status === 'Member Imported'
                ? 'successStatus'
                : 'errorStatus'
            "
          >
            {{ row.status }}
          </span>
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Actions
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right">
          <button
            *ngIf="row?.status != 'Member Imported'"
            type="button"
            mat-raised-button
            [matMenuTriggerFor]="menu"
            class="btn btn-just-icon btn-white btn-fab btn-round"
            (click)="$event.stopPropagation()"
          >
            <i class="material-icons text_align-center">more_vert</i>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item type="button" (click)="editReferral(row)">
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>

            <button mat-menu-item type="button" (click)="importMember(row)">
              <mat-icon style="color: green"> input </mat-icon>
              <span>Import Member</span>
            </button>

            <button mat-menu-item type="button" (click)="deleteReferral(row)">
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Service Line COB Expanded -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="row">
              <!-- Attempt Details -->
              <div class="col-md-12" style="margin-bottom: 25px">
                <label
                  class="labelHeader justicyCenterLabel"
                  style="justify-content: center"
                >
                  <mat-icon>history_edu</mat-icon> Attempt Details
                </label>
              </div>

              <!-- Add Attempt Button -->
              <div
                class="col-md-12"
                *ngIf="
                  getAttempCount(expandedElement, 'number') < 3 &&
                  expandedElement?.status != 'Member Imported'
                "
                style="text-align: right; margin-bottom: 12px"
              >
                <button
                  mat-raised-button
                  color="warn"
                  type="button"
                  (click)="addAttempt(expandedElement)"
                >
                  + Attempt
                </button>
              </div>

              <!-- Progress -->
              <div class="col-md-12" *ngIf="attemptProcessing">
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="attemptProcessing"
                ></mat-progress-bar>
              </div>

              <!-- No Attempt Indicator -->
              <div
                class="col-md-12"
                *ngIf="getAttempCount(expandedElement, 'number') == 0"
                style="text-align: right"
              >
                <mat-error>No attempt has been recorded</mat-error>
              </div>

              <!-- 1st Attempt -->
              <div
                class="col-md-4"
                style="text-align: center; position: relative"
                *ngIf="expandedElement?.firstAttempt"
              >
                <!-- Actions -->
                <div class="editAttemptAction">
                  <button
                    *ngIf="expandedElement?.status != 'Member Imported'"
                    mat-icon-button
                    type="button"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" style="max-height: 500px">
                    <button
                      mat-menu-item
                      type="button"
                      (click)="
                        editAttempt(
                          'First',
                          expandedElement,
                          expandedElement?.firstAttempt
                        )
                      "
                    >
                      <mat-icon style="color: #f39121"> edit </mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      type="button"
                      (click)="removeAttempt('First', expandedElement)"
                    >
                      <mat-icon style="color: red"> delete_forever </mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>

                <app-referral-attempt-details-view
                  attemptNumber="1st Attempt Details"
                  [currentTimezone]="currentTimezone"
                  [attemptDetails]="expandedElement?.firstAttempt"
                >
                </app-referral-attempt-details-view>
              </div>

              <!-- 2nd Attempt -->
              <div
                class="col-md-4"
                style="text-align: center; position: relative"
                *ngIf="expandedElement?.secondAttempt"
              >
                <!-- Actions -->
                <div class="editAttemptAction">
                  <button
                    *ngIf="expandedElement?.status != 'Member Imported'"
                    mat-icon-button
                    type="button"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" style="max-height: 500px">
                    <button
                      mat-menu-item
                      type="button"
                      (click)="
                        editAttempt(
                          'Second',
                          expandedElement,
                          expandedElement?.secondAttempt
                        )
                      "
                    >
                      <mat-icon style="color: #f39121"> edit </mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      type="button"
                      (click)="removeAttempt('Second', expandedElement)"
                    >
                      <mat-icon style="color: red"> delete_forever </mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>

                <app-referral-attempt-details-view
                  attemptNumber="2nd Attempt Details"
                  [currentTimezone]="currentTimezone"
                  [attemptDetails]="expandedElement?.secondAttempt"
                >
                </app-referral-attempt-details-view>
              </div>

              <!-- 3rd Attempt -->
              <div
                class="col-md-4"
                style="text-align: center; position: relative"
                *ngIf="expandedElement?.thirdAttempt"
              >
                <!-- Actions -->
                <div class="editAttemptAction">
                  <button
                    *ngIf="expandedElement?.status != 'Member Imported'"
                    mat-icon-button
                    type="button"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" style="max-height: 500px">
                    <button
                      mat-menu-item
                      type="button"
                      (click)="
                        editAttempt(
                          'Third',
                          expandedElement,
                          expandedElement?.thirdAttempt
                        )
                      "
                    >
                      <mat-icon style="color: #f39121"> edit </mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      type="button"
                      (click)="removeAttempt('Third', expandedElement)"
                    >
                      <mat-icon style="color: red"> delete_forever </mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>

                <app-referral-attempt-details-view
                  attemptNumber="3rd Attempt Details"
                  [currentTimezone]="currentTimezone"
                  [attemptDetails]="expandedElement?.thirdAttempt"
                >
                </app-referral-attempt-details-view>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
  </div>
  <mat-paginator
    [length]="data.total"
    [pageSize]="data.per_page"
    *ngIf="data.total > data.per_page"
    (page)="getNext($event)"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
