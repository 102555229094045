import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import { IIntakeClinicalGuidance } from 'src/app/_models/session/intake/intakeclinicalguidancereasoning.model';
import { ToastMessageService } from 'src/app/_services';
import { IntakeClinicalGuidanceApiService } from 'src/app/_services/session/intake/intakeclinicalguidancereasoning.service';

@Component({
  selector: 'app-intake-clinical-guidance-reasoning',
  templateUrl: './intake-clinical-guidance-reasoning.component.html',
  styleUrls: ['./intake-clinical-guidance-reasoning.component.css'],
})
export class IntakeClinicalGuidanceReasoningComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;
  @Output() updatedTherapySession = new EventEmitter<any>();

  intakeclinicalguidance: IIntakeClinicalGuidance;

  constructor(
    public intakeclinicalguidanceApiService: IntakeClinicalGuidanceApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) { }

  public action: string;
  public intakeclinicalguidanceForm: FormGroup;
  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.intakeclinicalguidance = this.therapySession.intakeClinicalGuidance;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();

    this.loadIntakeClinicalGuidance();
  }

  loadIntakeClinicalGuidance() {
    if (this.intakeclinicalguidance) {
      this.intakeclinicalguidanceForm.controls['reasons'].setValue(
        this.intakeclinicalguidance.reasons
      );
      this.intakeclinicalguidanceForm.controls['expectations'].setValue(
        this.intakeclinicalguidance.expectations
      );
      this.intakeclinicalguidanceForm.controls['preferences'].setValue(
        this.intakeclinicalguidance.preferences
      );
      this.intakeclinicalguidanceForm.controls['additionalComments'].setValue(
        this.intakeclinicalguidance.additionalComments
      );
    }

    // Initiate Auto Save
    this.initiateAutoSave();
  }

  onIntakeClinicalGuidanceFormSubmit() {
    this.intakeclinicalguidance = Object.assign(
      {},
      this.intakeclinicalguidance
    );
    this.intakeclinicalguidance = Object.assign(
      this.intakeclinicalguidance,
      this.intakeclinicalguidanceForm.value
    );

    this.processing = true;

    this.intakeclinicalguidanceApiService
      .updateIntakeClinicalGuidance(this.intakeclinicalguidance)
      .subscribe({
        next: (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated Reasons for Clinical Guidance'
          // );
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.intakeclinicalguidanceForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      reasons: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      expectations: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      preferences: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      additionalComments: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
    });
  }

  initiateAutoSave() {
    this.intakeclinicalguidanceForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onIntakeClinicalGuidanceFormSubmit();
      });
  }
}
