import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Case, Patient } from 'src/app/_models';
import { ICaseTreatmentAdmissionDiagnosis } from 'src/app/_models/case/treatment/admission/casetreatmentadmissiondiagnosis.model';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { CaseTreatmentAdmissionDiagnosisApiService } from 'src/app/_services/case/treatment/admission/casetreatmentadmissiondiagnosis.service';
import { DashboardsService } from 'src/app/_services/dashboards/dashboards.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { ExistingDiagnosisComponent } from 'src/app/pages/patient-details/patient-components/sessions/session-codes/session-diagnosis-codes/existing-diagnosis/existing-diagnosis.component';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-case-admission-diagnosis',
  templateUrl: './case-admission-diagnosis.component.html',
  styleUrl: './case-admission-diagnosis.component.css',
})
export class CaseAdmissionDiagnosisComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;

  constructor(
    public casetreatmentadmissiondiagnosisApiService: CaseTreatmentAdmissionDiagnosisApiService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService,
    private errorHandler: ErrorHandler
  ) {}

  public action: string;
  public casetreatmentadmissiondiagnosisForm: FormGroup;
  casetreatmentadmissiondiagnosis: ICaseTreatmentAdmissionDiagnosis;
  processing = false;

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  public ngOnInit() {
    this.buildForm();
    this.loadCaseTreatmentAdmissionDiagnosis();
  }

  loadCaseTreatmentAdmissionDiagnosis() {
    this.casetreatmentadmissiondiagnosisApiService
      .getCaseTreatmentAdmissionDiagnosisDetails(
        this.case.id,
        this.selectedPlan?.id
      )
      .subscribe((casetreatmentadmissiondiagnosis) => {
        if (
          casetreatmentadmissiondiagnosis &&
          casetreatmentadmissiondiagnosis.data
        ) {
          Object.keys(casetreatmentadmissiondiagnosis.data).forEach((key) => {
            if (this.casetreatmentadmissiondiagnosisForm.controls[key]) {
              if (key === 'effectiveDate' || key === 'expirationDate') {
                this.casetreatmentadmissiondiagnosisForm.controls[key].setValue(
                  mmddyyyyToDate(casetreatmentadmissiondiagnosis.data[key])
                );
              } else {
                this.casetreatmentadmissiondiagnosisForm.controls[key].setValue(
                  casetreatmentadmissiondiagnosis.data[key]
                );
              }
            }
          });
        }
        this.loadActiveProviders(
          casetreatmentadmissiondiagnosis
            ? casetreatmentadmissiondiagnosis.data
            : null
        );
      });
  }

  loadActiveProviders(diagnosisData: any) {
    this.providersListLoading = true;

    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  onCaseTreatmentAdmissionDiagnosisFormSubmit() {
    if (this.casetreatmentadmissiondiagnosisForm.invalid) {
      this.casetreatmentadmissiondiagnosisForm.markAllAsTouched();
      return;
    }

    this.casetreatmentadmissiondiagnosis = Object.assign(
      {},
      this.casetreatmentadmissiondiagnosis
    );
    this.casetreatmentadmissiondiagnosis = Object.assign(
      this.casetreatmentadmissiondiagnosis,
      this.casetreatmentadmissiondiagnosisForm.value
    );

    ['effectiveDate', 'expirationDate'].forEach((dateField) => {
      if (this.casetreatmentadmissiondiagnosis[dateField]) {
        this.casetreatmentadmissiondiagnosis[dateField] = formatDate(
          new Date(this.casetreatmentadmissiondiagnosis[dateField])
        );
      }
    });

    this.processing = true;
    this.casetreatmentadmissiondiagnosisApiService
      .updateCaseTreatmentAdmissionDiagnosis(
        this.casetreatmentadmissiondiagnosis
      )
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.casetreatmentadmissiondiagnosisForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planId: new FormControl(this.selectedPlan?.id),
      primaryDiagnosis: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      primaryDiagnosisDescription: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      secondaryDiagnosis: new FormControl(''),
      secondaryDiagnosisDescription: new FormControl(''),
      tertiaryDiagnosis: new FormControl(''),
      tertiaryDiagnosisDescription: new FormControl(''),
      effectiveDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      effectiveTime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      expirationDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      expirationTime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      diagnosingClinicianId: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      diagnosingClinicianName: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      gafScore: new FormControl('', Validators.compose([Validators.required])),
      diagnosisComments: new FormControl(''),
    });

    if (this.selectedPlan && this.selectedPlan.status != 'Active') {
      this.casetreatmentadmissiondiagnosisForm.disable();
    }
  }

  selectDiagnosis(fieldName: string) {
    const dialogRef = this.dialog.open(ExistingDiagnosisComponent, {
      data: {
        patient: this.patient,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        if (response.diagnosis) {
          this.casetreatmentadmissiondiagnosisForm.controls[fieldName].setValue(
            response.diagnosis.icdCode
          );
          this.casetreatmentadmissiondiagnosisForm.controls[
            fieldName + 'Description'
          ].setValue(response.diagnosis.shortDesc);
        }
      }
    });
  }

  selectedClinician(event: MatSelectChange): void {
    const diagnosingClinicianId = event.value;

    let selectedProvider = this.providersList
      .filter((provider) => provider.id === diagnosingClinicianId)
      .at(0);
    const diagnosingClinicianName =
      selectedProvider.firstName + ' ' + selectedProvider.lastName;

    this.casetreatmentadmissiondiagnosisForm.controls[
      'diagnosingClinicianName'
    ].setValue(diagnosingClinicianName);
  }

  removeDiagnosis(level) {
    if (level === 'secondary') {
      this.casetreatmentadmissiondiagnosisForm.controls[
        'secondaryDiagnosis'
      ].setValue('');
      this.casetreatmentadmissiondiagnosisForm.controls[
        'secondaryDiagnosisDescription'
      ].setValue('');
    } else {
      this.casetreatmentadmissiondiagnosisForm.controls[
        'tertiaryDiagnosis'
      ].setValue('');
      this.casetreatmentadmissiondiagnosisForm.controls[
        'tertiaryDiagnosisDescription'
      ].setValue('');
    }
  }

  // Search Filter for supervising provider
  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }
}
