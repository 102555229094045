import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IComplicatedGrief } from 'src/app/_models/assessments/stress/complicatedgrief.model';
import {
  ComplicatedGriefApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-complicated-grief',
  templateUrl: './complicated-grief.component.html',
  styleUrls: ['./complicated-grief.component.css'],
})
export class ComplicatedGriefComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public complicatedGriefApiService: ComplicatedGriefApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public complicatedGriefForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  complicatedGriefAssessment: IComplicatedGrief;
  complicatedGriefAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.complicatedGriefForm, this.errors);

    this.loadComplicatedGriefAssessment();
  }

  loadComplicatedGriefAssessment() {
    this.complicatedGriefForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.complicatedGriefApiService
        .getComplicatedGriefDetails(this.assessmentId)
        .subscribe((complicatedGriefAssessment) => {
          if (complicatedGriefAssessment.data) {
            this.complicatedGriefForm.controls['thinkAboutThisPerson'].setValue(
              complicatedGriefAssessment.data.thinkAboutThisPerson.answer
            );
            this.complicatedGriefForm.controls['memoriesOfThePerson'].setValue(
              complicatedGriefAssessment.data.memoriesOfThePerson.answer
            );
            this.complicatedGriefForm.controls['cannotAcceptTheDeath'].setValue(
              complicatedGriefAssessment.data.cannotAcceptTheDeath.answer
            );
            this.complicatedGriefForm.controls['longingForThePerson'].setValue(
              complicatedGriefAssessment.data.longingForThePerson.answer
            );
            this.complicatedGriefForm.controls[
              'thingsAssociatedWithThePersonWhoDied'
            ].setValue(
              complicatedGriefAssessment.data
                .thingsAssociatedWithThePersonWhoDied.answer
            );
            this.complicatedGriefForm.controls[
              'feelingAngryAboutTheirDeath'
            ].setValue(
              complicatedGriefAssessment.data.feelingAngryAboutTheirDeath.answer
            );
            this.complicatedGriefForm.controls['feelDisbelief'].setValue(
              complicatedGriefAssessment.data.feelDisbelief.answer
            );
            this.complicatedGriefForm.controls['feelStunnedOrDazed'].setValue(
              complicatedGriefAssessment.data.feelStunnedOrDazed.answer
            );
            this.complicatedGriefForm.controls[
              'hardForMeToTrustPeople'
            ].setValue(
              complicatedGriefAssessment.data.hardForMeToTrustPeople.answer
            );
            this.complicatedGriefForm.controls[
              'lostTheAbilityToCareAboutOtherPeople'
            ].setValue(
              complicatedGriefAssessment.data
                .lostTheAbilityToCareAboutOtherPeople.answer
            );
            this.complicatedGriefForm.controls[
              'feelLonelyAGreatDealOfTheTime'
            ].setValue(
              complicatedGriefAssessment.data.feelLonelyAGreatDealOfTheTime
                .answer
            );
            this.complicatedGriefForm.controls['havePain'].setValue(
              complicatedGriefAssessment.data.havePain.answer
            );
            this.complicatedGriefForm.controls[
              'avoidRemindersOfThePersonWhoDied'
            ].setValue(
              complicatedGriefAssessment.data.avoidRemindersOfThePersonWhoDied
                .answer
            );
            this.complicatedGriefForm.controls[
              'lifeIsEmptyWithoutThePersonWhoDied'
            ].setValue(
              complicatedGriefAssessment.data.lifeIsEmptyWithoutThePersonWhoDied
                .answer
            );
            this.complicatedGriefForm.controls[
              'personWhoDiedSpeakToMe'
            ].setValue(
              complicatedGriefAssessment.data.personWhoDiedSpeakToMe.answer
            );
            this.complicatedGriefForm.controls[
              'thePersonWhoDiedStandBeforeMe'
            ].setValue(
              complicatedGriefAssessment.data.thePersonWhoDiedStandBeforeMe
                .answer
            );
            this.complicatedGriefForm.controls[
              'unfairThatIShouldLive'
            ].setValue(
              complicatedGriefAssessment.data.unfairThatIShouldLive.answer
            );
            this.complicatedGriefForm.controls[
              'bitterOverThisPersonsDeath'
            ].setValue(
              complicatedGriefAssessment.data.bitterOverThisPersonsDeath.answer
            );
            this.complicatedGriefForm.controls[
              'enviousOfOtherWhoHaveNotLostSomeoneClose'
            ].setValue(
              complicatedGriefAssessment.data
                .enviousOfOtherWhoHaveNotLostSomeoneClose.answer
            );

            this.riskScore = complicatedGriefAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.complicatedGriefForm.invalid) {
      this.complicatedGriefForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.complicatedGriefAssessment = Object.assign(
      {},
      this.complicatedGriefAssessment
    );
    this.complicatedGriefAssessment.patientId =
      this.complicatedGriefForm.controls['patientId'].value;
    this.complicatedGriefAssessment.sessionId =
      this.complicatedGriefForm.controls['sessionId'].value;
    this.complicatedGriefAssessment.assessmentRequestId =
      this.complicatedGriefForm.controls['assessmentRequestId'].value;
    this.complicatedGriefAssessment.thinkAboutThisPerson = {
      answer: this.complicatedGriefForm.controls['thinkAboutThisPerson'].value,
    };
    this.complicatedGriefAssessment.memoriesOfThePerson = {
      answer: this.complicatedGriefForm.controls['memoriesOfThePerson'].value,
    };
    this.complicatedGriefAssessment.cannotAcceptTheDeath = {
      answer: this.complicatedGriefForm.controls['cannotAcceptTheDeath'].value,
    };
    this.complicatedGriefAssessment.longingForThePerson = {
      answer: this.complicatedGriefForm.controls['longingForThePerson'].value,
    };
    this.complicatedGriefAssessment.thingsAssociatedWithThePersonWhoDied = {
      answer:
        this.complicatedGriefForm.controls[
          'thingsAssociatedWithThePersonWhoDied'
        ].value,
    };
    this.complicatedGriefAssessment.feelingAngryAboutTheirDeath = {
      answer:
        this.complicatedGriefForm.controls['feelingAngryAboutTheirDeath'].value,
    };
    this.complicatedGriefAssessment.feelDisbelief = {
      answer: this.complicatedGriefForm.controls['feelDisbelief'].value,
    };
    this.complicatedGriefAssessment.feelStunnedOrDazed = {
      answer: this.complicatedGriefForm.controls['feelStunnedOrDazed'].value,
    };
    this.complicatedGriefAssessment.hardForMeToTrustPeople = {
      answer:
        this.complicatedGriefForm.controls['hardForMeToTrustPeople'].value,
    };
    this.complicatedGriefAssessment.lostTheAbilityToCareAboutOtherPeople = {
      answer:
        this.complicatedGriefForm.controls[
          'lostTheAbilityToCareAboutOtherPeople'
        ].value,
    };
    this.complicatedGriefAssessment.feelLonelyAGreatDealOfTheTime = {
      answer:
        this.complicatedGriefForm.controls['feelLonelyAGreatDealOfTheTime']
          .value,
    };
    this.complicatedGriefAssessment.havePain = {
      answer: this.complicatedGriefForm.controls['havePain'].value,
    };
    this.complicatedGriefAssessment.avoidRemindersOfThePersonWhoDied = {
      answer:
        this.complicatedGriefForm.controls['avoidRemindersOfThePersonWhoDied']
          .value,
    };
    this.complicatedGriefAssessment.lifeIsEmptyWithoutThePersonWhoDied = {
      answer:
        this.complicatedGriefForm.controls['lifeIsEmptyWithoutThePersonWhoDied']
          .value,
    };
    this.complicatedGriefAssessment.personWhoDiedSpeakToMe = {
      answer:
        this.complicatedGriefForm.controls['personWhoDiedSpeakToMe'].value,
    };
    this.complicatedGriefAssessment.thePersonWhoDiedStandBeforeMe = {
      answer:
        this.complicatedGriefForm.controls['thePersonWhoDiedStandBeforeMe']
          .value,
    };
    this.complicatedGriefAssessment.unfairThatIShouldLive = {
      answer: this.complicatedGriefForm.controls['unfairThatIShouldLive'].value,
    };
    this.complicatedGriefAssessment.bitterOverThisPersonsDeath = {
      answer:
        this.complicatedGriefForm.controls['bitterOverThisPersonsDeath'].value,
    };
    this.complicatedGriefAssessment.enviousOfOtherWhoHaveNotLostSomeoneClose = {
      answer:
        this.complicatedGriefForm.controls[
          'enviousOfOtherWhoHaveNotLostSomeoneClose'
        ].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.complicatedGriefAssessment.accessCode = this.accessCode;

      this.complicatedGriefApiService
        .addGuestComplicatedGrief(this.complicatedGriefAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Complicated Grief Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Complicated Grief Assessment'
            );
          }
        );
    } else {
      this.complicatedGriefApiService
        .addComplicatedGrief(this.complicatedGriefAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Complicated Grief Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Complicated Grief Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    thinkAboutThisPerson: [
      {
        type: 'required',
        message:
          "I think about this person so much that it's hard for me to do the things I normally do is required",
      },
    ],
    memoriesOfThePerson: [
      {
        type: 'required',
        message: 'Memories of the person who died upset me is required',
      },
    ],
    cannotAcceptTheDeath: [
      {
        type: 'required',
        message:
          'I feel I cannot accept the death of the person who died is required',
      },
    ],
    longingForThePerson: [
      {
        type: 'required',
        message: 'I feel myself longing for the person who died is required',
      },
    ],
    thingsAssociatedWithThePersonWhoDied: [
      {
        type: 'required',
        message:
          'I feel drawn to places and things associated with the person who died is required',
      },
    ],
    feelingAngryAboutTheirDeath: [
      {
        type: 'required',
        message: "I can't help feeling angry about his/her death is required",
      },
    ],
    feelDisbelief: [
      {
        type: 'required',
        message: 'I feel disbelief over what happened is required',
      },
    ],
    feelStunnedOrDazed: [
      {
        type: 'required',
        message: 'I feel stunned or dazed over what happened is required',
      },
    ],
    hardForMeToTrustPeople: [
      {
        type: 'required',
        message:
          'Ever since he/she died, it is hard for me to trust people... is required',
      },
    ],
    lostTheAbilityToCareAboutOtherPeople: [
      {
        type: 'required',
        message:
          'Ever since he/she died, I feel as if I have lost the ability to care about other people or I feel distant from people I care about is required',
      },
    ],
    feelLonelyAGreatDealOfTheTime: [
      {
        type: 'required',
        message:
          'I feel lonely a great deal of the time ever since he/she died is required',
      },
    ],
    havePain: [
      {
        type: 'required',
        message:
          'I have pain in the same area of my body or have some of the same symptoms as the person who died is required',
      },
    ],
    avoidRemindersOfThePersonWhoDied: [
      {
        type: 'required',
        message:
          'I got out of my way to avoid reminders of the person who died is required',
      },
    ],
    lifeIsEmptyWithoutThePersonWhoDied: [
      {
        type: 'required',
        message:
          'I feel that life is empty without the person who died is required',
      },
    ],
    personWhoDiedSpeakToMe: [
      {
        type: 'required',
        message:
          'I hear the voice of the person who died speak to me is required',
      },
    ],
    thePersonWhoDiedStandBeforeMe: [
      {
        type: 'required',
        message: 'I see the person who died stand before me is required',
      },
    ],
    unfairThatIShouldLive: [
      {
        type: 'required',
        message:
          'I feel that is is unfair that I should live when this person died is required',
      },
    ],
    bitterOverThisPersonsDeath: [
      {
        type: 'required',
        message: "I feel bitter over this person's death is required",
      },
    ],
    enviousOfOtherWhoHaveNotLostSomeoneClose: [
      {
        type: 'required',
        message:
          'I feel envious of other who have not lost someone close is required',
      },
    ],
  };

  private buildForm() {
    this.complicatedGriefForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      thinkAboutThisPerson: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      memoriesOfThePerson: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      cannotAcceptTheDeath: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      longingForThePerson: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thingsAssociatedWithThePersonWhoDied: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelingAngryAboutTheirDeath: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelDisbelief: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelStunnedOrDazed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hardForMeToTrustPeople: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lostTheAbilityToCareAboutOtherPeople: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelLonelyAGreatDealOfTheTime: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      havePain: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      avoidRemindersOfThePersonWhoDied: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifeIsEmptyWithoutThePersonWhoDied: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      personWhoDiedSpeakToMe: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thePersonWhoDiedStandBeforeMe: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      unfairThatIShouldLive: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      bitterOverThisPersonsDeath: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      enviousOfOtherWhoHaveNotLostSomeoneClose: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.complicatedGriefForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.complicatedGriefForm) {
      return;
    }
    const form = this.complicatedGriefForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
