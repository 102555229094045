<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Program</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Program</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="treatmentprogramForm"
    novalidate
    (ngSubmit)="onTreatmentProgramFormSubmit()"
  >
    <div class="row">
      <!-- Program Name  -->
      <div class="col-md-12">
        <label class="labelText">
          Program Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Program Name"
            formControlName="programName"
            name="programName"
            required
          />
          <mat-error
            *ngIf="treatmentprogramForm.controls['programName'].invalid"
          >
            Program name is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Facility  -->
      <div class="col-md-12">
        <label class="labelText">
          Facility
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="facilityId"
            placeholder="Facility"
            (selectionChange)="facilitySelected($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Facility Name"
                noEntriesFoundLabel="No matching facility found"
                [(ngModel)]="filterFacilityName"
                (ngModelChange)="filterFacility()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let facility of filteredFacilities"
              [value]="facility.id"
            >
              {{ facility.facilityName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="treatmentprogramForm.controls['facilityId'].invalid"
          >
            Please select a facility
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Facility  -->
      <div class="col-md-12">
        <label class="labelText">
          Program Staff
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="staffId"
            placeholder="Program Staff"
            (selectionChange)="providerSelected($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Provider Name"
                noEntriesFoundLabel="No matching provider found"
                [(ngModel)]="providerName"
                (ngModelChange)="filterProvider()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let provider of filteredProvidersList"
              [value]="provider.id"
            >
              {{ provider.lastName }}, {{ provider.firstName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="treatmentprogramForm.controls['staffId'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Program Start Date   -->
      <div class="col-md-6">
        <label class="labelText">
          Program Start Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="treatmentprogramForm.controls['startDate'].invalid">
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Program End Date   -->
      <div class="col-md-6">
        <label class="labelText">Program End Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="endDate"
            matInput
            [matDatepicker]="endDatePicker"
            autoSlashDate
            [min]="treatmentprogramForm.controls['startDate'].value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error *ngIf="treatmentprogramForm.controls['endDate'].invalid">
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Days On Wait List  -->
      <div class="col-md-6">
        <label class="labelText">
          Days On Wait List <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Days On Wait List"
            formControlName="daysOnWaitList"
            name="daysOnWaitList"
            required
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="treatmentprogramForm.controls['daysOnWaitList'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Termination Reason -->
      <div class="col-md-6" *ngIf="action === 'EDIT'">
        <label class="labelText">Termination Reason</label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="terminationReason"
            placeholder="Termination Reason"
          >
            <mat-option value="Admin termination">Admin termination</mat-option>
            <mat-option value="Deceased">Deceased</mat-option>
            <mat-option value="Disciplinary violation">
              Disciplinary violation
            </mat-option>
            <mat-option value="Change Level of Care">
              Change Level of Care
            </mat-option>
            <mat-option value="Incarcerated">Incarcerated</mat-option>
            <mat-option value="Left agaomst Tx advice"
              >Left agaomst Tx advice</mat-option
            >
            <mat-option value="Left w/o notice">Left w/o notice</mat-option>
            <mat-option value="Left w/ notice">Left w/ notice</mat-option>
            <mat-option value="Referred">Referred</mat-option>
            <mat-option value="Tx completion">Tx completion</mat-option>
            <mat-option value="Transferred">Transferred</mat-option>
            <mat-option value="Wrong Level of Care"
              >Wrong Level of Care</mat-option
            >
            <mat-option value="Waitlist program available"
              >Waitlist program available</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <!--Notes -->
      <div class="col-md-12">
        <label class="labelText">Notes</label>
        <mat-form-field appearance="outline">
          <textarea formControlName="notes" matInput rows="5"></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentprogramForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
