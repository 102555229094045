import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Case, IFacility, Patient } from 'src/app/_models';
import { ICaseTreatmentIntake } from 'src/app/_models/case/treatment/admission/casetreatmentintake.model';
import { CaseTreatmentIntakeApiService } from 'src/app/_services/case/treatment/admission/casetreatmentintake.service';
import { CoreService } from 'src/app/_services/core.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { getCountiesByState } from 'src/app/shared/utilities/states-counties/counties';
import {
  buildValidationMsgs,
  formatDate,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-case-treatment-intake',
  templateUrl: './case-treatment-intake.component.html',
  styleUrl: './case-treatment-intake.component.css',
})
export class CaseTreatmentIntakeComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;

  public action: string;
  public casetreatmentintakeForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  casetreatmentintake: ICaseTreatmentIntake;
  processing: boolean = false;
  facilities: Array<IFacility> = [];
  public counties: string[] = [];

  constructor(
    public casetreatmentintakeApiService: CaseTreatmentIntakeApiService,
    private formBuilder: FormBuilder,
    private facilitiesSandbox: FacilitiesSandbox,
    private coreService: CoreService,
    private toastMessageService: ToastMessageService
  ) {}

  public referralSources: string[] = [
    'Prison/Jail',
    'Homeless Service Agency',
    'KEDP',
    'MAT Program',
    'Self',
    'Family or friend',
    'Residential A&D Treatment Facility',
    'Non-residential A&D Facility',
    'Other A&D Facility',
    'OP Mental Health Service or Clinic',
    'Other psychiatric facility',
    'Private Psychiatrist',
    'Private mental health professional',
    'State regional mental health institute',
    'General hospital inpatient psych unit',
    'General hospital other unit',
    'VA hospital',
    'Nursing home',
    'Residential mental retardation or developmental disability facility',
    'Other mental retardation facility',
    'School system or education agency',
    'EPA Employer',
    'Public Health/welfare agency',
    'Div of vocational rehabilitation',
    'Shelter for homeless/abused',
    'Police',
    'Diversionary Program (E.G., TASC)',
    'State/Federal Court',
    'Other Court',
    'DUI/DWI',
    'Probation/Parole',
    'Other Recognized Legal Entity',
    'Unknown',
    'Telehealth',
    'VA Center',
    'VA Outpatient',
  ];

  public problemAreas: string[] = [
    'Alcohol',
    'Drugs',
    'Both Alcohol & Drugs',
    'Co-dependence',
    'No diagnosis',
    'Alcohol/Drug and Gambling',
    'Gambling',
    'Co-occurring',
    'Mental Health',
  ];

  public initialContactTypes: string[] = [
    'By Appointment',
    'Community service patrol',
    'Court',
    'Other',
    'Phone',
    'Walk-in',
  ];

  public ngOnInit() {
    this.loadFacilities();
    this.buildForm();
    this.counties = getCountiesByState('TN');
    this.loadCaseTreatmentIntake();
  }

  loadCaseTreatmentIntake() {
    this.casetreatmentintakeApiService
      .getCaseTreatmentIntakeDetails(this.case.id)
      .subscribe((casetreatmentintake) => {
        if (casetreatmentintake?.data) {
          Object.keys(this.casetreatmentintakeForm.controls).forEach(
            (field) => {
              if (casetreatmentintake.data[field]) {
                if (field === 'intakeDate' || field === 'dueDate') {
                  this.casetreatmentintakeForm.controls[field].setValue(
                    mmddyyyyToDate(casetreatmentintake.data[field])
                  );
                } else {
                  this.casetreatmentintakeForm.controls[field].setValue(
                    casetreatmentintake.data[field]
                  );
                }
              } else {
                if (field === 'intakeFacility') {
                  const selectedFacility = this.facilities.find(
                    (facility) =>
                      facility.id ===
                      casetreatmentintake.data['intakeFacilityId']
                  );
                  this.casetreatmentintakeForm.controls[field].setValue(
                    selectedFacility || null
                  );
                }
              }
            }
          );
        }
      });
  }

  onCaseTreatmentIntakeFormSubmit() {
    if (this.casetreatmentintakeForm.invalid) {
      this.casetreatmentintakeForm.markAllAsTouched();
      return;
    }
    this.casetreatmentintake = { ...this.casetreatmentintakeForm.value };
    ['intakeDate', 'dueDate'].forEach((dateField) => {
      if (this.casetreatmentintake[dateField]) {
        this.casetreatmentintake[dateField] = formatDate(
          new Date(this.casetreatmentintake[dateField])
        );
      }
    });

    this.processing = true;
    this.casetreatmentintakeApiService
      .updateCaseTreatmentIntake(this.casetreatmentintake)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.casetreatmentintakeForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      intakeFacility: new FormControl(''),
      intakeFacilityId: new FormControl('', Validators.required),
      intakeFacilityName: new FormControl('', Validators.required),
      intakeStaffId: new FormControl(
        this.coreService.getLoggedInCareProviderId(),
        Validators.required
      ),
      intakeStaffName: new FormControl(
        this.coreService.getUserDetails().firstName +
          ' ' +
          this.coreService.getUserDetails().lastName,
        Validators.required
      ),
      intakeDate: new FormControl(new Date(), Validators.required),
      initialContact: new FormControl('', Validators.required),
      dueDate: new FormControl(''),
      pregnant: new FormControl(
        '',
        this.patient.gender === 'FEMALE' || this.patient.gender === 'F'
          ? Validators.required
          : null
      ),
      countyOfResidence: new FormControl('', Validators.required),
      sourceOfReferral: new FormControl('', Validators.required),
      injectionDrugUser: new FormControl('', Validators.required),
      numberOfDaysSinceRelease: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      criminalJusticeClient: new FormControl('', Validators.required),
      problemArea: new FormControl('', Validators.required),
      presentingProblem: new FormControl('', Validators.required),
    });

    this.casetreatmentintakeForm
      .get('pregnant')
      .valueChanges.subscribe((value) => {
        const dueDateControl = this.casetreatmentintakeForm.get('dueDate');
        if (value === 'Yes') {
          dueDateControl.setValidators(Validators.required);
          dueDateControl.enable();
        } else {
          dueDateControl.clearValidators();
          dueDateControl.disable();
        }
        dueDateControl.updateValueAndValidity();
      });

    this.casetreatmentintakeForm
      .get('criminalJusticeClient')
      .valueChanges.subscribe((value) => {
        const daysSinceReleaseControl = this.casetreatmentintakeForm.get(
          'numberOfDaysSinceRelease'
        );
        if (value === 'Yes') {
          daysSinceReleaseControl.setValidators(Validators.required);
          daysSinceReleaseControl.enable();
        } else {
          daysSinceReleaseControl.clearValidators();
          daysSinceReleaseControl.disable();
        }
        daysSinceReleaseControl.updateValueAndValidity();
      });
  }

  public loadFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
      }
    });
  }

  facilitySelected(event: MatSelectChange): void {
    const selectedFacility = event.value;
    const facilityId = selectedFacility.id;
    const facilityName = selectedFacility.facilityName;

    this.casetreatmentintakeForm.controls['intakeFacilityId'].setValue(
      facilityId
    );
    this.casetreatmentintakeForm.controls['intakeFacilityName'].setValue(
      facilityName
    );
  }
}
