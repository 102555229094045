<nav
  #navbar
  class="navbar navbar-expand-lg fixed-top"
  data-bs-color="primary-white"
>
  <div class="container-fluid">
    <!-- Logo Section -->
    <div class="navbar-wrapper">
      <div
        class="logo-img"
        *ngIf="orgConfigLoaded"
        routerLink="/"
        style="cursor: pointer"
      >
        <img
          src="{{ apiUrl }}/public/orgimage/{{ orgConfig.organizationId }}"
          *ngIf="orgConfig && orgConfig.logo"
          style="height: 60px; width: 160px; object-fit: contain"
        />
        <img
          *ngIf="orgConfig && !orgConfig.logo"
          src="/assets/img/logos/logo.png"
          style="height: 60px; width: 160px; object-fit: contain"
        />
      </div>
      <!-- Headings -->
      <div
        style="
          padding-left: 200px;
          display: flex;
          column-gap: 25px;
          font-weight: 400;
        "
        class="navBarDesktopView"
        *ngIf="displayTopBarLinks"
      >
        <ul class="nav">
          <!-- Provider Home Page -->
          <li
            *ngIf="isPhysician"
            routerlinkactive="active"
            class="nav-item {{
              url.path() === '/provider-dashboard' || url.path() === '/'
                ? 'active'
                : ''
            }}"
          >
            <a class="nav-link" routerLink="/provider-dashboard">
              <span>Home</span>
            </a>
          </li>
          <!-- NP Provider Home Page -->
          <li
            *ngIf="!isPhysician && !isPatient && hasTherapySessionFeature"
            routerlinkactive="active"
            class="nav-item {{
              url.path() === '/general-dashboard' || url.path() === '/'
                ? 'active'
                : ''
            }}"
          >
            <a class="nav-link" routerLink="/general-dashboard">
              <span>Home</span>
            </a>
          </li>

          <!-- Members -->
          <li
            *ngIf="!isPatient"
            class="nav-item dropdown"
            [class.active]="
              url.path() === '/physician/patients' ||
              url.path().includes('/main/member') ||
              url.path() === '/patients' ||
              url.path().includes('/patients?facilityId') ||
              url.path().includes('/main/assessments') ||
              url.path().includes('/main/cases') ||
              url.path().includes('/main/eligibility-checks') ||
              url.path().includes('/main/referrals')
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="membersDropDown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Members</span>
            </a>
            <ul
              class="dropdown-menu"
              aria-labelledby="membersDropDown"
              style="max-height: 450px; overflow-y: auto"
            >
              <li *ngIf="isPhysician">
                <a class="dropdown-item" routerLink="/physician/patients">
                  <mat-icon>people</mat-icon> &nbsp; My Members
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/patients">
                  <mat-icon>group</mat-icon> &nbsp; All Members
                </a>
              </li>
              <li *ngIf="isPhysician">
                <a class="dropdown-item" routerLink="/main/assessments">
                  <mat-icon>business_center</mat-icon> &nbsp; My Assessments
                </a>
              </li>
              <li *ngIf="hasCaseManagementFeature && hasCaseViewAccess">
                <a class="dropdown-item" routerLink="/main/cases">
                  <mat-icon>business_center</mat-icon> &nbsp; My Cases
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/main/eligibility-checks">
                  <mat-icon>credit_score</mat-icon> &nbsp; Eligibility Checks
                </a>
              </li>
            </ul>
          </li>

          <!-- Patient Navigation Links -->
          <li
            *ngIf="isPatient"
            routerlinkactive="active"
            class="nav-item {{
              url.path().indexOf('/patientDashboard/Home') > -1 ? 'active' : ''
            }}"
          >
            <a class="nav-link" routerLink="/patientDashboard/Home">
              <span>Home</span>
            </a>
          </li>
          <li
            *ngIf="isPatient"
            routerlinkactive="active"
            class="nav-item {{
              url.path().indexOf('/patientDashboard/Assessments') > -1
                ? 'active'
                : ''
            }}"
          >
            <a class="nav-link" routerLink="/patientDashboard/Assessments">
              <span>Assessments</span>
            </a>
          </li>
          <li
            *ngIf="isPatient"
            routerlinkactive="active"
            class="nav-item {{
              url.path().indexOf('/patientDashboard/Sessions') > -1
                ? 'active'
                : ''
            }}"
          >
            <a class="nav-link" routerLink="/patientDashboard/Sessions">
              <span>Appointments</span>
            </a>
          </li>
          <li
            *ngIf="isPatient"
            routerlinkactive="active"
            class="nav-item {{
              url.path().indexOf('/patientDashboard/Goals') > -1 ? 'active' : ''
            }}"
          >
            <a class="nav-link" routerLink="/patientDashboard/Goals">
              <span>Goals</span>
            </a>
          </li>

          <!-- Claims Only If it has Claims View access -->
          <li
            *ngIf="!isPatient && hasClaimsViewAccess && hasClaimsFeature"
            class="nav-item dropdown"
            [class.active]="
              url.path() === '/claims' ||
              url.path().includes('/claims') ||
              url.path().includes('/remittances/remit')
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="claimsDropDown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Billing</span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="claimsDropDown"
              style="max-height: 450px; overflow-y: auto"
            >
              <li>
                <a class="dropdown-item" routerLink="/claims">
                  <mat-icon>account_balance_wallet</mat-icon> &nbsp; Claims
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/remittances/remit">
                  <mat-icon>paid</mat-icon> &nbsp; Remittances
                </a>
              </li>
            </ul>
          </li>

          <!-- Schedules For NP -->
          <li
            *ngIf="!isPatient"
            class="nav-item dropdown"
            [class.active]="url.path().includes('/schedules')"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="schedulesDropDown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>Schedules</span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="schedulesDropDown"
              style="max-height: 450px; overflow-y: auto"
            >
              <li *ngIf="hasTherapySessionAddAccess">
                <a
                  class="dropdown-item"
                  routerLink="/schedules/providerlistscheduler"
                >
                  <mat-icon>person</mat-icon> &nbsp; + Individual Appointment
                </a>
              </li>
              <li *ngIf="hasTherapySessionAddAccess && hasGroupSessionFeature">
                <a
                  class="dropdown-item"
                  routerLink="/schedules/groupsessionscheduler"
                >
                  <mat-icon>group</mat-icon> &nbsp; + Group Appointment
                </a>
              </li>
              <li *ngIf="hasTeleHealthEnabled">
                <a class="dropdown-item" routerLink="/telehealthscheduler">
                  <mat-icon>videocam</mat-icon> &nbsp; + Telehealth Meeting
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  routerLink="/schedules/staffs-schedule-dashboard"
                >
                  <mat-icon>calendar_month</mat-icon> &nbsp; Staff Schedules
                </a>
              </li>
            </ul>
          </li>

          <li
            class="nav-item dropdown"
            *ngIf="!isPatient"
            [class.active]="url.path().includes('/other/')"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="otherDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>Other</span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="otherDropdownMenuLink"
              style="max-height: 450px; overflow-y: auto"
            >
              <!-- Referrals -->
              <li *ngIf="!isPatient && showReferralFunctionality">
                <a
                  class="dropdown-item d-flex align-items-center"
                  routerLink="/other/referral/home"
                >
                  <mat-icon>person_add_alt</mat-icon> &nbsp; Referrals
                </a>
              </li>

              <!-- IVR Main Menu -->
              <li
                *ngIf="
                  hasIVRServices &&
                  !isPatient &&
                  (hasIVRSessionsViewAccess ||
                    hasIVRVoiceMessageViewAccess ||
                    hasIVRAppointmentsViewAccess)
                "
              >
                <div
                  class="dropdown-item"
                  [matMenuTriggerFor]="ivrMenu"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>settings_phone</mat-icon> &nbsp; IVR
                  <mat-icon class="d-flex align-items-right"
                    >chevron_right</mat-icon
                  >
                </div>
              </li>

              <!-- Submenu Trigger -->
              <mat-menu #ivrMenu="matMenu" overlapTrigger="false">
                <button
                  mat-menu-item
                  *ngIf="hasIVRSessionsViewAccess"
                  [routerLink]="'/other/ivr/sessions'"
                >
                  Sessions
                </button>
                <button
                  mat-menu-item
                  *ngIf="hasIVRVoiceMessageViewAccess"
                  [routerLink]="'/other/ivr/voice-messages'"
                >
                  Voice Messages
                </button>
                <button
                  mat-menu-item
                  *ngIf="hasIVRAppointmentsViewAccess"
                  [routerLink]="'/other/ivr/scheduled-appointments'"
                >
                  Appointments
                </button>
              </mat-menu>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- Hamburger Menu -Mobile View <991 px-->
    <div
      class="row"
      style="margin-right: 1px; z-index: 1000"
      class="mobileView"
    >
      <!-- Notifications -->
      <button
        *ngIf="isPatient || isPhysician"
        mat-icon-button
        [matMenuTriggerFor]="notificationMenu"
        #menuTrigger
        type="button"
        [matBadge]="newNotificationCount"
        [matBadgeHidden]="newNotificationCount == 0"
        matBadgeColor="warn"
        style="
          width: 35px !important;
          height: 35px !important;
          padding: 0 !important;
        "
      >
        <mat-icon> notifications </mat-icon>
      </button>
      <!-- Chat Button -->
      <button
        mat-icon-button
        type="button"
        *ngIf="displayTopBarLinks && !isPatient"
        (click)="openChat()"
        matTooltip="Messenger"
        [matBadge]="totalMessageUnreadCount"
        [matBadgeHidden]="totalMessageUnreadCount == 0"
        matBadgeColor="warn"
      >
        <mat-icon class="material-icons">chat</mat-icon>
      </button>

      <button
        mat-button
        class="navbar-toggler btn-no-ripple"
        type="button"
        (click)="sidebarToggle()"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
    </div>

    <!-- Nav Bar Menu Hides when screen <991-->
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <!-- Notifications -->
        <button
          *ngIf="isPatient || isPhysician"
          mat-icon-button
          [matMenuTriggerFor]="notificationMenu"
          #menuTrigger
          type="button"
          [matBadge]="newNotificationCount"
          [matBadgeHidden]="newNotificationCount == 0"
          matBadgeColor="warn"
          style="
            width: 35px !important;
            height: 35px !important;
            padding: 0 !important;
          "
        >
          <mat-icon> notifications </mat-icon>
        </button>
        <!-- Chat Button -->
        <button
          mat-icon-button
          type="button"
          *ngIf="displayTopBarLinks && !isPatient"
          (click)="openChat()"
          matTooltip="Messenger"
          [matBadge]="totalMessageUnreadCount"
          [matBadgeHidden]="totalMessageUnreadCount == 0"
          matBadgeColor="warn"
        >
          <mat-icon class="material-icons">chat</mat-icon>
        </button>

        <!--Facility List-->
        <li
          *ngIf="
            facilities.length > 1 &&
            displayTopBarLinks &&
            !consolidatedMemberSearch
          "
          class="nav-item dropdown"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="facilityDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <mat-icon>location_on</mat-icon>
            <span *ngIf="selectedFacility; else defaultFacility">
              {{ selectedFacility }}
            </span>
            <ng-template #defaultFacility>Select a Facility</ng-template>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="facilityDropdownMenuLink"
            style="max-height: 450px; overflow-y: auto"
          >
            <li *ngFor="let facility of facilities">
              <a
                class="dropdown-item"
                (click)="
                  onFacilityChange($event, facility.id, facility.facilityName)
                "
                href="#"
              >
                {{ facility.facilityName }}
              </a>
            </li>
          </ul>
        </li>

        <li
          *ngIf="
            this.facilities.length == 1 && !isPhysician && displayTopBarLinks
          "
          class="nav-item"
        >
          <a class="txt-blue nav-link" routerLink="" routerLinkActive="">
            <mat-icon class=" ">location_on</mat-icon>
            {{ selectedFacility }}
          </a>
        </li>

        <li class="nav-item dropdown" *ngIf="isPhysician && displayTopBarLinks">
          <a
            class="nav-link"
            routerLink="/config/playbooks"
            id="settingsDropdownMenuLink"
            matTooltip="Play Book"
          >
            <i class="material-icons">menu_book</i>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="accountDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">account_circle</i>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="accountDropdownMenuLink"
          >
            <li *ngIf="displayTopBarLinks">
              <a class="dropdown-item" routerLink="/config/manage-settings">
                <span class="material-icons">manage_accounts</span>
                &nbsp;Account Settings
              </a>
            </li>

            <li *ngIf="displayTopBarLinks && !isPatient">
              <a class="dropdown-item" routerLink="/forms">
                <span class="material-icons">picture_as_pdf</span>
                &nbsp;Forms
              </a>
            </li> 
            
            <li *ngIf="isOrgAdmin">
              <a class="dropdown-item"[href]="adminPortalUrl">
                <span class="material-icons">swap_horiz</span>
                &nbsp;Admin Portal
              </a>
            </li>

            <li>
              <a
                class="dropdown-item"
                (click)="logout()"
                style="cursor: pointer"
              >
                <span class="material-icons">logout</span>
                &nbsp;Log out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<mat-menu #notificationMenu="matMenu" class="notificationMenu">
  <!--Notification Heading -->
  <div class="notificationHeading">
    <div style="font-weight: 800">Notifications</div>
    <!-- Buttons -->
    <div>
      <mat-icon
        (click)="updateStatusToSeen(); $event.stopPropagation()"
        *ngIf="notificationsList?.total > 0"
        style="cursor: pointer; margin: 0px 15px; color: #2196f3"
        matTooltip="Mark all as read"
      >
        done_all
      </mat-icon>

      <mat-icon
        *ngIf="notificationsList?.total > 0"
        (click)="deleteAllNotifications(); $event.stopPropagation()"
        style="cursor: pointer; margin: 0px 15px; color: red"
        matTooltip="Delete all notificatons"
      >
        delete_forever
      </mat-icon>
    </div>
  </div>

  <p
    *ngIf="notificationsList?.total == 0 || !notificationsList?.total"
    style="
      font-size: 14px;
      font-weight: 420;
      text-align: center;
      margin-top: 20px;
    "
  >
    No new notifications.
  </p>

  <mat-card
    *ngFor="let notification of notificationsList?.items"
    appearance="raised"
    (click)="$event.stopPropagation(); navigateNotificationLink(notification)"
    class="notificationCard mat-elevation-z8"
    style="
      font-weight: '400';
      font-size: 12px;
      margin-top: 20px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
    "
  >
    <div
      class="row"
      style="align-items: center; position: relative; display: flex"
    >
      <div style="padding-left: 10px; padding-right: 10px; width: 95%">
        <span style="font-weight: 410">{{ notification.message }}</span>
        <br />
        <div
          style="font-size: 9px; display: flex; justify-content: space-between"
        >
          <span>
            {{ notification.notificationTime | date : 'mediumDate' }}
          </span>
          <span>
            {{ getTimeDiff(notification.notificationTime) }}
          </span>
        </div>
      </div>
      <div style="width: 5%">
        <mat-icon
          *ngIf="notification.status === 'NEW'"
          style="
            position: absolute;
            top: 40%;
            right: 0;
            font-size: 12px;
            color: #2196f3;
            cursor: pointer;
          "
          matTooltip="Mark as read"
          (click)="markNotificationAsRead(notification.id)"
        >
          fiber_manual_record
        </mat-icon>

        <mat-icon
          style="
            position: absolute;
            top: -10px;
            right: 0;
            font-size: 13px;
            color: darkgray;
            cursor: pointer;
          "
          (click)="deleteNotification(notification.id)"
        >
          cancel
        </mat-icon>
      </div>
    </div>
  </mat-card>
</mat-menu>
