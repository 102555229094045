import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentDischargeSubstance } from 'src/app/_models/case/treatment/discharge/treatmentdischargesubstance.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentDischargeSubstanceApiService } from 'src/app/_services/case/treatment/discharge/treatmentdischargesubstance.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
@Component({
  selector: 'app-discharge-substance-use',
  templateUrl: './discharge-substance-use.component.html',
  styleUrl: './discharge-substance-use.component.css',
})
export class DischargeSubstanceUseComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedProgram: ITreatmentProgram;

  public action: string;
  public treatmentdischargesubstanceForm: FormGroup;
  treatmentdischargesubstance: ITreatmentDischargeSubstance;
  processing: boolean = false;

  public substanceCodeOptions = [
    'None',
    'K2',
    'Alcohol',
    'Cocaine',
    'Marijuana',
    'Heroin',
    'Non-Prescription Methadone',
    'Other Opiates and Synthetics',
    'PCP',
    'Hallucinogens',
    'Methamphetamine',
    'Amphetamines',
    'Other stimulants',
    'Benzodiazepine',
    'Tranquilizers',
    'Barbiturates',
    'Other Sedatives or Hypnotics',
    'Inhalants',
    'Over-the-counter',
    'Other Drug Not Otherwise Specified',
    'Drug Unknown',
    'Tobacco',
    'Binge Drinking',
    'Prescription Drugs',
    'School Based Liaison',
    'Lifeline',
  ];

  public severityCodeOptions = [
    'Mild Problem/Dysfunction',
    'Moderate Problem/Dysfnc',
    'N/A',
    'Not a Problem',
    'Severe Problem/Dysfnc',
  ];

  public frequencyCodeOptions = [
    'No Secondary or Tertiary Drug Applicable',
    'No use in the past month',
    '1-3 times in past month',
    '1-2 times in past week',
    '3-6 times in past week',
    'Daily',
    'Unknown',
  ];

  public intakeTypeCodeOptions = [
    'N/A',
    'Oral',
    'Smoking',
    'Inhalation',
    'Injection',
    'Other',
    'No Secondary or Tertiary Drug Applicable',
    'Unknown',
  ];

  constructor(
    public treatmentdischargesubstanceApiService: TreatmentDischargeSubstanceApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();
    this.loadTreatmentDischargeSubstance();
  }

  loadTreatmentDischargeSubstance() {
    this.treatmentdischargesubstanceApiService
      .getTreatmentDischargeSubstanceDetails(this.selectedProgram.id)
      .subscribe((treatmentdischargesubstance) => {
        if (treatmentdischargesubstance?.data) {
          this.treatmentdischargesubstanceForm.controls['id'].setValue(
            treatmentdischargesubstance.data.id
          );
          this.treatmentdischargesubstanceForm.controls[
            'organizationId'
          ].setValue(treatmentdischargesubstance.data.organizationId);
          this.treatmentdischargesubstanceForm.controls['patientId'].setValue(
            treatmentdischargesubstance.data.patientId
          );
          this.treatmentdischargesubstanceForm.controls['caseId'].setValue(
            treatmentdischargesubstance.data.caseId
          );
          this.treatmentdischargesubstanceForm.controls['programId'].setValue(
            treatmentdischargesubstance.data.programId
          );
          this.treatmentdischargesubstanceForm.controls[
            'primarySubstance'
          ].setValue(treatmentdischargesubstance.data.primarySubstance);
          this.treatmentdischargesubstanceForm.controls[
            'primarySeverity'
          ].setValue(treatmentdischargesubstance.data.primarySeverity);
          this.treatmentdischargesubstanceForm.controls[
            'primaryFrequency'
          ].setValue(treatmentdischargesubstance.data.primaryFrequency);
          this.treatmentdischargesubstanceForm.controls[
            'primaryMethod'
          ].setValue(treatmentdischargesubstance.data.primaryMethod);
          this.treatmentdischargesubstanceForm.controls[
            'secondarySubstance'
          ].setValue(treatmentdischargesubstance.data.secondarySubstance);
          this.treatmentdischargesubstanceForm.controls[
            'secondarySeverity'
          ].setValue(treatmentdischargesubstance.data.secondarySeverity);
          this.treatmentdischargesubstanceForm.controls[
            'secondaryFrequency'
          ].setValue(treatmentdischargesubstance.data.secondaryFrequency);
          this.treatmentdischargesubstanceForm.controls[
            'secondaryMethod'
          ].setValue(treatmentdischargesubstance.data.secondaryMethod);
          this.treatmentdischargesubstanceForm.controls[
            'tertiarySubstance'
          ].setValue(treatmentdischargesubstance.data.tertiarySubstance);
          this.treatmentdischargesubstanceForm.controls[
            'tertiarySeverity'
          ].setValue(treatmentdischargesubstance.data.tertiarySeverity);
          this.treatmentdischargesubstanceForm.controls[
            'tertiaryFrequency'
          ].setValue(treatmentdischargesubstance.data.tertiaryFrequency);
          this.treatmentdischargesubstanceForm.controls[
            'tertiaryMethod'
          ].setValue(treatmentdischargesubstance.data.tertiaryMethod);
          this.treatmentdischargesubstanceForm.controls[
            'wasMethadoneMaintenancePartOfTx'
          ].setValue(
            treatmentdischargesubstance.data.wasMethadoneMaintenancePartOfTx
          );
          this.treatmentdischargesubstanceForm.controls[
            'doesClientUseTobacco'
          ].setValue(treatmentdischargesubstance.data.doesClientUseTobacco);
          this.treatmentdischargesubstanceForm.controls[
            'dischargeStatus'
          ].setValue(treatmentdischargesubstance.data.dischargeStatus);
          this.treatmentdischargesubstanceForm.controls[
            'postDischargeCaseManagement'
          ].setValue(
            treatmentdischargesubstance.data.postDischargeCaseManagement
          );
          this.treatmentdischargesubstanceForm.controls[
            'postDischargeCaseManagementDays'
          ].setValue(
            treatmentdischargesubstance.data.postDischargeCaseManagementDays
          );
          this.treatmentdischargesubstanceForm.controls['prognosis'].setValue(
            treatmentdischargesubstance.data.prognosis
          );
          this.treatmentdischargesubstanceForm.controls[
            'wasFamilyMemberInvolved'
          ].setValue(treatmentdischargesubstance.data.wasFamilyMemberInvolved);
          this.treatmentdischargesubstanceForm.controls[
            'wasConcernedPersonInvolved'
          ].setValue(
            treatmentdischargesubstance.data.wasConcernedPersonInvolved
          );
          this.treatmentdischargesubstanceForm.controls[
            'wasMHServiceReceived'
          ].setValue(treatmentdischargesubstance.data.wasMHServiceReceived);
          this.treatmentdischargesubstanceForm.controls[
            'psychiatricFollowUp'
          ].setValue(treatmentdischargesubstance.data.psychiatricFollowUp);
          this.treatmentdischargesubstanceForm.controls[
            'codependentOrCollateral'
          ].setValue(treatmentdischargesubstance.data.codependentOrCollateral);
        }
      });
  }

  onTreatmentDischargeSubstanceFormSubmit() {
    if (this.treatmentdischargesubstanceForm.invalid) {
      this.treatmentdischargesubstanceForm.markAllAsTouched();
      return;
    }
    this.treatmentdischargesubstance = Object.assign(
      {},
      this.treatmentdischargesubstance
    );
    this.treatmentdischargesubstance = Object.assign(
      this.treatmentdischargesubstance,
      this.treatmentdischargesubstanceForm.value
    );

    this.processing = true;
    this.treatmentdischargesubstanceApiService
      .updateTreatmentDischargeSubstance(this.treatmentdischargesubstance)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentdischargesubstanceForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      programId: new FormControl(this.selectedProgram.id, Validators.required),
      primarySubstance: new FormControl('', Validators.required),
      primarySeverity: new FormControl('', Validators.required),
      primaryFrequency: new FormControl('', Validators.required),
      primaryMethod: new FormControl('', Validators.required),
      secondarySubstance: new FormControl('', Validators.required),
      secondarySeverity: new FormControl('', Validators.required),
      secondaryFrequency: new FormControl('', Validators.required),
      secondaryMethod: new FormControl('', Validators.required),
      tertiarySubstance: new FormControl('', Validators.required),
      tertiarySeverity: new FormControl('', Validators.required),
      tertiaryFrequency: new FormControl('', Validators.required),
      tertiaryMethod: new FormControl('', Validators.required),
      wasMethadoneMaintenancePartOfTx: new FormControl('', Validators.required),
      doesClientUseTobacco: new FormControl('', Validators.required),
      dischargeStatus: new FormControl('', Validators.required),
      postDischargeCaseManagement: new FormControl('', Validators.required),
      postDischargeCaseManagementDays: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      prognosis: new FormControl('', Validators.compose([Validators.required])),
      wasFamilyMemberInvolved: new FormControl('', Validators.required),
      wasConcernedPersonInvolved: new FormControl('', Validators.required),
      wasMHServiceReceived: new FormControl('', Validators.required),
      psychiatricFollowUp: new FormControl('', Validators.required),
      codependentOrCollateral: new FormControl('', Validators.required),
    });
  }
}
