import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CareLog,
  DataTablesModel,
  INewClinicalNote,
  ITherapySession,
  ResponseModal,
} from 'src/app/_models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TherapySessionService {
  constructor(private httpClient: HttpClient) {}

  // Creating therapy session
  createNewTherapySession(newTherapySession: ITherapySession) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/cp-create-therapy-session`,
      newTherapySession,
      { headers: headers }
    );
  }

  createNewGroupTherapySession(newTherapySession: ITherapySession) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/cp-create-group-therapy-session`,
      newTherapySession,
      { headers: headers }
    );
  }

  // Creating therapy session
  createNewTelehealthMeeting(newTherapySession: ITherapySession) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/cp-create-telehealth-meeting`,
      newTherapySession,
      { headers: headers }
    );
  }

  // Creating therapy session
  updateNewTelehealthMeeting(newTherapySession: ITherapySession) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.put<any>(
      `${environment.apiUrl}/cp-update-telehealth-meeting`,
      newTherapySession,
      { headers: headers }
    );
  }

  handleGroupSessionMemberAttendance(
    sessionId: string,
    memberId: string,
    attendance: boolean
  ) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/handle-group-therapy-attendance/${sessionId}/${memberId}/${attendance}`,
      null,
      { headers: headers }
    );
  }

  handleGroupSessionMembers(
    sessionId: string,
    memberId: string,
    type: boolean
  ) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/handle-group-therapy-member/${sessionId}/${memberId}/${type}`,
      null,
      { headers: headers }
    );
  }

  updateGroupTherapySession(newTherapySession: ITherapySession) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/cp-update-group-therapy-session`,
      newTherapySession,
      { headers: headers }
    );
  }

  // Get all the sessions for scheduling new session
  getExistingAppointmentsforNewSchedule(patientId: string) {
    let params = new HttpParams();
    params = params.append('patientId', patientId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/existingappointmentsfornewschedule`,
      { params: params }
    );
  }

  // Get all meetings for physician
  getClinicianTherapySessions(
    status: string,
    searchSessionCode: string,
    lastName: string,
    firstName: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    if (status) params = params.append('status', status);
    if (lastName) params = params.append('lastName', lastName);
    if (firstName) params = params.append('firstName', firstName);

    if (searchSessionCode)
      params = params.append('searchSessionCode', searchSessionCode);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/clinician-therapy-sessions`,
      { params: params }
    );
  }

  getTherapySessionDetails(
    therapysessionId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/therapysession/` + therapysessionId
    );
  }

  getPatientTherapySessions(
    patientId: string,
    organizationId: string,
    preselectedSessionCode: string,
    pageSize: number,
    pageIndex: number,
    sortOrder: string,
    searchSessionCode: string,
    status: string
  ) {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('order', sortOrder);

    if (searchSessionCode)
      params = params.append('searchSessionCode', searchSessionCode);
    if (status) params = params.append('status', status);

    if (preselectedSessionCode)
      params = params.append('sessionCode', preselectedSessionCode);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/patient-therapy-sessions`,
      { params: params }
    );
  }

  getProviderActiveNotes(
    patientId: string,
    organizationId: string,
    preselectedSessionCode: string,
    pageSize: number,
    pageIndex: number,
    sortOrder: string,
    searchSessionCode: string,
    status: string
  ) {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('order', sortOrder);

    if (searchSessionCode)
      params = params.append('searchSessionCode', searchSessionCode);
    if (status) params = params.append('status', status);

    if (preselectedSessionCode)
      params = params.append('sessionCode', preselectedSessionCode);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/patient-therapy-sessions`,
      { params: params }
    );
  }

  // Get Host URL
  getHostUrl(appointmentId: string, patientId: string, organizationId: string) {
    let params = new HttpParams();
    params = params.append('appointmentId', appointmentId);
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    return this.httpClient.get<any>(
      `${environment.apiUrl}/gethostmeetinglink`,
      { params: params }
    );
  }

  // Get Guest URL
  getGuestUrl(appointmentId: string) {
    let params = new HttpParams();
    params = params.append('appointmentId', appointmentId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/getpatientmeetinglink`,
      { params: params }
    );
  }

  // Get appointment for a type - for physicians only
  getAppointmentByType(
    patientId: string,
    type: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('status', type);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/getphysicianappointment`,
      { params: params }
    );
  }

  // Get all appointments for patient
  getPatientAppointments(pageSize: number, pageIndex: number) {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/patientsupcomingappointments`,
      { params: params }
    );
  }

  updateObjective(
    sessionId: string,
    objectiveAndSubjective: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/updateobjectives/` + sessionId,
        objectiveAndSubjective,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateSubjective(
    sessionId: string,
    objectiveAndSubjective: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/updatesubjectives/` + sessionId,
        objectiveAndSubjective,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateSession(session: ITherapySession) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/update-therapy-session`, session, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Add New Clincial Note Type
  addNewSessionClinicalNote(newClinicalNote: INewClinicalNote) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/add-therapy-session-clinical-note`,
        newClinicalNote,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addNewGroupClinicalNote(newClinicalNote: INewClinicalNote) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/add-therapy-group-note`,
        newClinicalNote,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addVitalsToClinicalNote(sessionId: string, careLog: CareLog) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/therapy-session-vitals/${sessionId}`,
        careLog,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Add New Clincial Note Type
  deleteClinicalNote(sessionId: string) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl}/delete-therapy-session-clinical-note/${sessionId}`,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Update Exising Note JSON
  updateSessionNote(sessionId: string, payload: any) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/update-therapy-session-clinical-note/` +
          sessionId,
        payload,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Update Group Member Level Note
  updateGroupMemberLevelNote(
    sessionId: string,
    memberId: string,
    payload: any
  ) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/update-group-member-level-note/${sessionId}/${memberId}`,
        payload,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Update Group Member Group Note Config
  updateGroupMemberGroupNoteConfig(
    sessionId: string,
    memberId: string,
    sectionId: string,
    option: boolean
  ) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/grouplevelmembernoteconfig/${sessionId}/${memberId}/${sectionId}/${option}`,

        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  closeSession(
    sessionId: string,
    objectiveAndSubjective: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/close-therapy-session/` + sessionId,
        objectiveAndSubjective,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  closeGroupSession(sessionId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/close-group-session/${sessionId}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  memberGetTherapySessions(
    pageSize: number,
    pageIndex: number,
    sortOrder: string,
    searchSessionCode: string,
    status: string
  ) {
    let params = new HttpParams();

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('order', sortOrder);

    if (searchSessionCode)
      params = params.append('searchSessionCode', searchSessionCode);
    if (status) params = params.append('status', status);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/member-all-sessions`,
      { params: params }
    );
  }

  updateBillingInformation(
    sessionId: string,
    therapySession: ITherapySession
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updatebillinginformation/` + sessionId,
        therapySession,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGroupDianosis(
    sessionId: string,
    memberId: string,
    newDiagnosis: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updategroupdiagnosis/${sessionId}/${memberId}`,
        newDiagnosis,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAllGroupMemberBillingInformation(
    sessionId: string,
    payload: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updateallgroupmemberbilling/${sessionId}`,
        payload,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGroupMemberCharge(
    sessionId: string,
    memberId: string,
    charges: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updategroupmembercharges/${sessionId}/${memberId}`,
        charges,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateClaimsBillingInformation(
    sessionId: string,
    therapySession: ITherapySession
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updateclaimsbillinginformation/` + sessionId,
        therapySession,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  submitPrimaryClaimsForProcessing(
    sessionId: string,
    therapySession: ITherapySession
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/submitprimaryclaims/` + sessionId,
        therapySession,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGeneralNote(
    sessionId: string,
    therapySession: ITherapySession
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updategeneralnote/` + sessionId,
        therapySession,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGroupMemberGeneralNote(
    sessionId: string,
    memberId: string,
    noteContent: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updategroupmembergeneralnote/${sessionId}/${memberId}`,
        noteContent,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateSignature(
    sessionId: string,
    therapySession: ITherapySession,
    type: string
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updatesignature/${sessionId}/${type}`,
        therapySession,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Update Session Queue
  updateQueueStatus(sessionId: string, queueName: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updatesessionqueue/${sessionId}/${queueName}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  previewClinicalNotesDocument(sessionId: string, memberId: string): any {
    let params = new HttpParams();
    if (sessionId) params = params.append('sessionId', sessionId);
    if (memberId) params = params.append('memberId', memberId);
    return this.httpClient
      .get(`${environment.apiUrl}/therapysession-document`, {
        responseType: 'blob',
        params: params,
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  viewGroupMemberNotes(
    sessionId: string,
    memberId: string,
    printType: string,
    startDate: any,
    endDate: any
  ): any {
    let params = new HttpParams();
    if (memberId) params = params.append('sessionId', sessionId);
    if (memberId) params = params.append('memberId', memberId);
    if (printType) params = params.append('printType', printType);
    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);

    return this.httpClient
      .get(`${environment.apiUrl}/group-session-clinical-notes-pdf`, {
        responseType: 'blob',
        params: params,
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  // Past Session Note Data
  getPastClinicalNotesData(
    sessionId: string,
    memberId: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (memberId) params = params.append('memberId', memberId);
    return this.httpClient
      .get<DataTablesModel>(
        `${environment.apiUrl}/getpastclinicalnotes/` + sessionId,
        { params: params }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPastGroupClinicalNotes(
    currentSessionId: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (currentSessionId)
      params = params.append('currentSessionId', currentSessionId);

    return this.httpClient
      .get<DataTablesModel>(`${environment.apiUrl}/getpastgroupclinicalnotes`, {
        params: params,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Get the lastest Note contents
  getLatestForMemberNoteSections(memberId: string): Observable<ResponseModal> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/memberLatestNoteContents/` + memberId
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getMemberSessionVitals(
    memberId: string,
    sessionId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/therapy-session-vitals/` +
        memberId +
        '/' +
        sessionId
    );
  }

  assignClinician(sessionId: string, assignClinician: any) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/therapy-session-assign-clinician/` + sessionId,
        assignClinician,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
