import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IChildrensAnxiety } from 'src/app/_models/assessments/anxiety/childrensanxiety.model';
import {
  ChildrensAnxietyApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-childrens-anxiety',
  templateUrl: './childrens-anxiety.component.html',
  styleUrls: ['./childrens-anxiety.component.css'],
})
export class ChildrensAnxietyComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public childrensAnxietyApiService: ChildrensAnxietyApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public childrensAnxietyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  childrensAnxietyAssessment: IChildrensAnxiety;
  childrensAnxietyAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.childrensAnxietyForm, this.errors);

    this.loadChildrensAnxietyAssessment();
  }

  loadChildrensAnxietyAssessment() {
    this.childrensAnxietyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.childrensAnxietyApiService
        .getChildrensAnxietyDetails(this.assessmentId)
        .subscribe((childrensAnxietyAssessment) => {
          if (childrensAnxietyAssessment.data) {
            this.childrensAnxietyForm.controls['q1'].setValue(
              childrensAnxietyAssessment.data.q1.answer
            );
            this.childrensAnxietyForm.controls['q2'].setValue(
              childrensAnxietyAssessment.data.q2.answer
            );
            this.childrensAnxietyForm.controls['q3'].setValue(
              childrensAnxietyAssessment.data.q3.answer
            );
            this.childrensAnxietyForm.controls['q4'].setValue(
              childrensAnxietyAssessment.data.q4.answer
            );
            this.childrensAnxietyForm.controls['q5'].setValue(
              childrensAnxietyAssessment.data.q5.answer
            );
            this.childrensAnxietyForm.controls['q6'].setValue(
              childrensAnxietyAssessment.data.q6.answer
            );
            this.childrensAnxietyForm.controls['q7'].setValue(
              childrensAnxietyAssessment.data.q7.answer
            );
            this.childrensAnxietyForm.controls['q8'].setValue(
              childrensAnxietyAssessment.data.q8.answer
            );
            this.childrensAnxietyForm.controls['q9'].setValue(
              childrensAnxietyAssessment.data.q9.answer
            );
            this.childrensAnxietyForm.controls['q10'].setValue(
              childrensAnxietyAssessment.data.q10.answer
            );
            this.childrensAnxietyForm.controls['q11'].setValue(
              childrensAnxietyAssessment.data.q11.answer
            );
            this.childrensAnxietyForm.controls['q12'].setValue(
              childrensAnxietyAssessment.data.q12.answer
            );
            this.childrensAnxietyForm.controls['q13'].setValue(
              childrensAnxietyAssessment.data.q13.answer
            );
            this.childrensAnxietyForm.controls['q14'].setValue(
              childrensAnxietyAssessment.data.q14.answer
            );
            this.childrensAnxietyForm.controls['q15'].setValue(
              childrensAnxietyAssessment.data.q15.answer
            );
            this.childrensAnxietyForm.controls['q16'].setValue(
              childrensAnxietyAssessment.data.q16.answer
            );
            this.childrensAnxietyForm.controls['q17'].setValue(
              childrensAnxietyAssessment.data.q17.answer
            );
            this.childrensAnxietyForm.controls['q18'].setValue(
              childrensAnxietyAssessment.data.q18.answer
            );
            this.childrensAnxietyForm.controls['q19'].setValue(
              childrensAnxietyAssessment.data.q19.answer
            );
            this.childrensAnxietyForm.controls['q20'].setValue(
              childrensAnxietyAssessment.data.q20.answer
            );
            this.childrensAnxietyForm.controls['q21'].setValue(
              childrensAnxietyAssessment.data.q21.answer
            );
            this.childrensAnxietyForm.controls['q22'].setValue(
              childrensAnxietyAssessment.data.q22.answer
            );
            this.childrensAnxietyForm.controls['q23'].setValue(
              childrensAnxietyAssessment.data.q23.answer
            );
            this.childrensAnxietyForm.controls['q24'].setValue(
              childrensAnxietyAssessment.data.q24.answer
            );
            this.childrensAnxietyForm.controls['q25'].setValue(
              childrensAnxietyAssessment.data.q25.answer
            );
            this.childrensAnxietyForm.controls['q26'].setValue(
              childrensAnxietyAssessment.data.q26.answer
            );
            this.childrensAnxietyForm.controls['q27'].setValue(
              childrensAnxietyAssessment.data.q27.answer
            );
            this.childrensAnxietyForm.controls['q28'].setValue(
              childrensAnxietyAssessment.data.q28.answer
            );
            this.childrensAnxietyForm.controls['q29'].setValue(
              childrensAnxietyAssessment.data.q29.answer
            );
            this.childrensAnxietyForm.controls['q30'].setValue(
              childrensAnxietyAssessment.data.q30.answer
            );
            this.childrensAnxietyForm.controls['q31'].setValue(
              childrensAnxietyAssessment.data.q31.answer
            );
            this.childrensAnxietyForm.controls['q32'].setValue(
              childrensAnxietyAssessment.data.q32.answer
            );
            this.childrensAnxietyForm.controls['q33'].setValue(
              childrensAnxietyAssessment.data.q33.answer
            );
            this.childrensAnxietyForm.controls['q34'].setValue(
              childrensAnxietyAssessment.data.q34.answer
            );
            this.childrensAnxietyForm.controls['q35'].setValue(
              childrensAnxietyAssessment.data.q35.answer
            );
            this.childrensAnxietyForm.controls['q36'].setValue(
              childrensAnxietyAssessment.data.q36.answer
            );
            this.childrensAnxietyForm.controls['q37'].setValue(
              childrensAnxietyAssessment.data.q37.answer
            );
            this.childrensAnxietyForm.controls['q38'].setValue(
              childrensAnxietyAssessment.data.q38.answer
            );
            this.childrensAnxietyForm.controls['q39'].setValue(
              childrensAnxietyAssessment.data.q39.answer
            );
            this.childrensAnxietyForm.controls['q40'].setValue(
              childrensAnxietyAssessment.data.q40.answer
            );
            this.childrensAnxietyForm.controls['q41'].setValue(
              childrensAnxietyAssessment.data.q41.answer
            );
            this.childrensAnxietyForm.controls['q42'].setValue(
              childrensAnxietyAssessment.data.q42.answer
            );
            this.childrensAnxietyForm.controls['q43'].setValue(
              childrensAnxietyAssessment.data.q43.answer
            );
            this.childrensAnxietyForm.controls['q44'].setValue(
              childrensAnxietyAssessment.data.q44.answer
            );
            this.childrensAnxietyForm.controls['q45'].setValue(
              childrensAnxietyAssessment.data.q45.answer
            );

            this.riskScore = childrensAnxietyAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.childrensAnxietyForm.invalid) {
      this.childrensAnxietyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.childrensAnxietyAssessment = Object.assign(
      {},
      this.childrensAnxietyAssessment
    );
    this.childrensAnxietyAssessment.patientId =
      this.childrensAnxietyForm.controls['patientId'].value;
    this.childrensAnxietyAssessment.sessionId =
      this.childrensAnxietyForm.controls['sessionId'].value;
    this.childrensAnxietyAssessment.assessmentRequestId =
      this.childrensAnxietyForm.controls['assessmentRequestId'].value;
    this.childrensAnxietyAssessment.q1 = {
      answer: this.childrensAnxietyForm.controls['q1'].value,
    };
    this.childrensAnxietyAssessment.q2 = {
      answer: this.childrensAnxietyForm.controls['q2'].value,
    };
    this.childrensAnxietyAssessment.q3 = {
      answer: this.childrensAnxietyForm.controls['q3'].value,
    };
    this.childrensAnxietyAssessment.q4 = {
      answer: this.childrensAnxietyForm.controls['q4'].value,
    };
    this.childrensAnxietyAssessment.q5 = {
      answer: this.childrensAnxietyForm.controls['q5'].value,
    };
    this.childrensAnxietyAssessment.q6 = {
      answer: this.childrensAnxietyForm.controls['q6'].value,
    };
    this.childrensAnxietyAssessment.q7 = {
      answer: this.childrensAnxietyForm.controls['q7'].value,
    };
    this.childrensAnxietyAssessment.q8 = {
      answer: this.childrensAnxietyForm.controls['q8'].value,
    };
    this.childrensAnxietyAssessment.q9 = {
      answer: this.childrensAnxietyForm.controls['q9'].value,
    };
    this.childrensAnxietyAssessment.q10 = {
      answer: this.childrensAnxietyForm.controls['q10'].value,
    };
    this.childrensAnxietyAssessment.q11 = {
      answer: this.childrensAnxietyForm.controls['q11'].value,
    };
    this.childrensAnxietyAssessment.q12 = {
      answer: this.childrensAnxietyForm.controls['q12'].value,
    };
    this.childrensAnxietyAssessment.q13 = {
      answer: this.childrensAnxietyForm.controls['q13'].value,
    };
    this.childrensAnxietyAssessment.q14 = {
      answer: this.childrensAnxietyForm.controls['q14'].value,
    };
    this.childrensAnxietyAssessment.q15 = {
      answer: this.childrensAnxietyForm.controls['q15'].value,
    };
    this.childrensAnxietyAssessment.q16 = {
      answer: this.childrensAnxietyForm.controls['q16'].value,
    };
    this.childrensAnxietyAssessment.q17 = {
      answer: this.childrensAnxietyForm.controls['q17'].value,
    };
    this.childrensAnxietyAssessment.q18 = {
      answer: this.childrensAnxietyForm.controls['q18'].value,
    };
    this.childrensAnxietyAssessment.q19 = {
      answer: this.childrensAnxietyForm.controls['q19'].value,
    };
    this.childrensAnxietyAssessment.q20 = {
      answer: this.childrensAnxietyForm.controls['q20'].value,
    };
    this.childrensAnxietyAssessment.q21 = {
      answer: this.childrensAnxietyForm.controls['q21'].value,
    };
    this.childrensAnxietyAssessment.q22 = {
      answer: this.childrensAnxietyForm.controls['q22'].value,
    };
    this.childrensAnxietyAssessment.q23 = {
      answer: this.childrensAnxietyForm.controls['q23'].value,
    };
    this.childrensAnxietyAssessment.q24 = {
      answer: this.childrensAnxietyForm.controls['q24'].value,
    };
    this.childrensAnxietyAssessment.q25 = {
      answer: this.childrensAnxietyForm.controls['q25'].value,
    };
    this.childrensAnxietyAssessment.q26 = {
      answer: this.childrensAnxietyForm.controls['q26'].value,
    };
    this.childrensAnxietyAssessment.q27 = {
      answer: this.childrensAnxietyForm.controls['q27'].value,
    };
    this.childrensAnxietyAssessment.q28 = {
      answer: this.childrensAnxietyForm.controls['q28'].value,
    };
    this.childrensAnxietyAssessment.q29 = {
      answer: this.childrensAnxietyForm.controls['q29'].value,
    };
    this.childrensAnxietyAssessment.q30 = {
      answer: this.childrensAnxietyForm.controls['q30'].value,
    };
    this.childrensAnxietyAssessment.q31 = {
      answer: this.childrensAnxietyForm.controls['q31'].value,
    };
    this.childrensAnxietyAssessment.q32 = {
      answer: this.childrensAnxietyForm.controls['q32'].value,
    };
    this.childrensAnxietyAssessment.q33 = {
      answer: this.childrensAnxietyForm.controls['q33'].value,
    };
    this.childrensAnxietyAssessment.q34 = {
      answer: this.childrensAnxietyForm.controls['q34'].value,
    };
    this.childrensAnxietyAssessment.q35 = {
      answer: this.childrensAnxietyForm.controls['q35'].value,
    };
    this.childrensAnxietyAssessment.q36 = {
      answer: this.childrensAnxietyForm.controls['q36'].value,
    };
    this.childrensAnxietyAssessment.q37 = {
      answer: this.childrensAnxietyForm.controls['q37'].value,
    };
    this.childrensAnxietyAssessment.q38 = {
      answer: this.childrensAnxietyForm.controls['q38'].value,
    };
    this.childrensAnxietyAssessment.q39 = {
      answer: this.childrensAnxietyForm.controls['q39'].value,
    };
    this.childrensAnxietyAssessment.q40 = {
      answer: this.childrensAnxietyForm.controls['q40'].value,
    };
    this.childrensAnxietyAssessment.q41 = {
      answer: this.childrensAnxietyForm.controls['q41'].value,
    };
    this.childrensAnxietyAssessment.q42 = {
      answer: this.childrensAnxietyForm.controls['q42'].value,
    };
    this.childrensAnxietyAssessment.q43 = {
      answer: this.childrensAnxietyForm.controls['q43'].value,
    };
    this.childrensAnxietyAssessment.q44 = {
      answer: this.childrensAnxietyForm.controls['q44'].value,
    };
    this.childrensAnxietyAssessment.q45 = {
      answer: this.childrensAnxietyForm.controls['q45'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.childrensAnxietyAssessment.accessCode = this.accessCode;

      this.childrensAnxietyApiService
        .addGuestChildrensAnxiety(this.childrensAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Childrens Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Childrens Anxiety Assessment'
            );
          }
        );
    } else {
      this.childrensAnxietyApiService
        .addChildrensAnxiety(this.childrensAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Childrens Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Childrens Anxiety Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [{ type: 'required', message: '1. I worry about things. is required' }],
    q2: [
      { type: 'required', message: '2. I am scared of the dark. is required' },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. When I have a problem, I get a funny feeling in my stomach. is required',
      },
    ],
    q4: [{ type: 'required', message: '4. I feel afraid. is required' }],
    q5: [
      {
        type: 'required',
        message:
          '5. I would feel afraid of being on my own at home. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message: '6. I feel scared when I have to take a test. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. I feel afraid if I have to use public toilets or bathrooms. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. I worry about being away from my parents. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. I feel afraid that I will make a fool of myself in front of people. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. I worry that I will do badly at my school work. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. I am popular amongst other kids my own age. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. I worry that something awful will happen to someone in my family. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          "13. I suddenly feel as if I can't breathe when there is no reason for this.is required",
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I have to keep checking that I have done things right (like the switch is off, or the door is locked). is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message: '15. I feel scared if I have to sleep on my own. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. I have trouble going to school in the mornings because I feel nervous or afraid. is required',
      },
    ],
    q17: [
      { type: 'required', message: '17. I am good at sports. is required' },
    ],
    q18: [
      { type: 'required', message: '18. I am scared of dogs. is required' },
    ],
    q19: [
      {
        type: 'required',
        message:
          "19. I can't seem to get bad or silly thoughts out of my head.is required",
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. When I have a problem, my heart beats really fast. is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          '21. I suddenly start to tremble or shake when there is no reason for this. is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. I worry that something bad will happen to me. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. I am scared of going to the doctors or dentists. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. When I have a problem, I feel shaky. is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. I am scared of being in high places or elevators (lifts). is required',
      },
    ],
    q26: [{ type: 'required', message: '26. I am a good person. is required' }],
    q27: [
      {
        type: 'required',
        message:
          '27. I have to think of special thoughts to stop bad things from happening (like numbers or words). is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. I feel scared if I have to travel in the car, or on a bus or a train. is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message: '29. I worry what other people think of me. is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. I am afraid of being in crowded places (like shopping centers, the movies, buses, busy playgrounds. is required',
      },
    ],
    q31: [{ type: 'required', message: '31. I feel happy. is required' }],
    q32: [
      {
        type: 'required',
        message:
          '32. All of a sudden I feel really scared for no reason at all. is required',
      },
    ],
    q33: [
      {
        type: 'required',
        message: '33. I am scared of insects or spiders. is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message:
          '34. I suddenly become dizzy or faint when there is no reason for this. is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message:
          '35. I feel afraid if I have to talk in front of my class. is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message:
          '36. My heart suddenly starts to beat too quickly for no reason. is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message:
          '37. I worry that I will suddenly get a scared feeling when there is nothing to be afraid of. is required',
      },
    ],
    q38: [{ type: 'required', message: '38. I like myself. is required' }],
    q39: [
      {
        type: 'required',
        message:
          '39. I am afraid of being in small closed places, like tunnels or small rooms. is required',
      },
    ],
    q40: [
      {
        type: 'required',
        message:
          '40. I have to do some things over and over again (like washing my hands, cleaning or putting things in a certain order). is required',
      },
    ],
    q41: [
      {
        type: 'required',
        message:
          '41. I get bothered by bad or silly thoughts or pictures in my mind. is required',
      },
    ],
    q42: [
      {
        type: 'required',
        message:
          '42. I have to do some things in just the right way to stop bad things happening. is required',
      },
    ],
    q43: [
      {
        type: 'required',
        message: '43. I am proud of my school work. is required',
      },
    ],
    q44: [
      {
        type: 'required',
        message:
          '44. I would feel scared if I had to stay away from home overnight. is required',
      },
    ],
    q45: [
      {
        type: 'required',
        message:
          '45. Is there something else that you are really afraid of? How often are you afraid of this thing? is required',
      },
    ],
  };

  private buildForm() {
    this.childrensAnxietyForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.childrensAnxietyForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.childrensAnxietyForm) {
      return;
    }
    const form = this.childrensAnxietyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
