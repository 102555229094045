import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { CaseTreatmentAdmissionLegalApiService } from 'src/app/_services/case/treatment/admission/casetreatmentadmissionlegal.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { getCountiesByState } from 'src/app/shared/utilities/states-counties/counties';

@Component({
  selector: 'app-case-admission-legal',
  templateUrl: './case-admission-legal.component.html',
  styleUrl: './case-admission-legal.component.css',
})
export class CaseAdmissionLegalComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() readOnly: boolean = false;

  public casetreatmentadmissionlegalForm: FormGroup;
  public counties: string[] = [];
  public legalStatusOptions = [
    '180 Day Commitment',
    '30 Day Commitment',
    '90 Day Commitment',
    'Case Pending',
    'Community Sentencing',
    'Court Order for observation and evaluation',
    'Court ordered juveniles (INT); DJJ custody',
    'Court ordered juveniles (INT); parents retain custody',
    'Deferred Prosecution',
    'Deferred Sentence',
    'Emergency Commitment',
    'Furlough/Rehabilitative Leave',
    'Incarcerated',
    'No Response',
    'None/ No Involvement',
    'Parole',
    'Probation',
    'Protective Custody',
    'Tite 12-Not guilty by reason of insanity (NGRI), (GBMI)',
    'Unknown',
  ];
  public convictionOptions = ['Yes', 'No', 'Refuse to Answer'];
  public levelOfConvictionOptions = [
    '1st Offense',
    '2 or more offenses',
    'Refused to Answer',
  ];
  public processing: boolean = false;

  constructor(
    public casetreatmentadmissionlegalApiService: CaseTreatmentAdmissionLegalApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.counties = getCountiesByState('TN');
    this.loadCaseTreatmentAdmissionLegal();
  }

  buildForm() {
    this.casetreatmentadmissionlegalForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      legalStatus: new FormControl('', Validators.required),
      numberOfArrestsInLifetime: new FormControl('', [Validators.min(0)]),
      numberOfArrestsInPast12Months: new FormControl('', [Validators.min(0)]),
      numberOfArrestsInPast30Days: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),
      convictedOfDui: new FormControl('', Validators.required),
      isConvictionCurrent: new FormControl(''),
      levelOfConviction: new FormControl(''),
      countyName: new FormControl(''),
      judgeName: new FormControl(''),
    });
  }

  loadCaseTreatmentAdmissionLegal() {
    this.casetreatmentadmissionlegalApiService
      .getCaseTreatmentAdmissionLegalDetails(this.case.id)
      .subscribe((data) => {
        if (data?.data) {
          Object.keys(data.data).forEach((key) => {
            if (this.casetreatmentadmissionlegalForm.controls[key]) {
              this.casetreatmentadmissionlegalForm.controls[key].setValue(
                data.data[key]
              );
            }

            if (this.readOnly) {
              this.casetreatmentadmissionlegalForm.controls[key].disable();
            }
          });
        }
      });
  }

  onCaseTreatmentAdmissionLegalFormSubmit() {
    if (this.casetreatmentadmissionlegalForm.invalid) {
      this.casetreatmentadmissionlegalForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    const formData = { ...this.casetreatmentadmissionlegalForm.value };

    this.casetreatmentadmissionlegalApiService
      .updateCaseTreatmentAdmissionLegal(formData)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  convictedChanged(event) {
    if (event.value === 'Yes') {
      this.casetreatmentadmissionlegalForm.controls[
        'isConvictionCurrent'
      ].addValidators(Validators.required);
      this.casetreatmentadmissionlegalForm.controls[
        'isConvictionCurrent'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls[
        'levelOfConviction'
      ].addValidators(Validators.required);
      this.casetreatmentadmissionlegalForm.controls[
        'levelOfConviction'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls['countyName'].addValidators(
        Validators.required
      );
      this.casetreatmentadmissionlegalForm.controls[
        'countyName'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls['judgeName'].addValidators(
        Validators.required
      );
      this.casetreatmentadmissionlegalForm.controls[
        'judgeName'
      ].updateValueAndValidity();
    } else {
      this.casetreatmentadmissionlegalForm.controls[
        'isConvictionCurrent'
      ].setValue(null);
      this.casetreatmentadmissionlegalForm.controls[
        'isConvictionCurrent'
      ].clearValidators();
      this.casetreatmentadmissionlegalForm.controls[
        'isConvictionCurrent'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls[
        'levelOfConviction'
      ].setValue(null);
      this.casetreatmentadmissionlegalForm.controls[
        'levelOfConviction'
      ].clearValidators();
      this.casetreatmentadmissionlegalForm.controls[
        'levelOfConviction'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls['countyName'].setValue(
        null
      );
      this.casetreatmentadmissionlegalForm.controls[
        'countyName'
      ].clearValidators();
      this.casetreatmentadmissionlegalForm.controls[
        'countyName'
      ].updateValueAndValidity();

      this.casetreatmentadmissionlegalForm.controls['judgeName'].setValue(null);
      this.casetreatmentadmissionlegalForm.controls[
        'judgeName'
      ].clearValidators();
      this.casetreatmentadmissionlegalForm.controls[
        'judgeName'
      ].updateValueAndValidity();
    }
  }
}
