import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { ITreatmentReviewProfile } from 'src/app/_models/case/treatment/review/treatmentreviewprofile.model';
import { TreatmentReviewProfileApiService } from 'src/app/_services/case/treatment/review/treatmentreviewprofile.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-treatment-review-profile',
  templateUrl: './treatment-review-profile.component.html',
  styleUrl: './treatment-review-profile.component.css',
})
export class TreatmentReviewProfileComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;
  @Input() selectedReview: ITreatmentReview;

  constructor(
    public treatmentreviewprofileApiService: TreatmentReviewProfileApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public action: string;
  public treatmentreviewprofileForm: FormGroup;
  treatmentreviewprofile: ITreatmentReviewProfile;

  processing: boolean = false;

  public ngOnInit() {
    this.buildForm();

    this.loadTreatmentReviewProfile();
  }

  loadTreatmentReviewProfile() {
    this.treatmentreviewprofileApiService
      .getTreatmentReviewProfileDetails(this.selectedReview.id)
      .subscribe((treatmentreviewprofile) => {
        if (treatmentreviewprofile?.data) {
          this.treatmentreviewprofileForm.controls['id'].setValue(
            treatmentreviewprofile.data.id
          );
          this.treatmentreviewprofileForm.controls['organizationId'].setValue(
            treatmentreviewprofile.data.organizationId
          );
          this.treatmentreviewprofileForm.controls['patientId'].setValue(
            treatmentreviewprofile.data.patientId
          );
          this.treatmentreviewprofileForm.controls['caseId'].setValue(
            treatmentreviewprofile.data.caseId
          );
          if (treatmentreviewprofile.data.planId) {
            this.treatmentreviewprofileForm.controls['planId'].setValue(
              treatmentreviewprofile.data.planId
            );
          }

          this.treatmentreviewprofileForm.controls['reviewId'].setValue(
            treatmentreviewprofile.data.reviewId
          );
          this.treatmentreviewprofileForm.controls[
            'documentsReviewed'
          ].setValue(treatmentreviewprofile.data.documentsReviewed);
          this.treatmentreviewprofileForm.controls[
            'assessmentsConductedDuringReviewPeriod'
          ].setValue(
            treatmentreviewprofile.data.assessmentsConductedDuringReviewPeriod
          );
        }
      });
  }

  onTreatmentReviewProfileFormSubmit() {
    if (this.treatmentreviewprofileForm.invalid) {
      this.treatmentreviewprofileForm.markAllAsTouched();
      return;
    }

    this.treatmentreviewprofile = Object.assign(
      {},
      this.treatmentreviewprofile
    );
    this.treatmentreviewprofile = Object.assign(
      this.treatmentreviewprofile,
      this.treatmentreviewprofileForm.value
    );

    this.processing = true;
    this.treatmentreviewprofileApiService
      .updateTreatmentReviewProfile(this.treatmentreviewprofile)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentreviewprofileForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planId: new FormControl(
        this.selectedReview.planId,
        Validators.compose([Validators.required])
      ),
      reviewId: new FormControl(
        this.selectedReview.id,
        Validators.compose([Validators.required])
      ),
      documentsReviewed: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      assessmentsConductedDuringReviewPeriod: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });

    if (this.selectedPlan.status != 'Active') {
      this.treatmentreviewprofileForm.disable();
    }
  }
}
