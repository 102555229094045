import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Case, Patient } from 'src/app/_models';
import { ICaseTreatmentAsam } from 'src/app/_models/case/treatment/admission/casetreatmentasam.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { CaseTreatmentAsamApiService } from 'src/app/_services/case/treatment/admission/casetreatmentasam.service';
import { TreatmentProgramApiService } from 'src/app/_services/case/treatment/discharge/treatmentprogram.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-case-asam-admission-ongoing',
  templateUrl: './case-asam-admission-ongoing.component.html',
  styleUrls: ['./case-asam-admission-ongoing.component.css'],
})
export class CaseAsamAdmissionOngoingComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() recordType: string;
  @Input() selectedReview: ITreatmentReview;
  @Input() selectedProgram: ITreatmentProgram;
  @Input() readOnly: boolean = false;
  action: string = '';
  casetreatmentasam: ICaseTreatmentAsam;

  public casetreatmentasamForm: FormGroup;
  public riskLevelOptions = ['0', '1', '2', '3', '4'];
  public careLevelOptions = [
    'Opioid Treatment Program',
    '2.1 - Intensive Outpatient Services, in conjunction with a Level 3.1 program',
    '2.1 - Intensive Outpatient Services, Co-Occurring Capable, in conjunction with a Level 3.1 program',
    '2.1 - Intensive Outpatient Services, Co-Occurring Enhanced, in conjunction with a Level 3.1 program',
    '1 - Outpatient Services (and consider recovery residence)',
    '1 - Outpatient Services, Co-Occurring Enhanced (and consider recovery residence) program',
    '0.5',
    'III.2',
    'Drug Court Program',
    'I.0',
    'II.1',
    'II.3',
    'III.1',
    'III.3',
    'III.5',
    'III.7',
    'IV',
    'N/A',
  ];
  public clinicalOverrideOptions = [
    'N/A',
    'Lack of insurance benefits',
    'Managed care refusal',
    'Clinical Judgement',
    'Patient Opinion',
    'Level of care not available',
    'Legal issues',
    'Other',
  ];
  public processing: boolean = false;
  public isModalWindow: boolean = false;
  public programOptions: any[] = [];

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CaseAsamAdmissionOngoingComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    public casetreatmentasamApiService: CaseTreatmentAsamApiService,
    public treatmentprogramApiService: TreatmentProgramApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.action = data?.action;
      this.case = data.case;
      this.patient = data.patient;
      this.recordType = data.recordType;
      this.isModalWindow = true;
      this.casetreatmentasam = data.casetreatmentasam;
    }
  }

  ngOnInit() {
    this.buildForm();

    if (this.action != 'ADD') {
      this.loadCaseTreatmentAsam();
    }
  }

  buildForm() {
    this.casetreatmentasamForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      recordType: new FormControl(this.recordType, Validators.required),

      acuteIntoxicationLevelOfRisk: new FormControl('', Validators.required),
      acuteIntoxicationLevelOfCare: new FormControl('', Validators.required),
      acuteIntoxicationComments: new FormControl(''),

      bioMedicalLevelOfRisk: new FormControl('', Validators.required),
      bioMedicalLevelOfCare: new FormControl('', Validators.required),
      bioMedicalComments: new FormControl(''),

      emcLevelOfRisk: new FormControl('', Validators.required),
      emcLevelOfCare: new FormControl('', Validators.required),
      emcComments: new FormControl(''),

      readinessToChangeLevelOfRisk: new FormControl('', Validators.required),
      readinessToChangeLevelOfCare: new FormControl('', Validators.required),
      readinessToChangeComments: new FormControl(''),

      relapsePotentialLevelOfRisk: new FormControl('', Validators.required),
      relapsePotentialLevelOfCare: new FormControl('', Validators.required),
      relapsePotentialComments: new FormControl(''),

      recoveryLevelOfRisk: new FormControl('', Validators.required),
      recoveryLevelOfCare: new FormControl('', Validators.required),
      recoveryComments: new FormControl(''),

      recommendedLevelOfCare: new FormControl('', Validators.required),
      actualLevelOfCare: new FormControl('', Validators.required),
      clinicalOverride: new FormControl(''),

      programId: new FormControl(this.selectedProgram?.id),
      program: new FormControl(''),
      programName: new FormControl(''),
      comments: new FormControl(''),
    });
  }

  loadCaseTreatmentAsam() {
    this.casetreatmentasamApiService
      .getCaseTreatmentAsamDetails(
        this.casetreatmentasam?.id,
        this.case?.id,
        this.selectedReview?.id,
        this.recordType,
        this.selectedProgram?.id
      )
      .subscribe((casetreatmentasam) => {
        if (casetreatmentasam?.data) {
          Object.keys(casetreatmentasam.data).forEach((key) => {
            if (this.casetreatmentasamForm.controls[key]) {
              this.casetreatmentasamForm.controls[key].setValue(
                casetreatmentasam.data[key]
              );

              if (this.readOnly) {
                this.casetreatmentasamForm.controls[key].disable();
              }
            }
          });

          const selectedProgram = this.programOptions.find(
            (program) =>
              program.id ===
              this.casetreatmentasamForm.controls['programId'].value
          );
          if (selectedProgram) {
            this.casetreatmentasamForm.controls['program'].setValue(
              selectedProgram
            );
            this.casetreatmentasamForm.controls['programName'].setValue(
              selectedProgram.programName
            );
          }
        }
      });
  }

  onCaseTreatmentAsamFormSubmit() {
    if (this.casetreatmentasamForm.invalid) {
      this.casetreatmentasamForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    const formData = { ...this.casetreatmentasamForm.value };

    this.casetreatmentasamApiService
      .updateCaseTreatmentAsam(formData)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;

          if (this.isModalWindow) {
            this.dialogRef.close('success');
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }
}
