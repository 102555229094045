import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { AssessmentApiService, ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-add-assessment-goal-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddAssessmentGoalDialogComponent implements OnInit {
  action: string = '';
  selectedAssessmentType: string = '';
  editAssessmentDetails: IAssessmentGoal;

  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  isLoadingResults: boolean = false;
  assessmentDetailError: boolean = false;
  assessmentDetails: any = [];

  // Mat-Stepper
  selectedQuestion: any;
  selectedAnswer: any = [];

  assessmentGoalForm: FormGroup;
  processing: boolean = false;
  showFollowUp: boolean = false;

  // Inerface
  assessmentGoal: IAssessmentGoal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAssessmentGoalDialogComponent>,
    public assessmentGoalApiService: MemberGoalsHttpService,
    private formBuilder: FormBuilder,
    private assessmentApiService: AssessmentApiService,
    private toastMessageService: ToastMessageService,
    private ref: ChangeDetectorRef
  ) {
    this.action = data.action;
    this.selectedAssessmentType = data.selectedAssessmentType;
    if (this.action === 'EDIT') {
      this.editAssessmentDetails = data.goalDetails;
    }
  }

  public ngOnInit() {
    this.buildForm();
    // this.getAssessmentDetails();

    if (this.action === 'EDIT') {
      this.assessmentGoalForm.controls['id'].setValue(
        this.editAssessmentDetails.id
      );
      this.assessmentGoalForm.controls['organizationId'].setValue(
        this.editAssessmentDetails.organizationId
      );
      this.assessmentGoalForm.controls['careProviderId'].setValue(
        this.editAssessmentDetails.careProviderId
      );
      this.assessmentGoalForm.controls['assessmentType'].setValue(
        this.editAssessmentDetails.assessmentType
      );
      this.assessmentGoalForm.controls['question'].setValue(
        this.editAssessmentDetails.question
      );
      this.assessmentGoalForm.controls['answers'].setValue(
        this.editAssessmentDetails.answers
      );
      this.assessmentGoalForm.controls['description'].setValue(
        this.editAssessmentDetails.description
      );

      for (var objective of this.editAssessmentDetails.objectives) {
        this.createObjective(
          objective.objectiveDescription,
          objective.targetNumber,
          objective.targetType
        );
      }

      this.assessmentGoalForm.controls['objectives'].setValue(
        this.editAssessmentDetails.objectives
      );

      this.assessmentGoalForm.controls['status'].setValue(
        this.editAssessmentDetails.status
      );
    } else {
      this.createObjective('', '', '');
    }
  }

  // public getAssessmentDetails() {
  //   this.isLoadingResults = true;

  //   this.assessmentApiService
  //     .getAssessmentDetails(this.selectedAssessmentType)
  //     .subscribe(
  //       (response) => {
  //         this.isLoadingResults = false;
  //         if (response && response.data) {
  //           this.assessmentDetails = response.data.fields;
  //           // If the action is of type edit
  //           if (this.action === 'EDIT') {
  //             this.loadSelectedQA();
  //           }
  //         } else {
  //           this.assessmentDetailError = true;
  //           this.assessmentDetails = [];
  //         }
  //       },
  //       (error) => {
  //         this.isLoadingResults = false;
  //         this.toastMessageService.displayErrorMessage(
  //           'Error: Failed to load assessment details.'
  //         );
  //       }
  //     );
  // }

  // loadSelectedQA() {
  //   let questionIndex = this.assessmentDetails.findIndex((question) => {
  //     if (
  //       question.title == this.assessmentGoalForm.controls['question'].value
  //     ) {
  //       return true;
  //     }
  //   });

  //   this.selectedQuestion = questionIndex;
  //   this.selectedAnswer = this.assessmentGoalForm.controls['answers'].value;
  //   this.changeFollowUp();
  // }

  buildForm() {
    this.assessmentGoalForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      careProviderId: new FormControl(null),
      assessmentType: this.selectedAssessmentType,

      classfieldName: new FormControl(),
      question: new FormControl(),
      answers: new FormControl(),

      description: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      objectives: this.formBuilder.array([]),
      status: new FormControl('Active', Validators.required),
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  // Change the stepper to go to next step
  nextStep(stepper: MatStepper) {
    stepper.next();
  }

  objectives(): FormArray {
    return this.assessmentGoalForm.get('objectives') as FormArray;
  }

  createObjective(
    objectiveDesc: string,
    targetNumber: string,
    targetType: string
  ) {
    let objective = new FormGroup({
      objectiveDescription: new FormControl(objectiveDesc, Validators.required),
      targetNumber: new FormControl(targetNumber),
      targetType: new FormControl(targetType),
    });
    this.objectives().push(objective);
  }

  removeObjective(index: number) {
    let currentObjectiveSize = this.objectives().value.length;
    if (currentObjectiveSize == 1) {
      this.toastMessageService.displayInfoMessage(
        'A minimum of 1 objective is required'
      );
      return;
    }
    this.objectives().removeAt(index);
  }

  // Submitting the goal form
  submitForm() {
    this.processing = true;

    this.assessmentGoal = Object.assign({}, this.assessmentGoal);
    this.assessmentGoal = Object.assign(
      this.assessmentGoal,
      this.assessmentGoalForm.value
    );

    // if (this.assessmentDetails[this.selectedQuestion]) {
    //   this.assessmentGoal.question =
    //     this.assessmentDetails[this.selectedQuestion].title;
    //   this.assessmentGoal.classfieldName =
    //     this.assessmentDetails[this.selectedQuestion].name;
    //   this.assessmentGoal.answers = this.selectedAnswer;
    // }

    // let objectives = [];
    // this.assessmentGoalForm.controls.objectives.value.forEach(element => {
    //   objectives.push(element.objective);
    // });

    // this.assessmentGoal.objectives = objectives;

    if (this.action === 'ADD') {
      this.assessmentGoalApiService
        .addAssessmentGoal(this.assessmentGoal)
        .subscribe(
          (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'New goal has been added.'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add new goal.'
            );
          }
        );
    } else if (this.action === 'EDIT') {
      this.assessmentGoalApiService
        .updateAssessmentGoal(this.assessmentGoal)
        .subscribe(
          (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Goal has been updated.'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the goal.'
            );
          }
        );
    }
  }

  // Follow Up
  changeFollowUp() {
    if (this.assessmentGoalForm.controls['followUp'].value === 'Yes') {
      this.showFollowUp = true;
    } else {
      this.showFollowUp = false;
      this.assessmentGoalForm.controls['followUpNumber'].clearValidators();
      this.assessmentGoalForm.controls['followUpNumber'].reset();
      this.assessmentGoalForm.controls['followUpType'].clearValidators();
      this.assessmentGoalForm.controls['followUpType'].reset();
    }
  }
}
