import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Patient } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-claims-details',
  templateUrl: './claims-details.component.html',
  styleUrl: './claims-details.component.css',
})
export class ClaimsDetailsComponent implements OnInit {
  patient: Patient;

  hasClaimsViewAccess: boolean = false;
  hasClaimsEditAccess: boolean = false;
  isBPSAEnforced: boolean = false;

  isLoadingDetails: boolean = false;
  currentTimezone: string;
  claimId: string;
  parent: string;
  prevTab: string;

  claimDetails: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService,
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.route.queryParamMap.subscribe((param) => {
      this.prevTab = param.get('prevTab');
      this.parent = param.get('parent');
    });

    // Load Permissions
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          if (orgConfig && orgConfig?.features) {
            this.isBPSAEnforced = orgConfig?.features.includes('BPSA_ENFORCED');
          }

          this.permissionsSandbox.permissions$.subscribe((response) => {
            this.hasClaimsViewAccess = hasAccess(
              this.keycloakService,
              'CLAIMS_VIEW',
              response,
              null
            );
            this.hasClaimsEditAccess = hasAccess(
              this.keycloakService,
              'CLAIMS_EDIT',
              response,
              null
            );
          });
        });
      }
    });

    // Load Claim Details
    this.loadClaimDetails();
  }

  loadClaimDetails() {
    this.isLoadingDetails = true;
    this.claimsApiService.getClaimDetails(this.claimId).subscribe({
      next: (response) => {
        // Get claim details
        this.claimDetails = response.data;

        // Populate member data
        this.patient = Object.assign({}, this.patient);
        this.patient.id = this.claimDetails.therapySession.patientId;
        this.patient.organizationId =
          this.claimDetails.therapySession.organizationId;
        this.patient.facilityId =
          this.claimDetails.therapySession.billingProviderRefId;
        this.patient.firstName =
          this.claimDetails.therapySession.intakeMemberData.firstName;
        this.patient.middleName =
          this.claimDetails.therapySession.intakeMemberData.middleName;
        this.patient.lastName =
          this.claimDetails.therapySession.intakeMemberData.lastName;
        this.patient.dateOfBirth =
          this.claimDetails.therapySession.intakeMemberData.dateOfBirth;
        this.patient.gender =
          this.claimDetails.therapySession.intakeMemberData.gender;
        this.patient.addressLine1 =
          this.claimDetails.therapySession.intakeMemberData.addressLine1;
        this.patient.addressLine2 =
          this.claimDetails.therapySession.intakeMemberData.addressLine2;
        this.patient.city =
          this.claimDetails.therapySession.intakeMemberData.city;
        this.patient.state =
          this.claimDetails.therapySession.intakeMemberData.state;
        this.patient.zip =
          this.claimDetails.therapySession.intakeMemberData.zip;

        this.isLoadingDetails = false;
      },
      error: (error) => {
        this.isLoadingDetails = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim details'
        );
      },
    });
  }

  reloadTherapySession(event) {
    if (event.type === 'reload_claim') {
      this.loadClaimDetails();
      window.scrollTo(0, 0);
    } else {
      this.claimDetails.therapySession = event.therapySession;

      if (event.type === 'deselect') {
        if (this.parent === 'claims') {
          this.router.navigate(['/claims']);
        } else {
          this.router.navigate([`/main/member/${this.patient.id}/CLAIMS`]);
        }
      }
    }
  }

  viewHistory() {
    this.router.navigate([`/claims/history/${this.claimId}`], {
      queryParams: {
        parent: this.parent,
        prevTab: this.prevTab,
      },
    });
  }

  viewPaymentHistory() {
    this.router.navigate(
      [`/remittances/remit/paymenthistory/${this.claimId}`],
      {
        queryParams: {
          parent: 'claimsdetails',
          subParent: this.parent,
        },
      }
    );
  }

  fileOtherClaim() {
    this.router.navigate([`/claims/secondary/${this.claimId}`], {
      queryParams: {
        parent: 'claims',
      },
    });
  }
}
