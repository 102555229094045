import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IZungDepressionScale } from 'src/app/_models/assessments/depression/zungdepressionscale.model';
import {
  ToastMessageService,
  ZungDepressionScaleApiService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-zung-depression-scale',
  templateUrl: './zung-depression-scale.component.html',
  styleUrls: ['./zung-depression-scale.component.css'],
})
export class ZungDepressionScaleComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public zungDepressionScaleApiService: ZungDepressionScaleApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public zungDepressionScaleForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  zungDepressionScaleAssessment: IZungDepressionScale;
  zungDepressionScaleAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.zungDepressionScaleForm, this.errors);

    this.loadZungDepressionScaleAssessment();
  }

  loadZungDepressionScaleAssessment() {
    this.zungDepressionScaleForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.zungDepressionScaleApiService
        .getZungDepressionScaleDetails(this.assessmentId)
        .subscribe((zungDepressionScaleAssessment) => {
          if (zungDepressionScaleAssessment.data) {
            this.zungDepressionScaleForm.controls[
              'downHeartedAndBlue'
            ].setValue(
              zungDepressionScaleAssessment.data.downHeartedAndBlue.answer
            );
            this.zungDepressionScaleForm.controls['feelBestInMorning'].setValue(
              zungDepressionScaleAssessment.data.feelBestInMorning.answer
            );
            this.zungDepressionScaleForm.controls['feelLikeCrying'].setValue(
              zungDepressionScaleAssessment.data.feelLikeCrying.answer
            );
            this.zungDepressionScaleForm.controls[
              'troubleSleepingAtNight'
            ].setValue(
              zungDepressionScaleAssessment.data.troubleSleepingAtNight.answer
            );
            this.zungDepressionScaleForm.controls['eatAsPlanned'].setValue(
              zungDepressionScaleAssessment.data.eatAsPlanned.answer
            );
            this.zungDepressionScaleForm.controls['enjoySex'].setValue(
              zungDepressionScaleAssessment.data.enjoySex.answer
            );
            this.zungDepressionScaleForm.controls['losingWeight'].setValue(
              zungDepressionScaleAssessment.data.losingWeight.answer
            );
            this.zungDepressionScaleForm.controls[
              'troubleWithConstipation'
            ].setValue(
              zungDepressionScaleAssessment.data.troubleWithConstipation.answer
            );
            this.zungDepressionScaleForm.controls[
              'heartBeatsFasterThanUsual'
            ].setValue(
              zungDepressionScaleAssessment.data.heartBeatsFasterThanUsual
                .answer
            );
            this.zungDepressionScaleForm.controls[
              'getTiredForNoReason'
            ].setValue(
              zungDepressionScaleAssessment.data.getTiredForNoReason.answer
            );
            this.zungDepressionScaleForm.controls['mindIsClear'].setValue(
              zungDepressionScaleAssessment.data.mindIsClear.answer
            );
            this.zungDepressionScaleForm.controls['easyToDoThings'].setValue(
              zungDepressionScaleAssessment.data.easyToDoThings.answer
            );
            this.zungDepressionScaleForm.controls[
              'restlessAndCantKeepStill'
            ].setValue(
              zungDepressionScaleAssessment.data.restlessAndCantKeepStill.answer
            );
            this.zungDepressionScaleForm.controls[
              'hopefulAboutFuture'
            ].setValue(
              zungDepressionScaleAssessment.data.hopefulAboutFuture.answer
            );
            this.zungDepressionScaleForm.controls[
              'irritableThanUsual'
            ].setValue(
              zungDepressionScaleAssessment.data.irritableThanUsual.answer
            );
            this.zungDepressionScaleForm.controls[
              'easyToMakeDecisions'
            ].setValue(
              zungDepressionScaleAssessment.data.easyToMakeDecisions.answer
            );
            this.zungDepressionScaleForm.controls[
              'feelUsefulAndNeeded'
            ].setValue(
              zungDepressionScaleAssessment.data.feelUsefulAndNeeded.answer
            );
            this.zungDepressionScaleForm.controls['lifeIsPrettyFull'].setValue(
              zungDepressionScaleAssessment.data.lifeIsPrettyFull.answer
            );
            this.zungDepressionScaleForm.controls[
              'othersWouldBeBetterIffIfIWereDead'
            ].setValue(
              zungDepressionScaleAssessment.data
                .othersWouldBeBetterIffIfIWereDead.answer
            );
            this.zungDepressionScaleForm.controls[
              'enjoyTheThingsIUsedToDo'
            ].setValue(
              zungDepressionScaleAssessment.data.enjoyTheThingsIUsedToDo.answer
            );

            this.riskScore = zungDepressionScaleAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.zungDepressionScaleForm.invalid) {
      this.zungDepressionScaleForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.zungDepressionScaleAssessment = Object.assign(
      {},
      this.zungDepressionScaleAssessment
    );
    this.zungDepressionScaleAssessment.patientId =
      this.zungDepressionScaleForm.controls['patientId'].value;
    this.zungDepressionScaleAssessment.sessionId =
      this.zungDepressionScaleForm.controls['sessionId'].value;
    this.zungDepressionScaleAssessment.assessmentRequestId =
      this.zungDepressionScaleForm.controls['assessmentRequestId'].value;
    this.zungDepressionScaleAssessment.downHeartedAndBlue = {
      answer: this.zungDepressionScaleForm.controls['downHeartedAndBlue'].value,
    };
    this.zungDepressionScaleAssessment.feelBestInMorning = {
      answer: this.zungDepressionScaleForm.controls['feelBestInMorning'].value,
    };
    this.zungDepressionScaleAssessment.feelLikeCrying = {
      answer: this.zungDepressionScaleForm.controls['feelLikeCrying'].value,
    };
    this.zungDepressionScaleAssessment.troubleSleepingAtNight = {
      answer:
        this.zungDepressionScaleForm.controls['troubleSleepingAtNight'].value,
    };
    this.zungDepressionScaleAssessment.eatAsPlanned = {
      answer: this.zungDepressionScaleForm.controls['eatAsPlanned'].value,
    };
    this.zungDepressionScaleAssessment.enjoySex = {
      answer: this.zungDepressionScaleForm.controls['enjoySex'].value,
    };
    this.zungDepressionScaleAssessment.losingWeight = {
      answer: this.zungDepressionScaleForm.controls['losingWeight'].value,
    };
    this.zungDepressionScaleAssessment.troubleWithConstipation = {
      answer:
        this.zungDepressionScaleForm.controls['troubleWithConstipation'].value,
    };
    this.zungDepressionScaleAssessment.heartBeatsFasterThanUsual = {
      answer:
        this.zungDepressionScaleForm.controls['heartBeatsFasterThanUsual']
          .value,
    };
    this.zungDepressionScaleAssessment.getTiredForNoReason = {
      answer:
        this.zungDepressionScaleForm.controls['getTiredForNoReason'].value,
    };
    this.zungDepressionScaleAssessment.mindIsClear = {
      answer: this.zungDepressionScaleForm.controls['mindIsClear'].value,
    };
    this.zungDepressionScaleAssessment.easyToDoThings = {
      answer: this.zungDepressionScaleForm.controls['easyToDoThings'].value,
    };
    this.zungDepressionScaleAssessment.restlessAndCantKeepStill = {
      answer:
        this.zungDepressionScaleForm.controls['restlessAndCantKeepStill'].value,
    };
    this.zungDepressionScaleAssessment.hopefulAboutFuture = {
      answer: this.zungDepressionScaleForm.controls['hopefulAboutFuture'].value,
    };
    this.zungDepressionScaleAssessment.irritableThanUsual = {
      answer: this.zungDepressionScaleForm.controls['irritableThanUsual'].value,
    };
    this.zungDepressionScaleAssessment.easyToMakeDecisions = {
      answer:
        this.zungDepressionScaleForm.controls['easyToMakeDecisions'].value,
    };
    this.zungDepressionScaleAssessment.feelUsefulAndNeeded = {
      answer:
        this.zungDepressionScaleForm.controls['feelUsefulAndNeeded'].value,
    };
    this.zungDepressionScaleAssessment.lifeIsPrettyFull = {
      answer: this.zungDepressionScaleForm.controls['lifeIsPrettyFull'].value,
    };
    this.zungDepressionScaleAssessment.othersWouldBeBetterIffIfIWereDead = {
      answer:
        this.zungDepressionScaleForm.controls[
          'othersWouldBeBetterIffIfIWereDead'
        ].value,
    };
    this.zungDepressionScaleAssessment.enjoyTheThingsIUsedToDo = {
      answer:
        this.zungDepressionScaleForm.controls['enjoyTheThingsIUsedToDo'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.zungDepressionScaleAssessment.accessCode = this.accessCode;

      this.zungDepressionScaleApiService
        .addGuestZungDepressionScale(this.zungDepressionScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Zung Depression Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Zung Depression Scale'
            );
          }
        );
    } else {
      this.zungDepressionScaleApiService
        .addZungDepressionScale(this.zungDepressionScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Zung Depression Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Zung Depression Scale'
            );
          }
        );
    }
  }

  public validation_messages = {
    downHeartedAndBlue: [
      { type: 'required', message: 'I feel down hearted and blue is required' },
    ],
    feelBestInMorning: [
      {
        type: 'required',
        message: 'Morning is when I feel the best is required',
      },
    ],
    feelLikeCrying: [
      {
        type: 'required',
        message: 'I have crying spells or feel like it is required',
      },
    ],
    troubleSleepingAtNight: [
      {
        type: 'required',
        message: 'I have trouble sleeping at night is required',
      },
    ],
    eatAsPlanned: [
      { type: 'required', message: 'I eat as much as I used to is required' },
    ],
    enjoySex: [{ type: 'required', message: 'I still enjoy sex is required' }],
    losingWeight: [
      {
        type: 'required',
        message: 'I notice that I am losing weight is required',
      },
    ],
    troubleWithConstipation: [
      {
        type: 'required',
        message: 'I have trouble with constipation is required',
      },
    ],
    heartBeatsFasterThanUsual: [
      {
        type: 'required',
        message: 'My heart beats faster than usual is required',
      },
    ],
    getTiredForNoReason: [
      { type: 'required', message: 'I get tired for no reason is required' },
    ],
    mindIsClear: [
      {
        type: 'required',
        message: 'My mind is as clear as it used to be is required',
      },
    ],
    easyToDoThings: [
      {
        type: 'required',
        message: 'I find it easy to do the things I used to is required',
      },
    ],
    restlessAndCantKeepStill: [
      {
        type: 'required',
        message: "I am restless and can't keep still is required",
      },
    ],
    hopefulAboutFuture: [
      {
        type: 'required',
        message: 'I feel hopeful about the future is required',
      },
    ],
    irritableThanUsual: [
      {
        type: 'required',
        message: 'I am more irritable than usual is required',
      },
    ],
    easyToMakeDecisions: [
      {
        type: 'required',
        message: 'I find it easy to make decisions is required',
      },
    ],
    feelUsefulAndNeeded: [
      {
        type: 'required',
        message: 'I feel that I am useful and needed is required',
      },
    ],
    lifeIsPrettyFull: [
      { type: 'required', message: 'My life is pretty full is required' },
    ],
    othersWouldBeBetterIffIfIWereDead: [
      {
        type: 'required',
        message:
          'I feel that others would be better off if I were dead is required',
      },
    ],
    enjoyTheThingsIUsedToDo: [
      {
        type: 'required',
        message: 'I still enjoy the things I used to do is required',
      },
    ],
  };

  private buildForm() {
    this.zungDepressionScaleForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      downHeartedAndBlue: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelBestInMorning: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelLikeCrying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      troubleSleepingAtNight: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      eatAsPlanned: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      enjoySex: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      losingWeight: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      troubleWithConstipation: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      heartBeatsFasterThanUsual: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      getTiredForNoReason: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      mindIsClear: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      easyToDoThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      restlessAndCantKeepStill: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hopefulAboutFuture: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      irritableThanUsual: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      easyToMakeDecisions: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelUsefulAndNeeded: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifeIsPrettyFull: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      othersWouldBeBetterIffIfIWereDead: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      enjoyTheThingsIUsedToDo: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.zungDepressionScaleForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.zungDepressionScaleForm) {
      return;
    }
    const form = this.zungDepressionScaleForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
