import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { TreatmentReviewApiService } from 'src/app/_services/case/treatment/review/treatmentreview.service';
import { AddEditTreatmentReviewsDialogComponent } from './dialog/add-edit-treatment-reviews-dialog/add-edit-treatment-reviews-dialog.component';

@Component({
  selector: 'app-treatment-reviews',
  templateUrl: './treatment-reviews.component.html',
  styleUrl: './treatment-reviews.component.css',
})
export class TreatmentReviewsComponent implements OnInit {
  @Input() displayAddButton: boolean = true;
  @Input() selectedPlan: ITreatmentPlan;
  @Output() selectedReview = new EventEmitter<any>();

  displayedColumns = [
    'reviewDate',
    'reviewPeriodStart',
    'reviewPeriodEnd',
    'status',
    'actions',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  patientId: string;

  constructor(
    public dialog: MatDialog,
    public treatmentreviewApiService: TreatmentReviewApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    if (this.selectedPlan.status != 'Active') {
      this.displayAddButton = false;
    }

    this.loadTreatmentReviewList();
  }

  startNewReview() {
    const dialogRef = this.dialog.open(AddEditTreatmentReviewsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        treatmentreview: null,
        selectedPlan: this.selectedPlan,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentReviewList();
      }
    });
  }

  editReview(treatmentreviews: ITreatmentReview) {
    const dialogRef = this.dialog.open(AddEditTreatmentReviewsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        treatmentreview: treatmentreviews,
        selectedPlan: this.selectedPlan,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentReviewList();
      }
    });
  }

  public loadTreatmentReviewList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.treatmentreviewApiService
      .getTreatmentReviewList(this.data.per_page, this.data.page)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTreatmentReviewList();
  }

  setSelectedReview(selectedReview: any) {
    this.selectedReview.emit(selectedReview);
  }
}
