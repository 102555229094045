import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import { IIntakeHealthInfo } from 'src/app/_models/session/intake/intakehealthinfo.model';
import { ToastMessageService } from 'src/app/_services';
import { IntakeHealthInfoApiService } from 'src/app/_services/session/intake/intakehealthinfo.service';

@Component({
  selector: 'app-intake-health-info',
  templateUrl: './intake-health-info.component.html',
  styleUrls: ['./intake-health-info.component.css'],
})
export class IntakeHealthInfoComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  intakehealthinfo: IIntakeHealthInfo;
  public action: string;
  public intakehealthinfoForm: FormGroup;
  key: string;
  processing: boolean = false;

  constructor(
    public intakehealthinfoApiService: IntakeHealthInfoApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.intakehealthinfo = this.therapySession.intakeHealthInfo;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();

    this.loadIntakeHealthInfo();
  }

  loadIntakeHealthInfo() {
    if (this.intakehealthinfo) {
      this.intakehealthinfoForm.controls['rateYourHealth'].setValue(
        this.intakehealthinfo.rateYourHealth
      );
      this.intakehealthinfoForm.controls['slepingHabits'].setValue(
        this.intakehealthinfo.slepingHabits
      );
      this.intakehealthinfoForm.controls['eatingPatterns'].setValue(
        this.intakehealthinfo.eatingPatterns
      );
      this.intakehealthinfoForm.controls['sadneddGriefDepression'].setValue(
        this.intakehealthinfo.sadneddGriefDepression
      );
      this.intakehealthinfoForm.controls['suicidalThoughts'].setValue(
        this.intakehealthinfo.suicidalThoughts
      );
      this.intakehealthinfoForm.controls['anxietyPanicAttacksPhobias'].setValue(
        this.intakehealthinfo.anxietyPanicAttacksPhobias
      );
      this.intakehealthinfoForm.controls['chronicPain'].setValue(
        this.intakehealthinfo.chronicPain
      );
      this.intakehealthinfoForm.controls['alcoholMoreThanOnceAWeek'].setValue(
        this.intakehealthinfo.alcoholMoreThanOnceAWeek
      );
      this.intakehealthinfoForm.controls['recreationalDrugUse'].setValue(
        this.intakehealthinfo.recreationalDrugUse
      );
    }

    // Initiate Auto Save
    this.initiateAutoSave();
  }

  onIntakeHealthInfoFormSubmit() {
    this.intakehealthinfo = Object.assign({}, this.intakehealthinfo);
    this.intakehealthinfo = Object.assign(
      this.intakehealthinfo,
      this.intakehealthinfoForm.value
    );

    this.processing = true;

    this.intakehealthinfoApiService
      .updateIntakeHealthInfo(this.intakehealthinfo)
      .subscribe({
        next: (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated health information'
          // );
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.intakehealthinfoForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      rateYourHealth: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      slepingHabits: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      eatingPatterns: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      sadneddGriefDepression: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      suicidalThoughts: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      anxietyPanicAttacksPhobias: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      chronicPain: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      alcoholMoreThanOnceAWeek: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      recreationalDrugUse: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
    });
  }

  initiateAutoSave() {
    this.intakehealthinfoForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onIntakeHealthInfoFormSubmit();
      });
  }
}
