import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAssessmentGoalNotes } from 'src/app/_models/assessments/assessmentgoalnotes.model';
import { ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-add-assessment-goal-notes',
  templateUrl: './add-assessment-goal-notes.component.html',
  styleUrls: ['./add-assessment-goal-notes.component.css'],
})
export class AddAssessmentGoalNotesComponent implements OnInit {
  goal: any;
  goalNotes: any;
  editIndex: number;
  action: string = '';
  goalNotesForm: FormGroup;
  processing: boolean = false;
  isPatient: boolean = false;

  newNote: IAssessmentGoalNotes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAssessmentGoalNotesComponent>,
    private goalsApiService: MemberGoalsHttpService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    this.goal = data.goal;
    this.action = data.action;
    this.isPatient = data.isPatient;

    if (this.isPatient) {
      this.goalNotes = data.goal.patientNotes;
    } else {
      this.goalNotes = data.goal.physicianNotes;
    }

    if (this.action === 'EDIT') {
      this.editIndex = data.index;
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.goalNotesForm.controls['note'].setValue(
        this.goalNotes[this.editIndex].notes
      );
      this.goalNotesForm.controls['visibleByPatient'].setValue(
        this.goalNotes[this.editIndex].visibleByPatient
      );
    }
  }

  buildForm() {
    this.goalNotesForm = this.formBuilder.group({
      note: new FormControl('', Validators.required),
      visibleByPatient: new FormControl(this.isPatient ? true : false),
    });
  }

  submitGoalNotesForm() {
    if (this.goalNotesForm.invalid) {
      return;
    }

    this.processing = true;
    if (this.goalNotes == null) {
      this.goalNotes = [];
    }

    if (this.action === 'ADD') {
      let newNoteDetails = this.goalNotesForm.controls['note'].value;

      this.newNote = Object.assign({}, this.newNote);
      this.newNote.notes = newNoteDetails;
      this.newNote.visibleByPatient =
        this.goalNotesForm.controls['visibleByPatient'].value;
      this.newNote.createdDate = new Date();
      this.newNote.modifiedDate = new Date();

      this.goalNotes.push(this.newNote);
    } else if (this.action === 'EDIT') {
      this.goalNotes[this.editIndex].notes =
        this.goalNotesForm.controls['note'].value;

      this.goalNotes[this.editIndex].visibleByPatient =
        this.goalNotesForm.controls['visibleByPatient'].value;

      this.goalNotes[this.editIndex].modifiedDate = new Date();
    }

    if (this.isPatient) {
      this.goal.patientNotes = this.goalNotes;
    } else {
      this.goal.physicianNotes = this.goalNotes;
    }

    this.goalsApiService.updateGoalNotes(this.goal, this.isPatient).subscribe(
      (response) => {
        this.processing = false;
        this.toastMessageService.displaySuccessMessage(
          'Goal ' + this.goal.goalIndex + ' notes have been updated.'
        );
        this.dialogRef.close({
          response: 'success',
          newNoteList: this.goalNotes,
        });
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to update goal notes.'
        );
      }
    );
  }
}
