import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { IMemberPharmacy } from 'src/app/_models/patient/memberpharmacy.model';
import {
  MemberPharmacyApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-add-edit-memberpharmacy-dialog',
  templateUrl: './add-edit-memberpharmacy-dialog.component.html',
  styleUrl: './add-edit-memberpharmacy-dialog.component.css',
})
export class AddEditMemberpharmacyDialogComponent implements OnInit {
  patientId: string = '';
  action: string;
  memberpharmacyForm: FormGroup;
  memberpharmacy: IMemberPharmacy;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };
  processing = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditMemberpharmacyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private memberPharmacyApiService: MemberPharmacyApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.patientId = data.patientId;
    this.memberpharmacy = data.memberpharmacy;
  }

  public ngOnInit() {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.memberpharmacyForm.controls['id'].setValue(this.memberpharmacy.id);
      this.memberpharmacyForm.controls['organizationId'].setValue(
        this.memberpharmacy.organizationId
      );
      this.memberpharmacyForm.controls['patientId'].setValue(
        this.memberpharmacy.patientId
      );
      this.memberpharmacyForm.controls['pharmacyName'].setValue(
        this.memberpharmacy.pharmacyName
      );
      this.memberpharmacyForm.controls['address1'].setValue(
        this.memberpharmacy.address1
      );
      this.memberpharmacyForm.controls['address2'].setValue(
        this.memberpharmacy.address2
      );
      this.memberpharmacyForm.controls['city'].setValue(
        this.memberpharmacy.city
      );
      this.memberpharmacyForm.controls['state'].setValue(
        this.memberpharmacy.state
      );
      this.memberpharmacyForm.controls['zip'].setValue(this.memberpharmacy.zip);
      this.memberpharmacyForm.controls['phoneNumber'].setValue(
        this.memberpharmacy.phoneNumber
      );
      this.memberpharmacyForm.controls['faxNumber'].setValue(
        this.memberpharmacy.faxNumber
      );
      this.memberpharmacyForm.controls['primaryPharmacy'].setValue(
        this.memberpharmacy.primaryPharmacy
      );
      this.memberpharmacyForm.controls['status'].setValue(
        this.memberpharmacy.status
      );
    }
  }

  private buildForm() {
    this.memberpharmacyForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patientId, Validators.required),
      pharmacyName: new FormControl('', Validators.required),
      address1: new FormControl('', Validators.required),
      address2: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zip: new FormControl(
        '',
        Validators.compose([
          RxwebValidators.mask({ mask: '99999' }),
          Validators.required,
        ])
      ),
      phoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      faxNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      status: new FormControl('Active', Validators.required),
      primaryPharmacy: new FormControl(false, Validators.required),
    });
  }

  onMemberPharmacyFormSubmit() {
    this.memberpharmacy = Object.assign({}, this.memberpharmacy);
    this.memberpharmacy = Object.assign(
      this.memberpharmacy,
      this.memberpharmacyForm.value
    );
    this.processing = true;

    if (this.action === 'EDIT') {
      this.memberPharmacyApiService
        .updateMemberPharmacy(this.memberpharmacy)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Sucessfully updated the pharmacy details'
            );
            this.processing = false;
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the pharmacy details'
            );
          },
        });
    } else {
      this.memberPharmacyApiService
        .addMemberPharmacy(this.memberpharmacy)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Sucessfully created new pharmacy'
            );
            this.processing = false;
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to create new pharmacy'
            );
          },
        });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.memberpharmacyForm.controls['city'].setValue(
            component.long_name
          );
          break;

        case 'administrative_area_level_1': {
          //state
          this.memberpharmacyForm.controls['state'].setValue(
            component.short_name
          );
          break;
        }

        case 'postal_code': {
          this.memberpharmacyForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.memberpharmacyForm.controls['address1'].setValue(addressField);
  }
}
