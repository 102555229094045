import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITraumaRelatedShame } from 'src/app/_models/assessments/stress/traumarelatedshame.model';
import {
  ToastMessageService,
  TraumaRelatedShameApiService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-trauma-related-shame',
  templateUrl: './trauma-related-shame.component.html',
  styleUrls: ['./trauma-related-shame.component.css'],
})
export class TraumaRelatedShameComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public traumaRelatedShameApiService: TraumaRelatedShameApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public traumaRelatedShameForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  traumaRelatedShameAssessment: ITraumaRelatedShame;
  traumaRelatedShameAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.traumaRelatedShameForm, this.errors);

    this.loadTraumaRelatedShameAssessment();
  }

  loadTraumaRelatedShameAssessment() {
    this.traumaRelatedShameForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.traumaRelatedShameApiService
        .getTraumaRelatedShameDetails(this.assessmentId)
        .subscribe((traumaRelatedShameAssessment) => {
          if (traumaRelatedShameAssessment.data) {
            this.traumaRelatedShameForm.controls['q1'].setValue(
              traumaRelatedShameAssessment.data.q1.answer
            );
            this.traumaRelatedShameForm.controls['q2'].setValue(
              traumaRelatedShameAssessment.data.q2.answer
            );
            this.traumaRelatedShameForm.controls['q3'].setValue(
              traumaRelatedShameAssessment.data.q3.answer
            );
            this.traumaRelatedShameForm.controls['q4'].setValue(
              traumaRelatedShameAssessment.data.q4.answer
            );
            this.traumaRelatedShameForm.controls['q5'].setValue(
              traumaRelatedShameAssessment.data.q5.answer
            );
            this.traumaRelatedShameForm.controls['q6'].setValue(
              traumaRelatedShameAssessment.data.q6.answer
            );
            this.traumaRelatedShameForm.controls['q7'].setValue(
              traumaRelatedShameAssessment.data.q7.answer
            );
            this.traumaRelatedShameForm.controls['q8'].setValue(
              traumaRelatedShameAssessment.data.q8.answer
            );
            this.traumaRelatedShameForm.controls['q9'].setValue(
              traumaRelatedShameAssessment.data.q9.answer
            );
            this.traumaRelatedShameForm.controls['q10'].setValue(
              traumaRelatedShameAssessment.data.q10.answer
            );
            this.traumaRelatedShameForm.controls['q11'].setValue(
              traumaRelatedShameAssessment.data.q11.answer
            );
            this.traumaRelatedShameForm.controls['q12'].setValue(
              traumaRelatedShameAssessment.data.q12.answer
            );
            this.traumaRelatedShameForm.controls['q13'].setValue(
              traumaRelatedShameAssessment.data.q13.answer
            );
            this.traumaRelatedShameForm.controls['q14'].setValue(
              traumaRelatedShameAssessment.data.q14.answer
            );
            this.traumaRelatedShameForm.controls['q15'].setValue(
              traumaRelatedShameAssessment.data.q15.answer
            );
            this.traumaRelatedShameForm.controls['q16'].setValue(
              traumaRelatedShameAssessment.data.q16.answer
            );
            this.traumaRelatedShameForm.controls['q17'].setValue(
              traumaRelatedShameAssessment.data.q17.answer
            );
            this.traumaRelatedShameForm.controls['q18'].setValue(
              traumaRelatedShameAssessment.data.q18.answer
            );
            this.traumaRelatedShameForm.controls['q19'].setValue(
              traumaRelatedShameAssessment.data.q19.answer
            );
            this.traumaRelatedShameForm.controls['q20'].setValue(
              traumaRelatedShameAssessment.data.q20.answer
            );
            this.traumaRelatedShameForm.controls['q21'].setValue(
              traumaRelatedShameAssessment.data.q21.answer
            );
            this.traumaRelatedShameForm.controls['q22'].setValue(
              traumaRelatedShameAssessment.data.q22.answer
            );
            this.traumaRelatedShameForm.controls['q23'].setValue(
              traumaRelatedShameAssessment.data.q23.answer
            );
            this.traumaRelatedShameForm.controls['q24'].setValue(
              traumaRelatedShameAssessment.data.q24.answer
            );

            this.riskScore = traumaRelatedShameAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.traumaRelatedShameForm.invalid) {
      this.traumaRelatedShameForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.traumaRelatedShameAssessment = Object.assign(
      {},
      this.traumaRelatedShameAssessment
    );
    this.traumaRelatedShameAssessment.patientId =
      this.traumaRelatedShameForm.controls['patientId'].value;
    this.traumaRelatedShameAssessment.sessionId =
      this.traumaRelatedShameForm.controls['sessionId'].value;
    this.traumaRelatedShameAssessment.assessmentRequestId =
      this.traumaRelatedShameForm.controls['assessmentRequestId'].value;
    this.traumaRelatedShameAssessment.q1 = {
      answer: this.traumaRelatedShameForm.controls['q1'].value,
    };
    this.traumaRelatedShameAssessment.q2 = {
      answer: this.traumaRelatedShameForm.controls['q2'].value,
    };
    this.traumaRelatedShameAssessment.q3 = {
      answer: this.traumaRelatedShameForm.controls['q3'].value,
    };
    this.traumaRelatedShameAssessment.q4 = {
      answer: this.traumaRelatedShameForm.controls['q4'].value,
    };
    this.traumaRelatedShameAssessment.q5 = {
      answer: this.traumaRelatedShameForm.controls['q5'].value,
    };
    this.traumaRelatedShameAssessment.q6 = {
      answer: this.traumaRelatedShameForm.controls['q6'].value,
    };
    this.traumaRelatedShameAssessment.q7 = {
      answer: this.traumaRelatedShameForm.controls['q7'].value,
    };
    this.traumaRelatedShameAssessment.q8 = {
      answer: this.traumaRelatedShameForm.controls['q8'].value,
    };
    this.traumaRelatedShameAssessment.q9 = {
      answer: this.traumaRelatedShameForm.controls['q9'].value,
    };
    this.traumaRelatedShameAssessment.q10 = {
      answer: this.traumaRelatedShameForm.controls['q10'].value,
    };
    this.traumaRelatedShameAssessment.q11 = {
      answer: this.traumaRelatedShameForm.controls['q11'].value,
    };
    this.traumaRelatedShameAssessment.q12 = {
      answer: this.traumaRelatedShameForm.controls['q12'].value,
    };
    this.traumaRelatedShameAssessment.q13 = {
      answer: this.traumaRelatedShameForm.controls['q13'].value,
    };
    this.traumaRelatedShameAssessment.q14 = {
      answer: this.traumaRelatedShameForm.controls['q14'].value,
    };
    this.traumaRelatedShameAssessment.q15 = {
      answer: this.traumaRelatedShameForm.controls['q15'].value,
    };
    this.traumaRelatedShameAssessment.q16 = {
      answer: this.traumaRelatedShameForm.controls['q16'].value,
    };
    this.traumaRelatedShameAssessment.q17 = {
      answer: this.traumaRelatedShameForm.controls['q17'].value,
    };
    this.traumaRelatedShameAssessment.q18 = {
      answer: this.traumaRelatedShameForm.controls['q18'].value,
    };
    this.traumaRelatedShameAssessment.q19 = {
      answer: this.traumaRelatedShameForm.controls['q19'].value,
    };
    this.traumaRelatedShameAssessment.q20 = {
      answer: this.traumaRelatedShameForm.controls['q20'].value,
    };
    this.traumaRelatedShameAssessment.q21 = {
      answer: this.traumaRelatedShameForm.controls['q21'].value,
    };
    this.traumaRelatedShameAssessment.q22 = {
      answer: this.traumaRelatedShameForm.controls['q22'].value,
    };
    this.traumaRelatedShameAssessment.q23 = {
      answer: this.traumaRelatedShameForm.controls['q23'].value,
    };
    this.traumaRelatedShameAssessment.q24 = {
      answer: this.traumaRelatedShameForm.controls['q24'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.traumaRelatedShameAssessment.accessCode = this.accessCode;

      this.traumaRelatedShameApiService
        .addGuestTraumaRelatedShame(this.traumaRelatedShameAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Trauma Related Shame Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Trauma Related Shame Assessment'
            );
          }
        );
    } else {
      this.traumaRelatedShameApiService
        .addTraumaRelatedShame(this.traumaRelatedShameAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Trauma Related Shame Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Trauma Related Shame Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. As a result of my traumatic experience, I have lost respect for myself. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Because of what happened to me, others find me less desirable. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. I am ashamed of myself because of what happened to me. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. As a result of my traumatic experience, others have seen parts of me that they want nothing to do with. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. As a result of my traumatic experience, I cannot accept myself. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. If others knew what happened to me, they would view me as inferior. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. If others knew what happened to me, they would be disgusted with me. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. I am ashamed of the way I behaved during my traumatic experience. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. I am so ashamed of what happened to me that I sometimes want to escape from myself. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. As a result of my traumatic experience, I find myself less desirable. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. I am ashamed of the way I felt during my traumatic experience. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. If others knew what had happened to me, they would look down on me. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. As a result of my traumatic experience, there are parts of me that I want to get rid of. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. If others knew what happened to me, they would not like me. is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. Because of my traumatic experience, I feel inferior to others. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. If others knew what happened to me, they would be ashamed of me. is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. If others knew what happened to me, they would find me unacceptable. is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. As a result of my traumatic experience, a part of me has been exposed that others find shameful. is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          '19. If otherslist knew how I behaved during my traumatic experience, they would be ashamed of me. is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. My traumatic experience has revealed a part of me that I am ashamed of. is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          "21. As a result of my traumatic experience, I don't like myself. is required",
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. If others knew how I felt during my traumatic experience, they would be ashamed of me. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. Because of what happened to me, I am disgusted with myself. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '24. I am so ashamed of what happened to me that I sometimes want to become invisible to others. is required',
      },
    ],
  };

  private buildForm() {
    this.traumaRelatedShameForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.traumaRelatedShameForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.traumaRelatedShameForm) {
      return;
    }
    const form = this.traumaRelatedShameForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
