import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAggressionQuestionnaire } from 'src/app/_models/assessments/aggression/aggressionquestionnaire.model';
import {
  AggressionQuestionnaireApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-aggression-questionnaire',
  templateUrl: './aggression-questionnaire.component.html',
  styleUrls: ['./aggression-questionnaire.component.css'],
})
export class AggressionQuestionnaireComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public aggressionQuestionnaireApiService: AggressionQuestionnaireApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public paScore: number;
  public vaScore: number;
  public hScore: number;
  public aScore: number;
  public aggressionQuestionnaireForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  aggressionQuestionnaireAssessment: IAggressionQuestionnaire;
  aggressionQuestionnaireAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.aggressionQuestionnaireForm,
      this.errors
    );

    this.loadAggressionQuestionnaireAssessment();
  }

  loadAggressionQuestionnaireAssessment() {
    this.aggressionQuestionnaireForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.aggressionQuestionnaireApiService
        .getAggressionQuestionnaireDetails(this.assessmentId)
        .subscribe((aggressionQuestionnaireAssessment) => {
          if (aggressionQuestionnaireAssessment.data) {
            this.aggressionQuestionnaireForm.controls['q1'].setValue(
              aggressionQuestionnaireAssessment.data.q1.answer
            );
            this.aggressionQuestionnaireForm.controls['q2'].setValue(
              aggressionQuestionnaireAssessment.data.q2.answer
            );
            this.aggressionQuestionnaireForm.controls['q3'].setValue(
              aggressionQuestionnaireAssessment.data.q3.answer
            );
            this.aggressionQuestionnaireForm.controls['q4'].setValue(
              aggressionQuestionnaireAssessment.data.q4.answer
            );
            this.aggressionQuestionnaireForm.controls['q5'].setValue(
              aggressionQuestionnaireAssessment.data.q5.answer
            );
            this.aggressionQuestionnaireForm.controls['q6'].setValue(
              aggressionQuestionnaireAssessment.data.q6.answer
            );
            this.aggressionQuestionnaireForm.controls['q7'].setValue(
              aggressionQuestionnaireAssessment.data.q7.answer
            );
            this.aggressionQuestionnaireForm.controls['q8'].setValue(
              aggressionQuestionnaireAssessment.data.q8.answer
            );
            this.aggressionQuestionnaireForm.controls['q9'].setValue(
              aggressionQuestionnaireAssessment.data.q9.answer
            );
            this.aggressionQuestionnaireForm.controls['q10'].setValue(
              aggressionQuestionnaireAssessment.data.q10.answer
            );
            this.aggressionQuestionnaireForm.controls['q11'].setValue(
              aggressionQuestionnaireAssessment.data.q11.answer
            );
            this.aggressionQuestionnaireForm.controls['q12'].setValue(
              aggressionQuestionnaireAssessment.data.q12.answer
            );
            this.aggressionQuestionnaireForm.controls['q13'].setValue(
              aggressionQuestionnaireAssessment.data.q13.answer
            );
            this.aggressionQuestionnaireForm.controls['q14'].setValue(
              aggressionQuestionnaireAssessment.data.q14.answer
            );
            this.aggressionQuestionnaireForm.controls['q15'].setValue(
              aggressionQuestionnaireAssessment.data.q15.answer
            );
            this.aggressionQuestionnaireForm.controls['q16'].setValue(
              aggressionQuestionnaireAssessment.data.q16.answer
            );
            this.aggressionQuestionnaireForm.controls['q17'].setValue(
              aggressionQuestionnaireAssessment.data.q17.answer
            );
            this.aggressionQuestionnaireForm.controls['q18'].setValue(
              aggressionQuestionnaireAssessment.data.q18.answer
            );
            this.aggressionQuestionnaireForm.controls['q19'].setValue(
              aggressionQuestionnaireAssessment.data.q19.answer
            );
            this.aggressionQuestionnaireForm.controls['q20'].setValue(
              aggressionQuestionnaireAssessment.data.q20.answer
            );
            this.aggressionQuestionnaireForm.controls['q21'].setValue(
              aggressionQuestionnaireAssessment.data.q21.answer
            );
            this.aggressionQuestionnaireForm.controls['q22'].setValue(
              aggressionQuestionnaireAssessment.data.q22.answer
            );
            this.aggressionQuestionnaireForm.controls['q23'].setValue(
              aggressionQuestionnaireAssessment.data.q23.answer
            );
            this.aggressionQuestionnaireForm.controls['q24'].setValue(
              aggressionQuestionnaireAssessment.data.q24.answer
            );
            this.aggressionQuestionnaireForm.controls['q25'].setValue(
              aggressionQuestionnaireAssessment.data.q25.answer
            );
            this.aggressionQuestionnaireForm.controls['q26'].setValue(
              aggressionQuestionnaireAssessment.data.q26.answer
            );
            this.aggressionQuestionnaireForm.controls['q27'].setValue(
              aggressionQuestionnaireAssessment.data.q27.answer
            );
            this.aggressionQuestionnaireForm.controls['q28'].setValue(
              aggressionQuestionnaireAssessment.data.q28.answer
            );
            this.aggressionQuestionnaireForm.controls['q29'].setValue(
              aggressionQuestionnaireAssessment.data.q29.answer
            );

            this.paScore =
              aggressionQuestionnaireAssessment.data.props['pa-score'];
            this.vaScore =
              aggressionQuestionnaireAssessment.data.props['va-score'];
            this.hScore =
              aggressionQuestionnaireAssessment.data.props['h-score'];
            this.aScore =
              aggressionQuestionnaireAssessment.data.props['a-score'];
          }
        });
    }
  }

  onFormSubmit() {
    if (this.aggressionQuestionnaireForm.invalid) {
      this.aggressionQuestionnaireForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.aggressionQuestionnaireAssessment = Object.assign(
      {},
      this.aggressionQuestionnaireAssessment
    );
    this.aggressionQuestionnaireAssessment.patientId =
      this.aggressionQuestionnaireForm.controls['patientId'].value;
    this.aggressionQuestionnaireAssessment.sessionId =
      this.aggressionQuestionnaireForm.controls['sessionId'].value;
    this.aggressionQuestionnaireAssessment.assessmentRequestId =
      this.aggressionQuestionnaireForm.controls['assessmentRequestId'].value;
    this.aggressionQuestionnaireAssessment.q1 = {
      answer: this.aggressionQuestionnaireForm.controls['q1'].value,
    };
    this.aggressionQuestionnaireAssessment.q2 = {
      answer: this.aggressionQuestionnaireForm.controls['q2'].value,
    };
    this.aggressionQuestionnaireAssessment.q3 = {
      answer: this.aggressionQuestionnaireForm.controls['q3'].value,
    };
    this.aggressionQuestionnaireAssessment.q4 = {
      answer: this.aggressionQuestionnaireForm.controls['q4'].value,
    };
    this.aggressionQuestionnaireAssessment.q5 = {
      answer: this.aggressionQuestionnaireForm.controls['q5'].value,
    };
    this.aggressionQuestionnaireAssessment.q6 = {
      answer: this.aggressionQuestionnaireForm.controls['q6'].value,
    };
    this.aggressionQuestionnaireAssessment.q7 = {
      answer: this.aggressionQuestionnaireForm.controls['q7'].value,
    };
    this.aggressionQuestionnaireAssessment.q8 = {
      answer: this.aggressionQuestionnaireForm.controls['q8'].value,
    };
    this.aggressionQuestionnaireAssessment.q9 = {
      answer: this.aggressionQuestionnaireForm.controls['q9'].value,
    };
    this.aggressionQuestionnaireAssessment.q10 = {
      answer: this.aggressionQuestionnaireForm.controls['q10'].value,
    };
    this.aggressionQuestionnaireAssessment.q11 = {
      answer: this.aggressionQuestionnaireForm.controls['q11'].value,
    };
    this.aggressionQuestionnaireAssessment.q12 = {
      answer: this.aggressionQuestionnaireForm.controls['q12'].value,
    };
    this.aggressionQuestionnaireAssessment.q13 = {
      answer: this.aggressionQuestionnaireForm.controls['q13'].value,
    };
    this.aggressionQuestionnaireAssessment.q14 = {
      answer: this.aggressionQuestionnaireForm.controls['q14'].value,
    };
    this.aggressionQuestionnaireAssessment.q15 = {
      answer: this.aggressionQuestionnaireForm.controls['q15'].value,
    };
    this.aggressionQuestionnaireAssessment.q16 = {
      answer: this.aggressionQuestionnaireForm.controls['q16'].value,
    };
    this.aggressionQuestionnaireAssessment.q17 = {
      answer: this.aggressionQuestionnaireForm.controls['q17'].value,
    };
    this.aggressionQuestionnaireAssessment.q18 = {
      answer: this.aggressionQuestionnaireForm.controls['q18'].value,
    };
    this.aggressionQuestionnaireAssessment.q19 = {
      answer: this.aggressionQuestionnaireForm.controls['q19'].value,
    };
    this.aggressionQuestionnaireAssessment.q20 = {
      answer: this.aggressionQuestionnaireForm.controls['q20'].value,
    };
    this.aggressionQuestionnaireAssessment.q21 = {
      answer: this.aggressionQuestionnaireForm.controls['q21'].value,
    };
    this.aggressionQuestionnaireAssessment.q22 = {
      answer: this.aggressionQuestionnaireForm.controls['q22'].value,
    };
    this.aggressionQuestionnaireAssessment.q23 = {
      answer: this.aggressionQuestionnaireForm.controls['q23'].value,
    };
    this.aggressionQuestionnaireAssessment.q24 = {
      answer: this.aggressionQuestionnaireForm.controls['q24'].value,
    };
    this.aggressionQuestionnaireAssessment.q25 = {
      answer: this.aggressionQuestionnaireForm.controls['q25'].value,
    };
    this.aggressionQuestionnaireAssessment.q26 = {
      answer: this.aggressionQuestionnaireForm.controls['q26'].value,
    };
    this.aggressionQuestionnaireAssessment.q27 = {
      answer: this.aggressionQuestionnaireForm.controls['q27'].value,
    };
    this.aggressionQuestionnaireAssessment.q28 = {
      answer: this.aggressionQuestionnaireForm.controls['q28'].value,
    };
    this.aggressionQuestionnaireAssessment.q29 = {
      answer: this.aggressionQuestionnaireForm.controls['q29'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.aggressionQuestionnaireAssessment.accessCode = this.accessCode;

      this.aggressionQuestionnaireApiService
        .addGuestAggressionQuestionnaire(this.aggressionQuestionnaireAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Aggression Questionnaire'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Aggression Questionnaire'
            );
          }
        );
    } else {
      this.aggressionQuestionnaireApiService
        .addAggressionQuestionnaire(this.aggressionQuestionnaireAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Aggression Questionnaire'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Aggression Questionnaire'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. Some of my friends think I am a hothead. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. If I have to resort to violence to protect my rights, I will. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. When people are especially nice to me, I wonder what they want. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. I tell my friends openly when I disagree with them. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. I have become so mad that I have broken things. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. I can’t help getting into arguments when people disagree with me. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. I wonder why sometimes I feel so bitter about things. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. Once in a while, I can’t control the urge to strike another person. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. I am an even-tempered person. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. I am suspicious of overly friendly strangers. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. I have threatened people I know. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '12. I flare up quickly but get over it quickly. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Given enough provocation, I may hit another person. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. When people annoy me, I may tell them what I think of them. is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message: '15. I am sometimes eaten up with jealousy. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. I can think of no good reason for ever hitting a person. is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. At times I feel I have gotten a raw deal out of life. is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message: '18. I have trouble controlling my temper. is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message: '19. When frustrated, I let my irritation show. is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. I sometimes feel that people are laughing at me behind my back. is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message: '21. I often find myself disagreeing with people. is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message: '22. If somebody hits me, I hit back. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. I sometimes feel like a powder keg ready to explode. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. Other people always seem to get the breaks. is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. There are people who pushed me so far that we came to blows. is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message:
          '26. I know that "friends" talk about me behind my back. is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message:
          '27. My friends say that I’m somewhat argumentative. is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. Sometimes I fly off the handle for no good reason. is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          '29. I get into fights a little more than the average person. is required',
      },
    ],
  };

  private buildForm() {
    this.aggressionQuestionnaireForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.aggressionQuestionnaireForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.aggressionQuestionnaireForm) {
      return;
    }
    const form = this.aggressionQuestionnaireForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
