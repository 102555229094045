import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentDischargeProfile } from 'src/app/_models/case/treatment/discharge/treatmentdischargeprofile.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentDischargeProfileApiService } from 'src/app/_services/case/treatment/discharge/treatmentdischargeprofile.service';
import { DashboardsService } from 'src/app/_services/dashboards/dashboards.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-discharge-profile',
  templateUrl: './discharge-profile.component.html',
  styleUrl: './discharge-profile.component.css',
})
export class DischargeProfileComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedProgram: ITreatmentProgram;

  constructor(
    public treatmentdischargeprofileApiService: TreatmentDischargeProfileApiService,
    private formBuilder: FormBuilder,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService
  ) {}

  public action: string;
  public treatmentdischargeprofileForm: FormGroup;
  treatmentdischargeprofile: ITreatmentDischargeProfile;

  key: string;
  processing: boolean = false;

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  public ngOnInit() {
    this.buildForm();
    this.getStaffMembers();
  }

  loadTreatmentDischargeProfile() {
    this.treatmentdischargeprofileApiService
      .getTreatmentDischargeProfileDetails(this.selectedProgram.id)
      .subscribe((treatmentdischargeprofile) => {
        if (treatmentdischargeprofile?.data) {
          this.treatmentdischargeprofileForm.controls['id'].setValue(
            treatmentdischargeprofile.data.id
          );
          this.treatmentdischargeprofileForm.controls[
            'organizationId'
          ].setValue(treatmentdischargeprofile.data.organizationId);
          this.treatmentdischargeprofileForm.controls['patientId'].setValue(
            treatmentdischargeprofile.data.patientId
          );
          this.treatmentdischargeprofileForm.controls['caseId'].setValue(
            treatmentdischargeprofile.data.caseId
          );
          this.treatmentdischargeprofileForm.controls['programId'].setValue(
            treatmentdischargeprofile.data.programId
          );
          this.treatmentdischargeprofileForm.controls['dischargeDate'].setValue(
            mmddyyyyToDate(treatmentdischargeprofile.data.dischargeDate)
          );
          this.treatmentdischargeprofileForm.controls[
            'dateOfLastContact'
          ].setValue(
            mmddyyyyToDate(treatmentdischargeprofile.data.dateOfLastContact)
          );
          this.treatmentdischargeprofileForm.controls[
            'externalRecordId'
          ].setValue(treatmentdischargeprofile.data.externalRecordId);

          this.treatmentdischargeprofileForm.controls['staffId'].setValue(
            treatmentdischargeprofile.data.staffId
          );
          this.treatmentdischargeprofileForm.controls['staffName'].setValue(
            treatmentdischargeprofile.data.staffName
          );
          this.treatmentdischargeprofileForm.controls[
            'dischargeReferral'
          ].setValue(treatmentdischargeprofile.data.dischargeReferral);
          this.treatmentdischargeprofileForm.controls[
            'dischargeReason'
          ].setValue(treatmentdischargeprofile.data.dischargeReason);
          this.treatmentdischargeprofileForm.controls[
            'selfHelpProgramAttendance'
          ].setValue(treatmentdischargeprofile.data.selfHelpProgramAttendance);
        }
      });
  }

  onTreatmentDischargeProfileFormSubmit() {
    if (this.treatmentdischargeprofileForm.invalid) {
      this.treatmentdischargeprofileForm.markAllAsTouched();
      return;
    }
    this.treatmentdischargeprofile = Object.assign(
      {},
      this.treatmentdischargeprofile
    );
    this.treatmentdischargeprofile = Object.assign(
      this.treatmentdischargeprofile,
      this.treatmentdischargeprofileForm.value
    );
    if (this.treatmentdischargeprofile.dischargeDate !== '') {
      this.treatmentdischargeprofile.dischargeDate = formatDate(
        new Date(this.treatmentdischargeprofile.dischargeDate)
      );
    }
    if (this.treatmentdischargeprofile.dateOfLastContact !== '') {
      this.treatmentdischargeprofile.dateOfLastContact = formatDate(
        new Date(this.treatmentdischargeprofile.dateOfLastContact)
      );
    }

    this.processing = true;
    this.treatmentdischargeprofileApiService
      .updateTreatmentDischargeProfile(this.treatmentdischargeprofile)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentdischargeprofileForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      programId: new FormControl(
        this.selectedProgram.id,
        Validators.compose([Validators.required])
      ),
      dischargeDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      dateOfLastContact: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      externalRecordId: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      staffId: new FormControl('', Validators.required),
      staffName: new FormControl('', Validators.required),

      dischargeReferral: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      dischargeReason: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      selfHelpProgramAttendance: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });
  }

  getStaffMembers() {
    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
        this.loadTreatmentDischargeProfile();
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  providerSelected(event: any): void {
    const diagnosingClinicianId = event.value;

    let selectedProvider = this.providersList
      .filter((provider) => provider.id === diagnosingClinicianId)
      .at(0);
    const diagnosingClinicianName =
      selectedProvider.firstName + ' ' + selectedProvider.lastName;

    this.treatmentdischargeprofileForm.controls['staffName'].setValue(
      diagnosingClinicianName
    );
  }

  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }
}
