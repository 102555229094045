<div class="container">
  <div class="dialogTitle" style="padding-left: 10px; padding-right: 10px">
    <p matDialogTitle></p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-session-print
    [patient]="patient"
    [therapySession]="therapySession"
    [isBPSAEnforced]="isBPSAEnforced"
  ></app-session-print>
</div>
