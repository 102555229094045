<div class="issue-wrap">
  <div class="issue mat-elevation-z8">
    <div class="row">
      <!-- Description -->
      <div class="col-md-12">
        <!-- Heading -->
        <p
          style="font-size: 16px; font-weight: 400; color: #2196f3; width: 90%"
        >
          {{ carelog.message }}
        </p>

        <!-- Vital Information -->
        <div
          class="vitalGridContainer"
          *ngIf="carelog.vitalInfoAvailable && !isProcessing"
        >
          <mat-icon *ngIf="carelog.height">height</mat-icon>
          <label *ngIf="carelog.height" class="labelHeader"> Height </label>
          <label *ngIf="carelog.height" class="labelText">
            {{ carelog.height }} in
          </label>

          <mat-icon *ngIf="carelog.weight">monitor_weight</mat-icon>
          <label *ngIf="carelog.weight" class="labelHeader"> Weight </label>
          <label *ngIf="carelog.weight" class="labelText">
            {{ carelog.weight }}
          </label>

          <mat-icon *ngIf="carelog.bloodSugar">bloodtype</mat-icon>
          <label *ngIf="carelog.bloodSugar" class="labelHeader">
            Blood Sugar
          </label>
          <label *ngIf="carelog.bloodSugar" class="labelText">
            {{ carelog.bloodSugar }}
          </label>

          <mat-icon *ngIf="carelog.pulse">monitor_heart</mat-icon>
          <label *ngIf="carelog.pulse" class="labelHeader"> Pulse </label>
          <label *ngIf="carelog.pulse" class="labelText">
            {{ carelog.pulse }}
          </label>

          <mat-icon *ngIf="carelog.oxygenLevel">scatter_plot</mat-icon>
          <label *ngIf="carelog.oxygenLevel" class="labelHeader">
            Oxygen Saturation
          </label>
          <label *ngIf="carelog.oxygenLevel" class="labelText">
            {{ carelog.oxygenLevel }}
          </label>

          <mat-icon *ngIf="carelog.bodyTemperature">thermostat</mat-icon>
          <label *ngIf="carelog.bodyTemperature" class="labelHeader">
            Body Temperature
          </label>
          <label *ngIf="carelog.bodyTemperature" class="labelText">
            {{ carelog.bodyTemperature }}
          </label>

          <mat-icon
            *ngIf="carelog.bloodPressureHigh || carelog.bloodPressureLow"
          >
            tire_repair
          </mat-icon>
          <label
            *ngIf="carelog.bloodPressureHigh || carelog.bloodPressureLow"
            class="labelHeader"
          >
            Blood Pressure
          </label>
          <label
            *ngIf="carelog.bloodPressureHigh || carelog.bloodPressureLow"
            class="labelText"
          >
            {{ carelog.bloodPressureHigh }} /
            {{ carelog.bloodPressureLow }}
          </label>
        </div>

        <!-- Seizure Log -->
        <div
          class="twoGridContainer"
          *ngIf="carelog.seizureLog && !isProcessing"
        >
          <label class="labelHeader">Had Seizures?</label>
          <label class="labelText">
            {{ carelog.seizureLog.hadSeizure }}
          </label>

          <ng-container *ngIf="carelog.seizureLog.hadSeizure === 'Yes'">
            <label class="labelHeader">Start Time</label>
            <label class="labelText">
              {{ carelog.seizureLog.startTime | hours }}
            </label>

            <label class="labelHeader">End Time</label>
            <label class="labelText">
              {{ carelog.seizureLog.endTime | hours }}
            </label>

            <label class="labelHeader">Called 911?</label>
            <label class="labelText">
              {{ carelog.seizureLog.called911 ? 'Yes' : 'No' }}
            </label>
          </ng-container>
        </div>

        <!-- Bowel Movement -->
        <div
          class="twoGridContainer"
          *ngIf="carelog.bowelMovement && !isProcessing"
        >
          <label class="labelHeader">Had Bowel?</label>
          <label class="labelText">
            {{ carelog.bowelMovement.hadBowel }}
          </label>

          <ng-container *ngIf="carelog.bowelMovement.hadBowel === 'Yes'">
            <label class="labelHeader">Size</label>
            <label class="labelText">
              {{ carelog.bowelMovement.size | boweldescriptions }}
            </label>

            <label class="labelHeader">Color</label>
            <label class="labelText">
              {{ carelog.bowelMovement.color | boweldescriptions }}
            </label>

            <label class="labelHeader">Consistency</label>
            <label class="labelText">
              {{ carelog.bowelMovement.consistency | boweldescriptions }}
            </label>
          </ng-container>
        </div>

        <!-- Sleep Info -->
        <div class="row" *ngIf="carelog.sleepTimes && !isProcessing">
          <div
            class="col-6 col-sm-4 col-xl-3"
            *ngFor="let timeslot of carelog.sleepTimes"
            style="margin-bottom: 15px"
          >
            <mat-chip-option class="mat-chip-active">
              <div style="display: flex; align-items: center; padding: 5px">
                <mat-icon style="margin-right: 5px">hotel</mat-icon>
                {{ timeslot | hours }}
              </div>
            </mat-chip-option>
          </div>
        </div>

        <!-- Behavioral Incident -->
        <div class="row" *ngIf="carelog.behavioralIncident && !isProcessing">
          <div class="col-md-12">
            <app-carelog-behavioral-incident
              [displayTitle]="false"
              [patientId]="case.patientId"
              action="VIEW"
              [case]="case"
              [logDate]="logDate"
              [shift]="shift"
              [carelog]="carelog"
              [goals]="goals"
              [goalDrivenCare]="goalDrivenCare"
            ></app-carelog-behavioral-incident>
          </div>
        </div>

        <!-- Urine Drug Screening -->
        <div class="row" *ngIf="carelog.urinalDrugScreen && !isProcessing">
          <div class="col mat-elevation-z4" style="margin: 0px 15px">
            <div
              style="
                font-weight: 600;
                font-size: 14px;
                margin: 0px -15px 30px -15px;
                height: 50px;
                background-color: #eeeeee;
                padding: 5px;
              "
            >
              Specimen Validity Test Results
            </div>
            <ng-container *ngFor="let item of basicTests">
              <app-drug-screen-result
                [label]="item.label"
                [value]="carelog.urinalDrugScreen[item.key]"
                [normalCondition]="item.normalCondition"
              >
              </app-drug-screen-result>
            </ng-container>
          </div>
          <div class="col-md-9 mat-elevation-z4" style="padding: 0px 15px">
            <div
              style="
                font-weight: 600;
                font-size: 14px;
                margin: 0px -15px 30px -15px;
                height: 50px;
                background-color: #eeeeee;
                padding: 5px;
              "
            >
              Drug Test Results
            </div>
            <ng-container *ngFor="let item of drugTests">
              <app-drug-screen-result
                [label]="item.name"
                [code]="item.code"
                [value]="carelog.urinalDrugScreen[item.control]"
                [normalCondition]="item.normalCondition"
                *ngIf="
                  !item.isRequired && carelog.urinalDrugScreen[item.control]
                "
              >
              </app-drug-screen-result>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="carelog.customMessage && !isProcessing"
          style="margin-top: 10px"
        >
          <label class="labelHeader" style="text-decoration: underline">
            Additional Details
          </label>
          <br />
          <label class="labelText">&emsp;{{ carelog.customMessage }}</label>
        </div>

        <div
          *ngIf="carelog.goal && showGoal && !carelog.goals"
          style="margin-top: 10px"
        >
          <label class="labelHeader" style="text-decoration: underline">
            Goal
          </label>
          <br />
          <label class="labelText">&emsp; {{ carelog.goal }} </label>
        </div>

        <div
          *ngIf="
            (carelog.goals && carelog.goals.length > 0) ||
            (carelog.memberGoals && carelog.memberGoals.length > 0) ||
            (showGoal && carelog.goals && carelog.goals.length > 0)
          "
          style="margin-top: 10px"
        >
          <label class="labelHeader" style="text-decoration: underline">
            Goals
          </label>
          <!-- For Old Goals -->
          <div *ngFor="let goal of carelog.goals">
            <ul>
              <ng-container *ngFor="let goalDetail of goals">
                <li *ngIf="goalDetail.id === goal" class="padding-left:15px">
                  <label class="labelText"> {{ goalDetail.description }}</label>
                </li>
              </ng-container>
            </ul>
          </div>
          <!-- For Member Goals -->
          <div *ngFor="let goal of carelog.memberGoals">
            <ul>
              <ng-container *ngFor="let goalDetail of memberGoals">
                <li *ngIf="goalDetail.id === goal" class="padding-left:15px">
                  <label class="labelText"> {{ goalDetail.description }}</label>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <!-- Created By -->
      <div class="createdByFooter" *ngIf="showGoal">
        <div>
          <i class="material-icons" style="vertical-align: bottom">person</i>
          <span
            class="mat-small"
            style="font-size: 10px; word-break: break-word"
            >Created by: {{ carelog.createdBy }}</span
          >
        </div>

        <div>
          <i class="material-icons" style="vertical-align: bottom">event</i>
          <span
            *ngIf="!isIndianOrg"
            class="mat-small"
            style="font-size: 10px; word-break: break-word"
          >
            {{
              activityDate
                | date
                  : 'MM/dd/YYYY
            hh:mm a'
            }}
          </span>
          <span
            *ngIf="isIndianOrg"
            class="mat-small"
            style="font-size: 10px; word-break: break-word"
          >
            {{
              activityDate
                | date
                  : 'dd/MM/YYYY
            hh:mm a'
            }}
          </span>
        </div>
      </div>
    </div>

    <!-- Action -->
    <div class="goalAction" *ngIf="case.status === 'active'">
      <span *ngIf="!showGoal && !isProcessing">
        <mat-icon
          style="color: green; cursor: pointer"
          *ngIf="hasCaseLogAddAccess && patient.status === 'Active'"
          (click)="getGoalAndAddCareLog(carelog)"
        >
          add_circle
        </mat-icon>
      </span>

      <div
        class="material-icons"
        style="
          color: gray;
          font-size: 17px;
          cursor: pointer;
          border-radius: 50%;
          z-index: 2;
          border: 2px solid darkgray;
        "
        [matMenuTriggerFor]="menu"
        *ngIf="showGoal && (hasCaseLogEditAccess || hasCaseLogDeleteAccess)"
      >
        more_vert
      </div>

      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          type="button"
          *ngIf="
            hasCaseLogEditAccess &&
            patient.status === 'Active' &&
            !carelog.behavioralIncident
          "
          (click)="editCareLog(carelog)"
        >
          <mat-icon style="color: #f39121"> edit </mat-icon>
          <span>Edit</span>
        </button>

        <button
          *ngIf="hasCaseLogDeleteAccess && patient.status === 'Active'"
          mat-menu-item
          (click)="deleteCareLog(carelog)"
          type="button"
        >
          <mat-icon style="color: red"> delete_forever </mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
