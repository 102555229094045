import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';

@Component({
  selector: 'app-available-goals',
  templateUrl: './available-goals.component.html',
  styleUrls: ['./available-goals.component.css'],
})
export class AvailableGoalsComponent implements OnInit {
  patient: Patient;
  therapySession: ITherapySession;
  hasGoalsAddAccess: boolean;
  hasGoalsEditAccess: boolean;
  hasGoalsDeleteAccess: boolean;

  selectedCategory: string = 'Addiction';
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults: boolean = true;
  assessmentGroups: any[] = assessmentGroups();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<AvailableGoalsComponent>,
    public memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = dialogData.patient;
    this.therapySession = dialogData.therapySession;
    this.hasGoalsAddAccess = dialogData.hasGoalsAddAccess;
    this.hasGoalsEditAccess = dialogData.hasGoalsEditAccess;
    this.hasGoalsDeleteAccess = dialogData.hasGoalsDeleteAccess;
  }

  ngOnInit(): void {
    this.data.per_page = 1000;
    this.data.page = 0;

    this.loadGoalsForSelectedCategory();
  }

  loadGoalsForSelectedCategory() {
    this.isLoadingResults = true;

    this.memberGoalHttpService
      .getAssessmentGoals(
        this.selectedCategory,
        this.data.per_page,
        this.data.page,
        'Active'
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: () => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve goals.'
          );
        },
      });
  }

  filterOptionChanged(option: string) {
    this.data.page = 0;

    this.selectedCategory = option;
    this.loadGoalsForSelectedCategory();
  }

  changesOccured(hasChangeEvent: boolean) {
    if (hasChangeEvent) {
      this.dialogRef.close('success');
    }
  }
}
