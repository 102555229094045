<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp; Schedules &nbsp; / &nbsp; Schedule Telehealth Meeting
  </p>
</div>

<div class="row">
  <form [formGroup]="meetingForm">
    <!-- Appointment Details -->
    <mat-stepper linear #stepper style="background-color: transparent">
      <!-- Step 1 - Calendar Details -->
      <mat-step
        label="Calendar"
        state="calendaricon"
        [stepControl]="meetingForm.controls['physicianId']"
      >
        <div class="row">
          <!-- Headers -->
          <!-- Calendar Components -->
          <div
            class="col-md-3"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
              max-height: 100vh;
              overflow-y: auto;
            "
          >
            <!-- Left Calendar Components -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <!-- Calendar Type -->
              <label class="labelHeader calendarTypeContainer">
                Calendar Type
              </label>
              <div class="calendarTypeContainer" style="margin-bottom: 10px">
                <mat-button-toggle-group
                  [(ngModel)]="calendarViewType"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="calendarTypeChanged($event)"
                >
                  <mat-button-toggle value="week"> Week </mat-button-toggle>
                  <mat-button-toggle value="day"> Day </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <!-- Calendar Time Zone -->
              <label class="labelHeader">Time Zone</label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(ngModel)]="selectedCalendarTimeZone"
                  [ngModelOptions]="{ standalone: true }"
                  (selectionChange)="timeZoneChanged()"
                >
                  <mat-option
                    *ngFor="let zone of calendarTimeZones"
                    [value]="zone.id"
                  >
                    {{ zone.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Calendar Increments -->
              <label class="labelHeader">Calendar Increment</label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(ngModel)]="timeCellStep"
                  [ngModelOptions]="{ standalone: true }"
                  (selectionChange)="timeCellStepChanged()"
                >
                  <mat-option
                    *ngFor="let cellstep of timeCellSteps"
                    [value]="cellstep"
                  >
                    {{ cellstep }} minutes
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Calendar Days Range -->
              <label class="labelHeader">Calendar Days Range</label>
              <mat-slider
                min="0"
                max="6"
                discrete
                step="1"
                color="primary"
                [displayWith]="formatDaySliderLabel"
              >
                <input
                  [value]="calendarStartDay"
                  [(ngModel)]="calendarStartDay"
                  [ngModelOptions]="{ standalone: true }"
                  matSliderStartThumb
                  (valueChange)="sliderValueChanged()"
                />
                <input
                  [value]="calendarEndDay"
                  [(ngModel)]="calendarEndDay"
                  [ngModelOptions]="{ standalone: true }"
                  matSliderEndThumb
                  (valueChange)="sliderValueChanged()"
                />
              </mat-slider>
            </mat-card>

            <!-- Providers -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                Providers ({{ filteredAllResources?.length }})
              </label>

              <!-- Search Box -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Provider Name"
                  [(ngModel)]="searchProviderName"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="searchByProviderNameChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>

              <!-- Quick Actions Box -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  padding-top: 15px;
                "
              >
                <button
                  mat-button
                  color="primary"
                  type="button"
                  (click)="selectAllProviders()"
                >
                  Select All
                </button>
                <button
                  mat-button
                  color="warn"
                  type="button"
                  (click)="deselectAllProviders()"
                >
                  Uncheck All
                </button>
              </div>

              <!-- Providers List -->
              <div style="max-height: 600px; overflow: auto">
                <div *ngFor="let provider of filteredAllResources">
                  <mat-checkbox
                    color="primary"
                    [checked]="isResourceVisible(provider)"
                    (change)="selectDeselctResource($event, provider)"
                  >
                    {{ provider.name }}
                  </mat-checkbox>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Calendar Component -->
          <div class="col-md-9" style="max-height: 100vh; overflow-y: auto">
            <mat-error
              *ngIf="!providersListLoading && providersList.length == 0"
            >
              No providers found. Please contact your administrator.
            </mat-error>

            <!-- Select a provider message -->
            <mat-error
              *ngIf="!providersListLoading && filteredResources.length == 0"
            >
              Please select a provider
            </mat-error>

            <!-- Right Calendar Components -->
            <mbsc-eventcalendar
              #mbscCalendar
              [data]="myEvents"
              [resources]="filteredResources"
              [invalid]="invalids"
              [colors]="invalidColors"
              [options]="mobiscrollCalendarOptions"
              [responsive]="responsiveMobiScrollCalendarView"
              [min]="minimumCalendarDate"
              [max]="maximumCalendarDate"
              [scheduleEventTemplate]="eventTemplate"
              [displayTimezone]="selectedCalendarTimeZone"
            >
              <!-- Custom Event eventTemplate -->
              <ng-template #eventTemplate let-data>
                <div
                  class="md-custom-event-cont"
                  [ngStyle]="{
                    'border-left': '10px solid ' + data.color,
                    background: data.color
                  }"
                >
                  <div class="md-custom-event-wrapper">
                    <div class="md-custom-event-details">
                      <div class="md-custom-event-title">
                        <mat-icon
                          *ngIf="data.original.status == 'Canceled'"
                          style="
                            color: red;
                            font-size: 17px;
                            height: 15px !important;
                          "
                        >
                          highlight_off
                        </mat-icon>
                        {{ data.title }}
                      </div>
                      <div class="md-custom-event-time">
                        {{ data.start + ' - ' + data.end }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mbsc-eventcalendar>
          </div>
        </div>

        <!-- Mat Stepper Buttons -->
        <div class="row" style="margin-top: 20px">
          <div class="col-md-12" style="text-align: right">
            <button
              mat-raised-button
              type="button"
              color="primary"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <!-- Step 2 - Location & Member -->
      <mat-step
        label="Select Member"
        state="memberIcon"
        [stepControl]="meetingForm.controls['patientId']"
      >
        <!-- Member Type Selection -->
        <div class="row" style="margin-bottom: 12px">
          <div class="col-md-12" style="text-align: center">
            <label class="labelHeader">
              Member Type <span style="color: red">*</span>
            </label>

            <mat-button-toggle-group
              [(ngModel)]="memberType"
              [ngModelOptions]="{ standalone: true }"
              (change)="memberTypeChanged($event)"
            >
              <mat-button-toggle value="Guest Member">
                Guest Member
              </mat-button-toggle>
              <mat-button-toggle value="Existing Member">
                Existing Member
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!-- Select Location and then Member -->
        <div class="row" *ngIf="memberType === 'Guest Member'">
          <div class="col-md-12" style="margin-bottom: 12px">
            <label class="labelHeader">
              Please enter the guest member details
            </label>
          </div>
          <!-- First Name -->
          <div class="col-md-6">
            <label class="labelBold">
              First Name <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="First Name"
                formControlName="patientFirstName"
                required
              />
              <mat-error
                *ngIf="meetingForm.controls['patientFirstName'].invalid"
              >
                First Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Last Name -->
          <div class="col-md-6">
            <label class="labelBold">
              Last Name <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Last Name"
                formControlName="patientLastName"
                required
              />
              <mat-error
                *ngIf="meetingForm.controls['patientLastName'].invalid"
              >
                Last Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <!-- DOB -->
          <div class="col-md-6">
            <label class="labelBold">
              Date of Birth <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="patientDOB"
                matInput
                [matDatepicker]="dobDatePicker"
                [max]="todaysDate"
                placeholder="MM/DD/YYYY"
                required
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dobDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #dobDatePicker></mat-datepicker>
              <mat-error *ngIf="meetingForm.controls['patientDOB'].invalid">
                Please enter a valid Date of Birth
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Phone -->
          <div class="col-md-6">
            <label class="labelBold">
              Phone Number <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="patientPhone"
                name="patientPhone"
                placeholder="(999) 999-9999"
              />
              <mat-error *ngIf="meetingForm.controls['patientPhone'].invalid">
                Please enter a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Email -->
          <div class="col-md-12">
            <label class="labelText">
              Email <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Email"
                formControlName="patientEmail"
                name="email"
              />
              <mat-error *ngIf="meetingForm.controls['patientEmail'].invalid">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Select Location and then Member -->
        <div class="row" *ngIf="memberType === 'Existing Member'">
          <!-- Left Side Select Facility -->
          <div class="col-md-4" *ngIf="!consolidatedMemberSearch">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">Service Facility</label>
              <div class="row">
                <!-- Select Facility -->
                <div class="col-md-12">
                  <label class="labelBold">
                    Please select a facility
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="billingProviderRefId"
                      placeholder="Facility"
                      (selectionChange)="serviceFacilityChanged($event)"
                    >
                      <mat-option
                        *ngFor="let facility of selectedProviderFacilities"
                        [value]="facility.id"
                      >
                        {{ facility.facilityName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        meetingForm.controls['billingProviderRefId'].invalid
                      "
                    >
                      Please select one of the facility
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Right Side Select Member -->
          <div [class]="consolidatedMemberSearch ? 'col-md-12' : 'col-md-8'">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">Member</label>

              <div class="row">
                <!-- When a facility is not selected -->
                <div
                  class="col-md-12"
                  *ngIf="
                    meetingForm.controls['billingProviderRefId'].invalid &&
                    !consolidatedMemberSearch
                  "
                >
                  <mat-error>
                    Please select a facility to view members
                  </mat-error>
                </div>
                <!-- When a facility is selected -->
                <div
                  class="col-md-12"
                  *ngIf="
                    meetingForm.controls['billingProviderRefId'].valid ||
                    consolidatedMemberSearch
                  "
                >
                  <!-- Header -->
                  <div
                    class="row"
                    style="margin-left: 5px; margin-right: 5px; row-gap: 15px"
                  >
                    <!-- Search Box -->
                    <div class="col-md-8">
                      <div
                        class="row"
                        style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap"
                      >
                        <div
                          class="col"
                          style="
                            display: flex;
                            align-items: center;
                            padding: 5px;
                            border-radius: 10px;
                            border: 1px solid rgb(0, 0, 0, 0.2);
                            background-color: #ffffff;
                            padding: 5px;
                            min-width: 140px;
                          "
                        >
                          <span
                            class="material-icons"
                            style="
                              text-align: left;
                              font-size: 20px;
                              opacity: 0.5;
                            "
                          >
                            search
                          </span>
                          <input
                            type="search"
                            placeholder="Last Name"
                            [(ngModel)]="memberLastName"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="searchByNameChanged()"
                            style="
                              padding-left: 15px;
                              width: 100%;
                              background: transparent;
                              border: 0;
                            "
                          />
                        </div>

                        <div
                          class="col"
                          style="
                            display: flex;
                            align-items: center;
                            padding: 5px;
                            border-radius: 10px;
                            border: 1px solid rgb(0, 0, 0, 0.2);
                            background-color: #ffffff;
                            padding: 5px;
                            min-width: 140px;
                          "
                        >
                          <span
                            class="material-icons"
                            style="
                              text-align: left;
                              font-size: 20px;
                              opacity: 0.5;
                            "
                          >
                            search
                          </span>
                          <input
                            type="search"
                            placeholder="First Name"
                            [(ngModel)]="memberFirstName"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="searchByNameChanged()"
                            style="
                              padding-left: 15px;
                              width: 100%;
                              background: transparent;
                              border: 0;
                            "
                          />
                        </div>

                        <div
                          class="col"
                          style="
                            display: flex;
                            align-items: center;
                            padding: 5px;
                            border-radius: 10px;
                            border: 1px solid rgb(0, 0, 0, 0.2);
                            background-color: #ffffff;
                            padding: 5px;
                            min-width: 140px;
                          "
                        >
                          <span
                            class="material-icons"
                            style="
                              text-align: left;
                              font-size: 20px;
                              opacity: 0.5;
                            "
                          >
                            call
                          </span>
                          <input
                            type="search"
                            placeholder="Phone"
                            [(ngModel)]="memberPhone"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="searchByNameChanged()"
                            style="
                              padding-left: 15px;
                              width: 100%;
                              background: transparent;
                              border: 0;
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Add Box -->
                    <div
                      *ngIf="hasMemberAddAccess"
                      class="col-md-4"
                      style="text-align: right"
                    >
                      <div>
                        <button
                          mat-raised-button
                          color="warn"
                          (click)="addMember()"
                        >
                          + Add Member
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Member List -->
                  <div
                    class="row"
                    style="margin-top: 25px; margin-bottom: 25px"
                  >
                    <div *ngIf="membersList.total === 0 && !isMembersLoading">
                      <mat-error> No member record found </mat-error>
                    </div>

                    <div class="table-responsive" *ngIf="membersList.total > 0">
                      <table mat-table [dataSource]="membersList.items">
                        <ng-container matColumnDef="memberName">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="font-weight: 900"
                          >
                            Member Name
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <b>{{ row.lastName }}, {{ row.firstName }}</b>
                            <br />
                            {{ row.dateOfBirth }} (
                            {{
                              row?.gender === 'M'
                                ? 'Male'
                                : row?.gender === 'F'
                                ? 'Female'
                                : row?.gender.toLowerCase()
                            }}
                            )
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef>Address</th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.addressLine1 }} {{ row.addressLine2
                            }}<br />{{ row.city }} {{ row.state }} {{ row.zip }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="phoneNumber">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="font-weight: 900"
                          >
                            Phone Number
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <span *ngIf="row?.phoneNumber">
                              {{ row?.phoneNumber | phoneFormat }}
                            </span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="font-weight: 900; text-align: right"
                          >
                            Action
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let row"
                            style="text-align: right"
                          >
                            <!-- If not selected, display select button if selected display check icon -->
                            <button
                              *ngIf="
                                this.meetingForm.controls['patientId'].value !=
                                row.id
                              "
                              mat-raised-button
                              color="primary"
                              type="button"
                              (click)="selectMember(row)"
                            >
                              Select
                            </button>
                            <mat-icon
                              *ngIf="
                                this.meetingForm.controls['patientId'].value ===
                                row.id
                              "
                              style="color: #3ac47d"
                            >
                              check_circle
                            </mat-icon>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                      </table>
                    </div>

                    <mat-paginator
                      #paginator
                      [length]="membersList.total"
                      [pageSize]="membersList.per_page"
                      [showFirstLastButtons]="true"
                      *ngIf="membersList.total > membersList.per_page"
                      (page)="getNext($event)"
                    >
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <!-- Mat Stepper Buttons -->
        <div class="row" style="margin-top: 20px">
          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button mat-raised-button type="button" matStepperPrevious>
              Back
            </button>

            <button
              mat-raised-button
              color="primary"
              type="button"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <!-- Step 3 - Appointment Infomration -->
      <mat-step label="Meeting Details" state="memberIcon">
        <!-- Select Location and then Member -->
        <div class="row">
          <div
            class="col-md-12"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
            "
          >
            <!-- Member Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader"> Member Details </label>
              <!-- Member Details -->
              <div class="twoGridContainer">
                <!-- Member Name -->
                <label class="labelBold">Member Name</label>
                <label class="labelText">
                  {{ meetingForm.controls['patientLastName'].value }},{{ ' ' }}
                  {{ meetingForm.controls['patientFirstName'].value }}{{ ' ' }}
                  {{ meetingForm.controls['patientMiddleName'].value }}
                </label>
                <!-- Member DOB -->
                <label class="labelBold">Member DOB</label>
                <label class="labelText">
                  {{ meetingForm.controls['patientDOB'].value }}
                </label>
                <!-- Member Email -->
                <label
                  class="labelBold"
                  *ngIf="meetingForm.controls['patientEmail'].value"
                  >Email</label
                >
                <label
                  class="labelText"
                  *ngIf="meetingForm.controls['patientEmail'].value"
                >
                  {{ meetingForm.controls['patientEmail'].value }}
                </label>
              </div>
            </mat-card>

            <!-- Provider Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader"> Provider Details </label>
              <!-- Provider Details -->
              <div class="twoGridContainer">
                <!-- Provider Name -->
                <label class="labelBold">Provider Name</label>
                <label class="labelText">
                  {{ meetingForm.controls['physicianLastName'].value }},{{
                    ' '
                  }}
                  {{ meetingForm.controls['physicianFirstName'].value
                  }}{{ ' ' }}
                  {{ meetingForm.controls['physicianMiddleName'].value }}
                </label>
                <!-- Provider Email -->
                <label
                  class="labelBold"
                  *ngIf="meetingForm.controls['physicianEmail'].value"
                  >Email</label
                >
                <label
                  class="labelText"
                  *ngIf="meetingForm.controls['physicianEmail'].value"
                >
                  {{ meetingForm.controls['physicianEmail'].value }}
                </label>

                <!-- Provider Phone -->
                <label
                  class="labelBold"
                  *ngIf="meetingForm.controls['physicianPhone'].value"
                  >Phone</label
                >
                <label
                  class="labelText"
                  *ngIf="meetingForm.controls['physicianPhone'].value"
                >
                  {{
                    meetingForm.controls['physicianPhone'].value | phoneFormat
                  }}
                </label>
              </div>
            </mat-card>
          </div>

          <!-- Appointment Details -->
          <div
            class="col-md-12"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
            "
          >
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader"> Telehealth Meeting Details </label>

              <div class="row">
                <!-- Visit Reasons -->
                <div class="col-md-12">
                  <label class="labelBold">
                    Please select a visit reason
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="visitReasonId"
                      placeholder="Visit Reasons"
                      (selectionChange)="visitReasonChanged()"
                    >
                      <mat-option
                        *ngFor="let visitReason of visitReasons"
                        [value]="visitReason.id"
                      >
                        {{ visitReason.reasonName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="meetingForm.controls['visitReasonId'].invalid"
                    >
                      Please select one of the visit reason
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Start Date -->
                <div class="col-md-12">
                  <label class="labelBold">Start Date</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="sessionStartDate"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <!-- Start Time -->
                <div class="col-md-6">
                  <label class="labelBold">Start Time</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="sessionStartTime"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <!-- Session Duration -->
                <div class="col-md-6">
                  <label class="labelBold">Duration</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Duration"
                      formControlName="sessionDuration"
                      type="text"
                      required
                      maxlength="3"
                      pattern="\d*"
                    />
                    <span matSuffix>min</span>
                    <mat-error
                      *ngIf="meetingForm.controls['sessionDuration'].invalid"
                    >
                      Valid values 1 - 999
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>

            <!-- Mat Stepper Buttons -->
            <div class="row" style="margin-top: 20px">
              <div
                class="col-md-12"
                style="display: flex; justify-content: space-between"
              >
                <button mat-raised-button type="button" matStepperPrevious>
                  Back
                </button>

                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  [disabled]="meetingForm.invalid || processing"
                  (click)="submitForm()"
                >
                  Submit
                  <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
