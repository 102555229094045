import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { addDays } from 'date-fns';
import { DataTablesModel } from 'src/app/_models';
import { IFacility } from 'src/app/_models/facility.model';
import { IReferral } from 'src/app/_models/referrals/referral.model';
import {
  DateTimeZoneService,
  PatientInsuranceApiService,
  ReferralServicesListService,
  ReferringProviderAPIService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services';
import { ReferralService } from 'src/app/_services/referrals/referral.service';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { PatientInsuredRelationship } from 'src/app/shared/utilities/billing/patientInsuredRelationship';
import { ReferralMethods } from 'src/app/shared/utilities/referrals/referralMethods';
import { ReferralStatus } from 'src/app/shared/utilities/referrals/referralStatus';
import { StimulantOptions } from 'src/app/shared/utilities/referrals/referralStimulant';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { EditReferringProviderComponent } from '../../patient-details/patient-components/sessions/session-billing/edit-referring-provider/edit-referring-provider.component';
import { AddAttemptDialogComponent } from '../add-attempt-dialog/add-attempt-dialog.component';

@Component({
  selector: 'app-add-edit-referral',
  templateUrl: './add-edit-referral.component.html',
  styleUrl: './add-edit-referral.component.css',
})
export class AddEditReferralComponent {
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };
  action: string = '';
  currentTimezone: string;

  // Global Import
  refferralStatusList = ReferralStatus;
  stimulantList = StimulantOptions;
  referralMethodsList = ReferralMethods;
  genderList = Gender;
  usStates = USStates;
  insuredRelationshipList = PatientInsuredRelationship;
  healthInsuranceTypeList = HealthInsuranceTypes;

  searchService: string = '';
  servicesList = [];
  filteredServicesList = [];

  searchReferringProvider: string = '';
  referringProviderLoading: boolean = false;
  referringProviderList: DataTablesModel = {} as DataTablesModel;
  searchPayer: string = '';
  insuranceList: DataTablesModel = {} as DataTablesModel;

  referralForm: FormGroup;
  maxDate = new Date();
  maxDateReceived = addDays(new Date(), 2);

  processing: boolean = false;
  referralDetails: IReferral;

  // All Active Care Provider
  userName: string = '';
  allCareProviders: any[];
  filteredCareProvidersList: any[];
  careProvidersLoading: boolean = false;

  // Facilities
  filterFacilityName: string = '';
  facilities: Array<IFacility> = [];
  filteredFacilities: Array<IFacility> = [];

  constructor(
    public dialogRef: MatDialogRef<AddEditReferralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private patientInsuranceApiService: PatientInsuranceApiService,
    private toastMessageService: ToastMessageService,
    private userApiService: UserApiService,
    private referringProviderAPIService: ReferringProviderAPIService,
    private referralServicesListService: ReferralServicesListService,
    private referralService: ReferralService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
    this.action = data.action;
    this.facilities = data.facilities;
    this.filteredFacilities = data.facilities;

    if (this.action === 'EDIT') {
      this.referralDetails = data.referral;
    }
  }

  ngOnInit() {
    this.referringProviderList.per_page = 10;
    this.referringProviderList.page = 0;

    this.insuranceList.per_page = 25;
    this.insuranceList.page = 0;

    this.loadReferringProviders();
    this.loadActiveOrgInsurances();
    this.loadAllActiveCareProviders();
    this.loadAllActiveServices();

    this.buildForm();

    // If the action is edit, prepolulate the form
    if (this.action === 'EDIT') {
      this.loadReferralForm();
    }
  }

  buildForm() {
    this.referralForm = this.formBuilder.group({
      // Referral Information
      id: new FormControl(null),
      organizationId: new FormControl(null),

      facilityId: new FormControl('', Validators.required),
      facilityDetails: new FormControl(null, Validators.required),
      dateReceived: new FormControl('', Validators.required),
      servicesRequested: new FormControl([], Validators.required),
      onStimulant: new FormControl(''),
      referralMethod: new FormControl(''),
      status: new FormControl('Initial', Validators.required),

      referringProviderId: new FormControl(''),
      referringProviderDetails: new FormControl(null),
      referralNote: new FormControl(''),

      // Patient Information
      patientFirstName: new FormControl('', Validators.required),
      patientMiddleName: new FormControl(''),
      patientLastName: new FormControl('', Validators.required),
      patientDateOfBirth: new FormControl(''),
      patientGender: new FormControl(null, Validators.required),
      patientPronouns: new FormControl(''),
      patientPhonePreference: new FormControl('Cell Phone'),
      patientPhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      patientHouseNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      patientWorkNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      patientEmail: new FormControl('', Validators.compose([Validators.email])),
      patientAddressLine1: new FormControl(''),
      patientAddressLine2: new FormControl(''),
      patientCity: new FormControl(''),
      patientState: new FormControl(''),
      patientZip: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '99999' })])
      ),
      patientContactNotes: new FormControl(''),

      // Patient Insurance
      patientInsuranceRelationship: new FormControl(''),
      patientInsurancePayorId: new FormControl(''),
      patientInsurancePayorDetails: new FormControl(null),
      healthInsuranceType: new FormControl(''),
      insuranceMemberId: new FormControl(''),
      subscriberFirstName: new FormControl(''),
      subscriberLastName: new FormControl(''),
      subscriberDOB: new FormControl(''),
      subscriberGender: new FormControl(''),

      // Attempt Details
      firstAttempt: new FormControl(null),
      secondAttempt: new FormControl(null),
      thirdAttempt: new FormControl(null),

      // Appointment Details
      appointmentDate: new FormControl(''),
      appointmentWithUserId: new FormControl(null),
      appointmentWith: new FormControl(null),
      appointmentNotes: new FormControl(''),
    });
  }

  // Load Referral Form
  loadReferralForm() {
    this.referralForm.controls['id'].setValue(this.referralDetails.id);
    this.referralForm.controls['organizationId'].setValue(
      this.referralDetails.organizationId
    );
    this.referralForm.controls['facilityId'].setValue(
      this.referralDetails.facilityId
    );
    this.referralForm.controls['facilityDetails'].setValue(
      this.referralDetails.facilityDetails
    );

    if (this.referralDetails.dateReceived) {
      this.referralForm.controls['dateReceived'].setValue(
        new Date(this.referralDetails.dateReceived)
      );
    }

    this.referralForm.controls['servicesRequested'].setValue(
      this.referralDetails.servicesRequested
    );
    this.referralForm.controls['onStimulant'].setValue(
      this.referralDetails.onStimulant
    );
    this.referralForm.controls['referralMethod'].setValue(
      this.referralDetails.referralMethod
    );
    this.referralForm.controls['status'].setValue(this.referralDetails.status);
    this.referralForm.controls['referringProviderId'].setValue(
      this.referralDetails.referringProviderId
    );
    this.referralForm.controls['referringProviderDetails'].setValue(
      this.referralDetails.referringProviderDetails
    );
    this.referralForm.controls['referralNote'].setValue(
      this.referralDetails.referralNote
    );

    this.referralForm.controls['patientFirstName'].setValue(
      this.referralDetails.patientFirstName
    );
    this.referralForm.controls['patientMiddleName'].setValue(
      this.referralDetails.patientMiddleName
    );
    this.referralForm.controls['patientLastName'].setValue(
      this.referralDetails.patientLastName
    );

    if (this.referralDetails.patientDateOfBirth) {
      this.referralForm.controls['patientDateOfBirth'].setValue(
        new Date(this.referralDetails.patientDateOfBirth)
      );
    }

    this.referralForm.controls['patientGender'].setValue(
      this.referralDetails.patientGender
    );
    this.referralForm.controls['patientPronouns'].setValue(
      this.referralDetails.patientPronouns
    );
    this.referralForm.controls['patientPhonePreference'].setValue(
      this.referralDetails.patientPhonePreference
    );
    this.referralForm.controls['patientPhoneNumber'].setValue(
      this.referralDetails.patientPhoneNumber
    );
    this.referralForm.controls['patientHouseNumber'].setValue(
      this.referralDetails.patientHouseNumber
    );
    this.referralForm.controls['patientWorkNumber'].setValue(
      this.referralDetails.patientWorkNumber
    );
    this.referralForm.controls['patientEmail'].setValue(
      this.referralDetails.patientEmail
    );
    this.referralForm.controls['patientAddressLine1'].setValue(
      this.referralDetails.patientAddressLine1
    );
    this.referralForm.controls['patientAddressLine2'].setValue(
      this.referralDetails.patientAddressLine2
    );
    this.referralForm.controls['patientCity'].setValue(
      this.referralDetails.patientCity
    );
    this.referralForm.controls['patientState'].setValue(
      this.referralDetails.patientState
    );
    this.referralForm.controls['patientZip'].setValue(
      this.referralDetails.patientZip
    );
    this.referralForm.controls['patientContactNotes'].setValue(
      this.referralDetails.patientContactNotes
    );

    this.referralForm.controls['patientInsuranceRelationship'].setValue(
      this.referralDetails.patientInsuranceRelationship
    );
    this.referralForm.controls['patientInsurancePayorId'].setValue(
      this.referralDetails.patientInsurancePayorId
    );
    this.referralForm.controls['patientInsurancePayorDetails'].setValue(
      this.referralDetails.patientInsurancePayorDetails
    );
    this.referralForm.controls['healthInsuranceType'].setValue(
      this.referralDetails.healthInsuranceType
    );
    this.referralForm.controls['insuranceMemberId'].setValue(
      this.referralDetails.insuranceMemberId
    );

    this.referralForm.controls['subscriberFirstName'].setValue(
      this.referralDetails.subscriberFirstName
    );
    this.referralForm.controls['subscriberLastName'].setValue(
      this.referralDetails.subscriberLastName
    );

    if (this.referralDetails.subscriberDOB) {
      this.referralForm.controls['subscriberDOB'].setValue(
        new Date(this.referralDetails.subscriberDOB)
      );
    }

    this.referralForm.controls['subscriberGender'].setValue(
      this.referralDetails.subscriberGender
    );

    this.referralForm.controls['firstAttempt'].setValue(
      this.referralDetails.firstAttempt
    );
    this.referralForm.controls['secondAttempt'].setValue(
      this.referralDetails.secondAttempt
    );
    this.referralForm.controls['thirdAttempt'].setValue(
      this.referralDetails.thirdAttempt
    );

    this.referralForm.controls['appointmentDate'].setValue(
      this.referralDetails.appointmentDate
    );
    this.referralForm.controls['appointmentWithUserId'].setValue(
      this.referralDetails.appointmentWithUserId
    );
    this.referralForm.controls['appointmentWith'].setValue(
      this.referralDetails.appointmentWith
    );
    this.referralForm.controls['appointmentNotes'].setValue(
      this.referralDetails.appointmentNotes
    );
  }

  // Facility Selected
  faclityChanged() {
    let selectedFacility = this.facilities
      .filter(
        (facility) =>
          facility.id === this.referralForm.controls['facilityId'].value
      )
      .at(0);

    this.referralForm.controls['facilityDetails'].setValue(selectedFacility);
  }

  // Insurance Changed
  insuranceSelected() {
    let selectedInsurance = this.insuranceList.items
      .filter(
        (provider) =>
          provider.id ===
          this.referralForm.controls['patientInsurancePayorId'].value
      )
      .at(0);

    if (selectedInsurance) {
      this.referralForm.controls['patientInsurancePayorDetails'].setValue(
        selectedInsurance
      );
    } else {
      this.referralForm.controls['patientInsurancePayorDetails'].setValue(null);
    }
  }

  // Loading all active care providers
  loadAllActiveCareProviders() {
    this.careProvidersLoading = true;

    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.allCareProviders = response.items;
          this.filteredCareProvidersList = response.items;
        } else {
          this.allCareProviders = [];
          this.filteredCareProvidersList = [];
        }
        this.careProvidersLoading = false;
      },
      error: (error) => {
        this.careProvidersLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.referralForm.controls['patientCity'].setValue(
            component.long_name
          );
          break;

        case 'administrative_area_level_1': {
          //state
          this.referralForm.controls['patientState'].setValue(
            component.short_name
          );
          break;
        }

        case 'postal_code': {
          this.referralForm.controls['patientZip'].setValue(
            component.long_name
          );
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.referralForm.controls['patientAddressLine1'].setValue(addressField);
  }

  searchByPayerChanged() {
    this.insuranceList.page = 0;
    this.loadActiveOrgInsurances();
  }

  loadActiveOrgInsurances() {
    this.patientInsuranceApiService
      .getInsuranceProviderList(
        this.searchPayer,
        'Active',
        this.insuranceList.per_page,
        this.insuranceList.page
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.insuranceList.items = response.items;
            this.insuranceList.total = response.total;
          } else {
            this.insuranceList.items = [];
            this.insuranceList.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get insurance providers for this organization.'
          );
        },
      });
  }

  // Filter Facilities
  filterFacility() {
    if (this.filterFacilityName) {
      this.filteredFacilities = this.facilities.filter((payee) => {
        if (
          payee.facilityName
            .toLowerCase()
            .includes(this.filterFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredFacilities = [...this.facilities];
    }
  }

  // Filter case managers
  filterAllActiveCaseManagers() {
    if (this.userName) {
      this.filteredCareProvidersList = this.allCareProviders.filter(
        (provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.userName.toLowerCase()) ||
            concatLastFirst.toLowerCase().includes(this.userName.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredCareProvidersList = [...this.allCareProviders];
    }
  }

  // Attempt Details
  addAttempt() {
    const dialogRef = this.dialog.open(AddAttemptDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        attemptCount: 'First',
        allCareProviders: this.allCareProviders,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        this.referralForm.controls['firstAttempt'].setValue(
          response.attemptDetails
        );
      }
    });
  }

  // Edit Attempt
  editAttempt() {
    const dialogRef = this.dialog.open(AddAttemptDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        attemptCount: 'First',
        allCareProviders: this.allCareProviders,
        attemptDetails: this.referralForm.controls['firstAttempt'].value,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        this.referralForm.controls['firstAttempt'].setValue(
          response.attemptDetails
        );
      }
    });
  }

  // Remove Attempt
  removeAttempt() {
    this.referralForm.controls['firstAttempt'].setValue(null);
  }

  // Referring Provider
  addReferringProvider() {
    const dialogRef = this.dialog.open(EditReferringProviderComponent, {
      data: {
        action: 'ADD',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '30vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadReferringProviders();
      }
    });
  }

  // Load all active referring providers
  loadReferringProviders() {
    this.referringProviderLoading = true;

    this.referringProviderAPIService
      .getReferringProvider(
        this.searchReferringProvider,
        null,
        null,
        'Active',
        this.referringProviderList.per_page,
        this.referringProviderList.page
      )
      .subscribe({
        next: (response) => {
          this.referringProviderLoading = false;
          if (response && response.items) {
            this.referringProviderList.items = response.items;
            this.referringProviderList.total = response.total;
          } else {
            this.referringProviderList.items = [];
            this.referringProviderList.total = 0;
          }
        },
        error: (error) => {
          this.referringProviderLoading = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the referring providers'
          );
        },
      });
  }

  // Referring Provider Selected
  referringProviderChanged() {
    let selectedReferringProviderId =
      this.referralForm.controls['referringProviderId'].value;

    // If empty reset
    if (selectedReferringProviderId === '') {
      this.referralForm.controls['referringProviderDetails'].setValue(null);
    } else {
      // Else find and attach the provider
      let selectedReferringProvider = this.referringProviderList.items
        .filter((provider) => provider.id === selectedReferringProviderId)
        .at(0);

      this.referralForm.controls['referringProviderDetails'].setValue(
        selectedReferringProvider
      );
    }
  }

  // Appointment user changed
  appointmentProviderSelected() {
    let selectedProviderId =
      this.referralForm.controls['appointmentWithUserId'].value;

    // If empty reset
    if (selectedProviderId === '') {
      this.referralForm.controls['appointmentWith'].setValue(null);
    } else {
      // Else find and attach the provider
      let selectedReferringProvider = this.allCareProviders
        .filter((provider) => provider.id === selectedProviderId)
        .at(0);

      this.referralForm.controls['appointmentWith'].setValue(
        selectedReferringProvider
      );
    }
  }

  submitReferral() {
    this.processing = true;

    this.referralDetails = Object.assign({}, this.referralDetails);
    this.referralDetails = Object.assign(
      this.referralDetails,
      this.referralForm.value
    );

    // For patient and subscriber DOB
    if (this.referralDetails.patientDateOfBirth) {
      this.referralDetails.patientDateOfBirth = formatDate(
        new Date(this.referralDetails.patientDateOfBirth)
      );
    }
    if (this.referralDetails.subscriberDOB) {
      this.referralDetails.subscriberDOB = formatDate(
        new Date(this.referralDetails.subscriberDOB)
      );
    }

    if (this.action === 'ADD') {
      this.referralService.createReferral(this.referralDetails).subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully added the referral'
          );
          this.processing = false;
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add the referral.'
          );
          this.processing = false;
        },
      });
    } else {
      this.referralService.updateReferral(this.referralDetails).subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated the referral'
          );
          this.processing = false;
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the referral.'
          );
          this.processing = false;
        },
      });
    }
  }

  loadAllActiveServices() {
    this.servicesList = [];
    this.filteredServicesList = [];

    this.referralServicesListService.getAllActiveReferralServices().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.servicesList = response.items;
          this.filteredServicesList = response.items;
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load services requested list.'
        );
      },
    });
  }

  searchServiceChanged() {
    if (this.searchService) {
      this.filteredServicesList = this.servicesList.filter((service) => {
        if (
          service.serviceName
            .toLowerCase()
            .includes(this.searchService.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredServicesList = [...this.servicesList];
    }
  }
}
