<div class="main-content" style="background-color: #eee">
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 1. Acute Intoxication and/or Withdrawal Potential Risk
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="lineChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 1 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Fully functioning, “no signs of intoxication or withdrawal present”
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Mild to moderate intoxication interferes with daily functioning but does not pose a
                                danger to self or others. Minimal risk of severe withdrawal.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Intoxication may be severe but responds to support; not posing a danger to self or
                                others. “Moderate risk of severe withdrawal”.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                “Severe signs/symptoms of intoxication indicates… an imminent danger to self or others”.
                                Risk of severe but manageable withdrawal; or withdrawal is worsening.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                “Incapacitated, with severe signs and symptoms. Severe withdrawal presents danger, as of
                                seizures. Continued use poses an imminent threat to life (e.g., liver failure, GI bleed,
                                or fetal death).”
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 2. Biomedical Conditions and Complications
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="dimension2ChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 2 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Fully functioning, no biomedical symptoms or signs are present. Biomedical conditions
                                are stable.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Biomedical signs/symptoms are mild to moderate that may interfere with daily
                                functioning.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Biomedical conditions may interfere with recovery and mental health treatment.
                                Neglecting serious biomedical conditions. Presence of acute but non-liftthreatening
                                medical symptoms and signs. Shows some “difficulty tolerating and coping with physical
                                problems.”
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                “Poor ability to tolerate and cope with physical problems.” Poor health condition.
                                Neglecting serious medical problems but health is still stable.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Presence of serious medical problems. “Patient is incapacitated.” Requires medical
                                stabilization and medication management in a hospital setting.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 3. Emotional, Behavioral, or Cognitive (EBC) Conditions
                            and Complications
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="dimension3ChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 3 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient has no mental health (MH) diagnosis or MH disorder (d/o) is stable. Good coping
                                skills. No interference with recovery, no impairment in social functioning or self-care
                                ability.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                MH d/o (shows mild to moderate symptoms) needs intervention but isn’t affecting recovery
                                significantly. Impulse control and coping skills are adequate. Some impairment in social
                                functioning.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Presence of chronic EBC conditions and cause significant interference with recovery.
                                Absence of imminent risk to safety. Impairment in social functioning and has minimal
                                skills to function independently.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Severe EBC symptoms, but sufficient control that does not require involuntary
                                confinement. Impulses to harm self or others are frequent, but no imminent risk in a
                                24hr setting.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Severe EBC symptoms that requires involuntary confinement. MH d/o is severe causing
                                threat to self and others. Unpredictable, disorganized, and violent psychotic symptoms.
                                Gravely disable.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 4. Readiness to Change
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="dimension4ChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 4 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient shows willingness and commitment to both SUD and mental health (MH) treatment.
                                Patient is proactive and responsible.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient shows willingness and commitment to both SUD and MH treatment but feels
                                ambivalent with the need for change.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient is reluctant to enter treatment. Aware of negative consequences of substance use
                                but has “low readiness to change and is passively involved in treatment. May be
                                inconsistent with treatment and selfhelp group attendance.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient does not follow through treatment consistently and has limited insight to need
                                for treatment. Not aware of the need to change.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Inability to follow through treatment recommendations and see the connection between
                                substance use and negative consequences. Blaming others for their SUD and unwilling to
                                explore change. Requires immediate action if patient shows imminent risk to harm
                                self/others due to SUD or MH conditions.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 5. Relapse, Continued Use, or Continued Problem
                            Potential
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="dimension5ChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 5 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Low relapse potential. Good coping skills.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Minimal relapse risk. Relapse prevention skills and selfmanagement skills are fair.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient is capable of self-management with prompting but has “impaired recognition and
                                understanding of” relapse.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Limited understanding on relapse and has poor coping skills. Limited relapse coping
                                skills.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                No relapse prevention skills to reduce relapse. Repeated treatment has little effect on
                                improving the patient's functioning. Requires immediate action if patient shows imminent
                                risk to harm self/others due to SUD or MH conditions.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Dimension 6. Recovery/Living Environment
                        </h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="dimension6ChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting Dimension # 6 Risk Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-1 description">Color</div>
                            <div class="col-md-1 description">Score</div>
                            <div class="col-md-10 description">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round greenButton"></button>
                            </div>
                            <div class="col-md-1 score" >
                                0
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                “The patient has a supportive environment or is able to cope with poor supports.”
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round yellow-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                1
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient is able to cope even with passive support or limited support from loved ones.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round orange-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                2
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient is able to cope with clinical structure even thought their environment is not
                                supportive of SUD recovery.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round pink-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                3
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient struggles with coping even with clinical structure due to unsupportive recovery
                                environment.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <button mat-raised-button
                                    class="btn btn-just-icon btn-fab btn-round red-button"></button>
                            </div>
                            <div class="col-md-1 score" >
                                4
                            </div>
                            <div class="col-md-10" style="padding-top: 4px; font-weight: 300">
                                Patient’s surrounding environment is hostile and not supportive of SUD recovery. Patient
                                struggles to cope with the environment. Requires immediate action if the environment is
                                posting imminent threat to patient's wellbeing and safety.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
    </div>
</div>