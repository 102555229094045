import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Editor, Toolbar, schema, toDoc } from 'ngx-editor';
import { ITherapySession } from 'src/app/_models/session/therapySession.model';
import {
  TherapySessionService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services';
import { DashboardsService } from 'src/app/_services/dashboards/dashboards.service';

@Component({
  selector: 'app-start-phone-note',
  templateUrl: './start-phone-note.component.html',
  styleUrl: './start-phone-note.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class StartPhoneNoteComponent implements OnInit, OnDestroy {
  public patientId: string;
  therapySession: ITherapySession;

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  public assignForm: FormGroup;

  processing: boolean = false;

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right'],
    ['horizontal_rule'],
  ];

  constructor(
    public dialogRef: MatDialogRef<StartPhoneNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService,
    public therapysessionApiService: TherapySessionService,
    private userApiService: UserApiService,
    private formBuilder: FormBuilder
  ) {
    this.patientId = data.patientId;
  }

  ngOnInit() {
    this.editor = new Editor();
    this.buildForm();

    this.loadActiveProviders();
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  private buildForm() {
    this.assignForm = this.formBuilder.group({
      id: new FormControl(null),
      caseId: new FormControl(null),
      organizationId: new FormControl(null),

      patientId: new FormControl(this.patientId, Validators.required),
      physicianId: new FormControl(''),
      note: new FormControl('', Validators.required),
    });
  }

  loadActiveProviders() {
    this.providersListLoading = true;

    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  // Search Filter for supervising provider
  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }

  onSessionAssignFormSubmit() {
    if (this.assignForm.invalid) {
      this.assignForm.markAllAsTouched();
      return;
    }

    this.therapySession = Object.assign({}, this.therapySession);
    this.therapySession = Object.assign(
      this.therapySession,
      this.assignForm.value
    );

    this.therapySession.sessionType = 'Phone Note';
    let jsonDoc = toDoc(this.assignForm.controls['note'].value, schema);
    this.therapySession.objective = jsonDoc;

    this.processing = true;

    this.therapysessionApiService
      .createNewTherapySession(this.therapySession)
      .subscribe({
        next: () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Successfully created Phone Note'
          );
        },
        error: () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed creating Phone Note'
          );
        },
      });
  }
}
