<mat-card style="border-radius: 15px; margin: 15px">
  <div class="row">
    <!-- Filters -->
    <div class="col-md-3">
      <label class="labelHeader">Calendar </label>
      <!-- Calendar Type Selection -->
      <div style="margin-bottom: 10px">
        <!-- Calendar Button -->
        <mat-button-toggle-group
          [(ngModel)]="calendarViewType"
          [ngModelOptions]="{ standalone: true }"
          (change)="calendarTypeChanged($event)"
        >
          <mat-button-toggle value="week"> Week </mat-button-toggle>
          <mat-button-toggle value="day"> Day </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <label class="labelHeader">Session Status </label>
      <!-- Session Status  Selection -->
      <div style="margin-bottom: 10px">
        <!--  Session Status Button -->
        <mat-button-toggle-group
          [(ngModel)]="sessionStatus"
          [ngModelOptions]="{ standalone: true }"
          (change)="sessionStatusChanged()"
        >
          <mat-button-toggle value="All"> All </mat-button-toggle>
          <mat-button-toggle value="Scheduled"> Scheduled </mat-button-toggle>
          <mat-button-toggle value="In Office"> In Office </mat-button-toggle>
          <mat-button-toggle value="Closed"> Closed </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!-- Location Filter -->
      <!-- Quick Actions Box -->
      <label class="labelHeader" style="padding-top: 15px">Facilities </label>

      <!-- Search Box -->
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Facility Name"
          [(ngModel)]="searchFacilityName"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="searchByFacilityNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
      <!-- Quick Actions -->
      <div
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <button
          mat-button
          color="primary"
          type="button"
          (click)="selectAllFacilities()"
        >
          Select All
        </button>
        <button
          mat-button
          color="warn"
          type="button"
          (click)="deselectAllFacilities()"
        >
          Uncheck All
        </button>
      </div>

      <!-- Facilities List -->
      <div style="max-height: 450px; overflow: auto">
        <div *ngFor="let facility of facilitiesSelectionList">
          <mat-checkbox
            color="primary"
            [checked]="isFacilityVisible(facility)"
            (change)="selectDeselctFacility($event, facility)"
          >
            {{ facility.facilityName }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <!-- Mobi Scroll Calendar -->
    <div class="col-md-9" style="max-height: 80vh; overflow: auto">
      <!-- Calendar -->
      <mbsc-eventcalendar
        [data]="filteredEvents"
        [invalid]="invalids"
        [colors]="invalidColors"
        [options]="mobiscrollCalendarOptions"
        [responsive]="responsiveMobiScrollCalendarView"
        [min]="minimumCalendarDate"
        [max]="maximumCalendarDate"
        [scheduleEventTemplate]="eventTemplate"
        [displayTimezone]="selectedCalendarTimeZone"
      >
        <!-- Custom Event eventTemplate -->
        <ng-template #eventTemplate let-data>
          <div
            class="md-custom-event-cont"
            [ngStyle]="{
              'border-left': '10px solid ' + data.color,
              background: data.color
            }"
          >
            <div class="md-custom-event-wrapper">
              <div class="md-custom-event-details">
                <div class="md-custom-event-title">
                  <div class="justifyCenterContainer">
                    <mat-icon
                      *ngIf="data.original.status == 'Canceled'"
                      style="
                        color: red;
                        font-size: 17px;
                        height: 15px !important;
                      "
                    >
                      highlight_off
                    </mat-icon>
                    <mat-icon *ngIf="data.original.groupAppointment"
                      >group</mat-icon
                    >
                    <mat-icon
                      *ngIf="
                        data.original.sessionType === 'Telehealth-Only' ||
                        data.original.sessionType === 'Tele-Health Appointment'
                      "
                      style="color: gray"
                    >
                      videocam
                    </mat-icon>
                    {{ data.title }}
                    <span
                      class="queueBox"
                      [style.background]="data.original.queueColor"
                      *ngIf="data.original?.queue"
                    >
                      {{ data.original.queue }}
                    </span>
                    <!-- Member level note -->
                    <span
                      *ngIf="!data.original?.groupAppointment"
                      class="collectPaymentText"
                      id="collectPaymentButton"
                      (click)="viewSessionLevelNote(data.original)"
                    >
                      <mat-icon
                        style="font-size: 14px; margin: 0px; padding: 0"
                      >
                        chat
                      </mat-icon>
                    </span>
                  </div>
                </div>
                <div class="md-custom-event-time">
                  <b>Facility : </b> {{ data.original.facilityName }}
                </div>
                <div class="md-custom-event-time">
                  {{ data.start + ' - ' + data.end }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </mbsc-eventcalendar>
      <!-- Event Popup -->
      <mbsc-popup
        class="md-tooltip"
        #popup
        [anchor]="anchor"
        [options]="popupOptions"
      >
        <div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
          <!-- Event Title -->
          <div
            class="md-tooltip-header"
            [ngStyle]="{ background: selectedEvent?.color }"
          >
            <label
              class="labelBold"
              style="color: #ffffff; cursor: pointer"
              (click)="navigateToMemberPage(selectedEvent)"
            >
              {{ selectedEvent?.title }}
            </label>
          </div>
          <!-- Event Information -->
          <div class="md-tooltip-info">
            <div>
              <label class="labelBold">Appointment : </label>
              <label
                class="labelText"
                style="color: #2196f3; cursor: pointer"
                (click)="navigateToSessionPage(selectedEvent)"
              >
                &nbsp;{{ selectedEvent?.sessionCode }}
              </label>
              <!-- Member level note -->
              <span
                *ngIf="!selectedEvent?.groupAppointment"
                class="collectPaymentText"
                id="collectPaymentButton"
                (click)="viewSessionLevelNote(selectedEvent)"
              >
                <mat-icon style="font-size: 14px; margin: 0px; padding: 0">
                  chat
                </mat-icon>
              </span>
              <!-- Collect payment -->
              <span
                *ngIf="
                  hasSessionEditAccess && selectedEvent?.status != 'Closed'
                "
                class="collectPaymentText"
                id="collectPaymentButton"
                (click)="rescheduleSession(selectedEvent)"
              >
                ( Edit )
              </span>
              <span
                *ngIf="
                  hasMemberPaymentsAddAccess &&
                  orgConfig?.connectedAccountStatus === 'Setup_Complete' &&
                  !selectedEvent?.groupAppointment &&
                  (selectedEvent?.sessionType != 'Telehealth-Only' ||
                    (selectedEvent?.sessionType === 'Telehealth-Only' &&
                      selectedEvent?.sessionFor === 'Existing Member'))
                "
                class="collectPaymentText"
                id="collectPaymentButton"
                (click)="collectPayment(selectedEvent)"
              >
                ( Collect payment )
              </span>
            </div>
            <div>
              <label class="labelBold">Appointment Type : </label>
              <label class="labelBold">
                &nbsp;{{ selectedEvent?.sessionType }}
              </label>
            </div>
            <div>
              <label class="labelBold">Appointment Start : </label>
              <label class="labelText">
                &nbsp;{{
                  selectedEvent?.start
                    | date
                      : 'M/d/y, h:mm a'
                      : selectedCaldendarTimeZoneDateFormat
                }}
                {{ selectedCaldendarTimeZoneDateFormat }}
              </label>
            </div>
            <div>
              <label class="labelBold">Appointment Duration : </label>
              <label class="labelText">
                &nbsp;{{ selectedEvent?.duration }} minutes
              </label>
            </div>
            <div>
              <label class="labelBold">Facility : </label>
              <label class="labelText">
                &nbsp;{{ selectedEvent?.facilityName }}
              </label>
            </div>
            <div>
              <label class="labelBold">Status : </label>
              <label class="labelText">
                <span
                  [style]="
                    selectedEvent?.status === 'Active'
                      ? 'color: #3ac47d; font-weight: 900'
                      : 'color: #d92550; font-weight: 900'
                  "
                >
                  &nbsp;{{ selectedEvent?.status }}
                </span>
              </label>
            </div>
            <div *ngIf="selectedEvent?.queue">
              <label class="labelBold">Queue : </label>
              &nbsp;
              <input
                #myinput
                [(ngModel)]="selectedEvent.queue"
                class="queueBox"
                [style.background]="selectedEvent?.queueColor"
              />
              <select
                mbsc-select
                [disabled]="selectedEvent.queue === 'Closed'"
                [data]="sessionQueues"
                [defaultSelection]="selectedEvent.queue"
                [inputElement]="myinput"
                [touchUi]="false"
                (onOpen)="onQueueSelectionBoxOpen($event)"
                (onChange)="onQueueSelectionBoxChange($event)"
                (onClose)="onQueueSelectionBoxClose($event)"
              ></select>
            </div>
            <!-- If only the organization has tele-health Feature and the appointment type is of tele-health-->
            <div
              style="text-align: center; margin-top: 10px"
              *ngIf="
                hasTelehealthFeature &&
                (selectedEvent?.sessionType === 'Tele-Health Appointment' ||
                  selectedEvent?.sessionType === 'Telehealth-Only')
              "
            >
              <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="!isJoinValid(selectedEvent) || hostUrlProcessing"
                (click)="JoinMeeting(selectedEvent)"
              >
                <i class="material-icons">videocam</i>&nbsp; Join
              </button>
            </div>

            <!-- Group Information -->
            <div *ngIf="selectedEvent?.groupAppointment" class="memberCardBox">
              <label class="labelBold">
                Group Members ({{ selectedEvent?.groupMembers?.length }})
              </label>
              <!-- loop through group members -->
              <div *ngFor="let member of selectedEvent?.groupMembers">
                <div class="row memberCardBox">
                  <div class="col-12">
                    <div class="justifyCenterContainer">
                      <mat-icon style="color: green" *ngIf="member?.attendance">
                        check_circle
                      </mat-icon>
                      <mat-icon style="color: red" *ngIf="!member?.attendance">
                        cancel
                      </mat-icon>
                      <span style="font-weight: 500">
                        {{ member.member.lastName }},
                        {{ member.member.firstName }}
                        {{ member.member.middleName }}
                      </span>
                    </div>
                    <!-- Take Attendance -->
                    <button
                      mat-raised-button
                      type="button"
                      color="primary"
                      *ngIf="
                        !member?.attendance &&
                        selectedEvent?.status === 'Active'
                      "
                      (click)="takeAttendance(selectedEvent, member, true)"
                    >
                      <mat-icon>edit_document</mat-icon> Take Attendance
                    </button>
                    <!-- Remove Attendance -->
                    <button
                      mat-raised-button
                      type="button"
                      color="warn"
                      *ngIf="
                        member?.attendance && selectedEvent?.status === 'Active'
                      "
                      (click)="takeAttendance(selectedEvent, member, false)"
                    >
                      <mat-icon>disabled_by_default</mat-icon> Remove Attendance
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Note & Billing Status -->
          <div
            class="noteChargeContainerWrapper"
            *ngIf="selectedEvent?.status != 'Canceled'"
          >
            <!-- Note -->
            <div
              class="noteChargeStatusDotWrapper"
              *ngIf="selectedEvent?.clinicalNoteStatus"
            >
              <mat-icon
                class="statusDot"
                [style.color]="
                  selectedEvent.clinicalNoteStatus === 'Note in progress'
                    ? '#f8b042'
                    : '#b6b6b6'
                "
              >
                fiber_manual_record
              </mat-icon>
              <span class="statusIndicator" style="color: #b6b6b6">
                {{ selectedEvent.clinicalNoteStatus }}
              </span>
            </div>
            <!-- Charge -->
            <div
              class="noteChargeStatusDotWrapper"
              *ngIf="selectedEvent?.chargeStatus"
            >
              <mat-icon
                class="statusDot"
                [style.color]="
                  selectedEvent.chargeStatus === 'Charge in progress'
                    ? '#f8b042'
                    : selectedEvent.chargeStatus === 'Charge not started'
                    ? '#b6b6b6'
                    : '#3ac47d'
                "
              >
                fiber_manual_record
              </mat-icon>
              <span class="statusIndicator" style="color: #b6b6b6">
                {{ selectedEvent.chargeStatus }}
              </span>
            </div>
          </div>
        </div>
      </mbsc-popup>
    </div>
  </div>
</mat-card>
