import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentPlanProfile } from 'src/app/_models/case/treatment/plan/treatmentplanprofile.model';
import { TreatmentProgramApiService } from 'src/app/_services/case/treatment/discharge/treatmentprogram.service';
import { TreatmentPlanProfileApiService } from 'src/app/_services/case/treatment/plan/treatmentplanprofile.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-care-plan-profile',
  templateUrl: './care-plan-profile.component.html',
  styleUrl: './care-plan-profile.component.css',
})
export class CarePlanProfileComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;

  public treatmentplanprofileForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  treatmentplanprofile: ITreatmentPlanProfile;

  processing: boolean = false;
  public programOptions: string[] = [];
  isLoadingResults = false;
  apiError = false;

  data: DataTablesModel = {} as DataTablesModel;

  constructor(
    public treatmentplanprofileApiService: TreatmentPlanProfileApiService,
    public treatmentprogramApiService: TreatmentProgramApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 1000;

    this.buildForm();

    this.loadTreatmentProgramsList();
    this.loadTreatmentPlanProfile();
  }

  public loadTreatmentProgramsList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.treatmentprogramApiService
      .getTreatmentProgramsList(
        this.case.id,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.programOptions = response.items.map(
              (item) => item.programName
            );
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  loadTreatmentPlanProfile() {
    this.treatmentplanprofileApiService
      .getTreatmentPlanProfileDetails(this.selectedPlan.id)
      .subscribe((treatmentplanprofile) => {
        if (treatmentplanprofile?.data) {
          this.treatmentplanprofileForm.controls['id'].setValue(
            treatmentplanprofile.data.id
          );
          this.treatmentplanprofileForm.controls['organizationId'].setValue(
            treatmentplanprofile.data.organizationId
          );
          this.treatmentplanprofileForm.controls['patientId'].setValue(
            treatmentplanprofile.data.patientId
          );
          this.treatmentplanprofileForm.controls['caseId'].setValue(
            treatmentplanprofile.data.caseId
          );
          this.treatmentplanprofileForm.controls['planId'].setValue(
            treatmentplanprofile.data.planId
          );
          this.treatmentplanprofileForm.controls['programs'].setValue(
            treatmentplanprofile.data.programs
          );
          this.treatmentplanprofileForm.controls['planPeriodDays'].setValue(
            treatmentplanprofile.data.planPeriodDays
          );
          this.treatmentplanprofileForm.controls['nextReviewDate'].setValue(
            mmddyyyyToDate(treatmentplanprofile.data.nextReviewDate)
          );
          this.treatmentplanprofileForm.controls[
            'clientParticipatedInTxPlanDevelopment'
          ].setValue(
            treatmentplanprofile.data.clientParticipatedInTxPlanDevelopment
          );
        }
      });
  }
  onTreatmentPlanProfileFormSubmit() {
    if (this.treatmentplanprofileForm.invalid) {
      this.treatmentplanprofileForm.markAllAsTouched();
      return;
    }
    this.treatmentplanprofile = Object.assign({}, this.treatmentplanprofile);
    this.treatmentplanprofile = Object.assign(
      this.treatmentplanprofile,
      this.treatmentplanprofileForm.value
    );
    if (this.treatmentplanprofile.nextReviewDate !== '') {
      this.treatmentplanprofile.nextReviewDate = formatDate(
        new Date(this.treatmentplanprofile.nextReviewDate)
      );
    }

    this.processing = true;
    this.treatmentplanprofileApiService
      .updateTreatmentPlanProfile(this.treatmentplanprofile)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentplanprofileForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planId: new FormControl(
        this.selectedPlan.id,
        Validators.compose([Validators.required])
      ),
      programs: new FormControl([], Validators.compose([Validators.required])),
      planPeriodDays: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      nextReviewDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      clientParticipatedInTxPlanDevelopment: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });

    if (this.selectedPlan.status != 'Active') {
      this.treatmentplanprofileForm.disable();
    }
  }

  removeSelectedOption(record, index) {
    let existingRecords = this.treatmentplanprofileForm.controls['programs']
      .value as [];

    existingRecords.splice(index, 1);

    this.treatmentplanprofileForm.controls['programs'].setValue(
      existingRecords
    );
  }
}
