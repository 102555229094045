export const ReferralStatus = [
  'Initial',
  'No Attempt',
  '1st Attempt',
  '2nd Attempt',
  '3rd Attempt',
  'Duplicate',
  'Insurance Pending',
  // 'Member Imported',
  'Refused',
  'Unable to Contact',
];
