<div class="row">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a
      [routerLink]="
        parent === 'Member' && memId
          ? ['/main/member', memId, 'NOTES']
          : parent === 'Assessments'
          ? '/main/assessments'
          : '/'
      "
      routerLinkActive="active"
    >
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
      >
        home
      </em> </a
    >&nbsp; / &nbsp;
    <span> <a>Schedules</a>&nbsp; / &nbsp; </span>
    {{
      editGroupAppointmentId
        ? 'Edit Group Appointment'
        : 'Schedule New Group Appointment'
    }}
  </p>
</div>

<div class="row">
  <form [formGroup]="therapysessionForm">
    <!-- Multiple Mat Stepper for Different Screens -->
    <mat-stepper linear #stepper style="background-color: transparent">
      <!-- Step 1 - Calendar Details -->
      <mat-step
        label="Calendar"
        state="calendaricon"
        [stepControl]="therapysessionForm.controls['title']"
      >
        <div class="row">
          <!-- Headers -->
          <!-- Calendar Components -->
          <div
            class="col-md-3"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
              max-height: 100vh;
              overflow-y: auto;
            "
          >
            <!-- Left Calendar Components -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <!-- Calendar Type -->
              <label class="labelHeader calendarTypeContainer">
                Calendar Type
              </label>
              <div class="calendarTypeContainer" style="margin-bottom: 10px">
                <mat-button-toggle-group
                  [(ngModel)]="calendarViewType"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="calendarTypeChanged($event)"
                >
                  <mat-button-toggle value="week"> Week </mat-button-toggle>
                  <mat-button-toggle value="day"> Day </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <!-- Calendar Time Zone -->
              <label class="labelHeader">Time Zone</label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(ngModel)]="selectedCalendarTimeZone"
                  [ngModelOptions]="{ standalone: true }"
                  (selectionChange)="timeZoneChanged()"
                >
                  <mat-option
                    *ngFor="let zone of calendarTimeZones"
                    [value]="zone.id"
                  >
                    {{ zone.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Calendar Increments -->
              <label class="labelHeader">Calendar Increment</label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(ngModel)]="timeCellStep"
                  [ngModelOptions]="{ standalone: true }"
                  (selectionChange)="timeCellStepChanged()"
                >
                  <mat-option
                    *ngFor="let cellstep of timeCellSteps"
                    [value]="cellstep"
                  >
                    {{ cellstep }} minutes
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Calendar Days Range -->
              <label class="labelHeader">Calendar Days Range</label>
              <mat-slider
                min="0"
                max="6"
                discrete
                step="1"
                color="primary"
                [displayWith]="formatDaySliderLabel"
              >
                <input
                  [value]="calendarStartDay"
                  [(ngModel)]="calendarStartDay"
                  [ngModelOptions]="{ standalone: true }"
                  matSliderStartThumb
                  (valueChange)="sliderValueChanged()"
                />
                <input
                  [value]="calendarEndDay"
                  [(ngModel)]="calendarEndDay"
                  [ngModelOptions]="{ standalone: true }"
                  matSliderEndThumb
                  (valueChange)="sliderValueChanged()"
                />
              </mat-slider>
            </mat-card>

            <!-- Providers -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                Providers ({{ filteredAllResources?.length }})
              </label>

              <!-- Search Box -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Provider Name"
                  [(ngModel)]="searchProviderName"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="searchByProviderNameChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>

              <!-- Quick Actions Box -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  padding-top: 15px;
                "
              >
                <button
                  mat-button
                  color="primary"
                  type="button"
                  (click)="selectAllProviders()"
                >
                  Select All
                </button>
                <button
                  mat-button
                  color="warn"
                  type="button"
                  (click)="deselectAllProviders()"
                >
                  Uncheck All
                </button>
              </div>

              <!-- Providers List -->
              <div style="max-height: 600px; overflow: auto">
                <div *ngFor="let provider of filteredAllResources">
                  <mat-checkbox
                    color="primary"
                    [disabled]="!isResourceFacilityVisible(provider)"
                    [checked]="isResourceVisible(provider)"
                    (change)="selectDeselctResource($event, provider)"
                  >
                    {{ provider.name }}
                  </mat-checkbox>
                </div>
              </div>
            </mat-card>

            <!-- Facilities  -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                Facilities ({{ facilitiesSelectionList?.length }})
              </label>

              <!-- Search Box -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Facility Name"
                  [(ngModel)]="searchFacilityName"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="searchByFacilityNameChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>
              <!-- Quick Actions -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                "
              >
                <button
                  mat-button
                  color="primary"
                  type="button"
                  (click)="selectAllFacilities()"
                >
                  Select All
                </button>
                <button
                  mat-button
                  color="warn"
                  type="button"
                  (click)="deselectAllFacilities()"
                >
                  Uncheck All
                </button>
              </div>

              <!-- Facilities List -->
              <div style="max-height: 450px; overflow: auto">
                <div *ngFor="let facility of facilitiesSelectionList">
                  <mat-checkbox
                    color="primary"
                    [checked]="isFacilityVisible(facility)"
                    (change)="selectDeselctFacility($event, facility)"
                  >
                    {{ facility.facilityName }}
                  </mat-checkbox>
                </div>
              </div>
            </mat-card>
          </div>

          <div class="col-md-9" style="max-height: 100vh; overflow-y: auto">
            <mat-error
              *ngIf="!providersListLoading && providersList.length == 0"
            >
              No providers found. Please contact your administrator.
            </mat-error>

            <!-- Select a provider message -->
            <mat-error
              *ngIf="!providersListLoading && filteredResources.length == 0"
            >
              Please select a provider
            </mat-error>

            <!-- Right Calendar Components -->
            <mbsc-eventcalendar
              #mbscCalendar
              [data]="myEvents"
              [resources]="filteredResources"
              [invalid]="invalids"
              [colors]="invalidColors"
              [options]="mobiscrollCalendarOptions"
              [responsive]="responsiveMobiScrollCalendarView"
              [min]="minimumCalendarDate"
              [max]="maximumCalendarDate"
              [scheduleEventTemplate]="eventTemplate"
              [displayTimezone]="selectedCalendarTimeZone"
            >
              <!-- Custom Event eventTemplate -->
              <ng-template #eventTemplate let-data>
                <div
                  class="md-custom-event-cont"
                  [ngStyle]="{
                    'border-left': '10px solid ' + data.color,
                    background: data.color
                  }"
                >
                  <div class="md-custom-event-wrapper">
                    <div class="md-custom-event-details">
                      <div class="md-custom-event-title">
                        <mat-icon
                          *ngIf="data.original.status == 'Canceled'"
                          style="
                            color: red;
                            font-size: 17px;
                            height: 15px !important;
                          "
                        >
                          highlight_off
                        </mat-icon>
                        {{ data.title }}
                      </div>
                      <div class="md-custom-event-time">
                        {{ data.start + ' - ' + data.end }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mbsc-eventcalendar>
          </div>
        </div>

        <!-- Mat Stepper Buttons -->
        <div class="row" style="margin-top: 20px">
          <div class="col-md-12" style="text-align: right">
            <button
              mat-raised-button
              type="button"
              color="primary"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>
      <!-- Step 2 - Group Members -->
      <mat-step
        label="Group Members"
        state="memberIcon"
        [stepControl]="therapysessionForm.controls['groupMembers']"
      >
        <!-- Select Members -->
        <div class="row">
          <!-- Search &  Select Members -->
          <div class="col-md-8" style="margin-bottom: 35px">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                <mat-icon>fact_check</mat-icon> Select Members
              </label>

              <!-- Selected Facility -->
              <div class="row">
                <!-- Select Facility is not consolidatedMemberSearch-->
                <div class="col-md-12" *ngIf="!consolidatedMemberSearch">
                  <label class="labelBold">
                    Please select a facility
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      placeholder="Facility"
                      [(ngModel)]="searchMemberFacility"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="searchByNameChanged()"
                      (selectionChange)="serviceFacilityChanged($event)"
                    >
                      <mat-option
                        *ngFor="let facility of selectedProviderFacilities"
                        [value]="facility.id"
                      >
                        {{ facility.facilityName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!searchMemberFacility">
                      Please select one of the facility
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- // Display warning to load members -->
              <mat-error
                *ngIf="!searchMemberFacility && !consolidatedMemberSearch"
              >
                Please select a facility to view members
              </mat-error>

              <!-- // Load Members -->
              <!-- Header -->
              <div
                *ngIf="searchMemberFacility || consolidatedMemberSearch"
                class="row"
                style="margin-left: 5px; margin-right: 5px; row-gap: 15px"
              >
                <!-- Search Box -->
                <div class="col-md-8">
                  <div
                    class="row"
                    style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap"
                  >
                    <div
                      class="col"
                      style="
                        display: flex;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        border: 1px solid rgb(0, 0, 0, 0.2);
                        background-color: #ffffff;
                        padding: 5px;
                        min-width: 140px;
                      "
                    >
                      <span
                        class="material-icons"
                        style="text-align: left; font-size: 20px; opacity: 0.5"
                      >
                        search
                      </span>
                      <input
                        type="search"
                        placeholder="Last Name"
                        [(ngModel)]="memberLastName"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="searchByNameChanged()"
                        style="
                          padding-left: 15px;
                          width: 100%;
                          background: transparent;
                          border: 0;
                        "
                      />
                    </div>

                    <div
                      class="col"
                      style="
                        display: flex;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        border: 1px solid rgb(0, 0, 0, 0.2);
                        background-color: #ffffff;
                        padding: 5px;
                        min-width: 140px;
                      "
                    >
                      <span
                        class="material-icons"
                        style="text-align: left; font-size: 20px; opacity: 0.5"
                      >
                        search
                      </span>
                      <input
                        type="search"
                        placeholder="First Name"
                        [(ngModel)]="memberFirstName"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="searchByNameChanged()"
                        style="
                          padding-left: 15px;
                          width: 100%;
                          background: transparent;
                          border: 0;
                        "
                      />
                    </div>

                    <div
                      class="col"
                      style="
                        display: flex;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        border: 1px solid rgb(0, 0, 0, 0.2);
                        background-color: #ffffff;
                        padding: 5px;
                        min-width: 140px;
                      "
                    >
                      <span
                        class="material-icons"
                        style="text-align: left; font-size: 20px; opacity: 0.5"
                      >
                        call
                      </span>
                      <input
                        type="search"
                        placeholder="Phone"
                        [(ngModel)]="memberPhone"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="searchByNameChanged()"
                        style="
                          padding-left: 15px;
                          width: 100%;
                          background: transparent;
                          border: 0;
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- Add Box -->
                <div
                  *ngIf="hasMemberAddAccess"
                  class="col-md-4"
                  style="text-align: right"
                >
                  <div>
                    <button
                      mat-raised-button
                      color="warn"
                      (click)="addMember()"
                    >
                      + Add Member
                    </button>
                  </div>
                </div>

                <!-- Progress bar -->
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="isMembersLoading"
                ></mat-progress-bar>

                <!-- Members List -->
                <div class="col-md-12">
                  <div class="row">
                    <!-- When a facility is selected -->
                    <div class="col-md-12">
                      <!-- Member List -->
                      <div
                        class="row"
                        style="margin-top: 25px; margin-bottom: 25px"
                      >
                        <div
                          *ngIf="membersList.total === 0 && !isMembersLoading"
                        >
                          <mat-error> No member record found </mat-error>
                        </div>

                        <div
                          class="table-responsive"
                          *ngIf="membersList.total > 0"
                        >
                          <table mat-table [dataSource]="membersList.items">
                            <ng-container matColumnDef="memberName">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                style="font-weight: 900"
                              >
                                Member Name
                              </th>
                              <td mat-cell *matCellDef="let row">
                                <b>{{ row.lastName }}, {{ row.firstName }}</b>
                                <br />
                                {{ row.dateOfBirth }} (
                                {{
                                  row?.gender === 'M'
                                    ? 'Male'
                                    : row?.gender === 'F'
                                    ? 'Female'
                                    : row?.gender.toLowerCase()
                                }}
                                )
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                              <th mat-header-cell *matHeaderCellDef>Address</th>
                              <td mat-cell *matCellDef="let row">
                                {{ row.addressLine1 }} {{ row.addressLine2
                                }}<br />{{ row.city }} {{ row.state }}
                                {{ row.zip }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="phoneNumber">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                style="font-weight: 900"
                              >
                                Phone Number
                              </th>
                              <td mat-cell *matCellDef="let row">
                                <span *ngIf="row?.phoneNumber">
                                  {{ row?.phoneNumber | phoneFormat }}
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                style="font-weight: 900; text-align: right"
                              >
                                Action
                              </th>
                              <td
                                mat-cell
                                *matCellDef="let row"
                                style="text-align: right"
                              >
                                <!-- If not selected, display select button if selected display check icon -->
                                <button
                                  *ngIf="!isMemberSelected(row)"
                                  mat-raised-button
                                  color="primary"
                                  type="button"
                                  (click)="selectMember(row)"
                                >
                                  Select
                                </button>
                                <mat-icon
                                  style="color: #3ac47d"
                                  *ngIf="isMemberSelected(row)"
                                >
                                  check_circle
                                </mat-icon>
                              </td>
                            </ng-container>
                            <tr
                              mat-header-row
                              *matHeaderRowDef="displayedColumns; sticky: true"
                            ></tr>
                            <tr
                              mat-row
                              *matRowDef="let row; columns: displayedColumns"
                            ></tr>
                          </table>
                        </div>

                        <mat-paginator
                          #paginator
                          [length]="membersList.total"
                          [pageSize]="membersList.per_page"
                          [showFirstLastButtons]="true"
                          *ngIf="membersList.total > membersList.per_page"
                          (page)="getNext($event)"
                        >
                        </mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Selected Members -->
          <div class="col-md-4">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                <mat-icon>how_to_reg</mat-icon> Selected Members ({{
                  therapysessionForm.get('groupMembers')['controls'].length
                }})
              </label>

              <!-- // Display Selected Members -->
              <div
                formArrayName="groupMembers"
                style="max-height: 80vh; overflow-y: auto"
              >
                <div
                  *ngFor="
                    let selectedMember of therapysessionForm.get(
                      'groupMembers'
                    )['controls'];
                    let memberLoopIndex = index
                  "
                >
                  <div
                    class="row memberCardBox"
                    [formGroupName]="memberLoopIndex"
                  >
                    <!-- Display Member Name -->
                    <div class="col-9">
                      <label class="labelBold">
                        {{ selectedMember.controls['member'].value.lastName }},
                        {{ selectedMember.controls['member'].value.firstName }}
                        {{ selectedMember.controls['member'].value.middleName }}
                      </label>
                      <br />
                      <label>
                        {{
                          selectedMember.controls['member'].value.dateOfBirth
                        }}
                        (
                        {{
                          selectedMember.controls['member'].value?.gender ===
                          'M'
                            ? 'Male'
                            : selectedMember.controls['member'].value
                                ?.gender === 'F'
                            ? 'Female'
                            : selectedMember.controls[
                                'member'
                              ].value?.gender.toLowerCase()
                        }}
                        )
                      </label>
                    </div>
                    <div class="col-3" style="text-align: right">
                      <button
                        mat-icon-button
                        type="button"
                        color="warn"
                        matTooltip="Remove member"
                        (click)="removeMember(memberLoopIndex)"
                      >
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <!-- Mat Stepper Buttons -->
        <div class="row" style="margin-top: 20px">
          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button mat-raised-button type="button" matStepperPrevious>
              Back
            </button>

            <button
              mat-raised-button
              color="primary"
              type="button"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>
      <!-- Step 3 - Group Appointment Details -->
      <mat-step label="Appointment Details">
        <!-- Dividing the Sessions Screen into 2 components -->
        <div class="row">
          <!-- Left side is for appointment details -->
          <div
            class="col-md-4"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
            "
          >
            <!-- Session Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader"> Session Details </label>
              <div class="row">
                <!-- Visit Type -->
                <div class="col-md-12">
                  <label class="labelBold">
                    Please select a visit reason
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="visitReasonId"
                      placeholder="Visit Reasons"
                      (selectionChange)="visitReasonChanged()"
                    >
                      <mat-option
                        *ngFor="let visitReason of visitReasons"
                        [value]="visitReason.id"
                      >
                        {{ visitReason.reasonName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        therapysessionForm.controls['visitReasonId'].invalid
                      "
                    >
                      Please select one of the visit reason
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Session Date, time, duration  -->
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="labelBold">Start Date</label>
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          formControlName="sessionStartDate"
                          readonly
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <label class="labelBold">Start Time</label>
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          formControlName="sessionStartTime"
                          readonly
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <label class="labelBold">Duration</label>
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          placeholder="Duration"
                          formControlName="sessionDuration"
                          type="text"
                          required
                          maxlength="3"
                          pattern="\d*"
                        />
                        <span matSuffix>min</span>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['sessionDuration']
                              .invalid
                          "
                        >
                          Valid values 1 - 999
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- Session For, Type -->
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <label class="labelBold">
                        Appointment For
                        <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          formControlName="sessionFor"
                          placeholder="Appointment For"
                        >
                          <mat-option value="Group">Group</mat-option>
                          <mat-option value="Couples">Couples</mat-option>
                          <mat-option value="Family">Family</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['sessionFor'].invalid
                          "
                        >
                          Appointment For is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                      <label class="labelBold">
                        Appointment Type
                        <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          formControlName="sessionType"
                          placeholder="Appointment Type"
                        >
                          <mat-option value="In-Person Appointment">
                            In-Person
                          </mat-option>
                          <mat-option value="Tele-Health Appointment">
                            Tele-Health
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['sessionType'].invalid
                          "
                        >
                          Appointment Type is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- Status / Queue Type -->
                <div class="col-md-12" *ngIf="editGroupAppointmentId">
                  <label class="labelBold">
                    Status
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="queue"
                      placeholder="Status"
                      (selectionChange)="statusChanged($event)"
                    >
                      <mat-option
                        *ngFor="let queue of sessionQueues"
                        [value]="queue"
                      >
                        {{ queue }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="therapysessionForm.controls['queue'].invalid"
                    >
                      Please select one of the status
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Cancellation Reason -->
                <div
                  class="col-md-12"
                  *ngIf="
                    therapysessionForm.controls['queue'].value === 'Canceled'
                  "
                >
                  <label class="labelBold">
                    Cancellation Reason
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <textarea
                      cdkTextareaAutosize
                      matInput
                      formControlName="cancellationReason"
                      name="cancellationReason"
                    ></textarea>
                    <mat-error
                      *ngIf="
                        therapysessionForm.controls['cancellationReason']
                          .invalid
                      "
                    >
                      Please provide the cancellation reason(s)
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- Recurring Appointment -->
                <!-- Show only when scheduling a new one -->
                <div class="col-md-12" *ngIf="!editGroupAppointmentId">
                  <label class="labelBold">
                    Recurring appointment <span style="color: red">*</span>
                  </label>
                  <mat-checkbox
                    color="primary"
                    class="checkbox-margin"
                    formControlName="recurringAppointment"
                    (change)="recurringChanged($event)"
                  ></mat-checkbox>
                </div>

                <!-- Cancel Series if edit recurring is there -->
                <div
                  class="col-md-12"
                  *ngIf="
                    therapysessionForm.controls['editSeries'].value &&
                    therapysessionForm.controls['recurringAppointment'].value &&
                    therapysessionForm.controls['queue'].value === 'Canceled'
                  "
                >
                  <label class="labelBold">
                    Cancel Future Series <span style="color: red">*</span>
                  </label>
                  <mat-checkbox
                    color="primary"
                    class="checkbox-margin"
                    formControlName="cancelOccurance"
                  ></mat-checkbox>
                </div>
                <!-- Edit Recurring Series -->
                <div
                  class="col-md-12"
                  *ngIf="
                    editGroupAppointmentId &&
                    therapysessionForm.controls['recurringAppointment'].value
                  "
                >
                  <label class="labelBold">
                    Edit Recurring Series <span style="color: red">*</span>
                  </label>
                  <mat-checkbox
                    color="primary"
                    class="checkbox-margin"
                    formControlName="editSeries"
                  ></mat-checkbox>
                </div>

                <!-- Recurring Appointment details -->
                <div
                  class="col-md-12"
                  *ngIf="
                    (therapysessionForm.controls['recurringAppointment']
                      .value &&
                      !editGroupAppointmentId) ||
                    therapysessionForm.controls['editSeries'].value
                  "
                >
                  <div class="row">
                    <!-- Recurring Start Date -->
                    <div class="col-md-6">
                      <label class="labelBold">
                        Recurring Start <span style="color: red">*</span></label
                      >
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="recurringStart"
                          matInput
                          [matDatepicker]="recurringStartPicker"
                          placeholder="Start Date"
                          autoSlashDate
                          [min]="therapysessionForm.controls['start'].value"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="recurringStartPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #recurringStartPicker></mat-datepicker>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['recurringStart']
                              .invalid
                          "
                        >
                          Please select a valid date
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Recurring End Date -->
                    <div class="col-md-6">
                      <label class="labelBold">
                        Recurring End <span style="color: red">*</span></label
                      >
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="recurringEnd"
                          matInput
                          [min]="
                            therapysessionForm.controls['recurringStart'].value
                          "
                          [matDatepicker]="recurringEndPicker"
                          placeholder="End Date"
                          autoSlashDate
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="recurringEndPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #recurringEndPicker></mat-datepicker>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['recurringEnd'].invalid
                          "
                        >
                          Please select a valid date
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Recurring Frequency  -->
                    <div class="col-md-6">
                      <label class="labelBold">
                        Recurring Frequency <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <mat-select formControlName="recurringFrequency">
                          <mat-option value="Daily">Daily</mat-option>
                          <mat-option value="Weekday">
                            Weekday (Mon-Fri)
                          </mat-option>
                          <mat-option value="Custom">Custom</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['recurringFrequency']
                              .invalid
                          "
                        >
                          Please select the frequency
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Recurring Frequency -->
                    <div
                      class="col-md-6"
                      *ngIf="
                        therapysessionForm.controls['recurringFrequency']
                          .value === 'Custom'
                      "
                    >
                      <label class="labelBold">
                        Repeats every <span style="color: red">*</span>
                      </label>
                      <div class="row">
                        <div class="col-6">
                          <mat-form-field appearance="outline">
                            <input
                              formControlName="recurringFrequencyNumber"
                              matInput
                              type="number"
                              min="1"
                            />
                            <mat-error
                              *ngIf="
                                therapysessionForm.controls[
                                  'recurringFrequencyNumber'
                                ].invalid
                              "
                            >
                              Minimum number is 1
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="recurringFrequencyInterval"
                            >
                              <mat-option value="Day">Day</mat-option>
                              <mat-option value="Week">Week</mat-option>
                              <mat-option value="Month">Month</mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="
                                therapysessionForm.controls[
                                  'recurringFrequencyInterval'
                                ].invalid
                              "
                            >
                              Please select the interval
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <!-- Recurring Days  -->
                    <div
                      class="col-md-12"
                      *ngIf="
                        therapysessionForm.controls['recurringFrequency']
                          .value === 'Custom' &&
                        therapysessionForm.controls[
                          'recurringFrequencyInterval'
                        ].value === 'Week'
                      "
                    >
                      <label class="labelBold">
                        Recurring Days <span style="color: red">*</span>
                      </label>
                      <section
                        [formGroup]="therapysessionForm.controls.recurringDays"
                      >
                        <mat-checkbox color="primary" formControlName="sunday">
                          Sunday
                        </mat-checkbox>
                        <mat-checkbox color="primary" formControlName="monday">
                          Monday
                        </mat-checkbox>
                        <mat-checkbox color="primary" formControlName="tuesday">
                          Tuesday
                        </mat-checkbox>
                        <mat-checkbox
                          color="primary"
                          formControlName="wednesday"
                        >
                          Wednesday
                        </mat-checkbox>
                        <mat-checkbox
                          color="primary"
                          formControlName="thursday"
                        >
                          Thursday
                        </mat-checkbox>
                        <mat-checkbox color="primary" formControlName="friday">
                          Friday
                        </mat-checkbox>
                        <mat-checkbox
                          color="primary"
                          formControlName="saturday"
                        >
                          Saturday
                        </mat-checkbox>
                      </section>
                    </div>

                    <div
                      class="col-md-12"
                      *ngIf="
                        therapysessionForm.controls['recurringFrequency']
                          .value === 'Custom' &&
                        therapysessionForm.controls[
                          'recurringFrequencyInterval'
                        ].value === 'Month'
                      "
                    >
                      <label class="labelBold">
                        On Day <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="recurringFrequencyMonthNumber"
                          matInput
                          type="number"
                          min="1"
                        />
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls[
                              'recurringFrequencyMonthNumber'
                            ].invalid
                          "
                        >
                          Minimum number is 1
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Recurring Label -->
                    <div
                      class="col-md-12"
                      *ngIf="
                        therapysessionForm.controls['recurringFrequency'].value
                      "
                    >
                      <label>{{ getRecurringLabel() }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>

            <!-- Member Document Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader"> Request Documents </label>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="memberIntakeDocuments"
                      multiple
                      placeholder="Select documents"
                    >
                      <mat-select-trigger>
                        <mat-chip-listbox>
                          <mat-chip-row
                            *ngFor="
                              let intakeDocuments of therapysessionForm
                                .controls['memberIntakeDocuments'].value;
                              let i = index
                            "
                            (removed)="removeIntakeDocument(intakeDocuments, i)"
                          >
                            {{ intakeDocuments.documentName }}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip-row>
                        </mat-chip-listbox>
                      </mat-select-trigger>
                      <mat-optgroup
                        *ngFor="let documentGroup of intakeDocumentsList"
                        [label]="documentGroup.name"
                      >
                        <mat-option
                          *ngFor="let documentOption of documentGroup.documents"
                          [value]="documentOption"
                        >
                          {{ documentOption.documentName }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
          <!-- Middle Provider Details -->
          <div
            class="col-md-4"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
            "
          >
            <!-- Provider Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <div class="row">
                <!-- Provider Details -->
                <div class="col-md-12">
                  <label class="labelHeader"> Provider Details </label>
                  <div class="twoGridContainer">
                    <!-- Provider Name -->
                    <label class="labelBold">Provider Name</label>
                    <label class="labelText">
                      {{
                        therapysessionForm.controls['physicianLastName'].value
                      }},{{ ' ' }}
                      {{
                        therapysessionForm.controls['physicianFirstName'].value
                      }}{{ ' ' }}
                      {{
                        therapysessionForm.controls['physicianMiddleName'].value
                      }}
                    </label>
                    <!-- Provider Email -->
                    <label class="labelBold">Provider Email</label>
                    <label class="labelText">
                      {{ therapysessionForm.controls['physicianEmail'].value }}
                    </label>
                    <!-- Provider Phone -->
                    <label class="labelBold">Provider Phone</label>
                    <label class="labelText">
                      <span
                        *ngIf="
                          therapysessionForm.controls['physicianPhone'].value
                        "
                      >
                        {{
                          therapysessionForm.controls['physicianPhone'].value
                            | phoneFormat
                        }}
                      </span>
                    </label>
                  </div>
                </div>

                <!-- Supervising Rendering Provider -->
                <div class="col-md-12" style="margin-top: 25px">
                  <label class="labelBold">
                    Is there a supervising rendering provider?
                    <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-radio-group
                    formControlName="supervisingRenderingProvider"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                    (change)="supervisingProviderChanged($event)"
                  >
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- If Supervising Rendering Provider is Yes -->
                <div
                  class="col-md-12"
                  *ngIf="
                    therapysessionForm.controls['supervisingRenderingProvider']
                      .value === 'Yes'
                  "
                  style="margin-top: 25px"
                >
                  <label class="labelBold">
                    Supervising Rendering Provider
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="supervisingRPId"
                      (selectionChange)="newSupervisorSelected()"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Supervising Provider Name"
                          noEntriesFoundLabel="'No matching supervising provider found'"
                          [(ngModel)]="searchSupervisingProviderName"
                          (ngModelChange)="filterSupervisingProvider()"
                          [ngModelOptions]="{ standalone: true }"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        [value]="supervisor.id"
                        *ngFor="
                          let supervisor of filteredSupervisingProviderList
                        "
                      >
                        {{ supervisor.lastName }}, {{ supervisor.firstName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        therapysessionForm.controls['supervisingRPId'].invalid
                      "
                    >
                      Please select the supervising provider
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- Referring Provider -->
                <div class="col-md-12" style="margin-top: 25px">
                  <label class="labelBold">
                    Is there a referring provider?
                    <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-radio-group
                    formControlName="referringProvider"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                    (change)="referringProviderChanged($event)"
                  >
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div
                  class="col-md-12"
                  style="text-align: right"
                  *ngIf="
                    therapysessionForm.controls['referringProvider'].value ===
                    'Yes'
                  "
                >
                  <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    (click)="selectReferringProvider()"
                  >
                    Select New Referring Provider
                  </button>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="
                    therapysessionForm.controls['referringProvider'].value ===
                      'Yes' &&
                    therapysessionForm.controls['referringProviderId'].valid
                  "
                >
                  <label class="labelHeader">
                    Referring Provider Details
                  </label>
                  <div class="twoGridContainer">
                    <!-- Referring Provider Name -->
                    <label class="labelBold">Provider Name</label>
                    <label class="labelText">
                      {{
                        therapysessionForm.controls['referringProviderLastName']
                          .value
                      }},{{ ' ' }}
                      {{
                        therapysessionForm.controls[
                          'referringProviderFirstName'
                        ].value
                      }}{{ ' ' }}
                      {{
                        therapysessionForm.controls[
                          'referringProviderMiddleName'
                        ].value
                      }}
                    </label>
                    <!-- Referring Provider NPI -->
                    <label class="labelBold">Provider NPI</label>
                    <label class="labelText">
                      {{
                        therapysessionForm.controls['referringProviderNPI']
                          .value
                      }}
                    </label>
                    <!-- Referring Provider Taxonomy -->
                    <label class="labelBold">Provider Taxonomy</label>
                    <label class="labelText">
                      {{
                        therapysessionForm.controls['referringProviderTaxonomy']
                          .value
                      }}
                    </label>
                  </div>
                </div>
              </div>
            </mat-card>

            <!-- General Billing Details -->
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader"> Billing Details </label>
                </div>

                <!-- Select Facility -->
                <div class="col-md-12">
                  <label class="labelBold">
                    Service Location
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="billingProviderRefId"
                      placeholder="Select a facility"
                    >
                      <mat-option
                        *ngFor="let facility of selectedProviderFacilities"
                        [value]="facility.id"
                      >
                        {{ facility.facilityName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        therapysessionForm.controls['billingProviderRefId']
                          .invalid
                      "
                    >
                      Please select one of the facility
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-12" style="margin-top: 5px">
                  <label class="labelBold">
                    Billing Type <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="billingType"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                  >
                    <mat-radio-button value="Self-Pay">
                      Self-Pay
                    </mat-radio-button>
                    <mat-radio-button value="Insurance">
                      Insurance
                    </mat-radio-button>
                    <mat-radio-button value="Other"> Other </mat-radio-button>
                  </mat-radio-group>
                  <mat-error
                    *ngIf="therapysessionForm.controls['billingType'].invalid"
                  >
                    Please select the billing type
                  </mat-error>
                </div>

                <div class="col-md-12" style="margin-top: 12px">
                  <label class="labelBold">
                    Is this an emergency service?
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 24c on the CMS1500)
                    </span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="emgType"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                  >
                    <mat-radio-button value="NA"> NA </mat-radio-button>
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-6" style="margin-top: 12px">
                  <label class="labelBold">
                    EPSDT
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 24H on the CMS1500)
                    </span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="epsdtFamilyPlan"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                    (change)="epsdtTypeChanged($event)"
                  >
                    <mat-radio-button value="NA"> NA </mat-radio-button>
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div
                  class="col-md-6"
                  style="margin-bottom: 12px"
                  *ngIf="
                    therapysessionForm.controls['epsdtFamilyPlan'].value ===
                    'Yes'
                  "
                >
                  <label class="labelBold">
                    EPSDT Condition Indicator
                    <span style="opacity: 0.5; font-size: 12px">
                      (Shaded area of Box 24H on the CMS1500)
                    </span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="epsdtType" placeholder="">
                      <mat-option value=""></mat-option>
                      <mat-option
                        [value]="condition.id"
                        *ngFor="let condition of epsdtConditionTypes"
                      >
                        {{ condition.id }} - {{ condition.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- Member Conditions -->
                <div class="col-md-12" style="margin-top: 12px">
                  <label class="labelBold">
                    Is the Member Condition Related To
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 10 on the CMS1500)
                    </span>
                  </label>
                </div>
                <!-- Employment -->
                <div
                  class="col-md-12"
                  style="margin-top: 12px; padding-left: 45px"
                >
                  <label class="labelBold">
                    Employment
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 10a on the CMS1500)
                    </span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="conditionRTEmployment"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                  >
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- Auto Accident -->
                <div
                  class="col-md-12"
                  style="margin-top: 12px; padding-left: 45px"
                >
                  <div class="row">
                    <!-- General Question -->
                    <div class="col">
                      <label class="labelBold">
                        Auto Accident
                        <span style="opacity: 0.5; font-size: 12px">
                          (Box 10b on the CMS1500)
                        </span>
                      </label>
                      <br />
                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="conditionRTAutoAccident"
                        style="column-gap: 15px; display: flex; flex-wrap: wrap"
                        color="primary"
                        (change)="autoAccidentTypeChanged($event)"
                      >
                        <mat-radio-button value="Yes"> Yes </mat-radio-button>
                        <mat-radio-button value="No"> No </mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <!-- State Question -->
                    <div
                      class="col"
                      *ngIf="
                        therapysessionForm.controls['conditionRTAutoAccident']
                          .value === 'Yes'
                      "
                    >
                      <label class="labelText">
                        Place (State) <span style="color: red">*</span></label
                      >
                      <mat-form-field appearance="outline">
                        <mat-select
                          formControlName="conditionRTAutoState"
                          placeholder="State"
                        >
                          <mat-option
                            [value]="state.id"
                            *ngFor="let state of usStateList"
                          >
                            {{ state.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['conditionRTAutoState']
                              .invalid
                          "
                        >
                          Please select the state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Date Question -->
                    <div
                      class="col"
                      *ngIf="
                        therapysessionForm.controls['conditionRTAutoAccident']
                          .value === 'Yes' ||
                        therapysessionForm.controls['conditionRTOtherAccident']
                          .value === 'Yes'
                      "
                    >
                      <label class="labelText">
                        Accident Date <span style="color: red">*</span></label
                      >
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="conditionRTAutoDate"
                          matInput
                          [matDatepicker]="accidentDatePicker"
                          placeholder="Date"
                          [max]="currentTime"
                          autoSlashDate
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="accidentDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #accidentDatePicker></mat-datepicker>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls['conditionRTAutoDate']
                              .invalid
                          "
                        >
                          Please select a valid date
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Qualifier Question -->
                    <div
                      class="col"
                      *ngIf="
                        therapysessionForm.controls['conditionRTAutoAccident']
                          .value === 'Yes'
                      "
                    >
                      <label class="labelText">
                        Qualifier <span style="color: red">*</span></label
                      >
                      <mat-form-field appearance="outline">
                        <mat-select
                          formControlName="conditionRTAutoQualifier"
                          placeholder="Qualifier"
                        >
                          <mat-option
                            [value]="qualifier.id"
                            *ngFor="let qualifier of autoAccidentQualifierList"
                          >
                            {{ qualifier.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            therapysessionForm.controls[
                              'conditionRTAutoQualifier'
                            ].invalid
                          "
                        >
                          Please select the qualifier
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- Other Accident -->
                <div
                  class="col-md-12"
                  style="margin-top: 12px; padding-left: 45px"
                >
                  <label class="labelBold">
                    Other Accident
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 10c on the CMS1500)
                    </span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="conditionRTOtherAccident"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                    (change)="otherAccidentTypeChanged($event)"
                  >
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-card>
          </div>
          <!-- Right side is for group members -->
          <div
            class="col-md-4"
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;
            "
          >
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelHeader">
                <mat-icon>how_to_reg</mat-icon> Selected Members ({{
                  therapysessionForm.get('groupMembers')['controls'].length
                }})
              </label>

              <!-- // Display Selected Members -->
              <div
                formArrayName="groupMembers"
                style="max-height: 80vh; overflow-y: auto"
              >
                <div
                  *ngFor="
                    let selectedMember of therapysessionForm.get(
                      'groupMembers'
                    )['controls'];
                    let memberLoopIndex = index
                  "
                >
                  <div
                    class="row memberCardBox"
                    [formGroupName]="memberLoopIndex"
                  >
                    <!-- Display Member Name -->
                    <div class="col-9">
                      <label class="labelBold">
                        {{ selectedMember.controls['member'].value.lastName }},
                        {{ selectedMember.controls['member'].value.firstName }}
                        {{ selectedMember.controls['member'].value.middleName }}
                      </label>
                      <br />
                      <label>
                        {{
                          selectedMember.controls['member'].value.dateOfBirth
                        }}
                        (
                        {{
                          selectedMember.controls['member'].value?.gender ===
                          'M'
                            ? 'Male'
                            : selectedMember.controls['member'].value
                                ?.gender === 'F'
                            ? 'Female'
                            : selectedMember.controls[
                                'member'
                              ].value?.gender.toLowerCase()
                        }}
                        )
                      </label>
                    </div>
                    <div class="col-3" style="text-align: right">
                      <button
                        mat-icon-button
                        type="button"
                        color="warn"
                        matTooltip="Remove member"
                        (click)="removeMember(memberLoopIndex)"
                      >
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <!-- Mat Stepper Buttons -->
        <div class="row" style="margin-top: 20px">
          <!-- Edit Series Disclaimer -->
          <div
            class="col-md-12"
            *ngIf="
              therapysessionForm.controls['editSeries'].value &&
              editGroupAppointmentId
            "
          >
            <mat-error style="justify-content: center; flex-wrap: wrap">
              You are editing the entire group appointment series. Only the
              future appointments in the series will be modified.
            </mat-error>
          </div>
          <!-- Buttons -->
          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button mat-raised-button type="button" matStepperPrevious>
              Back
            </button>

            <button
              mat-raised-button
              color="primary"
              type="button"
              [disabled]="therapysessionForm.invalid || processing"
              (click)="submitForm()"
            >
              Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
