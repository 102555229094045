import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICdrisc } from 'src/app/_models/assessments/general/cdrisc.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  CdriscApiService
} from 'src/app/_services/assessments/general/cdrisc.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-cdrisc-assessment-dialog',
  templateUrl: './cdrisc-assessment-dialog.component.html',
  styleUrls: ['./cdrisc-assessment-dialog.component.css']
})
export class CdriscAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public cdriscAssessmentApiService: CdriscApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public cdriscAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  cdriscAssessment: ICdrisc;
  cdriscAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.cdriscAssessmentForm, this.errors);

    this.loadCdrisc();
  }

  loadCdrisc() {
    this.cdriscAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.cdriscAssessmentApiService
        .getCdriscDetails(this.assessmentId)
        .subscribe((cdriscAssessment) => {
          if (cdriscAssessment.data) {

            this.cdriscAssessmentForm.controls['adaptability'].setValue(cdriscAssessment.data.adaptability.answer);
            this.cdriscAssessmentForm.controls['resilience'].setValue(cdriscAssessment.data.resilience.answer);
            this.cdriscAssessmentForm.controls['senseOfHumor'].setValue(cdriscAssessment.data.senseOfHumor.answer);
            this.cdriscAssessmentForm.controls['stressGrowth'].setValue(cdriscAssessment.data.stressGrowth.answer);
            this.cdriscAssessmentForm.controls['recoveryAbility'].setValue(cdriscAssessment.data.recoveryAbility.answer);
            this.cdriscAssessmentForm.controls['goalPersistence'].setValue(cdriscAssessment.data.goalPersistence.answer);
            this.cdriscAssessmentForm.controls['pressureFocus'].setValue(cdriscAssessment.data.pressureFocus.answer);
            this.cdriscAssessmentForm.controls['failureResilience'].setValue(cdriscAssessment.data.failureResilience.answer);
            this.cdriscAssessmentForm.controls['personalStrength'].setValue(cdriscAssessment.data.personalStrength.answer);
            this.cdriscAssessmentForm.controls['emotionalManagement'].setValue(cdriscAssessment.data.emotionalManagement.answer);

            this.riskScore = cdriscAssessment.data.riskScore;
          }
        });
    }
  }
  onCdriscFormSubmit() {
    if (this.cdriscAssessmentForm.invalid) {
      this.cdriscAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.cdriscAssessment = Object.assign({}, this.cdriscAssessment);
    this.cdriscAssessment.patientId =
      this.cdriscAssessmentForm.controls['patientId'].value;
    this.cdriscAssessment.sessionId =
      this.cdriscAssessmentForm.controls['sessionId'].value;
    this.cdriscAssessment.assessmentRequestId =
      this.cdriscAssessmentForm.controls['assessmentRequestId'].value;

    this.cdriscAssessment.adaptability = {
      answer:
        this.cdriscAssessmentForm.controls['adaptability'].value,
    };
    this.cdriscAssessment.resilience = {
      answer:
        this.cdriscAssessmentForm.controls['resilience'].value,
    };
    this.cdriscAssessment.senseOfHumor = {
      answer:
        this.cdriscAssessmentForm.controls['senseOfHumor'].value,
    };
    this.cdriscAssessment.stressGrowth = {
      answer:
        this.cdriscAssessmentForm.controls['stressGrowth'].value,
    };
    this.cdriscAssessment.recoveryAbility = {
      answer:
        this.cdriscAssessmentForm.controls['recoveryAbility'].value,
    };
    this.cdriscAssessment.goalPersistence = {
      answer:
        this.cdriscAssessmentForm.controls['goalPersistence'].value,
    };
    this.cdriscAssessment.pressureFocus = {
      answer:
        this.cdriscAssessmentForm.controls['pressureFocus'].value,
    };
    this.cdriscAssessment.failureResilience = {
      answer:
        this.cdriscAssessmentForm.controls['failureResilience'].value,
    };
    this.cdriscAssessment.personalStrength = {
      answer:
        this.cdriscAssessmentForm.controls['personalStrength'].value,
    };
    this.cdriscAssessment.emotionalManagement = {
      answer:
        this.cdriscAssessmentForm.controls['emotionalManagement'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.cdriscAssessment.accessCode = this.accessCode;

      this.cdriscAssessmentApiService
        .addGuestCdrisc(this.cdriscAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Connor-Davidson Resilience Scale(CD-RISC 10) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Connor-Davidson Resilience Scale(CD-RISC 10) Assessment'
            );
          }
        );
    } else {
      this.cdriscAssessmentApiService
        .addCdrisc(this.cdriscAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Connor-Davidson Resilience Scale(CD-RISC 10) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Connor-Davidson Resilience Scale(CD-RISC 10) Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'adaptability': [
      { type: 'required', message: '1. I am able to adapt when changes occur. is required' },
    ],
    'resilience': [
      { type: 'required', message: '2. I can deal with whatever comes my way. is required' },
    ],
    'senseOfHumor': [
      { type: 'required', message: '3. I try to see the humorous side of things when I am faced with problems. is required' },
    ],
    'stressGrowth': [
      { type: 'required', message: '4. Having to cope with stress can make me stronger. is required' },
    ],
    'recoveryAbility': [
      { type: 'required', message: '5. I tend to bounce back after illness, injury or other hardships. is required' },
    ],
    'goalPersistence': [
      { type: 'required', message: '6. I believe I can achieve my goals, even if there are obstacles. is required' },
    ],
    'pressureFocus': [
      { type: 'required', message: '7. Under pressure, I stay focused and think clearly. is required' },
    ],
    'failureResilience': [
      { type: 'required', message: '8. I am not easily discouraged by failure. is required' },
    ],
    'personalStrength': [
      { type: 'required', message: '9. I think of myself as a strong person when dealing with life\'s challenges and difficulties.is required' },
    ],
    'emotionalManagement': [
      { type: 'required', message: '10. I am able to handle unpleasant or painful feelings like sadness, fear, and anger. is required' },
    ],
  };

  private buildForm() {
    this.cdriscAssessmentForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),

      adaptability: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      resilience: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      senseOfHumor: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      stressGrowth: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      recoveryAbility: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      goalPersistence: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      pressureFocus: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      failureResilience: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      personalStrength: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      emotionalManagement: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.cdriscAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.cdriscAssessmentForm) {
      return;
    }
    const form = this.cdriscAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
