<mat-card-title>ASAM</mat-card-title>

<div class="chip-container" style="margin-bottom: 15px">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let subTab of asamTabs"
      [selected]="selectedTab === subTab.value"
      (click)="selectTab(subTab.value)"
      selectable="true"
      [class]="
        selectedTab === subTab.value ? 'mat-chip-active' : 'mat-chip-inactive'
      "
    >
      {{ subTab.label }}
    </mat-chip>
  </mat-chip-list>
</div>

<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <!-- Asam Assessment list -->
  <ng-container *ngIf="selectedTab === 'Assessments'">
    <div class="row" style="margin-bottom: 15px">
      <div class="col-md-12" style="text-align: right">
        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="addCaseTreatmentAsam()"
        >
          + Add ASAM Assessment
        </button>
      </div>
    </div>

    <div *ngIf="data.total === 0">
      <mat-error> No ASAM Assessments found </mat-error>
    </div>

    <div *ngIf="isLoadingResults || apiError">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>
      <mat-error *ngIf="apiError">
        An error occurred while retrieving ASAM Assessments records
      </mat-error>
    </div>

    <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
      <table
        mat-table
        [dataSource]="data.items"
        class="mat-elevation-z8"
        matSort
        matSortDisableClear
      >
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef>Recorded Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdDate | date : 'MM/dd/YYYY' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Action
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            style="text-align: right"
          >
            <button
              type="button"
              mat-raised-button
              class="btn btn-just-icon btn-white btn-fab btn-round"
              color="accent"
              (click)="editCaseTreatmentAsam(row)"
            >
              <i class="material-icons text_align-center">edit</i>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [showFirstLastButtons]="true"
        [length]="data.total"
        [pageSize]="data.per_page"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
      ></mat-paginator>
    </div>
  </ng-container>

  <!-- Progress Report -->
  <ng-container *ngIf="selectedTab === 'Progress Report'">
    <app-treatment-asam-report [case]="case"></app-treatment-asam-report>
  </ng-container>
</div>
