import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentReviewProfile } from 'src/app/_models/case/treatment/review/treatmentreviewprofile.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TreatmentReviewProfileApiService {
  constructor(private httpClient: HttpClient) {}

  getTreatmentReviewProfileDetails(
    treatmentreviewprofileId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/treatmentreviewprofile/` + treatmentreviewprofileId
    );
  }

  updateTreatmentReviewProfile(
    thisTreatmentReviewProfile: ITreatmentReviewProfile
  ): Observable<ITreatmentReviewProfile> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITreatmentReviewProfile>(
        `${environment.apiUrl}/treatmentreviewprofile`,
        thisTreatmentReviewProfile,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
