import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IDosi } from 'src/app/_models/assessments/general/dosi.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  DosiApiService
} from 'src/app/_services/assessments/general/dosi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-differention-of-self-inventory',
  templateUrl: './differention-of-self-inventory.component.html',
  styleUrls: ['./differention-of-self-inventory.component.css']
})
export class DifferentionOfSelfInventoryComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public dosiAssessmentApiService: DosiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public dosiAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  dosiAssessment: IDosi;
  dosiAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.dosiAssessmentForm, this.errors);

    this.loadDosi();
  }

  loadDosi() {
    this.dosiAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.dosiAssessmentApiService
        .getDosiDetails(this.assessmentId)
        .subscribe((dosiAssessment) => {
          if (dosiAssessment.data) {

            this.dosiAssessmentForm.controls['calmUnderStress'].setValue(dosiAssessment.data.calmUnderStress.answer);
            this.dosiAssessmentForm.controls['needsEncouragement'].setValue(dosiAssessment.data.needsEncouragement.answer);
            this.dosiAssessmentForm.controls['strongSelfIdentity'].setValue(dosiAssessment.data.strongSelfIdentity.answer);
            this.dosiAssessmentForm.controls['distanceWhenClose'].setValue(dosiAssessment.data.distanceWhenClose.answer);
            this.dosiAssessmentForm.controls['botheredByCriticism'].setValue(dosiAssessment.data.botheredByCriticism.answer);
            this.dosiAssessmentForm.controls['emotionsCloudThinking'].setValue(dosiAssessment.data.emotionsCloudThinking.answer);
            this.dosiAssessmentForm.controls['uncomfortableWhenClose'].setValue(dosiAssessment.data.uncomfortableWhenClose.answer);
            this.dosiAssessmentForm.controls['approvalNeed'].setValue(dosiAssessment.data.approvalNeed.answer);
            this.dosiAssessmentForm.controls['emotionalRollerCoaster'].setValue(dosiAssessment.data.emotionalRollerCoaster.answer);
            this.dosiAssessmentForm.controls['unfazedByUnchangeables'].setValue(dosiAssessment.data.unfazedByUnchangeables.answer);
            this.dosiAssessmentForm.controls['sensitiveToCriticism'].setValue(dosiAssessment.data.sensitiveToCriticism.answer);
            this.dosiAssessmentForm.controls['selfAccepting'].setValue(dosiAssessment.data.selfAccepting.answer);
            this.dosiAssessmentForm.controls['appeaseOthers'].setValue(dosiAssessment.data.appeaseOthers.answer);
            this.dosiAssessmentForm.controls['dwellsOnArguments'].setValue(dosiAssessment.data.dwellsOnArguments.answer);
            this.dosiAssessmentForm.controls['runFromIntensity'].setValue(dosiAssessment.data.runFromIntensity.answer);
            this.dosiAssessmentForm.controls['holdsOntoUpset'].setValue(dosiAssessment.data.holdsOntoUpset.answer);
            this.dosiAssessmentForm.controls['unsureAlone'].setValue(dosiAssessment.data.unsureAlone.answer);
            this.dosiAssessmentForm.controls['hurtSensitivity'].setValue(dosiAssessment.data.hurtSensitivity.answer);
            this.dosiAssessmentForm.controls['esteemDependsOnOthers'].setValue(dosiAssessment.data.esteemDependsOnOthers.answer);
            this.dosiAssessmentForm.controls['stableUnderStress'].setValue(dosiAssessment.data.stableUnderStress.answer);

            this.riskScore = dosiAssessment.data.riskScore;
          }
        });
    }
  }
  onDosiFormSubmit() {
    if (this.dosiAssessmentForm.invalid) {
      this.dosiAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.dosiAssessment = Object.assign({}, this.dosiAssessment);
    this.dosiAssessment.patientId =
      this.dosiAssessmentForm.controls['patientId'].value;
    this.dosiAssessment.sessionId =
      this.dosiAssessmentForm.controls['sessionId'].value;
    this.dosiAssessment.assessmentRequestId =
      this.dosiAssessmentForm.controls['assessmentRequestId'].value;

    this.dosiAssessment.calmUnderStress = { answer: this.dosiAssessmentForm.controls['calmUnderStress'].value };
    this.dosiAssessment.needsEncouragement = { answer: this.dosiAssessmentForm.controls['needsEncouragement'].value };
    this.dosiAssessment.strongSelfIdentity = { answer: this.dosiAssessmentForm.controls['strongSelfIdentity'].value };
    this.dosiAssessment.distanceWhenClose = { answer: this.dosiAssessmentForm.controls['distanceWhenClose'].value };
    this.dosiAssessment.botheredByCriticism = { answer: this.dosiAssessmentForm.controls['botheredByCriticism'].value };
    this.dosiAssessment.emotionsCloudThinking = { answer: this.dosiAssessmentForm.controls['emotionsCloudThinking'].value };
    this.dosiAssessment.uncomfortableWhenClose = { answer: this.dosiAssessmentForm.controls['uncomfortableWhenClose'].value };
    this.dosiAssessment.approvalNeed = { answer: this.dosiAssessmentForm.controls['approvalNeed'].value };
    this.dosiAssessment.emotionalRollerCoaster = { answer: this.dosiAssessmentForm.controls['emotionalRollerCoaster'].value };
    this.dosiAssessment.unfazedByUnchangeables = { answer: this.dosiAssessmentForm.controls['unfazedByUnchangeables'].value };
    this.dosiAssessment.sensitiveToCriticism = { answer: this.dosiAssessmentForm.controls['sensitiveToCriticism'].value };
    this.dosiAssessment.selfAccepting = { answer: this.dosiAssessmentForm.controls['selfAccepting'].value };
    this.dosiAssessment.appeaseOthers = { answer: this.dosiAssessmentForm.controls['appeaseOthers'].value };
    this.dosiAssessment.dwellsOnArguments = { answer: this.dosiAssessmentForm.controls['dwellsOnArguments'].value };
    this.dosiAssessment.runFromIntensity = { answer: this.dosiAssessmentForm.controls['runFromIntensity'].value };
    this.dosiAssessment.holdsOntoUpset = { answer: this.dosiAssessmentForm.controls['holdsOntoUpset'].value };
    this.dosiAssessment.unsureAlone = { answer: this.dosiAssessmentForm.controls['unsureAlone'].value };
    this.dosiAssessment.hurtSensitivity = { answer: this.dosiAssessmentForm.controls['hurtSensitivity'].value };
    this.dosiAssessment.esteemDependsOnOthers = { answer: this.dosiAssessmentForm.controls['esteemDependsOnOthers'].value };
    this.dosiAssessment.stableUnderStress = { answer: this.dosiAssessmentForm.controls['stableUnderStress'].value };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.dosiAssessment.accessCode = this.accessCode;

      this.dosiAssessmentApiService
        .addGuestDosi(this.dosiAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Differentiation of Self Inventory - Short Form Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Differentiation of Self Inventory - Short Form Assessment'
            );
          }
        );
    } else {
      this.dosiAssessmentApiService
        .addDosi(this.dosiAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Differentiation of Self Inventory - Short Form Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Differentiation of Self Inventory - Short Form Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {

  };

  private buildForm() {
    this.dosiAssessmentForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),

      calmUnderStress: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      needsEncouragement: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      strongSelfIdentity: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      distanceWhenClose: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      botheredByCriticism: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      emotionsCloudThinking: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      uncomfortableWhenClose: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      approvalNeed: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      emotionalRollerCoaster: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      unfazedByUnchangeables: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sensitiveToCriticism: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      selfAccepting: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      appeaseOthers: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      dwellsOnArguments: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      runFromIntensity: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      holdsOntoUpset: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      unsureAlone: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      hurtSensitivity: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      esteemDependsOnOthers: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      stableUnderStress: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.dosiAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.dosiAssessmentForm) {
      return;
    }
    const form = this.dosiAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
