<div style="margin-top: 10px; padding: 10px">
  <div class="row" style="margin-bottom: 15px" *ngIf="displayAddButton">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="startNewReview()"
      >
        + Add Review
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No Reviews found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>
    <mat-error *ngIf="apiError">
      An error occurred while retrieving Review records
    </mat-error>
  </div>

  <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
    <table
      mat-table
      [dataSource]="data.items"
      class="mat-elevation-z8"
      matSort
      matSortDisableClear
    >
      <ng-container matColumnDef="planName">
        <th mat-header-cell *matHeaderCellDef>Review Name</th>
        <td mat-cell *matCellDef="let row">
          <span
            style="color: #2196f3; font-weight: 440; cursor: pointer"
            (click)="setSelectedReview(row)"
          >
            {{ row.planName }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="planNumber">
        <th mat-header-cell *matHeaderCellDef>Plan Number</th>
        <td mat-cell *matCellDef="let row">{{ row.planNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="versionNumber">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td mat-cell *matCellDef="let row">{{ row.versionNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="reviewDate">
        <th mat-header-cell *matHeaderCellDef>Review Date</th>
        <td mat-cell *matCellDef="let row">
          <span
            style="color: #2196f3; font-weight: 440; cursor: pointer"
            (click)="setSelectedReview(row)"
          >
            {{ row.reviewDate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="reviewPeriodStart">
        <th mat-header-cell *matHeaderCellDef>Review Period Start Date</th>
        <td mat-cell *matCellDef="let row">{{ row.reviewPeriodStart }}</td>
      </ng-container>
      <ng-container matColumnDef="reviewPeriodEnd">
        <th mat-header-cell *matHeaderCellDef>Review Period End Date</th>
        <td mat-cell *matCellDef="let row">{{ row.reviewPeriodEnd }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <button
            mat-raised-button
            class="btn btn-just-icon btn-white btn-fab btn-round"
            color="accent"
            (click)="editReview(row)"
          >
            <i class="material-icons text_align-center">edit</i>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    ></mat-paginator>
  </div>
</div>
