import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ISexualAddiction } from 'src/app/_models/assessments/addiction/sexualaddiction.model';
import {
  SexualAddictionApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-sexual-addiction',
  templateUrl: './sexual-addiction.component.html',
  styleUrls: ['./sexual-addiction.component.css'],
})
export class SexualAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public sexualAddictionApiService: SexualAddictionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public sexualAddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  sexualAddictionAssessment: ISexualAddiction;
  sexualAddictionAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.sexualAddictionForm, this.errors);

    this.loadSexualAddictionAssessment();
  }

  loadSexualAddictionAssessment() {
    this.sexualAddictionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.sexualAddictionApiService
        .getSexualAddictionDetails(this.assessmentId)
        .subscribe((sexualAddictionAssessment) => {
          if (sexualAddictionAssessment.data) {
            this.sexualAddictionForm.controls['q1'].setValue(
              sexualAddictionAssessment.data.q1.answer
            );
            this.sexualAddictionForm.controls['q2'].setValue(
              sexualAddictionAssessment.data.q2.answer
            );
            this.sexualAddictionForm.controls['q3'].setValue(
              sexualAddictionAssessment.data.q3.answer
            );
            this.sexualAddictionForm.controls['q4'].setValue(
              sexualAddictionAssessment.data.q4.answer
            );
            this.sexualAddictionForm.controls['q5'].setValue(
              sexualAddictionAssessment.data.q5.answer
            );
            this.sexualAddictionForm.controls['q6'].setValue(
              sexualAddictionAssessment.data.q6.answer
            );
            this.sexualAddictionForm.controls['q7'].setValue(
              sexualAddictionAssessment.data.q7.answer
            );
            this.sexualAddictionForm.controls['q8'].setValue(
              sexualAddictionAssessment.data.q8.answer
            );
            this.sexualAddictionForm.controls['q9'].setValue(
              sexualAddictionAssessment.data.q9.answer
            );
            this.sexualAddictionForm.controls['q10'].setValue(
              sexualAddictionAssessment.data.q10.answer
            );
            this.sexualAddictionForm.controls['q11'].setValue(
              sexualAddictionAssessment.data.q11.answer
            );
            this.sexualAddictionForm.controls['q12'].setValue(
              sexualAddictionAssessment.data.q12.answer
            );
            this.sexualAddictionForm.controls['q13'].setValue(
              sexualAddictionAssessment.data.q13.answer
            );
            this.sexualAddictionForm.controls['q14'].setValue(
              sexualAddictionAssessment.data.q14.answer
            );
            this.sexualAddictionForm.controls['q15'].setValue(
              sexualAddictionAssessment.data.q15.answer
            );
            this.sexualAddictionForm.controls['q16'].setValue(
              sexualAddictionAssessment.data.q16.answer
            );
            this.sexualAddictionForm.controls['q17'].setValue(
              sexualAddictionAssessment.data.q17.answer
            );
            this.sexualAddictionForm.controls['q18'].setValue(
              sexualAddictionAssessment.data.q18.answer
            );
            this.sexualAddictionForm.controls['q19'].setValue(
              sexualAddictionAssessment.data.q19.answer
            );
            this.sexualAddictionForm.controls['q20'].setValue(
              sexualAddictionAssessment.data.q20.answer
            );
            this.sexualAddictionForm.controls['q21'].setValue(
              sexualAddictionAssessment.data.q21.answer
            );
            this.sexualAddictionForm.controls['q22'].setValue(
              sexualAddictionAssessment.data.q22.answer
            );
            this.sexualAddictionForm.controls['q23'].setValue(
              sexualAddictionAssessment.data.q23.answer
            );
            this.sexualAddictionForm.controls['q24'].setValue(
              sexualAddictionAssessment.data.q24.answer
            );
            this.sexualAddictionForm.controls['q25'].setValue(
              sexualAddictionAssessment.data.q25.answer
            );
            this.sexualAddictionForm.controls['q26'].setValue(
              sexualAddictionAssessment.data.q26.answer
            );
            this.sexualAddictionForm.controls['q27'].setValue(
              sexualAddictionAssessment.data.q27.answer
            );
            this.sexualAddictionForm.controls['q28'].setValue(
              sexualAddictionAssessment.data.q28.answer
            );
            this.sexualAddictionForm.controls['q29'].setValue(
              sexualAddictionAssessment.data.q29.answer
            );
            this.sexualAddictionForm.controls['q30'].setValue(
              sexualAddictionAssessment.data.q30.answer
            );
            this.sexualAddictionForm.controls['q31'].setValue(
              sexualAddictionAssessment.data.q31.answer
            );
            this.sexualAddictionForm.controls['q32'].setValue(
              sexualAddictionAssessment.data.q32.answer
            );
            this.sexualAddictionForm.controls['q33'].setValue(
              sexualAddictionAssessment.data.q33.answer
            );
            this.sexualAddictionForm.controls['q34'].setValue(
              sexualAddictionAssessment.data.q34.answer
            );
            this.sexualAddictionForm.controls['q35'].setValue(
              sexualAddictionAssessment.data.q35.answer
            );
            this.sexualAddictionForm.controls['q36'].setValue(
              sexualAddictionAssessment.data.q36.answer
            );
            this.sexualAddictionForm.controls['q37'].setValue(
              sexualAddictionAssessment.data.q37.answer
            );
            this.sexualAddictionForm.controls['q38'].setValue(
              sexualAddictionAssessment.data.q38.answer
            );
            this.sexualAddictionForm.controls['q39'].setValue(
              sexualAddictionAssessment.data.q39.answer
            );
            this.sexualAddictionForm.controls['q40'].setValue(
              sexualAddictionAssessment.data.q40.answer
            );
            this.sexualAddictionForm.controls['q41'].setValue(
              sexualAddictionAssessment.data.q41.answer
            );
            this.sexualAddictionForm.controls['q42'].setValue(
              sexualAddictionAssessment.data.q42.answer
            );
            this.sexualAddictionForm.controls['q43'].setValue(
              sexualAddictionAssessment.data.q43.answer
            );
            this.sexualAddictionForm.controls['q44'].setValue(
              sexualAddictionAssessment.data.q44.answer
            );
            this.sexualAddictionForm.controls['q45'].setValue(
              sexualAddictionAssessment.data.q45.answer
            );

            this.riskScore = sexualAddictionAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.sexualAddictionForm.invalid) {
      this.sexualAddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.sexualAddictionAssessment = Object.assign(
      {},
      this.sexualAddictionAssessment
    );
    this.sexualAddictionAssessment.patientId =
      this.sexualAddictionForm.controls['patientId'].value;
    this.sexualAddictionAssessment.sessionId =
      this.sexualAddictionForm.controls['sessionId'].value;
    this.sexualAddictionAssessment.assessmentRequestId =
      this.sexualAddictionForm.controls['assessmentRequestId'].value;
    this.sexualAddictionAssessment.q1 = {
      answer: this.sexualAddictionForm.controls['q1'].value,
    };
    this.sexualAddictionAssessment.q2 = {
      answer: this.sexualAddictionForm.controls['q2'].value,
    };
    this.sexualAddictionAssessment.q3 = {
      answer: this.sexualAddictionForm.controls['q3'].value,
    };
    this.sexualAddictionAssessment.q4 = {
      answer: this.sexualAddictionForm.controls['q4'].value,
    };
    this.sexualAddictionAssessment.q5 = {
      answer: this.sexualAddictionForm.controls['q5'].value,
    };
    this.sexualAddictionAssessment.q6 = {
      answer: this.sexualAddictionForm.controls['q6'].value,
    };
    this.sexualAddictionAssessment.q7 = {
      answer: this.sexualAddictionForm.controls['q7'].value,
    };
    this.sexualAddictionAssessment.q8 = {
      answer: this.sexualAddictionForm.controls['q8'].value,
    };
    this.sexualAddictionAssessment.q9 = {
      answer: this.sexualAddictionForm.controls['q9'].value,
    };
    this.sexualAddictionAssessment.q10 = {
      answer: this.sexualAddictionForm.controls['q10'].value,
    };
    this.sexualAddictionAssessment.q11 = {
      answer: this.sexualAddictionForm.controls['q11'].value,
    };
    this.sexualAddictionAssessment.q12 = {
      answer: this.sexualAddictionForm.controls['q12'].value,
    };
    this.sexualAddictionAssessment.q13 = {
      answer: this.sexualAddictionForm.controls['q13'].value,
    };
    this.sexualAddictionAssessment.q14 = {
      answer: this.sexualAddictionForm.controls['q14'].value,
    };
    this.sexualAddictionAssessment.q15 = {
      answer: this.sexualAddictionForm.controls['q15'].value,
    };
    this.sexualAddictionAssessment.q16 = {
      answer: this.sexualAddictionForm.controls['q16'].value,
    };
    this.sexualAddictionAssessment.q17 = {
      answer: this.sexualAddictionForm.controls['q17'].value,
    };
    this.sexualAddictionAssessment.q18 = {
      answer: this.sexualAddictionForm.controls['q18'].value,
    };
    this.sexualAddictionAssessment.q19 = {
      answer: this.sexualAddictionForm.controls['q19'].value,
    };
    this.sexualAddictionAssessment.q20 = {
      answer: this.sexualAddictionForm.controls['q20'].value,
    };
    this.sexualAddictionAssessment.q21 = {
      answer: this.sexualAddictionForm.controls['q21'].value,
    };
    this.sexualAddictionAssessment.q22 = {
      answer: this.sexualAddictionForm.controls['q22'].value,
    };
    this.sexualAddictionAssessment.q23 = {
      answer: this.sexualAddictionForm.controls['q23'].value,
    };
    this.sexualAddictionAssessment.q24 = {
      answer: this.sexualAddictionForm.controls['q24'].value,
    };
    this.sexualAddictionAssessment.q25 = {
      answer: this.sexualAddictionForm.controls['q25'].value,
    };
    this.sexualAddictionAssessment.q26 = {
      answer: this.sexualAddictionForm.controls['q26'].value,
    };
    this.sexualAddictionAssessment.q27 = {
      answer: this.sexualAddictionForm.controls['q27'].value,
    };
    this.sexualAddictionAssessment.q28 = {
      answer: this.sexualAddictionForm.controls['q28'].value,
    };
    this.sexualAddictionAssessment.q29 = {
      answer: this.sexualAddictionForm.controls['q29'].value,
    };
    this.sexualAddictionAssessment.q30 = {
      answer: this.sexualAddictionForm.controls['q30'].value,
    };
    this.sexualAddictionAssessment.q31 = {
      answer: this.sexualAddictionForm.controls['q31'].value,
    };
    this.sexualAddictionAssessment.q32 = {
      answer: this.sexualAddictionForm.controls['q32'].value,
    };
    this.sexualAddictionAssessment.q33 = {
      answer: this.sexualAddictionForm.controls['q33'].value,
    };
    this.sexualAddictionAssessment.q34 = {
      answer: this.sexualAddictionForm.controls['q34'].value,
    };
    this.sexualAddictionAssessment.q35 = {
      answer: this.sexualAddictionForm.controls['q35'].value,
    };
    this.sexualAddictionAssessment.q36 = {
      answer: this.sexualAddictionForm.controls['q36'].value,
    };
    this.sexualAddictionAssessment.q37 = {
      answer: this.sexualAddictionForm.controls['q37'].value,
    };
    this.sexualAddictionAssessment.q38 = {
      answer: this.sexualAddictionForm.controls['q38'].value,
    };
    this.sexualAddictionAssessment.q39 = {
      answer: this.sexualAddictionForm.controls['q39'].value,
    };
    this.sexualAddictionAssessment.q40 = {
      answer: this.sexualAddictionForm.controls['q40'].value,
    };
    this.sexualAddictionAssessment.q41 = {
      answer: this.sexualAddictionForm.controls['q41'].value,
    };
    this.sexualAddictionAssessment.q42 = {
      answer: this.sexualAddictionForm.controls['q42'].value,
    };
    this.sexualAddictionAssessment.q43 = {
      answer: this.sexualAddictionForm.controls['q43'].value,
    };
    this.sexualAddictionAssessment.q44 = {
      answer: this.sexualAddictionForm.controls['q44'].value,
    };
    this.sexualAddictionAssessment.q45 = {
      answer: this.sexualAddictionForm.controls['q45'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.sexualAddictionAssessment.accessCode = this.accessCode;

      this.sexualAddictionApiService
        .addGuestSexualAddiction(this.sexualAddictionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Sexual Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Sexual Addiction Assessment'
            );
          }
        );
    } else {
      this.sexualAddictionApiService
        .addSexualAddiction(this.sexualAddictionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Sexual Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Sexual Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Were you sexually abused as a child or adolescent? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Did your parents have trouble with sexual behavior? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. Do you often find yourself preoccupied with sexual thoughts? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. Do you feel that your sexual behavior is not normal? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Do you ever feel bad about your sexual behavior? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. Has your sexual behavior ever created problems for you and your family? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. Have you ever sought help for sexual behavior you did not like? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. Has anyone been hurt emotionally because of your sexual behavior? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. Are any of your sexual activities against the law? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Have you made efforts to quit a type of sexual activity and failed? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. Do you hide some of your sexual behaviors from others? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. Have you attempted to stop some parts of your sexual activity? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Have you felt degraded by your sexual behaviors? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. When you have sex, do you feel depressed afterwards? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. Do you feel controlled by your sexual desire? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. Have important parts of your life (such as job, family, friends, leisure activities) been neglected because you were spending too much time on sex? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. Do you ever think your sexual desire is stronger than you are? is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message: '18. Is sex almost all you think about? is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          '19. Has sex (or romantic fantasies) been a way for you to escape your problems? is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. Has sex become the most important thing in your life? is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message: '21. Are you in crisis over sexual matters? is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. The internet has created sexual problems for me. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. I spend too much time online for sexual purposes. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '24. I have purchased services online for erotic purposes (sites for dating) is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. I have used the internet to make romantic or erotic connections with people online. is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message:
          '26. People in my life have been upset about my sexual activities online. is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message:
          '27. I have attempted to stop my online sexual behaviors. is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. I have subscribed to or regularly purchased or rented sexually explicit materials (magazines, videos, books or online pornography). is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message: '29. I have been sexual with minors. is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. I have spent considerable time and money on strip clubs, adult bookstores and movie houses. is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message:
          '31. I have engaged prostitutes and escorts to satisfy my sexual needs. is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message:
          '32. I have spent considerable time surfing pornography online. is required',
      },
    ],
    q33: [
      {
        type: 'required',
        message:
          '33. I have used magazines, videos or online pornography even when there was considerable risk of being caught by family members who would be upset by my behavior. is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message:
          '34. I have regularly purchased romantic novels or sexually explicit magazines. is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message:
          '35. I have stayed in romantic relationships after they became emotionally abusive. is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message: '36. I have traded sex for money or gifts. is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message:
          '37. I have maintained multiple romantic or sexual relationships at the same time. is required',
      },
    ],
    q38: [
      {
        type: 'required',
        message:
          '38. After sexually acting out, I sometimes refrain from all sex for a significant period. is required',
      },
    ],
    q39: [
      {
        type: 'required',
        message:
          '39. I have regularly engaged in sadomasochistic behavior. is required',
      },
    ],
    q40: [
      {
        type: 'required',
        message:
          '40. I visit sexual bath-houses, sex clubs or video/bookstores as part of my regular sexual activity. is required',
      },
    ],
    q41: [
      {
        type: 'required',
        message:
          '41. I have engaged in unsafe or “risky” sex even though I knew it could cause me harm. is required',
      },
    ],
    q42: [
      {
        type: 'required',
        message:
          '42. I have cruised public restrooms, rest areas or parks looking for sex with strangers. is required',
      },
    ],
    q43: [
      {
        type: 'required',
        message:
          '43. I believe casual or anonymous sex has kept me from having more long-term intimate relationships. is required',
      },
    ],
    q44: [
      {
        type: 'required',
        message:
          '44. My sexual behavior has put me at risk for arrest for lewd conduct or public indecency. is required',
      },
    ],
    q45: [
      {
        type: 'required',
        message: '45. I have been paid for sex. is required',
      },
    ],
  };

  private buildForm() {
    this.sexualAddictionForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.sexualAddictionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.sexualAddictionForm) {
      return;
    }
    const form = this.sexualAddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
