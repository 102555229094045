import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-member-document',
  templateUrl: './preview-member-document.component.html',
  styleUrl: './preview-member-document.component.css'
})
export class PreviewMemberDocumentComponent implements AfterViewInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  pdfSrc: any;
  fileType: any;
  htmlContent: any;
  imageUrl: string;

  blobUrl: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<PreviewMemberDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    this.htmlContent = data.content;
    this.fileType = data.type;
    this.imageUrl = data.url;
    if (this.fileType === 'pdf') {
      this.pdfSrc = data.blob
    }

  }

  ngAfterViewInit() {
    if (this.fileType === 'pdf') {
      this.pdfViewerOnDemand.pdfSrc = this.pdfSrc;
      this.pdfViewerOnDemand.refresh();
      this.cdr.detectChanges();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
