<div style="margin-top: 10px; padding: 10px">
  <div class="row" style="margin-bottom: 15px" *ngIf="showAddButton">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addTreatmentProgram()"
      >
        + Add Program
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No Programs found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>
    <mat-error *ngIf="apiError">
      An error occurred while retrieving Program records
    </mat-error>
  </div>

  <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
    <table
      mat-table
      [dataSource]="data.items"
      class="mat-elevation-z8"
      matSort
      matSortDisableClear
    >
      <ng-container matColumnDef="programName">
        <th mat-header-cell *matHeaderCellDef>Program Name</th>
        <td mat-cell *matCellDef="let row">
          <span
            style="color: #2196f3; font-weight: 440; cursor: pointer"
            (click)="setSelectedProgram(row)"
          >
            {{ row.programName }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="facilityName">
        <th mat-header-cell *matHeaderCellDef>Facility Name</th>
        <td mat-cell *matCellDef="let row">{{ row.facilityName }}</td>
      </ng-container>

      <ng-container matColumnDef="programStaff">
        <th mat-header-cell *matHeaderCellDef>Staff</th>
        <td mat-cell *matCellDef="let row">{{ row.staffName }}</td>
      </ng-container>
      <ng-container matColumnDef="daysOnWaitList">
        <th mat-header-cell *matHeaderCellDef>Days On Wait List</th>
        <td mat-cell *matCellDef="let row">{{ row.daysOnWaitList }}</td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Program Start Date</th>
        <td mat-cell *matCellDef="let row">{{ row.startDate }}</td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>Program End Date</th>
        <td mat-cell *matCellDef="let row">{{ row.endDate }}</td>
      </ng-container>
      <ng-container matColumnDef="terminationReason">
        <th mat-header-cell *matHeaderCellDef>Termination Reason</th>
        <td mat-cell *matCellDef="let row">{{ row.terminationReason }}</td>
      </ng-container>
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <button
            type="button"
            mat-raised-button
            [matMenuTriggerFor]="menu"
            class="btn btn-just-icon btn-white btn-fab btn-round"
          >
            <i class="material-icons text_align-center">more_vert</i>
          </button>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              type="button"
              (click)="editTreatmentProgram(row)"
            >
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>

            <button
              mat-menu-item
              (click)="deleteTreatmentProgram(row)"
              type="button"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    ></mat-paginator>
  </div>
</div>
