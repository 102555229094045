import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IRitvoAsperger } from 'src/app/_models/assessments/autism/ritvoasperger.model';
import {
  RitvoAspergerApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-ritvo-asperger',
  templateUrl: './ritvo-asperger.component.html',
  styleUrls: ['./ritvo-asperger.component.css'],
})
export class RitvoAspergerComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public ritvoAspergerApiService: RitvoAspergerApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public ritvoAspergerForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  ritvoAspergerAssessment: IRitvoAsperger;
  ritvoAspergerAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.ritvoAspergerForm, this.errors);

    this.loadRitvoAspergerAssessment();
  }

  loadRitvoAspergerAssessment() {
    this.ritvoAspergerForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.ritvoAspergerApiService
        .getRitvoAspergerDetails(this.assessmentId)
        .subscribe((ritvoAspergerAssessment) => {
          if (ritvoAspergerAssessment.data) {
            this.ritvoAspergerForm.controls['q1'].setValue(
              ritvoAspergerAssessment.data.q1.answer
            );
            this.ritvoAspergerForm.controls['q2'].setValue(
              ritvoAspergerAssessment.data.q2.answer
            );
            this.ritvoAspergerForm.controls['q3'].setValue(
              ritvoAspergerAssessment.data.q3.answer
            );
            this.ritvoAspergerForm.controls['q4'].setValue(
              ritvoAspergerAssessment.data.q4.answer
            );
            this.ritvoAspergerForm.controls['q5'].setValue(
              ritvoAspergerAssessment.data.q5.answer
            );
            this.ritvoAspergerForm.controls['q6'].setValue(
              ritvoAspergerAssessment.data.q6.answer
            );
            this.ritvoAspergerForm.controls['q7'].setValue(
              ritvoAspergerAssessment.data.q7.answer
            );
            this.ritvoAspergerForm.controls['q8'].setValue(
              ritvoAspergerAssessment.data.q8.answer
            );
            this.ritvoAspergerForm.controls['q9'].setValue(
              ritvoAspergerAssessment.data.q9.answer
            );
            this.ritvoAspergerForm.controls['q10'].setValue(
              ritvoAspergerAssessment.data.q10.answer
            );
            this.ritvoAspergerForm.controls['q11'].setValue(
              ritvoAspergerAssessment.data.q11.answer
            );
            this.ritvoAspergerForm.controls['q12'].setValue(
              ritvoAspergerAssessment.data.q12.answer
            );
            this.ritvoAspergerForm.controls['q13'].setValue(
              ritvoAspergerAssessment.data.q13.answer
            );
            this.ritvoAspergerForm.controls['q14'].setValue(
              ritvoAspergerAssessment.data.q14.answer
            );

            this.riskScore = ritvoAspergerAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.ritvoAspergerForm.invalid) {
      this.ritvoAspergerForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.ritvoAspergerAssessment = Object.assign(
      {},
      this.ritvoAspergerAssessment
    );
    this.ritvoAspergerAssessment.patientId =
      this.ritvoAspergerForm.controls['patientId'].value;
    this.ritvoAspergerAssessment.sessionId =
      this.ritvoAspergerForm.controls['sessionId'].value;
    this.ritvoAspergerAssessment.assessmentRequestId =
      this.ritvoAspergerForm.controls['assessmentRequestId'].value;
    this.ritvoAspergerAssessment.q1 = {
      answer: this.ritvoAspergerForm.controls['q1'].value,
    };
    this.ritvoAspergerAssessment.q2 = {
      answer: this.ritvoAspergerForm.controls['q2'].value,
    };
    this.ritvoAspergerAssessment.q3 = {
      answer: this.ritvoAspergerForm.controls['q3'].value,
    };
    this.ritvoAspergerAssessment.q4 = {
      answer: this.ritvoAspergerForm.controls['q4'].value,
    };
    this.ritvoAspergerAssessment.q5 = {
      answer: this.ritvoAspergerForm.controls['q5'].value,
    };
    this.ritvoAspergerAssessment.q6 = {
      answer: this.ritvoAspergerForm.controls['q6'].value,
    };
    this.ritvoAspergerAssessment.q7 = {
      answer: this.ritvoAspergerForm.controls['q7'].value,
    };
    this.ritvoAspergerAssessment.q8 = {
      answer: this.ritvoAspergerForm.controls['q8'].value,
    };
    this.ritvoAspergerAssessment.q9 = {
      answer: this.ritvoAspergerForm.controls['q9'].value,
    };
    this.ritvoAspergerAssessment.q10 = {
      answer: this.ritvoAspergerForm.controls['q10'].value,
    };
    this.ritvoAspergerAssessment.q11 = {
      answer: this.ritvoAspergerForm.controls['q11'].value,
    };
    this.ritvoAspergerAssessment.q12 = {
      answer: this.ritvoAspergerForm.controls['q12'].value,
    };
    this.ritvoAspergerAssessment.q13 = {
      answer: this.ritvoAspergerForm.controls['q13'].value,
    };
    this.ritvoAspergerAssessment.q14 = {
      answer: this.ritvoAspergerForm.controls['q14'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.ritvoAspergerAssessment.accessCode = this.accessCode;

      this.ritvoAspergerApiService
        .addGuestRitvoAsperger(this.ritvoAspergerAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Ritvo Asperger Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Ritvo Asperger Assessment'
            );
          }
        );
    } else {
      this.ritvoAspergerApiService
        .addRitvoAsperger(this.ritvoAspergerAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Ritvo Asperger Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Ritvo Asperger Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. It is difficult for me to understand how other people are feeling when we are talking. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Some ordinary textures that do not bother others feel very offensive when they touch my skin. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. It is very difficult for me to work and function in groups. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. It is difficult to figure out what other people expect of me. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. I often don’t know how to act in social situations. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message: '6. I can chat and make small talk with people. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. When I feel overwhelmed by my senses, I have to isolate myself to shut them down. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. How to make friends and socialize is a mystery to me. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. When talking to someone, I have a hard time telling when it is my turn to talk or to listen. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Sometimes I have to cover my ears to block out painful noises (like vacuum cleaners or people talking too much or too loudly). is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. It can be very hard to read someone’s face, hand, and body movements when we are talking. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. I focus on details rather than the overall idea. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. I take things too literally, so I often miss what people are trying to say. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I get extremely upset when the way I like to do things is suddenly changed. is required',
      },
    ],
  };

  private buildForm() {
    this.ritvoAspergerForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.ritvoAspergerForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.ritvoAspergerForm) {
      return;
    }
    const form = this.ritvoAspergerForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
