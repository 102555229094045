import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAutismSpectrumScreening } from 'src/app/_models/assessments/autism/autismspectrumscreening.model';
import {
  AutismSpectrumScreeningApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-autism-spectrum-screening',
  templateUrl: './autism-spectrum-screening.component.html',
  styleUrls: ['./autism-spectrum-screening.component.css'],
})
export class AutismSpectrumScreeningComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public autismSpectrumScreeningApiService: AutismSpectrumScreeningApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public autismSpectrumScreeningForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  autismSpectrumScreeningAssessment: IAutismSpectrumScreening;
  autismSpectrumScreeningAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.autismSpectrumScreeningForm,
      this.errors
    );

    this.loadAutismSpectrumScreeningAssessment();
  }

  loadAutismSpectrumScreeningAssessment() {
    this.autismSpectrumScreeningForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.autismSpectrumScreeningApiService
        .getAutismSpectrumScreeningDetails(this.assessmentId)
        .subscribe((autismSpectrumScreeningAssessment) => {
          if (autismSpectrumScreeningAssessment.data) {
            this.autismSpectrumScreeningForm.controls['q1'].setValue(
              autismSpectrumScreeningAssessment.data.q1.answer
            );
            this.autismSpectrumScreeningForm.controls['q2'].setValue(
              autismSpectrumScreeningAssessment.data.q2.answer
            );
            this.autismSpectrumScreeningForm.controls['q3'].setValue(
              autismSpectrumScreeningAssessment.data.q3.answer
            );
            this.autismSpectrumScreeningForm.controls['q4'].setValue(
              autismSpectrumScreeningAssessment.data.q4.answer
            );
            this.autismSpectrumScreeningForm.controls['q5'].setValue(
              autismSpectrumScreeningAssessment.data.q5.answer
            );
            this.autismSpectrumScreeningForm.controls['q6'].setValue(
              autismSpectrumScreeningAssessment.data.q6.answer
            );
            this.autismSpectrumScreeningForm.controls['q7'].setValue(
              autismSpectrumScreeningAssessment.data.q7.answer
            );
            this.autismSpectrumScreeningForm.controls['q8'].setValue(
              autismSpectrumScreeningAssessment.data.q8.answer
            );
            this.autismSpectrumScreeningForm.controls['q9'].setValue(
              autismSpectrumScreeningAssessment.data.q9.answer
            );
            this.autismSpectrumScreeningForm.controls['q10'].setValue(
              autismSpectrumScreeningAssessment.data.q10.answer
            );
            this.autismSpectrumScreeningForm.controls['q11'].setValue(
              autismSpectrumScreeningAssessment.data.q11.answer
            );
            this.autismSpectrumScreeningForm.controls['q12'].setValue(
              autismSpectrumScreeningAssessment.data.q12.answer
            );
            this.autismSpectrumScreeningForm.controls['q13'].setValue(
              autismSpectrumScreeningAssessment.data.q13.answer
            );
            this.autismSpectrumScreeningForm.controls['q14'].setValue(
              autismSpectrumScreeningAssessment.data.q14.answer
            );
            this.autismSpectrumScreeningForm.controls['q15'].setValue(
              autismSpectrumScreeningAssessment.data.q15.answer
            );
            this.autismSpectrumScreeningForm.controls['q16'].setValue(
              autismSpectrumScreeningAssessment.data.q16.answer
            );
            this.autismSpectrumScreeningForm.controls['q17'].setValue(
              autismSpectrumScreeningAssessment.data.q17.answer
            );
            this.autismSpectrumScreeningForm.controls['q18'].setValue(
              autismSpectrumScreeningAssessment.data.q18.answer
            );
            this.autismSpectrumScreeningForm.controls['q19'].setValue(
              autismSpectrumScreeningAssessment.data.q19.answer
            );
            this.autismSpectrumScreeningForm.controls['q20'].setValue(
              autismSpectrumScreeningAssessment.data.q20.answer
            );
            this.autismSpectrumScreeningForm.controls['q21'].setValue(
              autismSpectrumScreeningAssessment.data.q21.answer
            );
            this.autismSpectrumScreeningForm.controls['q22'].setValue(
              autismSpectrumScreeningAssessment.data.q22.answer
            );
            this.autismSpectrumScreeningForm.controls['q23'].setValue(
              autismSpectrumScreeningAssessment.data.q23.answer
            );
            this.autismSpectrumScreeningForm.controls['q24'].setValue(
              autismSpectrumScreeningAssessment.data.q24.answer
            );
            this.autismSpectrumScreeningForm.controls['q25'].setValue(
              autismSpectrumScreeningAssessment.data.q25.answer
            );
            this.autismSpectrumScreeningForm.controls['q26'].setValue(
              autismSpectrumScreeningAssessment.data.q26.answer
            );
            this.autismSpectrumScreeningForm.controls['q27'].setValue(
              autismSpectrumScreeningAssessment.data.q27.answer
            );

            this.riskScore = autismSpectrumScreeningAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.autismSpectrumScreeningForm.invalid) {
      this.autismSpectrumScreeningForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.autismSpectrumScreeningAssessment = Object.assign(
      {},
      this.autismSpectrumScreeningAssessment
    );
    this.autismSpectrumScreeningAssessment.patientId =
      this.autismSpectrumScreeningForm.controls['patientId'].value;
    this.autismSpectrumScreeningAssessment.sessionId =
      this.autismSpectrumScreeningForm.controls['sessionId'].value;
    this.autismSpectrumScreeningAssessment.assessmentRequestId =
      this.autismSpectrumScreeningForm.controls['assessmentRequestId'].value;
    this.autismSpectrumScreeningAssessment.q1 = {
      answer: this.autismSpectrumScreeningForm.controls['q1'].value,
    };
    this.autismSpectrumScreeningAssessment.q2 = {
      answer: this.autismSpectrumScreeningForm.controls['q2'].value,
    };
    this.autismSpectrumScreeningAssessment.q3 = {
      answer: this.autismSpectrumScreeningForm.controls['q3'].value,
    };
    this.autismSpectrumScreeningAssessment.q4 = {
      answer: this.autismSpectrumScreeningForm.controls['q4'].value,
    };
    this.autismSpectrumScreeningAssessment.q5 = {
      answer: this.autismSpectrumScreeningForm.controls['q5'].value,
    };
    this.autismSpectrumScreeningAssessment.q6 = {
      answer: this.autismSpectrumScreeningForm.controls['q6'].value,
    };
    this.autismSpectrumScreeningAssessment.q7 = {
      answer: this.autismSpectrumScreeningForm.controls['q7'].value,
    };
    this.autismSpectrumScreeningAssessment.q8 = {
      answer: this.autismSpectrumScreeningForm.controls['q8'].value,
    };
    this.autismSpectrumScreeningAssessment.q9 = {
      answer: this.autismSpectrumScreeningForm.controls['q9'].value,
    };
    this.autismSpectrumScreeningAssessment.q10 = {
      answer: this.autismSpectrumScreeningForm.controls['q10'].value,
    };
    this.autismSpectrumScreeningAssessment.q11 = {
      answer: this.autismSpectrumScreeningForm.controls['q11'].value,
    };
    this.autismSpectrumScreeningAssessment.q12 = {
      answer: this.autismSpectrumScreeningForm.controls['q12'].value,
    };
    this.autismSpectrumScreeningAssessment.q13 = {
      answer: this.autismSpectrumScreeningForm.controls['q13'].value,
    };
    this.autismSpectrumScreeningAssessment.q14 = {
      answer: this.autismSpectrumScreeningForm.controls['q14'].value,
    };
    this.autismSpectrumScreeningAssessment.q15 = {
      answer: this.autismSpectrumScreeningForm.controls['q15'].value,
    };
    this.autismSpectrumScreeningAssessment.q16 = {
      answer: this.autismSpectrumScreeningForm.controls['q16'].value,
    };
    this.autismSpectrumScreeningAssessment.q17 = {
      answer: this.autismSpectrumScreeningForm.controls['q17'].value,
    };
    this.autismSpectrumScreeningAssessment.q18 = {
      answer: this.autismSpectrumScreeningForm.controls['q18'].value,
    };
    this.autismSpectrumScreeningAssessment.q19 = {
      answer: this.autismSpectrumScreeningForm.controls['q19'].value,
    };
    this.autismSpectrumScreeningAssessment.q20 = {
      answer: this.autismSpectrumScreeningForm.controls['q20'].value,
    };
    this.autismSpectrumScreeningAssessment.q21 = {
      answer: this.autismSpectrumScreeningForm.controls['q21'].value,
    };
    this.autismSpectrumScreeningAssessment.q22 = {
      answer: this.autismSpectrumScreeningForm.controls['q22'].value,
    };
    this.autismSpectrumScreeningAssessment.q23 = {
      answer: this.autismSpectrumScreeningForm.controls['q23'].value,
    };
    this.autismSpectrumScreeningAssessment.q24 = {
      answer: this.autismSpectrumScreeningForm.controls['q24'].value,
    };
    this.autismSpectrumScreeningAssessment.q25 = {
      answer: this.autismSpectrumScreeningForm.controls['q25'].value,
    };
    this.autismSpectrumScreeningAssessment.q26 = {
      answer: this.autismSpectrumScreeningForm.controls['q26'].value,
    };
    this.autismSpectrumScreeningAssessment.q27 = {
      answer: this.autismSpectrumScreeningForm.controls['q27'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.autismSpectrumScreeningAssessment.accessCode = this.accessCode;

      this.autismSpectrumScreeningApiService
        .addGuestAutismSpectrumScreening(this.autismSpectrumScreeningAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Autism Spectrum Screening Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Autism Spectrum Screening Assessment'
            );
          }
        );
    } else {
      this.autismSpectrumScreeningApiService
        .addAutismSpectrumScreening(this.autismSpectrumScreeningAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Autism Spectrum Screening Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Autism Spectrum Screening Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. is old-fashioned or precocious is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. is regarded as an “eccentric professor” by the other children is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. lives somewhat in a world of his/her own with restricted idiosyncratic intellectual interests is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. accumulates facts on certain subjects (good rote memory) but does not really understand the meaning is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. has a literal understanding of ambiguous and metaphorical language is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. has a deviant style of communication with a formal, fussy, old-fashioned or “robot-like” language is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: '7. invents idiosyncratic words and expressions is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. has a different voice or speech is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. expresses sounds involuntarily; clears throat, grunts, smacks, cries or screams is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. is surprisingly good at some things and surprisingly poor at others is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. uses language freely but fails to make adjustment to fit social contexts or the needs of different listeners is required',
      },
    ],
    q12: [{ type: 'required', message: '12. lacks empathy is required' }],
    q13: [
      {
        type: 'required',
        message: '13. makes naive and embarrassing remarks is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message: '14. has a deviant style of gaze is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. wishes to be sociable but fails to make relationships with peers is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. can be with other children but only on his/her terms is required',
      },
    ],
    q17: [{ type: 'required', message: '17. lacks best friend is required' }],
    q18: [{ type: 'required', message: '18. lacks common sense is required' }],
    q19: [
      {
        type: 'required',
        message:
          '19. is poor at games: no idea of cooperating in a team, scores “own goals” is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. has clumsy, ill coordinated, ungainly, awkward movements or gestures is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message: '21. has involuntary face or body movements is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. has difficulties in completing simple daily activities because of compulsory repetition of certain actions or thoughts is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. has special routines: insists on no change is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. shows idiosyncratic attachment to objects is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message: '25. is bullied by other children is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message: '26. has markedly unusual facial expression is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message: '27. has markedly unusual posture is required',
      },
    ],
  };

  private buildForm() {
    this.autismSpectrumScreeningForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.autismSpectrumScreeningForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.autismSpectrumScreeningForm) {
      return;
    }
    const form = this.autismSpectrumScreeningForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
