<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Case</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Case</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="careProvidersLoading || serviceTypesLoading"
  ></mat-progress-bar>

  <form
    [formGroup]="caseForm"
    *ngIf="!careProvidersLoading && !serviceTypesLoading"
  >
    <div class="row">
      <!-- Case Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">business_center</mat-icon>
              Case Information
            </label>
          </div>
        </div>
        <!-- Service Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <!-- Service Type -->
          <div class="row">
            <div class="col-md-12 col-xl-6">
              <label class="labelBold">
                Service Type <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="selectedServices"
                  placeholder="Service Type"
                  [disabled]="action != 'ADD'"
                  [compareWith]="compare"
                >
                  <mat-option
                    *ngFor="let serviceOption of filteredServiceTypes"
                    [value]="serviceOption"
                  >
                    {{ serviceOption.displayName }}
                  </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="caseForm.controls['selectedServices'].invalid"
                >
                  Service Type is required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Case Manager -->
            <div class="col-md-12 col-xl-6">
              <label class="labelBold">
                Primary Case Manager <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Case Manager"
                  formControlName="caseManagerId"
                  (selectionChange)="selectedValue($event)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Case Manager Name"
                      noEntriesFoundLabel="No matching case manager found"
                      [(ngModel)]="searchCareProvider"
                      (ngModelChange)="filterCaseManager()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    [value]="user.id"
                    *ngFor="let user of filteredCareProvidersList"
                  >
                    {{ user.lastName }}, {{ user.firstName }}
                    {{ user.middleName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="caseForm.controls['caseManagerId'].invalid">
                  Case Manager is required
                </mat-error>
              </mat-form-field>
            </div>

            <!--  Diagnosis -->
            <div class="col-md-12 col-xl-6">
              <label class="labelBold"> Primary Diagnosis </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="primaryDiagnosisCode"
                  name="primaryDiagnosisCode"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of icdCodeData.items"
                    [value]="option.icdCode"
                  >
                    {{ option.icdCode }} - {{ option.icdDescription }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="caseForm.controls['primaryDiagnosisCode'].invalid"
                >
                  Please enter a valid diagnosis code
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Room Number -->
            <div class="col-md-12 col-xl-6">
              <label class="labelBold"> Room Number</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="roomNumber"
                  name="roomNumber"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Case Description -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">description</mat-icon>
              Case Description
            </label>
          </div>
        </div>

        <!-- Description -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelBold"> Description</label>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  formControlName="description"
                  name="description"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Additional Care Providers -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">supervisor_account</mat-icon>
              Additional Care Providers
            </label>
          </div>
        </div>

        <!-- Grant Providers / Sponsors -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelBold"> Care Providers </label>

              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="additionalCaseManagers"
                  multiple
                  [compareWith]="compareAdditionalCaseManagers"
                >
                  <mat-select-trigger>
                    <mat-chip-listbox>
                      <mat-chip-row
                        *ngFor="
                          let manager of caseForm.controls[
                            'additionalCaseManagers'
                          ].value;
                          let i = index
                        "
                        (removed)="removeAdditionalManagers(manager, i)"
                      >
                        {{ manager.lastName }}, {{ manager.firstName }}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    </mat-chip-listbox>
                  </mat-select-trigger>

                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Name"
                      noEntriesFoundLabel="No matching user found"
                      [(ngModel)]="userName"
                      (ngModelChange)="filterAllActiveCaseManagers()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let user of filteredCaseManagers"
                    [value]="user"
                  >
                    {{ user.lastName }}, {{ user.firstName }}
                    <span *ngIf="user.emailAddress"> | </span>
                    <span *ngIf="user.emailAddress" style="font-style: italic">
                      {{ user.emailAddress }}
                    </span>
                    <span *ngIf="user.phoneNumber"> | </span>
                    <span *ngIf="user.phoneNumber" style="font-style: italic">
                      {{ user.phoneNumber | phoneFormat }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Case Payer -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">monetization_on</mat-icon>
              Case Payor / Insurance
            </label>
          </div>
        </div>

        <!-- Grant Providers / Sponsors -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelBold"> Grant Provider or Sponsors</label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="attachedGrantProviders"
                  multiple
                  [compareWith]="compareGrants"
                >
                  <mat-select-trigger>
                    <mat-chip-listbox>
                      <mat-chip-row
                        *ngFor="
                          let grantProvider of caseForm.controls[
                            'attachedGrantProviders'
                          ].value;
                          let i = index
                        "
                        (removed)="removeGrantProvider(grantProvider, i)"
                      >
                        {{ grantProvider.grantName }}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    </mat-chip-listbox>
                  </mat-select-trigger>

                  <mat-option
                    *ngFor="let selectGrant of activeGrants"
                    [value]="selectGrant"
                  >
                    {{ selectGrant.grantName }}
                    <span
                      *ngIf="selectGrant.grantType"
                      style="font-style: italic"
                    >
                      ({{ selectGrant.grantType }})
                    </span>
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="caseForm.controls['attachedGrantProviders'].invalid"
                >
                  Please select a valid grant
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <label class="labelBold"> Insurance Provider </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="attachedMemberInsurances"
                  multiple
                  [compareWith]="compareGrants"
                >
                  <mat-select-trigger>
                    <mat-chip-listbox>
                      <mat-chip-row
                        *ngFor="
                          let insuranceProvider of caseForm.controls[
                            'attachedMemberInsurances'
                          ].value;
                          let i = index
                        "
                        (removed)="
                          removeInsuranceProvider(insuranceProvider, i)
                        "
                      >
                        {{ insuranceProvider.payerName }}
                        <span *ngIf="insuranceProvider.claimPayerCode">
                          ({{ insuranceProvider.claimPayerCode }})
                        </span>

                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    </mat-chip-listbox>
                  </mat-select-trigger>

                  <mat-option
                    *ngFor="let payer of activeInsurance"
                    [value]="payer"
                  >
                    {{ payer.payerName }}
                    <span
                      *ngIf="payer.claimPayerCode"
                      style="font-style: italic"
                    >
                      ({{ payer.claimPayerCode }})
                    </span>
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="caseForm.controls['attachedMemberInsurances'].invalid"
                >
                  Please select a valid insurance provider
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Case Status -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">settings</mat-icon>
              Case Status
            </label>
          </div>
        </div>

        <!-- Dates & Status -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Admittance Date -->
            <div class="col-md-12 col-xl-6">
              <label class="labelBold">Admittance Date</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="admittanceDate"
                  matInput
                  [matDatepicker]="admittanceDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="admittanceDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #admittanceDate></mat-datepicker>

                <mat-error *ngIf="caseForm.controls['admittanceDate'].invalid">
                  Please enter or select a valid date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Expected Discharge Date -->
            <div class="col-md-12 col-xl-6">
              <label class="labelBold">Expected Discharge Date</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="expectedDischargeDate"
                  matInput
                  [matDatepicker]="expectedDischargeDate"
                  [min]="caseForm.controls['admittanceDate'].value"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="expectedDischargeDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #expectedDischargeDate></mat-datepicker>

                <mat-error
                  *ngIf="caseForm.controls['expectedDischargeDate'].invalid"
                >
                  Please enter or select a valid date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Actual closed Date -->
            <div
              class="col-md-12 col-xl-6"
              *ngIf="caseForm.controls['status'].value != 'active'"
            >
              <label class="labelBold" style="text-transform: capitalize">
                {{ caseForm.controls['status'].value }} Date
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="closeDate"
                  matInput
                  [matDatepicker]="closeDate"
                  placeholder="MM/DD/YYYY"
                  [max]="todaysDate"
                  [min]="caseForm.controls['admittanceDate'].value"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="closeDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #closeDate></mat-datepicker>

                <mat-error *ngIf="caseForm.controls['closeDate'].invalid">
                  Please enter or select a valid date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Discharged Reasons -->
            <div
              class="col-md-12 col-xl-6"
              *ngIf="caseForm.controls['status'].value === 'Discharged'"
            >
              <label class="labelBold"> Discharged Reasons </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="dischargedReasons" multiple>
                  <mat-option value="According to Plan (ATP)">
                    According to Plan (ATP)</mat-option
                  >
                  <mat-option value="Failure to Complete (FTC)"
                    >Failure to Complete (FTC)</mat-option
                  >
                  <mat-option value="AWOL">AWOL</mat-option>
                  <mat-option value="Against Staff Advice (ASA)">
                    Against Staff Advice (ASA)
                  </mat-option>
                  <mat-option value="Transfer to TL">Transfer to TL</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="caseForm.controls['dischargedReasons'].invalid"
                >
                  Please select a discharged reason
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Transferred Reasons -->
            <div
              class="col-md-12 col-xl-6"
              *ngIf="caseForm.controls['status'].value === 'Transferred'"
            >
              <label class="labelBold"> Transferred Reasons </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="transferredReasons" multiple>
                  <mat-option value="Transferred to Transitional Living">
                    Transferred to Transitional Living
                  </mat-option>
                  <mat-option value="Transferred to Intensive Residential">
                    Transferred to Intensive Residential
                  </mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="caseForm.controls['transferredReasons'].invalid"
                >
                  Please select a transferred reason
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Status -->
            <div class="col-md-12">
              <label class="labelBold">
                Status <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="status" placeholder="Status">
                  <mat-option value="active">Active</mat-option>
                  <mat-option value="Transferred">Transferred</mat-option>
                  <mat-option value="Discharged">Discharged</mat-option>
                  <mat-option value="closed">Closed</mat-option>
                </mat-select>
                <mat-error *ngIf="caseForm.controls['status'].invalid">
                  Please select a status
                </mat-error>
              </mat-form-field>
            </div>

            <!--  Comments -->
            <div class="col-md-12">
              <label class="labelBold"> Comments</label>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  formControlName="comments"
                  name="comments"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-md-12" *ngIf="serviceTypes.length == 0">
        <mat-error>
          No service type found. Please contact your admin to have services
          setup for your organization.
        </mat-error>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="caseForm.invalid || processing"
          (click)="onCaseFormSubmit()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
