import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MbscCalendarEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  momentTimezone,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment-timezone';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import {
  CaseApiService,
  DashboardsService,
  DateTimeZoneService,
  PatientService,
  TherapySessionService,
  TimeOffAPIService,
  ToastMessageService,
  WorkingHoursDialogApiService,
} from 'src/app/_services';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { VisitReasonsSandbox } from 'src/app/shared/sandbox/visit-reasons.sandbox';
import { AutoAccidentQualifier } from 'src/app/shared/utilities/billing/autoAccidentQualifier';
import { EPSDTConditionInticatorType } from 'src/app/shared/utilities/billing/epsdtType';
import { IntakeDocumentsList } from 'src/app/shared/utilities/calendar/intakeDocumentsList';
import { TimeArrayMap } from 'src/app/shared/utilities/calendar/timeArrays';
import { TimeCellSteps } from 'src/app/shared/utilities/calendar/timeCellArrays';
import {
  TimeZonesCalendar,
  getTimeInTimeZone,
} from 'src/app/shared/utilities/calendar/timeZonesCalendar';
import { Colors } from 'src/app/shared/utilities/colors';
import {
  SessionQueues,
  SessionQueuesColorMap,
} from 'src/app/shared/utilities/session/sessionQueues';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import { formatDate, hasAccess } from 'src/app/shared/utilities/utilities';
import { AddEditCaseDialogComponent } from '../../cases/case-dialogs/case-add-edit/case-add-edit.component';

momentTimezone.moment = moment;
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrl: './reschedule-appointment.component.css',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class RescheduleAppointmentComponent implements OnInit {
  @ViewChild('mbscCalendar') mbscCalendar!: MbscEventcalendar;
  rescheduleAppoitmentId: string;
  parent: string;

  // Reschedule Appointment Details
  isLoadingAppointmentDetails: boolean = true;
  rescheduleAppointmentDetails: ITherapySession;
  public therapysessionForm: FormGroup;

  // Patient Details
  patientId: string;
  patientDetailsLoading: boolean = true;
  patient: Patient;

  // Importing Constants from the Shared Library
  sessionQueues = SessionQueues;
  sessionQueuesColorMap = SessionQueuesColorMap;
  intakeDocumentsList = IntakeDocumentsList;
  selectedCalendarTimeZone = '';
  selectedCaldendarTimeZoneDateFormat = '';
  colorsList = Colors;
  timeArrayMap = TimeArrayMap;
  epsdtConditionTypes = EPSDTConditionInticatorType;
  usStateList = USStates;
  autoAccidentQualifierList = AutoAccidentQualifier;
  calendarTimeZones = TimeZonesCalendar;

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<String, any> = new Map<String, any>();
  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;
  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  // Org config
  orgConfig: any;
  hasCareFeature: boolean = false;
  hasCaseAddAccess: boolean = false;

  // Facilities
  myFacilities: any = [];
  facilitiesSelectionList: any = [];
  filteredFacilities: any = [];
  filteredFacilitiesProviders = new Set<string>();
  facilitiesListMap: Map<String, any> = new Map<String, any>();

  selectedProviderFacilities: any = [];
  searchFacilityName: string = '';

  // Get All Active Providers for the selected patient's facility
  providersList: any = [];
  supervisingProvidersList: any = [];
  filteredSupervisingProviderList: any = [];
  providersListMap: Map<String, any> = new Map<String, any>();
  searchProviderName: string = '';
  searchSupervisingProviderName: string = '';
  providersListLoading: boolean = true;
  // Using Map to Store Each Provider's Schedule and Time Off
  providersScheduleTimeOffMap: Map<String, any> = new Map<String, any>();

  // Charges
  chargesColumn = ['cptCode', 'charges', 'actions'];

  //  Existing all appointments
  existingAppointmentList: any = [];

  // Care Log Cases
  existingCaseList = [];

  // MobiScroll Components
  allResources = [];
  filteredAllResources = [];
  filteredResources = [];
  invalids = [];
  invalidColors = [];
  myEvents: MbscCalendarEvent[] = [];
  allWorkingHoursArray = [];
  calendarStartDay = 0; // Sunday is 0, Monday is 1, ...
  calendarEndDay = 6;
  calendarStartHour = '00:00';
  calendarEndHour = '24:00';
  calendarViewType: string = 'week';
  timeCellStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeLabelStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeCellSteps = TimeCellSteps;

  initialResponsiveView = {
    allDay: false,
    currentTimeIndicator: true,
    startTime: this.calendarStartHour,
    endTime: this.calendarEndHour,
    startDay: this.calendarStartDay,
    endDay: this.calendarEndDay,
    timeCellStep: this.timeCellStep,
    timeLabelStep: this.timeLabelStep,
  };

  // Setting Up Initial MobiScroll Calendar View
  responsiveMobiScrollCalendarView = {
    // Min-width:0px
    xsmall: {
      view: {
        schedule: {
          type: 'day',
          ...this.initialResponsiveView,
        },
      },
    },
    // min-width: 768px
    medium: {
      view: {
        schedule: {
          type: 'week',
          ...this.initialResponsiveView,
        },
      },
    },
  };

  // Current Date & time
  currentTime = new Date();
  // Can be modified within past 14 days
  dayBeforeYesterday = addDays(new Date(), -14);
  minimumCalendarDate = addDays(new Date(), -7);
  maximumCalendarDate = addDays(new Date(), 120);

  // Setting up initial MobiScroll Calendar options
  mobiscrollCalendarOptions: MbscEventcalendarOptions = {
    clickToCreate: 'double',
    dragToCreate: false,
    dragToMove: true,
    dragInTime: true,
    eventOverlap: true,
    timezonePlugin: momentTimezone,

    // Double click to update to selected time
    onEventCreated: (args) => {
      setTimeout(() => {
        // Filter out the null ones
        this.myEvents = this.myEvents.filter((event) => event.id != null);

        // Handle mobiscroll event
        let mobiscrollStartDate: string | object | Date = args.event.start;
        let eventStartDate: Date;
        // Convert to the appropriate date type
        if (typeof mobiscrollStartDate === 'string') {
          eventStartDate = new Date(mobiscrollStartDate);
        } else if (mobiscrollStartDate instanceof Date) {
          eventStartDate = mobiscrollStartDate;
        } else {
          eventStartDate = new Date(mobiscrollStartDate.toString());
        }

        const { hour, minute, formattedDate } = getTimeInTimeZone(
          eventStartDate,
          this.selectedCalendarTimeZone
        );

        let startHour = hour;
        let startMinutes = minute;
        let startMinutesString = startMinutes.toString();
        if (startMinutesString.length == 1) {
          startMinutesString = startMinutesString + '0';
        }
        let combinedTimeString = startHour.toString() + startMinutesString;

        // Set the new start value
        this.therapysessionForm.controls['start'].setValue(eventStartDate);
        this.therapysessionForm.controls['sessionStartDate'].setValue(
          formatDate(eventStartDate)
        );
        this.therapysessionForm.controls['sessionStartTime'].setValue(
          this.timeArrayMap.get(Number(combinedTimeString))
        );

        let newEndTime = addMinutes(
          this.therapysessionForm.controls['start'].value,
          this.therapysessionForm.controls['sessionDuration'].value
        );
        this.therapysessionForm.controls['end'].setValue(newEndTime);
        this.rescheduleAppointmentDetails.sessionStartTime = combinedTimeString;

        // Extracting Data From MobiScroll Event
        let mobiscrollResouce = args.event.resource;
        // Handle changes with physician resource change
        this.therapysessionForm.controls['physicianId'].setValue(
          mobiscrollResouce.toString()
        );
        this.renderingProviderChanged();
        this.determineSelectedProviderFacilities();

        // Set the new start date on the mobiscroll event
        this.myEvents = this.myEvents.map((event) => {
          if (event.id == this.rescheduleAppoitmentId) {
            // Update this event
            event.start = eventStartDate;
            event.end = newEndTime;
            event.resource = mobiscrollResouce.toString();
          }
          return event;
        });
      });
    },

    // When the drag even stops
    onEventDragEnd: (args) => {
      setTimeout(() => {
        //  Start Date Conversion
        let mobiscrollStartDate: string | object | Date = args.event.start;
        let eventStartDate: Date;
        // Convert to the appropriate date type
        if (typeof mobiscrollStartDate === 'string') {
          eventStartDate = new Date(mobiscrollStartDate);
        } else if (mobiscrollStartDate instanceof Date) {
          eventStartDate = mobiscrollStartDate;
        } else {
          eventStartDate = new Date(mobiscrollStartDate.toString());
        }

        // End date Conversion
        let mobiscrollEndDate: string | object | Date = args.event.end;
        let eventEndDate: Date;
        if (typeof mobiscrollEndDate === 'string') {
          eventEndDate = new Date(mobiscrollEndDate);
        } else if (mobiscrollEndDate instanceof Date) {
          eventEndDate = mobiscrollEndDate;
        } else {
          eventEndDate = new Date(mobiscrollEndDate.toString());
        }

        // Getting event from args
        let mobiScrollEvent = args.event;

        // Extracting Data From MobiScroll Event
        let mobiscrollResouce = mobiScrollEvent.resource;

        const { hour, minute, formattedDate } = getTimeInTimeZone(
          eventStartDate,
          this.selectedCalendarTimeZone
        );

        let startHour = hour;
        let startMinutes = minute;
        let startMinutesString = startMinutes.toString();
        if (startMinutesString.length == 1) {
          startMinutesString = startMinutesString + '0';
        }
        let combinedTimeString = startHour.toString() + startMinutesString;

        // Handle changes with physician resource change
        this.therapysessionForm.controls['physicianId'].setValue(
          mobiscrollResouce.toString()
        );
        this.renderingProviderChanged();
        this.determineSelectedProviderFacilities();

        // Populating the therapy session form with updated value
        this.therapysessionForm.controls['start'].setValue(eventStartDate);
        this.therapysessionForm.controls['sessionStartDate'].setValue(
          formatDate(eventStartDate)
        );
        this.therapysessionForm.controls['sessionStartTime'].setValue(
          this.timeArrayMap.get(Number(combinedTimeString))
        );
        this.therapysessionForm.controls['end'].setValue(eventEndDate);

        // Overriding session start time in therapy object
        this.rescheduleAppointmentDetails.sessionStartTime = combinedTimeString;
      });
    },
  };

  processing: boolean = false;

  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private route: ActivatedRoute,
    private patientAPIService: PatientService,
    private timeOffAPIService: TimeOffAPIService,
    private workinghoursdialogapiservice: WorkingHoursDialogApiService,
    public caseApiService: CaseApiService,
    public customformApiService: CustomFormApiService,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService,
    private visitReasonsSandBox: VisitReasonsSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox,
    private facilitiesSandbox: FacilitiesSandbox,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private therapySessionService: TherapySessionService,
    private dateTimeZoneService: DateTimeZoneService
  ) { }

  ngOnInit(): void {
    // Get the appointment to be rescheduled details
    this.rescheduleAppoitmentId = this.route.snapshot.paramMap.get('id');
    this.getAppointmentDetails();

    this.route.queryParamMap.subscribe((param) => {
      this.parent = param.get('parent');
    });

    // Default time zone based on user login
    this.selectedCalendarTimeZone = moment.tz.guess();
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
    // Check if this timezone exists or not
    this.checkTimeZoneExists();

    // Now load the visit reasons
    this.loadVisitReasons();

    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Load Organizational Holidays If Any
    this.loadOrganizationalHolidays();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    // Block The Calendar Upto the current hours
    this.blockCalendarUptoNow();
  }

  public buildForm() {
    this.therapysessionForm = this.formBuilder.group({
      id: new FormControl(this.rescheduleAppointmentDetails.id),
      organizationId: new FormControl(
        this.rescheduleAppointmentDetails.organizationId
      ),

      caseId: new FormControl(this.rescheduleAppointmentDetails.caseId),
      caseNumber: new FormControl(this.rescheduleAppointmentDetails.caseNumber),

      title: new FormControl(
        this.rescheduleAppointmentDetails.title,
        Validators.required
      ),
      visitReasonId: new FormControl(
        this.rescheduleAppointmentDetails.visitReasonId,
        Validators.required
      ),
      visitReasonName: new FormControl(
        this.rescheduleAppointmentDetails.visitReasonName,
        Validators.required
      ),

      start: new FormControl(
        { value: this.rescheduleAppointmentDetails.start, disabled: true },
        Validators.required
      ),
      end: new FormControl(
        this.rescheduleAppointmentDetails.end,
        Validators.required
      ),
      sessionStartDate: new FormControl(
        {
          value: formatDate(new Date(this.rescheduleAppointmentDetails.start)),
          disabled: true,
        },
        Validators.required
      ),
      sessionStartTime: new FormControl(
        {
          value: this.timeArrayMap.get(
            Number(this.rescheduleAppointmentDetails.sessionStartTime)
          ),
          disabled: true,
        },
        Validators.required
      ),
      sessionDuration: new FormControl(
        this.rescheduleAppointmentDetails.sessionDuration,
        Validators.required
      ),

      sessionCode: new FormControl(
        this.rescheduleAppointmentDetails.sessionCode
      ),
      sessionFor: new FormControl(
        this.rescheduleAppointmentDetails.sessionFor,
        Validators.required
      ),
      sessionType: new FormControl(
        this.rescheduleAppointmentDetails.sessionType,
        Validators.required
      ),

      physicianId: new FormControl(
        this.rescheduleAppointmentDetails.physicianId,
        Validators.required
      ),
      physicianFirstName: new FormControl(
        this.rescheduleAppointmentDetails.physicianFirstName,
        Validators.required
      ),
      physicianMiddleName: new FormControl(
        this.rescheduleAppointmentDetails.physicianMiddleName
      ),
      physicianLastName: new FormControl(
        this.rescheduleAppointmentDetails.physicianLastName,
        Validators.required
      ),
      physicianEmail: new FormControl(
        this.rescheduleAppointmentDetails.physicianEmail,
        Validators.required
      ),
      physicianPhone: new FormControl(
        this.rescheduleAppointmentDetails.physicianPhone,
        Validators.required
      ),

      billingProviderRefId: new FormControl(
        this.rescheduleAppointmentDetails.billingProviderRefId,
        Validators.required
      ),

      queue: new FormControl(
        this.rescheduleAppointmentDetails.queue,
        Validators.required
      ),

      cancellationReason: new FormControl(
        this.rescheduleAppointmentDetails.cancellationReason
      ),

      recurringAppointment: new FormControl(
        this.rescheduleAppointmentDetails?.recurringAppointment
      ),
      recurringStart: new FormControl(
        this.rescheduleAppointmentDetails?.recurringStart
      ),
      recurringEnd: new FormControl(
        this.rescheduleAppointmentDetails?.recurringEnd
      ),
      recurringFrequency: new FormControl(
        this.rescheduleAppointmentDetails?.recurringFrequency
      ),
      recurringFrequencyNumber: new FormControl(
        this.rescheduleAppointmentDetails?.recurringFrequencyNumber
      ),
      recurringFrequencyInterval: new FormControl(
        this.rescheduleAppointmentDetails?.recurringFrequencyInterval
      ),
      recurringFrequencyMonthNumber: new FormControl(
        this.rescheduleAppointmentDetails?.recurringFrequencyMonthNumber
      ),
      recurringDays: this.formBuilder.group({
        sunday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.sunday
        ),
        monday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.monday
        ),
        tuesday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.tuesday
        ),
        wednesday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.wednesday
        ),
        thursday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.thursday
        ),
        friday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.friday
        ),
        saturday: new FormControl(
          this.rescheduleAppointmentDetails?.recurringDays?.saturday
        ),
      }),

      cancelOccurance: new FormControl(false),
      editSeries: new FormControl(false),
    });

    // WhenEver the session duration changes
    this.therapysessionForm.controls['sessionDuration'].valueChanges.subscribe(
      () => {
        this.sessionDurationChanged();
      }
    );
  }

  // Getting the appointment to be rescheduled details
  getAppointmentDetails() {
    this.isLoadingAppointmentDetails = true;

    this.therapySessionService
      .getTherapySessionDetails(this.rescheduleAppoitmentId)
      .subscribe({
        next: (response) => {
          this.rescheduleAppointmentDetails = response.data;
          this.isLoadingAppointmentDetails = false;

          this.patientId = this.rescheduleAppointmentDetails.patientId;
          this.loadPatientDetails();

          this.buildForm();

          // Load All Active Providers at patient's facility
          this.loadActiveProviders();
        },
        error: (error) => {
          this.isLoadingAppointmentDetails = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load appointment details'
          );
        },
      });
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCareFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');
          }

          // Get the permissions from permission sandbox
          this.permissionsSandbox.permissions$.subscribe((response) => {
            this.hasCaseAddAccess = hasAccess(
              this.keycloakService,
              'CASES_ADD',
              response,
              null
            );
          });
        });
      }
    });
  }

  // If this organization has care log feature
  loadAllActiveCareCases() {
    // Loading Existing Active Cases
    this.caseApiService
      .getCases(this.patient.id, null, null, null, 'active', 100, 0)
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.existingCaseList = response.items;
          } else {
            this.existingCaseList = [];
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load active case management cases'
          );
        },
      });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
        this.facilitiesSelectionList = response;

        this.loadFacilitiesMap();
        this.loadVisibleFacilitiesProvidersSet();
      }
    });
  }

  loadFacilitiesMap() {
    this.myFacilities.forEach((facility) => {
      this.facilitiesListMap.set(facility.id, facility);
    });
  }

  // Create a set to load all active providers list in a set for performance
  loadVisibleFacilitiesProvidersSet() {
    let newVisibleProvidersSet = new Set<string>();
    // For All visible facilities, append the doctors/providers
    this.filteredFacilities.forEach((visibleFacility) => {
      if (visibleFacility?.doctors) {
        visibleFacility.doctors.forEach((provider) => {
          newVisibleProvidersSet.add(provider);
        });
      }
    });

    this.filteredFacilitiesProviders = newVisibleProvidersSet;
  }

  // Get Visit Reasons From the SandBox
  loadVisitReasons() {
    this.visitReasonsSandBox.visitReasonsLoading$.subscribe((response) => {
      // If the visit Reasons Have Loaded Successfully
      if (!response) {
        // Now the load has been complete, get the visit reasons
        this.visitReasonsSandBox.visitReasons$.subscribe((visitReasons) => {
          this.visitReasons = visitReasons;
          this.visitReasonsColorMap = new Map<string, string>();

          // Set these visit reasons in color map
          visitReasons.forEach((visitReason) => {
            this.visitReasonsColorMap.set(visitReason.id, visitReason.color);
            this.visitReasonsListMap.set(visitReason.id, visitReason);
          });
        });

        // Load Existing Sessions
        this.getAllSession();
      }
    });
  }

  // Load All Existing Appointments
  getAllSession() {
    this.existingAppointmentList = [];

    this.dashboardsService.getSessionsForNonProvider().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.existingAppointmentList = response.items;
          this.loadExistingEvents();
        }
      },
      error: (error) => {
        this.existingAppointmentList = [];
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load existing calendar appointments.'
        );
      },
    });
  }

  // Loading Mobiscroll resources
  loadExistingEvents() {
    if (this.existingAppointmentList) {
      let mobiScrollEvents = [];

      // Build the events
      this.existingAppointmentList.forEach((existingSession) => {
        let newEvent = <MbscCalendarEvent>{
          id: existingSession.id,
          resource: existingSession.physicianId,
          start: new Date(existingSession.start),
          end: new Date(existingSession.end),
          title: existingSession.title,

          // Editable if the id is equal to reschedule appointment id
          color:
            this.rescheduleAppoitmentId != existingSession.id
              ? 'darkgray'
              : '#2196f3',
          editable: this.rescheduleAppoitmentId === existingSession.id,

          status: existingSession.status,
          patientId: existingSession.patientId,
          organizationId: existingSession.organizationId,
          facilityId: existingSession.billingProviderRefId,
          facilityName: existingSession.billingProviderName,
          startTime: existingSession.sessionStartTime,
          duration: existingSession.sessionDuration,
          sessionCode: existingSession.sessionCode,
          sessionType: existingSession.sessionType,
          sessionFor: existingSession.sessionFor,
          groupAppointment: existingSession.groupAppointment,
        };

        mobiScrollEvents.push(newEvent);
      });

      // Finally append these events with existing events
      this.myEvents = [...this.myEvents, ...mobiScrollEvents];

      // Navigate to that event
      this.myEvents.map((event) => {
        if (event.id === this.rescheduleAppoitmentId) {
          this.mbscCalendar.navigate(event.start, true);
        }
      });
    }
  }

  // Check if the time zone exists and if not use the CT
  checkTimeZoneExists() {
    let timeZoneExists = false;
    this.calendarTimeZones.map((zone) => {
      if (zone.id === this.selectedCalendarTimeZone) {
        timeZoneExists = true;
      }
    });
    if (!timeZoneExists) {
      this.selectedCalendarTimeZone = 'America/Chicago';
      this.timeZoneChanged();
    }
  }

  // Handle time zone change to display date
  timeZoneChanged() {
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
  }

  // Get Patient Details
  loadPatientDetails() {
    this.patientDetailsLoading = true;

    this.patientAPIService.getPatientDetails(this.patientId, null).subscribe({
      next: (response) => {
        if (response && response.data) {
          this.patient = response.data;

          if (this.hasCareFeature) {
            this.loadAllActiveCareCases();
          }
        }
        this.patientDetailsLoading = false;
      },
      error: (error) => {
        this.patientDetailsLoading = false;
        this.toastMessageService.displayErrorMessage(
          "Error: Failed to retreive member's information."
        );
      },
    });
  }

  // Get All Organizational Holidays
  loadOrganizationalHolidays() {
    this.organizationalTimeOffLoading = true;
    this.timeOffAPIService.getOrganizationTimeOff(200, 0).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.organizationalTimeOffs = response.items;

          // Now add these dates to invalids
          this.loadOrganizationalHolidaysOnMBSC();
        }
        this.organizationalTimeOffLoading = false;
      },
      error: (error) => {
        this.organizationalTimeOffLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive organizational holidays.'
        );
      },
    });
  }

  // Load Organizational Holidays as invalid in Mobiscroll
  loadOrganizationalHolidaysOnMBSC() {
    // Loop through each holiday for organization and mark them as invalid dates in calendar
    this.organizationalTimeOffs.forEach((holiday) => {
      // Start Date For Invalid in Mobi Scroll
      let startDate = new Date(holiday.startDay);
      // End Date For Invalid in Mobi Scroll
      let endDate = new Date(holiday.endDay);
      // Set End Date to be the start of the next day of end date
      //   endDate.setDate(endDate.getDate() + 1);

      // Invalid Date Structure as per Mobi Scroll
      let invalidDateInCalendar = {
        start: addMinutes(startDate, 2),
        end: endDate,
        title: holiday?.reason,
        cssClass: 'md-stripes-gray-bg',
      };
      // Now update existing invalid and add this invalid date aswell
      this.invalids = [...this.invalids, invalidDateInCalendar];
      this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
    });
  }

  // Block calendar upto now
  blockCalendarUptoNow() {
    this.invalids = [
      ...this.invalids,
      // Giving extra cushion to schedule appointments for the past 24 hours
      {
        recurring: {
          repeat: 'daily',
          until: this.dayBeforeYesterday,
        },
      },
      // {
      //   start: this.dayBeforeYesterday,
      //   end: this.currentTime,
      // },
    ];
  }

  // Load All Active Providers
  loadActiveProviders() {
    this.providersListLoading = true;

    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.supervisingProvidersList = response.items;
          this.filteredSupervisingProviderList = response.items;

          // Load These Providers as Resources for MobiScroll
          this.loadMobiScrollResources();

          // If there is providersList
          this.loadScheduleForAllProviders();
          this.loadTimeOffsForAllProviders();

          // Determine the facilities box selection for the selected provider
          this.determineSelectedProviderFacilities();
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers at the member facility.'
        );
        this.providersListLoading = false;
      },
    });
  }

  // Re populate provider details
  renderingProviderChanged() {
    let selectedProvider = this.providersList.filter(
      (provider) =>
        provider.id === this.therapysessionForm.controls['physicianId'].value
    );

    if (selectedProvider && selectedProvider.length > 0) {
      // Populate therapy session physician details
      let providerDetails = selectedProvider.at(0);
      this.therapysessionForm.controls['physicianId'].setValue(
        providerDetails.id
      );
      this.therapysessionForm.controls['physicianFirstName'].setValue(
        providerDetails.firstName
      );
      this.therapysessionForm.controls['physicianMiddleName'].setValue(
        providerDetails.middleName
      );
      this.therapysessionForm.controls['physicianLastName'].setValue(
        providerDetails.lastName
      );
      this.therapysessionForm.controls['physicianEmail'].setValue(
        providerDetails.emailAddress
      );
      this.therapysessionForm.controls['physicianPhone'].setValue(
        providerDetails.phoneNumber
      );
    }
  }

  //determineSelectedProviderFacilities
  determineSelectedProviderFacilities() {
    let newFacilitiesSelectionList = [];

    let selectedProvider = this.providersList.filter(
      (provider) =>
        provider.id === this.therapysessionForm.controls['physicianId'].value
    );

    if (selectedProvider && selectedProvider.length > 0) {
      let providerDetails = selectedProvider.at(0);

      providerDetails.facilities.forEach((facilityId) => {
        if (this.facilitiesListMap.get(facilityId)) {
          newFacilitiesSelectionList.push(
            this.facilitiesListMap.get(facilityId)
          );
        }
      });

      this.selectedProviderFacilities = newFacilitiesSelectionList;

      // Check if the filtered facilities include the newly selected resource facility
      if (
        this.selectedProviderFacilities.filter(
          (facility) =>
            facility.id ===
            this.therapysessionForm.controls['billingProviderRefId'].value
        ).length == 0
      ) {
        // If the newly added facility does not exist, reset billingProviderRefId
        this.therapysessionForm.controls['billingProviderRefId'].setValue('');
      }
    }
  }

  // Loading Resources for Mobi Scroll
  loadMobiScrollResources() {
    this.allResources = [];
    this.providersScheduleTimeOffMap = new Map<String, any>();

    // Pushing each provider as resource
    this.providersList.forEach((provider) => {
      // Load provider as mobiscroll resource
      this.allResources.push({
        id: provider.id,
        name: provider.lastName + ', ' + provider.firstName,
      });
      // Load Provider to Map
      this.providersListMap.set(provider.id, provider);

      // Just Setting the Time map as null for now
      this.providersScheduleTimeOffMap.set(provider.id, {
        providerId: provider.id,
        workingHours: null,
      });
    });

    this.filteredResources = [];
    this.filteredAllResources = [...this.allResources];

    // Auto select the rendering provider for the existing session
    let filteredPhysician = this.filteredAllResources.filter(
      (provider) =>
        provider.id === this.rescheduleAppointmentDetails.physicianId
    );

    if (filteredPhysician && filteredPhysician.length >= 0) {
      this.filteredResources = [...filteredPhysician];
    }
  }

  // Load Time Off for all Providers
  loadTimeOffsForAllProviders() {
    this.timeOffAPIService.getAllMyProviderTimeOffsForSession().subscribe({
      next: (response) => {
        if (response && response?.items) {
          // For each time off block the resource calendar
          response.items.forEach((timeOff) => {
            // Start Date For Invalid in Mobi Scroll
            let startDate = new Date(timeOff.startDay);
            // End Date For Invalid in Mobi Scroll
            let endDate = new Date(timeOff.endDay);
            // Set End Date to be the start of the next day of end date
            //   endDate.setDate(endDate.getDate() + 1);

            let invalidDateInCalendar = {
              start: addMinutes(startDate, 10),
              end: endDate,
              title: timeOff?.reason,
              resource: timeOff.careProviderId,
              cssClass: 'md-rect-bg',
            };

            // Now update existing invalid and add this invalid date aswell
            this.invalids = [...this.invalids, invalidDateInCalendar];
            this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
          });
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load time offs for providers'
        );
      },
    });
  }

  // Load Schedule for all Providers
  loadScheduleForAllProviders() {
    // Obserable to handle multiple http call
    const allproviderScheduleTimeOffObservable: Observable<any>[] = [];

    // Get Schedule and Time Offs for all proviers
    allproviderScheduleTimeOffObservable.push(
      this.getAllProvidersWorkingHoursAndTimeOffs().pipe(
        map((responseList) => {
          // For Providers Schedule
          if (responseList[0]?.items) {
            // Now loop through each schedule and set the provider time
            responseList[0].items.forEach((providerSchedule) => {
              // First check if this provider is in schedule map
              if (
                this.providersScheduleTimeOffMap.get(
                  providerSchedule.careProviderId
                )
              ) {
                // Now as we have this provider update their set
                let currentTimeOffMapValue =
                  this.providersScheduleTimeOffMap.get(
                    providerSchedule.careProviderId
                  );
                currentTimeOffMapValue.workingHours = providerSchedule;

                this.providersScheduleTimeOffMap.set(
                  providerSchedule.careProviderId,
                  currentTimeOffMapValue
                );
              }
            });
          }

          // For Providers Time Offs
          return responseList;
        }),
        catchError((error) => {
          // If there is an error, display the error in toast message
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load working hours and time offs'
          );
          return of(null);
        })
      )
    );

    // Once all the providers time off and schedule has been loaded
    forkJoin(allproviderScheduleTimeOffObservable).subscribe(() => {
      // Now that the time Off and Working hours have been populated
      this.loadResourceSchedule();
    });
  }

  public getAllProvidersWorkingHoursAndTimeOffs(): Observable<any[]> {
    let allWorkingHours =
      this.workinghoursdialogapiservice.getAllMyProvidersSchedules();

    return forkJoin([allWorkingHours]);
  }

  // Load Resource Schedules
  loadResourceSchedule() {
    // As of this point, the provider's schedule and time offs have been loaded
    // For Each Provider, we now need to load their working hours and block any time offs
    this.providersScheduleTimeOffMap.forEach((providerMap) => {
      // Now Load Each Provider Schedule
      this.loadResourceWorkingHours(
        providerMap.providerId,
        providerMap.workingHours
      );
    });

    // Now that all the resource working hours have been populated, determining the calendar start and end time
    this.determineCalendarStartEndTime();
  }

  // Determining calendar start and end time
  determineCalendarStartEndTime() {
    // Handle the time
    if (this.allWorkingHoursArray.length > 0) {
      // For check if there are numbers greater than 2400
      this.allWorkingHoursArray = this.allWorkingHoursArray.map((num) =>
        num >= 2400 ? 0 : num
      );

      // Now that we have converted the all working hours array
      let lowStart = Math.min(...this.allWorkingHoursArray);
      let highEnd = Math.max(...this.allWorkingHoursArray);

      // Now convert them into appropriate format
      let startTimeHour = Math.trunc(lowStart / 100);
      let startTimeHourString = startTimeHour.toString();

      let endTimeHour = Math.trunc(highEnd / 100);
      //endTimeHour = endTimeHour + 1;
      let endTimeHourString = endTimeHour.toString();

      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }

      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }

      // Now append this as the calendar start and end time range
      this.calendarStartHour = startTimeHourString + ':00';
      this.calendarEndHour = endTimeHourString + ':00';

      // Once the star and end Time hour has been determined
      this.responsiveMobiScrollCalendarView = {
        // Min-width:0px
        xsmall: {
          view: {
            schedule: {
              type: 'day',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
        // min-width: 768px
        medium: {
          view: {
            schedule: {
              type: 'week',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
      };
    }

    // // Navigate to current time
    // this.mbscCalendar.navigate(new Date(), true);
  }

  // Load Calendar Working Hours For Each Provider/Resource
  loadResourceWorkingHours(resourceId, workingHoursData) {
    // Making Sure that the working hours Data exists for this provider
    if (workingHoursData && workingHoursData?.id) {
      // Loop Through each day to find the provider's working hours
      this.determineWorkingHourForDay(
        resourceId,
        'SU',
        workingHoursData?.sunday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'MO',
        workingHoursData?.monday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TU',
        workingHoursData?.tuesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'WE',
        workingHoursData?.wednesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TH',
        workingHoursData?.thursday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'FR',
        workingHoursData?.friday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'SA',
        workingHoursData?.saturday
      );
    } else {
      // If The Working Hour of the provider is not set by default use 9-5
      this.handleDefaultWorkingHoursForResource(resourceId);
    }
  }

  // Handle the working hours for each day for each resource
  determineWorkingHourForDay(resourceId, day, data) {
    // If this day is an off day, block the day for this resource
    if (data.offday) {
      this.blockAllDayForResource(resourceId, day);
    } else {
      // If this is not a off day, now determine their working hours
      if (data?.workingHours) {
        // Now as there is working hours defined
        if (data.workingHours?.length == 1) {
          this.addSingleStartEndTime(
            resourceId,
            day,
            data.workingHours[0].startTime,
            data.workingHours[0].endTime
          );
        } else if (data.workingHours?.length > 1) {
          this.addMultipleStartEndTime(resourceId, day, data.workingHours);
        }
      }
    }
  }

  // Setting Default working hours to be 9-5 for the passed in resource
  handleDefaultWorkingHoursForResource(resourceId) {
    // Block Saturday and Sundays First
    this.blockAllDayForResource(resourceId, 'SA');
    this.blockAllDayForResource(resourceId, 'SU');

    // For Monday To Friday set 9-5
    this.addSingleStartEndTime(resourceId, 'MO', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TU', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'WE', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TH', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'FR', 900, 1700);
  }

  // Block Calendar Time For Single start and end time passed
  addSingleStartEndTime(
    resourceId: string,
    day: string,
    startTime: number,
    endTime: number
  ) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(startTime, endTime);

    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    let startTimeHour = Math.trunc(startTime / 100);
    let startTimeHourString = startTimeHour.toString();
    let startTimeMinutes = startTime % 100;
    let startTimeMinutesString = startTimeMinutes.toString();

    let endTimeHour = Math.trunc(endTime / 100);
    let endTimeHourString = endTimeHour.toString();
    let endTimeMinutes = endTime % 100;
    let endTimeMinutesString = endTimeMinutes.toString();

    // Handling for 24
    if (startTimeHour == 24) {
      startTimeHourString = '00';
    }
    if (endTimeHour == 24) {
      endTimeHourString = '00';
    }

    // Converting to Correct Format
    if (startTimeHourString.length === 1) {
      startTimeHourString = '0' + startTimeHourString;
    }
    if (endTimeHourString.length === 1) {
      endTimeHourString = '0' + endTimeHourString;
    }
    if (startTimeMinutesString.length === 1) {
      startTimeMinutesString = startTimeMinutesString + '0';
    }
    if (endTimeMinutesString.length === 1) {
      endTimeMinutesString = endTimeMinutesString + '0';
    }

    // Now block the time for this resource
    this.invalids = [
      ...this.invalids,
      {
        start: dayStartTime,
        end: startTimeHourString + ':' + startTimeMinutesString,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
      {
        start: endTimeHourString + ':' + endTimeMinutesString,
        end: dayEndTime,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block Calendar Time For Multiple start and end time passed
  addMultipleStartEndTime(resourceId: string, day: string, workingHours) {
    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    workingHours.forEach((workHour, index) => {
      let firstIndex = index == 0;
      let lastIndex = index == workingHours.length - 1;

      // Push this startTime & EndTime to the hours array
      this.allWorkingHoursArray.push(workHour.startTime, workHour.endTime);

      let startTimeHour = Math.trunc(workHour.startTime / 100);
      let startTimeHourString = startTimeHour.toString();
      let startTimeMinutes = workHour.startTime % 100;
      let startTimeMinutesString = startTimeMinutes.toString();

      let endTimeHour = Math.trunc(workHour.endTime / 100);
      let endTimeHourString = endTimeHour.toString();
      let endTimeMinutes = workHour.endTime % 100;
      let endTimeMinutesString = endTimeMinutes.toString();

      if (startTimeHour == 24) {
        startTimeHourString = '00';
      }
      if (endTimeHour == 24) {
        endTimeHourString = '00';
      }

      // Converting to Correct Format
      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }
      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }
      if (startTimeMinutesString.length === 1) {
        startTimeMinutesString = '0' + startTimeMinutesString;
      }
      if (endTimeMinutesString.length === 1) {
        endTimeMinutesString = '0' + endTimeMinutesString;
      }

      if (firstIndex) {
        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }
        // Blocking from start of day to start of time and end of time to next index start
        this.invalids = [
          ...this.invalids,
          {
            start: dayStartTime,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else if (lastIndex) {
        // For last Index just block from last index end time to the end of the day
        this.invalids = [
          ...this.invalids,
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end: dayEndTime,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else {
        // For multiple start and end times within the start and end index
        // Get the Next Index Start Time
        let previousIndexEndTimeHour = Math.trunc(
          workingHours[index - 1].endTime / 100
        );
        let previousIndexEndTimeHourString =
          previousIndexEndTimeHour.toString();
        let previousIndexEndTimeMinutes = workingHours[index - 1].endTime % 100;
        let previousIndexEndTimeMinutesString =
          previousIndexEndTimeMinutes.toString();
        // Converting to the right format
        if (previousIndexEndTimeHourString.length === 1) {
          previousIndexEndTimeHourString = '0' + previousIndexEndTimeHourString;
        }
        if (previousIndexEndTimeMinutesString.length === 1) {
          previousIndexEndTimeMinutesString =
            '0' + previousIndexEndTimeMinutesString;
        }

        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[index + 1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[index + 1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }

        // Blocking from previos end to current start and current end to next start
        this.invalids = [
          ...this.invalids,
          {
            start:
              previousIndexEndTimeHourString +
              ':' +
              previousIndexEndTimeMinutesString,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      }
    });
  }

  // Block all day for this resource
  blockAllDayForResource(resourceId, day) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(800, 1700);

    // Blocking all Day for the passed in day for the Resource
    this.invalids = [
      ...this.invalids,
      {
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  calendarTypeChanged(event) {
    this.calendarViewType = event.value;

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: event.value,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
    };
  }

  timeCellStepChanged() {
    this.timeLabelStep = this.timeCellStep;
    // this.therapysessionForm.controls['sessionDuration'].setValue(
    //   this.timeCellStep
    // );

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
    };
  }

  sliderValueChanged() {
    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
    };
  }

  // Search By provider Name Changed
  searchByProviderNameChanged() {
    if (this.searchProviderName) {
      this.filteredAllResources = this.allResources.filter((resource) => {
        const nameParts = resource.name.split(', ');
        const lastName = nameParts[0].toLowerCase();
        const firstName = nameParts[1].toLowerCase();

        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;

        if (
          concatFirstLast.includes(this.searchProviderName.toLowerCase()) ||
          concatLastFirst.includes(this.searchProviderName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredAllResources = [...this.allResources];
    }
  }

  // Quick Actions To Select/Deselect all providers
  selectAllProviders() {
    this.filteredResources = [...this.allResources];

    // Handle Resource Filter
    this.handleResourceFilter();
  }

  deselectAllProviders() {
    this.filteredResources = [];

    // Handle Resource Filter
    this.handleResourceFilter();
  }

  handleResourceFilter() {
    // Need to Filter current visible resource based on facility
    let newFilteredResource = [];

    // For Each filtered resource check if their facility is also visible
    this.filteredResources.forEach((resource) => {
      if (this.filteredFacilitiesProviders.has(resource.id)) {
        newFilteredResource.push(resource);
      }
    });

    // This is the final filtered Resource
    this.filteredResources = newFilteredResource;
  }

  // Disable if this resource is not in the facilty
  isResourceFacilityVisible(provider) {
    // Check if the current providers set has this provider id
    return this.filteredFacilitiesProviders.has(provider.id);
  }

  // This is for resource checkbox
  isResourceVisible(provider) {
    return this.filteredResources.includes(provider);
  }

  // Show or hide resource
  selectDeselctResource(event, provider) {
    // Event checked => true meaning add the provider back
    if (event.checked) {
      // If the filtered list does not include the provider then add the provider to the resource to display
      if (!this.filteredResources.includes(provider)) {
        this.filteredResources = [...this.filteredResources, provider];
      }
    } else {
      // If the filtered list does include the provider then remove the provider to the resource to display
      if (this.filteredResources.includes(provider)) {
        this.filteredResources = this.filteredResources.filter(
          (resource) => resource != provider
        );
      }
    }
  }

  // For Facilities Options
  isFacilityVisible(facility) {
    return this.filteredFacilities.includes(facility);
  }

  selectAllFacilities() {
    this.filteredFacilities = [...this.myFacilities];

    // Handle Providers Map Change
    this.loadVisibleFacilitiesProvidersSet();

    // Handle Resource filter
    this.handleResourceFilter();
  }

  deselectAllFacilities() {
    this.filteredFacilities = [];

    // Handle Providers Map Change
    this.loadVisibleFacilitiesProvidersSet();

    // Handle Resource filter
    this.handleResourceFilter();
  }

  selectDeselctFacility(event, facility) {
    if (event.checked) {
      // Add the facility to the list
      if (!this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = [...this.filteredFacilities, facility];

        // Handle Providers Map Change
        this.loadVisibleFacilitiesProvidersSet();

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    } else {
      // Remove the facility to the list
      if (this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = this.filteredFacilities.filter(
          (resource) => resource != facility
        );

        // Handle Providers Map Change
        this.loadVisibleFacilitiesProvidersSet();

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    }
  }

  // Facility search box
  searchByFacilityNameChanged() {
    if (this.searchFacilityName) {
      this.facilitiesSelectionList = this.myFacilities.filter((facility) => {
        if (
          facility.facilityName
            .toLowerCase()
            .includes(this.searchFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.facilitiesSelectionList = [...this.myFacilities];
    }
  }

  // For Days Range Format Label
  formatDaySliderLabel(value: number): string {
    switch (value) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }

  addNewCase() {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'ADD',
        case: null,
        patientId: this.patient.id,
        facilityId: this.patient.facilityId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAllActiveCareCases();
      }
    });
  }

  // If there is case management and a case has been associated with it
  careCaseChanged(event) {
    let filteredCase = this.existingCaseList.filter(
      (temp) => temp.id === event.value
    );

    if (filteredCase[0]) {
      // Set the case number
      this.therapysessionForm.controls['caseNumber'].setValue(
        filteredCase[0]?.caseNumber
      );
    } else {
      // Reset the case number
      this.therapysessionForm.controls['caseNumber'].setValue(
        filteredCase[0]?.caseNumber
      );
    }
  }

  visitReasonChanged() {
    let newVisitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;

    let newVisitReasonDetails = this.visitReasonsListMap.get(newVisitReasonId);

    // Now Set the session title
    let newTitle = `${this.patient.lastName}, ${this.patient.firstName} - ${newVisitReasonDetails.reasonName}`;

    // Set form control
    this.therapysessionForm.controls['title'].setValue(newTitle);
    this.therapysessionForm.controls['visitReasonName'].setValue(
      newVisitReasonDetails?.reasonName
    );
    this.therapysessionForm.controls['sessionDuration'].setValue(
      newVisitReasonDetails?.duration
    );

    // // Now Set the appropriate End Time
    this.setNewEndTime();
  }

  //  Session Duration Changed
  sessionDurationChanged() {
    if (this.therapysessionForm.controls['start'].value) {
      this.setNewEndTime();
    }
  }

  // Set the new end Time
  setNewEndTime() {
    let newVisitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;
    let newEndTime = addMinutes(
      this.therapysessionForm.controls['start'].value,
      this.therapysessionForm.controls['sessionDuration'].value
    );

    this.therapysessionForm.controls['end'].setValue(newEndTime);

    // Now update the mobiscroll event
    this.myEvents = this.myEvents.map((event) => {
      if (event.id == this.rescheduleAppoitmentId) {
        // Update this event
        event.title = this.therapysessionForm.controls['title'].value;
        event.end = newEndTime;
        // event.color = this.visitReasonsColorMap.get(newVisitReasonId);
        event.duration =
          this.therapysessionForm.controls['sessionDuration'].value;
      }
      return event;
    });
  }

  // Status changed for cancellation reasons
  statusChanged(event) {
    if (event.value === 'Canceled') {
      this.therapysessionForm
        .get('cancellationReason')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('cancellationReason')
        .updateValueAndValidity();
    } else {
      this.therapysessionForm.get('cancellationReason').clearValidators();
      this.therapysessionForm.get('cancellationReason').setValue('');
      this.therapysessionForm
        .get('cancellationReason')
        .updateValueAndValidity();
    }
  }

  submitForm() {
    // Populate the rescheduleAppointmentDetails with the new data
    this.rescheduleAppointmentDetails.caseId =
      this.therapysessionForm.controls['caseId'].value;
    this.rescheduleAppointmentDetails.caseNumber =
      this.therapysessionForm.controls['caseNumber'].value;

    this.rescheduleAppointmentDetails.title =
      this.therapysessionForm.controls['title'].value;
    this.rescheduleAppointmentDetails.visitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;
    this.rescheduleAppointmentDetails.visitReasonName =
      this.therapysessionForm.controls['visitReasonName'].value;

    this.rescheduleAppointmentDetails.start =
      this.therapysessionForm.controls['start'].value;
    this.rescheduleAppointmentDetails.end =
      this.therapysessionForm.controls['end'].value;
    this.rescheduleAppointmentDetails.sessionStartDate =
      this.therapysessionForm.controls['sessionStartDate'].value;
    this.rescheduleAppointmentDetails.sessionDuration =
      this.therapysessionForm.controls['sessionDuration'].value;
    this.rescheduleAppointmentDetails.sessionFor =
      this.therapysessionForm.controls['sessionFor'].value;
    this.rescheduleAppointmentDetails.sessionType =
      this.therapysessionForm.controls['sessionType'].value;

    this.rescheduleAppointmentDetails.physicianId =
      this.therapysessionForm.controls['physicianId'].value;
    this.rescheduleAppointmentDetails.billingProviderRefId =
      this.therapysessionForm.controls['billingProviderRefId'].value;
    this.rescheduleAppointmentDetails.queue =
      this.therapysessionForm.controls['queue'].value;

    this.rescheduleAppointmentDetails.cancellationReason =
      this.therapysessionForm.controls['cancellationReason'].value;

    // Update for recurring appointment
    if (this.rescheduleAppointmentDetails.recurringAppointment) {
      this.rescheduleAppointmentDetails.recurringStart =
        this.therapysessionForm.controls['recurringStart'].value;

      this.rescheduleAppointmentDetails.recurringEnd =
        this.therapysessionForm.controls['recurringEnd'].value;

      this.rescheduleAppointmentDetails.recurringFrequency =
        this.therapysessionForm.controls['recurringFrequency'].value;

      this.rescheduleAppointmentDetails.recurringFrequencyNumber =
        this.therapysessionForm.controls['recurringFrequencyNumber'].value;

      this.rescheduleAppointmentDetails.recurringFrequencyInterval =
        this.therapysessionForm.controls['recurringFrequencyInterval'].value;

      this.rescheduleAppointmentDetails.recurringFrequencyMonthNumber =
        this.therapysessionForm.controls['recurringFrequencyMonthNumber'].value;

      this.rescheduleAppointmentDetails.recurringDays =
        this.therapysessionForm.controls['recurringDays'].value;

      this.rescheduleAppointmentDetails.cancelOccurance =
        this.therapysessionForm.controls['cancelOccurance'].value;

      this.rescheduleAppointmentDetails.editSeries =
        this.therapysessionForm.controls['editSeries'].value;
    }
    this.processing = true;

    this.therapySessionService
      .updateSession(this.rescheduleAppointmentDetails)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated the appointment.'
          );
          this.processing = false;

          if (this.parent === 'Member') {
            // Navigate to Member page
            this.router.navigate([
              `/main/member/${this.rescheduleAppointmentDetails.patientId}/NOTES`,
            ]);
          } else {
            // Else navigate to dashboard
            this.router.navigate(['/']);
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the appointment.'
          );
          this.processing = false;
        },
      });
  }

  // Get current label for the recurring
  getRecurringLabel() {
    let recurringFrequency =
      this.therapysessionForm.controls['recurringFrequency'].value;

    let start = this.therapysessionForm.controls['recurringStart'].value;
    let freqNum =
      this.therapysessionForm.controls['recurringFrequencyNumber'].value;
    let freqInt =
      this.therapysessionForm.controls['recurringFrequencyInterval'].value;

    if (recurringFrequency === 'Daily') {
      return (
        'Occurs every day starting ' + new Date(start).toLocaleDateString()
      );
    } else if (recurringFrequency === 'Weekday') {
      return (
        'Occurs every week day starting ' + new Date(start).toLocaleDateString()
      );
    }
    // For Days
    if (freqInt === 'Day') {
      if (freqNum == 1) {
        return (
          'Occurs every day starting ' + new Date(start).toLocaleDateString()
        );
      } else {
        return (
          'Occurs every ' +
          freqNum +
          ' days starting ' +
          new Date(start).toLocaleDateString()
        );
      }
    } else if (freqInt === 'Week') {
      const recurringDaysGroup = <FormGroup>(
        this.therapysessionForm.controls['recurringDays']
      );
      let sundaySelected = recurringDaysGroup.controls['sunday'].value;
      let mondaySelected = recurringDaysGroup.controls['monday'].value;
      let tuesdaySelected = recurringDaysGroup.controls['tuesday'].value;
      let wednesdaySelected = recurringDaysGroup.controls['wednesday'].value;
      let thursdaySelected = recurringDaysGroup.controls['thursday'].value;
      let fridaySelected = recurringDaysGroup.controls['friday'].value;
      let saturdaySelected = recurringDaysGroup.controls['saturday'].value;

      // If none is selected, return empty
      if (
        !sundaySelected &&
        !mondaySelected &&
        !tuesdaySelected &&
        !wednesdaySelected &&
        !thursdaySelected &&
        !fridaySelected &&
        !saturdaySelected
      ) {
        return '';
      }

      if (freqNum == 1) {
        let message = 'Occurs every ';
        if (sundaySelected) {
          message += 'Sunday, ';
        }
        if (mondaySelected) {
          message += 'Monday, ';
        }
        if (tuesdaySelected) {
          message += 'Tuesday, ';
        }
        if (wednesdaySelected) {
          message += 'Wednesday, ';
        }
        if (thursdaySelected) {
          message += 'Thursday, ';
        }
        if (fridaySelected) {
          message += 'Friday, ';
        }
        if (saturdaySelected) {
          message += 'Saturday, ';
        }
        message += 'starting ' + new Date(start).toLocaleDateString();
        return message;
      } else {
        let message = 'Occurs every ' + freqNum + ' weeks on ';
        if (sundaySelected) {
          message += 'Sunday, ';
        }
        if (mondaySelected) {
          message += 'Monday, ';
        }
        if (tuesdaySelected) {
          message += 'Tuesday, ';
        }
        if (wednesdaySelected) {
          message += 'Wednesday, ';
        }
        if (thursdaySelected) {
          message += 'Thursday, ';
        }
        if (fridaySelected) {
          message += 'Friday, ';
        }
        if (saturdaySelected) {
          message += 'Saturday, ';
        }
        message += 'starting ' + new Date(start).toLocaleDateString();
        return message;
      }
    } else if (freqInt === 'Month') {
      const freqMonth =
        this.therapysessionForm.controls['recurringFrequencyMonthNumber'].value;
      if (!freqMonth) {
        return '';
      }
      if (freqNum == 1) {
        return (
          'Occurs every month on day ' +
          freqMonth +
          ' starting ' +
          new Date(start).toLocaleDateString()
        );
      } else {
        return (
          'Occurs every ' +
          freqNum +
          ' months on day ' +
          freqMonth +
          ' starting ' +
          new Date(start).toLocaleDateString()
        );
      }
    }
  }
}
