import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MbscCalendarEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  momentTimezone,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment-timezone';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import {
  DataTablesModel,
  IFacility,
  ITherapySession,
  Patient,
} from 'src/app/_models';
import {
  CoreService,
  DashboardsService,
  DateTimeZoneService,
  PatientService,
  TherapySessionService,
  TimeOffAPIService,
  ToastMessageService,
  WorkingHoursDialogApiService,
} from 'src/app/_services';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { VisitReasonsSandbox } from 'src/app/shared/sandbox/visit-reasons.sandbox';
import { TimeArrayMap } from 'src/app/shared/utilities/calendar/timeArrays';
import { TimeCellSteps } from 'src/app/shared/utilities/calendar/timeCellArrays';
import {
  getTimeInTimeZone,
  TimeZonesCalendar,
} from 'src/app/shared/utilities/calendar/timeZonesCalendar';
import { formatDate, hasAccess } from 'src/app/shared/utilities/utilities';
import { AddEditPatientComponent } from '../../patients/dialogs/add/add.component';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

momentTimezone.moment = moment;
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});
@Component({
  selector: 'app-schedule-telehealth',
  templateUrl: './schedule-telehealth.component.html',
  styleUrl: './schedule-telehealth.component.css',
})
export class ScheduleTelehealthComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('mbscCalendar') mbscCalendar!: MbscEventcalendar;

  editMeetingId: string = '';
  consolidatedMemberSearch = false;
  showMemberNumber: boolean = false;

  memberType: string = 'Existing Member';
  todaysDate = new Date();

  calendarTimeZones = TimeZonesCalendar;
  // Caledar Time Zone - Default CST
  selectedCalendarTimeZone = '';
  selectedCaldendarTimeZoneDateFormat = '';

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<String, any> = new Map<String, any>();
  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;
  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  // Get All Active Providers
  userName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListMap: Map<String, any> = new Map<String, any>();
  searchProviderName: string = '';
  providersListLoading: boolean = true;
  // Using Map to Store Each Provider's Schedule and Time Off
  providersScheduleTimeOffMap: Map<String, any> = new Map<String, any>();

  // MobiScroll Components
  allResources = [];
  filteredAllResources = [];
  filteredResources = [];

  invalids = [];
  invalidColors = [];
  myEvents: MbscCalendarEvent[] = [];
  allWorkingHoursArray = [];
  calendarStartDay = 0; // Sunday is 0, Monday is 1, ...
  calendarEndDay = 6;
  calendarStartHour = '00:00';
  calendarEndHour = '24:00';
  calendarViewType: string = 'week';
  timeCellStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeLabelStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeCellSteps = TimeCellSteps;

  initialResponsiveView = {
    allDay: false,
    currentTimeIndicator: true,
    startTime: this.calendarStartHour,
    endTime: this.calendarEndHour,
    startDay: this.calendarStartDay,
    endDay: this.calendarEndDay,
    timeCellStep: this.timeCellStep,
    timeLabelStep: this.timeLabelStep,
  };

  // Setting Up Initial MobiScroll Calendar View
  responsiveMobiScrollCalendarView = {
    // Min-width:0px
    xsmall: {
      view: {
        schedule: {
          type: 'day',
          ...this.initialResponsiveView,
        },
      },
    },
    // min-width: 768px
    medium: {
      view: {
        schedule: {
          type: 'week',
          ...this.initialResponsiveView,
        },
      },
    },
  };

  // Current Date & time
  currentTime = new Date();
  yesterday = addDays(new Date(), -1);
  dayBeforeYesterday = addDays(new Date(), -2);
  minimumCalendarDate = addDays(new Date(), -4);
  maximumCalendarDate = addDays(new Date(), 120);

  // Permissions
  hasMemberAddAccess: boolean = false;

  // Member search
  membersList: DataTablesModel = {} as DataTablesModel;
  isMembersLoading: boolean = false;
  memberFirstName: string = '';
  memberLastName: string = '';
  memberPhone: string = '';
  displayedColumns = ['memberName', 'address', 'phoneNumber', 'actions'];

  // Facilities
  orgConfig: any;
  // Facilities
  filterFacilityName: string = '';
  facilities: Array<IFacility> = [];
  filteredFacilitiesProviders = new Set<string>();
  facilitiesListMap: Map<String, any> = new Map<String, any>();
  selectedProviderFacilities: any = [];

  existingSession: any = [];
  loggedInUserId: String;
  loggedInUserName: String;
  isPhysician: boolean = false;
  isOrgAdmin: boolean = false;
  timeArrayMap = TimeArrayMap;

  // Form Components
  meetingInformation: ITherapySession;
  public meetingForm: FormGroup;
  processing: boolean = false;

  // Setting up initial MobiScroll Calendar options
  mobiscrollCalendarOptions: MbscEventcalendarOptions = {
    clickToCreate: 'double',
    dragToCreate: false,
    dragToMove: true,
    dragInTime: true,
    eventOverlap: true,
    dragBetweenResources: false,
    timezonePlugin: momentTimezone,

    // Double click event creation
    onEventCreated: (args) => {
      setTimeout(() => {
        if (!args?.event?.resource) {
          this.myEvents = this.myEvents.filter((event) => event.id != null);
        } else if (args?.event?.resource) {
          // First Getting the selected Time Segment Start Date
          let mobiscrollStartDate: string | object | Date = args.event.start;
          let eventStartDate: Date;

          // Convert to the appropriate date type
          if (typeof mobiscrollStartDate === 'string') {
            eventStartDate = new Date(mobiscrollStartDate);
          } else if (mobiscrollStartDate instanceof Date) {
            eventStartDate = mobiscrollStartDate;
          } else {
            eventStartDate = new Date(mobiscrollStartDate.toString());
          }

          let eventEndDate = addMinutes(
            eventStartDate,
            this.meetingForm.controls['sessionDuration'].value
          );

          let mobiScrollEvent = args.event;

          // Remove previosly selected Events if any
          this.myEvents = this.myEvents.filter((event) => event.id != null);

          // Extracting Data From MobiScroll Event
          let mobiscrollResouce = mobiScrollEvent.resource;
          let selectedSessionProviderId = mobiscrollResouce.toString();

          const { hour, minute, formattedDate } = getTimeInTimeZone(
            eventStartDate,
            this.selectedCalendarTimeZone
          );

          let startHour = hour;
          let startMinutes = minute;
          let startMinutesString = startMinutes.toString();
          if (startMinutesString.length == 1) {
            startMinutesString = startMinutesString + '0';
          }
          let combinedTimeString = startHour.toString() + startMinutesString;

          // Now Load this as a new Event on calendar
          if (!this.editMeetingId) {
            let newMobiscrollEvent = <MbscCalendarEvent>{
              id: null,
              resource: selectedSessionProviderId,
              start: mobiscrollStartDate,
              end: eventEndDate,
              title: 'New Telehealth Meeting',
              color: '#2196f3',
              editable: true,
            };

            //Add this event to the calendar
            this.myEvents = [...this.myEvents, newMobiscrollEvent];
          } else {
            // Set the new start date on the mobiscroll event
            this.myEvents = this.myEvents.map((event) => {
              if (event.id == this.editMeetingId) {
                // Update this event
                event.start = eventStartDate;
                event.end = eventEndDate;
                event.resource = mobiscrollResouce.toString();
              }
              return event;
            });
          }

          // Now pouplate this provider details on the form
          this.meetingForm.controls['physicianId'].setValue(
            selectedSessionProviderId
          );

          // Fill the Provider Details
          this.fillRPDetails();

          // Also populate the start and end time
          this.meetingForm.controls['start'].setValue(mobiscrollStartDate);
          this.meetingForm.controls['end'].setValue(eventEndDate);
          this.meetingForm.controls['sessionStartDate'].setValue(
            formatDate(eventStartDate)
          );
          this.meetingForm.controls['sessionStartTime'].setValue(
            this.timeArrayMap.get(Number(combinedTimeString))
          );

          // Now move to the next screen
          this.stepper.next();
        }
      });
    },

    // When the drag even stops
    onEventDragEnd: (args) => {
      setTimeout(() => {
        //  Start Date Conversion
        let mobiscrollStartDate: string | object | Date = args.event.start;
        let eventStartDate: Date;
        // Convert to the appropriate date type
        if (typeof mobiscrollStartDate === 'string') {
          eventStartDate = new Date(mobiscrollStartDate);
        } else if (mobiscrollStartDate instanceof Date) {
          eventStartDate = mobiscrollStartDate;
        } else {
          eventStartDate = new Date(mobiscrollStartDate.toString());
        }

        // End date Conversion
        let mobiscrollEndDate: string | object | Date = args.event.end;
        let eventEndDate: Date;
        if (typeof mobiscrollEndDate === 'string') {
          eventEndDate = new Date(mobiscrollEndDate);
        } else if (mobiscrollEndDate instanceof Date) {
          eventEndDate = mobiscrollEndDate;
        } else {
          eventEndDate = new Date(mobiscrollEndDate.toString());
        }

        // Getting event from args
        let mobiScrollEvent = args.event;

        // Extracting Data From MobiScroll Event
        let mobiscrollResouce = mobiScrollEvent.resource;
        let selectedSessionProviderId = mobiscrollResouce.toString();

        // Now pouplate this provider details on the form
        this.meetingForm.controls['physicianId'].setValue(
          selectedSessionProviderId
        );

        const { hour, minute, formattedDate } = getTimeInTimeZone(
          eventStartDate,
          this.selectedCalendarTimeZone
        );

        let startHour = hour;
        let startMinutes = minute;
        let startMinutesString = startMinutes.toString();
        if (startMinutesString.length == 1) {
          startMinutesString = startMinutesString + '0';
        }
        let combinedTimeString = startHour.toString() + startMinutesString;

        // Fill the Provider Details
        this.fillRPDetails();

        // Also populate the start and end time
        this.meetingForm.controls['start'].setValue(mobiscrollStartDate);
        this.meetingForm.controls['end'].setValue(eventEndDate);
        this.meetingForm.controls['sessionStartDate'].setValue(
          formatDate(eventStartDate)
        );
        this.meetingForm.controls['sessionStartTime'].setValue(
          this.timeArrayMap.get(Number(combinedTimeString))
        );
      });
    },
  };

  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    public patientService: PatientService,
    private dashboardsService: DashboardsService,
    private timeOffAPIService: TimeOffAPIService,
    private workinghoursdialogapiservice: WorkingHoursDialogApiService,
    private therapySessionService: TherapySessionService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private orgConfigSandbox: OrgConfigSandbox,
    private facilitiesSandbox: FacilitiesSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private visitReasonsSandBox: VisitReasonsSandbox,
    private toastMessageService: ToastMessageService,
    private keycloakService: KeycloakService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.loggedInUserName = this.coreService.getUserDetails().name;
    this.isPhysician = this.coreService.isPhysician();
  }

  ngOnInit(): void {
    this.editMeetingId = this.route.snapshot.paramMap.get('id');
    // Default time zone based on user login
    this.selectedCalendarTimeZone = moment.tz.guess();
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');

    // Check if this timezone exists or not
    this.checkTimeZoneExists();

    // Setting up initial pagination for membersList
    this.membersList.per_page = 10;
    this.membersList.page = 0;
    this.buildForm();

    // load the visit reasons
    this.loadVisitReasons();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    // Load Permissions
    this.loadMyPermissions();

    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Load all active care providers
    this.loadAllActiveCareProviders();

    // Block The Calendar Upto the current hours
    this.blockCalendarUptoNow();
  }

  // Fill RP Details
  fillRPDetails() {
    let selectedSessionProviderId =
      this.meetingForm.controls['physicianId'].value;

    let selectedRP = this.providersList
      .filter((provider) => provider.id === selectedSessionProviderId)
      .at(0);

    // Determine the facilities box selection for the selected provider
    this.determineSelectedProviderFacilities(selectedRP);

    this.meetingForm.controls['physicianFirstName'].setValue(
      selectedRP.firstName
    );
    this.meetingForm.controls['physicianMiddleName'].setValue(
      selectedRP.middleName
    );
    this.meetingForm.controls['physicianLastName'].setValue(
      selectedRP.lastName
    );
    this.meetingForm.controls['physicianEmail'].setValue(
      selectedRP.emailAddress
    );
    this.meetingForm.controls['physicianPhone'].setValue(
      selectedRP.phoneNumber
    );
  }

  // Check if the time zone exists and if not use the CT
  checkTimeZoneExists() {
    let timeZoneExists = false;
    this.calendarTimeZones.map((zone) => {
      if (zone.id === this.selectedCalendarTimeZone) {
        timeZoneExists = true;
      }
    });
    if (!timeZoneExists) {
      this.selectedCalendarTimeZone = 'America/Chicago';
      this.timeZoneChanged();
    }
  }

  // Handle time zone change to display date
  timeZoneChanged() {
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.consolidatedMemberSearch = this.orgConfig.features.includes(
              'CONSOLIDATED_MEMBER_SEARCH'
            );

            this.showMemberNumber = this.orgConfig.features.includes(
              'MANUAL_MEMBER_NUMBER'
            );

            if (this.consolidatedMemberSearch) {
              this.loadMembersForTheSelectedFacility();
            } else {
              this.meetingForm.controls['billingProviderRefId'].addValidators(
                Validators.required
              );
              this.meetingForm.controls[
                'billingProviderRefId'
              ].updateValueAndValidity();
            }
          }
        });
      }
    });
  }

  // Get My Permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasMemberAddAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_ADD',
        response,
        null
      );
    });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
        this.loadFacilitiesMap();
      }
    });
  }

  loadFacilitiesMap() {
    this.facilities.forEach((facility) => {
      this.facilitiesListMap.set(facility.id, facility);
    });
  }

  //determineSelectedProviderFacilities
  determineSelectedProviderFacilities(providerDetails) {
    let newFacilitiesSelectionList = [];

    providerDetails.facilities.forEach((facilityId) => {
      if (this.facilitiesListMap.get(facilityId)) {
        newFacilitiesSelectionList.push(this.facilitiesListMap.get(facilityId));
      }
    });

    this.selectedProviderFacilities = newFacilitiesSelectionList;
  }

  // Loading all active care providers
  loadAllActiveCareProviders() {
    this.providersListLoading = true;

    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;

          // Load These Providers as Resources for MobiScroll
          this.loadMobiScrollResources();

          // If there is providersList
          this.loadScheduleForAllProviders();
          this.loadTimeOffsForAllProviders();

          if (this.editMeetingId) {
            this.loadMeetingDetails();
          }
        } else {
          this.providersList = [];
          this.filteredProvidersList = [];
        }
        this.providersListLoading = false;
      },
      error: (error) => {
        this.providersListLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  // Loading existing appointment details on the form
  loadMeetingDetails() {
    this.therapySessionService
      .getTherapySessionDetails(this.editMeetingId)
      .subscribe({
        next: (response) => {
          // If there is data
          if (response && response.data) {
            this.meetingInformation = response.data;

            // Now load the form details
            this.meetingForm.controls['id'].setValue(
              this.meetingInformation.id
            );
            this.meetingForm.controls['organizationId'].setValue(
              this.meetingInformation.organizationId
            );
            this.meetingForm.controls['title'].setValue(
              this.meetingInformation.title
            );
            this.meetingForm.controls['visitReasonId'].setValue(
              this.meetingInformation.visitReasonId
            );
            this.meetingForm.controls['visitReasonName'].setValue(
              this.meetingInformation.visitReasonName
            );
            this.meetingForm.controls['start'].setValue(
              this.meetingInformation.start
            );
            this.meetingForm.controls['end'].setValue(
              this.meetingInformation.end
            );
            this.meetingForm.controls['sessionStartDate'].setValue(
              formatDate(new Date(this.meetingInformation.start))
            );
            this.meetingForm.controls['sessionStartTime'].setValue(
              this.meetingInformation.sessionStartTime
            );
            this.meetingForm.controls['sessionDuration'].setValue(
              this.meetingInformation.sessionDuration
            );
            this.meetingForm.controls['sessionCode'].setValue(
              this.meetingInformation.sessionCode
            );
            this.meetingForm.controls['sessionType'].setValue(
              this.meetingInformation.sessionType
            );
            this.meetingForm.controls['sessionFor'].setValue(
              this.meetingInformation.sessionFor
            );
            this.memberType = this.meetingInformation.sessionFor;
            this.memberTypeChanged({ value: this.memberType });

            this.meetingForm.controls['billingProviderRefId'].setValue(
              this.meetingInformation.billingProviderRefId
            );
            // Auto select the member
            if (!this.consolidatedMemberSearch) {
              this.loadMembersForTheSelectedFacility();
            } else {
              this.memberFirstName = this.meetingInformation.patientFirstName;
              this.memberLastName = this.meetingInformation.patientLastName;
              this.loadMembersForTheSelectedFacility();
            }

            // Physician Details
            this.meetingForm.controls['physicianId'].setValue(
              this.meetingInformation.physicianId
            );
            this.meetingForm.controls['physicianFirstName'].setValue(
              this.meetingInformation.physicianFirstName
            );
            this.meetingForm.controls['physicianMiddleName'].setValue(
              this.meetingInformation.physicianMiddleName
            );
            this.meetingForm.controls['physicianLastName'].setValue(
              this.meetingInformation.physicianLastName
            );
            this.meetingForm.controls['physicianEmail'].setValue(
              this.meetingInformation.physicianEmail
            );
            this.meetingForm.controls['physicianPhone'].setValue(
              this.meetingInformation.physicianPhone
            );
            // Trigger Physician selection changed
            let filteredPhysician = this.filteredAllResources.filter(
              (provider) => provider.id === this.meetingInformation.physicianId
            );
            this.filteredResources = [...filteredPhysician];

            // Patient Details
            this.meetingForm.controls['patientId'].setValue(
              this.meetingInformation.patientId
            );
            this.meetingForm.controls['patientFirstName'].setValue(
              this.meetingInformation.patientFirstName
            );
            this.meetingForm.controls['patientMiddleName'].setValue(
              this.meetingInformation.patientMiddleName
            );
            this.meetingForm.controls['patientLastName'].setValue(
              this.meetingInformation.patientLastName
            );
            this.meetingForm.controls['patientEmail'].setValue(
              this.meetingInformation.patientEmail
            );
            this.meetingForm.controls['patientPhone'].setValue(
              this.meetingInformation.patientPhone
            );
            if (this.meetingInformation.patientDOB) {
              this.meetingForm.controls['patientDOB'].setValue(
                new Date(this.meetingInformation.patientDOB)
              );
            }
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load appointment details'
          );
        },
      });
  }

  // Loading Resources for Mobi Scroll
  loadMobiScrollResources() {
    this.allResources = [];

    // Pushing each provider as resource
    this.providersList.forEach((provider) => {
      // Load provider as mobiscroll resource
      this.allResources.push({
        id: provider.id,
        name: provider.lastName + ', ' + provider.firstName,
      });
      // Load Provider to Map
      this.providersListMap.set(provider.id, provider);

      // Just Setting the Time map as null for now
      this.providersScheduleTimeOffMap.set(provider.id, {
        physicianId: provider.id,
        workingHours: null,
      });

      this.filteredResources = [...this.allResources];
      this.filteredAllResources = [...this.allResources];

      // If the list is greater than 3, deselect all providers
      if (this.providersList.length > 3) {
        this.filteredResources = [];
      }

      // If provider select the logged in provider, auto select them
      if (this.isPhysician) {
        let filteredPhysician = this.filteredAllResources.filter(
          (provider) => provider.id === this.loggedInUserId
        );
        if (filteredPhysician && filteredPhysician.length == 1) {
          if (this.filteredResources.indexOf(filteredPhysician.at(0)) < 0) {
            this.filteredResources = [
              ...this.filteredResources,
              ...filteredPhysician,
            ];
          }
        }
      }
    });
  }

  // Load Schedule for all Providers
  loadScheduleForAllProviders() {
    // Obserable to handle multiple http call
    const allproviderScheduleTimeOffObservable: Observable<any>[] = [];

    // Get Schedule and Time Offs for all proviers
    allproviderScheduleTimeOffObservable.push(
      this.getAllProvidersWorkingHoursAndTimeOffs().pipe(
        map((responseList) => {
          // For Providers Schedule
          if (responseList[0]?.items) {
            // Now loop through each schedule and set the provider time
            responseList[0].items.forEach((providerSchedule) => {
              // First check if this provider is in schedule map
              if (
                this.providersScheduleTimeOffMap.get(
                  providerSchedule.careProviderId
                )
              ) {
                // Now as we have this provider update their set
                let currentTimeOffMapValue =
                  this.providersScheduleTimeOffMap.get(
                    providerSchedule.careProviderId
                  );
                currentTimeOffMapValue.workingHours = providerSchedule;

                this.providersScheduleTimeOffMap.set(
                  providerSchedule.careProviderId,
                  currentTimeOffMapValue
                );
              }
            });
          }

          // For Providers Time Offs
          return responseList;
        }),
        catchError((error) => {
          // If there is an error, display the error in toast message
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load working hours and time offs'
          );
          return of(null);
        })
      )
    );

    // Once all the providers time off and schedule has been loaded
    forkJoin(allproviderScheduleTimeOffObservable).subscribe(() => {
      // Now that the time Off and Working hours have been populated
      this.loadResourceSchedule();
    });
  }

  // Load Resource Schedules
  loadResourceSchedule() {
    // As of this point, the provider's schedule and time offs have been loaded
    // For Each Provider, we now need to load their working hours and block any time offs
    this.providersScheduleTimeOffMap.forEach((providerMap) => {
      // Now Load Each Provider Schedule
      this.loadResourceWorkingHours(
        providerMap.physicianId,
        providerMap.workingHours
      );
    });

    // Now that all the resource working hours have been populated, determining the calendar start and end time
    this.determineCalendarStartEndTime();
  }

  // Determining calendar start and end time
  determineCalendarStartEndTime() {
    // Handle the time
    if (this.allWorkingHoursArray.length > 0) {
      // For check if there are numbers greater than 2400
      this.allWorkingHoursArray = this.allWorkingHoursArray.map((num) =>
        num >= 2400 ? 0 : num
      );

      // Now that we have converted the all working hours array
      let lowStart = Math.min(...this.allWorkingHoursArray);
      let highEnd = Math.max(...this.allWorkingHoursArray);

      // Now convert them into appropriate format
      let startTimeHour = Math.trunc(lowStart / 100);
      let startTimeHourString = startTimeHour.toString();

      let endTimeHour = Math.trunc(highEnd / 100);
      //endTimeHour = endTimeHour + 1;
      let endTimeHourString = endTimeHour.toString();

      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }

      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }

      // Now append this as the calendar start and end time range
      this.calendarStartHour = startTimeHourString + ':00';
      this.calendarEndHour = endTimeHourString + ':00';

      // Once the star and end Time hour has been determined
      this.responsiveMobiScrollCalendarView = {
        // Min-width:0px
        xsmall: {
          view: {
            schedule: {
              type: 'day',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
        // min-width: 768px
        medium: {
          view: {
            schedule: {
              type: 'week',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
      };
    }

    // Navigate to current time
    this.mbscCalendar.navigate(new Date(), true);
  }

  // Load Calendar Working Hours For Each Provider/Resource
  loadResourceWorkingHours(resourceId, workingHoursData) {
    // Making Sure that the working hours Data exists for this provider
    if (workingHoursData && workingHoursData?.id) {
      // Loop Through each day to find the provider's working hours
      this.determineWorkingHourForDay(
        resourceId,
        'SU',
        workingHoursData?.sunday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'MO',
        workingHoursData?.monday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TU',
        workingHoursData?.tuesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'WE',
        workingHoursData?.wednesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TH',
        workingHoursData?.thursday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'FR',
        workingHoursData?.friday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'SA',
        workingHoursData?.saturday
      );
    } else {
      // If The Working Hour of the provider is not set by default use 9-5
      this.handleDefaultWorkingHoursForResource(resourceId);
    }
  }

  // Load Time Off for all Providers
  loadTimeOffsForAllProviders() {
    this.timeOffAPIService.getAllMyProviderTimeOffsForSession().subscribe({
      next: (response) => {
        if (response && response?.items) {
          // For each time off block the resource calendar
          response.items.forEach((timeOff) => {
            // Start Date For Invalid in Mobi Scroll
            let startDate = new Date(timeOff.startDay);
            // End Date For Invalid in Mobi Scroll
            let endDate = new Date(timeOff.endDay);

            // Set End Date to be the start of the next day of end date
            // endDate.setDate(endDate.getDate() + 1);

            // Invalid Date Structure as per Mobi Scroll
            let invalidDateInCalendar = {
              start: startDate,
              end: endDate,
              title: timeOff?.reason,
              resource: timeOff.careProviderId,
              cssClass: 'md-rect-bg',
            };

            // Now update existing invalid and add this invalid date aswell
            this.invalids = [...this.invalids, invalidDateInCalendar];
            this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
          });
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load time offs for providers'
        );
      },
    });
  }

  // Handle the working hours for each day for each resource
  determineWorkingHourForDay(resourceId, day, data) {
    // If this day is an off day, block the day for this resource
    if (data.offday) {
      this.blockAllDayForResource(resourceId, day);
    } else {
      // If this is not a off day, now determine their working hours
      if (data?.workingHours) {
        // Now as there is working hours defined
        if (data.workingHours?.length == 1) {
          this.addSingleStartEndTime(
            resourceId,
            day,
            data.workingHours[0].startTime,
            data.workingHours[0].endTime
          );
        } else if (data.workingHours?.length > 1) {
          this.addMultipleStartEndTime(resourceId, day, data.workingHours);
        }
      }
    }
  }

  // Setting Default working hours to be 9-5 for the passed in resource
  handleDefaultWorkingHoursForResource(resourceId) {
    // Block Saturday and Sundays First
    this.blockAllDayForResource(resourceId, 'SA');
    this.blockAllDayForResource(resourceId, 'SU');

    // For Monday To Friday set 9-5
    this.addSingleStartEndTime(resourceId, 'MO', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TU', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'WE', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TH', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'FR', 900, 1700);
  }

  // Block Calendar Time For Single start and end time passed
  addSingleStartEndTime(
    resourceId: string,
    day: string,
    startTime: number,
    endTime: number
  ) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(startTime, endTime);

    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    let startTimeHour = Math.trunc(startTime / 100);
    let startTimeHourString = startTimeHour.toString();
    let startTimeMinutes = startTime % 100;
    let startTimeMinutesString = startTimeMinutes.toString();

    let endTimeHour = Math.trunc(endTime / 100);
    let endTimeHourString = endTimeHour.toString();
    let endTimeMinutes = endTime % 100;
    let endTimeMinutesString = endTimeMinutes.toString();

    // Handling for 24
    if (startTimeHour == 24) {
      startTimeHourString = '00';
    }
    if (endTimeHour == 24) {
      endTimeHourString = '00';
    }

    // Converting to Correct Format
    if (startTimeHourString.length === 1) {
      startTimeHourString = '0' + startTimeHourString;
    }
    if (endTimeHourString.length === 1) {
      endTimeHourString = '0' + endTimeHourString;
    }
    if (startTimeMinutesString.length === 1) {
      startTimeMinutesString = startTimeMinutesString + '0';
    }
    if (endTimeMinutesString.length === 1) {
      endTimeMinutesString = endTimeMinutesString + '0';
    }

    // Now block the time for this resource
    this.invalids = [
      ...this.invalids,
      {
        start: dayStartTime,
        end: startTimeHourString + ':' + startTimeMinutesString,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
      {
        start: endTimeHourString + ':' + endTimeMinutesString,
        end: dayEndTime,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block Calendar Time For Multiple start and end time passed
  addMultipleStartEndTime(resourceId: string, day: string, workingHours) {
    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    workingHours.forEach((workHour, index) => {
      let firstIndex = index == 0;
      let lastIndex = index == workingHours.length - 1;

      // Push this startTime & EndTime to the hours array
      this.allWorkingHoursArray.push(workHour.startTime, workHour.endTime);

      let startTimeHour = Math.trunc(workHour.startTime / 100);
      let startTimeHourString = startTimeHour.toString();
      let startTimeMinutes = workHour.startTime % 100;
      let startTimeMinutesString = startTimeMinutes.toString();

      let endTimeHour = Math.trunc(workHour.endTime / 100);
      let endTimeHourString = endTimeHour.toString();
      let endTimeMinutes = workHour.endTime % 100;
      let endTimeMinutesString = endTimeMinutes.toString();

      if (startTimeHour == 24) {
        startTimeHourString = '00';
      }
      if (endTimeHour == 24) {
        endTimeHourString = '00';
      }

      // Converting to Correct Format
      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }
      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }
      if (startTimeMinutesString.length === 1) {
        startTimeMinutesString = '0' + startTimeMinutesString;
      }
      if (endTimeMinutesString.length === 1) {
        endTimeMinutesString = '0' + endTimeMinutesString;
      }

      if (firstIndex) {
        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }
        // Blocking from start of day to start of time and end of time to next index start
        this.invalids = [
          ...this.invalids,
          {
            start: dayStartTime,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else if (lastIndex) {
        // For last Index just block from last index end time to the end of the day
        this.invalids = [
          ...this.invalids,
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end: dayEndTime,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else {
        // For multiple start and end times within the start and end index
        // Get the Next Index Start Time
        let previousIndexEndTimeHour = Math.trunc(
          workingHours[index - 1].endTime / 100
        );
        let previousIndexEndTimeHourString =
          previousIndexEndTimeHour.toString();
        let previousIndexEndTimeMinutes = workingHours[index - 1].endTime % 100;
        let previousIndexEndTimeMinutesString =
          previousIndexEndTimeMinutes.toString();
        // Converting to the right format
        if (previousIndexEndTimeHourString.length === 1) {
          previousIndexEndTimeHourString = '0' + previousIndexEndTimeHourString;
        }
        if (previousIndexEndTimeMinutesString.length === 1) {
          previousIndexEndTimeMinutesString =
            '0' + previousIndexEndTimeMinutesString;
        }

        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[index + 1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[index + 1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }

        // Blocking from previos end to current start and current end to next start
        this.invalids = [
          ...this.invalids,
          {
            start:
              previousIndexEndTimeHourString +
              ':' +
              previousIndexEndTimeMinutesString,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      }
    });
  }

  public getAllProvidersWorkingHoursAndTimeOffs(): Observable<any[]> {
    let allWorkingHours =
      this.workinghoursdialogapiservice.getAllMyProvidersSchedules();

    return forkJoin([allWorkingHours]);
  }

  // Block all day for this resource
  blockAllDayForResource(resourceId, day) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(800, 1700);

    // Blocking all Day for the passed in day for the Resource
    this.invalids = [
      ...this.invalids,
      {
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block calendar upto now
  blockCalendarUptoNow() {
    this.invalids = [
      ...this.invalids,
      // Giving extra cushion to schedule appointments for the past 24 hours
      {
        recurring: {
          repeat: 'daily',
          until: this.yesterday,
        },
      },
      //Giving extra cushion to schedule appointments for the past 24 hours
      {
        start: this.yesterday,
        end: this.currentTime,
      },
    ];
  }

  // Get Visit Reasons From the SandBox
  loadVisitReasons() {
    this.visitReasonsSandBox.visitReasonsLoading$.subscribe((response) => {
      // If the visit Reasons Have Loaded Successfully
      if (!response) {
        // Now the load has been complete, get the visit reasons
        this.visitReasonsSandBox.visitReasons$.subscribe((visitReasons) => {
          this.visitReasons = visitReasons;
          this.visitReasonsColorMap = new Map<string, string>();

          // Set these visit reasons in color map
          visitReasons.forEach((visitReason) => {
            this.visitReasonsColorMap.set(visitReason.id, visitReason.color);
            this.visitReasonsListMap.set(visitReason.id, visitReason);
          });
        });

        // Now Load All Sessions
        this.getAllSession();
      }
    });
  }

  // Get all sessions
  getAllSession() {
    this.dashboardsService.getSessionsForNonProvider().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.existingSession = response.items;
          this.loadExistingEvents();
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Unable to retrieve existing appointments'
        );
      },
    });
  }

  // Loading Mobiscroll resources
  loadExistingEvents() {
    if (this.existingSession) {
      let mobiScrollEvents = [];

      // Build the events
      this.existingSession.forEach((existingSession) => {
        let newEvent = <MbscCalendarEvent>{
          id: existingSession.id,
          resource: existingSession.physicianId,
          start: new Date(existingSession.start),
          end: new Date(existingSession.end),
          title: existingSession.title,

          color:
            existingSession.status === 'Canceled' ||
            this.editMeetingId != existingSession.id
              ? 'darkgray'
              : this.editMeetingId === existingSession.id
              ? '#2196f3'
              : this.visitReasonsColorMap.get(existingSession.visitReasonId),
          editable: this.editMeetingId === existingSession.id,

          status: existingSession.status,
          patientId: existingSession.patientId,
          organizationId: existingSession.organizationId,
          facilityId: existingSession.billingProviderRefId,
          facilityName: existingSession.billingProviderName,
          startTime: existingSession.sessionStartTime,
          duration: existingSession.sessionDuration,
          sessionCode: existingSession.sessionCode,
          sessionType: existingSession.sessionType,
          sessionFor: existingSession.sessionFor,
          groupAppointment: existingSession.groupAppointment,
        };

        mobiScrollEvents.push(newEvent);
      });

      // Finally append these events with existing events
      this.myEvents = [...this.myEvents, ...mobiScrollEvents];
    }
  }

  // Time Cell Changed
  timeCellStepChanged() {
    this.timeLabelStep = this.timeCellStep;

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
    };
  }

  // Customize which days to display on Mobiscroll calendar
  sliderValueChanged() {
    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
    };
  }

  // For Days Range Format Label
  formatDaySliderLabel(value: number): string {
    switch (value) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }

  // Calendar Type Changed
  calendarTypeChanged(event) {
    this.calendarViewType = event.value;

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: event.value,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
    };
  }

  // Search By provider Name Changed
  searchByProviderNameChanged() {
    if (this.searchProviderName) {
      this.filteredAllResources = this.allResources.filter((resource) => {
        const nameParts = resource.name.split(', ');
        const lastName = nameParts[0].toLowerCase();
        const firstName = nameParts[1].toLowerCase();

        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;

        if (
          concatFirstLast.includes(this.searchProviderName.toLowerCase()) ||
          concatLastFirst.includes(this.searchProviderName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredAllResources = [...this.allResources];
    }
  }

  // Quick Actions To Select/Deselect all providers
  selectAllProviders() {
    this.filteredResources = [...this.allResources];
  }

  deselectAllProviders() {
    this.filteredResources = [];
  }

  // Show or hide resource
  selectDeselctResource(event, provider) {
    // Event checked => true meaning add the provider back
    if (event.checked) {
      // If the filtered list does not include the provider then add the provider to the resource to display
      if (!this.filteredResources.includes(provider)) {
        this.filteredResources = [...this.filteredResources, provider];
      }
    } else {
      // If the filtered list does include the provider then remove the provider to the resource to display
      if (this.filteredResources.includes(provider)) {
        this.filteredResources = this.filteredResources.filter(
          (resource) => resource != provider
        );
      }
    }
  }

  // This is for resource checkbox
  isResourceVisible(provider) {
    return this.filteredResources.includes(provider);
  }

  public buildForm() {
    this.meetingForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),

      // Session Information
      title: new FormControl(''),
      visitReasonId: new FormControl('', Validators.required),
      visitReasonName: new FormControl(''),

      // Appointment Patients
      start: new FormControl(''),
      end: new FormControl(''),
      sessionStartDate: new FormControl(''),
      sessionStartTime: new FormControl(''),
      sessionDuration: new FormControl(30, Validators.required),
      sessionType: new FormControl('Telehealth-Only', Validators.required),
      sessionFor: new FormControl('Existing Member', Validators.required),
      sessionCode: new FormControl(''),

      // Service Facility
      billingProviderRefId: new FormControl(null),

      // Appointment with
      physicianId: new FormControl('', Validators.required),
      physicianFirstName: new FormControl('', Validators.required),
      physicianMiddleName: new FormControl(''),
      physicianLastName: new FormControl(''),
      physicianEmail: new FormControl(''),
      physicianPhone: new FormControl(''),

      // Appointment Patient
      patientId: new FormControl(null, Validators.required),
      patientFirstName: new FormControl(''),
      patientMiddleName: new FormControl(''),
      patientLastName: new FormControl(''),
      patientEmail: new FormControl('', Validators.compose([Validators.email])),
      patientPhone: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      patientDOB: new FormControl(''),

      // Status
      sessionLevelNote: new FormControl(''),
      status: new FormControl('Active', Validators.required),
    });

    // WhenEver the session duration changes
    this.meetingForm.controls['sessionDuration'].valueChanges.subscribe(() => {
      this.sessionDurationChanged();
    });
  }

  // Search Member Changed
  searchByNameChanged() {
    this.membersList.page = 0;

    this.loadMembersForTheSelectedFacility();
  }

  getNext(event: PageEvent) {
    this.membersList.page = event.pageIndex;
    this.loadMembersForTheSelectedFacility();
  }

  // New Service Location selected
  serviceFacilityChanged(event) {
    // Now Load Members
    this.loadMembersForTheSelectedFacility();
  }

  // Add New Member if the user has Add Member Permission
  addMember() {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: {
        action: 'ADD',
        patient: null,
        facilityId: this.meetingForm.controls['billingProviderRefId'].value,
        showMemberNumber: this.showMemberNumber,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMembersForTheSelectedFacility();
      }
    });
  }

  // Loading Existing Member for this facility
  loadMembersForTheSelectedFacility() {
    this.isMembersLoading = true;

    this.patientService
      .getPatients(
        this.consolidatedMemberSearch
          ? null
          : this.meetingForm.controls['billingProviderRefId'].value,
        null,
        this.memberFirstName,
        this.memberLastName,
        this.memberPhone,
        'Active',
        this.membersList.per_page,
        this.membersList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.membersList.items = response.items;
            this.membersList.total = response.total;
          } else {
            this.membersList.items = [];
            this.membersList.total = 0;
          }
          this.isMembersLoading = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load members at the selected facility'
          );
          this.isMembersLoading = false;
        },
      });
  }

  // Select the member
  selectMember(member: Patient) {
    // Fill the member details
    this.meetingForm.controls['patientId'].setValue(member.id);
    this.meetingForm.controls['patientFirstName'].setValue(member.firstName);
    this.meetingForm.controls['patientMiddleName'].setValue(member.middleName);
    this.meetingForm.controls['patientLastName'].setValue(member.lastName);
    this.meetingForm.controls['patientEmail'].setValue(member.email);
    this.meetingForm.controls['patientDOB'].setValue(member.dateOfBirth);

    // Now move to the next screen
    this.stepper.next();
  }

  // Appointment user changed
  appointmentProviderSelected() {
    let selectedProviderId = this.meetingForm.controls['physicianId'].value;

    // If empty reset
    if (selectedProviderId === '') {
      this.meetingForm.controls['providerDetails'].setValue(null);
    } else {
      // Else find and attach the provider
      let selectedReferringProvider = this.providersList
        .filter((provider) => provider.id === selectedProviderId)
        .at(0);

      this.meetingForm.controls['providerDetails'].setValue(
        selectedReferringProvider
      );
    }
  }

  // Filter case managers
  filterAllActiveCaseManagers() {
    if (this.userName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast.toLowerCase().includes(this.userName.toLowerCase()) ||
          concatLastFirst.toLowerCase().includes(this.userName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }

  visitReasonChanged() {
    let newVisitReasonId = this.meetingForm.controls['visitReasonId'].value;

    let newVisitReasonDetails = this.visitReasonsListMap.get(newVisitReasonId);

    // Now Set the session title
    let newTitle = `${this.meetingForm.controls['patientLastName'].value}, ${this.meetingForm.controls['patientFirstName'].value} - ${newVisitReasonDetails.reasonName}`;

    // Set form control
    this.meetingForm.controls['title'].setValue(newTitle);
    this.meetingForm.controls['visitReasonName'].setValue(
      newVisitReasonDetails?.reasonName
    );
    this.meetingForm.controls['sessionDuration'].setValue(
      newVisitReasonDetails?.duration
    );

    // Now Set the appropriate End Time
    this.setNewEndTime();
  }

  //  Session Duration Changed
  sessionDurationChanged() {
    if (this.meetingForm.controls['start'].value) {
      this.setNewEndTime();
    }
  }

  // Set the new end Time
  setNewEndTime() {
    let newEndTime = addMinutes(
      this.meetingForm.controls['start'].value,
      this.meetingForm.controls['sessionDuration'].value
    );
    this.meetingForm.controls['end'].setValue(newEndTime);

    // Now update the mobiscroll event
    this.myEvents = this.myEvents.map((event) => {
      if (event.id == null) {
        // Update this event
        event.title = this.meetingForm.controls['title'].value;
        event.end = newEndTime;
        event.duration = this.meetingForm.controls['sessionDuration'].value;
      }
      return event;
    });
  }

  // Finally submit the form
  submitForm() {
    if (this.meetingForm.invalid) {
      return;
    }

    this.meetingInformation = Object.assign({}, this.meetingInformation);
    this.meetingInformation = Object.assign(
      this.meetingInformation,
      this.meetingForm.value
    );

    // Format Patient DOB
    if (
      this.meetingInformation.patientDOB &&
      this.meetingInformation.patientDOB !== ''
    ) {
      this.meetingInformation.patientDOB = formatDate(
        new Date(this.meetingInformation.patientDOB)
      );
    }

    this.processing = true;

    if (this.editMeetingId) {
      this.therapySessionService
        .updateNewTelehealthMeeting(this.meetingInformation)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully updated the telehealth meeting'
            );
            this.processing = false;

            this.router.navigate(['/']);
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the telehealth meeting'
            );
            this.processing = false;
          },
        });
    } else {
      this.therapySessionService
        .createNewTelehealthMeeting(this.meetingInformation)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully created the telehealth meeting'
            );
            this.processing = false;

            this.router.navigate(['/']);
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to create the telehealth meeting'
            );
            this.processing = false;
          },
        });
    }
  }

  memberTypeChanged(event) {
    this.meetingForm.controls['sessionFor'].setValue(event.value);
    if (event.value === 'Guest Member') {
      this.meetingForm.get('patientId').clearValidators();
      this.meetingForm.get('patientId').updateValueAndValidity();

      this.meetingForm.get('billingProviderRefId').clearValidators();
      this.meetingForm.get('billingProviderRefId').updateValueAndValidity();

      this.meetingForm.get('patientPhone').addValidators(Validators.required);
      this.meetingForm.get('patientPhone').updateValueAndValidity();

      this.meetingForm.get('patientEmail').addValidators(Validators.required);
      this.meetingForm.get('patientEmail').updateValueAndValidity();
    } else {
      this.meetingForm.get('patientPhone').clearValidators();
      this.meetingForm.get('patientPhone').updateValueAndValidity();

      this.meetingForm.get('patientEmail').clearValidators();
      this.meetingForm.get('patientEmail').updateValueAndValidity();

      this.meetingForm.get('patientId').addValidators(Validators.required);
      this.meetingForm.get('patientId').updateValueAndValidity();

      if (!this.consolidatedMemberSearch) {
        this.meetingForm.controls['billingProviderRefId'].addValidators(
          Validators.required
        );
        this.meetingForm.controls[
          'billingProviderRefId'
        ].updateValueAndValidity();
      }
    }
  }
}
