<app-intake-header
  sectionTitle="Member Details"
  [therapySession]="therapySession"
  [patient]="patient"
  showPrintButton="true"
></app-intake-header>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form [formGroup]="intakeMemberDataForm" (ngSubmit)="onPatientFormSubmit()">
    <div class="row">
      <!-- General Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">person</mat-icon> General
          Information
        </label>

        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  name="firstName"
                  minlength="1"
                  required
                />
                <mat-error
                  *ngIf="intakeMemberDataForm.controls['firstName'].invalid"
                >
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Middle Name"
                  formControlName="middleName"
                  name="middleName"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                  minlength="1"
                  required
                />
                <mat-error
                  *ngIf="intakeMemberDataForm.controls['lastName'].invalid"
                >
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Date of Birth <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  formControlName="dateOfBirth"
                  matInput
                  [matDatepicker]="dobDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  required
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error
                  *ngIf="intakeMemberDataForm.controls['dateOfBirth'].invalid"
                >
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Gender <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="gender"
                  placeholder="Gender"
                  required
                  (selectionChange)="genderChanged($event)"
                >
                  <mat-option [value]="r.id" *ngFor="let r of genderList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="intakeMemberDataForm.controls['gender'].invalid"
                >
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Pronouns</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="pronouns" name="pronouns" />
              </mat-form-field>
            </div>

            <!-- SSN -->
            <div class="col-md-4">
              <label class="labelText"> Social Security Number (SSN) </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="XXX-XX-XXXX"
                  formControlName="ssn"
                  name="ssn"
                  [type]="showSSN ? 'text' : 'password'"
                />
                <mat-icon matSuffix (click)="showSSN = !showSSN">
                  {{ showSSN ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="intakeMemberDataForm.controls['ssn'].invalid">
                  Please enter a valid SSN
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <label class="labelText"> Height </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="height"
                  name="height"
                  type="number"
                  min="0"
                />
                <span matSuffix> in </span>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <label class="labelText"> Weight </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="weight"
                  name="weight"
                  type="number"
                  min="0"
                />
                <span matSuffix> Lb </span>
              </mat-form-field>
            </div>

            <!-- Gender at birth -->
            <div
              class="col-md-4"
              *ngIf="intakeMemberDataForm.controls['gender'].value === 'O'"
            >
              <label class="labelText">
                Gender at Birth<span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="genderAtBirth"
                  placeholder="Gender at Birth"
                >
                  <mat-option value="M"> Male </mat-option>
                  <mat-option value="F"> Female </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="intakeMemberDataForm.controls['genderAtBirth'].invalid"
                >
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText"> Admittance Date </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="admittanceDate"
                  matInput
                  [matDatepicker]="admittanceDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="admittanceDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #admittanceDatePicker></mat-datepicker>

                <mat-error
                  *ngIf="
                    intakeMemberDataForm.controls['admittanceDate'].invalid
                  "
                >
                  Please enter a valid Admittance Date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">contact_phone</mat-icon> Contact
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">Email</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  name="email"
                />
                <mat-error
                  *ngIf="intakeMemberDataForm.controls['email'].invalid"
                >
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Phone Preference <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="phonePreference"
                  placeholder="Phone Prefence"
                  (selectionChange)="phonePreferenceChanged($event)"
                >
                  <mat-option value="Cell Phone">Cell Phone</mat-option>
                  <mat-option value="Home Phone">Home Phone</mat-option>
                  <mat-option value="Work Phone">Work Phone</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    intakeMemberDataForm.controls['phonePreference'].invalid
                  "
                >
                  Please select the phone preference
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Cell Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="phoneNumber"
                  name="phoneNumber"
                />
                <mat-error
                  *ngIf="intakeMemberDataForm.controls['phoneNumber'].invalid"
                >
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Home Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    intakeMemberDataForm.controls['phonePreference'].value ===
                    'Home Phone'
                  "
                  >*</span
                >
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="homePhoneNumber"
                  name="homePhoneNumber"
                />
                <mat-error
                  *ngIf="
                    intakeMemberDataForm.controls['homePhoneNumber'].invalid
                  "
                >
                  Please enter a valid home number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Work Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    intakeMemberDataForm.controls['phonePreference'].value ===
                    'Work Phone'
                  "
                  >*</span
                >
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="workPhoneNumber"
                  name="workPhoneNumber"
                />
                <mat-error
                  *ngIf="
                    intakeMemberDataForm.controls['workPhoneNumber'].invalid
                  "
                >
                  Please enter a valid work number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home</mat-icon>
          Address
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">
                Address 1 <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  placeholder="Address Line1"
                  formControlName="addressLine1"
                  name="addressLine1"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Address 2 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="addressLine2"
                  name="addressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                City <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="City"
                  formControlName="city"
                  name="city"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                State <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select formControlName="state" placeholder="State">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                  <mat-error
                    *ngIf="intakeMemberDataForm.controls['state'].invalid"
                  >
                    Please select the state
                  </mat-error>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Zip <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Zip"
                  formControlName="zip"
                  name="zip"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="
          therapySession.status === 'Active' &&
          (hasBPSAIntakeAddAccess || hasBPSAIntakeEditAccess)
        "
      >
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!intakeMemberDataForm.valid || processing"
        >
          Update
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
