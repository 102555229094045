<div class="container">
    <div class="dialogTitle" style="margin-bottom: 25px">
        <p matDialogTitle>Lab Order</p>

        <button mat-mini-fab style="background-color: transparent; color: #555555" aria-label="close dialog"
            mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <mat-horizontal-stepper [linear]="true" #stepper>
            <!-- Step 2, Select Zip Code -->
            <mat-step [label]="' '" [stepControl]="stepOneForm">
                <form [formGroup]="stepOneForm">
                    <ng-template matStepLabel></ng-template>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="labelText">
                                Zip Code <span style="color: red">*</span>
                            </label>
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="zip" name="zip" placeholder="Zip" />
                                <mat-error *ngIf="stepOneForm.controls['zip'].invalid">
                                    Please enter a valid zip code
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-12" style="text-align: right">
                        <button color="primary" mat-raised-button matStepperNext type="submit"
                            [disabled]="stepOneForm.invalid" (click)="fetchLabs()">
                            Find Labs
                        </button>
                    </div>
                </form>
            </mat-step>

            <!-- Step 2, Select Lab -->
            <mat-step [label]="' '" [stepControl]="stepTwoForm">
                <ng-template matStepLabel></ng-template>
                <div *ngIf="isLoadingStep2">Loading labs...</div>
                <div *ngIf="!isLoadingStep2" class="service-center-grid">
                    <h5>Select Lab</h5>
                    <form [formGroup]="stepTwoForm">
                        <ng-container *ngIf="isLoadingStep2">
                            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                            <p>Loading...</p>
                        </ng-container>
                        <div class="row">
                            <div class="col-md-12">
                                <div *ngIf="!isLoadingStep2" class="lab-grid">
                                    <mat-card *ngFor="let lab of labs" class="mat-elevation-z8 pad20"
                                        [class.selected]="stepTwoForm.get('selectedLab')?.value === lab.id">
                                        <mat-card-content>
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <h4>{{ lab.name }}</h4>
                                                </div>
                                                <div class="col-md-3" style="text-align: right">
                                                    <button mat-raised-button color="primary" type="submit"
                                                        (click)="selectLab(lab.id, lab.slug)">
                                                        Select
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-step>

            <!-- Step 3, Select Location -->
            <mat-step [label]="' '">
                <ng-template matStepLabel></ng-template>
                <div *ngIf="isLoadingStep3">Loading service centers...</div>
                <div *ngIf="!isLoadingStep3" class="service-center-grid">
                    <h5>Select Location</h5>
                    <mat-card *ngFor="let center of serviceCenters" class="mat-elevation-z8 pad20">
                        <mat-card-header>
                            <mat-card-title>{{ center.metadata.name }}</mat-card-title>
                            <mat-card-subtitle>
                                {{ center.metadata.first_line }},
                                {{ center.metadata.second_line }},
                                {{ center.metadata.city }}, {{ center.metadata.state }} {{ center.metadata.zip_code }}
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <p><strong>Distance:</strong> {{ center.distance }} miles</p>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="row">
                                <div class="col-md-12 pad20" style="text-align: right">
                                    <button mat-raised-button color="primary" matStepperNext
                                        (click)="navigateToMarkersScreen()"
                                        *ngIf="!stepThreeForm.controls['appointmentWithVital'].value">
                                        Select
                                    </button>
                                </div>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </mat-step>

            <!-- Step 4, Select Markers -->
            <mat-step [label]="' '">
                <ng-template matStepLabel></ng-template>
                <div *ngIf="isLoadingMarkers">Loading available markers...</div>
                <div *ngIf="!isLoadingMarkers" class="service-center-grid">
                    <h5>Select Markers</h5>
                    <form [formGroup]="markerForm">
                        <div>
                            <mat-form-field>
                                <input matInput (keyup)="applyMarkerFilter($event)" placeholder="Search markers">
                            </mat-form-field>

                            <table mat-table [dataSource]="filteredMarkers" matSort class="mat-elevation-z8">
                                <!-- Select Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="masterMarkerToggle()"
                                            [checked]="isAllMarkersSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="toggleMarkerSelection(row)"
                                            [checked]="markerSelection.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- Marker Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
                                </ng-container>

                                <!-- Price Column -->
                                <ng-container matColumnDef="price">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.price }} </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Expected Results </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngFor="let expectedResult of element.expected_results">
                                            {{ expectedResult.name }}
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedMarkerColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedMarkerColumns;"></tr>
                            </table>
                        </div>

                        <div class="col-md-12 pad20" style="text-align: right">
                            <button mat-raised-button color="primary" matStepperNext type="submit"
                                [disabled]="markerForm.invalid" (click)="fetchTimeSlots()">
                                Next
                            </button>
                        </div>
                    </form>
                </div>
            </mat-step>


            <!-- Step 5, Select Time Slot-->
            <mat-step [label]="' '">
                <ng-template matStepLabel></ng-template>
                <div *ngIf="isLoadingStep4">Loading available Timeslots...</div>
                <div *ngIf="!isLoadingStep4" class="service-center-grid">
                    <h5>Pick a Slot</h5>
                    <div *ngFor="let slot of slots">
                        <mat-card class="mat-elevation-z8 pad20">
                            <mat-card-header>
                                <mat-card-title>{{ slot.location.name }}</mat-card-title>
                                <mat-card-subtitle>
                                    {{ slot.location.address.first_line }}, {{ slot.location.address.second_line }},
                                    {{ slot.location.address.city }}, {{ slot.location.address.state }} - {{
                                    slot.location.address.zip_code }}
                                </mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-md-2" *ngFor="let timeSlot of slot.slots">
                                        <mat-card class="mat-elevation-z8 pad20">
                                            <mat-card-content>
                                                <p>{{ timeSlot.start | date : 'M/d/y, h:mm a' : currentTimezone }}</p>
                                            </mat-card-content>
                                            <mat-card-actions>
                                                <button mat-raised-button color="primary"
                                                    (click)="setAppointmentTime(timeSlot, slot.location)">Select</button>
                                            </mat-card-actions>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                </div>
            </mat-step>


            <!-- Step 6, Confirm and Order-->
            <mat-step [label]="' '">
                <ng-template matStepLabel></ng-template>
                <h5>Confirm & Order</h5>
                <div class="card-container" *ngIf="patient">

                    <!-- Lab Information Card -->
                    <mat-card class="mat-elevation-z8 pad20">
                        <mat-card-header>
                            <mat-card-title>Lab Information</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p><strong>Lab Name:</strong> {{ selectedLab.name }}</p>
                            <p><strong>Address:</strong> {{ selectedLab.address }}</p>
                        </mat-card-content>
                    </mat-card>

                    <!-- Appointment Slot Card -->
                    <mat-card class="mat-elevation-z8 pad20">
                        <mat-card-header>
                            <mat-card-title>Appointment Slot</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p><strong>Start Time:</strong> {{ selectedAppointmentSlot.start | date : 'M/d/y, h:mm a' :
                                currentTimezone }}</p>
                            <p><strong>End Time:</strong> {{ selectedAppointmentSlot.end | date : 'M/d/y, h:mm a' :
                                currentTimezone }}</p>
                        </mat-card-content>
                    </mat-card>
                    <!-- Patient Information Card -->
                    <mat-card class="mat-elevation-z8 pad20">
                        <mat-card-header>
                            <mat-card-title>Patient Information</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p><strong>First Name:</strong> {{ patient.firstName }}</p>
                            <p><strong>Last Name:</strong> {{ patient.lastName }}</p>
                            <p><strong>Date of Birth:</strong> {{ patient.dateOfBirth }}</p>
                            <p><strong>Gender:</strong> {{ patient.gender }}</p>
                            <p><strong>Phone:</strong> {{ patient.phoneNumber }}</p>
                            <p><strong>Email:</strong> {{ patient.email }}</p>
                            <p><strong>Address:</strong> {{ patient.addressLine1 }}{{ patient.addressLine2 ?
                                ', '+patient.addressLine2:'' }}, {{
                                patient.city
                                }},
                                {{ patient.state }} {{ patient.zip }}</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-12 pad20" style="text-align: right">
                    <button color="primary" mat-raised-button type="submit" (click)="onSubmit()"
                        [disabled]="savingLabOrder">Place Lab Order
                        <i class="fa fa-spinner fa-spin" *ngIf="savingLabOrder"></i>
                    </button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-dialog-content>

</div>