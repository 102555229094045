import { IAbcForm } from '../patient/abcform.model';
import { LaneStatus } from './issue-status';

export class CareLogLane {
  id: LaneStatus;
  title: string;
  notes: CareLog[];
}

export interface CareLog {
  id?: string;
  caseId?: string;
  patientId?: string;
  organizationId: string;
  message: string;
  customMessage: string;
  additionalInput?: string;
  logNumber?: string;
  shift?: number;
  goal?: string;
  goals: string[];
  memberGoals: string[];
  logDate?: string;
  activityDateTime?: string;

  vitalInfoAvailable: boolean;
  pulse: string;
  bodyTemperature: string;
  bloodPressureLow: string;
  bloodPressureHigh: string;
  oxygenLevel: string;
  bloodSugar: string;
  weight: string;
  height: number;

  bowelMovement: BowelInfo;
  seizureLog: SeizureInfo;
  behavioralIncident: IAbcForm;
  urinalDrugScreen: UrinalDrugScreen;
  sleepTimes: string[];

  createdDate?: string;
  createdBy?: string;
}

export class SeizureInfo {
  hadSeizure: string;
  startTime: string;
  endTime: string;
  called911: boolean;
}

export class BowelInfo {
  hadBowel: string;
  size: string;
  color: string;
  consistency: string;
}

export class UrinalDrugScreen {
  oxidant: string;
  specificGravity: string;
  ph: string;
  nitrite: string;
  gl: string;
  creatinine: string;
  alcohol: string;
  amphetamine: string;
  barbiturates: string;
  buprenorphine: string;
  benzodiazepines: string;
  cocaine: string;
  cotinine: string;
  methadonemetabolite: string;
  ethyl: string;
  fentanyl: string;
  heroin: string;
  ketanine: string;
  methadone: string;
  methamphetamine: string;
  ecstasy: string;
  morphine: string;
  opiate: string;
  oxycodone: string;
  phencyclidine: string;
  propoxyphene: string;
  syntheticcannabinoids: string;
  marijuana: string;
  tramadol: string;
  tricyclicAntidepressants: string;
  other: string;
  otherDesc: string;
}

export const MOCK_LANES: CareLogLane[] = [
  {
    id: LaneStatus.AVAILABLE,
    title: 'Available Notes',
    notes: [],
  },
  {
    id: LaneStatus.APPLIED,
    title: 'Applied Notes',
    notes: [],
  },
];
