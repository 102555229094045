<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Target</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Target</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="treatmentplanproblemsForm"
    (ngSubmit)="onTreatmentPlanTargetFormSubmit()"
  >
    <div class="row">
      <!-- Description -->
      <div class="col-md-12">
        <label class="labelText">Description</label>
        <mat-form-field appearance="outline">
          <textarea formControlName="description" matInput rows="2"></textarea>
        </mat-form-field>
      </div>

      <!-- Dimensions -->
      <div class="col-md-12">
        <label class="labelText">
          Dimensions <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="dimensions"
            multiple
            placeholder="Dimensions"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-option
                  class="mat-elevation-z2 mat-chip-active"
                  *ngFor="
                    let record of treatmentplanproblemsForm.controls[
                      'dimensions'
                    ].value;
                    let i = index
                  "
                  (removed)="removeSelectedOption(record, i)"
                >
                  {{ record }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </mat-chip-listbox>
            </mat-select-trigger>
            <ng-container>
              <mat-option
                *ngFor="let availableOption of dimensionOptions"
                [value]="availableOption"
              >
                {{ availableOption }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="treatmentplanproblemsForm.controls['dimensions'].invalid"
          >
            Please select atleast one dimension
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Status -->
      <div class="col-md-12">
        <label class="labelText">
          Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status">
            <mat-option
              *ngFor="let status of problemStatusOptions"
              [value]="status"
            >
              {{ status }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="treatmentplanproblemsForm.controls['status'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: r">
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentplanproblemsForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
