import {
  AnimationEvent,
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { subMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { AssessmentRequestApiService } from 'src/app/_services/assessments/assessmentrequest.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { VideoIframeComponent } from 'src/app/shared/components/video-iframe/video-iframe.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { Colors } from 'src/app/shared/utilities/colors';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { DataTablesModel, IOTP, Patient } from '../../_models';
import {
  CaseApiService,
  CoreService,
  DateTimeZoneService,
  OTPService,
  PatientNotesHttpService,
  PatientService,
  TherapySessionService,
  ToastMessageService,
  UsersService,
} from '../../_services';
import { AddCaseNotesDialogComponent } from '../cases/case-notes/dialogs/add/add.component';
import { AssessmentMainDialogComponent } from './patient-components/assessments/assessment-main-dialog/assessment-main-dialog.component';
import { ReoccurringAssessmentComponent } from './patient-components/assessments/reoccurring-assessment/reoccurring-assessment.component';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'true',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'false',
        style({
          display: 'none',
          transform: 'translateX(-100%)',
        })
      ),
      state('fullWidth', style({})),
      transition('true => false', animate('400ms ease-out')),
      transition('false => true', animate('400ms ease-in')),
      transition(
        'true => fullWidth',
        animate(
          '400ms ease-out',
          keyframes([
            style({ transform: 'translateX(0%)', offset: 0 }), // 0%:
            style({ transform: 'translateX(0%)', offset: 0.5 }), // 50%:
            style({ transform: 'translateX(0%)', offset: 1 }), //
          ])
        )
      ),
      transition(
        'fullWidth => true',
        animate(
          '800ms ease-out',
          keyframes([
            style({ transform: 'translateX(-30%)', offset: 0 }), // 0%:
            style({ transform: 'translateX(0%)', offset: 0.5 }), // 50%:
            style({ transform: 'translateX(0%)', offset: 1 }), //
          ])
        )
      ),
    ]),
  ],
})
export class PatientDetailsComponent implements OnInit {
  loggedInUserId: string;
  isOrgAdmin: boolean = false;

  patient: Patient;
  activeTab: string;
  active: string;

  patientId: string;
  secondaryId: string;
  secondaryTab: string;
  hideDetails: string;

  id: number;
  action: string = '';
  orgConfig: any;

  // Alert feature
  isAlertAcknowledgementEnabled = false;
  isBPSAEnforced = false;

  //********  Member Permissions ********/
  hasMemberEditAccess = false;
  // Member Payments
  hasMemberPaymentsViewAccess = false;
  hasMemberPaymentsAddAccess = false;
  hasMemberPaymentsEditAccess = false;
  hasMemberPaymentsDeleteAccess = false;
  // Member Notes
  hasNoteViewAccess = false;
  hasNoteAddAccess = false;
  hasNoteEditAccess = false;
  // Member Mood
  hasMoodViewAccess = false;
  hasMoodAddAccess = false;
  // Member Behavior Incidents
  hasIncidentViewAccess = false;
  hasIncidentAddAccess = false;
  // Member Goals
  hasGoalsViewAccess = false;
  hasGoalsAddAccess = false;
  hasGoalsEditAccess = false;
  hasGoalsDeleteAccess = false;
  // Member Diagnosis
  hasDiagnosisViewAccess = false;
  hasDiagnosisAddAccess = false;
  hasDiagnosisEditAccess = false;
  hasDiagnosisDeleteAccess = false;
  // Member Medications
  hasMedicationViewAccess = false;
  hasMedicationAddAccess = false;
  hasMedicationEditAccess = false;
  hasMedicationDeleteAccess = false;
  // Member Allergies
  hasAllergiesViewAccess = false;
  hasAllergiesAddAccess = false;
  hasAllergiesEditAccess = false;
  hasAllergiesDeleteAccess = false;
  // Member Physicians
  hasPhysiciansViewAccess = false;
  hasPhysiciansAddAccess = false;
  hasPhysiciansEditAccess = false;
  hasPhysiciansDeleteAccess = false;
  // Member Contacts
  hasContactsViewAccess = false;
  hasContactsAddAccess = false;
  hasContactsEditAccess = false;
  hasContactsDeleteAccess = false;
  // Member History
  hasMemberHistoryViewAccess = false;
  hasMemberHistoryEditAccess = false;
  // Member Family History
  hasFamilyHistoryViewAccess = false;
  hasFamilyHistoryEditAccess = false;

  //********  Case Permissions ********/
  // Cases
  hasCaseViewAccess = false;
  hasClosedCaseViewAccess = false;
  hasCaseAddAccess = false;
  hasCaseEditAccess = false;
  // Cases Tasks
  hasCaseTaskViewAccess = false;
  hasCaseTaskAddAccess = false;
  hasCaseTaskEditAccess = false;
  hasCaseTaskDeleteAccess = false;
  // Cases Notes
  hasCaseNotesViewAccess = false;
  hasCaseNotesAddAccess = false;
  hasCaseNotesEditAccess = false;
  hasCaseNotesDeleteAccess = false;
  // Cases Documents
  hasCaseDocumentsViewAccess = false;
  hasCaseDocumentsAddAccess = false;
  hasCaseDocumentsDeleteAccess = false;
  // Cases Care Log
  hasCaseLogViewAccess = false;
  hasCaseLogAddAccess = false;
  hasCaseLogEditAccess = false;
  hasCaseLogDeleteAccess = false;

  //********  Therapy Permissions ********/
  // Therapy Sessions
  hasTherapySessionViewAccess = false;
  hasTherapySessionAddAccess = false;
  hasTherapySessionEditAccess = false;
  hasTherapySessionDeleteAccess = false;
  // BPSA Intake
  hasBPSAIntakeViewAccess = false;
  hasBPSAIntakeAddAccess = false;
  hasBPSAIntakeEditAccess = false;
  // Clinical Notes
  hasClinicalNotesViewAccess = false;
  hasClinicalNotesAddAccess = false;
  hasClinicalNotesEditAccess = false;
  // BPSA Clinical
  hasBPSAClinicalViewAccess = false;
  hasBPSAClinicalAddAccess = false;
  hasBPSAClinicalEditAccess = false;
  // Subjectives
  hasSubjectivesViewAccess = false;
  hasSubjectivesEditAccess = false;
  // Objectives
  hasObjectivesViewAccess = false;
  hasObjectivesEditAccess = false;
  // Documents
  hasSessionDocumentsViewAccess = false;
  hasSessionDocumentsAddAccess = false;
  hasSessionDocumentsDeleteAccess = false;
  // Billing
  hasBillingViewAccess = false;
  hasBillingEditAccess = false;
  // Claims
  hasClaimsViewAccess = false;
  hasClaimsEditAccess = false;

  //********  Assessment Permissions ********/
  // Assessments
  hasMemberAssessmentViewAccess = false;
  hasMemberAssessmentAddAccess = false;
  hasMemberAssessmentEditAccess = false;
  hasMemberAssessmentDeleteAccess = false;
  // Assessments Charts
  hasAssessmentChartsViewAccess = false;
  // Assessments Guidance
  hasAssessmentGuidanceViewAccess = false;
  hasAssessmentGuidanceEditAccess = false;
  // Assessments Summary
  hasAssessmentSummaryViewAccess = false;
  hasAssessmentSummaryEditAccess = false;
  // Assessments Take
  hasAssessmentTakeAddAccess = false;

  // Organization Features
  hasHospitalDeviceFeature = false;
  hasCaseManagementFeature = false;
  hasTherapySessionFeature = false;
  hasClaimsFeature = false;
  hasTeleHealthEnabled = false;
  hasPatientPortalFeature = false;
  hasERXFeatures = false;
  hasASIFeatures = false;

  isPhysician: boolean = false;
  reload: Subject<boolean> = new Subject<boolean>();

  assessmentRequests: any[] = [];
  pendingAssessmentProcessing: boolean = false;

  // Interface for Getting OTP
  otpInterface: IOTP;
  otpLoader: boolean = false;
  accessCode: string = '';

  // Reoccurring Assessment Data
  reoccurringAssessments: DataTablesModel = {} as DataTablesModel;
  reoccurringProcessing: boolean = false;

  // Virtual appointment
  virtualAppointments: DataTablesModel = {} as DataTablesModel;
  selectedVirtualAppointment = 'Upcoming';
  virtualAppointmentProcessing: boolean = false;
  hostUrl: any;
  iframeMeeting: string = '';
  iframeDialogRef: any;
  hostUrlProcessing: boolean = false;

  // Member Notes Data
  memberAllNotes: DataTablesModel = {} as DataTablesModel;
  memberAllNotesProcessing: boolean = false;

  // Exanded Section:
  virtualExpanded: boolean = false;
  recurringExpanded: boolean = false;
  newAssessmentsExpanded: boolean = false;
  patientNotesExpanded: boolean = false;

  currentTimezone: string;
  physicianOrgConfigLoaded = false;
  preSelectedSession = null;

  reloadCasesTab: Subject<boolean> = new Subject<boolean>();
  reloadSessionTab: Subject<boolean> = new Subject<boolean>();
  reloadClaimsTab: Subject<boolean> = new Subject<boolean>();
  reloadReportsTab: Subject<boolean> = new Subject<boolean>();
  reloadDocumentsTab: Subject<boolean> = new Subject<boolean>();
  reloadAlerts: Subject<boolean> = new Subject<boolean>();

  // Hide or Unhide patient tab
  sideBarVisible: boolean = true;
  prescriptionEnabled: boolean = false;

  // Member Tab index
  memberTabPreSelected: string = '';

  constructor(
    public dialog: MatDialog,
    public apiService: PatientService,
    public caseApiService: CaseApiService,
    public caseNotesApiService: PatientNotesHttpService,
    public assessmentRequestApiService: AssessmentRequestApiService,
    private therapySessionService: TherapySessionService,
    private route: ActivatedRoute,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService,
    private coreService: CoreService,
    private otpService: OTPService,
    private toastMessageService: ToastMessageService,
    private sanitizer: DomSanitizer,
    private dateTimeZoneService: DateTimeZoneService,
    private orgConfigSandbox: OrgConfigSandbox,
    private router: Router,
    private userService: UsersService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();

    if (this.router.getCurrentNavigation()?.extras) {
      this.preSelectedSession =
        this.router.getCurrentNavigation()?.extras.state?.sessionCode;
    }
  }

  ngOnInit() {
    this.reoccurringAssessments.page = 0;
    this.reoccurringAssessments.per_page = 10;
    this.virtualAppointments.page = 0;
    this.virtualAppointments.per_page = 10;
    this.memberAllNotes.page = 0;
    this.memberAllNotes.per_page = 5;

    this.isPhysician = this.coreService.isPhysician();

    this.isOrgAdmin =
      this.coreService.isSuperOrgAdmin() || this.coreService.isOrgAdmin();
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();

    this.route.queryParams.subscribe((params) => {
      this.secondaryId = params?.secondaryId;
      this.secondaryTab = params?.secondaryTab;
      if (params?.hideMemberDetails) {
        this.sideBarVisible = false;
      } else {
        this.sideBarVisible = true;
      }
    });

    this.route.paramMap.subscribe((params) => {
      if (params.get('memberId') != this.patientId) {
        this.patientId = params.get('memberId');
        if (this.patientId) {
          this.getAllRequiredInformation();
        }
      }

      this.patientId = params.get('memberId');
      this.activeTab = params.get('primaryTab');
    });

    // Only for Staff members, check if eRX is enabled
    this.checkIfERXIsEnabled();

    // First check the organization config sandbox to determine if it is loading or not
    // Once load is complete assign respective orgconfig
    // if (this.isPhysician) {
    //   this.orgConfigSandbox.triggerPhysicianOrgConfigLoad();
    // }

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.isBPSAEnforced =
              this.orgConfig?.features.includes('BPSA_ENFORCED');

            this.isAlertAcknowledgementEnabled =
              this.orgConfig?.features.includes('ALERT_ACKNOWLEDGEMENT');

            this.hasTeleHealthEnabled =
              this.orgConfig?.features.includes('TELEHEALTH_ENABLED');

            this.hasHospitalDeviceFeature = this.orgConfig?.features.includes(
              'HOSPITAL_DEVICE_ENABLED'
            );

            this.hasTherapySessionFeature =
              this.orgConfig.features.includes('THERAPY_MANAGEMENT');

            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.hasPatientPortalFeature =
              this.orgConfig.features.includes('PP_ENABLE_PORTAL');

            if (this.orgConfig.features.includes('ORG_CLAIMS')) {
              this.hasClaimsFeature = true;
            }

            if (this.orgConfig.features.includes('ERX_SERVICES')) {
              this.hasERXFeatures = true;
            }
            if (this.orgConfig.features.includes('ASI_ENABLED')) {
              this.hasASIFeatures = true;
            }
          }

          if (this.hasTeleHealthEnabled) {
            this.getVirtualAppointmentList(this.patientId);
          }

          // Saving the permissions
          this.permissionsSandbox.permissions$.subscribe((response) => {
            // Make Sure that the organization has claims feature enabled
            if (this.orgConfig?.features.includes('ORG_CLAIMS')) {
              // Claims Access
              this.hasClaimsViewAccess = hasAccess(
                this.keycloakService,
                'CLAIMS_VIEW',
                response,
                null
              );
              this.hasClaimsEditAccess = hasAccess(
                this.keycloakService,
                'CLAIMS_EDIT',
                response,
                null
              );
              this.hasBillingViewAccess = hasAccess(
                this.keycloakService,
                'SESSION_BILLING_VIEW',
                response,
                null
              );
              this.hasBillingEditAccess = hasAccess(
                this.keycloakService,
                'SESSION_BILLING_EDIT',
                response,
                null
              );
            }
            // Member Access
            this.hasMemberEditAccess = hasAccess(
              this.keycloakService,
              'MEMBERS_EDIT',
              response,
              null
            );
            // Member Payments
            this.hasMemberPaymentsViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_PAYMENTS_VIEW',
              response,
              null
            );
            this.hasMemberPaymentsAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_PAYMENTS_ADD',
              response,
              null
            );
            this.hasMemberPaymentsEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_PAYMENTS_EDIT',
              response,
              null
            );
            this.hasMemberPaymentsDeleteAccess = hasAccess(
              this.keycloakService,
              'MEMBER_PAYMENTS_DELETE',
              response,
              null
            );
            // Member Notes
            this.hasNoteViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_NOTES_VIEW',
              response,
              null
            );
            this.hasNoteAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_NOTES_ADD',
              response,
              null
            );
            this.hasNoteEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_NOTES_EDIT',
              response,
              null
            );
            // Member Mood
            this.hasMoodViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_MOOD_VIEW',
              response,
              null
            );
            this.hasMoodAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_MOOD_ADD',
              response,
              null
            );
            // Member Behavior Incidents
            this.hasIncidentViewAccess = hasAccess(
              this.keycloakService,
              'BEHAVIOR_INCIDENTS_VIEW',
              response,
              null
            );
            this.hasIncidentAddAccess = hasAccess(
              this.keycloakService,
              'BEHAVIOR_INCIDENTS_ADD',
              response,
              null
            );
            // Member Goals
            this.hasGoalsViewAccess = hasAccess(
              this.keycloakService,
              'GOALS_VIEW',
              response,
              null
            );
            this.hasGoalsAddAccess = hasAccess(
              this.keycloakService,
              'GOALS_ADD',
              response,
              null
            );
            this.hasGoalsEditAccess = hasAccess(
              this.keycloakService,
              'GOALS_EDIT',
              response,
              null
            );
            this.hasGoalsDeleteAccess = hasAccess(
              this.keycloakService,
              'GOALS_DELETE',
              response,
              null
            );
            // Member Diagnosis
            this.hasDiagnosisViewAccess = hasAccess(
              this.keycloakService,
              'DIAGNOSIS_VIEW',
              response,
              null
            );
            this.hasDiagnosisAddAccess = hasAccess(
              this.keycloakService,
              'DIAGNOSIS_ADD',
              response,
              null
            );
            this.hasDiagnosisEditAccess = hasAccess(
              this.keycloakService,
              'DIAGNOSIS_EDIT',
              response,
              null
            );
            this.hasDiagnosisDeleteAccess = hasAccess(
              this.keycloakService,
              'DIAGNOSIS_DELETE',
              response,
              null
            );
            // Member Medications
            this.hasMedicationViewAccess = hasAccess(
              this.keycloakService,
              'MEDICATIONS_VIEW',
              response,
              null
            );
            this.hasMedicationAddAccess = hasAccess(
              this.keycloakService,
              'MEDICATIONS_ADD',
              response,
              null
            );
            this.hasMedicationEditAccess = hasAccess(
              this.keycloakService,
              'MEDICATIONS_EDIT',
              response,
              null
            );
            this.hasMedicationDeleteAccess = hasAccess(
              this.keycloakService,
              'MEDICATIONS_DELETE',
              response,
              null
            );
            // Member Allergies
            this.hasAllergiesViewAccess = hasAccess(
              this.keycloakService,
              'ALLERGIES_VIEW',
              response,
              null
            );
            this.hasAllergiesAddAccess = hasAccess(
              this.keycloakService,
              'ALLERGIES_ADD',
              response,
              null
            );
            this.hasAllergiesEditAccess = hasAccess(
              this.keycloakService,
              'ALLERGIES_EDIT',
              response,
              null
            );
            this.hasAllergiesDeleteAccess = hasAccess(
              this.keycloakService,
              'ALLERGIES_DELETE',
              response,
              null
            );
            // Member Physicians
            this.hasPhysiciansViewAccess = hasAccess(
              this.keycloakService,
              'PHYSICIANS_VIEW',
              response,
              null
            );
            this.hasPhysiciansAddAccess = hasAccess(
              this.keycloakService,
              'PHYSICIANS_ADD',
              response,
              null
            );
            this.hasPhysiciansEditAccess = hasAccess(
              this.keycloakService,
              'PHYSICIANS_EDIT',
              response,
              null
            );
            this.hasPhysiciansDeleteAccess = hasAccess(
              this.keycloakService,
              'PHYSICIANS_DELETE',
              response,
              null
            );
            // Member Contacts
            this.hasContactsViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_CONTACTS_VIEW',
              response,
              null
            );
            this.hasContactsAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_CONTACTS_ADD',
              response,
              null
            );
            this.hasContactsEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_CONTACTS_EDIT',
              response,
              null
            );
            this.hasContactsDeleteAccess = hasAccess(
              this.keycloakService,
              'MEMBER_CONTACTS_DELETE',
              response,
              null
            );
            // Member History
            this.hasMemberHistoryViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_MEDICAL_HISTORY_VIEW',
              response,
              null
            );
            this.hasMemberHistoryEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_MEDICAL_HISTORY_EDIT',
              response,
              null
            );
            // Member Family History
            this.hasFamilyHistoryViewAccess = hasAccess(
              this.keycloakService,
              'FAMILY_MEDICAL_HISTORY_VIEW',
              response,
              null
            );
            this.hasFamilyHistoryEditAccess = hasAccess(
              this.keycloakService,
              'FAMILY_MEDICAL_HISTORY_EDIT',
              response,
              null
            );

            //********  Case Permissions ********/
            // Cases
            this.hasCaseViewAccess = hasAccess(
              this.keycloakService,
              'CASES_VIEW',
              response,
              null
            );
            this.hasClosedCaseViewAccess = hasAccess(
              this.keycloakService,
              'CLOSED_CASES_VIEW',
              response,
              null
            );
            this.hasCaseAddAccess = hasAccess(
              this.keycloakService,
              'CASES_ADD',
              response,
              null
            );
            this.hasCaseEditAccess = hasAccess(
              this.keycloakService,
              'CASES_EDIT',
              response,
              null
            );
            // Cases Tasks
            this.hasCaseTaskViewAccess = hasAccess(
              this.keycloakService,
              'TASKS_VIEW',
              response,
              null
            );
            this.hasCaseTaskAddAccess = hasAccess(
              this.keycloakService,
              'TASKS_ADD',
              response,
              null
            );
            this.hasCaseTaskEditAccess = hasAccess(
              this.keycloakService,
              'TASKS_EDIT',
              response,
              null
            );
            this.hasCaseTaskDeleteAccess = hasAccess(
              this.keycloakService,
              'TASKS_DELETE',
              response,
              null
            );
            // Cases Notes
            this.hasCaseNotesViewAccess = hasAccess(
              this.keycloakService,
              'CASE_NOTES_VIEW',
              response,
              null
            );
            this.hasCaseNotesAddAccess = hasAccess(
              this.keycloakService,
              'CASE_NOTES_ADD',
              response,
              null
            );
            this.hasCaseNotesEditAccess = hasAccess(
              this.keycloakService,
              'CASE_NOTES_EDIT',
              response,
              null
            );
            this.hasCaseNotesDeleteAccess = hasAccess(
              this.keycloakService,
              'CASE_NOTES_DELETE',
              response,
              null
            );
            // Cases Documents
            this.hasCaseDocumentsViewAccess = hasAccess(
              this.keycloakService,
              'CASE_DOCUMENTS_VIEW',
              response,
              null
            );
            this.hasCaseDocumentsAddAccess = hasAccess(
              this.keycloakService,
              'CASE_DOCUMENTS_ADD',
              response,
              null
            );
            this.hasCaseDocumentsDeleteAccess = hasAccess(
              this.keycloakService,
              'CASE_DOCUMENTS_DELETE',
              response,
              null
            );
            // Cases Care Log
            this.hasCaseLogViewAccess = hasAccess(
              this.keycloakService,
              'CARE_LOG_VIEW',
              response,
              null
            );
            this.hasCaseLogAddAccess = hasAccess(
              this.keycloakService,
              'CARE_LOG_ADD',
              response,
              null
            );
            this.hasCaseLogEditAccess = hasAccess(
              this.keycloakService,
              'CARE_LOG_EDIT',
              response,
              null
            );
            this.hasCaseLogDeleteAccess = hasAccess(
              this.keycloakService,
              'CARE_LOG_DELETE',
              response,
              null
            );

            //********  Therapy Permissions ********/
            // Therapy Sessions
            this.hasTherapySessionViewAccess = hasAccess(
              this.keycloakService,
              'THERAPY_SESSIONS_VIEW',
              response,
              null
            );
            this.hasTherapySessionAddAccess = hasAccess(
              this.keycloakService,
              'THERAPY_SESSIONS_ADD',
              response,
              null
            );
            this.hasTherapySessionEditAccess = hasAccess(
              this.keycloakService,
              'THERAPY_SESSIONS_EDIT',
              response,
              null
            );
            this.hasTherapySessionDeleteAccess = hasAccess(
              this.keycloakService,
              'THERAPY_SESSIONS_DELETE',
              response,
              null
            );
            // BPSA Intake
            this.hasBPSAIntakeViewAccess = hasAccess(
              this.keycloakService,
              'BPSA_INTAKE_VIEW',
              response,
              null
            );
            this.hasBPSAIntakeAddAccess = hasAccess(
              this.keycloakService,
              'BPSA_INTAKE_ADD',
              response,
              null
            );
            this.hasBPSAIntakeEditAccess = hasAccess(
              this.keycloakService,
              'BPSA_INTAKE_EDIT',
              response,
              null
            );
            // Clinical Notes
            this.hasClinicalNotesViewAccess = hasAccess(
              this.keycloakService,
              'CLINICAL_NOTES_VIEW',
              response,
              null
            );
            this.hasClinicalNotesAddAccess = hasAccess(
              this.keycloakService,
              'CLINICAL_NOTES_ADD',
              response,
              null
            );
            this.hasClinicalNotesEditAccess = hasAccess(
              this.keycloakService,
              'CLINICAL_NOTES_EDIT',
              response,
              null
            );
            // BPSA Clinical
            this.hasBPSAClinicalViewAccess = hasAccess(
              this.keycloakService,
              'BPSA_CLINICAL_VIEW',
              response,
              null
            );
            this.hasBPSAClinicalAddAccess = hasAccess(
              this.keycloakService,
              'BPSA_CLINICAL_ADD',
              response,
              null
            );
            this.hasBPSAClinicalEditAccess = hasAccess(
              this.keycloakService,
              'BPSA_CLINICAL_EDIT',
              response,
              null
            );
            // Subjectives
            this.hasSubjectivesViewAccess = hasAccess(
              this.keycloakService,
              'SESSION_SUBJECTIVES_VIEW',
              response,
              null
            );
            this.hasSubjectivesEditAccess = hasAccess(
              this.keycloakService,
              'SESSION_SUBJECTIVES_EDIT',
              response,
              null
            );
            // Objectives
            this.hasObjectivesViewAccess = hasAccess(
              this.keycloakService,
              'SESSION_OBJECTIVES_VIEW',
              response,
              null
            );
            this.hasObjectivesEditAccess = hasAccess(
              this.keycloakService,
              'SESSION_OBJECTIVES_EDIT',
              response,
              null
            );
            // Documents
            this.hasSessionDocumentsViewAccess = hasAccess(
              this.keycloakService,
              'SESSION_DOCUMENTS_VIEW',
              response,
              null
            );
            this.hasSessionDocumentsAddAccess = hasAccess(
              this.keycloakService,
              'SESSION_DOCUMENTS_ADD',
              response,
              null
            );
            this.hasSessionDocumentsDeleteAccess = hasAccess(
              this.keycloakService,
              'SESSION_DOCUMENTS_DELETE',
              response,
              null
            );
            //********  Assessment Permissions ********/
            // Assessments
            this.hasMemberAssessmentViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_VIEW',
              response,
              null
            );
            this.hasMemberAssessmentAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_ADD',
              response,
              null
            );
            this.hasMemberAssessmentEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_EDIT',
              response,
              null
            );
            this.hasMemberAssessmentDeleteAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_DELETE',
              response,
              null
            );
            // Assessments Charts
            this.hasAssessmentChartsViewAccess = hasAccess(
              this.keycloakService,
              'CHARTS_VIEW',
              response,
              null
            );
            // Assessments Guidance
            this.hasAssessmentGuidanceViewAccess = hasAccess(
              this.keycloakService,
              'GUIDANCE_VIEW',
              response,
              null
            );
            this.hasAssessmentGuidanceEditAccess = hasAccess(
              this.keycloakService,
              'GUIDANCE_EDIT',
              response,
              null
            );
            // Assessments Summary
            this.hasAssessmentSummaryViewAccess = hasAccess(
              this.keycloakService,
              'SUMMARY_VIEW',
              response,
              null
            );
            this.hasAssessmentSummaryEditAccess = hasAccess(
              this.keycloakService,
              'SUMMARY_EDIT',
              response,
              null
            );
            // Assessments Take
            this.hasAssessmentTakeAddAccess = hasAccess(
              this.keycloakService,
              'TAKE_ASSESSMENTS_ADD',
              response,
              null
            );

            if (!this.activeTab) {
              if (
                this.hasTherapySessionFeature &&
                this.hasTherapySessionViewAccess &&
                !this.activeTab
              ) {
                this.activeTab = 'NOTES';
              } else if (
                this.hasCaseManagementFeature &&
                this.hasCaseViewAccess &&
                !this.activeTab
              ) {
                this.activeTab = 'CARE';
              }

              // Check for when active tab
              else if (this.activeTab === 'CARE') {
                if (!this.hasCaseViewAccess) {
                  if (this.hasTherapySessionViewAccess) {
                    this.activeTab = 'NOTES';
                  } else {
                    this.activeTab = '';
                  }
                }
              }

              if (this.activeTab === 'NOTES') {
                if (!this.hasTherapySessionViewAccess) {
                  this.activeTab = '';
                }
              }
            }
          });
        });
      }
    });
  }

  getAllRequiredInformation() {
    this.getPatientDetails(this.patientId);
    this.getPendingAssessmentRequests(this.patientId);
    this.getReoccurringAssessmentRequestList(this.patientId);
    this.getMemberNotes(this.patientId);

    this.notesListener();

    if (this.hasTeleHealthEnabled) {
      this.getVirtualAppointmentList(this.patientId);
    }
  }

  notesListener() {
    addEventListener('notesModified', (event) => {
      this.getMemberNotes(this.patientId);
    });
  }

  getPatientDetails(id: string) {
    this.apiService
      .getPatientDetails(id, this.orgConfig?.organizationCountry)
      .subscribe((response) => {
        this.patient = response.data;

        // Check for restricted member
        if (this.patient?.restrictedMember) {
          // If not an admin, check if this user is in the authorized list
          if (!this.isOrgAdmin) {
            if (
              this.patient.authorizedUsers == null ||
              this.patient.authorizedUsers?.indexOf(this.loggedInUserId) < 0
            ) {
              // Not authorized redirect to patients page
              this.router.navigate(['/patients']);
            }
          }
        }
      });
  }

  getPendingAssessmentRequests(id: string) {
    this.pendingAssessmentProcessing = true;
    this.assessmentRequestApiService
      .getAssessmentRequestsByPatientId(id, null, 1000, 0)
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.assessmentRequests = response.items;
          } else {
            this.assessmentRequests = [];
          }
          this.pendingAssessmentProcessing = false;
        },
        error: (error) => {
          this.pendingAssessmentProcessing = false;
        },
      });
  }

  // Get all notes for the member
  getMemberNotes(id: string) {
    this.memberAllNotesProcessing = true;
    this.caseNotesApiService
      .getMemberNotes(
        id,
        this.memberAllNotes.per_page,
        this.memberAllNotes.page
      )
      .subscribe({
        next: (response) => {
          this.memberAllNotesProcessing = false;
          if (response && response.total > 0) {
            this.memberAllNotes.items = response.items;
            this.memberAllNotes.total = response.total;
          } else {
            this.memberAllNotes.items = [];
            this.memberAllNotes.total = 0;
          }
        },
        error: (error) => {
          this.memberAllNotesProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get member notes.'
          );
        },
      });
  }

  // Member Notes Paginator
  getNextMemberNotes(event: PageEvent) {
    this.memberAllNotes.page = event.pageIndex;
    this.getMemberNotes(this.patientId);
  }

  // Virtual Appointment Paginator d
  getNextVirtual(event: PageEvent) {
    this.virtualAppointments.page = event.pageIndex;
    this.getVirtualAppointmentList(this.patientId);
  }

  getVirtualAppointmentList(id: string) {
    this.virtualAppointmentProcessing = true;
    this.therapySessionService
      .getAppointmentByType(
        id,
        this.selectedVirtualAppointment,
        this.virtualAppointments.per_page,
        this.virtualAppointments.page
      )
      .subscribe({
        next: (response) => {
          this.virtualAppointmentProcessing = false;
          if (response && response.total > 0) {
            this.virtualAppointments.items = response.items;
            this.virtualAppointments.total = response.total;
          } else {
            this.virtualAppointments.items = [];
            this.virtualAppointments.total = 0;
          }
        },
        error: (error) => {
          this.virtualAppointmentProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get appointment details.'
          );
        },
      });
  }

  completeAssessment(assessmentRequest: any) {
    const dialogRef = this.dialog.open(AssessmentMainDialogComponent, {
      minWidth: '50vw',
      maxWidth: '90vw',
      minHeight: '50vh',
      maxHeight: '95vh',
      autoFocus: false,
      disableClose: true,
      data: {
        patientId: this.patient.id,
        assessmentType: assessmentRequest.assessmentType,
        requestId: assessmentRequest.id,
        sessionId: assessmentRequest.sessionId,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.getPendingAssessmentRequests(this.patientId);
        this.reload.next(true);
      }
    });
  }

  triggerEvent(payload: any) {
    if (payload.eventType === 'RELOAD_PENDING_ASSESSMENT_REQUESTS') {
      this.getPendingAssessmentRequests(this.patientId);
      this.getReoccurringAssessmentRequestList(this.patientId);
    }

    if (payload.eventType === 'RELOAD_PATIENT_PROFILE') {
      this.getPatientDetails(this.patientId);
    }

    if (payload.eventType === 'RELOAD_TELEHEALTH_IF_APPLICABLE') {
      if (this.hasTeleHealthEnabled) {
        this.getVirtualAppointmentList(this.patientId);
      }
    }

    if (payload.eventType === 'DESELECT_PRESELECTED_SESSION') {
      this.preSelectedSession = null;
    }

    if (payload.eventType === 'RELOAD_ALERTS') {
      this.reloadAlerts.next(true);
    }
  }

  // Generate a one-time access code
  generateAcessCode(assessment) {
    this.otpInterface = Object.assign({}, this.otpInterface);
    this.accessCode = '';
    // Populating the desired fields only
    this.otpInterface.organizationId = assessment.organizationId;
    this.otpInterface.assessmentCategory = assessment.assessmentCategory;
    this.otpInterface.assessmentType = assessment.assessmentType;
    this.otpInterface.assessmentId = assessment.id;
    this.otpInterface.patientEmailAddress = assessment.patientEmailAddress;
    this.otpInterface.patientId = assessment.patientId;
    this.otpInterface.patientName = assessment.patientName;
    this.otpInterface.patientFirstName = assessment.patientFirstName;
    this.otpInterface.patientMiddleName = assessment.patientMiddleName;
    this.otpInterface.patientLastName = assessment.patientLastName;
    this.otpInterface.requestorId = assessment.requestorId;
    this.otpInterface.requestorName = assessment.requestorName;
    this.otpInterface.dateOfBirth = this.patient.dateOfBirth;

    // Call in the API to get the code
    this.otpLoader = true;
    this.otpService.generateOTP(this.otpInterface).subscribe(
      (response) => {
        if (response.result) {
          this.accessCode = response.message.text;
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Access Code',
              msg: response.message.text,
              disclaimer:
                '* Please note that this code will expire in 15 minutes.',
            },
            disableClose: true,
            autoFocus: false,
          });
        }

        this.otpLoader = false;
      },
      (error) => {
        this.otpLoader = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong. Please try again.'
        );
      }
    );
  }

  // Reoccurring Assessment
  getNextReoccurring(event: PageEvent) {
    this.reoccurringAssessments.page = event.pageIndex;
    this.getReoccurringAssessmentRequestList(this.patientId);
  }

  getReoccurringAssessmentRequestList(patientId) {
    this.reoccurringProcessing = true;
    this.assessmentRequestApiService
      .getReoccurringAssessmentRequestList(
        patientId,
        this.reoccurringAssessments.per_page,
        this.reoccurringAssessments.page
      )
      .subscribe(
        (response) => {
          this.reoccurringProcessing = false;
          if (response) {
            this.reoccurringAssessments.items = response.items;
            this.reoccurringAssessments.total = response.total;
          } else {
            this.reoccurringAssessments.items = [];
            this.reoccurringAssessments.total = 0;
          }
        },
        (error) => {
          this.reoccurringProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get reoccurring assessments.'
          );
        }
      );
  }

  requestReoccurringAssessment() {
    const dialogRef = this.dialog.open(ReoccurringAssessmentComponent, {
      minWidth: '40vw',
      minHeight: '40vh',
      disableClose: true,
      autoFocus: false,
      data: { patient: this.patient },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        // Reload reoccuring assessment list
        this.toastMessageService.displaySuccessMessage(
          'Successfully added recurring assessment.'
        );
        this.getReoccurringAssessmentRequestList(this.patientId);
        this.getPendingAssessmentRequests(this.patientId);
      }
    });
  }

  deleteReoccurringAssessment(id) {
    let dialogref = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        message: 'Are you sure you want to remove this recurring assessment?',
      },
    });
    dialogref.afterClosed().subscribe((response) => {
      if (response) {
        this.reoccurringProcessing = true;
        this.assessmentRequestApiService
          .deleteReoccuringAssessment(id, this.patientId)
          .subscribe(
            (response) => {
              if (this.reoccurringAssessments.items.length == 1) {
                this.reoccurringAssessments.page =
                  this.reoccurringAssessments.page - 1;
                if (this.reoccurringAssessments.page < 0) {
                  this.reoccurringAssessments.page = 0;
                }
              }
              this.toastMessageService.displaySuccessMessage(
                'Reoccuring assessment list has been updated.'
              );
              this.getReoccurringAssessmentRequestList(this.patientId);
              this.reoccurringProcessing = false;
            },
            (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong.'
              );
              this.reoccurringProcessing = false;
            }
          );
      }
    });
  }

  // Change Tab for virtual
  changeVirtualAppointment(type) {
    this.selectedVirtualAppointment = type;
    this.getVirtualAppointmentList(this.patientId);
  }

  // Disable/Enable Join
  isJoinValid(appointment) {
    let currentTime = new Date();
    let appointmentStartTime = new Date(appointment.start);
    let appointmentEndTime = new Date(appointment.end);

    let delayedStartTime = subMinutes(appointmentStartTime, 10);
    let delayedEndTime = subMinutes(appointmentEndTime, 5);

    if (currentTime >= delayedStartTime && currentTime <= delayedEndTime) {
      return true;
    } else {
      return false;
    }
  }

  // Join Meeting
  JoinMeeting(appointmentId) {
    this.hostUrl = '';
    this.iframeDialogRef = null;
    this.iframeMeeting = '';
    //First Get the host url
    this.hostUrlProcessing = true;

    this.therapySessionService
      .getHostUrl(appointmentId, this.patient.id, this.patient.organizationId)
      .subscribe({
        next: (response) => {
          this.hostUrlProcessing = false;
          this.hostUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            response.data.hostUrl
          );
          this.iframeMeeting = response.data.title;

          let dialogRef = (this.iframeDialogRef = this.dialog.open(
            VideoIframeComponent,
            {
              data: {
                iframeMeeting: this.iframeMeeting,
                hostUrl: this.hostUrl,
              },
              minWidth: '100vw',
              maxWidth: '100vw',
              minHeight: '100vh',
              maxHeight: '100vh',
              autoFocus: false,
              disableClose: true,
            }
          ));
        },
        error: (error) => {
          this.hostUrlProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  closeVideo() {
    if (this.iframeDialogRef != null) {
      this.iframeDialogRef.close();
      this.iframeDialogRef = null;
    }
  }

  addMemberNote() {
    const dialogRef = this.dialog.open(AddCaseNotesDialogComponent, {
      minWidth: '40vw',
      minHeight: '40vh',
      disableClose: true,
      autoFocus: false,
      data: { action: 'ADD', patientId: this.patient.id },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.getMemberNotes(this.patientId);
      }
    });
  }

  editMemberNote(note: any) {
    const dialogRef = this.dialog.open(AddCaseNotesDialogComponent, {
      minWidth: '40vw',
      minHeight: '40vh',
      disableClose: true,
      autoFocus: false,
      data: { action: 'EDIT', patientId: this.patient.id, caseNotes: note },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.getMemberNotes(this.patientId);
      }
    });
  }

  navigateToTab(tabName) {
    this.preSelectedSession = tabName;
  }

  colors = Colors;

  getColor(i) {
    let newIndex = i % 8;
    let colorCode = `2px solid ${this.colors[newIndex]}`;

    return colorCode;
  }

  public triggerTabReloadEvent(tabName: string) {
    this.activeTab = tabName;
    if (tabName === 'CARE') {
      this.activeTab = 'CARE';
      this.reloadCasesTab.next(true);
    } else if (tabName === 'NOTES') {
      this.activeTab = 'NOTES';
      this.reloadSessionTab.next(true);
    } else if (tabName === 'CLAIMS') {
      this.reloadClaimsTab.next(true);
    } else if (tabName === 'REPORTS') {
      this.reloadReportsTab.next(true);
    } else if (tabName === 'DOCUMENTS') {
      this.reloadDocumentsTab.next(true);
    }
  }

  checkIfERXIsEnabled() {
    this.userService.checkIfThisUserHasERXEnabled().subscribe({
      next: (response) => {
        if (response && response.data === 'Enabled') {
          this.prescriptionEnabled = true;
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get eRX Status'
        );
      },
    });
  }

  onAnimationStart(event: AnimationEvent) {
    if (this.sideBarVisible) {
      const element = event.element as HTMLElement;
      element.classList.add('col-lg-9');
      if (element.classList.contains('col-lg-12')) {
        element.classList.remove('col-lg-12');
      }
    }
  }

  onAnimationEnd(event: AnimationEvent) {
    if (!this.sideBarVisible) {
      const element = event.element as HTMLElement;
      element.classList.add('col-lg-12');
      if (element.classList.contains('col-lg-9')) {
        element.classList.remove('col-lg-9');
      }
    }
  }
}
