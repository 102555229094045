import { LocationStrategy } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { filter } from 'rxjs/operators';
import { DataTablesModel, IFacility } from 'src/app/_models';
import { CoreService, NotificationService } from 'src/app/_services';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { StreamApiService } from 'src/app/_services/messaging/stream.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { User } from 'stream-chat';
import {
  ChannelService,
  ChatClientService,
  StreamI18nService,
} from 'stream-chat-angular';
import { environment } from '../../../environments/environment';
import { MessagingComponent } from '../messaging/messaging.component';

var SockJs = require('sockjs-client');
var Stomp = require('stompjs');

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit, OnDestroy, AfterViewInit {
  apiKey = environment.streamApiKey;
  apiUrl = environment.apiUrl;
  public notifications = 0;
  public totalMessageUnreadCount: any;
  private sidebarVisible: boolean;

  public stompClient: any;

  mobile_menu_visible: any = 0;
  orgConfig: any = null;
  isPhysician: boolean = false;
  isPatient: boolean = false;

  facilities: Array<IFacility> = [];
  selectedFacility: string = '';
  loggedInUserId: string = '';
  adminPortalUrl: string = '';
  orgConfigLoaded: boolean = false;

  displayTopBarLinks = true;
  showReferralFunctionality = false;

  notificationsProcessing: boolean = false;
  notificationsList: DataTablesModel = {} as DataTablesModel;
  newNotificationCount: number = 0;

  currentTimeStamp = new Date().getTime();

  hasTherapySessionFeature: boolean = false;
  hasIVRServices: boolean = false;
  hasClaimsFeature = false;
  hasClaimsViewAccess = false;
  hasTherapySessionAddAccess = false;
  hasIVRSessionsViewAccess = false;
  hasIVRVoiceMessageViewAccess = false;
  hasIVRVoiceMessageEditAccess = false;
  hasIVRAppointmentsViewAccess = false;
  hasCaseManagementFeature: boolean = false;
  hasCaseViewAccess = false;
  hasTeleHealthEnabled = false;

  consolidatedMemberSearch = false;
  hasGroupSessionFeature = false;
  isOrgAdmin = false;

  private audio = new Audio('../../../assets/audio/notification-sound.mp3');

  constructor(
    private readonly keycloak: KeycloakService,
    private coreService: CoreService,
    private notificationService: NotificationService,
    public streamApiService: StreamApiService,
    public router: Router,
    public url: LocationStrategy,

    private localStorageService: LocalStorageService,
    private facilitiesSandbox: FacilitiesSandbox,
    private route: ActivatedRoute,
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private dialog: MatDialog,

    private streamI18nService: StreamI18nService,
    private chatService: ChatClientService,
    private channelService: ChannelService
  ) {
    this.isPhysician = this.coreService.isPhysician();
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
  }

  ngOnInit() {
    this.showReferralFunctionality = environment.referralFunctionality;
    this.isOrgAdmin = this.coreService.isOrgAdmin() || this.coreService.isSuperOrgAdmin();
    this.adminPortalUrl = environment.adminPortalUrl;

    this.orgConfigLoaded = false;
    this.sidebarVisible = false;
    this.notificationsList.per_page = 100;
    this.notificationsList.page = 0;

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((response) => {
          this.orgConfig = response;
          this.orgConfigLoaded = true;
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.hasTherapySessionFeature =
              this.orgConfig.features.includes('THERAPY_MANAGEMENT');

            this.hasIVRServices =
              this.orgConfig.features.includes('IVR_SERVICES');

            this.hasClaimsFeature =
              this.orgConfig.features.includes('ORG_CLAIMS');

            this.hasGroupSessionFeature =
              this.orgConfig.features.includes('GROUP_SESSIONS');

            this.consolidatedMemberSearch = this.orgConfig.features.includes(
              'CONSOLIDATED_MEMBER_SEARCH'
            );

            this.hasTeleHealthEnabled =
              this.orgConfig?.features.includes('TELEHEALTH_ENABLED');

            this.permissionsSandbox.permissions$.subscribe((response) => {
              this.hasClaimsViewAccess = hasAccess(
                this.keycloak,
                'CLAIMS_VIEW',
                response,
                null
              );
              this.hasIVRSessionsViewAccess = hasAccess(
                this.keycloak,
                'IVR_SESSIONS_VIEW',
                response,
                null
              );
              this.hasIVRVoiceMessageViewAccess = hasAccess(
                this.keycloak,
                'IVR_VOICE_MESSAGES_VIEW',
                response,
                null
              );

              this.hasIVRAppointmentsViewAccess = hasAccess(
                this.keycloak,
                'IVR_APPOINTMENTS_VIEW',
                response,
                null
              );

              this.hasTherapySessionAddAccess = hasAccess(
                this.keycloak,
                'THERAPY_SESSIONS_ADD',
                response,
                null
              );

              this.hasCaseViewAccess = hasAccess(
                this.keycloak,
                'CASES_VIEW',
                response,
                null
              );
            });
          }
        });
      }
    });

    if (this.url.path().indexOf('pwotb') > -1) {
      this.displayTopBarLinks = false;
    } else {
      this.isPatient = this.coreService.isPatient();
      this.loadFacilities();

      // Do not initialize chat for patient
      if (!this.isPatient) {
        this.initialChatService();
      }

      if (localStorage.getItem('selectedFacility')) {
        this.selectedFacility = JSON.parse(
          localStorage.getItem('selectedFacility')
        ).name;
        this.localStorageService.set(
          'selectedFacility',
          JSON.parse(localStorage.getItem('selectedFacility'))
        );
      } else {
        // If the local storage is empty and it has query params
        this.route.queryParams.subscribe((params) => {
          //check if facility id exists
          if (params?.facilityId && params?.facilityName) {
            this.selectedFacility = params?.facilityName;
            let localStorageFacility: any = {};
            localStorageFacility.id = params?.facilityId;
            localStorageFacility.name = params?.facilityName;
            this.localStorageService.set(
              'selectedFacility',
              localStorageFacility
            );
          }
        });
      }

      this.localStorageService
        .watch('selectedFacility')
        .subscribe((selectedFacility) => {
          if (selectedFacility) {
            this.selectedFacility = selectedFacility.name;
          } else {
            this.selectedFacility = null;
          }
        });
    }

    if (this.isPatient || this.isPhysician) {
      setTimeout(() => {
        this.initializeWebSocket();

        this.getNotifications();
      }, 5000);
    }
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (this.sidebarVisible) {
          this.sidebarClose();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.isPatient || this.isPhysician) {
      if (this.stompClient !== null) {
        this.stompClient.disconnect();
      }
    }
  }

  initializeWebSocket() {
    let myId = this.isPatient
      ? this.coreService.getPatientId()
      : this.coreService.getLoggedInCareProviderId();

    let websocketChannel = '/message/' + myId;

    const serverUrl = `${environment.apiUrl}/mw-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;

    // Listening to web socket event
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe(websocketChannel, (message) => {
        if (message?.body === 'Increment') {
          that.getNotifications();
          that.playAudio();
        }
      });
    });
  }

  sidebarOpen() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];

    const body = document.getElementsByTagName('body')[0];

    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];

    var $layer = document.getElementsByClassName('close-layer')[0];
    $layer.classList.remove('close-layer');

    this.sidebarVisible = false;
    if (body) {
      body.classList.remove('nav-open');
    }
    // $('html').removeClass('nav-open');

    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  public logout() {
    localStorage.clear();
    this.keycloak.logout().then(() => {});
  }

  public loadFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
      }
    });
  }

  onFacilityChange(event, facilityId, facilityName) {
    this.selectedFacility = facilityName;
    let selectedFacility: any = {};
    selectedFacility.id = facilityId;
    selectedFacility.name = facilityName;
    localStorage.setItem('selectedFacility', selectedFacility);
    this.localStorageService.set('selectedFacility', selectedFacility);
    this.router.navigate(['/patients'], {
      queryParams: { facilityId: facilityId, facilityName: facilityName },
    });
  }

  // Getting difference in time
  getTimeDiff(notificationTime) {
    // Two date objects to find the difference between
    const notificationDate = new Date(notificationTime);
    const currentTime = new Date();

    // Convert the dates to UTC
    const date1Utc = notificationDate.toISOString();
    const date2Utc = currentTime.toISOString();

    // Get the difference between the two dates in milliseconds
    const diff = Math.abs(
      new Date(date1Utc).getTime() - new Date(date2Utc).getTime()
    );

    // Calculate the days, hours, and minutes between the two dates
    const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    // Output the difference in days, hours, and minutes
    if (weeks >= 1) {
      if (weeks == 1) {
        return weeks + ' week ago';
      } else {
        return weeks + ' weeks ago';
      }
    } else if (days >= 1 && weeks < 1) {
      if (days == 1) {
        return days + ' day ago';
      } else {
        return days + ' days ago';
      }
    } else if (days < 1 && hours <= 24 && hours >= 1) {
      if (hours == 1) {
        return hours + ' hour ago';
      } else {
        return hours + ' hours ago';
      }
    } else if (days < 1 && hours < 1) {
      if (minutes == 1) {
        return minutes + ' minute ago';
      } else {
        return minutes + ' minutes ago';
      }
    }
  }

  markNotificationAsRead(id: string) {
    this.notificationsProcessing = true;

    this.notificationService.markAsRead(id).subscribe(
      (response) => {
        this.getNotifications();
        this.notificationsProcessing = false;
      },
      (error) => {
        this.notificationsProcessing = false;
      }
    );
  }

  // Update Status to seen
  updateStatusToSeen() {
    let newNotificationFilter = this.notificationsList.items.filter(
      (notification) => notification.status === 'NEW'
    );
    // If there are notifications with status "NEW"
    if (newNotificationFilter.length > 0) {
      this.notificationService
        .updateSeenStatus(newNotificationFilter)
        .subscribe((response) => {
          this.notificationsList.items.forEach((item) => {
            item.status = 'SEEN';
          });

          this.newNotificationCount = this.notificationsList.items.filter(
            (item) => item.status === 'NEW'
          ).length;
        });
    }
  }

  // Handle Delete notification for the nav bar notification
  deleteNotification(id: string) {
    this.notificationService.deleteNotification(id).subscribe(
      (response) => {
        this.getNotifications();
      },
      (error) => {}
    );
  }

  deleteAllNotifications() {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove all the notifications?',
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.notificationService
          .deleteAllNotification()
          .subscribe((response) => {
            this.getNotifications();
          });
      }
    });
  }

  playAudio() {
    this.audio.pause();

    this.audio
      .play()
      .then(() => {})
      .catch((error) => {
        console.error('Error playing audio:', error);
      });
  }

  getNotifications() {
    this.notificationsProcessing = true;

    if (this.isPhysician) {
      // Get notifications for Clinicians
      this.notificationService
        .getClinicianNotifications(
          this.notificationsList.per_page,
          this.notificationsList.page
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.notificationsList.items = response.items;
              this.notificationsList.total = response.total;

              this.newNotificationCount = this.notificationsList.items.filter(
                (item) => item.status === 'NEW'
              ).length;
            } else {
              this.notificationsList.items = [];
              this.notificationsList.total = 0;
              this.newNotificationCount = 0;
            }
            this.notificationsProcessing = false;
          },
          error: (error) => {
            this.notificationsProcessing = false;
          },
        });
    } else {
      // Get notifications for Members
      this.notificationService
        .getMemberNotifications(
          this.notificationsList.per_page,
          this.notificationsList.page
        )
        .subscribe(
          (response) => {
            if (response) {
              this.notificationsList.items = response.items;
              this.notificationsList.total = response.total;

              this.newNotificationCount = this.notificationsList.items.filter(
                (item) => item.status === 'NEW'
              ).length;
            }
            if (response == null) {
              this.notificationsList.items = [];
              this.notificationsList.total = 0;
              this.newNotificationCount = 0;
            }
            this.notificationsProcessing = false;
          },
          (error) => {
            this.notificationsProcessing = false;
          }
        );
    }
  }

  navigateNotificationLink(notification) {
    if (notification.linkType) {
      if (notification.linkType === 'Session') {
        // IF physician go to the session note page
        if (this.isPhysician) {
          this.router.navigate(
            ['/main/member/' + notification.patientId + '/NOTES'],
            {
              queryParams: {
                secondaryId: notification.sessionId,
                secondaryTab: 'notedetails',
              },
            }
          );
        } else if (this.isPatient) {
          // If this is a patient go to the appointmets page
          this.router.navigate(['/patientDashboard/Sessions']);
        }
        // Navigate to session
      } else if (notification.linkType === 'Assessment') {
        if (this.isPatient) {
          // If this is a patient go to the appointmets page
          this.router.navigate(['/patientDashboard/Assessments']);
        } else if (this.isPhysician) {
          this.router.navigate(
            ['/main/member/' + notification.patientId + '/MEMBER'],
            {
              queryParams: {
                secondaryId: '4',
              },
            }
          );
        }
        // Navigate to Goals
      } else if (notification.linkType === 'Goal') {
        if (this.isPatient) {
          // If this is a patient go to the appointmets page
          this.router.navigate(['/patientDashboard/Goals']);
        } else if (this.isPhysician) {
          this.router.navigate(
            ['/main/member/' + notification.patientId + '/MEMBER'],
            {
              queryParams: {
                secondaryId: '5',
              },
            }
          );
        }
      }
    }
  }

  // Initialize the Chat Service, that way we can open and close popup as desired
  initialChatService() {
    this.streamApiService.getStreamToken().subscribe((response) => {
      if (response.data) {
        const userToken = response.data;
        this.chatService.init(this.apiKey, this.loggedInUserId, userToken);
        this.streamI18nService.setTranslation();

        this.channelService.init(
          {
            type: 'messaging',
            members: { $in: [this.loggedInUserId] },
            // initialsType: 'first-letter-of-each-word'
          },
          { has_unread: -1 },
          {},
          false // Do not set any active channel at the time of Init
        );

        this.chatService.user$.forEach((user: User) => {
          // Helps track how many unread messages are there
          this.totalMessageUnreadCount = user.total_unread_count;
        });
      }
    });
  }

  // Open chat model in a popup
  openChat() {
    let dialogRef = this.dialog.open(MessagingComponent, {
      height: '90vh',
      width: '90vw',
      data: {
        channelService: this.channelService,
        chatService: this.chatService,
        streamI18nService: this.streamI18nService,
      },
    });

    // De-Select active channel when chat box is closed
    dialogRef.afterClosed().subscribe(() => {
      this.channelService.deselectActiveChannel();
    });
  }
}
