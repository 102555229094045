import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ICaseTreatmentIntake } from 'src/app/_models/case/treatment/admission/casetreatmentintake.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CaseTreatmentIntakeApiService {
    constructor(private httpClient: HttpClient) { }
    getCaseTreatmentIntakeDetails(casetreatmentintakeId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/casetreatmentintake/` + casetreatmentintakeId);
    }
    updateCaseTreatmentIntake(thisCaseTreatmentIntake: ICaseTreatmentIntake): Observable<ICaseTreatmentIntake> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ICaseTreatmentIntake>(`${environment.apiUrl}/casetreatmentintake`, thisCaseTreatmentIntake, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}