import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentDischargeStatus } from 'src/app/_models/case/treatment/discharge/treatmentdischargestatus.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TreatmentDischargeStatusApiService {
  constructor(private httpClient: HttpClient) {}

  getTreatmentDischargeStatusDetails(
    programId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/treatmentdischargestatus/` + programId
    );
  }

  updateTreatmentDischargeStatus(
    thisTreatmentDischargeStatus: ITreatmentDischargeStatus
  ): Observable<ITreatmentDischargeStatus> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITreatmentDischargeStatus>(
        `${environment.apiUrl}/treatmentdischargestatus`,
        thisTreatmentDischargeStatus,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
