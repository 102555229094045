import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICiwa } from 'src/app/_models/assessments/addiction/ciwa.model';
import {
  ToastMessageService,
} from 'src/app/_services';
import { CiwaApiService } from 'src/app/_services/assessments/addiction/ciwa.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-ciwa',
  templateUrl: './ciwa.component.html',
  styleUrl: './ciwa.component.css'
})
export class CiwaComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public ciwaApiService: CiwaApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public ciwaForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  ciwa: ICiwa;
  ciwaError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.ciwaForm, this.errors);

    this.loadCiwaAssessment();
  }

  loadCiwaAssessment() {
    this.ciwaForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.ciwaApiService
        .getCiwaDetails(this.assessmentId)
        .subscribe((ciwa) => {
          if (ciwa.data) {
            this.ciwaForm.controls['pulse'].setValue(ciwa.data.pulse);
            this.ciwaForm.controls['bloodpressure'].setValue(ciwa.data.bloodpressure);
            this.ciwaForm.controls['nauseaAndVomiting'].setValue(ciwa.data.nauseaAndVomiting.answer);
            this.ciwaForm.controls['tactileDisturbances'].setValue(ciwa.data.tactileDisturbances.answer);
            this.ciwaForm.controls['tremor'].setValue(ciwa.data.tremor.answer);
            this.ciwaForm.controls['auditoryDisturbances'].setValue(ciwa.data.auditoryDisturbances.answer);
            this.ciwaForm.controls['paroxysmalSweats'].setValue(ciwa.data.paroxysmalSweats.answer);
            this.ciwaForm.controls['visualDisturbances'].setValue(ciwa.data.visualDisturbances.answer);
            this.ciwaForm.controls['anxiety'].setValue(ciwa.data.anxiety.answer);
            this.ciwaForm.controls['headacheFullnessInHead'].setValue(ciwa.data.headacheFullnessInHead.answer);
            this.ciwaForm.controls['agitation'].setValue(ciwa.data.agitation.answer);
            this.ciwaForm.controls['orientationAndCloudingOfSensorium'].setValue(ciwa.data.orientationAndCloudingOfSensorium.answer);

            this.riskScore = ciwa.data.riskScore;
          }
        });
    }
  }
  onCiwaFormSubmit() {
    if (this.ciwaForm.invalid) {
      this.ciwaForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.ciwa = Object.assign({}, this.ciwa);
    this.ciwa.patientId =
      this.ciwaForm.controls['patientId'].value;
    this.ciwa.sessionId =
      this.ciwaForm.controls['sessionId'].value;
    this.ciwa.assessmentRequestId =
      this.ciwaForm.controls['assessmentRequestId'].value;

    this.ciwa.pulse = this.ciwaForm.controls['pulse'].value;
    this.ciwa.bloodpressure = this.ciwaForm.controls['bloodpressure'].value;

    this.ciwa.nauseaAndVomiting = {
      answer:
        this.ciwaForm.controls['nauseaAndVomiting'].value,
    };
    this.ciwa.tactileDisturbances = {
      answer:
        this.ciwaForm.controls['tactileDisturbances'].value,
    };
    this.ciwa.tremor = {
      answer:
        this.ciwaForm.controls['tremor'].value,
    };
    this.ciwa.auditoryDisturbances = {
      answer:
        this.ciwaForm.controls['auditoryDisturbances'].value,
    };
    this.ciwa.paroxysmalSweats = {
      answer:
        this.ciwaForm.controls['paroxysmalSweats'].value,
    };
    this.ciwa.visualDisturbances = {
      answer:
        this.ciwaForm.controls['visualDisturbances'].value,
    };
    this.ciwa.anxiety = {
      answer:
        this.ciwaForm.controls['anxiety'].value,
    };
    this.ciwa.headacheFullnessInHead = {
      answer:
        this.ciwaForm.controls['headacheFullnessInHead'].value,
    };
    this.ciwa.agitation = {
      answer:
        this.ciwaForm.controls['agitation'].value,
    };
    this.ciwa.orientationAndCloudingOfSensorium = {
      answer:
        this.ciwaForm.controls['orientationAndCloudingOfSensorium'].value,
    };


    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.ciwa.accessCode = this.accessCode;

      this.ciwaApiService
        .addGuestLinkCiwa(this.ciwa)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised Assessment'
            );
          }
        );
    } else {
      this.ciwaApiService
        .addCiwa(this.ciwa)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'pulse': [
      { type: 'required', message: 'Pulse is required' },
    ],
    'bloodpressure': [
      { type: 'required', message: 'Blood Pressure is required' },
    ],
    'nauseaAndVomiting': [
      { type: 'required', message: 'Nausea and Vomiting is required' },
    ],
    'tactileDisturbances': [
      { type: 'required', message: 'Tactile Disturbances is required' },
    ],
    'tremor': [
      { type: 'required', message: 'Tremor is required' },
    ],
    'auditoryDisturbances': [
      { type: 'required', message: 'Auditory Disturbances is required' },
    ],
    'paroxysmalSweats': [
      { type: 'required', message: 'Paroxysmal Sweats is required' },
    ],
    'visualDisturbances': [
      { type: 'required', message: 'Visual Disturbances is required' },
    ],
    'anxiety': [
      { type: 'required', message: 'Anxiety is required' },
    ],
    'headacheFullnessInHead': [
      { type: 'required', message: 'Headache, Fullness in Head is required' },
    ],
    'agitation': [
      { type: 'required', message: 'Agitation is required' },
    ],
    'orientationAndCloudingOfSensorium': [
      { type: 'required', message: 'Orientation and Clouding of Sensorium is required' },
    ],
  };

  private buildForm() {
    this.ciwaForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      pulse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      bloodpressure: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      nauseaAndVomiting: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      tactileDisturbances: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      tremor: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      auditoryDisturbances: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      paroxysmalSweats: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      visualDisturbances: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      anxiety: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      headacheFullnessInHead: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      agitation: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      orientationAndCloudingOfSensorium: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.ciwaForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.ciwaForm) {
      return;
    }
    const form = this.ciwaForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
