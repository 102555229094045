<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Task</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Task</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="noteForm" novalidate (ngSubmit)="onNoteFormSubmit()">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">Task <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Task"
            formControlName="text"
            name="text"
            required
          ></textarea>
          <mat-error *ngIf="noteForm.controls['text'].invalid">
            Please describe the task
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">
          Category <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="category" placeholder="Category">
            <mat-option value="General">General</mat-option>
            <mat-option value="Clinical">Clinical</mat-option>
            <mat-option value="Diet">Diet</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          <mat-error *ngIf="noteForm.controls['category'].invalid">
            Please select a category
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText"
          >Priority <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <mat-select formControlName="priority" placeholder="Priority">
            <mat-option value="Low">Low</mat-option>
            <mat-option value="Medium">Medium</mat-option>
            <mat-option value="High">High</mat-option>
          </mat-select>
          <mat-error *ngIf="noteForm.controls['priority'].invalid">
            Please select a priority
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">Shift <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="shift" placeholder="Shift">
            <mat-option value="All">All Shifts</mat-option>
            <mat-option value="1">{{
              orgConfig.firstShiftName ? orgConfig.firstShiftName : '1st'
            }}</mat-option>
            <mat-option value="2" *ngIf="totalShifts >= 2"
              >{{
                orgConfig.secondShiftName ? orgConfig.secondShiftName : '2nd'
              }}
            </mat-option>
            <mat-option value="3" *ngIf="totalShifts >= 3"
              >{{ orgConfig.thirdShiftName ? orgConfig.thirdShiftName : '3rd' }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="noteForm.controls['shift'].invalid">
            Please select a shift
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">Capture Outcome </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="additionalInput"
            placeholder="Capture Outcome"
          >
            <mat-option value=""></mat-option>
            <mat-option value="Vital">Vital</mat-option>
            <mat-option value="Seizure Log">Seizure Log</mat-option>
            <mat-option value="Bowel Movement">Bowel Movement</mat-option>
            <mat-option value="Sleep Record">Sleep Record</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="noteForm.invalid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
