import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMemberConsentDocument, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';

@Component({
  selector: 'app-member-add-edit-alerts',
  templateUrl: './member-add-edit-alerts.component.html',
  styleUrl: './member-add-edit-alerts.component.css',
})
export class MemberAddEditAlertsComponent implements OnInit {
  patient: Patient;
  action: string = '';
  memberAlertForm: FormGroup;

  memberConsentDocuments: IMemberConsentDocument;
  isProcessing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MemberAddEditAlertsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.patient = data.patient;

    if (data.action === 'EDIT') {
      this.memberConsentDocuments = data.memberConsentDocuments;
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.memberAlertForm.controls['id'].setValue(
        this.memberConsentDocuments.id
      );
      this.memberAlertForm.controls['documentName'].setValue(
        this.memberConsentDocuments.documentName
      );
    }
  }

  buildForm() {
    this.memberAlertForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),

      memberId: new FormControl(this.patient.id, Validators.required),
      documentName: new FormControl('', Validators.required),
      documentType: new FormControl('Member Alerts', Validators.required),

      status: new FormControl('Pending'),
    });
  }

  submitForm() {
    this.memberConsentDocuments = Object.assign(
      {},
      this.memberConsentDocuments
    );
    this.memberConsentDocuments = Object.assign(
      this.memberConsentDocuments,
      this.memberAlertForm.value
    );

    this.isProcessing = true;

    if (this.action === 'ADD') {
      this.consentDocumentApiService
        .addNewMemberAlert(this.memberConsentDocuments)
        .subscribe({
          next: (response) => {
            this.isProcessing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully added member alert'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.isProcessing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add the member alert'
            );
          },
        });
    } else {
      this.consentDocumentApiService
        .updateMemberAlert(this.memberConsentDocuments)
        .subscribe({
          next: (response) => {
            this.isProcessing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully updated member alert'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.isProcessing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the member alert'
            );
          },
        });
    }
  }
}
