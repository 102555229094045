<mat-card
  appearance="raised"
  style="margin-top: 10px; margin-bottom: 10px; padding-bottom: 30px"
>
  <div class="row" style="row-gap: 15px">
    <div class="col-sm-12 col-xl-12">
      <p style="font-size: 16px; font-weight: 400; padding-top: 10px">
        <span
          (click)="deSelectSession()"
          style="color: #2196f3; cursor: pointer; font-weight: 500"
        >
          Notes
        </span>
        &nbsp; / &nbsp; {{ therapySession?.sessionCode }}

        &nbsp; &nbsp;
        <button
          id="chatButton"
          mat-icon-button
          color="primary"
          type="button"
          style="margin: 5px; opacity: 0.5"
          (click)="viewSessionLevelNote()"
        >
          <mat-icon style="font-size: 16px">chat</mat-icon>
        </button>
      </p>

      <!-- Session Information Box -->
      <div class="row">
        <!-- Left Side -->
        <div class="col-md-4">
          <!-- Session Code -->
          <div class="longTwoGridContainer">
            <label class="labelBold">Session Code</label>
            <label>
              {{ therapySession?.sessionCode }}
            </label>
          </div>
          <!-- Attached Case Code -->
          <div class="longTwoGridContainer" *ngIf="therapySession?.caseId">
            <label class="labelBold">Associated Case Id</label>
            <label>
              {{ therapySession?.caseNumber }}
            </label>
          </div>
          <!-- Visit Reason -->
          <div
            class="longTwoGridContainer"
            *ngIf="therapySession.visitReasonName"
          >
            <label class="labelBold">Visit Reason</label>
            <label>
              {{ therapySession.visitReasonName }}
            </label>
          </div>
          <!-- Appointment For -->
          <div class="longTwoGridContainer" *ngIf="therapySession.sessionFor">
            <label class="labelBold">Appointment For</label>
            <label>
              {{ therapySession.sessionFor }}
            </label>
          </div>
          <!-- Appointment Type -->
          <div class="longTwoGridContainer">
            <label class="labelBold">
              {{
                therapySession?.sessionType === 'Phone Note'
                  ? 'Type'
                  : 'Appointment Type'
              }}</label
            >
            <label>
              {{ therapySession.sessionType }}
            </label>
          </div>
          <!-- Appointment Status -->
          <div class="longTwoGridContainer">
            <label class="labelBold"> Status</label>
            <label
              class="statusCard"
              [style.color]="
                therapySession.status === 'Active' ? '#029102' : '#a72323'
              "
              [style.background]="
                therapySession.status === 'Active' ? '#dbffdbde' : '#f3b3b3a1'
              "
            >
              {{ therapySession?.status }}
              <span
                *ngIf="
                  therapySession.status === 'Active' &&
                  therapySession?.sessionType != 'Phone Note'
                "
                style="text-transform: capitalize"
              >
                & {{ therapySession.queue }}
              </span>
            </label>
          </div>
          <!-- Appointment Cancelation Reason -->
          <div
            class="longTwoGridContainer"
            *ngIf="therapySession.status === 'Canceled'"
          >
            <label class="labelBold">Cancellation Reason</label>
            <label>
              {{ therapySession.cancellationReason }}
            </label>
          </div>
        </div>

        <!-- Middle Side -->
        <div class="col-md-4">
          <!-- Session Code -->
          <div class="longTwoGridContainer">
            <label class="labelBold">
              {{
                therapySession?.sessionType === 'Phone Note'
                  ? 'Date/Time'
                  : 'Session Time'
              }}
            </label>
            <label>
              {{
                therapySession.start | date : 'M/d/y, h:mm a' : currentTimezone
              }}
              <span *ngIf="therapySession?.sessionType != 'Phone Note'">
                -
                {{
                  therapySession.end | date : 'M/d/y, h:mm a' : currentTimezone
                }}
              </span>
              {{ currentTimezone }}
            </label>
          </div>
          <!-- Session Duration -->
          <div
            class="longTwoGridContainer"
            *ngIf="therapySession.sessionDuration"
          >
            <label class="labelBold">Session Duration</label>
            <label> {{ therapySession.sessionDuration }} minutes </label>
          </div>

          <!--therapeuticModality -->
          <div
            class="longTwoGridContainer"
            *ngIf="
              therapySession?.therapeuticModality &&
              therapySession?.therapeuticModality.length > 0
            "
          >
            <label class="labelBold">Therapeutic Modality</label>
            <label>
              {{ therapySession.therapeuticModality }}
            </label>
          </div>
        </div>

        <!-- Right Side -->
        <div class="col-md-4">
          <!-- Billing Type -->
          <div
            class="longTwoGridContainer"
            *ngIf="therapySession?.sessionType !== 'Phone Note'"
          >
            <label class="labelBold">Billing Type</label>
            <label>
              {{ therapySession.billingType }}
            </label>
          </div>
          <!-- Rendering Provider -->
          <div class="longTwoGridContainer" *ngIf="therapySession?.physicianId">
            <label class="labelBold"> Rendering Provider </label>
            <label>
              {{ therapySession.physicianLastName }},
              {{ therapySession.physicianFirstName }}
            </label>
          </div>
          <!-- Supervising Provider -->
          <div
            class="longTwoGridContainer"
            *ngIf="therapySession?.supervisingRPId"
          >
            <label class="labelBold"> Supervising Provider </label>
            <label>
              {{ therapySession.supervisingRPLastName }},
              {{ therapySession.supervisingRPFirstName }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<mat-card appearance="raised" style="margin-top: 10px; padding: 0px">
  <nav
    mat-tab-nav-bar
    class="mat-elevation-z8"
    style="background-color: #e1f5fe"
    [tabPanel]="tabPanel"
  >
    <a
      *ngIf="
        isBPSAEnforced &&
        hasBPSAIntakeViewAccess &&
        therapySession?.sessionType !== 'Phone Note'
      "
      mat-tab-link
      (click)="changeSelectedTab('BPSA-INTAKE')"
      [active]="secondaryTab === 'BPSA-INTAKE'"
    >
      Intake
    </a>
    <a
      *ngIf="
        isBPSAEnforced &&
        hasBPSAClinicalViewAccess &&
        therapySession?.sessionType !== 'Phone Note'
      "
      mat-tab-link
      (click)="changeSelectedTab('BPSA-CLINICAL')"
      [active]="secondaryTab === 'BPSA-CLINICAL'"
    >
      Clinical
    </a>

    <a
      mat-tab-link
      (click)="changeSelectedTab('notedetails')"
      [active]="secondaryTab === 'notedetails'"
    >
      Note Details
    </a>

    <a
      *ngIf="
        hasClaimsFeature &&
        hasBillingViewAccess &&
        therapySession?.sessionType !== 'Phone Note'
      "
      mat-tab-link
      (click)="changeSelectedTab('BILLING')"
      [active]="secondaryTab === 'BILLING'"
    >
      Billing
    </a>
  </nav>

  <mat-tab-nav-panel #tabPanel>
    <app-bpsa-intake
      *ngIf="secondaryTab === 'BPSA-INTAKE'"
      [patient]="patient"
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="reloadTherapySession($event)"
      (triggerEvent)="triggerEvent($event)"
    >
    </app-bpsa-intake>

    <app-bpsa-clinical
      *ngIf="secondaryTab === 'BPSA-CLINICAL'"
      [therapySession]="therapySession"
      [patient]="patient"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="reloadTherapySession($event)"
    ></app-bpsa-clinical>

    <app-therapy
      *ngIf="secondaryTab === 'notedetails'"
      [patient]="patient"
      [loggedInUserId]="loggedInUserId"
      [therapySession]="therapySession"
      [isBPSAEnforced]="isBPSAEnforced"
      [hasTherapySessionAddAccess]="hasTherapySessionAddAccess"
      [hasTherapySessionEditAccess]="hasTherapySessionEditAccess"
      [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
      [hasMemberEditAccess]="hasMemberEditAccess"
      [hasASIFeatures]="hasASIFeatures"
      [hasClinicalNotesViewAccess]="hasClinicalNotesViewAccess"
      [hasClinicalNotesAddAccess]="hasClinicalNotesAddAccess"
      [hasClinicalNotesEditAccess]="hasClinicalNotesEditAccess"
      [hasSubjectivesViewAccess]="hasSubjectivesViewAccess"
      [hasSubjectivesEditAccess]="hasSubjectivesEditAccess"
      [hasObjectivesViewAccess]="hasObjectivesViewAccess"
      [hasObjectivesEditAccess]="hasObjectivesEditAccess"
      [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
      [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
      [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
      [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
      [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
      [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
      [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
      [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
      [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
      [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
      [hasGoalsViewAccess]="hasGoalsViewAccess"
      [hasGoalsAddAccess]="hasGoalsAddAccess"
      [hasGoalsEditAccess]="hasGoalsEditAccess"
      [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
      [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
      [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
      [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
      [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
      [hasMedicationViewAccess]="hasMedicationViewAccess"
      [hasMedicationAddAccess]="hasMedicationAddAccess"
      [hasMedicationEditAccess]="hasMedicationEditAccess"
      [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
      [prescriptionEnabled]="prescriptionEnabled"
      (output)="triggerEvent($event)"
    ></app-therapy>

    <app-session-documents
      *ngIf="secondaryTab === 'SESSION-DOCUMENTS'"
      [therapySession]="therapySession"
      [hasSessionDocumentsViewAccess]="hasSessionDocumentsViewAccess"
      [hasSessionDocumentsAddAccess]="hasSessionDocumentsAddAccess"
      [hasSessionDocumentsDeleteAccess]="hasSessionDocumentsDeleteAccess"
      [patient]="patient"
      [loggedInUserId]="loggedInUserId"
    ></app-session-documents>

    <app-session-billing
      *ngIf="secondaryTab === 'BILLING'"
      [patient]="patient"
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      parentClass="Session"
      [isBPSAEnforced]="isBPSAEnforced"
      [hasBillingViewAccess]="hasBillingViewAccess"
      [hasBillingEditAccess]="hasBillingEditAccess"
      (updatedTherapySession)="reloadBillingSession($event)"
    >
    </app-session-billing>
  </mat-tab-nav-panel>
</mat-card>
