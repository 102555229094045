import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ToastMessageService } from 'src/app/_services';
import { TreatmentPlansApiService } from 'src/app/_services/case/treatment/plan/treatmentplan.service';

@Component({
  selector: 'app-print-plan-treatment-report',
  templateUrl: './print-plan-treatment-report.component.html',
  styleUrl: './print-plan-treatment-report.component.css'
})
export class PrintPlanTreatmentReportComponent implements OnInit {
  @Input() selectedPlan: ITreatmentPlan;
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  displayNoRecordsMsg = false;
  processing: boolean = false;
  pdfSrc = null;
  public searchForm: FormGroup;
  constructor(
    public treatmentPlansApiService: TreatmentPlansApiService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit(): void {
    this.printReport();
  }
  printReport() {
    this.displayNoRecordsMsg = false;

    this.processing = true;
    this.treatmentPlansApiService.printReport(
      this.selectedPlan.caseId, this.selectedPlan.id
    )
      .subscribe(
        (res) => {
          this.processing = false;
          if (res) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = false;
          } else {
            this.pdfViewerOnDemand.pdfSrc = null;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = true;
          }
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong'
          );
        }
      );
  }
}
