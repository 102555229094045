<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>
      Eligibility Checks for {{ referralInformation?.patientLastName }},
      {{ referralInformation?.patientFirstName }}
    </p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Records -->
  <app-eligibility-records
    [displayTitle]="false"
    [sessionId]="referralInformation.id"
    [referralInformation]="referralInformation"
  ></app-eligibility-records>
</div>
