import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-attempt-details-view',
  templateUrl: './referral-attempt-details-view.component.html',
  styleUrl: './referral-attempt-details-view.component.css',
})
export class ReferralAttemptDetailsViewComponent implements OnInit {
  @Input() attemptNumber: string;
  @Input() currentTimezone: string;
  @Input() attemptDetails: any;

  constructor() {}

  ngOnInit(): void {}
}
