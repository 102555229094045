import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-member-claims',
  templateUrl: './member-claims.component.html',
  styleUrls: ['./member-claims.component.css'],
})
export class MemberClaimsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patient: Patient;
  @Input() hasBillingViewAccess: boolean;
  @Input() hasBillingEditAccess: boolean;
  @Input() hasClaimsViewAccess: boolean;
  @Input() hasClaimsEditAccess: boolean;
  @Input() reload: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription = new Subscription();

  processing: boolean = false;
  apiError: boolean = false;
  viewClaimHistory: boolean = false;
  currentTimezone: string;

  selectedSessionId: string = '';
  therapySession: ITherapySession;

  status = 'All Claims';
  currentTime = new Date();
  searchStartDate: Date;
  searchEndDate: Date;
  searchClaimCode = '';
  searchSessionCode: string = '';
  searchProviderName: string = '';

  displayedColumns = [
    'details',
    'payer',
    'sequence',
    'renderingProvider',
    'status',
    'totalCharge',
    'actions',
  ];
  claimSearchStatusList = [
    'All Claims',
    'In Draft',
    'On Hold',
    'Queued for Submission',
    'Processing',
    'Accepted',
    'Sent To Payer',
    'Rejected',
    'ERA Received',
    'Archived',
  ];
  data: DataTablesModel = {} as DataTablesModel;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    const reloadSubscription = this.reload.subscribe((reload) => {
      if (reload) {
        this.selectedSessionId = null;
        this.loadClaims();
      }
    });
    this.subscriptions.add(reloadSubscription);

    this.loadClaims();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearDate() {
    this.searchStartDate = null;
    this.searchEndDate = null;

    this.searchChanged();
  }

  searchChanged() {
    this.data.page = 0;
    this.loadClaims();
  }

  loadClaims() {
    this.processing = true;
    this.apiError = false;

    this.claimsApiService
      .getMemberClaims(
        this.patient.id,
        this.status,
        this.searchClaimCode,
        this.searchSessionCode,
        this.searchStartDate,
        this.searchEndDate,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the claims list.'
          );
          this.apiError = true;
          this.processing = false;
        },
      });
  }

  optionChanged(option) {
    this.status = option;
    this.data.page = 0;
    this.loadClaims();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadClaims();
  }

  viewClaim(claim) {
    // Navigating to Primary Claim
    if (claim.billingSequence === 'P') {
      this.router.navigate([`/claims/details/${claim.id}`], {
        queryParams: {
          parent: 'member',
        },
      });
    } else if (claim.billingSequence === 'S') {
      // Navigating to Secondary Claim using primaryclaimid
      this.router.navigate([`/claims/secondary/${claim.primaryClaimId}`], {
        queryParams: {
          parent: 'member',
        },
      });
    }
  }

  fileOtherClaim(claim) {
    this.router.navigate([`/claims/secondary/${claim.id}`], {
      queryParams: {
        parent: 'member',
      },
    });
  }

  unarchiveClaim(claim) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'UnArchive Claim',
        msg: 'Are you sure that you want to unarchive claim?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;
        if (claim.billingSequence === 'P') {
          this.claimsApiService
            .unArchievePrimaryClaim(
              claim.therapySession.id,
              claim.therapySession.patientId
            )
            .subscribe({
              next: (response) => {
                this.toastMessageService.displaySuccessMessage(
                  'Successfully unarchived the claim'
                );
                this.processing = false;
                this.loadClaims();
              },
              error: (error) => {
                this.processing = false;
                this.toastMessageService.displayErrorMessage(
                  'Error: Failed to unarchive the claim'
                );
              },
            });
        }
      }
    });
  }

  viewHistory(claim) {
    // Navigating to Primary Claim
    if (claim.billingSequence === 'P') {
      this.router.navigate([`/claims/history/${claim.id}`], {
        queryParams: {
          parent: 'member',
        },
      });
    } else if (claim.billingSequence === 'S') {
      this.router.navigate([`/claims/secondaryhistory/${claim.id}`], {
        queryParams: {
          parent: 'member',
        },
      });
    }
  }

  viewPaymentHistory(row) {
    this.router.navigate([`/remittances/remit/paymenthistory/${row.id}`], {
      queryParams: {
        parent: 'member',
      },
    });
  }
}
