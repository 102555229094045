import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SurveyModule } from 'survey-angular-ui';
import { IntakeHeaderComponent } from '../../patient-details/patient-components/sessions/bpsa/bpsa-intake/intake-header/intake-header.component';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { MemberGoalsComponent } from './member-goals/member-goals.component';
import { AddEditMemberGoalComponent } from './member-goals/add-edit-member-goal/add-edit-member-goal.component';

@NgModule({
  declarations: [MemberGoalsComponent, AddEditMemberGoalComponent],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgProgressModule,
    PipesModule,
    RxReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEditorModule,
    IntakeHeaderComponent,
    CustomDirectivesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    AngularMaterialModule,
    SurveyModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [MemberGoalsComponent, AddEditMemberGoalComponent],
})
export class MemberGoalsModule {}
