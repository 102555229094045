import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPostnatalDepression } from 'src/app/_models/assessments/depression/postnataldepression.model';
import {
  PostnatalDepressionApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-postnatal-depression',
  templateUrl: './postnatal-depression.component.html',
  styleUrls: ['./postnatal-depression.component.css'],
})
export class PostnatalDepressionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public postnatalDepressionApiService: PostnatalDepressionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public postnatalDepressionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  postnatalDepressionAssessment: IPostnatalDepression;
  postnatalDepressionAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.postnatalDepressionForm, this.errors);

    this.loadPostnatalDepressionAssessment();
  }

  loadPostnatalDepressionAssessment() {
    this.postnatalDepressionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.postnatalDepressionApiService
        .getPostnatalDepressionDetails(this.assessmentId)
        .subscribe((postnatalDepressionAssessment) => {
          if (postnatalDepressionAssessment.data) {
            this.postnatalDepressionForm.controls['funnySide'].setValue(
              postnatalDepressionAssessment.data.funnySide.answer
            );
            this.postnatalDepressionForm.controls['enjoyment'].setValue(
              postnatalDepressionAssessment.data.enjoyment.answer
            );
            this.postnatalDepressionForm.controls['blamedMyself'].setValue(
              postnatalDepressionAssessment.data.blamedMyself.answer
            );
            this.postnatalDepressionForm.controls['noGoodReason'].setValue(
              postnatalDepressionAssessment.data.noGoodReason.answer
            );
            this.postnatalDepressionForm.controls['feelingScared'].setValue(
              postnatalDepressionAssessment.data.feelingScared.answer
            );
            this.postnatalDepressionForm.controls['top'].setValue(
              postnatalDepressionAssessment.data.top.answer
            );
            this.postnatalDepressionForm.controls[
              'difficultySleeping'
            ].setValue(
              postnatalDepressionAssessment.data.difficultySleeping.answer
            );
            this.postnatalDepressionForm.controls['sadness'].setValue(
              postnatalDepressionAssessment.data.sadness.answer
            );
            this.postnatalDepressionForm.controls['unhappy'].setValue(
              postnatalDepressionAssessment.data.unhappy.answer
            );
            this.postnatalDepressionForm.controls['harmingMyself'].setValue(
              postnatalDepressionAssessment.data.harmingMyself.answer
            );

            this.riskScore = postnatalDepressionAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.postnatalDepressionForm.invalid) {
      this.postnatalDepressionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.postnatalDepressionAssessment = Object.assign(
      {},
      this.postnatalDepressionAssessment
    );
    this.postnatalDepressionAssessment.patientId =
      this.postnatalDepressionForm.controls['patientId'].value;
    this.postnatalDepressionAssessment.sessionId =
      this.postnatalDepressionForm.controls['sessionId'].value;
    this.postnatalDepressionAssessment.assessmentRequestId =
      this.postnatalDepressionForm.controls['assessmentRequestId'].value;
    this.postnatalDepressionAssessment.funnySide = {
      answer: this.postnatalDepressionForm.controls['funnySide'].value,
    };
    this.postnatalDepressionAssessment.enjoyment = {
      answer: this.postnatalDepressionForm.controls['enjoyment'].value,
    };
    this.postnatalDepressionAssessment.blamedMyself = {
      answer: this.postnatalDepressionForm.controls['blamedMyself'].value,
    };
    this.postnatalDepressionAssessment.noGoodReason = {
      answer: this.postnatalDepressionForm.controls['noGoodReason'].value,
    };
    this.postnatalDepressionAssessment.feelingScared = {
      answer: this.postnatalDepressionForm.controls['feelingScared'].value,
    };
    this.postnatalDepressionAssessment.top = {
      answer: this.postnatalDepressionForm.controls['top'].value,
    };
    this.postnatalDepressionAssessment.difficultySleeping = {
      answer: this.postnatalDepressionForm.controls['difficultySleeping'].value,
    };
    this.postnatalDepressionAssessment.sadness = {
      answer: this.postnatalDepressionForm.controls['sadness'].value,
    };
    this.postnatalDepressionAssessment.unhappy = {
      answer: this.postnatalDepressionForm.controls['unhappy'].value,
    };
    this.postnatalDepressionAssessment.harmingMyself = {
      answer: this.postnatalDepressionForm.controls['harmingMyself'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.postnatalDepressionAssessment.accessCode = this.accessCode;

      this.postnatalDepressionApiService
        .addGuestPostnatalDepression(this.postnatalDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Postnatal Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Postnatal Depression Assessment'
            );
          }
        );
    } else {
      this.postnatalDepressionApiService
        .addPostnatalDepression(this.postnatalDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Postnatal Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Postnatal Depression Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    funnySide: [
      {
        type: 'required',
        message:
          'I have been able to laugh and see the funny side of things is required',
      },
    ],
    enjoyment: [
      {
        type: 'required',
        message: 'I have looked forward with enjoyment to things is required',
      },
    ],
    blamedMyself: [
      {
        type: 'required',
        message:
          'I have blamed myself unnecessarily when things went wrong is required',
      },
    ],
    noGoodReason: [
      {
        type: 'required',
        message:
          'I have been anxious or worried for no good reason is required',
      },
    ],
    feelingScared: [
      {
        type: 'required',
        message:
          'I have felt scared or panicky for no very good reason is required',
      },
    ],
    top: [
      {
        type: 'required',
        message: 'Things have been getting on top of me is required',
      },
    ],
    difficultySleeping: [
      {
        type: 'required',
        message:
          'I have been so unhappy that I have had difficulty sleeping is required',
      },
    ],
    sadness: [
      { type: 'required', message: 'I have felt sad or miserable is required' },
    ],
    unhappy: [
      {
        type: 'required',
        message: 'I have been so unhappy that I have been crying. is required',
      },
    ],
    harmingMyself: [
      {
        type: 'required',
        message: 'The thought of harming myself has occurred to me is required',
      },
    ],
  };

  private buildForm() {
    this.postnatalDepressionForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      funnySide: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      enjoyment: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      blamedMyself: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      noGoodReason: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelingScared: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      top: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      difficultySleeping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sadness: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      unhappy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      harmingMyself: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.postnatalDepressionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.postnatalDepressionForm) {
      return;
    }
    const form = this.postnatalDepressionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
