<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Referral</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Referral</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="referralForm">
    <div class="row">
      <!-- Referral Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">keyboard_alt</mat-icon>
              Referral Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Facility -->
            <div class="col-md-8">
              <label class="labelBold">
                Facility <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="facilityId"
                  placeholder="Facility"
                  (selectionChange)="faclityChanged()"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Facility Name"
                      noEntriesFoundLabel="No matching facility found"
                      [(ngModel)]="filterFacilityName"
                      (ngModelChange)="filterFacility()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let facility of filteredFacilities"
                    [value]="facility.id"
                  >
                    {{ facility.facilityName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="referralForm.controls['facilityId'].invalid">
                  Please select the facility
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Date Received -->
            <div class="col-md-4">
              <label class="labelBold">
                Date Received <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="dateReceived"
                  matInput
                  [matDatepicker]="dateReceived"
                  placeholder="MM/DD/YYYY"
                  [max]="maxDateReceived"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dateReceived"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateReceived></mat-datepicker>
                <mat-error
                  *ngIf="referralForm.controls['dateReceived'].invalid"
                >
                  Please enter or select a valid date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Services -->
            <div class="col-md-8">
              <label class="labelBold">
                Services Requested <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="servicesRequested"
                  placeholder="Services"
                  multiple
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Service Name"
                      noEntriesFoundLabel="No matching service found"
                      [(ngModel)]="searchService"
                      (ngModelChange)="searchServiceChanged()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let service of filteredServicesList"
                    [value]="service.serviceName"
                  >
                    {{ service.serviceName }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['servicesRequested'].invalid"
                >
                  Please select the services requested
                </mat-error>
              </mat-form-field>
            </div>

            <!-- On Stimulant -->
            <div class="col-md-4">
              <label class="labelBold"> On Stimulant </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="onStimulant">
                  <mat-option
                    *ngFor="let stimulant of stimulantList"
                    [value]="stimulant"
                  >
                    {{ stimulant }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="referralForm.controls['onStimulant'].invalid">
                  Please select if on stimulant
                </mat-error>
              </mat-form-field>
            </div>

            <!--  Referring Provider -->
            <div class="col-md-8">
              <label
                class="labelBold"
                style="display: flex; align-items: center; gap: 5px"
              >
                Referring Provider

                <mat-icon
                  matTooltip="Add New Referring Provider"
                  style="cursor: pointer"
                  color="warn"
                  (click)="addReferringProvider()"
                >
                  add_circle
                </mat-icon>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="referringProviderId"
                  (selectionChange)="referringProviderChanged()"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Name"
                      noEntriesFoundLabel="No matching user found"
                      [(ngModel)]="searchReferringProvider"
                      (ngModelChange)="loadReferringProviders()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option value=""> </mat-option>
                  <mat-option
                    *ngFor="let provider of referringProviderList?.items"
                    [value]="provider.id"
                  >
                    {{ provider.lastName }}, {{ provider.firstName }}
                    <span *ngIf="provider.emailAddress"> | </span>
                    <span
                      *ngIf="provider.emailAddress"
                      style="font-style: italic"
                    >
                      {{ provider.emailAddress }}
                    </span>
                    <span *ngIf="provider.phoneNumber"> | </span>
                    <span
                      *ngIf="provider.phoneNumber"
                      style="font-style: italic"
                    >
                      {{ provider.phoneNumber | phoneFormat }}
                    </span>
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['referringProviderId'].invalid"
                >
                  Please select the referring provider
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Referral Method -->
            <div class="col-md-4">
              <label class="labelBold"> Referral Method </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="referralMethod">
                  <mat-option
                    *ngFor="let method of referralMethodsList"
                    [value]="method"
                  >
                    {{ method }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['referralMethod'].invalid"
                >
                  Please select referral method
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Referral Status -->
            <div class="col-md-12">
              <label class="labelBold">
                Status <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="status" placeholder="Status">
                  <mat-option
                    *ngFor="let status of refferralStatusList"
                    [value]="status"
                  >
                    {{ status }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="referralForm.controls['status'].invalid">
                  Please select the current status
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Referral Notes -->
            <div class="col-md-12">
              <label class="labelBold"> Notes About Referral</label>
              <mat-form-field appearance="outline">
                <textarea
                  rows="3"
                  matInput
                  formControlName="referralNote"
                  spellcheck="true"
                  cdkTextareaAutosize
                ></textarea>
                <mat-error
                  *ngIf="referralForm.controls['referralNote'].invalid"
                >
                  Please add referral note
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Patient Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">person</mat-icon>
              Patient Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- First Name -->
            <div class="col-md-4">
              <label class="labelBold">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="patientFirstName"
                  required
                />
                <mat-error
                  *ngIf="referralForm.controls['patientFirstName'].invalid"
                >
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Middle Name -->
            <div class="col-md-4">
              <label class="labelBold"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientMiddleName"
                  name="middleName"
                />
              </mat-form-field>
            </div>
            <!-- Last Name -->
            <div class="col-md-4">
              <label class="labelBold">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="patientLastName"
                  required
                />
                <mat-error
                  *ngIf="referralForm.controls['patientLastName'].invalid"
                >
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <!-- DOB -->
            <div class="col-md-4">
              <label class="labelBold"> Date of Birth </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="patientDateOfBirth"
                  matInput
                  [matDatepicker]="dobDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error
                  *ngIf="referralForm.controls['patientDateOfBirth'].invalid"
                >
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Gender -->
            <div class="col-md-4">
              <label class="labelBold">
                Gender <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="patientGender"
                  placeholder="Gender"
                >
                  <mat-option [value]="gen.id" *ngFor="let gen of genderList">
                    {{ gen.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['patientGender'].invalid"
                >
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Pronoun -->
            <div class="col-md-4">
              <label class="labelText"> Pronouns </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientPronouns"
                  name="pronouns"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Patient Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">contact_phone</mat-icon>
              Patient Contact Information
            </label>
          </div>
        </div>

        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Email -->
            <div class="col-md-8">
              <label class="labelBold">Email </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Email"
                  formControlName="patientEmail"
                  name="email"
                />
                <mat-error
                  *ngIf="referralForm.controls['patientEmail'].invalid"
                >
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Phone Preference -->
            <div class="col-md-4">
              <label class="labelText"> Phone Preference </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="patientPhonePreference"
                  placeholder="Phone Prefence"
                >
                  <mat-option value="Cell Phone">Cell Phone</mat-option>
                  <mat-option value="Home Phone">Home Phone</mat-option>
                  <mat-option value="Work Phone">Work Phone</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    referralForm.controls['patientPhonePreference'].invalid
                  "
                >
                  Please select the phone preference
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Cell Phone  -->
            <div class="col-md-4">
              <label class="labelText"> Cell Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientPhoneNumber"
                  placeholder="(999) 999-9999"
                />
                <mat-error
                  *ngIf="referralForm.controls['patientPhoneNumber'].invalid"
                >
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Home Phone  -->
            <div class="col-md-4">
              <label class="labelText"> Home Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientHouseNumber"
                  name="patientHouseNumber"
                />
                <mat-error
                  *ngIf="referralForm.controls['patientHouseNumber'].invalid"
                >
                  Please enter a valid home number
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Work Phone  -->
            <div class="col-md-4">
              <label class="labelText"> Work Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientWorkNumber"
                  name="patientWorkNumber"
                />
                <mat-error
                  *ngIf="referralForm.controls['patientWorkNumber'].invalid"
                >
                  Please enter a valid work number
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Contact Notes -->
            <div class="col-md-12">
              <label class="labelBold"> Contact Notes </label>
              <mat-form-field appearance="outline">
                <textarea
                  rows="3"
                  matInput
                  formControlName="patientContactNotes"
                  spellcheck="true"
                  cdkTextareaAutosize
                ></textarea>
                <mat-error
                  *ngIf="referralForm.controls['patientContactNotes'].invalid"
                >
                  Please add contact note
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Patient Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">home</mat-icon>
              Patient Address Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelBold"> Address 1 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  formControlName="patientAddressLine1"
                  name="addressLine1"
                />

                <mat-error
                  *ngIf="referralForm.controls['patientAddressLine1'].invalid"
                >
                  Please enter the address line 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelBold">Address 2</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientAddressLine2"
                  name="patientAddressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelBold"> City </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="patientCity"
                  name="patientCity"
                />
                <mat-error *ngIf="referralForm.controls['patientCity'].invalid">
                  Please enter the city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelBold"> State </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="patientState">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['patientState'].invalid"
                >
                  Please select the state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelBold"> Zip </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="patientZip" name="zip" />
                <mat-error *ngIf="referralForm.controls['patientZip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Patient Insurance Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">
                health_and_safety
              </mat-icon>
              Patient Primary Insurance Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Insurance Type -->
            <div class="col-md-6" style="margin-bottom: 12px">
              <label class="labelText"> Member relationship to insured </label>
              <br />
              <mat-radio-group
                aria-label="Select an option"
                formControlName="patientInsuranceRelationship"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
              >
                <mat-radio-button
                  *ngFor="let r of insuredRelationshipList"
                  [value]="r.id"
                  >{{ r.description }}</mat-radio-button
                >
              </mat-radio-group>
            </div>

            <!-- Member Id -->
            <div class="col-md-6">
              <label class="labelText"> Member ID </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="insuranceMemberId"
                  name="memberId"
                />
                <mat-error
                  *ngIf="referralForm.controls['insuranceMemberId'].invalid"
                >
                  Please enter the Member ID
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Insurance Payer -->
            <div class="col-md-6">
              <label class="labelText"> Insurance payer </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="patientInsurancePayorId"
                  (selectionChange)="insuranceSelected()"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Payer Name"
                      noEntriesFoundLabel="No matching payer found"
                      [(ngModel)]="searchPayer"
                      (ngModelChange)="searchByPayerChanged()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let payer of insuranceList.items"
                    [value]="payer.id"
                  >
                    {{ payer.payerName }}
                    <span *ngIf="payer.claimPayerCode">
                      ({{ payer.claimPayerCode }})
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Insurance Payer Type -->
            <div class="col-md-6">
              <label class="labelText"> Type of Health Insurance </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="healthInsuranceType">
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let insuranceType of healthInsuranceTypeList"
                    [value]="insuranceType.id"
                  >
                    {{ insuranceType.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Subscriber First Name -->
            <div
              class="col-md-6"
              *ngIf="
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '' &&
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '18'
              "
            >
              <label class="labelText"> Subscriber First Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="subscriberFirstName"
                  name="subscriberFirstName"
                />
                <mat-error
                  *ngIf="referralForm.controls['subscriberFirstName'].invalid"
                >
                  Please enter the Subscriber First Name
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Subscriber Last Name -->
            <div
              class="col-md-6"
              *ngIf="
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '' &&
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '18'
              "
            >
              <label class="labelText"> Subscriber Last Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="subscriberLastName"
                  name="subscriberLastName"
                />
                <mat-error
                  *ngIf="referralForm.controls['subscriberLastName'].invalid"
                >
                  Please enter the Subscriber Last Name
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Subscriber DOB -->
            <div
              class="col-md-6"
              *ngIf="
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '' &&
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '18'
              "
            >
              <label class="labelText"> Subscriber Date Of Birth </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="subscriberDOB"
                  matInput
                  [matDatepicker]="subscriberDOBPicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="subscriberDOBPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #subscriberDOBPicker></mat-datepicker>
                <mat-error
                  *ngIf="referralForm.controls['subscriberDOB'].invalid"
                >
                  Please enter a valid subscriber DOB
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Subscriber Gender -->
            <div
              class="col-md-6"
              *ngIf="
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '' &&
                referralForm.controls['patientInsuranceRelationship'].value !=
                  '18'
              "
            >
              <label class="labelText"> Subscriber Gender </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="subscriberGender">
                  <mat-option [value]="r.id" *ngFor="let r of genderList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="referralForm.controls['subscriberGender'].invalid"
                >
                  Please select a valid gender
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Appointment Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px"> calendar_month </mat-icon>
              Appointment Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Appointment Date -->
            <div class="col-md-4">
              <label class="labelText"> Appointment Date and Time</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="appointmentDate"
                  matInput
                  [owlDateTime]="dt1"
                  [owlDateTimeTrigger]="dt1"
                  placeholder="Date Time"
                />
                <owl-date-time #dt1></owl-date-time>
                <mat-error
                  *ngIf="referralForm.controls['appointmentDate'].invalid"
                >
                  Please enter a valid appointment Date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Appointment With -->
            <div class="col-md-8">
              <label class="labelBold"> Appointment With </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="appointmentWithUserId"
                  (selectionChange)="appointmentProviderSelected()"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Name"
                      noEntriesFoundLabel="No matching user found"
                      [(ngModel)]="userName"
                      (ngModelChange)="filterAllActiveCaseManagers()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let user of filteredCareProvidersList"
                    [value]="user.id"
                  >
                    {{ user.lastName }}, {{ user.firstName }}
                    <span *ngIf="user.emailAddress"> | </span>
                    <span *ngIf="user.emailAddress" style="font-style: italic">
                      {{ user.emailAddress }}
                    </span>
                    <span *ngIf="user.phoneNumber"> | </span>
                    <span *ngIf="user.phoneNumber" style="font-style: italic">
                      {{ user.phoneNumber | phoneFormat }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Appointment Notes -->
            <div class="col-md-12">
              <label class="labelBold">Appointment Notes</label>
              <mat-form-field appearance="outline">
                <textarea
                  rows="3"
                  matInput
                  formControlName="appointmentNotes"
                  spellcheck="true"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Attempt Details -->
      <div
        class="col-md-12"
        style="margin-bottom: 12px"
        *ngIf="action === 'ADD'"
      >
        <!-- Header -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px"> calendar_month </mat-icon>
              Attempt Information
            </label>
          </div>
        </div>

        <!-- Information -->
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <!-- Action buttons  -->
            <div
              class="col-md-12"
              style="display: flex; justify-content: right; gap: 15px"
            >
              <!-- Add -->
              <button
                *ngIf="!referralForm.controls['firstAttempt'].value"
                mat-raised-button
                type="button"
                color="warn"
                (click)="addAttempt()"
              >
                + Add Attempt
              </button>
              <!-- Remove -->
              <button
                *ngIf="referralForm.controls['firstAttempt'].value"
                mat-raised-button
                type="button"
                (click)="removeAttempt()"
              >
                Remove Attempt
              </button>

              <!-- Edit -->
              <button
                *ngIf="referralForm.controls['firstAttempt'].value"
                mat-raised-button
                type="button"
                color="warn"
                (click)="editAttempt()"
              >
                Edit Attempt
              </button>
            </div>

            <!-- Attempt Details -->
            <div
              class="col-md-12"
              *ngIf="referralForm.controls['firstAttempt'].value"
            >
              <!-- Two Column Grid -->
              <div class="row">
                <!-- Attempt Date and Time  -->
                <div class="col-md-4 col-xl-3">
                  <label class="labelBold"> Attempt Date and Time </label>
                </div>
                <div class="col-md-8 col-xl-9">
                  <label>
                    {{
                      referralForm.controls['firstAttempt'].value
                        .attemptDateAndTime
                        | date : 'MM/dd/y HH:MM a' : currentTimezone
                    }}
                    {{ currentTimezone }}
                  </label>
                </div>
                <!-- Attempt Result -->
                <div class="col-md-4 col-xl-3">
                  <label class="labelBold"> Attempt Result </label>
                </div>
                <div class="col-md-8 col-xl-9">
                  <label>
                    {{
                      referralForm.controls['firstAttempt'].value.attemptResult
                    }}
                  </label>
                </div>
                <!-- Recorded By -->
                <div class="col-md-4 col-xl-3">
                  <label class="labelBold"> Recorded By </label>
                </div>
                <div class="col-md-8 col-xl-9">
                  <label>
                    {{
                      referralForm.controls['firstAttempt'].value
                        .recordedByUserDetails?.lastName
                    }},
                    {{
                      referralForm.controls['firstAttempt'].value
                        .recordedByUserDetails?.firstName
                    }}
                  </label>
                </div>
                <!-- Attempt Note -->
                <div class="col-md-4 col-xl-3">
                  <label class="labelBold"> Attempt Note </label>
                </div>
                <div class="col-md-8 col-xl-9">
                  <label>
                    {{
                      referralForm.controls['firstAttempt'].value.attemptNote
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Submit -->
      <div
        class="col-md-12"
        style="margin-top: 15px; margin-bottom: 30px; text-align: right"
      >
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="referralForm.invalid || processing"
          (click)="submitReferral()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
