<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Summary</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="summaryForm" novalidate (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            cdkTextareaAutosize
            placeholder="Please add the Assessment Summary here..."
            formControlName="summary"
            rows="8"
            name="summary"
            [readonly]="!hasAssessmentSummaryEditAccess"
          ></textarea>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="hasAssessmentSummaryEditAccess"
      >
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!summaryForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
