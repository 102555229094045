import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ICaseTreatmentAdmissionDiagnosis } from 'src/app/_models/case/treatment/admission/casetreatmentadmissiondiagnosis.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CaseTreatmentAdmissionDiagnosisApiService {
  constructor(private httpClient: HttpClient) {}

  getCaseTreatmentAdmissionDiagnosisDetails(
    caseId: string,
    planId: string
  ): Observable<ResponseModal> {
    if (planId) {
      return this.httpClient.get<ResponseModal>(
        `${environment.apiUrl}/casetreatmentadmissiondiagnosisbyplanid/` +
          planId
      );
    } else {
      return this.httpClient.get<ResponseModal>(
        `${environment.apiUrl}/casetreatmentadmissiondiagnosis/` + caseId
      );
    }
  }

  updateCaseTreatmentAdmissionDiagnosis(
    thisCaseTreatmentAdmissionDiagnosis: ICaseTreatmentAdmissionDiagnosis
  ): Observable<ICaseTreatmentAdmissionDiagnosis> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ICaseTreatmentAdmissionDiagnosis>(
        `${environment.apiUrl}/casetreatmentadmissiondiagnosis`,
        thisCaseTreatmentAdmissionDiagnosis,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
