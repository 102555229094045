import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IZungSelfRatingScale } from 'src/app/_models/assessments/anxiety/zungselfratingscale.model';
import {
  ToastMessageService,
  ZungSelfRatingScaleApiService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-zung-self-rating-scale-dialog',
  templateUrl: './zung-self-rating-scale-dialog.component.html',
  styleUrls: ['./zung-self-rating-scale-dialog.component.css'],
})
export class ZungSelfRatingScaleDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public zungselfratingscaleApiService: ZungSelfRatingScaleApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }

  public riskScore: number;
  public action: string;
  public zungselfratingscaleForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  zungselfratingscale: IZungSelfRatingScale;
  zungselfratingscaleError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.zungselfratingscaleForm, this.errors);

    this.loadZungSelfRatingScale();
  }

  loadZungSelfRatingScale() {
    this.zungselfratingscaleForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.zungselfratingscaleApiService
        .getZungSelfRatingScaleDetails(this.assessmentId)
        .subscribe((zungselfratingscale) => {
          if (zungselfratingscale.data) {
            this.zungselfratingscaleForm.controls['id'].setValue(
              zungselfratingscale.data.id
            );
            this.zungselfratingscaleForm.controls['organizationId'].setValue(
              zungselfratingscale.data.organizationId
            );
            this.zungselfratingscaleForm.controls['patientId'].setValue(
              zungselfratingscale.data.patientId
            );
            this.zungselfratingscaleForm.controls[
              'feelNervousAndAnxious'
            ].setValue(zungselfratingscale.data.feelNervousAndAnxious.answer);
            this.zungselfratingscaleForm.controls['feelAfraid'].setValue(
              zungselfratingscale.data.feelAfraid.answer
            );
            this.zungselfratingscaleForm.controls['getUpsetEasily'].setValue(
              zungselfratingscale.data.getUpsetEasily.answer
            );
            this.zungselfratingscaleForm.controls[
              'feelLikeFallingApart'
            ].setValue(zungselfratingscale.data.feelLikeFallingApart.answer);
            this.zungselfratingscaleForm.controls['everythingIsOkay'].setValue(
              zungselfratingscale.data.everythingIsOkay.answer
            );
            this.zungselfratingscaleForm.controls['armsAndLegsShake'].setValue(
              zungselfratingscale.data.armsAndLegsShake.answer
            );
            this.zungselfratingscaleForm.controls[
              'botheredByHeadachesNeckBackPains'
            ].setValue(
              zungselfratingscale.data.botheredByHeadachesNeckBackPains.answer
            );
            this.zungselfratingscaleForm.controls['feelWeakAndTired'].setValue(
              zungselfratingscale.data.feelWeakAndTired.answer
            );
            this.zungselfratingscaleForm.controls[
              'feelCalmAndCanSitStill'
            ].setValue(zungselfratingscale.data.feelCalmAndCanSitStill.answer);
            this.zungselfratingscaleForm.controls[
              'feelHeartBeatingFast'
            ].setValue(zungselfratingscale.data.feelHeartBeatingFast.answer);
            this.zungselfratingscaleForm.controls[
              'botheredByDizzySpells'
            ].setValue(zungselfratingscale.data.botheredByDizzySpells.answer);
            this.zungselfratingscaleForm.controls['feelFaint'].setValue(
              zungselfratingscale.data.feelFaint.answer
            );
            this.zungselfratingscaleForm.controls['canBreatheEasily'].setValue(
              zungselfratingscale.data.canBreatheEasily.answer
            );
            this.zungselfratingscaleForm.controls[
              'feelNumbnessAndTingling'
            ].setValue(zungselfratingscale.data.feelNumbnessAndTingling.answer);
            this.zungselfratingscaleForm.controls[
              'botheredByStomachAchesOrIngestion'
            ].setValue(
              zungselfratingscale.data.botheredByStomachAchesOrIngestion.answer
            );
            this.zungselfratingscaleForm.controls[
              'haveToEmptyBladderOften'
            ].setValue(zungselfratingscale.data.haveToEmptyBladderOften.answer);
            this.zungselfratingscaleForm.controls[
              'handsAreDryAndWarm'
            ].setValue(zungselfratingscale.data.handsAreDryAndWarm.answer);
            this.zungselfratingscaleForm.controls[
              'faceGetsHotAndBlushes'
            ].setValue(zungselfratingscale.data.faceGetsHotAndBlushes.answer);
            this.zungselfratingscaleForm.controls['fallAsleepEasily'].setValue(
              zungselfratingscale.data.fallAsleepEasily.answer
            );
            this.zungselfratingscaleForm.controls['haveNightmares'].setValue(
              zungselfratingscale.data.haveNightmares.answer
            );
            this.riskScore = zungselfratingscale.data.riskScore;
          }
        });
    }
  }
  onZungSelfRatingScaleFormSubmit() {
    if (this.zungselfratingscaleForm.invalid) {
      this.zungselfratingscaleForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.zungselfratingscale = Object.assign({}, this.zungselfratingscale);
    this.zungselfratingscale.patientId =
      this.zungselfratingscaleForm.controls['patientId'].value;
    this.zungselfratingscale.sessionId =
      this.zungselfratingscaleForm.controls['sessionId'].value;
    this.zungselfratingscale.assessmentRequestId =
      this.zungselfratingscaleForm.controls['assessmentRequestId'].value;
    this.zungselfratingscale.feelNervousAndAnxious = {
      answer:
        this.zungselfratingscaleForm.controls['feelNervousAndAnxious'].value,
    };
    this.zungselfratingscale.feelAfraid = {
      answer: this.zungselfratingscaleForm.controls['feelAfraid'].value,
    };
    this.zungselfratingscale.getUpsetEasily = {
      answer: this.zungselfratingscaleForm.controls['getUpsetEasily'].value,
    };
    this.zungselfratingscale.feelLikeFallingApart = {
      answer:
        this.zungselfratingscaleForm.controls['feelLikeFallingApart'].value,
    };
    this.zungselfratingscale.everythingIsOkay = {
      answer: this.zungselfratingscaleForm.controls['everythingIsOkay'].value,
    };
    this.zungselfratingscale.armsAndLegsShake = {
      answer: this.zungselfratingscaleForm.controls['armsAndLegsShake'].value,
    };
    this.zungselfratingscale.botheredByHeadachesNeckBackPains = {
      answer:
        this.zungselfratingscaleForm.controls[
          'botheredByHeadachesNeckBackPains'
        ].value,
    };
    this.zungselfratingscale.feelWeakAndTired = {
      answer: this.zungselfratingscaleForm.controls['feelWeakAndTired'].value,
    };
    this.zungselfratingscale.feelCalmAndCanSitStill = {
      answer:
        this.zungselfratingscaleForm.controls['feelCalmAndCanSitStill'].value,
    };
    this.zungselfratingscale.feelHeartBeatingFast = {
      answer:
        this.zungselfratingscaleForm.controls['feelHeartBeatingFast'].value,
    };
    this.zungselfratingscale.botheredByDizzySpells = {
      answer:
        this.zungselfratingscaleForm.controls['botheredByDizzySpells'].value,
    };
    this.zungselfratingscale.feelFaint = {
      answer: this.zungselfratingscaleForm.controls['feelFaint'].value,
    };
    this.zungselfratingscale.canBreatheEasily = {
      answer: this.zungselfratingscaleForm.controls['canBreatheEasily'].value,
    };
    this.zungselfratingscale.feelNumbnessAndTingling = {
      answer:
        this.zungselfratingscaleForm.controls['feelNumbnessAndTingling'].value,
    };
    this.zungselfratingscale.botheredByStomachAchesOrIngestion = {
      answer:
        this.zungselfratingscaleForm.controls[
          'botheredByStomachAchesOrIngestion'
        ].value,
    };
    this.zungselfratingscale.haveToEmptyBladderOften = {
      answer:
        this.zungselfratingscaleForm.controls['haveToEmptyBladderOften'].value,
    };
    this.zungselfratingscale.handsAreDryAndWarm = {
      answer: this.zungselfratingscaleForm.controls['handsAreDryAndWarm'].value,
    };
    this.zungselfratingscale.faceGetsHotAndBlushes = {
      answer:
        this.zungselfratingscaleForm.controls['faceGetsHotAndBlushes'].value,
    };
    this.zungselfratingscale.fallAsleepEasily = {
      answer: this.zungselfratingscaleForm.controls['fallAsleepEasily'].value,
    };
    this.zungselfratingscale.haveNightmares = {
      answer: this.zungselfratingscaleForm.controls['haveNightmares'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.zungselfratingscale.accessCode = this.accessCode;

      this.zungselfratingscaleApiService
        .addGuestZungSelfRatingScale(this.zungselfratingscale)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Geriatric Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Geriatric Depression Assessment'
            );
          }
        );
    } else {
      this.zungselfratingscaleApiService
        .addZungSelfRatingScale(this.zungselfratingscale)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Geriatric Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Geriatric Depression Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    id: [{ type: 'required', message: 'Id is required' }],
    organizationId: [
      { type: 'required', message: 'Organization Id is required' },
    ],
    patientId: [{ type: 'required', message: 'Member Id is required' }],
    feelNervousAndAnxious: [
      { type: 'required', message: 'Feel Nervous And Anxious is required' },
    ],
    feelAfraid: [{ type: 'required', message: 'Feel Afraid is required' }],
    getUpsetEasily: [
      { type: 'required', message: 'Get Upset Easily is required' },
    ],
    feelLikeFallingApart: [
      { type: 'required', message: 'Feel Like Falling Apart is required' },
    ],
    everythingIsOkay: [
      { type: 'required', message: 'Everything Is Okay is required' },
    ],
    armsAndLegsShake: [
      { type: 'required', message: 'Arms And Legs Shake is required' },
    ],
    botheredByHeadachesNeckBackPains: [
      {
        type: 'required',
        message: 'Bothered By Headaches Neck Back Pains is required',
      },
    ],
    feelWeakAndTired: [
      { type: 'required', message: 'Feel Weak And Tired is required' },
    ],
    feelCalmAndCanSitStill: [
      { type: 'required', message: 'Feel Calm And Can Sit Still is required' },
    ],
    feelHeartBeatingFast: [
      { type: 'required', message: 'Feel Heart Beating Fast is required' },
    ],
    botheredByDizzySpells: [
      { type: 'required', message: 'Bothered By Dizzy Spells is required' },
    ],
    feelFaint: [{ type: 'required', message: 'Feel Faint is required' }],
    canBreatheEasily: [
      { type: 'required', message: 'Can Breathe Easily is required' },
    ],
    feelNumbnessAndTingling: [
      { type: 'required', message: 'Feel Numbness And Tingling is required' },
    ],
    botheredByStomachAchesOrIngestion: [
      {
        type: 'required',
        message: 'Bothered By Stomach Aches Or Ingestion is required',
      },
    ],
    haveToEmptyBladderOften: [
      { type: 'required', message: 'Have To Empty Bladder Often is required' },
    ],
    handsAreDryAndWarm: [
      { type: 'required', message: 'Hands Are Dry And Warm is required' },
    ],
    faceGetsHotAndBlushes: [
      { type: 'required', message: 'Face Gets Hot And Blushes is required' },
    ],
    fallAsleepEasily: [
      { type: 'required', message: 'Fall Asleep Easily is required' },
    ],
    haveNightmares: [
      { type: 'required', message: 'Have Nightmares is required' },
    ],
  };

  private buildForm() {
    this.zungselfratingscaleForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      feelNervousAndAnxious: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelAfraid: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      getUpsetEasily: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelLikeFallingApart: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      everythingIsOkay: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      armsAndLegsShake: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      botheredByHeadachesNeckBackPains: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelWeakAndTired: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelCalmAndCanSitStill: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelHeartBeatingFast: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      botheredByDizzySpells: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelFaint: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      canBreatheEasily: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelNumbnessAndTingling: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      botheredByStomachAchesOrIngestion: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      haveToEmptyBladderOften: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      handsAreDryAndWarm: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      faceGetsHotAndBlushes: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      fallAsleepEasily: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      haveNightmares: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.zungselfratingscaleForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.zungselfratingscaleForm) {
      return;
    }
    const form = this.zungselfratingscaleForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
