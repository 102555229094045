import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {
  Case,
  DataTablesModel,
  ITherapySession,
  Patient,
} from 'src/app/_models';
import {
  CoreService,
  DashboardsService,
  ToastMessageService,
} from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditMemberGoalComponent } from './add-edit-member-goal/add-edit-member-goal.component';

@Component({
  selector: 'app-member-goals',
  templateUrl: './member-goals.component.html',
  styleUrls: ['./member-goals.component.css'],
})
export class MemberGoalsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() case: Case;
  @Input() patient: Patient;
  @Input() isPhysician: boolean;
  @Input() targetId: string;
  @Input() therapySession: ITherapySession;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Output() output = new EventEmitter<any>();

  loggedInUserId: string = '';
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = true;

  order: string = 'DESC';
  goalStatus: string = 'Active';
  searchGoalDescription: string = '';
  reloadRequired: string = '';
  loadOnInit: boolean = false;
  isModalWindow: boolean = false;
  providersList: any = [];
  providersListLoading: boolean = true;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<MemberGoalsComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any = null,

    private router: Router,
    public dialog: MatDialog,
    private coreService: CoreService,
    private dashboardsService: DashboardsService,
    public memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {
    if (dialogData) {
      this.isModalWindow = true;
      this.targetId = dialogData.targetId;
      this.case = dialogData.case;
      this.patient = dialogData.patient;
      this.hasGoalsViewAccess = true;
      this.hasGoalsAddAccess = true;
      this.hasGoalsEditAccess = true;
      this.hasGoalsDeleteAccess = true;
      this.isPhysician = true;
      this.loadOnInit = true;
      this.data.page = 0;
      this.data.per_page = 12;
    }
  }

  ngOnInit(): void {
    this.getStaffMembers();
    this.loadMemberGoals();

    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.data.page = 0;
      this.data.per_page = 12;

      this.loadMemberGoals();
    }
  }

  loadMemberGoals() {
    this.isLoadingResults = true;

    this.memberGoalHttpService
      .getPatientGoals(
        null,
        null,
        this.patient.id,
        this.targetId,
        this.data.per_page,
        this.data.page,
        this.order,
        this.goalStatus,
        this.searchGoalDescription
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.isLoadingResults = false;
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get goals for this session'
          );
        },
      });
  }

  filterOptionChanged(filterOption: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.goalStatus = filterOption;
    this.loadMemberGoals();
  }

  sortByChanged(order: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.order = order;
    this.loadMemberGoals();
  }

  searchByDescription() {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.loadMemberGoals();
  }

  getNextPage(event) {
    this.data.page = event.pageIndex;
    this.loadMemberGoals();
  }

  addGoal() {
    let dialogRef = this.dialog.open(AddEditMemberGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        loggedInUserId: this.loggedInUserId,
        patientId: this.patient?.id,
        patientName: this.patient?.firstName + ' ' + this.patient?.lastName,
        organizationId: this.patient.organizationId,
        patient: this.patient,
        case: this.case,
        therapySession: this.therapySession,
        targetId: this.targetId,
        providersList: this.providersList,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberGoals();
        this.reloadRequired = 'success';
        this.output.emit({ reload: true });
      }
    });
  }

  editGoal(goal) {
    let dialogRef = this.dialog.open(AddEditMemberGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        goal: goal,
        patient: this.patient,
        providersList: this.providersList,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberGoals();
        this.reloadRequired = 'success';
        this.output.emit({ reload: true });
      }
    });
  }

  deleteGoal(goalToBeDeleted) {
    let deleteDialogRef = this.dialog.open(
      GeneralDeleteConfirmDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          message: 'Are you sure you want to remove this goal?',
        },
      }
    );

    deleteDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;
        this.memberGoalHttpService
          .deletePatientAssessmentGoal(goalToBeDeleted)
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Goal has been deleted.'
              );
              this.isLoadingResults = false;
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.loadMemberGoals();
              this.output.emit({ reload: true });
            },
            error: (error) => {
              this.isLoadingResults = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to delete goal'
              );
            },
          });
      }
    });
  }

  getStaffMembers() {
    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  navigateToCase(goal) {
    this.router.navigate([`/main/member/${this.patient.id}/CARE`], {
      queryParams: { secondaryId: goal.caseId, secondaryTab: 'GOALS' },
    });
  }
}
