import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DataTablesModel, IMemberInsurance, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { PatientInsuranceApiService } from 'src/app/_services/patient/patient-insurance.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { ClaimFilingIndicators } from 'src/app/shared/utilities/billing/claimFilingIndicators';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { PatientInsuredRelationship } from 'src/app/shared/utilities/billing/patientInsuredRelationship';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-member-insurance',
  templateUrl: './add-edit-member-insurance.component.html',
  styleUrls: ['./add-edit-member-insurance.component.css'],
})
export class AddEditMemberInsuranceComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  patient: Patient;
  action: string = '';
  consentRequestId: string = '';

  public insuranceForm: FormGroup;
  memberInsurance: IMemberInsurance;
  processing: boolean = false;
  maxDate = new Date();

  memberName: string = '';

  searchPayer: string = '';
  insuranceList: DataTablesModel = {} as DataTablesModel;

  genderList = Gender;
  insuredRelationshipList = PatientInsuredRelationship;
  healthInsuranceTypeList = HealthInsuranceTypes;
  claimFilingIndicatorList = ClaimFilingIndicators;
  statesList = USStates;

  constructor(
    public dialogRef: MatDialogRef<AddEditMemberInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private patientInsuranceApiService: PatientInsuranceApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.patient = data.patient;
    if (this.action === 'EDIT') {
      this.memberInsurance = data.memberInsurance;
    }
    this.consentRequestId = data.consentRequestId;
  }

  ngOnInit(): void {
    this.insuranceList.per_page = 25;
    this.insuranceList.page = 0;

    this.loadActiveOrgInsurances();
    this.memberName =
      this.patient.lastName +
      ', ' +
      this.patient.firstName +
      (this.patient?.middleName ? ' ' + this.patient?.middleName : '');

    this.buildForm();
    if (this.action === 'EDIT') {
      this.insuranceForm.controls['id'].setValue(this.memberInsurance.id);
      this.insuranceForm.controls['organizationId'].setValue(
        this.memberInsurance.organizationId
      );
      this.insuranceForm.controls['facilityId'].setValue(
        this.memberInsurance.facilityId
      );
      this.insuranceForm.controls['patientId'].setValue(
        this.memberInsurance.patientId
      );
      this.insuranceForm.controls['gender'].setValue(
        this.memberInsurance.gender
      );
      this.insuranceForm.controls['dateOfBirth'].setValue(
        mmddyyyyToDate(this.memberInsurance.dateOfBirth)
      );
      this.insuranceForm.controls['insuranceType'].setValue(
        this.memberInsurance.insuranceType
      );
      this.insuranceForm.controls['payerId'].setValue(
        this.memberInsurance.payerId
      );
      this.insuranceForm.controls['payerName'].setValue(
        this.memberInsurance.payerName
      );
      this.insuranceForm.controls['payerPhone'].setValue(
        this.memberInsurance.payerPhone
      );
      this.insuranceForm.controls['payerFax'].setValue(
        this.memberInsurance.payerFax
      );
      this.insuranceForm.controls['memberId'].setValue(
        this.memberInsurance.memberId
      );
      this.insuranceForm.controls['planId'].setValue(
        this.memberInsurance.planId
      );
      this.insuranceForm.controls['groupId'].setValue(
        this.memberInsurance.groupId
      );
      this.insuranceForm.controls['employerSchool'].setValue(
        this.memberInsurance.employerSchool
      );
      this.insuranceForm.controls['relationship'].setValue(
        this.memberInsurance.relationship
      );
      this.insuranceForm.controls['subscriberFirstName'].setValue(
        this.memberInsurance.subscriberFirstName
      );
      this.insuranceForm.controls['subscriberLastName'].setValue(
        this.memberInsurance.subscriberLastName
      );
      this.insuranceForm.controls['subscriberDOB'].setValue(
        mmddyyyyToDate(this.memberInsurance.subscriberDOB)
      );
      this.insuranceForm.controls['subscriberGender'].setValue(
        this.memberInsurance.subscriberGender
      );
      this.insuranceForm.controls['memberName'].setValue(
        this.memberInsurance.memberName
      );
      if (this.memberInsurance.startDate) {
        this.insuranceForm.controls['startDate'].setValue(
          mmddyyyyToDate(this.memberInsurance.startDate)
        );
      }
      if (this.memberInsurance.endDate) {
        this.insuranceForm.controls['endDate'].setValue(
          mmddyyyyToDate(this.memberInsurance.endDate)
        );
      }
      // Claim payer code
      if (this.memberInsurance.claimPayerCode) {
        this.insuranceForm.controls['claimPayerCode'].setValue(
          this.memberInsurance.claimPayerCode
        );
      }

      this.insuranceForm.controls['status'].setValue(
        this.memberInsurance.status
      );
      this.insuranceForm.controls['sameInsuredAddress'].setValue(
        this.memberInsurance.sameInsuredAddress
      );
      this.insuranceForm.controls['addressLine1'].setValue(
        this.memberInsurance.addressLine1
      );
      this.insuranceForm.controls['addressLine2'].setValue(
        this.memberInsurance.addressLine2
      );
      this.insuranceForm.controls['city'].setValue(this.memberInsurance.city);
      this.insuranceForm.controls['state'].setValue(this.memberInsurance.state);
      this.insuranceForm.controls['zip'].setValue(this.memberInsurance.zip);
      this.insuranceForm.controls['copay'].setValue(this.memberInsurance.copay);
      this.insuranceForm.controls['deductible'].setValue(
        this.memberInsurance.deductible
      );
      if (this.memberInsurance.priorAuths) {
        this.pushPriorAuths();
      }

      this.insuranceForm.controls['healthInsuranceType'].setValue(
        this.memberInsurance.healthInsuranceType
      );
      this.insuranceForm.controls['claimFilingIndicator'].setValue(
        this.memberInsurance.claimFilingIndicator
      );
    }
  }

  loadActiveOrgInsurances() {
    this.patientInsuranceApiService
      .getInsuranceProviderList(
        this.searchPayer,
        'Active',
        this.insuranceList.per_page,
        this.insuranceList.page
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.insuranceList.items = response.items;
            this.insuranceList.total = response.total;
          } else {
            this.insuranceList.items = [];
            this.insuranceList.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get insurance providers for this organization.'
          );
        },
      });
  }

  buildForm() {
    this.insuranceForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(
        this.patient.organizationId,
        Validators.required
      ),
      facilityId: new FormControl(this.patient.facilityId),
      patientId: new FormControl(this.patient.id, Validators.required),
      gender: new FormControl(
        { value: this.patient.gender, disabled: true },
        Validators.required
      ),
      dateOfBirth: new FormControl(
        {
          value: mmddyyyyToDate(this.patient.dateOfBirth),
          disabled: true,
        },
        Validators.required
      ),

      insuranceType: new FormControl('', Validators.required),
      claimPayerCode: new FormControl(''),
      payerId: new FormControl('', Validators.required),
      payerName: new FormControl('', Validators.required),
      payerPhone: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      payerFax: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      memberId: new FormControl('', Validators.required),
      planId: new FormControl(''),
      groupId: new FormControl(''),
      employerSchool: new FormControl(''),
      relationship: new FormControl('', Validators.required),
      subscriberFirstName: new FormControl(
        this.patient.firstName,
        Validators.required
      ),
      subscriberLastName: new FormControl(
        this.patient.lastName,
        Validators.required
      ),
      subscriberDOB: new FormControl(
        mmddyyyyToDate(this.patient.dateOfBirth),
        Validators.required
      ),
      subscriberGender: new FormControl(
        this.patient.gender,
        Validators.required
      ),
      memberName: new FormControl(
        { value: this.memberName, disabled: true },
        Validators.required
      ),
      memberFirstName: new FormControl(this.patient.firstName),
      memberMiddleName: new FormControl(this.patient.middleName),
      memberLastName: new FormControl(this.patient.lastName),

      startDate: new FormControl(''),
      endDate: new FormControl(''),
      status: new FormControl('Active', Validators.required),

      copay: new FormControl(''),
      deductible: new FormControl(''),
      sameInsuredAddress: new FormControl(true),
      addressLine1: new FormControl(this.patient.addressLine1),
      addressLine2: new FormControl(this.patient.addressLine2),
      city: new FormControl(this.patient.city),
      state: new FormControl(this.patient.state),
      zip: new FormControl(
        this.patient.zip,
        Validators.compose([RxwebValidators.mask({ mask: '99999' })])
      ),

      priorAuths: this.formBuilder.array([]),

      healthInsuranceType: new FormControl(''),
      claimFilingIndicator: new FormControl(''),
    });
  }

  addAuthorization() {
    const control = <FormArray>this.insuranceForm.get('priorAuths');

    control.push(
      new FormGroup({
        authNumber: new FormControl('', Validators.required),
        visitType: new FormControl('', Validators.required),
        totalVisitNumber: new FormControl(''),
        usedVisits: new FormControl(''),
        remainingVisits: new FormControl(''),
        effectiveStartDate: new FormControl('', Validators.required),
        effectiveEndDate: new FormControl('', Validators.required),
        authorizationContactName: new FormControl(''),
        authorizationContactPhone: new FormControl(
          '',
          Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
        ),
        notes: new FormControl(''),
      })
    );
  }

  pushPriorAuths() {
    const control = <FormArray>this.insuranceForm.get('priorAuths');
    this.memberInsurance.priorAuths.forEach((priorAuth: any) => {
      control.push(
        new FormGroup({
          authNumber: new FormControl(
            priorAuth.authNumber,
            Validators.required
          ),
          visitType: new FormControl(priorAuth.visitType, Validators.required),
          totalVisitNumber: new FormControl(priorAuth.totalVisitNumber),
          usedVisits: new FormControl(priorAuth.usedVisits),
          remainingVisits: new FormControl(priorAuth.remainingVisits),
          effectiveStartDate: new FormControl(
            priorAuth.effectiveStartDate,
            Validators.required
          ),
          effectiveEndDate: new FormControl(
            priorAuth.effectiveEndDate,
            Validators.required
          ),
          authorizationContactName: new FormControl(
            priorAuth.authorizationContactName
          ),
          authorizationContactPhone: new FormControl(
            priorAuth.authorizationContactPhone,
            Validators.compose([
              RxwebValidators.mask({ mask: '(999) 999-9999' }),
            ])
          ),
          notes: new FormControl(priorAuth.notes),
        })
      );
    });
  }

  submitForm() {
    this.memberInsurance = Object.assign({}, this.memberInsurance);
    this.memberInsurance = Object.assign(
      this.memberInsurance,
      this.insuranceForm.value
    );

    this.memberInsurance.memberName = this.memberName;
    this.memberInsurance.gender = this.patient.gender;
    this.memberInsurance.dateOfBirth = formatDate(
      new Date(this.patient.dateOfBirth)
    );

    this.memberInsurance.subscriberDOB = formatDate(
      new Date(this.memberInsurance.subscriberDOB)
    );

    if (
      this.memberInsurance.startDate &&
      this.memberInsurance.startDate !== ''
    ) {
      this.memberInsurance.startDate = formatDate(
        new Date(this.memberInsurance.startDate)
      );
    }

    if (this.memberInsurance.endDate && this.memberInsurance.endDate !== '') {
      this.memberInsurance.endDate = formatDate(
        new Date(this.memberInsurance.endDate)
      );
    }

    this.processing = true;

    // Determine if there is a consentRequestId or not
    if (this.consentRequestId) {
      // Member Document request
      this.patientInsuranceApiService
        .memberAddIntakeInsurance(this.memberInsurance, this.consentRequestId)
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully added the insurance.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: failed to add the  insurance.'
            );
          },
        });
    } else {
      // Regular insurance add edit
      if (this.action === 'ADD') {
        this.patientInsuranceApiService
          .addInsurance(this.memberInsurance)
          .subscribe({
            next: (response) => {
              this.processing = false;
              this.toastMessageService.displaySuccessMessage(
                'Successfully added member insurance.'
              );
              this.dialogRef.close('success');
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: failed to add the  insurance.'
              );
            },
          });
      } else {
        this.patientInsuranceApiService
          .updateInsurance(this.memberInsurance)
          .subscribe({
            next: (response) => {
              this.processing = false;
              this.toastMessageService.displaySuccessMessage(
                'Successfully updated the member insurance details.'
              );
              this.dialogRef.close('success');
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: failed to update the member insurance details.'
              );
            },
          });
      }
    }
  }

  searchByPayerChanged() {
    this.insuranceList.page = 0;
    this.loadActiveOrgInsurances();
  }

  insuranceSelected(event) {
    this.insuranceForm.controls['claimPayerCode'].setValue(
      event.value.claimPayerCode
    );
    this.insuranceForm.controls['payerId'].setValue(event.value.id);
    this.insuranceForm.controls['payerName'].setValue(event.value.payerName);
    this.insuranceForm.controls['payerPhone'].setValue(event.value.phoneNumber);
    this.insuranceForm.controls['payerFax'].setValue(event.value.faxNumber);
  }

  compare(o1, o2) {
    return o1.id === o2;
  }

  sameAsAddressChanged(event) {
    if (event.checked) {
      this.insuranceForm.controls['addressLine1'].setValue(
        this.patient.addressLine1
      );
      this.insuranceForm.controls['addressLine2'].setValue(
        this.patient.addressLine2
      );
      this.insuranceForm.controls['city'].setValue(this.patient.city);
      this.insuranceForm.controls['state'].setValue(this.patient.state);
      this.insuranceForm.controls['zip'].setValue(this.patient.zip);
    }
  }

  removeAuthorization(index) {
    const control = <FormArray>this.insuranceForm.get('priorAuths');

    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this prior authorization?',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        control.removeAt(index);
      }
    });
  }

  visitOptionChanged(event, index) {
    const control = <FormArray>this.insuranceForm.get('priorAuths');
    let formControl = <FormGroup>control.at(index);

    if (event.value === 'Limited') {
      formControl.get('totalVisitNumber').addValidators(Validators.required);
      formControl.get('totalVisitNumber').updateValueAndValidity();
    } else {
      formControl.get('totalVisitNumber').setValue(null);
      formControl.get('totalVisitNumber').clearValidators();
      formControl.get('totalVisitNumber').updateValueAndValidity();
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.insuranceForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.insuranceForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.insuranceForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.insuranceForm.controls['addressLine1'].setValue(addressField);
  }
}
