<form
  [formGroup]="treatmentreviewprofileForm"
  novalidate
  (ngSubmit)="onTreatmentReviewProfileFormSubmit()"
>
  <div class="row">
    <div class="col-md-12 pad20">
      <label class="labelText">
        Documents Reviewed <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="documentsReviewed"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewprofileForm.controls['documentsReviewed'].invalid
          "
        >
          Reqiuired
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 pad20">
      <label class="labelText">
        Assessments Conducted During Review Period
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="assessmentsConductedDuringReviewPeriod"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewprofileForm.controls[
              'assessmentsConductedDuringReviewPeriod'
            ].invalid
          "
        >
          Reqiuired
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-12"
      style="text-align: right"
      *ngIf="selectedPlan.status === 'Active'"
    >
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!treatmentreviewprofileForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
