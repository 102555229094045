import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Case, Patient } from 'src/app/_models';

@Component({
  selector: 'app-case-treatment',
  templateUrl: './case-treatment.component.html',
  styleUrl: './case-treatment.component.css',
})
export class CaseTreatmentComponent implements OnInit {
  @ViewChildren(MatTab) tabs!: QueryList<MatTab>;
  @Input() subSectionId: number;
  @Input() case: Case;
  @Input() patient: Patient;

  selectedIndex: number = 0;
  isIntakeTabSelected = true;
  isAdmissionSelected = false;
  isAsamTabSelected = false;
  isTreatmentPlanTabSelected = false;
  isTreatmentReviewTabSelected = false;
  isDischargeTabSelected = false;
  selectedAdmissionSubTab: string = 'Profile';
  selectedPlan: any;
  selectedReview: any;
  selectedProgram: any;

  selectedPlanSubTab: string = '';
  selectedReviewSubTab: string = '';
  selectedDischargeSubTab: string = '';
  admissionTabs = [
    { value: 'Profile', label: 'Profile' },
    { value: 'Financial', label: 'Financial/Household' },
    { value: 'Substance', label: 'Substance Use' },
    { value: 'Legal', label: 'Legal' },
    { value: 'ASAM', label: 'ASAM' },
    { value: 'Diagnosis', label: 'Diagnosis' },
    { value: 'ProgramEnroll', label: 'Program Enroll' },
    { value: 'TreatmentTeam', label: 'Treatment Team' },
  ];
  planTabs = [
    { value: 'Profile', label: 'Profile' },
    { value: 'Overview', label: 'Overview' },
    { value: 'Diagnosis', label: 'Diagnosis' },
    { value: 'Targets', label: 'Targets' },
    { value: 'Plan Outline', label: 'Plan Outline' },
  ];

  reviewTabs = [
    { value: 'Profile', label: 'Profile' },
    { value: 'ASAM', label: 'ASAM' },
    { value: 'Team', label: 'Team' },
    { value: 'Comments', label: 'Comments' },
    { value: 'Plan Outline', label: 'Plan Outline' },
  ];
  dischargeTabs = [
    { value: 'Profile', label: 'Profile' },
    { value: 'ASAM', label: 'ASAM' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Status', label: 'Status' },
    { value: 'Substance', label: 'Substance Use' },
    { value: 'Summary', label: 'Summary' },
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.selectedIndex = 0;
    if (this.subSectionId) {
      this.selectedIndex = this.subSectionId;
    } else {
      this.isIntakeTabSelected = true;
    }
  }

  async changeSelectedIndex(event: MatTabChangeEvent) {
    const index = event.index;
    const tabTitle = event.tab.textLabel;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        treatmentTab: index,
      },
      queryParamsHandling: 'merge',
    });

    this.selectedPlan = undefined;
    this.selectedPlanSubTab = '';

    this.selectedReview = undefined;
    this.selectedReviewSubTab = '';

    this.selectedProgram = undefined;
    this.selectedDischargeSubTab = '';

    await this.toggleTabShowNotShow(tabTitle);
  }

  async toggleTabShowNotShow(tabTitle) {
    this.isIntakeTabSelected = tabTitle == 'Intake';
    this.isAdmissionSelected = tabTitle == 'Admission';
    this.isAsamTabSelected = tabTitle == 'ASAM';
    this.isTreatmentPlanTabSelected = tabTitle == 'Plans';
    this.isTreatmentReviewTabSelected = tabTitle == 'Reviews';
    this.isDischargeTabSelected = tabTitle == 'Admission Discharge';
  }

  // Get tab from the available tabs
  async getIndexOfTab(tabTitle): Promise<Number> {
    let index = 0;
    if (this.tabs) {
      const tabArray = this.tabs.toArray();
      tabArray.forEach((tab, i) => {
        if (tab.textLabel == tabTitle) {
          index = i;
        }
      });
    }
    return index;
  }

  selectAdmissionSubTab(subTab: string) {
    this.selectedAdmissionSubTab = subTab;
  }

  selectPlanSubTab(subTab: string) {
    this.selectedPlanSubTab = subTab;
  }

  selectReviewSubTab(subTab: string) {
    this.selectedReviewSubTab = subTab;
  }
  selectDischargeSubTab(subTab: string) {
    this.selectedDischargeSubTab = subTab;
  }

  onPlanSelected(selectedPlan: any) {
    this.selectedPlan = selectedPlan;
    this.selectedPlanSubTab = 'Profile';
  }

  onReviewPlanSelected(selectedPlan: any) {
    this.selectedPlan = selectedPlan;
    this.selectedReviewSubTab = '';
  }

  onReviewSelected(selectedReview: any) {
    this.selectedReview = selectedReview;
    this.selectedReviewSubTab = 'Profile';
  }

  onProgramSelected(selectedProgram: any) {
    this.selectedProgram = selectedProgram;
    this.selectedDischargeSubTab = 'Profile';
  }

  resetReviewTab() {
    this.selectedReview = undefined;
    this.selectedReviewSubTab = '';

    this.selectedPlan = undefined;
    this.selectedReviewSubTab = '';
  }

  resetReviewPlan() {
    this.selectedReview = undefined;
    this.selectedReviewSubTab = '';
  }

  resetProgram() {
    this.selectedProgram = undefined;
    this.selectedDischargeSubTab = '';
  }

  resetPlanTab() {
    this.selectedPlan = undefined;
    this.selectedPlanSubTab = '';
  }
}
