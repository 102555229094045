<form
  [formGroup]="treatmentdischargeprofileForm"
  novalidate
  (ngSubmit)="onTreatmentDischargeProfileFormSubmit()"
>
  <div class="row">
    <!-- Discharge Date  -->
    <div class="col-md-6">
      <label class="labelText">
        Discharge Date <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input
          formControlName="dischargeDate"
          matInput
          [matDatepicker]="dischargeDatePicker"
          autoSlashDate
          placeholder="Discharge Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dischargeDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #dischargeDatePicker></mat-datepicker>
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['dischargeDate'].invalid
          "
        >
          Please select a valid date
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Date Of Last Contact  -->
    <div class="col-md-6">
      <label class="labelText">
        Date Of Last Contact<span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input
          formControlName="dateOfLastContact"
          matInput
          [matDatepicker]="dateOfLastContactPicker"
          autoSlashDate
          placeholder="Date of last contact"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dateOfLastContactPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateOfLastContactPicker></mat-datepicker>
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['dateOfLastContact'].invalid
          "
        >
          Please select a valid date
        </mat-error>
      </mat-form-field>
    </div>

    <!--External Record Id   -->
    <div class="col-md-6">
      <label class="labelText">
        External Record Id <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="External Record Id"
          formControlName="externalRecordId"
          name="externalRecordId"
          required
        />
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['externalRecordId'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!--  Discharge Staff  -->
    <div class="col-md-6">
      <label class="labelBold">
        Discharge Staff
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="staffId"
          placeholder="Discharge Staff"
          (selectionChange)="providerSelected($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Staff Name"
              noEntriesFoundLabel="No matching staff found"
              [(ngModel)]="providerName"
              (ngModelChange)="filterProvider()"
              [ngModelOptions]="{ standalone: true }"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option
            *ngFor="let provider of filteredProvidersList"
            [value]="provider.id"
          >
            {{ provider.lastName }}, {{ provider.firstName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="treatmentdischargeprofileForm.controls['staffId'].invalid"
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Discharge Referral -->
    <div class="col-md-6">
      <label class="labelText">
        Discharge Referral <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="dischargeReferral"
          placeholder="Discharge Referral"
        >
          <mat-option value="No Referral">No Referral</mat-option>
          <mat-option value="Other Referral">Other Referral</mat-option>
          <mat-option value="To Community Mental Health Services"
            >To Community Mental Health Services</mat-option
          >
          <mat-option value="To Detox Services">To Detox Services</mat-option>
          <mat-option value="To General Hospital"
            >To General Hospital</mat-option
          >
          <mat-option value="To Half-Way House">To Half-Way House</mat-option>
          <mat-option value="To Intensive Outpatient"
            >To Intensive Outpatient</mat-option
          >
          <mat-option value="To Intermediate Care"
            >To Intermediate Care</mat-option
          >
          <mat-option value="To Long Term Care Program"
            >To Long Term Care Program</mat-option
          >
          <mat-option value="To Methadone">To Methadone</mat-option>
          <mat-option value="To Other Outpatient Use"
            >To Other Outpatient Use</mat-option
          >
          <mat-option value="To Other Residential Program"
            >To Other Residential Program</mat-option
          >
          <mat-option value="To Psychiatric Hospital"
            >To Psychiatric Hospital</mat-option
          >
          <mat-option value="To Self Help Programs (AA-NA)"
            >To Self Help Programs (AA-NA)</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['dischargeReferral'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Discharge Reason -->
    <div class="col-md-6">
      <label class="labelText">
        Discharge Reason <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="dischargeReason"
          placeholder="Discharge Reason"
        >
          <mat-option value="Incarcerated">Incarcerated</mat-option>
          <mat-option value="Client Died">Client Died</mat-option>
          <mat-option value="No further care indicated"
            >No further care indicated</mat-option
          >
          <mat-option value="Treatment completed referral made"
            >Treatment completed referral made</mat-option
          >
          <mat-option value="Treatment completed no referral"
            >Treatment completed no referral</mat-option
          >
          <mat-option value="Treatment completed additional services advised"
            >Treatment completed additional services advised</mat-option
          >
          <mat-option value="Lost contact/Left against medical advice"
            >Lost contact/Left against medical advice</mat-option
          >
          <mat-option
            value="Treatment not complete referred to other SA treatment program"
            >Treatment not complete referred to other SA treatment
            program</mat-option
          >
          <mat-option value="Client Moved">Client Moved</mat-option>
          <mat-option value="Client's needs exceeded provider's offerings"
            >Client's needs exceeded provider's offerings</mat-option
          >
          <mat-option value="Administratively Discontinued"
            >Administratively Discontinued</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['dischargeReason'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Self Help Program Attendance -->
    <div class="col-md-6">
      <label class="labelText">
        Self Help Program Attendance <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="selfHelpProgramAttendance"
          placeholder="Self Help Program Attendance"
        >
          <mat-option value="No attendance in the past month"
            >No attendance in the past month</mat-option
          >
          <mat-option value="1-3 times in past month"
            >1-3 times in past month</mat-option
          >
          <mat-option value="4-7 times in past month"
            >4-7 times in past month</mat-option
          >
          <mat-option value="8-15 times in past month"
            >8-15 times in past month</mat-option
          >
          <mat-option value="16-30 times in past month"
            >16-30 times in past month</mat-option
          >
          <mat-option value="Unknown">Unknown</mat-option>
          <mat-option
            value="Some attendance in past month, but frequency unknown"
            >Some attendance in past month, but frequency unknown</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            treatmentdischargeprofileForm.controls['selfHelpProgramAttendance']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12" style="text-align: right">
      <button
        class="btn btn-primary pull-right"
        mat-raised-button
        type="submit"
        [disabled]="!treatmentdischargeprofileForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
