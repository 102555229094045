import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentDischargeProfile } from 'src/app/_models/case/treatment/discharge/treatmentdischargeprofile.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentDischargeProfileApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentDischargeProfileDetails(programId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/treatmentdischargeprofile/` + programId);
    }
    updateTreatmentDischargeProfile(thisTreatmentDischargeProfile: ITreatmentDischargeProfile): Observable<ITreatmentDischargeProfile> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentDischargeProfile>(`${environment.apiUrl}/treatmentdischargeprofile`, thisTreatmentDischargeProfile, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}