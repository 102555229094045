import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReferralServicesListService {
  constructor(private httpClient: HttpClient) {}

  getReferralServicesList(
    serviceName: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    if (serviceName) params = params.append('name', serviceName);
    if (status) params = params.append('status', status);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.referralUrl}/referral-services`,
      {
        params: params,
      }
    );
  }

  getAllActiveReferralServices(): Observable<any> {
    let params = new HttpParams();
    params = params.append('status', 'Active');

    return this.httpClient
      .get<any>(`${environment.referralUrl}/all-referral-services`, {
        params: params,
      })
      .pipe(
        map((response) => {
          return response || {};
        })
      );
  }
}
