import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from 'src/app/_models/patient/patient';
import { ITherapySession } from 'src/app/_models/session/therapySession.model';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrl: './print-dialog.component.css',
})
export class PrintDialogComponent {
  therapySession: ITherapySession;
  patient: Patient;
  isBPSAEnforced: boolean;

  constructor(
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.therapySession = data.therapySession;
    this.patient = data.patient;
    this.isBPSAEnforced = data.isBPSAEnforced;
  }
}
