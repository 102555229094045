import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPanicAgoraphobia } from 'src/app/_models/assessments/anxiety/panicagoraphobia.model';
import {
  PanicAgoraphobiaApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-panic-agoraphobia',
  templateUrl: './panic-agoraphobia.component.html',
  styleUrls: ['./panic-agoraphobia.component.css'],
})
export class PanicAgoraphobiaComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public panicAgoraphobiaApiService: PanicAgoraphobiaApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public panicAgoraphobiaForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  panicAgoraphobiaAssessment: IPanicAgoraphobia;
  panicAgoraphobiaAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.panicAgoraphobiaForm, this.errors);

    this.loadPanicAgoraphobiaAssessment();
  }

  loadPanicAgoraphobiaAssessment() {
    this.panicAgoraphobiaForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.panicAgoraphobiaApiService
        .getPanicAgoraphobiaDetails(this.assessmentId)
        .subscribe((panicAgoraphobiaAssessment) => {
          if (panicAgoraphobiaAssessment.data) {
            this.panicAgoraphobiaForm.controls['q1'].setValue(
              panicAgoraphobiaAssessment.data.q1.answer
            );
            this.panicAgoraphobiaForm.controls['q2'].setValue(
              panicAgoraphobiaAssessment.data.q2.answer
            );
            this.panicAgoraphobiaForm.controls['q3'].setValue(
              panicAgoraphobiaAssessment.data.q3.answer
            );
            this.panicAgoraphobiaForm.controls['q4'].setValue(
              panicAgoraphobiaAssessment.data.q4.answer
            );
            this.panicAgoraphobiaForm.controls['q5'].setValue(
              panicAgoraphobiaAssessment.data.q5.answer
            );
            this.panicAgoraphobiaForm.controls['q6'].setValue(
              panicAgoraphobiaAssessment.data.q6.answer
            );
            this.panicAgoraphobiaForm.controls['q7'].setValue(
              panicAgoraphobiaAssessment.data.q7.answer
            );
            this.panicAgoraphobiaForm.controls['q8'].setValue(
              panicAgoraphobiaAssessment.data.q8.answer
            );
            this.panicAgoraphobiaForm.controls['q9'].setValue(
              panicAgoraphobiaAssessment.data.q9.answer
            );
            this.panicAgoraphobiaForm.controls['q10'].setValue(
              panicAgoraphobiaAssessment.data.q10.answer
            );
            this.panicAgoraphobiaForm.controls['q11'].setValue(
              panicAgoraphobiaAssessment.data.q11.answer
            );
            this.panicAgoraphobiaForm.controls['q12'].setValue(
              panicAgoraphobiaAssessment.data.q12.answer
            );
            this.panicAgoraphobiaForm.controls['q13'].setValue(
              panicAgoraphobiaAssessment.data.q13.answer
            );
            this.panicAgoraphobiaForm.controls['q14'].setValue(
              panicAgoraphobiaAssessment.data.q14.answer
            );

            this.riskScore = panicAgoraphobiaAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.panicAgoraphobiaForm.invalid) {
      this.panicAgoraphobiaForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.panicAgoraphobiaAssessment = Object.assign(
      {},
      this.panicAgoraphobiaAssessment
    );
    this.panicAgoraphobiaAssessment.patientId =
      this.panicAgoraphobiaForm.controls['patientId'].value;
    this.panicAgoraphobiaAssessment.sessionId =
      this.panicAgoraphobiaForm.controls['sessionId'].value;
    this.panicAgoraphobiaAssessment.assessmentRequestId =
      this.panicAgoraphobiaForm.controls['assessmentRequestId'].value;
    this.panicAgoraphobiaAssessment.q1 = {
      answer: this.panicAgoraphobiaForm.controls['q1'].value,
    };
    this.panicAgoraphobiaAssessment.q2 = {
      answer: this.panicAgoraphobiaForm.controls['q2'].value,
    };
    this.panicAgoraphobiaAssessment.q3 = {
      answer: this.panicAgoraphobiaForm.controls['q3'].value,
    };
    this.panicAgoraphobiaAssessment.q4 = {
      answer: this.panicAgoraphobiaForm.controls['q4'].value,
    };
    this.panicAgoraphobiaAssessment.q5 = {
      answer: this.panicAgoraphobiaForm.controls['q5'].value,
    };
    this.panicAgoraphobiaAssessment.q6 = {
      answer: this.panicAgoraphobiaForm.controls['q6'].value,
    };
    this.panicAgoraphobiaAssessment.q7 = {
      answer: this.panicAgoraphobiaForm.controls['q7'].value,
    };
    this.panicAgoraphobiaAssessment.q8 = {
      answer: this.panicAgoraphobiaForm.controls['q8'].value,
    };
    this.panicAgoraphobiaAssessment.q9 = {
      answer: this.panicAgoraphobiaForm.controls['q9'].value,
    };
    this.panicAgoraphobiaAssessment.q10 = {
      answer: this.panicAgoraphobiaForm.controls['q10'].value,
    };
    this.panicAgoraphobiaAssessment.q11 = {
      answer: this.panicAgoraphobiaForm.controls['q11'].value,
    };
    this.panicAgoraphobiaAssessment.q12 = {
      answer: this.panicAgoraphobiaForm.controls['q12'].value,
    };
    this.panicAgoraphobiaAssessment.q13 = {
      answer: this.panicAgoraphobiaForm.controls['q13'].value,
    };
    this.panicAgoraphobiaAssessment.q14 = {
      answer: this.panicAgoraphobiaForm.controls['q14'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.panicAgoraphobiaAssessment.accessCode = this.accessCode;

      this.panicAgoraphobiaApiService
        .addGuestPanicAgoraphobia(this.panicAgoraphobiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Panic Agoraphobia Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Panic Agoraphobia Assessment'
            );
          }
        );
    } else {
      this.panicAgoraphobiaApiService
        .addPanicAgoraphobia(this.panicAgoraphobiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Panic Agoraphobia Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Panic Agoraphobia Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: 'How frequently did you have panic attacks? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          'How severe were the panic attacks in the last week? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message: 'How long did the panic attacks last? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          'Were most of the attacks expected (occurring in feared situations) or unexpected (spontaneous)? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          'In the past week, did you avoid certain situations because you feared having a panic attack or a feeling of discomfort? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          'Please check the situation(s) you avoided or in which you developed panic attacks or a feeling of discomfort when you are not accompanied: is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: 'How important were the avoided situations? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          'In the past week, did you suffer from the fear of having a panic attack (anticipatory anxiety or “fear of being afraid?”) is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: 'How strong was this “fear of fear?” is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          'In the past week, did panic attacks or agoraphobia lead to an impairment in your family relationships (partner, children, etc.)? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          "In the past week, did panic attacks or agoraphobia lead to an impairment of your social life and leisure activities (for example, you weren't able to go a film or party)? is required",
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          'In the past week, did panic attacks or agoraphobia lead to an impairment of your work or household responsibilities? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          'In the past week, did you worry about suffering harm from your panic attacks (for example, having a heart attack or fainting)? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          'Do you sometimes believe that your doctor was wrong when he told you your symptoms (like rapid heart rate, tingling sensations, or shortness of breath) have a psychological cause? Do you believe the actually cause of these symptoms is an undiscovered physical problem? is required',
      },
    ],
  };

  private buildForm() {
    this.panicAgoraphobiaForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.panicAgoraphobiaForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.panicAgoraphobiaForm) {
      return;
    }
    const form = this.panicAgoraphobiaForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
