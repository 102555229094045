import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Case, IFacility, Patient } from 'src/app/_models';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { DashboardsService, ToastMessageService } from 'src/app/_services';
import { TreatmentProgramApiService } from 'src/app/_services/case/treatment/discharge/treatmentprogram.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-treatment-discharge-programs-dialog',
  templateUrl: './add-edit-treatment-discharge-programs-dialog.component.html',
  styleUrl: './add-edit-treatment-discharge-programs-dialog.component.css',
})
export class AddEditTreatmentProgramsDialogComponent implements OnInit {
  public action: string;
  public treatmentprogramForm: FormGroup;
  treatmentprogram: ITreatmentProgram;

  processing = false;
  case: Case;
  patient: Patient;

  facilitiesLoading = false;
  filteredFacilities: Array<IFacility> = [];
  filterFacilityName: string = '';
  facilities: Array<IFacility> = [];

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddEditTreatmentProgramsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private facilitiesSandbox: FacilitiesSandbox,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService,
    public treatmentprogramApiService: TreatmentProgramApiService,
    private formBuilder: FormBuilder
  ) {
    this.action = data.action;
    this.treatmentprogram = data.treatmentprogram;
    this.case = data.case;
    this.patient = data.patient;
  }

  public ngOnInit() {
    this.loadFacilities();
    this.loadActiveProviders();

    this.buildForm();

    if (this.action === 'EDIT') {
      this.treatmentprogramForm.controls['id'].setValue(
        this.treatmentprogram.id
      );
      this.treatmentprogramForm.controls['organizationId'].setValue(
        this.treatmentprogram.organizationId
      );
      this.treatmentprogramForm.controls['patientId'].setValue(
        this.treatmentprogram.patientId
      );
      this.treatmentprogramForm.controls['caseId'].setValue(
        this.treatmentprogram.caseId
      );
      this.treatmentprogramForm.controls['facilityId'].setValue(
        this.treatmentprogram.facilityId
      );
      this.treatmentprogramForm.controls['facilityName'].setValue(
        this.treatmentprogram.facilityName
      );
      this.treatmentprogramForm.controls['programName'].setValue(
        this.treatmentprogram.programName
      );
      this.treatmentprogramForm.controls['staffId'].setValue(
        this.treatmentprogram.staffId
      );
      this.treatmentprogramForm.controls['staffName'].setValue(
        this.treatmentprogram.staffName
      );
      this.treatmentprogramForm.controls['daysOnWaitList'].setValue(
        this.treatmentprogram.daysOnWaitList
      );
      this.treatmentprogramForm.controls['terminationReason'].setValue(
        this.treatmentprogram.terminationReason
      );
      this.treatmentprogramForm.controls['notes'].setValue(
        this.treatmentprogram.notes
      );
      this.treatmentprogramForm.controls['status'].setValue(
        this.treatmentprogram.status
      );

      this.treatmentprogramForm.controls['startDate'].setValue(
        mmddyyyyToDate(this.treatmentprogram['startDate'])
      );

      this.treatmentprogramForm.controls['endDate'].setValue(
        mmddyyyyToDate(this.treatmentprogram['endDate'])
      );
    }
  }

  onTreatmentProgramFormSubmit() {
    if (this.treatmentprogramForm.invalid) {
      this.treatmentprogramForm.markAllAsTouched();
      return;
    }
    this.treatmentprogram = Object.assign({}, this.treatmentprogram);

    this.treatmentprogram = Object.assign(
      this.treatmentprogram,
      this.treatmentprogramForm.value
    );
    if (this.treatmentprogram.startDate !== '') {
      this.treatmentprogram.startDate = formatDate(
        new Date(this.treatmentprogram.startDate)
      );
    }
    if (this.treatmentprogram.endDate !== '') {
      this.treatmentprogram.endDate = formatDate(
        new Date(this.treatmentprogram.endDate)
      );
    }
    this.processing = true;
    if (this.action === 'EDIT') {
      this.treatmentprogramApiService
        .updateTreatmentPrograms(this.treatmentprogram)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    } else {
      this.treatmentprogramApiService
        .addTreatmentPrograms(this.treatmentprogram)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    }
  }

  private buildForm() {
    this.treatmentprogramForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      facilityId: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      facilityName: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      programName: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      staffId: new FormControl([], Validators.compose([Validators.required])),
      staffName: new FormControl([], Validators.compose([Validators.required])),

      daysOnWaitList: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl(''),
      terminationReason: new FormControl(''),
      notes: new FormControl(''),
      status: new FormControl('Active', Validators.required),
    });
  }

  facilitySelected(event: MatSelectChange): void {
    const facilityId = event.value;

    const selectedFacility = this.facilities
      .filter((facility) => facility.id === facilityId)
      .at(0);

    this.treatmentprogramForm.controls['facilityName'].setValue(
      selectedFacility.facilityName
    );
  }

  public loadFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
        this.filteredFacilities = response;
      }
    });
  }

  loadActiveProviders() {
    this.providersListLoading = true;

    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  providerSelected(event: any): void {
    const providerId = event.value;

    const selectedProvider = this.providersList
      .filter((staff) => staff.id === providerId)
      .at(0);

    this.treatmentprogramForm.controls['staffName'].setValue(
      selectedProvider.firstName + ' ' + selectedProvider.lastName
    );
  }

  // Filter Facilities
  filterFacility() {
    if (this.filterFacilityName) {
      this.filteredFacilities = this.facilities.filter((payee) => {
        if (
          payee.facilityName
            .toLowerCase()
            .includes(this.filterFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredFacilities = [...this.facilities];
    }
  }

  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }
}
