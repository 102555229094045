<mat-card-title>Lab Orders</mat-card-title>
<mat-divider></mat-divider>

<div class="row" style="padding: 10px">
  <div class="col-md-12" style="text-align: right">
    <button
      type="button"
      mat-raised-button
      color="warn"
      (click)="addLabOrder()"
    >
      + Lab Order
    </button>
  </div>
</div>
<div *ngIf="data.total === 0">
  <mat-error> No Lab Orders found </mat-error>
</div>
<div *ngIf="isLoadingResults || apiError">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>
  <mat-error *ngIf="apiError">
    An error occurred while retrieving Lab Order records
  </mat-error>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="testType">
          <th mat-header-cell *matHeaderCellDef>Test Type</th>
          <td mat-cell *matCellDef="let row">{{ row.order.lab_test.name }}</td>
        </ng-container>
        <ng-container matColumnDef="patientAddressLine1">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">
            {{ row.order.patient_address.first_line }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientCity">
          <th mat-header-cell *matHeaderCellDef>City</th>
          <td mat-cell *matCellDef="let row">
            {{ row.order.patient_address.city }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientState">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let row">
            {{ row.order.patient_address.state }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientZip">
          <th mat-header-cell *matHeaderCellDef>Zip</th>
          <td mat-cell *matCellDef="let row">
            {{ row.order.patient_address.zip }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button
              mat-raised-button
              class="btn btn-just-icon btn-white btn-fab btn-round pull-right"
              color="accent"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
      ></mat-paginator>
    </div>
  </div>
</div>
