<mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 20px">
  <div class="row" style="margin-bottom: 25px">
    <mat-card-title>Claims</mat-card-title>
  </div>

  <!-- Search Box -->
  <div
    class="row"
    style="
      margin-left: 5px;
      margin-right: 5px;
      row-gap: 15px;
      margin-bottom: 15px;
    "
  >
    <div class="col-sm-12 col-md-10">
      <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
        <!-- Date Picker -->
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            min-width: 300px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            calendar_month
          </span>
          <mat-date-range-input
            [rangePicker]="picker"
            [max]="currentTime"
            matTooltip="To search, please select the date range and click on the search icon"
          >
            <input
              matStartDate
              placeholder="Date Range"
              (click)="picker.open()"
              [(ngModel)]="searchStartDate"
            />
            <input
              matEndDate
              [(ngModel)]="searchEndDate"
              (click)="picker.open()"
            />
          </mat-date-range-input>
          <button
            matIconSuffix
            type="button"
            mat-icon-button
            (click)="searchChanged()"
          >
            <mat-icon>search</mat-icon>
          </button>
          <button
            matIconSuffix
            type="button"
            mat-icon-button
            matTooltip="Clear date filter"
            (click)="clearDate()"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <mat-date-range-picker
            #picker
            [disabled]="false"
          ></mat-date-range-picker>
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            pin
          </span>
          <input
            type="search"
            placeholder="Claim Id"
            [(ngModel)]="searchClaimCode"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            pin
          </span>
          <input
            type="search"
            placeholder="Note Id"
            [(ngModel)]="searchSessionCode"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Provider Name"
            [(ngModel)]="searchProviderName"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
    </div>

    <div
      class="col-sm-12 col-md-2"
      style="
        column-gap: 20px;
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
      "
    >
      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces"> Status: {{ status }} </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <div *ngFor="let option of claimSearchStatusList">
          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="optionChanged(option)"
          >
            {{ option }}
          </button>
        </div>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="processing || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving {{ status }} claims records
    </mat-error>
  </div>

  <div *ngIf="data.total === 0 && !processing">
    <mat-error> No {{ status }} claims found </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items">
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let row">
            <div class="justifyCenterContainer">
              <b>Claim Id : </b>
              <button
                mat-button
                type="button"
                (click)="viewClaim(row)"
                color="primary"
                style="padding: 0"
              >
                {{ row.claimCode }}
              </button>
              <button
                *ngIf="row?.mindwiseClaimsScrubbingErrors?.length > 0"
                mat-icon-button
                type="button"
                [matTooltip]="row?.mindwiseClaimsScrubbingErrors"
                (click)="viewClaim(row)"
              >
                <mat-icon color="warn"> warning </mat-icon>
              </button>
              <button
                *ngIf="row?.externalClaimsErrors?.length > 0"
                mat-icon-button
                type="button"
                [matTooltip]="row?.externalClaimsErrors"
                (click)="viewClaim(row)"
              >
                <mat-icon color="warn"> report_problem </mat-icon>
              </button>
              <button
                *ngIf="row?.initialERAReceived"
                mat-icon-button
                type="button"
                matTooltip="ERA Received"
                (click)="viewPaymentHistory(row)"
              >
                <mat-icon style="color: green"> local_atm </mat-icon>
              </button>
            </div>
            <b>Note Id :</b>{{ row.sessionCode }}
            <br />
            <b>
              {{
                row?.therapySession?.start | date : 'M/d/y' : currentTimezone
              }}
              ( {{ row?.therapySession?.visitReasonName }} -
              {{ row?.therapySession?.sessionDuration }} min)
            </b>
            <br />
            {{ row?.therapySession?.start | date : 'h:mm a' : currentTimezone }}
            -
            {{ row?.therapySession?.end | date : 'h:mm a' : currentTimezone }}
            {{ row?.therapySession?.start ? currentTimezone : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="payer">
          <th mat-header-cell *matHeaderCellDef>Payer</th>
          <td mat-cell *matCellDef="let row">
            <label class="labelBold">
              {{
                row?.billingSequence === 'P'
                  ? row?.therapySession?.primaryInsuranceDetails?.payerName
                  : row?.insuranceDetails?.payerName
              }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="sequence">
          <th mat-header-cell *matHeaderCellDef>Sequence</th>
          <td mat-cell *matCellDef="let row">
            <span
              [class]="
                row?.billingSequence === 'P'
                  ? 'acceptedByWaystarStatus'
                  : row?.billingSequence === 'S'
                  ? 'queuedForSubmissionStatus'
                  : row?.billingSequence === 'T'
                  ? 'processingStatus'
                  : 'draftStatus'
              "
            >
              {{
                row?.billingSequence === 'P'
                  ? 'Primary'
                  : row?.billingSequence === 'S'
                  ? 'Secondary'
                  : row?.billingSequence === 'T'
                  ? 'Tertiary'
                  : 'Other'
              }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="renderingProvider">
          <th mat-header-cell *matHeaderCellDef>Rendering Provider</th>
          <td mat-cell *matCellDef="let row">
            {{ row?.therapySession?.physicianLastName }},
            {{ row?.therapySession?.physicianFirstName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <div class="justifyCenterContainer">
              <span
                [class]="
                  row.billingStatus === 'In Draft'
                    ? 'draftStatus'
                    : row.billingStatus === 'Queued for Submission'
                    ? 'queuedForSubmissionStatus'
                    : row.billingStatus === 'Processing'
                    ? 'processingStatus'
                    : row.billingStatus === 'Accepted'
                    ? 'acceptedByWaystarStatus'
                    : row.billingStatus === 'Rejected'
                    ? 'rejectedByWaystarStatus'
                    : row.billingStatus === 'Sent To Payer'
                    ? 'sentToPayerStatus'
                    : row.billingStatus === 'ERA Received'
                    ? 'completedClaimsStatus'
                    : row.billingStatus === 'Archived'
                    ? 'archivedStatus'
                    : 'onHoldStatus'
                "
              >
                {{ row.billingStatus }}
              </span>

              <mat-icon
                color="warn"
                *ngIf="row.billingStatus === 'Rejected'"
                [matTooltip]="row?.externalClaimsErrors"
              >
                error_outline
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalCharge">
          <th mat-header-cell *matHeaderCellDef>Total Charge</th>
          <td mat-cell *matCellDef="let row">
            {{ row?.therapySession?.billingTotal | currency : 'USD' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              type="button"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" (click)="viewClaim(row)">
                <mat-icon style="color: #2196f3"> visibility </mat-icon>
                <span>View</span>
              </button>
              <button
                *ngIf="row?.initialERAReceived"
                mat-menu-item
                type="button"
                (click)="viewPaymentHistory(row)"
              >
                <mat-icon style="color: green"> local_atm </mat-icon>
                <span>View Payment History</span>
              </button>
              <button mat-menu-item type="button" (click)="viewHistory(row)">
                <mat-icon style="color: #d3d3d3"> history </mat-icon>
                <span>View History</span>
              </button>

              <!-- For Secondary Claims -->
              <button
                *ngIf="row?.initialERAReceived"
                mat-menu-item
                type="button"
                (click)="fileOtherClaim(row)"
              >
                <mat-icon style="color: #d69f20"> difference </mat-icon>
                <span *ngIf="!row?.secondaryClaimCode">File Secondary</span>
                <span *ngIf="row?.secondaryClaimCode">View Secondary</span>
              </button>

              <button
                *ngIf="row?.billingStatus === 'Archived'"
                mat-menu-item
                type="button"
                (click)="unarchiveClaim(row)"
              >
                <mat-icon style="color: #20d684"> widgets </mat-icon>
                Unarchive Claim
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</mat-card>
