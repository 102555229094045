import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentReviewApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentReviewList(pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/treatmentreviewslist`, { params: params });
    }

    getTreatmentReviewDetails(treatmentreviewId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/treatmentreviews/` + treatmentreviewId);
    }

    addTreatmentReview(thisTreatmentReview: ITreatmentReview): Observable<ITreatmentReview> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<ITreatmentReview>(`${environment.apiUrl}/treatmentreviews`, thisTreatmentReview, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateTreatmentReview(thisTreatmentReview: ITreatmentReview): Observable<ITreatmentReview> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentReview>(`${environment.apiUrl}/treatmentreviews`, thisTreatmentReview, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}