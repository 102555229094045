import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { DataTablesModel } from 'src/app/_models/data-tables-modal';
import {
  DateTimeZoneService,
  PatientInsuranceApiService,
} from 'src/app/_services';
import { EligibilityTransactionDetailsDialogComponent } from './dialogs/eligibility-transaction-details-dialog/eligibility-transaction-details-dialog.component';

@Component({
  selector: 'app-view-eligibility-reports',
  templateUrl: './view-eligibility-reports.component.html',
  styleUrl: './view-eligibility-reports.component.css',
})
export class ViewEligibilityReportsComponent implements OnInit {
  @Input() insuranceId: string;
  @Input() displayTitle: boolean = true;
  @Input() sessionId: string;
  @Input() reloadEligibilityReport: Subject<boolean> = new Subject<boolean>();

  currentTimezone: string;
  searchStartDate: Date;
  searchEndDate: Date;
  payerName = '';
  memberName: string = '';
  searchStatus: string = '';
  searchStatusList = ['', 'Processing', 'Success'];

  data: DataTablesModel = {} as DataTablesModel;
  processing: boolean = true;
  apiError = false;

  displayedColumns = [
    'createdDate',
    'serviceDate',
    'serviceType',
    'status',
    'actions',
  ];

  constructor(
    public dialog: MatDialog,
    private patientEligibilityService: PatientInsuranceApiService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.page = 0;
    this.data.per_page = 5;
    if (!this.insuranceId) {
      this.displayedColumns = [
        'createdDate',
        'memberName',
        'subscriber',
        'payer',
        'serviceDate',
        'serviceType',
        'status',
        'actions',
      ];
      this.data.per_page = 10;
    }
    this.loadEligibilityReports();

    this.reloadEligibilityReport.subscribe((reloadReport) => {
      if (reloadReport) {
        this.loadEligibilityReports();
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadEligibilityReports();
  }

  loadEligibilityReports() {
    this.processing = true;
    this.apiError = false;
    // Calling in the API To load all the eligibility reports
    if (this.insuranceId) {
      this.patientEligibilityService
        .getEligibilityReport(
          this.insuranceId,
          this.searchStartDate,
          this.searchEndDate,
          this.searchStatus,
          this.data.per_page,
          this.data.page
        )
        .subscribe({
          next: (response) => {
            if (response && response.items) {
              this.data.items = response.items;
              this.data.total = response.total;
            } else {
              this.data.items = [];
              this.data.total = 0;
            }
            this.processing = false;
          },
          error: (error) => {
            this.processing = false;
            this.apiError = true;
          },
        });
    } else {
      this.patientEligibilityService
        .getOrgEligibilityTransactions(
          this.memberName,
          this.payerName,
          this.searchStartDate,
          this.searchEndDate,
          this.searchStatus,
          this.sessionId,
          this.data.per_page,
          this.data.page
        )
        .subscribe({
          next: (response) => {
            if (response && response.items) {
              this.data.items = response.items;
              this.data.total = response.total;
            } else {
              this.data.items = [];
              this.data.total = 0;
            }
            this.processing = false;
          },
          error: (error) => {
            this.processing = false;
            this.apiError = true;
          },
        });
    }
  }

  public displayEligibilityTransactionDetails(row: any) {
    this.dialog.open(EligibilityTransactionDetailsDialogComponent, {
      data: { row: row },
      disableClose: true,
      autoFocus: false,
    });
  }

  // Search options
  searchChanged() {
    this.data.page = 0;
    this.loadEligibilityReports();
  }
  // Search options
  optionChanged(option) {
    this.searchStatus = option;
    this.data.page = 0;
    this.loadEligibilityReports();
  }
  // Clear search dates
  clearDate() {
    this.searchStartDate = null;
    this.searchEndDate = null;

    this.searchChanged();
  }

  getDateInFormat(row) {
    if (row?.request?.encounter?.beginningDateOfService) {
      let yyyymmdd = row?.request?.encounter?.beginningDateOfService;
      const year = yyyymmdd.slice(0, 4);
      const month = yyyymmdd.slice(4, 6) - 1; // Months are 0-based in JS Date
      const day = yyyymmdd.slice(6, 8);

      const date = new Date(year, month, day);

      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    } else {
      return '';
    }
  }
}
