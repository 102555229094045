<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Active Member Diagnosis</p>

    <button mat-mini-fab style="background-color: transparent; color: #555555" aria-label="close dialog"
      mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>

  <div class="row" style="margin-top: 20px">
    <div class="col-md-12" style="text-align: right">
      <button mat-raised-button color="warn" type="button" (click)="addNewDiagnosis()">
        + Add New Diagnosis Code
      </button>
    </div>

    <div class="col-md-12">
      <mat-error *ngIf="!isLoadingResults && existingDiagnosisList.total == 0">
        No Active Diagnosis found. Please add new diagnosis.
      </mat-error>

      <div class="table-responsive" *ngIf="existingDiagnosisList.total > 0">
        <table mat-table [dataSource]="existingDiagnosisList.items">
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>ICD Code</th>
            <td mat-cell *matCellDef="let row" style="max-width: 350px">
              <b>{{ row.icdCode }}</b>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">
              {{ row.shortDesc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <button *ngIf="!checkIfAlreadyExists(row)" mat-raised-button color="primary"
                (click)="selectDiagnosis(row)">
                Select
              </button>
              <mat-error *ngIf="checkIfAlreadyExists(row)">Already Selected</mat-error>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="existingDiagnosisList.total" [pageSize]="existingDiagnosisList.per_page"
          *ngIf="existingDiagnosisList.total > existingDiagnosisList.per_page" (page)="getNext($event)"
          [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </div>
  </div>
</div>