import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { MbscModule } from '@mobiscroll/angular';
import { NgChartsModule } from 'ng2-charts';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPrintModule } from 'ngx-print';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AngularMaterialModule } from '../../material-imports';
import { MemberGoalsModule } from '../modules/member-goals/member-goals.module';
import { CareLogAddedListComponent } from './care-activities/carelog-added-list/carelog-added-list.component';
import { CaseNotesAvailableListComponent } from './care-activities/carelog-available-list/carelog-available-list.component';
import { CaseNoteCardComponent } from './care-activities/carelog-card/carelog-card.component';
import { AddCareLogDialogComponent } from './care-activities/carelog-dialogs/carelog-add/carelog-add.component';
import { CarelogBehavioralIncidentComponent } from './care-activities/carelog-dialogs/carelog-behavioral-incident/carelog-behavioral-incident.component';
import { CarelogBowelMovementComponent } from './care-activities/carelog-dialogs/carelog-bowel-movement/carelog-bowel-movement.component';
import { CarelogDialogSelectorComponent } from './care-activities/carelog-dialogs/carelog-dialog-selector/carelog-dialog-selector.component';
import { CarelogSeizureLogComponent } from './care-activities/carelog-dialogs/carelog-dialog-selector/carelog-seizure-log/carelog-seizure-log.component';
import { GetGoalDialogComponent } from './care-activities/carelog-dialogs/carelog-goal/carelog-goal.component';
import { CarelogSleepRecordComponent } from './care-activities/carelog-dialogs/carelog-sleep-record/carelog-sleep-record.component';
import { CarelogUrinalDrugAnalysisComponent } from './care-activities/carelog-dialogs/carelog-urinal-drug-analysis/carelog-urinal-drug-analysis.component';
import { CarelogVitalsComponent } from './care-activities/carelog-dialogs/carelog-vitals/carelog-vitals.component';
import { CaseNotesListComponent } from './care-activities/carelog-list/carelog-list.component';
import { CarelogComponent } from './care-activities/carelog.component';
import { DrugScreenResultComponent } from './care-activities/drug-screen-result/drug-screen-result.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CaseHeaderComponent } from './case-header/case-header.component';
import { CaseIncidentsComponent } from './case-incidents/case-incidents.component';
import { AddEditBehaviorIncidentDialogComponent } from './case-incidents/dialogs/add-edit-behavior-incident-dialog/add-edit-behavior-incident-dialog.component';
import { CaseNotesComponent } from './case-notes/case-notes.component';
import { AddCaseNotesDialogComponent } from './case-notes/dialogs/add/add.component';
import { CasePrintComponent } from './case-print/case-print.component';
import { AddEditCaseTasksComponent } from './case-tasks/add-edit-case-tasks/add-edit-case-tasks.component';
import { CaseTasksComponent } from './case-tasks/case-tasks.component';
import { CaseTreatmentComponent } from './case-treatment/case-treatment.component';
import { CaseAdmissionCareTeamComponent } from './case-treatment/sections/admissions/case-admission-care-team/case-admission-care-team.component';
import { AddEditCaseTreatmentTeamDialogComponent } from './case-treatment/sections/admissions/case-admission-care-team/dialog/add-edit-case-treatment-team-dialog/add-edit-case-treatment-team-dialog.component';
import { CaseAdmissionDiagnosisComponent } from './case-treatment/sections/admissions/case-admission-diagnosis/case-admission-diagnosis.component';
import { CaseAdmissionFinancialComponent } from './case-treatment/sections/admissions/case-admission-financial/case-admission-financial.component';
import { CaseAdmissionLegalComponent } from './case-treatment/sections/admissions/case-admission-legal/case-admission-legal.component';
import { CaseTreatmentAdmissionProfileComponent } from './case-treatment/sections/admissions/case-admission-profile/case-admission-profile.component';
import { CaseAdmissionSubstanceComponent } from './case-treatment/sections/admissions/case-admission-substance/case-admission-substance.component';
import { CaseAsamAdmissionOngoingComponent } from './case-treatment/sections/admissions/case-asam-admission-ongoing/case-asam-admission-ongoing.component';
import { CaseTreatmentAsamComponent } from './case-treatment/sections/case-treatment-asam/case-treatment-asam.component';
import { TreatmentAsamReportComponent } from './case-treatment/sections/case-treatment-asam/treatment-asam-report/treatment-asam-report.component';
import { CaseTreatmentIntakeComponent } from './case-treatment/sections/case-treatment-intake/case-treatment-intake.component';
import { AddEditTreatmentProgramsDialogComponent } from './case-treatment/sections/discharge/dialog/add-edit-treatment-discharge-programs-dialog/add-edit-treatment-discharge-programs-dialog.component';
import { DischargeLegalComponent } from './case-treatment/sections/discharge/discharge-legal/discharge-legal.component';
import { DischargeProfileComponent } from './case-treatment/sections/discharge/discharge-profile/discharge-profile.component';
import { DischargeStatusComponent } from './case-treatment/sections/discharge/discharge-status/discharge-status.component';
import { DischargeSubstanceUseComponent } from './case-treatment/sections/discharge/discharge-substance-use/discharge-substance-use.component';
import { DischargeSummaryComponent } from './case-treatment/sections/discharge/discharge-summary/discharge-summary.component';
import { DischargeComponent } from './case-treatment/sections/discharge/discharge.component';
import { CarePlanOverviewComponent } from './case-treatment/sections/plan/care-plan-overview/care-plan-overview.component';
import { CarePlanTargetComponent } from './case-treatment/sections/plan/care-plan-problems/care-plan-problems.component';
import { AddEditTreatmentPlanTargetDialogComponent } from './case-treatment/sections/plan/care-plan-problems/dialog/add-edit-treatment-plan-problems-dialog/add-edit-treatment-plan-problems-dialog.component';
import { CarePlanProfileComponent } from './case-treatment/sections/plan/care-plan-profile/care-plan-profile.component';
import { CarePlansComponent } from './case-treatment/sections/plan/care-plans.component';
import { AddEditTreatmentPlansDialogComponent } from './case-treatment/sections/plan/dialog/add-edit-treatment-plans-dialog/add-edit-treatment-plans-dialog.component';
import { AddEditTreatmentReviewsDialogComponent } from './case-treatment/sections/review/dialog/add-edit-treatment-reviews-dialog/add-edit-treatment-reviews-dialog.component';
import { TreatmentReviewCommentsComponent } from './case-treatment/sections/review/treatment-review-comments/treatment-review-comments.component';
import { TreatmentReviewTargetComponent } from './case-treatment/sections/review/treatment-review-outline/treatment-review-outline.component';
import { TreatmentReviewProfileComponent } from './case-treatment/sections/review/treatment-review-profile/treatment-review-profile.component';
import { AddEditTreatmentReviewTeamDialogComponent } from './case-treatment/sections/review/treatment-review-team/dialog/add-edit-treatment-review-team-dialog/add-edit-treatment-review-team-dialog.component';
import { TreatmentReviewTeamComponent } from './case-treatment/sections/review/treatment-review-team/treatment-review-team.component';
import { TreatmentReviewsComponent } from './case-treatment/sections/review/treatment-reviews.component';
import { AddDocumentDialogComponent } from './documents/dialogs/add/add.component';
import { DocumentsComponent } from './documents/documents.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressComponent } from './file-upload/progress/progress.component';
import { PrintBowelReportComponent } from './print-center/print-bowel-report/print-bowel-report.component';
import { PrintCarelogReportComponent } from './print-center/print-carelog-report/print-carelog-report.component';
import { PrintPlanTreatmentReportComponent } from './print-center/print-plan-treatment-report/print-plan-treatment-report.component';
import { PrintSeizureReportComponent } from './print-center/print-seizure-report/print-seizure-report.component';
import { PrintSleepReportComponent } from './print-center/print-sleep-report/print-sleep-report.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    PdfJsViewerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipesModule,
    NgxPrintModule,
    MbscModule,
    CustomDirectivesModule,
    NgxMaterialTimepickerModule,
    MemberGoalsModule,
    NgChartsModule
  ],
  declarations: [
    CaseDetailsComponent,
    CarelogComponent,
    CaseHeaderComponent,
    CaseNotesListComponent,
    CaseNoteCardComponent,
    AddCareLogDialogComponent,
    GetGoalDialogComponent,
    DocumentsComponent,
    FileUploadComponent,
    ProgressComponent,
    CaseTasksComponent,
    AddEditCaseTasksComponent,
    CasePrintComponent,
    CaseNotesAvailableListComponent,
    CareLogAddedListComponent,
    CarelogVitalsComponent,
    CaseNotesComponent,
    AddCaseNotesDialogComponent,
    AddDocumentDialogComponent,
    CarelogBowelMovementComponent,
    CarelogSeizureLogComponent,
    CarelogSleepRecordComponent,
    CaseIncidentsComponent,
    AddEditBehaviorIncidentDialogComponent,
    PrintCarelogReportComponent,
    PrintBowelReportComponent,
    PrintSeizureReportComponent,
    PrintSleepReportComponent,
    CarelogDialogSelectorComponent,
    CarelogBehavioralIncidentComponent,
    CarelogUrinalDrugAnalysisComponent,
    DrugScreenResultComponent,
    CaseTreatmentComponent,
    CaseTreatmentIntakeComponent,
    CaseTreatmentAdmissionProfileComponent,
    CaseAdmissionFinancialComponent,
    CaseAdmissionSubstanceComponent,
    CaseAdmissionLegalComponent,
    CaseAsamAdmissionOngoingComponent,
    CaseTreatmentAsamComponent,
    CaseAdmissionDiagnosisComponent,
    CaseAdmissionCareTeamComponent,
    CarePlansComponent,
    CarePlanProfileComponent,
    CarePlanOverviewComponent,
    CarePlanTargetComponent,
    DischargeComponent,
    DischargeSummaryComponent,
    AddEditCaseTreatmentTeamDialogComponent,
    AddEditTreatmentPlansDialogComponent,
    AddEditTreatmentPlanTargetDialogComponent,
    TreatmentReviewsComponent,
    AddEditTreatmentReviewsDialogComponent,
    TreatmentReviewProfileComponent,
    TreatmentReviewTeamComponent,
    TreatmentReviewCommentsComponent,
    TreatmentReviewTargetComponent,
    AddEditTreatmentReviewTeamDialogComponent,
    AddEditTreatmentProgramsDialogComponent,
    PrintPlanTreatmentReportComponent,
    DischargeLegalComponent,
    DischargeProfileComponent,
    DischargeStatusComponent,
    DischargeSubstanceUseComponent,
    TreatmentAsamReportComponent,
  ],
  exports: [CaseDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CasesModule { }
