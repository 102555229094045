import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentPlanTarget } from 'src/app/_models/case/treatment/plan/treatmentplantargets.model';
import { TreatmentPlanTargetApiService } from 'src/app/_services/case/treatment/plan/treatmentplantarget.service';
import { MemberGoalsComponent } from 'src/app/pages/modules/member-goals/member-goals/member-goals.component';
import { AddEditTreatmentPlanTargetDialogComponent } from './dialog/add-edit-treatment-plan-problems-dialog/add-edit-treatment-plan-problems-dialog.component';

@Component({
  selector: 'app-care-plan-problems',
  templateUrl: './care-plan-problems.component.html',
  styleUrl: './care-plan-problems.component.css',
})
export class CarePlanTargetComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;

  displayedColumns = [
    'sequenceNumber',
    'description',
    'dimensions',
    'status',
    'goals',
    'actions',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public treatmentPlanTargetApiService: TreatmentPlanTargetApiService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.loadTreatmentPlanTargetList();
  }

  addTreatmentPlanTarget() {
    const dialogRef = this.dialog.open(
      AddEditTreatmentPlanTargetDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'ADD',
          target: null,
          case: this.case,
          selectedPlan: this.selectedPlan,
        },
      }
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentPlanTargetList();
      }
    });
  }

  public editTreatmentPlanTarget(target: ITreatmentPlanTarget) {
    const dialogRef = this.dialog.open(
      AddEditTreatmentPlanTargetDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'EDIT',
          target,
          case: this.case,
          selectedPlan: this.selectedPlan,
        },
      }
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentPlanTargetList();
      }
    });
  }

  public openGoalsInModalWindow(target: ITreatmentPlanTarget) {
    const dialogRef = this.dialog.open(MemberGoalsComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
      data: {
        action: 'EDIT',
        targetId: target.id,
        patient: this.patient,
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTreatmentPlanTargetList();
      }
    });
  }

  public loadTreatmentPlanTargetList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.treatmentPlanTargetApiService
      .getTreatmentPlanTargetList(
        this.selectedPlan.id,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTreatmentPlanTargetList();
  }
}
