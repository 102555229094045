import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { DataTablesModel, ITherapySession } from 'src/app/_models';
import { IBpsaMedicalHistory } from 'src/app/_models/session/clinical/bpsa-clinical-medical-history.model';
import { NDCMedicationService, ToastMessageService } from 'src/app/_services';
import { BpsaMedicalHistoryApiService } from 'src/app/_services/session/clinical/bpsa-clinical-medical-history.service';

@Component({
  selector: 'app-member-medical-history',
  templateUrl: './member-medical-history.component.html',
  styleUrls: ['./member-medical-history.component.css'],
})
export class MemberMedicalHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsamedicalhistory: IBpsaMedicalHistory;

  public action: string;
  public bpsamedicalhistoryForm: FormGroup;
  key: string;
  processing: boolean = false;

  ndcMedicationData: DataTablesModel = {} as DataTablesModel;
  isNDCMedicationLoading: boolean = false;

  constructor(
    public bpsamedicalhistoryApiService: BpsaMedicalHistoryApiService,
    private ndcMedicationService: NDCMedicationService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.ndcMedicationData.per_page = 25;
    this.ndcMedicationData.page = 0;

    this.bpsamedicalhistory = this.therapySession?.bpsaMedicalHistory;
    this.key = this.route.snapshot.paramMap.get('id');

    this.buildForm();

    this.loadBpsaMedicalHistory();
  }

  private loadNDCMedications(medicationName) {
    this.isNDCMedicationLoading = true;

    this.ndcMedicationService
      .getMedicationInformation(
        medicationName,
        this.ndcMedicationData.per_page,
        this.ndcMedicationData.page
      )
      .subscribe(
        (response) => {
          this.isNDCMedicationLoading = false;
          if (response && response.items) {
            this.ndcMedicationData.items = response.items;
            this.ndcMedicationData.total = response.total;
          }
          if (response == null) {
            this.ndcMedicationData.items = [];
            this.ndcMedicationData.total = 0;
          }
        },
        (error) => {
          this.isNDCMedicationLoading = false;
        }
      );
  }

  loadBpsaMedicalHistory() {
    if (this.bpsamedicalhistory) {
      this.bpsamedicalhistoryForm.controls[
        'takingPrescriptionMedication'
      ].setValue(this.bpsamedicalhistory.takingPrescriptionMedication);

      if (this.bpsamedicalhistory.prescriptionMedications) {
        if (this.bpsamedicalhistory.prescriptionMedications.length > 0) {
          for (
            var i = 0;
            i < this.bpsamedicalhistory.prescriptionMedications.length;
            i++
          ) {
            this.addMedication('prescriptionMedications');
          }
          this.bpsamedicalhistoryForm.controls[
            'prescriptionMedications'
          ].setValue(this.bpsamedicalhistory.prescriptionMedications);
        }
      }

      this.bpsamedicalhistoryForm.controls[
        'takingOverTheCounterMedication'
      ].setValue(this.bpsamedicalhistory.takingOverTheCounterMedication);

      if (this.bpsamedicalhistory.overTheCounterMedications) {
        if (this.bpsamedicalhistory.overTheCounterMedications.length > 0) {
          for (
            var i = 0;
            i < this.bpsamedicalhistory.overTheCounterMedications.length;
            i++
          ) {
            this.addMedication('overTheCounterMedications');
          }
          this.bpsamedicalhistoryForm.controls[
            'overTheCounterMedications'
          ].setValue(this.bpsamedicalhistory.overTheCounterMedications);
        }
      }
    }

    // Initialize Auto save
    this.initiateAutoSave();
  }

  onBpsaMedicalHistoryFormSubmit() {
    this.bpsamedicalhistory = Object.assign({}, this.bpsamedicalhistory);
    this.bpsamedicalhistory = Object.assign(
      this.bpsamedicalhistory,
      this.bpsamedicalhistoryForm.value
    );

    this.processing = true;
    this.bpsamedicalhistoryApiService
      .updateBpsaMedicalHistory(this.bpsamedicalhistory)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsamedicalhistoryForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      takingPrescriptionMedication: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      prescriptionMedications: this.formBuilder.array([]),
      takingOverTheCounterMedication: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },

        Validators.compose([Validators.required])
      ),
      overTheCounterMedications: this.formBuilder.array([]),
    });
  }

  initiateAutoSave() {
    this.bpsamedicalhistoryForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaMedicalHistoryFormSubmit();
      });
  }

  addMedication(controlName: string) {
    const control = <FormArray>(
      this.bpsamedicalhistoryForm.controls[controlName]
    );

    control.push(this.getMedication());

    for (let i = 0; i < control.length; i++) {
      control
        .at(i)
        .get('medication')
        .valueChanges.subscribe((newValue) => {
          // Load NDC List
          this.loadNDCMedications(newValue);
        });
    }
  }

  removeMedication(i: number, controlName: string) {
    const control = <FormArray>(
      this.bpsamedicalhistoryForm.controls[controlName]
    );
    control.removeAt(i);
  }

  clearAllMedication(controlName: string) {
    const control = <FormArray>(
      this.bpsamedicalhistoryForm.controls[controlName]
    );
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  }

  checkPrescriptionMedication(controlName: string) {
    let selectedAnswer =
      this.bpsamedicalhistoryForm.controls['takingPrescriptionMedication']
        .value;

    if (selectedAnswer === 'Yes') {
      this.addMedication(controlName);
    } else {
      this.clearAllMedication(controlName);
    }
  }

  checkCounterMedication(controlName: string) {
    let selectedAnswer =
      this.bpsamedicalhistoryForm.controls['takingOverTheCounterMedication']
        .value;

    if (selectedAnswer === 'Yes') {
      this.addMedication(controlName);
    } else {
      this.clearAllMedication(controlName);
    }
  }

  getMedication() {
    return this.formBuilder.group({
      medication: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.required
      ),
      dosage: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.required
      ),
      frequency: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.required
      ),
      reasons: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (this.therapySession.physicianId != this.loggedInUserId &&
            this.loggedInUserId != this.therapySession?.supervisingRPId),
      }),
      productId: new FormControl(''),
      productNDC: new FormControl(''),
      productTypeName: new FormControl(''),
      proprietaryName: new FormControl(''),
      dosageFormName: new FormControl(''),
      routeName: new FormControl(''),
      marketingCategoryName: new FormControl(''),
      applicationNumber: new FormControl(''),
      labelerName: new FormControl(''),
      substanceName: new FormControl(''),
      activeNumeratorStrength: new FormControl(''),
      activeIngredUnit: new FormControl(''),
      pharmClasses: new FormControl(''),
    });
  }

  updateMedicationInfo(controlName, index, info) {
    const control = <FormArray>(
      this.bpsamedicalhistoryForm.controls[controlName]
    );

    control
      .at(index)
      .get('dosage')
      .setValue(info?.activeNumeratorStrength + ' ' + info?.activeIngredUnit);

    control.at(index).get('productId').setValue(info?.productId);
    control.at(index).get('productNDC').setValue(info?.productNDC);
    control.at(index).get('productTypeName').setValue(info?.productTypeName);
    control.at(index).get('proprietaryName').setValue(info?.proprietaryName);
    control.at(index).get('dosageFormName').setValue(info?.dosageFormName);
    control.at(index).get('routeName').setValue(info?.routeName);
    control
      .at(index)
      .get('marketingCategoryName')
      .setValue(info?.marketingCategoryName);
    control
      .at(index)
      .get('applicationNumber')
      .setValue(info?.applicationNumber);
    control.at(index).get('labelerName').setValue(info?.labelerName);
    control.at(index).get('substanceName').setValue(info?.substanceName);
    control
      .at(index)
      .get('activeNumeratorStrength')
      .setValue(info?.activeNumeratorStrength);
    control.at(index).get('activeIngredUnit').setValue(info?.activeIngredUnit);
    control.at(index).get('pharmClasses').setValue(info?.pharmClasses);
  }
}
