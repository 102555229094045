import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  DataTablesModel,
  IGuestAssessment,
  IGuestIntakeRequest,
  ResponseModal,
} from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GuestLinkService {
  constructor(private httpClient: HttpClient) {}

  verifyUserInformation(body: IGuestAssessment): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/verifyuserinformation`,
        body,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  verifyTelehealthUserInformation(body: IGuestAssessment): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/verifytelehealthuserinformation`,
        body,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  validateAccessCodeForAssessment(body: IGuestAssessment): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/verifyassessmentcode`,
        body,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  validateAccessCodeForTelehealth(body: IGuestAssessment): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/verifytelehealthassesscode`,
        body,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  verifyIntakeUserInformation(body: IGuestIntakeRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestintake/verifyuserinformation`,
        body,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  validateAccessCodeForIntake(body: IGuestIntakeRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/guestintake/verifyaccesscode`, body, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  findPatientIntakeToDoList(
    requestId: string,
    accessCode: string
  ): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/guestintake/myintaketodolist/${requestId}/${accessCode}`
    );
  }

  getDocumentDetails(requestId: string): Observable<ResponseModal> {
    let path = 'patient';
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/memberintake-document/` + requestId
    );
  }
}
