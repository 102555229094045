import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { Role } from 'src/app/_models';

export function capitalizeFirstLetters(name: string) {
  if (!name || name === null || name === undefined) {
    return name;
  }
  return name
    .split(' ')
    .map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1);
    })
    .join(' ');
}

export function applyFormValues(group, formValues) {
  Object.keys(formValues).forEach((key) => {
    const formControl = group.controls[key] as FormControl;
    if (formControl instanceof FormGroup) {
      this.applyFormValues(formControl, formValues[key]);
    } else {
      formControl.setValue(formValues[key]);
    }
  });
}

export function conditionalValidation(
  validators: Validators[],
  boolStatement: boolean
): Validators[] {
  if (boolStatement) {
    return validators;
  } else {
    return [];
  }
}

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(252, 163, 23)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  peach: 'rgb(244, 192, 149)',
  darkGreen: 'rgb(7, 30, 34)',
  bloody: 'rgb(219, 80, 74)',
  greyishBlue: 'rgb(79, 109, 122)',
  brown: 'rgb(137, 63, 4)',
  midnightBlue: 'rgb(25, 25, 112)',
  champagneGold: 'rgb(255, 204, 102)',
  burgundy: 'rgb(128, 0, 32)',
  charcoalGray: 'rgb(64, 64, 64)',
  roseQuartz: 'rgb(227, 175, 175)',
  royalPurple: 'rgb(102, 51, 153)',
  ebonyBlack: 'rgb(0, 0, 0)',
  coralBlush: 'rgb(255, 127, 80)',
  tealGreen: 'rgb0, 128, 128)',
  pearlWhite: 'rgb(255, 250, 250)',
  deepMaroon: 'rgb(128, 0, 0)',
  silverSage: 'rgb(150, 184, 172)',
  navyElegance: 'rgb(36, 46, 64)',
};

export const greenChart: any = {
  borderColor: '#355a36',
  backgroundColor: '#5a9e7c',
};
export const yellowChart: any = {
  borderColor: '#665526',
  backgroundColor: '#FFCC00',
};
export const orangeChart: any = {
  borderColor: '#cc8006',
  backgroundColor: '#FF9900',
};
export const purpleChart: any = {
  borderColor: '#3f2b69',
  backgroundColor: '#ab5570',
};
export const pinkChart: any = {
  borderColor: '#b71c1c',
  backgroundColor: '#ef7ab4',
};
export const redChart: any = {
  borderColor: '#b71c1c',
  backgroundColor: '#ff6384',
};

export function hexToRGBA(hex: string, alpha: number): string {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function buildValidationMsgs(
  formChunk: FormGroup | FormArray,
  errorClass: object,
  validationMessages
) {
  for (const controlName in formChunk.controls) {
    if (formChunk.controls.hasOwnProperty(controlName)) {
      const control = formChunk.get(controlName);
      if ((control as FormGroup | FormArray).controls) {
        errorClass[controlName] = {};
        buildValidationMsgs(
          control as FormGroup | FormArray,
          errorClass[controlName],
          validationMessages
        );
      } else {
        errorClass[controlName] = '';
        if (control && control.dirty && control.touched && !control.valid) {
          const messages = validationMessages[controlName];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              errorClass[controlName] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
}

export function uniqueArrayItems<T>(inArray: T[]): T[] {
  return inArray.filter((item, index) => inArray.indexOf(item) === index);
}

export function formatDate(date: Date): string {
  if (isNaN(date.getTime())) {
    return '';
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return ('00' + month).slice(-2) + '/' + ('00' + day).slice(-2) + '/' + year;
  }
}

export function formatDateTime(date: Date): string {
  if (date instanceof moment) {
    date = new Date(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'));
  }
  if (isNaN(date.getTime())) {
    return '';
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    var ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return (
      ('00' + month).slice(-2) +
      '/' +
      ('00' + day).slice(-2) +
      '/' +
      year +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ' ' +
      ampm
    );
  }
}

export function mmddyyyyToDate(dateStr: string): Date {
  if (dateStr === null || dateStr === '') {
    return null;
  }
  const date = dateStr.split('/');
  return new Date(Number(date[2]), Number(date[0]) - 1, Number(date[1]));
}

export function mmddyyyyhhmiToDate(dateStr: string): Date {
  if (dateStr === null || dateStr === '') {
    return null;
  }
  const dateStrSplit = dateStr.split(' ');
  const date = dateStrSplit[0].split('/');
  const time = dateStrSplit[1].split(':');
  return new Date(
    Number(date[2]),
    Number(date[0]) - 1,
    Number(date[1]),
    Number(time[0]),
    Number(time[1])
  );
}

export function mmddyyyyStringFromDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + day + year;
}

export function randonString(length) {
  var result = [];
  var characters = 'BCDFGHJKLMNPQRSTVWXYZ123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join('');
}

export function updateURLParameter(
  url: string,
  param: string,
  paramVal: string
): string {
  if (!url) {
    return undefined;
  }
  let newAdditionalURL = '';
  let tempArray = url.split('?');
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = '';
  if (additionalURL) {
    tempArray = additionalURL.split('&');
    for (const item of tempArray) {
      if (item.split('=')[0] !== param) {
        newAdditionalURL += temp + item;
        temp = '&';
      } else {
        newAdditionalURL += temp + '' + param + '=' + paramVal;
        temp = '&';
      }
    }
  }
  // let rows_txt = temp + '' + param + '=' + paramVal;
  return baseURL + '?' + newAdditionalURL;
}

export const DEFAULT_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export function printableTimePeriod(start: any, end: any): string {
  return getPrintableHour(start) + '-' + getPrintableHour(end);
}

export function getPrintableHour(hour: number): string {
  if (hour < 12) {
    if (hour < 1) hour = 12;
    return hour * 1 + 'AM';
  } else {
    if (hour > 12) hour = hour - 12;
    return hour + 'PM';
  }
}

export function hasAccess(
  keycloakService: KeycloakService,
  accessTo: String,
  permissions: String[],
  caseStatus: String
): boolean {
  if (caseStatus != null && caseStatus.toLowerCase() === 'closed') {
    if (accessTo.endsWith('_VIEW')) {
      if (keycloakService.isUserInRole(Role.SuperOrgAdmin)) {
        return true;
      } else {
        if (permissions && permissions.includes(accessTo)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  } else if (keycloakService.isUserInRole(Role.SuperOrgAdmin)) {
    return true;
  } else {
    if (permissions && permissions.includes(accessTo)) {
      return true;
    } else {
      return false;
    }
  }
}
export function calculateAgeInMonths(dateOfBirth): number {
  const currentDate = new Date();
  const birthDate = new Date(dateOfBirth);

  let months;
  months = (currentDate.getFullYear() - birthDate.getFullYear()) * 12;
  months -= birthDate.getMonth();
  months += currentDate.getMonth();

  // If the baby's birth day is later in the month than the current day, subtract a month
  if (birthDate.getDate() > currentDate.getDate()) {
    months--;
  }

  return months < 0 ? 0 : months;
}

export function convertDateToYYYYMMDDFormat(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-based, so add 1
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}${month}${day}`;
}

export function extractDateFromYYYYMMDDFormat(dateString: string): Date {
  let year = parseInt(dateString.substring(0, 4));
  let month = parseInt(dateString.substring(4, 6)) - 1; // Month is zero-indexed in JavaScript Date
  let day = parseInt(dateString.substring(6, 8));

  // Create a new Date object
  return new Date(year, month, day);
}

export function extractPDFFileNameFromCustomFormTitle(input: string): string {
  // Trim the input to remove leading and trailing spaces
  const trimmedInput = input.trim();

  // Split the trimmed string by spaces and filter out any empty parts
  const words = trimmedInput
    .split(' ')
    .filter((word) => word.length > 0)
    .map((word) => {
      // Convert each word to lowercase and capitalize the first letter
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    });

  // Join the words with an underscore
  return words.join('_');
}
