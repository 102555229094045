import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { KeycloakService } from 'keycloak-angular';
import { Patient } from 'src/app/_models';
import {
  PatientService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services/';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { employmentStatusOptions } from 'src/app/shared/utilities/billing/employmentStatus';
import { ethnicityOptions } from 'src/app/shared/utilities/billing/ethnicity';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import { preferredLanguageOptions } from 'src/app/shared/utilities/billing/preferredLanguages';
import { raceOptions } from 'src/app/shared/utilities/billing/race';
import { TimeZones } from 'src/app/shared/utilities/billing/timeZones';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import {
  buildValidationMsgs,
  formatDate,
  hasAccess,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-patient',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddEditPatientComponent implements OnInit, AfterViewInit {
  @Input() patient: Patient;
  @Input() hasMemberEditAccess: boolean;
  @Output() output = new EventEmitter<any>();

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<AddEditPatientComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    public dialog: MatDialog,
    public patientApiService: PatientService,
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private orgConfigSandbox: OrgConfigSandbox,
    private keycloakService: KeycloakService,
    protected permissionsSandbox: PermissionsSandbox,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.action = data.action;
      this.patient = data.patient;
      this.facilityId = data.facilityId;
      this.isOrgAdmin = data.isOrgAdmin;
      this.showMemberNumber = data.showMemberNumber;
    } else {
      this.isModalWindow = false;
    }
  }

  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  action: string;
  isOrgAdmin: boolean;
  facilityId: string;
  maxDate: Date;
  showMemberNumber: boolean;
  militaryBranchOptions = [
    'None',
    'Air Force',
    'Army',
    'Coast Guard',
    'Marines',
    'Navy',
    'National Guard',
  ];
  militaryReserveBranchOptions = [
    'None',
    'Air Force Active Duty for Special Work Guard',
    'Air Force Active Duty for Special Work Reserves',
    'Air Force Active Guard',
    'Air Force Active Reserves',
    'Army Active Duty for Special Work Guard',
    'Army Active Duty for Special Work Reserves',
    'Army Active Guard',
    'Army Active Reserves',
    'Marine Corps Active Duty for Special Work Reserves',
    'Marine Corps Active Reserves',
    'Naval Active Duty for Special Work Reserves',
    'Naval Active Reserves',
  ];
  armedForcesBranchOptions = [
    'None',
    'Air Force',
    'Army',
    'Coast Guard',
    'Marines',
    'Navy',
    'National Guard',
  ];
  combatTheaterOptions = [
    'Desert Storm',
    'Global War on Terror, Operation Enduring Freedom',
    'Glodbal War on Terror, Operation Iraqi Freedom',
    'Global War on Terror, Other',
    'Korea',
    'Vietnam',
    'WWII',
  ];

  public patientForm: FormGroup;
  // Careproviders
  searchCareProvider: string = '';
  allCareProviders: any[];
  filteredCareProvidersList: any[];
  careProvidersLoading: boolean = false;
  hasMemberAddAccess = false;

  orgConfig: any;
  processing: boolean = false;
  origPatientStatus: string = '';
  isTNwitsMember = false;

  usStates = USStates;
  genderList = Gender;
  raceList = [];
  ethnicityList = [];
  languageList = [];
  employmentList = [];
  timeZoneList = TimeZones;
  marriedStatusList = MarriedStatus;

  showSSN: boolean = false;
  isModalWindow: boolean = true;
  public formErrors: Record<string, Record<string, string>> = {};

  public ngOnInit() {
    this.buildForm();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.raceList = raceOptions(orgConfig.dropDownOptions);
          this.languageList = preferredLanguageOptions(
            orgConfig.dropDownOptions
          );
          this.ethnicityList = ethnicityOptions(orgConfig.dropDownOptions);
          this.employmentList = employmentStatusOptions(
            orgConfig.dropDownOptions
          );
          this.isTNwitsMember = orgConfig.dropDownOptions === 'TNWITS';

          this.permissionsSandbox.permissions$.subscribe((permissions) => {
            this.hasMemberEditAccess = hasAccess(
              this.keycloakService,
              'MEMBERS_EDIT',
              permissions,
              null
            );

            this.hasMemberAddAccess = hasAccess(
              this.keycloakService,
              'MEMBERS_ADD',
              permissions,
              null
            );
          });

          if (this.isTNwitsMember) {
            this.patientForm
              .get('addressType')
              .setValidators([Validators.required]);
            this.patientForm
              .get('militaryStatus')
              .setValidators([Validators.required]);
            this.patientForm
              .get('militaryScreenedForTraumaticBrainInjury')
              .setValidators([Validators.required]);
            this.patientForm
              .get('militaryScreenedForPTSD')
              .setValidators([Validators.required]);
          }
        });
      }
    });

    this.maxDate = new Date();
    this.loadAllActiveCareProviders();

    if (this.patient) {
      this.origPatientStatus = this.patient.status;
      this.patientForm.controls['id'].setValue(this.patient.id);
      this.patientForm.controls['organizationId'].setValue(
        this.patient.organizationId
      );
      this.patientForm.controls['facilityId'].setValue(this.patient.facilityId);
      this.patientForm.controls['firstName'].setValue(this.patient.firstName);
      this.patientForm.controls['middleName'].setValue(this.patient.middleName);
      this.patientForm.controls['lastName'].setValue(this.patient.lastName);

      if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
        this.patientForm.controls['dateOfBirth'].setValue(
          mmddyyyyToDate(this.patient.dateOfBirth)
        );
      }

      if (this.patient.deceasedDate && this.patient.deceasedDate !== '') {
        this.patientForm.controls['deceasedDate'].setValue(
          mmddyyyyToDate(this.patient.deceasedDate)
        );
      }

      if (this.patient.dischargedDate) {
        this.patientForm.controls['dischargedDate'].setValue(
          new Date(this.patient.dischargedDate)
        );
      }

      this.patientForm.controls['gender'].setValue(this.patient.gender);

      this.patientForm.controls['ssn'].setValue(this.patient.ssn);
      this.patientForm.controls['genderAtBirth'].setValue(
        this.patient.genderAtBirth
      );

      this.patientForm.controls['addressType'].setValue(
        this.patient.addressType
      );
      this.patientForm.controls['addressLine1'].setValue(
        this.patient.addressLine1
      );
      this.patientForm.controls['addressLine2'].setValue(
        this.patient.addressLine2
      );
      this.patientForm.controls['city'].setValue(this.patient.city);
      this.patientForm.controls['state'].setValue(this.patient.state);
      this.patientForm.controls['zip'].setValue(this.patient.zip);
      this.patientForm.controls['restrictedMember'].setValue(
        this.patient.restrictedMember
      );

      this.patientForm.controls['phonePreference'].setValue(
        this.patient.phonePreference
      );
      this.patientForm.controls['phoneNumber'].setValue(
        this.patient.phoneNumber
      );
      this.patientForm.controls['homePhoneNumber'].setValue(
        this.patient.homePhoneNumber
      );
      this.patientForm.controls['workPhoneNumber'].setValue(
        this.patient.workPhoneNumber
      );

      this.patientForm.controls['memberNumber'].setValue(
        this.patient.memberNumber
      );
      this.patientForm.controls['status'].setValue(this.patient.status);
      this.patientForm.controls['preferredLanguage'].setValue(
        this.patient.preferredLanguage
      );
      this.patientForm.controls['email'].setValue(this.patient.email);
      this.patientForm.controls['race'].setValue(this.patient.race);
      this.patientForm.controls['ethnicity'].setValue(this.patient.ethnicity);
      this.patientForm.controls['maritalStatus'].setValue(
        this.patient.maritalStatus
      );
      this.patientForm.controls['employmentStatus'].setValue(
        this.patient.employmentStatus
      );
      this.patientForm.controls['education'].setValue(this.patient.education);
      this.patientForm.controls['specialNeeds'].setValue(
        this.patient.specialNeeds
      );
      this.patientForm.controls['timeZone'].setValue(this.patient.timeZone);

      this.patientForm.controls['militaryStatus'].setValue(
        this.patient.militaryStatus
      );
      if (this.patient.militaryBranches)
        this.patientForm.controls['militaryBranches'].setValue(
          this.patient.militaryBranches
        );
      if (this.patient.militaryReserveBranches)
        this.patientForm.controls['militaryReserveBranches'].setValue(
          this.patient.militaryReserveBranches
        );
      this.patientForm.controls['militaryReserveDutyStatus'].setValue(
        this.patient.militaryReserveDutyStatus
      );
      this.patientForm.controls['militaryReserveDutyState'].setValue(
        this.patient.militaryReserveDutyState
      );
      if (this.patient.militaryArmedForcesBranches)
        this.patientForm.controls['militaryArmedForcesBranches'].setValue(
          this.patient.militaryArmedForcesBranches
        );
      if (this.patient.militaryCombatTheaters)
        this.patientForm.controls['militaryCombatTheaters'].setValue(
          this.patient.militaryCombatTheaters
        );

      this.patientForm.controls['militaryTotalCombatDeployments'].setValue(
        this.patient.militaryTotalCombatDeployments
      );
      this.patientForm.controls['militaryCombatDeploymentStatus'].setValue(
        this.patient.militaryCombatDeploymentStatus
      );
      this.patientForm.controls[
        'militaryScreenedForTraumaticBrainInjury'
      ].setValue(this.patient.militaryScreenedForTraumaticBrainInjury);
      this.patientForm.controls[
        'militaryDiagnosedWithTraumaticBrainInjury'
      ].setValue(this.patient.militaryDiagnosedWithTraumaticBrainInjury);
      this.patientForm.controls['militaryScreenedForPTSD'].setValue(
        this.patient.militaryScreenedForPTSD
      );
      this.patientForm.controls['militaryDiagnosedWithPTSD'].setValue(
        this.patient.militaryDiagnosedWithPTSD
      );

      this.patientForm.controls['pronouns'].setValue(this.patient.pronouns);

      if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
        this.patientForm.controls['admittanceDate'].setValue(
          mmddyyyyToDate(this.patient.admittanceDate)
        );
      }

      if (this.patient.height) {
        this.patientForm.controls['height'].setValue(this.patient.height);
      }
      if (this.patient.weight) {
        this.patientForm.controls['weight'].setValue(this.patient.weight);
      }

      this.patientForm.controls['primaryCPId'].setValue(
        this.patient.primaryCPId
      );
      this.patientForm.controls['primaryCPFirstName'].setValue(
        this.patient.primaryCPFirstName
      );
      this.patientForm.controls['primaryCPMiddleName'].setValue(
        this.patient.primaryCPMiddleName
      );
      this.patientForm.controls['primaryCPLastName'].setValue(
        this.patient.primaryCPLastName
      );
      this.patientForm.controls['primaryCPEmail'].setValue(
        this.patient.primaryCPEmail
      );
      this.patientForm.controls['primaryCPPhone'].setValue(
        this.patient.primaryCPPhone
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.patient) {
      const militaryBranches = this.patient.militaryBranches;
      if (militaryBranches) {
        militaryBranches.forEach((branch) => {
          const index = this.militaryBranchOptions.indexOf(branch);
          if (index !== -1) {
            let event: any = {};
            event.checked = true;
            this.onMilitaryBranchCheckboxChange(event, branch, index, true);
            this.militaryBranchControls.at(index).setValue(true);
          }
        });
      }

      const militaryReserveBranches = this.patient.militaryReserveBranches;
      if (militaryReserveBranches) {
        militaryReserveBranches.forEach((branch) => {
          const index = this.militaryReserveBranchOptions.indexOf(branch);
          if (index !== -1) {
            let event: any = {};
            event.checked = true;
            this.onMilitaryReserveBranchCheckboxChange(
              event,
              branch,
              index,
              true
            );
          }
        });
      }

      const militaryArmedForcesBranches =
        this.patient.militaryArmedForcesBranches;
      if (militaryArmedForcesBranches) {
        militaryArmedForcesBranches.forEach((branch) => {
          const index = this.armedForcesBranchOptions.indexOf(branch);
          if (index !== -1) {
            let event: any = {};
            event.checked = true;
            this.onArmedForcesBranchCheckboxChange(event, branch, index, true);
          }
        });
      }

      const combatTheaters = this.patient.militaryCombatTheaters;
      if (combatTheaters) {
        combatTheaters.forEach((branch) => {
          const index = this.combatTheaterOptions.indexOf(branch);
          if (index !== -1) {
            let event: any = {};
            event.checked = true;
            this.onCombatTheaterCheckboxChange(event, branch, index, true);
          }
        });
      }
    }
  }

  // Loading all active care providers
  loadAllActiveCareProviders() {
    this.careProvidersLoading = true;

    this.userApiService.getAllActiveCareProviders(this.facilityId).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.allCareProviders = response.items;
          this.filteredCareProvidersList = response.items;
        } else {
          this.allCareProviders = [];
          this.filteredCareProvidersList = [];
        }
        this.careProvidersLoading = false;
      },
      error: (error) => {
        this.careProvidersLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  // Filter care providers
  filterCareProviders() {
    if (this.searchCareProvider) {
      this.filteredCareProvidersList = this.allCareProviders.filter(
        (provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase()) ||
            concatLastFirst
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredCareProvidersList = [...this.allCareProviders];
    }
  }

  // Load care provider details on form
  careProviderSelected(event) {
    if (event.value) {
      let selectedCareProvider = this.allCareProviders
        .filter((provider) => provider.id === event.value)
        .at(0);

      this.patientForm.controls['primaryCPFirstName'].setValue(
        selectedCareProvider.firstName
      );
      this.patientForm.controls['primaryCPMiddleName'].setValue(
        selectedCareProvider.middleName
      );
      this.patientForm.controls['primaryCPLastName'].setValue(
        selectedCareProvider.lastName
      );
      this.patientForm.controls['primaryCPEmail'].setValue(
        selectedCareProvider.emailAddress
      );
      this.patientForm.controls['primaryCPPhone'].setValue(
        selectedCareProvider.phoneNumber
      );
    } else {
      this.patientForm.controls['primaryCPId'].setValue(null);
      this.patientForm.controls['primaryCPFirstName'].setValue(null);
      this.patientForm.controls['primaryCPMiddleName'].setValue(null);
      this.patientForm.controls['primaryCPLastName'].setValue(null);
      this.patientForm.controls['primaryCPEmail'].setValue(null);
      this.patientForm.controls['primaryCPPhone'].setValue(null);
    }
  }

  onPatientFormSubmit() {
    if (this.patientForm.invalid) {
      this.patientForm.markAllAsTouched();
      return;
    }

    this.patient = Object.assign({}, this.patient);
    this.patient = Object.assign(this.patient, this.patientForm.value);

    //
    if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
      this.patient.dateOfBirth = formatDate(new Date(this.patient.dateOfBirth));
    }

    if (this.patient.deceasedDate && this.patient.deceasedDate !== '') {
      this.patient.deceasedDate = formatDate(
        new Date(this.patient.deceasedDate)
      );
    }

    if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
      this.patient.admittanceDate = formatDate(
        new Date(this.patient.admittanceDate)
      );
    }

    if (!this.patient.dischargedDate && this.patient.status === 'Discharged') {
      this.patient.dischargedDate = new Date();
    }

    if (!this.patient.deceasedDate && this.patient.status === 'Deceased') {
      this.patient.deceasedDate = formatDate(new Date());
    }

    this.processing = true;

    if (this.patient && this.action !== 'ADD') {
      this.patientApiService.updatePatient(this.patient).subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated patient record'
          );
          if (this.dialogRef) this.dialogRef.close('success');
          if (this.output)
            this.output.emit({ eventType: 'RELOAD_PATIENT_PROFILE' });
        },
        error: (error) => {
          this.processing = false;
          if (
            error.text ===
            'Another Member with the same Member Number already exists in the database'
          ) {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with same Member Number already exists. Please update the member number to a new one.'
            );
            this.patientForm.controls['memberNumber'].setErrors({
              duplicate: true,
            });
            (<any>this.patientForm.get('memberNumber')).nativeElement.focus();
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          }
        },
      });
    } else {
      this.patientApiService.addPatient(this.patient).subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully added new patient'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.processing = false;
          if (
            error.text ===
            'Another Member with the same Member Number already exists in the database'
          ) {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with same Member Number already exists. Please update the member number to a new one.'
            );
            this.patientForm.controls['memberNumber'].setErrors({
              duplicate: true,
            });
            (<any>this.patientForm.get('memberNumber')).nativeElement.focus();
          } else if (error.text === 'Duplicate Email') {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with the given email already exists.'
            );
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          }
        },
      });
    }
  }

  private buildForm() {
    this.patientForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),

      facilityId: new FormControl(this.facilityId ? this.facilityId : null),
      firstName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      middleName: new FormControl(''),
      lastName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      ssn: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '999-99-9999' })])
      ),
      genderAtBirth: new FormControl(''),
      email: new FormControl('', Validators.email),
      dateOfBirth: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      gender: new FormControl(null, Validators.compose([Validators.required])),
      addressType: new FormControl(''),
      addressLine1: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      addressLine2: new FormControl(''),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required])),
      zip: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '99999' }),
        ])
      ),

      phonePreference: new FormControl('Cell Phone', Validators.required),
      phoneNumber: new FormControl(
        null,
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      homePhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      workPhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      memberNumber: new FormControl(null),
      mco: new FormControl(null),
      aadhaarNo: new FormControl(null),
      panNo: new FormControl(null),
      status: new FormControl(null),

      deceasedDate: new FormControl(null),
      dischargedDate: new FormControl(null),
      preferredLanguage: new FormControl(null),

      race: new FormControl(''),
      ethnicity: new FormControl(''),
      maritalStatus: new FormControl(''),
      employmentStatus: new FormControl(''),
      education: new FormControl(''),
      specialNeeds: new FormControl(''),
      timeZone: new FormControl(''),
      pronouns: new FormControl(''),
      admittanceDate: new FormControl(''),

      restrictedMember: new FormControl(
        { value: false, disabled: this.isOrgAdmin ? false : true },
        Validators.compose([Validators.required])
      ),

      height: new FormControl(''),
      weight: new FormControl(''),

      // Newly added, primary cp attached to a patient
      primaryCPId: new FormControl(''),
      primaryCPFirstName: new FormControl(''),
      primaryCPMiddleName: new FormControl(''),
      primaryCPLastName: new FormControl(''),
      primaryCPEmail: new FormControl(''),
      primaryCPPhone: new FormControl(''),

      militaryStatus: new FormControl(
        '',
        this.isTNwitsMember ? Validators.required : null
      ),
      militaryBranches: new FormControl([]),
      tmpMilitaryBranchSelections: this.formBuilder.array([]),
      militaryDutyStatus: new FormControl(''),
      militaryReserveBranches: new FormControl([]),
      tmpMilitaryReserveSelections: this.formBuilder.array([]),
      militaryReserveDutyStatus: new FormControl(''),
      militaryReserveDutyState: new FormControl(''),
      militaryArmedForcesBranches: new FormControl([]),
      tmpMilitaryArmedForcesSelections: this.formBuilder.array([]),
      militaryCombatTheaters: new FormControl([]),
      tmpCombatTheaterSelections: this.formBuilder.array([]),
      militaryScreenedForTraumaticBrainInjury: new FormControl(
        '',
        this.isTNwitsMember ? Validators.required : null
      ),
      militaryDiagnosedWithTraumaticBrainInjury: new FormControl(''),
      militaryScreenedForPTSD: new FormControl(
        '',
        this.isTNwitsMember ? Validators.required : null
      ),
      militaryDiagnosedWithPTSD: new FormControl(''),
      militaryTotalCombatDeployments: new FormControl(),
      militaryCombatDeploymentStatus: new FormControl(''),
    });

    this.patientForm.get('militaryStatus')?.valueChanges.subscribe((status) => {
      if (!status || status === 'Never in Military' || status === '') {
        this.resetMilitaryInfo();
      } else {
        this.patientForm
          .get('militaryReserveDutyStatus')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryReserveDutyState')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryReserveBranches')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryArmedForcesBranches')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryCombatTheaters')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryTotalCombatDeployments')
          .setValidators([Validators.required]);
        this.patientForm
          .get('militaryCombatDeploymentStatus')
          .setValidators([Validators.required]);
      }
    });

    this.militaryBranchOptions.forEach(() => {
      (this.patientForm.get('tmpMilitaryBranchSelections') as FormArray).push(
        new FormControl(false)
      );
    });
    this.militaryReserveBranchOptions.forEach(() => {
      (this.patientForm.get('tmpMilitaryReserveSelections') as FormArray).push(
        new FormControl(false)
      );
    });
    this.armedForcesBranchOptions.forEach(() => {
      (
        this.patientForm.get('tmpMilitaryArmedForcesSelections') as FormArray
      ).push(new FormControl(false));
    });

    this.combatTheaterOptions.forEach(() => {
      (this.patientForm.get('tmpCombatTheaterSelections') as FormArray).push(
        new FormControl(false)
      );
    });

    this.patientForm.valueChanges.subscribe(() => {
      this.onValueChanged();
    });
  }

  onValueChanged() {
    if (!this.patientForm) return;
    buildValidationMsgs(this.patientForm, this.formErrors, {});
  }

  confirmPatientStatusChange(event: MatSelectChange) {
    let selectedValue = event.source.triggerValue;
    if (this.origPatientStatus === 'Active' && selectedValue !== 'Active') {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Please Confirm',
          msg:
            'Switching status to "' +
            selectedValue +
            '" will close all the Active Cases.',
          yesButtonTitle: 'Yes',
          noButtonTitle: 'No',
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '20vw',
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (!confirm) {
          this.patientForm.controls['status'].setValue('Active');
        }
      });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.patientForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.patientForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.patientForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.patientForm.controls['addressLine1'].setValue(addressField);
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.patientForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();

      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.patientForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  genderChanged(event) {
    if (event.value === 'O') {
      this.patientForm.get('genderAtBirth').addValidators(Validators.required);
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    } else {
      this.patientForm.get('genderAtBirth').clearValidators();
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    }
  }

  resetMilitaryInfo(): void {
    this.patientForm.patchValue({
      tmpMilitaryBranchSelections: [],
      militaryReserveDutyStatus: '',
      militaryReserveDutyState: '',
      militaryReserveBranches: [],
      militaryArmedForcesBranches: [],
      militaryCombatTheaters: [],
      militaryTotalCombatDeployments: 0,
      militaryCombatDeploymentStatus: '',
    });
    this.patientForm.get('militaryReserveDutyStatus').clearValidators();
    this.patientForm.get('militaryReserveDutyStatus').updateValueAndValidity();
    this.patientForm.get('militaryReserveDutyState').clearValidators();
    this.patientForm.get('militaryReserveDutyState').updateValueAndValidity();
    this.patientForm.get('militaryReserveBranches').clearValidators();
    this.patientForm.get('militaryReserveBranches').updateValueAndValidity();
    this.patientForm.get('militaryArmedForcesBranches').clearValidators();
    this.patientForm
      .get('militaryArmedForcesBranches')
      .updateValueAndValidity();
    this.patientForm.get('militaryCombatTheaters').clearValidators();
    this.patientForm.get('militaryCombatTheaters').updateValueAndValidity();
    this.patientForm.get('militaryTotalCombatDeployments').clearValidators();
    this.patientForm
      .get('militaryTotalCombatDeployments')
      .updateValueAndValidity();
    this.patientForm.get('militaryCombatDeploymentStatus').clearValidators();
    this.patientForm
      .get('militaryCombatDeploymentStatus')
      .updateValueAndValidity();
  }

  showMilitaryDetailQuestions(): boolean {
    return (
      this.patientForm.get('militaryStatus')?.value !== 'Never in Military' &&
      this.patientForm.get('militaryStatus')?.value !== '' &&
      this.patientForm.get('militaryStatus')?.value
    );
  }

  get militaryBranchControls(): FormArray {
    return this.patientForm.get('tmpMilitaryBranchSelections') as FormArray;
  }
  get militaryReserveBranchControls(): FormArray {
    return this.patientForm.get('tmpMilitaryReserveSelections') as FormArray;
  }
  get militaryArmedForcesBranchControls(): FormArray {
    return this.patientForm.get(
      'tmpMilitaryArmedForcesSelections'
    ) as FormArray;
  }
  get combatTheaterControls(): FormArray {
    return this.patientForm.get('tmpCombatTheaterSelections') as FormArray;
  }

  militaryBranchNoneIsSelected: boolean = false;
  militaryBranchOtherIsSelected: boolean = false;
  onMilitaryBranchCheckboxChange(
    event: any,
    branchName: string,
    index: number,
    emitEvent: boolean
  ) {
    const formArray = this.militaryBranchControls;
    formArray.at(index).setValue(event.checked);
    if (branchName === 'None' && event.checked) {
      this.militaryBranchNoneIsSelected = true;
      // Remove
      this.patientForm.get('militaryReserveDutyStatus').clearValidators();
      this.patientForm
        .get('militaryReserveDutyStatus')
        .updateValueAndValidity();
      this.patientForm.get('militaryReserveDutyState').clearValidators();
      this.patientForm.get('militaryReserveDutyState').updateValueAndValidity();
      this.patientForm.updateValueAndValidity();
    } else if (branchName === 'None' && !event.checked) {
      this.militaryBranchNoneIsSelected = false;
      this.patientForm
        .get('militaryReserveDutyStatus')
        .addValidators(Validators.required);
      this.patientForm
        .get('militaryReserveDutyState')
        .addValidators(Validators.required);
      this.patientForm.updateValueAndValidity();
    } else {
      this.patientForm
        .get('militaryReserveDutyStatus')
        .addValidators(Validators.required);
      this.patientForm
        .get('militaryReserveDutyState')
        .addValidators(Validators.required);
      this.patientForm.updateValueAndValidity();
      const noneIndex = this.militaryBranchOptions.indexOf('None');
      if (event.checked) {
        this.militaryBranchOtherIsSelected = true;
      } else {
        const otherSelected = formArray.value.some(
          (value: boolean, i: number) => i !== noneIndex && value
        );
        if (otherSelected) {
          this.militaryBranchOtherIsSelected = true;
        } else {
          this.militaryBranchOtherIsSelected = false;
          this.militaryBranchNoneIsSelected = false;
        }
      }
    }
    const militaryBranches = this.militaryBranchOptions.filter(
      (_, i) => formArray.value[i]
    );
    this.patientForm.patchValue({ militaryBranches }, { emitEvent });
  }

  militaryReserveBranchNoneIsSelected: boolean = false;
  militaryReserveBranchOtherIsSelected: boolean = false;
  onMilitaryReserveBranchCheckboxChange(
    event: any,
    branchName: string,
    index: number,
    emitEvent: boolean
  ) {
    const formArray = this.militaryReserveBranchControls;
    formArray.at(index).setValue(event.checked);
    if (branchName === 'None' && event.checked) {
      this.militaryReserveBranchNoneIsSelected = true;
    } else if (branchName === 'None' && !event.checked) {
      this.militaryReserveBranchNoneIsSelected = false;
    } else {
      const noneIndex = this.militaryReserveBranchOptions.indexOf('None');
      if (event.checked) {
        this.militaryReserveBranchOtherIsSelected = true;
      } else {
        const otherSelected = formArray.value.some(
          (value: boolean, i: number) => i !== noneIndex && value
        );
        if (otherSelected) {
          this.militaryReserveBranchOtherIsSelected = true;
        } else {
          this.militaryReserveBranchOtherIsSelected = false;
          this.militaryReserveBranchNoneIsSelected = false;
        }
      }
    }
    const militaryReserveBranches = this.militaryReserveBranchOptions.filter(
      (_, i) => formArray.value[i]
    );
    this.patientForm.patchValue({ militaryReserveBranches }, { emitEvent });
  }

  armedForcesBranchNoneIsSelected: boolean = false;
  armedForcesBranchOtherIsSelected: boolean = false;
  onArmedForcesBranchCheckboxChange(
    event: any,
    branchName: string,
    index: number,
    emitEvent: boolean
  ) {
    const formArray = this.militaryArmedForcesBranchControls;
    formArray.at(index).setValue(event.checked);
    if (branchName === 'None' && event.checked) {
      this.armedForcesBranchNoneIsSelected = true;
    } else if (branchName === 'None' && !event.checked) {
      this.armedForcesBranchNoneIsSelected = false;
    } else {
      const noneIndex = this.armedForcesBranchOptions.indexOf('None');
      if (event.checked) {
        this.armedForcesBranchOtherIsSelected = true;
      } else {
        const otherSelected = formArray.value.some(
          (value: boolean, i: number) => i !== noneIndex && value
        );
        if (otherSelected) {
          this.armedForcesBranchOtherIsSelected = true;
        } else {
          this.armedForcesBranchOtherIsSelected = false;
          this.armedForcesBranchNoneIsSelected = false;
        }
      }
    }
    const militaryArmedForcesBranches = this.armedForcesBranchOptions.filter(
      (_, i) => formArray.value[i]
    );
    this.patientForm.patchValue({ militaryArmedForcesBranches }, { emitEvent });
  }

  onCombatTheaterCheckboxChange(
    event: any,
    branchName: string,
    index: number,
    emitEvent: boolean
  ) {
    const formArray = this.combatTheaterControls;
    formArray.at(index).setValue(event.checked);

    const militaryCombatTheaters = this.combatTheaterOptions.filter(
      (_, i) => formArray.value[i]
    );
    this.patientForm.patchValue({ militaryCombatTheaters }, { emitEvent });
  }
}
