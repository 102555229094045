import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  DataTablesModel,
  ICheckEligibility,
  IMemberInsurance,
} from '../../_models';

@Injectable({ providedIn: 'root' })
export class PatientInsuranceApiService {
  constructor(private httpClient: HttpClient) {}

  getMemberInsurances(
    patientId: string,
    insuranceType: string,
    memberId: string,
    payer: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    if (insuranceType) params = params.append('insuranceType', insuranceType);
    if (memberId) params = params.append('memberId', memberId);
    if (payer) params = params.append('payer', payer);
    if (status) params = params.append('status', status);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patientinsurance`,
      { params: params }
    );
  }

  memberAddIntakeInsurance(
    insuranceDetails: IMemberInsurance,
    consentRequestId: string
  ): Observable<IMemberInsurance> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMemberInsurance>(
        `${environment.apiUrl}/memberselfaddinsurance/${consentRequestId}`,
        insuranceDetails,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  guestIntakeAddInsurance(
    requestId: string,
    accessCode: string,
    consentRequestId: string,
    insuranceDetails: IMemberInsurance
  ): Observable<IMemberInsurance> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMemberInsurance>(
        `${environment.apiUrl}/guestintake/updateinsurance/${requestId}/${accessCode}/${consentRequestId}`,
        insuranceDetails,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addInsurance(
    insuranceDetails: IMemberInsurance
  ): Observable<IMemberInsurance> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMemberInsurance>(
        `${environment.apiUrl}/patientinsurance`,
        insuranceDetails,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateInsurance(
    insuranceDetails: IMemberInsurance
  ): Observable<IMemberInsurance> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IMemberInsurance>(
        `${environment.apiUrl}/patientinsurance`,
        insuranceDetails,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteInsurance(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/patientinsurance/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getAllActiveProviders(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allactiveinsuranceproviders`
    );
  }

  getInsuranceProviderList(
    payerName: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (status) params = params.append('status', status);
    if (payerName) params = params.append('payer', payerName);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/insuranceproviders`,
      { params: params }
    );
  }

  getInsuranceProviderListForGuestIntake(
    requestId: string,
    accessCode: string,
    payerName: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let path = 'guestintake/intakeinsuranceproviders';
    if (!accessCode) {
      path = 'insuranceproviders';
    }
    let params = new HttpParams();

    params = params.append('requestId', requestId);
    params = params.append('accessCode', accessCode);

    if (status) params = params.append('status', status);
    if (payerName) params = params.append('payer', payerName);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/` + path,
      { params: params }
    );
  }

  getInsuraneDetails(id) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/patientinsurance/${id}`
    );
  }

  checkEligibility(details: ICheckEligibility): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/checkeligibility`, details, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  manualEligibilityCheck(details: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/manual-eligibilitycheck`, details, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getEligibilityReport(
    insuranceId: string,
    startDate: any,
    endDate: any,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<any> {
    let params = new HttpParams();
    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient
      .get<any>(`${environment.apiUrl}/geteligibilityreport/${insuranceId}`, {
        params: params,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getOrgEligibilityTransactions(
    memberName: string,
    payerName: string,
    startDate: any,
    endDate: any,
    status: string,
    sessionId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<any> {
    let params = new HttpParams();
    if (memberName) params = params.append('memberName', memberName);
    if (payerName) params = params.append('payerName', payerName);
    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);
    if (status) params = params.append('status', status);
    if (sessionId) params = params.append('sessionId', sessionId);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient
      .get<any>(`${environment.apiUrl}/orgeligibilityrecords`, {
        params: params,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
