import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Case, Patient } from 'src/app/_models';
import { ICaseTreatmentTeam } from 'src/app/_models/case/treatment/admission/casetreatmentteam.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { CaseTreatmentTeamApiService } from 'src/app/_services/case/treatment/admission/casetreatmentteam.service';
import { DashboardsService } from 'src/app/_services/dashboards/dashboards.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-case-treatment-team-dialog',
  templateUrl: './add-edit-case-treatment-team-dialog.component.html',
  styleUrls: ['./add-edit-case-treatment-team-dialog.component.css'],
})
export class AddEditCaseTreatmentTeamDialogComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;

  selectedReview: ITreatmentReview;
  recordType: string;
  public action: string;
  public casetreatmentteamForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  public casetreatmentteam: ICaseTreatmentTeam;
  public processing = false;
  public treatmentSubTeams: any[] = [
    { id: 'Recovery' },
    { id: 'Release Plan' },
  ];
  public selectedSubTeams: string[] = [];

  public roleList: Array<{ id: string; name: string }> = [
    { id: 'Brother', name: 'Brother' },
    { id: 'Case Manager', name: 'Case Manager' },
    { id: 'Counselor', name: 'Counselor' },
    { id: 'Direct Service Provider', name: 'Direct Service Provider' },
    { id: 'DJJ', name: 'DJJ' },
    { id: 'DOC', name: 'DOC' },
    { id: 'Father', name: 'Father' },
    { id: 'Guardian', name: 'Guardian' },
    { id: 'Mother', name: 'Mother' },
    { id: 'Neurologist', name: 'Neurologist' },
    { id: 'Nurse', name: 'Nurse' },
    { id: 'OCS', name: 'OCS' },
    { id: 'Other', name: 'Other' },
    { id: 'Parole Officer', name: 'Parole Officer' },
    { id: 'Principal', name: 'Principal' },
    { id: 'Psychiatrist', name: 'Psychiatrist' },
    { id: 'Psychologist', name: 'Psychologist' },
    { id: 'School Staff', name: 'School Staff' },
    { id: 'Significant Other', name: 'Significant Other' },
    { id: 'Sister', name: 'Sister' },
    { id: 'Spouse', name: 'Spouse' },
    { id: 'Teacher', name: 'Teacher' },
    { id: 'Team Lead', name: 'Team Lead' },
  ];

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddEditCaseTreatmentTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private casetreatmentteamApiService: CaseTreatmentTeamApiService,
    private formBuilder: FormBuilder,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.casetreatmentteam = data.casetreatmentteam;
    this.case = data.case;
    this.patient = data.patient;
    this.recordType = data.recordType;
    this.selectedReview = data.selectedReview;
  }

  ngOnInit() {
    this.buildForm();
    if (this.action === 'EDIT') {
      this.populateFormForEdit();
    } else {
      this.loadActiveProviders();
    }
  }

  private buildForm() {
    this.casetreatmentteamForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient?.id || '', Validators.required),
      caseId: new FormControl(this.case?.id || '', Validators.required),
      reviewId: new FormControl(this.selectedReview?.id),
      recordType: new FormControl(this.recordType),
      staffMemberId: new FormControl('', Validators.required),
      staffMemberName: new FormControl('', Validators.required),
      roleRelation: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl(''),
      reviewMember: new FormControl('No', Validators.required),
      primaryCareStaff: new FormControl('No', Validators.required),
      denyAccessToClientRecords: new FormControl('No', Validators.required),
      treatmentSubTeams: new FormControl([]),
      selectedSubTeams: new FormControl([]),
      notes: new FormControl(''),
      status: new FormControl('Active'),
    });
  }

  loadActiveProviders() {
    this.providersListLoading = true;

    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  private populateFormForEdit() {
    this.casetreatmentteamForm.patchValue({
      id: this.casetreatmentteam.id,
      organizationId: this.casetreatmentteam.organizationId,
      patientId: this.casetreatmentteam.patientId,
      caseId: this.casetreatmentteam.caseId,
      reviewId: this.casetreatmentteam.reviewId,
      staffMemberId: this.casetreatmentteam.staffMemberId,
      staffMemberName: this.casetreatmentteam.staffMemberName,
      roleRelation: this.casetreatmentteam.roleRelation,
      startDate: mmddyyyyToDate(this.casetreatmentteam.startDate),

      reviewMember: this.casetreatmentteam.reviewMember,
      primaryCareStaff: this.casetreatmentteam.primaryCareStaff,
      denyAccessToClientRecords:
        this.casetreatmentteam.denyAccessToClientRecords,
      selectedSubTeams: this.casetreatmentteam.selectedSubTeams,
      notes: this.casetreatmentteam.notes,
      status: this.casetreatmentteam.status,
    });

    if (this.casetreatmentteam.endDate) {
      this.casetreatmentteamForm.controls['endDate'].setValue(
        new Date(this.casetreatmentteam.endDate)
      );
    }

    this.selectedSubTeams = this.casetreatmentteam.selectedSubTeams.map(
      (team: string) => {
        const matchingTeam = this.treatmentSubTeams.find((t) => t.id === team);
        return matchingTeam ? matchingTeam : { id: team };
      }
    );

    this.casetreatmentteamForm.controls['selectedSubTeams'].setValue(
      this.selectedSubTeams
    );

    this.casetreatmentteamForm.markAllAsTouched();
    this.loadActiveProviders();
  }

  onCaseTreatmentTeamFormSubmit() {
    if (this.casetreatmentteamForm.invalid) {
      this.casetreatmentteamForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    const formData = {
      ...this.casetreatmentteam,
      ...this.casetreatmentteamForm.value,
      startDate: formatDate(
        new Date(this.casetreatmentteamForm.value.startDate)
      ),
    };

    if (this.casetreatmentteamForm.value.endDate) {
      const formData = {
        ...this.casetreatmentteam,
        endDate: formatDate(new Date(this.casetreatmentteamForm.value.endDate)),
      };
    }

    formData.selectedSubTeams = formData.selectedSubTeams.map(
      (team: { id: string }) => team.id
    );

    const saveAction =
      this.action === 'EDIT'
        ? this.casetreatmentteamApiService.updateCaseTreatmentTeam(formData)
        : this.casetreatmentteamApiService.addCaseTreatmentTeam(formData);

    saveAction.subscribe(() => {
      this.processing = false;
      this.dialogRef.close('success');
    });
  }

  moveToSelectedTeams() {
    const selected = this.casetreatmentteamForm.value.treatmentSubTeams;
    if (selected) {
      this.selectedSubTeams.push(selected);
      this.treatmentSubTeams = this.treatmentSubTeams.filter(
        (team) => team !== selected
      );
      this.casetreatmentteamForm.patchValue({
        treatmentSubTeams: '',
        selectedSubTeams: this.selectedSubTeams,
      });
    }
  }

  removeFromSelectedTeams() {
    const selected = this.casetreatmentteamForm.value.selectedSubTeams;
    if (selected) {
      this.treatmentSubTeams.push(selected);
      this.selectedSubTeams = this.selectedSubTeams.filter(
        (team) => team !== selected
      );
      this.casetreatmentteamForm.patchValue({
        treatmentSubTeams: '',
        selectedSubTeams: this.selectedSubTeams,
      });
    }
  }

  removeSubTeam(subTeam, index) {
    let existingRecords = this.casetreatmentteamForm.controls[
      'selectedSubTeams'
    ].value as [];

    existingRecords.splice(index, 1);

    this.casetreatmentteamForm.controls['selectedSubTeams'].setValue(
      existingRecords
    );
  }

  selectedClinician(event: MatSelectChange): void {
    const providerId = event.value;

    const selectedProvider = this.providersList
      .filter((staff) => staff.id === providerId)
      .at(0);

    this.casetreatmentteamForm.controls['staffMemberName'].setValue(
      selectedProvider.firstName + ' ' + selectedProvider.lastName
    );
  }

  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }
}
