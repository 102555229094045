import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { AvailableGoalsComponent } from './available-goals/available-goals.component';

@Component({
  selector: 'app-session-goals-by-category',
  templateUrl: './session-goals-by-category.component.html',
  styleUrls: ['./session-goals-by-category.component.css'],
})
export class SessionGoalsByCategoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() loggedInUserId: string;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  selectedCategory: string = 'Addiction';
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults: boolean = false;

  goals: IAssessmentGoal[] = [];
  assignedGoalStatus: string = 'Active';

  constructor(
    private dialog: MatDialog,
    public memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {}

  openLibrary() {
    let dialogRef = this.dialog.open(AvailableGoalsComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        hasGoalsAddAccess: this.hasGoalsAddAccess,
        hasGoalsEditAccess: this.hasGoalsEditAccess,
        hasGoalsDeleteAccess: this.hasGoalsDeleteAccess,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
      }
    });
  }
}
