<mat-card class="attemptCard mat-elevation-z8">
  <label class="labelHeader justicyCenterLabel" style="margin-bottom: 15px">
    <mat-icon>history_edu</mat-icon> {{ attemptNumber }}
  </label>

  <!-- Attempted on -->
  <div class="justicyCenterBox">
    <mat-icon>insert_invitation</mat-icon>
    <label class="labelBold"> Attempted on : &nbsp;</label>
    <label style="margin: 0; padding: 0">
      {{
        attemptDetails?.attemptDateAndTime
          | date : 'MM/dd/y hh:mm a' : currentTimezone
      }}
      {{ currentTimezone }}
    </label>
  </div>

  <!-- Attempted on -->
  <div class="justicyCenterBox">
    <mat-icon>assignment_ind</mat-icon>
    <label class="labelBold"> Recorded by : &nbsp;</label>
    <label style="margin: 0; padding: 0">
      {{ attemptDetails?.recordedByUserDetails?.lastName }},
      {{ attemptDetails?.recordedByUserDetails?.firstName }}
      {{ attemptDetails?.recordedByUserDetails?.middleName }}
    </label>
  </div>

  <!-- Attempted Result -->
  <div class="justicyCenterBox">
    <mat-icon>dynamic_feed</mat-icon>
    <label class="labelBold"> Attempt Outcome : &nbsp;</label>
    <label style="margin: 0; padding: 0">
      <span
        [class]="
          attemptDetails?.attemptResult === 'No Answer' ||
          attemptDetails?.attemptResult === 'Line Busy'
            ? 'newStatus'
            : attemptDetails?.attemptResult === 'Left Voicemail' ||
              attemptDetails?.attemptResult === 'Line Busy' ||
              attemptDetails?.attemptResult === 'Follow-Up Needed'
            ? 'warningStatus'
            : attemptDetails?.attemptResult === 'Success'
            ? 'successStatus'
            : 'errorStatus'
        "
      >
        {{ attemptDetails?.attemptResult }}
      </span>
    </label>
  </div>

  <!-- Attempted Note -->
  <div class="justicyCenterBox">
    <mat-icon>sticky_note_2</mat-icon>
    <label class="labelBold"> Notes : &nbsp;</label>
    <label style="font-style: italic; margin: 0; padding: 0">
      {{ attemptDetails?.attemptNote }}
    </label>
  </div>
</mat-card>
