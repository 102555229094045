import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentPlanTarget } from 'src/app/_models/case/treatment/plan/treatmentplantargets.model';
import { TreatmentPlanTargetApiService } from 'src/app/_services/case/treatment/plan/treatmentplantarget.service';

@Component({
  selector: 'app-add-edit-treatment-plan-problems-dialog',
  templateUrl: './add-edit-treatment-plan-problems-dialog.component.html',
  styleUrl: './add-edit-treatment-plan-problems-dialog.component.css',
})
export class AddEditTreatmentPlanTargetDialogComponent implements OnInit {
  case: Case;
  patient: Patient;
  selectedPlan: ITreatmentPlan;

  public action: string;
  public treatmentplanproblemsForm: FormGroup;
  target: ITreatmentPlanTarget;
  processing = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditTreatmentPlanTargetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public treatmentplantargetApiService: TreatmentPlanTargetApiService,
    private formBuilder: FormBuilder
  ) {
    this.action = data.action;
    this.target = data.target;

    this.case = data.case;
    this.patient = data.patient;
    this.selectedPlan = data.selectedPlan;
  }

  public problemStatusOptions: string[] = [
    'Deferred',
    'In Treatment',
    'Referred',
    'Resolved',
    'Withdrawn',
  ];
  public problemOptions: string[] = [
    'A harmful pattern of substance abuse with increased tolerance and symptoms of withdrawal.',
    'Acute Potential for DTs',
    'Acute Seizures',
    'Acute Hypertension',
    'Affective Disorders',
    'Client is only moderately motivated to change his addiction behaviors.',
    'Compulsion',
    'Chronic CAD',
    'Clinical Syndromes',
    'Co-dependency',
    'Court Date Pending',
    'Destructive',
    'Chronic Diabetes Mellitus',
    'Client Can Go Home',
    'Client Has No Medical Problems',
    'Disengagement',
    'Client is Recovered',
    'Client is Fine',
    'Enmeshment',
    'Homeostasis',
    'Chronic Hypertension',
    'Intoxication',
    'Isolation',
    'Incarceration',
    'Insufficient Education',
    'Lack of Employment/Job Skills',
    'Mental Retardation',
    'Mood Disorders',
    'Client does not have a very supportive environment for recovery.',
    'Organic Disorders',
    'Other. See Comments',
    'Parole',
    'Personality Syndromes',
    'Probation Before Judgement',
    'Probation',
    'Not Punctual',
    'Chronic Renal Failure/Dialysis',
    'High Risk Behavior',
    'Chronic Seizures',
    'Thought Disorders',
    'Withdrawal',
  ];
  public dimensionOptions: string[] = [
    'Withdrawal Potential',
    'Biomedical Conditions',
    'Emotional/Behavioral/Cognitive Conditions',
    'Readiness to Change',
    'Relapse/Continued Use or Problem Potential',
    'Recovery/Living Environment',
  ];

  public ngOnInit() {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.treatmentplanproblemsForm.controls['id'].setValue(this.target.id);
      this.treatmentplanproblemsForm.controls['organizationId'].setValue(
        this.target.organizationId
      );
      this.treatmentplanproblemsForm.controls['patientId'].setValue(
        this.target.patientId
      );
      this.treatmentplanproblemsForm.controls['caseId'].setValue(
        this.target.caseId
      );
      this.treatmentplanproblemsForm.controls['planId'].setValue(
        this.target.planId
      );
      this.treatmentplanproblemsForm.controls['status'].setValue(
        this.target.status
      );
      this.treatmentplanproblemsForm.controls['description'].setValue(
        this.target.description
      );
      this.treatmentplanproblemsForm.controls['dimensions'].setValue(
        this.target.dimensions
      );
    }
  }

  onTreatmentPlanTargetFormSubmit() {
    if (this.treatmentplanproblemsForm.invalid) {
      this.treatmentplanproblemsForm.markAllAsTouched();
      return;
    }
    this.target = Object.assign({}, this.target);
    this.target = Object.assign(
      this.target,
      this.treatmentplanproblemsForm.value
    );

    this.processing = true;

    if (this.action === 'EDIT') {
      this.treatmentplantargetApiService
        .updateTreatmentPlanTarget(this.target)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    } else {
      this.treatmentplantargetApiService
        .addTreatmentPlanTarget(this.target)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    }
  }

  private buildForm() {
    this.treatmentplanproblemsForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.case.patientId,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planId: new FormControl(
        this.selectedPlan.id,
        Validators.compose([Validators.required])
      ),
      status: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl(''),
      dimensions: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });
  }

  removeSelectedOption(record, index) {
    let existingRecords = this.treatmentplanproblemsForm.controls['dimensions']
      .value as [];

    existingRecords.splice(index, 1);

    this.treatmentplanproblemsForm.controls['dimensions'].setValue(
      existingRecords
    );
  }
}
