<div class="row" *ngIf="displayTitle">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      > </a
    >&nbsp; / &nbsp; Eligibility Checks
  </p>
</div>

<!-- Top Bar Buttons -->
<div class="row">
  <div class="col-md-12 flexEndContainer">
    <button mat-raised-button color="primary" (click)="refresh()">
      <mat-icon>refresh</mat-icon> Refresh
    </button>

    <button
      type="button"
      mat-raised-button
      color="warn"
      (click)="openEligibilityModal()"
    >
      + Eligibility Check
    </button>
  </div>
</div>

<!-- Elibibility Screen List -->
<app-view-eligibility-reports
  [reloadEligibilityReport]="reloadEligibilityReport.asObservable()"
  [displayTitle]="displayTitle"
  [sessionId]="sessionId"
></app-view-eligibility-reports>
