<div style="margin-top: 10px; padding: 10px">
  <div class="row" style="margin-bottom: 15px">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addCaseTreatmentTeam()"
      >
        + Add Team Member
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No Care Team Member found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>
    <mat-error *ngIf="apiError">
      An error occurred while retrieving Care Team Member records
    </mat-error>
  </div>

  <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
    <table
      mat-table
      [dataSource]="data.items"
      class="mat-elevation-z8"
      matSort
      matSortDisableClear
    >
      <ng-container matColumnDef="patientId">
        <th mat-header-cell *matHeaderCellDef>Patient Id</th>
        <td mat-cell *matCellDef="let row">{{ row.patientId }}</td>
      </ng-container>
      <ng-container matColumnDef="caseId">
        <th mat-header-cell *matHeaderCellDef>Case Id</th>
        <td mat-cell *matCellDef="let row">{{ row.caseId }}</td>
      </ng-container>
      <ng-container matColumnDef="staffName">
        <th mat-header-cell *matHeaderCellDef>Staff Name</th>
        <td mat-cell *matCellDef="let row">{{ row.staffMemberName }}</td>
      </ng-container>
      <ng-container matColumnDef="roleRelation">
        <th mat-header-cell *matHeaderCellDef>Role Relation</th>
        <td mat-cell *matCellDef="let row">{{ row.roleRelation }}</td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Start Date</th>
        <td mat-cell *matCellDef="let row">{{ row.startDate }}</td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>End Date</th>
        <td mat-cell *matCellDef="let row">{{ row.endDate }}</td>
      </ng-container>
      <ng-container matColumnDef="reviewMember">
        <th mat-header-cell *matHeaderCellDef>Review Member</th>
        <td mat-cell *matCellDef="let row">{{ row.reviewMember }}</td>
      </ng-container>
      <ng-container matColumnDef="primaryCareStaff">
        <th mat-header-cell *matHeaderCellDef>Primary Care Staff</th>
        <td mat-cell *matCellDef="let row">{{ row.primaryCareStaff }}</td>
      </ng-container>
      <ng-container matColumnDef="denyAccessToClientRecords">
        <th mat-header-cell *matHeaderCellDef>Deny Access To Client Records</th>
        <td mat-cell *matCellDef="let row">
          {{ row.denyAccessToClientRecords }}
        </td>
      </ng-container>
      <ng-container matColumnDef="selectedSubTeams">
        <th mat-header-cell *matHeaderCellDef>Sub Teams</th>
        <td mat-cell *matCellDef="let row">{{ row.selectedSubTeams }}</td>
      </ng-container>
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <span
            *ngIf="row?.status === 'Active'"
            style="color: #3ac47d; font-weight: 900"
          >
            {{ row?.status }}
          </span>
          <span
            *ngIf="row?.status != 'Active'"
            style="color: #d92550; font-weight: 900"
          >
            {{ row?.status }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <button
            type="button"
            mat-raised-button
            [matMenuTriggerFor]="menu"
            class="btn btn-just-icon btn-white btn-fab btn-round"
          >
            <i class="material-icons text_align-center">more_vert</i>
          </button>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              type="button"
              (click)="editCaseTreatmentTeam(row)"
            >
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>

            <button
              mat-menu-item
              (click)="deleteCaseTreatmentTeam(row)"
              type="button"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    ></mat-paginator>
  </div>
</div>
