<mat-card-title *ngIf="!isModalWindow">Member Profile</mat-card-title>
<mat-divider *ngIf="!isModalWindow"></mat-divider>

<div
  [class]="isModalWindow ? 'container' : ''"
  [style]="isModalWindow ? '' : 'margin-top: 40px; padding: 10px'"
>
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Member</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Member</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="patientForm" (ngSubmit)="onPatientFormSubmit()">
    <div class="row">
      <!-- General Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <div class="row">
          <div class="col-md-8">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">person</mat-icon> General
              Information
            </label>
          </div>

          <div class="col-md-4" *ngIf="isOrgAdmin">
            <section class="checkbox-section pull-right">
              <mat-checkbox
                color="warn"
                class="checkbox-margin"
                formControlName="restrictedMember"
              >
                Restricted Member
              </mat-checkbox>
            </section>
          </div>
        </div>

        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  name="firstName"
                  minlength="1"
                  required
                />
                <mat-error *ngIf="patientForm.controls['firstName'].invalid">
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="middleName"
                  name="middleName"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                  minlength="1"
                  required
                />
                <mat-error *ngIf="patientForm.controls['lastName'].invalid">
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Date of Birth<span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  formControlName="dateOfBirth"
                  matInput
                  [matDatepicker]="dobDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  required
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error *ngIf="patientForm.controls['dateOfBirth'].invalid">
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Gender <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="gender"
                  placeholder="Gender"
                  required
                  (selectionChange)="genderChanged($event)"
                >
                  <mat-option [value]="gen.id" *ngFor="let gen of genderList">
                    {{ gen.description }}</mat-option
                  >
                </mat-select>

                <mat-error *ngIf="patientForm.controls['gender'].invalid">
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Pronouns </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="pronouns" name="pronouns" />
              </mat-form-field>
            </div>

            <!-- SSN -->
            <div class="col-md-4">
              <label class="labelText"> Social Security Number (SSN) </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="XXX-XX-XXXX"
                  formControlName="ssn"
                  name="ssn"
                  [type]="showSSN ? 'text' : 'password'"
                />
                <mat-icon matSuffix (click)="showSSN = !showSSN">
                  {{ showSSN ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="patientForm.controls['ssn'].invalid">
                  Please enter a valid SSN
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Height -->
            <div class="col-md-2">
              <label class="labelText"> Height </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="height"
                  name="height"
                  type="number"
                  min="0"
                />
                <span matSuffix> in </span>
              </mat-form-field>
            </div>

            <!-- Weight -->
            <div class="col-md-2">
              <label class="labelText"> Weight </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="weight"
                  name="weight"
                  type="number"
                  min="0"
                />
                <span matSuffix> Lb </span>
              </mat-form-field>
            </div>

            <!-- Gender at birth -->
            <div
              class="col-md-4"
              *ngIf="patientForm.controls['gender'].value === 'O'"
            >
              <label class="labelText">
                Gender at Birth<span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="genderAtBirth"
                  placeholder="Gender at Birth"
                >
                  <mat-option value="M"> Male </mat-option>
                  <mat-option value="F"> Female </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="patientForm.controls['genderAtBirth'].invalid"
                >
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Admittance Date -->
            <div class="col-md-4">
              <label class="labelText"> Admittance Date</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="admittanceDate"
                  matInput
                  [matDatepicker]="admittanceDatePicker"
                  [max]="maxDate"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="admittanceDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #admittanceDatePicker></mat-datepicker>

                <mat-error
                  *ngIf="patientForm.controls['admittanceDate'].invalid"
                >
                  Please enter a valid Admittance Date
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Member Number is Optional -->
            <div class="col-md-4" *ngIf="showMemberNumber">
              <label class="labelText"> Member Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="memberNumber"
                  name="memberNumber"
                />
              </mat-form-field>
            </div>

            <!-- Primary Care Provider -->
            <div class="col">
              <label class="labelText"> Primary Care Provider </label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder=""
                  formControlName="primaryCPId"
                  (selectionChange)="careProviderSelected($event)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Care Provider Name"
                      noEntriesFoundLabel="No matching care provider found"
                      [(ngModel)]="searchCareProvider"
                      (ngModelChange)="filterCareProviders()"
                      [ngModelOptions]="{ standalone: true }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option></mat-option>
                  <mat-option
                    [value]="user.id"
                    *ngFor="let user of filteredCareProvidersList"
                  >
                    {{ user.lastName }}, {{ user.firstName }}
                    {{ user.middleName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">contact_phone</mat-icon> Contact
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">Email</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  name="email"
                />
                <mat-error *ngIf="patientForm.controls['email'].invalid">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Phone Preference <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="phonePreference"
                  placeholder="Phone Prefence"
                  (selectionChange)="phonePreferenceChanged($event)"
                >
                  <mat-option value="Cell Phone">Cell Phone</mat-option>
                  <mat-option value="Home Phone">Home Phone</mat-option>
                  <mat-option value="Work Phone">Work Phone</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="patientForm.controls['phonePreference'].invalid"
                >
                  Please select the phone preference
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Cell Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="phoneNumber"
                  name="phoneNumber"
                  placeholder="(999) 999-9999"
                />
                <mat-error *ngIf="patientForm.controls['phoneNumber'].invalid">
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Home Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    patientForm.controls['phonePreference'].value ===
                    'Home Phone'
                  "
                  >*</span
                >
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="homePhoneNumber"
                  name="homePhoneNumber"
                />
                <mat-error
                  *ngIf="patientForm.controls['homePhoneNumber'].invalid"
                >
                  Please enter a valid home number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Work Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    patientForm.controls['phonePreference'].value ===
                    'Work Phone'
                  "
                  >*</span
                >
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="workPhoneNumber"
                  name="workPhoneNumber"
                />
                <mat-error
                  *ngIf="patientForm.controls['workPhoneNumber'].invalid"
                >
                  Please enter a valid work number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home</mat-icon>
          Address
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4" *ngIf="isTNwitsMember">
              <label class="labelText">
                Address Type
                <span style="color: red" *ngIf="isTNwitsMember">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="addressType"
                  placeholder="Address Type"
                >
                  <mat-option value="Client Mailing">Client Mailing</mat-option>
                  <mat-option value="Client Unknown">Client Unknown</mat-option>
                  <mat-option value="Client Home">Client Home</mat-option>
                  <mat-option value="Client Previous"
                    >Client Previous</mat-option
                  > </mat-select
                ><mat-error *ngIf="patientForm.controls['addressType'].invalid">
                  Please select Address Type
                </mat-error>
              </mat-form-field>
            </div>
            <div [class]="isTNwitsMember ? 'col-md-4' : 'col-md-8'">
              <label class="labelText">
                Address 1 <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  formControlName="addressLine1"
                  name="addressLine1"
                  required
                />

                <mat-error *ngIf="patientForm.controls['addressLine1'].invalid">
                  Please enter the address line 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Address 2</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="addressLine2"
                  name="addressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                City <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="city"
                  name="city"
                  placeholder="City"
                />
                <mat-error *ngIf="patientForm.controls['city'].invalid">
                  Please enter the city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                State <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="state" placeholder="State">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="patientForm.controls['state'].invalid">
                  Please select the state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Zip <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="zip"
                  name="zip"
                  placeholder="Zip"
                />
                <mat-error *ngIf="patientForm.controls['zip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Demographic Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">note_alt</mat-icon>
          Race, Ethnicity, Status
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">Race </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="race" placeholder="Race">
                  <mat-option [value]="r.id" *ngFor="let r of raceList"
                    >{{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Ethnicity </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="ethnicity" placeholder="Ethnicity">
                  <mat-option [value]="r.id" *ngFor="let r of ethnicityList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Preferred Language </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="preferredLanguage"
                  placeholder="Language"
                >
                  <mat-option [value]="r.id" *ngFor="let r of languageList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Employment Status </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="employmentStatus"
                  placeholder="Employment Status"
                >
                  <mat-option [value]="r.id" *ngFor="let r of employmentList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Marital Status</label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="maritalStatus"
                  placeholder="Marital Status"
                >
                  <mat-option
                    [value]="r.id"
                    *ngFor="let r of marriedStatusList"
                  >
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Education </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="education" placeholder="Education">
                  <mat-option value="Less than one grade completed"
                    >Less than one grade completed</mat-option
                  >
                  <mat-option value="1st Grade">1st Grade</mat-option>
                  <mat-option value="2nd Grade">2nd Grade</mat-option>
                  <mat-option value="3rd Grade">3rd Grade</mat-option>
                  <mat-option value="4th Grade">4th Grade</mat-option>
                  <mat-option value="5th Grade">5th Grade</mat-option>
                  <mat-option value="6th Grade">6th Grade</mat-option>
                  <mat-option value="7th Grade">7th Grade</mat-option>
                  <mat-option value="8th Grade">8th Grade</mat-option>
                  <mat-option value="9th Grade">9th Grade</mat-option>
                  <mat-option value="10th Grade">10th Grade</mat-option>
                  <mat-option value="11th Grade">11th Grade</mat-option>
                  <mat-option value="Grade 12, HS diploma, GED"
                    >Grade 12, HS diploma, GED</mat-option
                  >
                  <mat-option value="Some college, no degree"
                    >Some college, no degree</mat-option
                  >
                  <mat-option value="Two-year college/associate degree"
                    >Two-year college/associate degree</mat-option
                  >
                  <mat-option value="Four-year college degree"
                    >Four-year college degree</mat-option
                  >
                  <mat-option value="Graduate work, no degree"
                    >Graduate work, no degree</mat-option
                  >
                  <mat-option value="Graduate professional degree or more"
                    >Graduate professional degree or more</mat-option
                  >
                  <mat-option value="Unknown">Unknown</mat-option>
                  <mat-option value="Vocational Training Beyond High School"
                    >Vocational Training Beyond High School</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Special Needs </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="specialNeeds"
                  placeholder="Special Needs"
                >
                  <mat-option value="Unknown">Unknown</mat-option>
                  <mat-option value="Co-Occurring">Co-Occurring</mat-option>
                  <mat-option value="Developmentally Disabled"
                    >Developmentally Disabled</mat-option
                  >
                  <mat-option value="Severe Hearing Loss or Deaf"
                    >Severe Hearing Loss or Deaf</mat-option
                  >
                  <mat-option
                    value="Major Difficulty in Ambulating or Nonambulation"
                    >Major Difficulty in Ambulating or Nonambulation</mat-option
                  >
                  <mat-option value="Moderate To Severe Medical Problems"
                    >Moderate To Severe Medical Problems</mat-option
                  >
                  <mat-option value="None">None</mat-option>
                  <mat-option value="No Response">No Response</mat-option>
                  <mat-option value="Organically Based Problem"
                    >Organically Based Problem</mat-option
                  >
                  <mat-option value="Other">Other</mat-option>
                  <mat-option value="TBI">TBI</mat-option>
                  <mat-option value="Visual Impairment or Blind"
                    >Visual Impairment or Blind</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Time Zone </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="timeZone" placeholder="Time Zone">
                  <mat-option [value]="r.id" *ngFor="let r of timeZoneList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Military Information -->
      <div class="col-md-12" style="margin-bottom: 12px" *ngIf="isTNwitsMember">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">military_tech</mat-icon>
          Military Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">
                In Military? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="militaryStatus"
                  placeholder="Military Status"
                >
                  <mat-option value="Not Collected">Not Collected</mat-option>
                  <mat-option value="Never in Military"
                    >Never in Military</mat-option
                  >
                  <mat-option value="On Active Duty; Combat"
                    >On Active Duty; Combat</mat-option
                  >
                  <mat-option value="On Active Duty; No Combat"
                    >On Active Duty; No Combat</mat-option
                  >
                  <mat-option value="Military Dependent"
                    >Military Dependent</mat-option
                  >
                  <mat-option value="Retired from Military"
                    >Retired from Military</mat-option
                  >
                  <mat-option value="In Reserves or National Guard; Combat"
                    >In Reserves or National Guard; Combat</mat-option
                  >
                  <mat-option value="In Reserves or National Guard; No Combat"
                    >In Reserves or National Guard; No Combat</mat-option
                  >
                  <mat-option value="Unknown">Unknown</mat-option>
                  <mat-option value="Veteran; Other Eras"
                    >Veteran; Other Eras</mat-option
                  >
                  <mat-option value="Vietnam Era Veteran; Combat"
                    >Vietnam Era Veteran; Combat</mat-option
                  >
                  <mat-option value="Vietnam Era Veteran; No Combat"
                    >Vietnam Era Veteran; No Combat</mat-option
                  > </mat-select
                ><mat-error
                  *ngIf="patientForm.controls['militaryStatus'].invalid"
                >
                  Please select Military Status
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="showMilitaryDetailQuestions()">
            <mat-divider></mat-divider>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label class="labelText">
                  Military Branches <span style="color: red">*</span></label
                >
                <div class="checkbox-container">
                  <div
                    *ngFor="let branch of militaryBranchOptions; let i = index"
                    class="checkbox-item"
                  >
                    <mat-checkbox
                      [formControl]="militaryBranchControls.at(i)"
                      (change)="
                        onMilitaryBranchCheckboxChange($event, branch, i, false)
                      "
                      [disabled]="militaryBranchOtherIsSelected"
                      *ngIf="i === 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                    <mat-checkbox
                      [formControl]="militaryBranchControls.at(i)"
                      (change)="
                        onMilitaryBranchCheckboxChange($event, branch, i, false)
                      "
                      [disabled]="militaryBranchNoneIsSelected"
                      *ngIf="i > 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="militaryBranchOtherIsSelected">
              <div class="col-md-12" style="padding-top: 15px">
                <label class="labelText">
                  Military Duty Status <span style="color: red">*</span></label
                >
                <br />
                <mat-button-toggle-group formControlName="militaryDutyStatus">
                  <mat-button-toggle value="Active">Active</mat-button-toggle>
                  <mat-button-toggle value="Dependent"
                    >Dependent</mat-button-toggle
                  >
                </mat-button-toggle-group>
                <mat-error
                  *ngIf="patientForm.controls['militaryDutyStatus'].invalid"
                >
                  Please select Military Duty Status
                </mat-error>
              </div>
            </div>
            <br />
            <mat-divider></mat-divider>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label class="labelText"
                  >Military Reserve Branches
                  <span style="color: red">*</span></label
                >
                <div class="checkbox-container">
                  <div
                    *ngFor="
                      let branch of militaryReserveBranchOptions;
                      let i = index
                    "
                    class="checkbox-item"
                  >
                    <mat-checkbox
                      [formControl]="militaryReserveBranchControls.at(i)"
                      (change)="
                        onMilitaryReserveBranchCheckboxChange(
                          $event,
                          branch,
                          i,
                          false
                        )
                      "
                      [disabled]="militaryReserveBranchOtherIsSelected"
                      *ngIf="i === 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                    <mat-checkbox
                      [formControl]="militaryReserveBranchControls.at(i)"
                      (change)="
                        onMilitaryReserveBranchCheckboxChange(
                          $event,
                          branch,
                          i,
                          false
                        )
                      "
                      [disabled]="militaryReserveBranchNoneIsSelected"
                      *ngIf="i > 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="militaryReserveBranchOtherIsSelected"
              style="padding-top: 15px"
            >
              <div class="col-2">
                <label class="labelText"
                  >Reserve Duty Status <span style="color: red">*</span></label
                >
                <br />
                <mat-button-toggle-group
                  formControlName="militaryReserveDutyStatus"
                >
                  <mat-button-toggle value="Active">Active</mat-button-toggle>
                  <mat-button-toggle value="Dependent"
                    >Dependent</mat-button-toggle
                  >
                </mat-button-toggle-group>
                <mat-error
                  *ngIf="
                    patientForm.controls['militaryReserveDutyStatus'].invalid &&
                    (patientForm.controls['militaryReserveDutyStatus']
                      .touched ||
                      patientForm.controls['militaryReserveDutyStatus'].dirty)
                  "
                >
                  Please select Deployment Status
                </mat-error>
              </div>
              <div class="col-3">
                <label class="labelText"
                  >Reserve Duty State <span style="color: red">*</span></label
                >
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="militaryReserveDutyState"
                    placeholder="State"
                  >
                    <mat-option
                      [value]="state.id"
                      *ngFor="let state of usStates"
                    >
                      {{ state.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    patientForm.controls['militaryReserveDutyState'].invalid &&
                    (patientForm.controls['militaryReserveDutyState'].touched ||
                      patientForm.controls['militaryReserveDutyState'].dirty)
                  "
                >
                  Please select Reserve Duty State
                </mat-error>
              </div>
            </div>
            <br />
            <mat-divider></mat-divider>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label class="labelText"
                  >Armed Forces Branches
                  <span style="color: red">*</span></label
                >
                <div class="checkbox-container">
                  <div
                    *ngFor="
                      let branch of armedForcesBranchOptions;
                      let i = index
                    "
                    class="checkbox-item"
                  >
                    <mat-checkbox
                      [formControl]="militaryArmedForcesBranchControls.at(i)"
                      (change)="
                        onArmedForcesBranchCheckboxChange(
                          $event,
                          branch,
                          i,
                          false
                        )
                      "
                      [disabled]="armedForcesBranchOtherIsSelected"
                      *ngIf="i === 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                    <mat-checkbox
                      [formControl]="militaryArmedForcesBranchControls.at(i)"
                      (change)="
                        onArmedForcesBranchCheckboxChange(
                          $event,
                          branch,
                          i,
                          false
                        )
                      "
                      [disabled]="armedForcesBranchNoneIsSelected"
                      *ngIf="i > 0"
                    >
                      {{ branch }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <mat-divider></mat-divider>
            <br />
            <div class="row">
              <div class="col-md-3">
                <label class="labelText">
                  Number of Deployments <span style="color: red">*</span></label
                >
                <mat-form-field appearance="outline">
                  <input
                    formControlName="militaryTotalCombatDeployments"
                    matInput
                    type="number"
                    min="0"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    patientForm.controls['militaryTotalCombatDeployments']
                      .invalid
                  "
                >
                  Please enter Number of Deployments
                </mat-error>
              </div>
              <div class="col-md-4">
                <label class="labelText">
                  Deployment Status <span style="color: red">*</span></label
                >
                <br />
                <mat-button-toggle-group
                  formControlName="militaryCombatDeploymentStatus"
                >
                  <mat-button-toggle value="Dependent"
                    >Dependent</mat-button-toggle
                  >
                  <mat-button-toggle value="Served">Served</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-error
                  *ngIf="
                    patientForm.controls['militaryCombatDeploymentStatus']
                      .invalid &&
                    (patientForm.controls['militaryCombatDeploymentStatus']
                      .touched ||
                      patientForm.controls['militaryCombatDeploymentStatus']
                        .dirty)
                  "
                >
                  Please select Deployment Status
                </mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="labelText">
                  Combat Theaters <span style="color: red">*</span></label
                >
                <div class="checkbox-container">
                  <div
                    *ngFor="
                      let combatTheater of combatTheaterOptions;
                      let i = index
                    "
                    class="checkbox-item"
                  >
                    <mat-checkbox
                      [formControl]="combatTheaterControls.at(i)"
                      (change)="
                        onCombatTheaterCheckboxChange(
                          $event,
                          combatTheater,
                          i,
                          false
                        )
                      "
                    >
                      {{ combatTheater }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                Have you been screened for Traumatic Brain Injury?
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group
                formControlName="militaryScreenedForTraumaticBrainInjury"
              >
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error
                *ngIf="
                  patientForm.controls[
                    'militaryScreenedForTraumaticBrainInjury'
                  ].invalid &&
                  (patientForm.controls[
                    'militaryScreenedForTraumaticBrainInjury'
                  ].touched ||
                    patientForm.controls[
                      'militaryScreenedForTraumaticBrainInjury'
                    ].dirty)
                "
              >
                Please answer
              </mat-error>
            </div>
            <div
              class="col-md-6"
              *ngIf="
                patientForm.get('militaryScreenedForTraumaticBrainInjury')
                  .value === 'Yes'
              "
            >
              <label class="labelText"
                >Have you been diagnosed with a Traumatic Brain Injury?
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group
                formControlName="militaryDiagnosedWithTraumaticBrainInjury"
              >
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error
                *ngIf="
                  patientForm.controls[
                    'militaryDiagnosedWithTraumaticBrainInjury'
                  ].invalid &&
                  (patientForm.controls[
                    'militaryDiagnosedWithTraumaticBrainInjury'
                  ].touched ||
                    patientForm.controls[
                      'militaryDiagnosedWithTraumaticBrainInjury'
                    ].dirty)
                "
              >
                Please answer
              </mat-error>
            </div>
          </div>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                Have you been screened for Post Traumatic Stress Disorder?
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group
                formControlName="militaryScreenedForPTSD"
              >
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>

              <mat-error
                *ngIf="
                  patientForm.controls['militaryScreenedForPTSD'].invalid &&
                  (patientForm.controls['militaryScreenedForPTSD'].touched ||
                    patientForm.controls['militaryScreenedForPTSD'].dirty)
                "
              >
                Please answer
              </mat-error>
            </div>
            <div
              class="col-md-6"
              *ngIf="patientForm.get('militaryScreenedForPTSD').value === 'Yes'"
            >
              <label class="labelText"
                >Have you been diagnosed with Post Traumatic Stress Disorder?
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group
                formControlName="militaryDiagnosedWithPTSD"
              >
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>

              <mat-error
                *ngIf="
                  patientForm.controls['militaryDiagnosedWithPTSD'].invalid &&
                  (patientForm.controls['militaryDiagnosedWithPTSD'].touched ||
                    patientForm.controls['militaryDiagnosedWithPTSD'].dirty)
                "
              >
                Please answer
              </mat-error>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4" *ngIf="action === 'EDIT'">
        <label class="labelText"
          >Status <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="status"
            placeholder="Status"
            (selectionChange)="confirmPatientStatusChange($event)"
          >
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Discharged">Discharged</mat-option>
            <mat-option value="Deceased">Deceased</mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-8"
        *ngIf="action === 'EDIT' && patientForm.value.status === 'Discharged'"
      >
        <label class="labelText">Discharged Date</label>
        <mat-form-field appearance="outline">
          <input
            formControlName="dischargedDate"
            matInput
            [matDatepicker]="dischargedDatePicker"
            placeholder="MM/DD/YYYY"
            [max]="maxDate"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dischargedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dischargedDatePicker></mat-datepicker>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-8"
        *ngIf="action === 'EDIT' && patientForm.value.status === 'Deceased'"
      >
        <label class="labelText">Deceased Date</label>
        <mat-form-field appearance="outline">
          <input
            formControlName="deceasedDate"
            matInput
            [matDatepicker]="deceasedDatePicker"
            placeholder="MM/DD/YYYY"
            [max]="maxDate"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deceasedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #deceasedDatePicker></mat-datepicker>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div
      class="col-md-12"
      style="text-align: right"
      *ngIf="hasMemberEditAccess || hasMemberAddAccess"
    >
      <mat-dialog-actions align="end">
        <button
          [class]="patientForm.valid ? 'btn btn-primary' : 'btn btn-default'"
          mat-raised-button
          type="submit"
          [disabled]="!patientForm.valid || processing"
        >
          {{ action === 'ADD' ? 'Submit' : 'Update' }}
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
