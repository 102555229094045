import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentPlanOverview } from 'src/app/_models/case/treatment/plan/treatmentplanoverview.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentPlanOverviewApiService {
  constructor(private httpClient: HttpClient) {}

  getTreatmentPlanOverviewDetails(caseId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/treatmentplanoverview/` + caseId
    );
  }

  updateTreatmentPlanOverview(
    thisTreatmentPlanOverview: ITreatmentPlanOverview
  ): Observable<ITreatmentPlanOverview> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITreatmentPlanOverview>(
        `${environment.apiUrl}/treatmentplanoverview`,
        thisTreatmentPlanOverview,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
