export const environment = {
  production: false,
  apiUrl: '/api/patient-services',
  referralUrl: 'https://dev-referral.bundlen.website/api/referral-services',
  ivrUrl: 'https://dev-ivr.bundlen.website/api/ivr-services',
  labUrl: 'https://dev-labs.bundlen.website/api/vital-services',
  adminPortalUrl: 'https://dev-admin.bundlen.website',
  googleMapsApiKey: 'AIzaSyBRQux8xvWFFuuvjyrYuUpkb8s5mqtIni0',
  customFormsUrl:
    'https://dev-forms.bundlen.website/api/dynamic-forms-services',
  referralsUrl: '/referrals',
  streamApiKey: 's6xpp8xdf4fw',
  httpInterceptor: {
    allowedList: [`/api/*`],
  },
  surveyJsLicenseKey:
    'OGJmYTEzNDYtYjc5Zi00ZWMzLWI4NDEtMDhmZmYxY2I4YzdmJmRvbWFpbnM6YnVuZGxlbi53ZWJzaXRlLG1pbmR3aXNlaGVhbHRoLmNvbSxhc3BlY3RuLmNvbSxtaXRlcmhlYWx0aC5jb207MT0yMDI1LTA3LTExLDI9MjAyNS0wNy0xMSw0PTIwMjUtMDctMTE=',
  referralFunctionality: true,
  enableLabOrders: true,
  treatmentSectionEnabled: true,
};
