import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CareLog, Case } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services/';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
  randonString,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-carelog-add',
  templateUrl: './carelog-add.component.html',
  styleUrls: ['./carelog-add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCareLogDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() memberGoals: any[];

  @Output() taskCompleted = new EventEmitter<any>();
  goals: any[];

  public caseForm: FormGroup;
  note: CareLog;

  processing: boolean = false;
  isProcessing: boolean = false;
  todaysDate = new Date();

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    public memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();
    this.filterActiveGoals();
    if (this.carelog) {
      this.caseForm.controls['message'].setValue(this.carelog.message);
      this.caseForm.controls['goal'].setValue(this.carelog.goal);
      this.caseForm.controls['customMessage'].setValue(
        this.carelog.customMessage
      );
      this.caseForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
      this.caseForm.controls['memberGoals'].setValue(this.carelog.memberGoals);
    } else {
      this.caseForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }
  }

  filterActiveGoals() {
    if (this.memberGoals) {
      this.goals = this.memberGoals.filter((goal) => {
        return goal.status === 'Active';
      });
    }
  }

  private buildForm() {
    this.caseForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      patientId: new FormControl(''),
      message: new FormControl('', Validators.required),
      customMessage: new FormControl(''),
      caseNumber: new FormControl(null),
      goal: new FormControl(null, Validators.compose([])),
      activityDateTime: new FormControl(null, Validators.required),
      memberGoals: new FormControl(
        null,
        Validators.compose([
          this.case.goalDrivenCare ? Validators.required : null,
        ])
      ),
    });
  }

  onCaseNoteSubmit() {
    if (this.caseForm.invalid) {
      this.caseForm.markAllAsTouched();
      return;
    }

    this.note = Object.assign({}, this.note);
    this.note = Object.assign(this.note, this.caseForm.value);

    if (this.note.goals && this.note.goals.length == 0) this.note.goals = null;
    this.note.patientId = this.case.patientId;

    this.note.activityDateTime = formatDateTime(
      this.caseForm.controls['activityDateTime'].value
    );

    this.processing = true;
    if (this.carelog) {
      this.note.logDate = this.carelog.logDate;
      this.note.logNumber = this.carelog.logNumber;
      this.note.shift = this.carelog.shift;
      this.note.id = this.carelog.id;
      this.note.caseId = this.case.id;
      this.careLogHttpService.editCareLog(this.case.id, this.note).subscribe(
        () => {
          this.processing = false;
          let outputResponse = { note: this.note, response: 'success' };
          this.taskCompleted.emit(outputResponse);
          this.toastMessageService.displaySuccessMessage(
            'Care log has been updated'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to update care log'
          );
        }
      );
    } else {
      this.note.logDate = formatDate(this.logDate);
      this.note.caseId = this.case.id;
      this.note.logNumber = randonString(7);
      this.note.shift = this.shift;
      this.careLogHttpService.addCareLog(this.case.id, this.note).subscribe(
        () => {
          this.processing = false;
          let outputResponse = { note: this.note, response: 'success' };
          this.taskCompleted.emit(outputResponse);
          this.toastMessageService.displaySuccessMessage(
            'Care log has been added'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to add new care log'
          );
        }
      );
    }
  }
}
