import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { IMemberGoal } from 'src/app/_models/assessments/patientassessmentgoal.model';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditSessionGoalComponent } from './add-edit-session-goal/add-edit-session-goal.component';

@Component({
  selector: 'app-goal-card',
  templateUrl: './goal-card.component.html',
  styleUrls: ['./goal-card.component.css'],
})
export class GoalCardComponent implements OnInit {
  @Input() goal: any;
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() type: string;

  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Output() hasChanges = new EventEmitter<boolean>();

  isProcessing: boolean = false;

  constructor(
    public careLogHttpService: CareLogHttpService,
    public dialog: MatDialog,
    private memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {}

  editSessionGoal(goal: IMemberGoal) {
    let dialogRef = this.dialog.open(AddEditSessionGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDITEXISTINGGOAL',
        goal: goal,
        therapySession: this.therapySession,
        loggedInUserId: this.loggedInUserId,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.hasChanges.emit(true);
      }
    });
  }

  public addGoalForSession(goal: IAssessmentGoal) {
    let dialogRef = this.dialog.open(AddEditSessionGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADDEXISTINGGOAL',
        goal: goal,
        therapySession: this.therapySession,
        loggedInUserId: this.loggedInUserId,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.hasChanges.emit(true);
      }
    });
  }

  deleteGoal(goalToBeDeleted) {
    let deleteDialogRef = this.dialog.open(
      GeneralDeleteConfirmDialogComponent,
      {
        autoFocus: false,
        disableClose: true,
        data: {
          message: 'Are you sure you want to remove this goal?',
        },
      }
    );

    deleteDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.memberGoalHttpService
          .deletePatientAssessmentGoal(goalToBeDeleted)
          .subscribe(
            (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Goal has been deleted.'
              );
              this.hasChanges.emit(true);
            },
            (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to delete goal'
              );
            }
          );
      }
    });
  }
}
