import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ICaseTreatmentTeam } from 'src/app/_models/case/treatment/admission/casetreatmentteam.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CaseTreatmentTeamApiService {
  constructor(private httpClient: HttpClient) {}

  getCaseTreatmentTeamList(
    caseId: string,
    reviewId: string,
    recordType: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (caseId) params = params.append('caseId', caseId);
    if (reviewId) params = params.append('reviewId', reviewId);
    if (recordType) params = params.append('recordType', recordType);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/casetreatmentteamlist`,
      { params: params }
    );
  }

  getCaseTreatmentTeamDetails(
    casetreatmentteamId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/casetreatmentteam/` + casetreatmentteamId
    );
  }

  addCaseTreatmentTeam(
    thisCaseTreatmentTeam: ICaseTreatmentTeam
  ): Observable<ICaseTreatmentTeam> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICaseTreatmentTeam>(
        `${environment.apiUrl}/casetreatmentteam`,
        thisCaseTreatmentTeam,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateCaseTreatmentTeam(
    thisCaseTreatmentTeam: ICaseTreatmentTeam
  ): Observable<ICaseTreatmentTeam> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ICaseTreatmentTeam>(
        `${environment.apiUrl}/casetreatmentteam`,
        thisCaseTreatmentTeam,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteTreatmentTeam(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/casetreatmentteam/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
