<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Team Member</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Team Member</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="casetreatmentteamForm"
    novalidate
    (ngSubmit)="onCaseTreatmentTeamFormSubmit()"
  >
    <div class="row">
      <!-- Staff Member -->
      <div class="col-md-6">
        <label class="labelText">
          Staff Member <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="staffMemberId"
            placeholder="Staff Member"
            (selectionChange)="selectedClinician($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Provider Name"
                noEntriesFoundLabel="No matching provider found"
                [(ngModel)]="providerName"
                (ngModelChange)="filterProvider()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let provider of filteredProvidersList"
              [value]="provider.id"
            >
              {{ provider.lastName }}, {{ provider.firstName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentteamForm.controls['staffMemberId'].invalid"
          >
            Please select Staff Member
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Role/Relation -->
      <div class="col-md-6">
        <label class="labelText">
          Role/Relation <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="roleRelation">
            <mat-option *ngFor="let role of roleList" [value]="role.id">
              {{ role.name }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="casetreatmentteamForm.controls['roleRelation'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Start Date-->
      <div class="col-md-6">
        <label class="labelText">
          Start Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error
            *ngIf="casetreatmentteamForm.controls['startDate'].invalid"
          >
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- End Date-->
      <div class="col-md-6">
        <label class="labelText">End Date </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
            autoSlashDate
            [min]="casetreatmentteamForm.controls['startDate'].value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error *ngIf="casetreatmentteamForm.controls['endDate'].invalid">
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Primary Care Staff-->
      <div class="col-md-4">
        <label class="labelText">
          Primary Care Staff <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="primaryCareStaff">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentteamForm.controls['primaryCareStaff'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--   Deny Access To Client Records -->
      <div class="col-md-4">
        <label class="labelText">
          Deny Access To Client Records <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="denyAccessToClientRecords">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentteamForm.controls['denyAccessToClientRecords']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Review Member -->
      <div class="col-md-4">
        <label class="labelText">
          Review Member <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="reviewMember">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentteamForm.controls['reviewMember'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Sub Team Member -->
      <div class="col-md-12">
        <label class="labelText"> Sub-Teams </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="selectedSubTeams"
            multiple
            placeholder="Sub-Teams"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-option
                  class="mat-elevation-z2 mat-chip-active"
                  *ngFor="
                    let selectedSubTeam of casetreatmentteamForm.controls[
                      'selectedSubTeams'
                    ].value;
                    let i = index
                  "
                  (removed)="removeSubTeam(selectedSubTeam, i)"
                >
                  {{ selectedSubTeam.id }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </mat-chip-listbox>
            </mat-select-trigger>
            <ng-container>
              <mat-option
                *ngFor="let subTeam of treatmentSubTeams"
                [value]="subTeam"
              >
                {{ subTeam.id }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentteamForm.controls['selectedSubTeams'].invalid"
          >
            Please select sub-teams
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">Notes</label>
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="notes"></textarea>
          <mat-error *ngIf="casetreatmentteamForm.controls['notes'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" *ngIf="action === 'EDIT'">
        <label class="labelText">Status</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
          <mat-error *ngIf="casetreatmentteamForm.controls['status'].invalid">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!casetreatmentteamForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
