import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IHarePsychopathy } from 'src/app/_models/assessments/personality/harepsychopathy.model';
import {
  HarePsychopathyApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-hare-psychopathy',
  templateUrl: './hare-psychopathy.component.html',
  styleUrls: ['./hare-psychopathy.component.css'],
})
export class HarePsychopathyComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public harePsychopathyApiService: HarePsychopathyApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public harePsychopathyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  harePsychopathyAssessment: IHarePsychopathy;
  harePsychopathyAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.harePsychopathyForm, this.errors);

    this.loadHarePsychopathyAssessment();
  }

  loadHarePsychopathyAssessment() {
    this.harePsychopathyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.harePsychopathyApiService
        .getHarePsychopathyDetails(this.assessmentId)
        .subscribe((harePsychopathyAssessment) => {
          if (harePsychopathyAssessment.data) {
            this.harePsychopathyForm.controls['charm'].setValue(
              harePsychopathyAssessment.data.charm.answer
            );
            this.harePsychopathyForm.controls['psychopathDiagnosis'].setValue(
              harePsychopathyAssessment.data.psychopathDiagnosis.answer
            );
            this.harePsychopathyForm.controls['senseOfSelfWorth'].setValue(
              harePsychopathyAssessment.data.senseOfSelfWorth.answer
            );
            this.harePsychopathyForm.controls['frustrationTolerance'].setValue(
              harePsychopathyAssessment.data.frustrationTolerance.answer
            );
            this.harePsychopathyForm.controls['lyingAndDeception'].setValue(
              harePsychopathyAssessment.data.lyingAndDeception.answer
            );
            this.harePsychopathyForm.controls['sincerity'].setValue(
              harePsychopathyAssessment.data.sincerity.answer
            );
            this.harePsychopathyForm.controls['lackOfRemorse'].setValue(
              harePsychopathyAssessment.data.lackOfRemorse.answer
            );
            this.harePsychopathyForm.controls['lackOfAffect'].setValue(
              harePsychopathyAssessment.data.lackOfAffect.answer
            );
            this.harePsychopathyForm.controls['lackOfEmpathy'].setValue(
              harePsychopathyAssessment.data.lackOfEmpathy.answer
            );
            this.harePsychopathyForm.controls['lifestyle'].setValue(
              harePsychopathyAssessment.data.lifestyle.answer
            );
            this.harePsychopathyForm.controls['poorBehavior'].setValue(
              harePsychopathyAssessment.data.poorBehavior.answer
            );
            this.harePsychopathyForm.controls['sexualRelations'].setValue(
              harePsychopathyAssessment.data.sexualRelations.answer
            );
            this.harePsychopathyForm.controls['earlyBehavior'].setValue(
              harePsychopathyAssessment.data.earlyBehavior.answer
            );
            this.harePsychopathyForm.controls[
              'usingElectronicsLongerThanPlanned'
            ].setValue(
              harePsychopathyAssessment.data.usingElectronicsLongerThanPlanned
                .answer
            );
            this.harePsychopathyForm.controls['impulse'].setValue(
              harePsychopathyAssessment.data.impulse.answer
            );
            this.harePsychopathyForm.controls['irresponsibleParent'].setValue(
              harePsychopathyAssessment.data.irresponsibleParent.answer
            );
            this.harePsychopathyForm.controls['maritalRelationships'].setValue(
              harePsychopathyAssessment.data.maritalRelationships.answer
            );
            this.harePsychopathyForm.controls['delinquency'].setValue(
              harePsychopathyAssessment.data.delinquency.answer
            );
            this.harePsychopathyForm.controls['paroleRisk'].setValue(
              harePsychopathyAssessment.data.paroleRisk.answer
            );
            this.harePsychopathyForm.controls[
              'failureToAcceptResponsibility'
            ].setValue(
              harePsychopathyAssessment.data.failureToAcceptResponsibility
                .answer
            );
            this.harePsychopathyForm.controls['offense'].setValue(
              harePsychopathyAssessment.data.offense.answer
            );
            this.harePsychopathyForm.controls['drugOrAlcoholAbuse'].setValue(
              harePsychopathyAssessment.data.drugOrAlcoholAbuse.answer
            );

            this.riskScore = harePsychopathyAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.harePsychopathyForm.invalid) {
      this.harePsychopathyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.harePsychopathyAssessment = Object.assign(
      {},
      this.harePsychopathyAssessment
    );
    this.harePsychopathyAssessment.patientId =
      this.harePsychopathyForm.controls['patientId'].value;
    this.harePsychopathyAssessment.sessionId =
      this.harePsychopathyForm.controls['sessionId'].value;
    this.harePsychopathyAssessment.assessmentRequestId =
      this.harePsychopathyForm.controls['assessmentRequestId'].value;
    this.harePsychopathyAssessment.charm = {
      answer: this.harePsychopathyForm.controls['charm'].value,
    };
    this.harePsychopathyAssessment.psychopathDiagnosis = {
      answer: this.harePsychopathyForm.controls['psychopathDiagnosis'].value,
    };
    this.harePsychopathyAssessment.senseOfSelfWorth = {
      answer: this.harePsychopathyForm.controls['senseOfSelfWorth'].value,
    };
    this.harePsychopathyAssessment.frustrationTolerance = {
      answer: this.harePsychopathyForm.controls['frustrationTolerance'].value,
    };
    this.harePsychopathyAssessment.lyingAndDeception = {
      answer: this.harePsychopathyForm.controls['lyingAndDeception'].value,
    };
    this.harePsychopathyAssessment.sincerity = {
      answer: this.harePsychopathyForm.controls['sincerity'].value,
    };
    this.harePsychopathyAssessment.lackOfRemorse = {
      answer: this.harePsychopathyForm.controls['lackOfRemorse'].value,
    };
    this.harePsychopathyAssessment.lackOfAffect = {
      answer: this.harePsychopathyForm.controls['lackOfAffect'].value,
    };
    this.harePsychopathyAssessment.lackOfEmpathy = {
      answer: this.harePsychopathyForm.controls['lackOfEmpathy'].value,
    };
    this.harePsychopathyAssessment.lifestyle = {
      answer: this.harePsychopathyForm.controls['lifestyle'].value,
    };
    this.harePsychopathyAssessment.poorBehavior = {
      answer: this.harePsychopathyForm.controls['poorBehavior'].value,
    };
    this.harePsychopathyAssessment.sexualRelations = {
      answer: this.harePsychopathyForm.controls['sexualRelations'].value,
    };
    this.harePsychopathyAssessment.earlyBehavior = {
      answer: this.harePsychopathyForm.controls['earlyBehavior'].value,
    };
    this.harePsychopathyAssessment.usingElectronicsLongerThanPlanned = {
      answer:
        this.harePsychopathyForm.controls['usingElectronicsLongerThanPlanned']
          .value,
    };
    this.harePsychopathyAssessment.impulse = {
      answer: this.harePsychopathyForm.controls['impulse'].value,
    };
    this.harePsychopathyAssessment.irresponsibleParent = {
      answer: this.harePsychopathyForm.controls['irresponsibleParent'].value,
    };
    this.harePsychopathyAssessment.maritalRelationships = {
      answer: this.harePsychopathyForm.controls['maritalRelationships'].value,
    };
    this.harePsychopathyAssessment.delinquency = {
      answer: this.harePsychopathyForm.controls['delinquency'].value,
    };
    this.harePsychopathyAssessment.paroleRisk = {
      answer: this.harePsychopathyForm.controls['paroleRisk'].value,
    };
    this.harePsychopathyAssessment.failureToAcceptResponsibility = {
      answer:
        this.harePsychopathyForm.controls['failureToAcceptResponsibility']
          .value,
    };
    this.harePsychopathyAssessment.offense = {
      answer: this.harePsychopathyForm.controls['offense'].value,
    };
    this.harePsychopathyAssessment.drugOrAlcoholAbuse = {
      answer: this.harePsychopathyForm.controls['drugOrAlcoholAbuse'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.harePsychopathyAssessment.accessCode = this.accessCode;

      this.harePsychopathyApiService
        .addGuestHarePsychopathy(this.harePsychopathyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted hare-psychopathy Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting hare-psychopathy Assessment'
            );
          }
        );
    } else {
      this.harePsychopathyApiService
        .addHarePsychopathy(this.harePsychopathyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted hare-psychopathy Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting hare-psychopathy Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    charm: [
      { type: 'required', message: 'Glibness / superficial charm is required' },
    ],
    psychopathDiagnosis: [
      {
        type: 'required',
        message: 'Previous diagnosis as psychopath (or similar) is required',
      },
    ],
    senseOfSelfWorth: [
      {
        type: 'required',
        message: 'Egocentricity/grandiose sense of self-worth is required',
      },
    ],
    frustrationTolerance: [
      {
        type: 'required',
        message: 'Proneness to boredom / low frustration tolerance is required',
      },
    ],
    lyingAndDeception: [
      {
        type: 'required',
        message: 'Pathological lying and deception is required',
      },
    ],
    sincerity: [
      { type: 'required', message: 'Conning / lack of sincerity is required' },
    ],
    lackOfRemorse: [
      { type: 'required', message: 'Lack of remorse or guilt is required' },
    ],
    lackOfAffect: [
      {
        type: 'required',
        message: 'Lack of affect and emotional depth is required',
      },
    ],
    lackOfEmpathy: [
      { type: 'required', message: 'Callous / lack of empathy is required' },
    ],
    lifestyle: [
      { type: 'required', message: 'Parasitic lifestyle is required' },
    ],
    poorBehavior: [
      {
        type: 'required',
        message: 'Short-tempered / poor behavioral controls is required',
      },
    ],
    sexualRelations: [
      { type: 'required', message: 'Promiscuous sexual relations is required' },
    ],
    earlyBehavior: [
      { type: 'required', message: 'Early behavior problems is required' },
    ],
    usingElectronicsLongerThanPlanned: [
      {
        type: 'required',
        message: 'Lack of realistic, long-term plans is required',
      },
    ],
    impulse: [{ type: 'required', message: 'Impulsivity is required' }],
    irresponsibleParent: [
      {
        type: 'required',
        message: 'Irresponsible behavior as parent is required',
      },
    ],
    maritalRelationships: [
      {
        type: 'required',
        message: 'Frequent marital relationships is required',
      },
    ],
    delinquency: [
      { type: 'required', message: 'Juvenile delinquency is required' },
    ],
    paroleRisk: [
      {
        type: 'required',
        message: 'Poor probation or parole risk is required',
      },
    ],
    failureToAcceptResponsibility: [
      {
        type: 'required',
        message: 'Failure to accept responsibility for own actions is required',
      },
    ],
    offense: [
      { type: 'required', message: 'Many types of offense is required' },
    ],
    drugOrAlcoholAbuse: [
      {
        type: 'required',
        message:
          'Drug or alcohol abuse not direct cause of antisocial behavior is required',
      },
    ],
  };

  private buildForm() {
    this.harePsychopathyForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      charm: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      psychopathDiagnosis: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      senseOfSelfWorth: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      frustrationTolerance: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lyingAndDeception: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sincerity: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lackOfRemorse: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lackOfAffect: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lackOfEmpathy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifestyle: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      poorBehavior: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sexualRelations: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      earlyBehavior: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      usingElectronicsLongerThanPlanned: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      impulse: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      irresponsibleParent: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      maritalRelationships: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      delinquency: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      paroleRisk: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      failureToAcceptResponsibility: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      offense: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drugOrAlcoholAbuse: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.harePsychopathyForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.harePsychopathyForm) {
      return;
    }
    const form = this.harePsychopathyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
