<form
  style="margin-top: 25px"
  [formGroup]="casetreatmentadmissionsubstanceForm"
  novalidate
  (ngSubmit)="onCaseTreatmentAdmissionSubstanceFormSubmit()"
>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-3" style="text-align: center">
      <label class="labelHeader">
        Primary <span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3" style="text-align: center">
      <label class="labelHeader">Secondary</label>
    </div>
    <div class="col-md-3" style="text-align: center">
      <label class="labelHeader">Tertiary</label>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Substance <span style="color: red">*</span>
      </label>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="primarySubstance"
          placeholder="Primary Substance Code"
        >
          <mat-option
            *ngFor="let option of substanceCodeOptions"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['primarySubstance']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="secondarySubstance"
          placeholder="Secondary Substance Code"
        >
          <mat-option
            *ngFor="let option of substanceCodeOptions"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['secondarySubstance']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="tertiarySubstance"
          placeholder="Tertiary Substance Code"
        >
          <mat-option
            *ngFor="let option of substanceCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['tertiarySubstance']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Severity <span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="primarySubstanceSeverity"
          placeholder="Primary Severity Code"
        >
          <mat-option
            *ngFor="let option of severityCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'primarySubstanceSeverity'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="secondarySubstanceSeverity"
          placeholder="Secondary Severity Code"
        >
          <mat-option
            *ngFor="let option of severityCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="tertiarySubstanceSeverity"
          placeholder="Tertiary Severity Code"
        >
          <mat-option
            *ngFor="let option of severityCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Frequency <span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="primarySubstanceFrequency"
          placeholder="Primary Frequency Code"
        >
          <mat-option
            *ngFor="let option of frequencyCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'primarySubstanceFrequency'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="secondarySubstanceFrequency"
          placeholder="Secondary Frequency Code"
        >
          <mat-option
            *ngFor="let option of frequencyCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="tertiarySubstanceFrequency"
          placeholder="Tertiary Frequency Code"
        >
          <mat-option
            *ngFor="let option of frequencyCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Method <span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="primarySubstanceMethod"
          placeholder="Primary Intake Method"
        >
          <mat-option
            *ngFor="let option of intakeTypeCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'primarySubstanceMethod'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="secondarySubstanceMethod"
          placeholder="Secondary Intake Method"
        >
          <mat-option
            *ngFor="let option of intakeTypeCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="tertiarySubstanceMethod"
          placeholder="Tertiary Intake Method"
        >
          <mat-option
            *ngFor="let option of intakeTypeCodeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Detailed Drug Code <span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="primarySubstanceDetailedDrugCode"
          placeholder="Enter Drug Code"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'primarySubstanceDetailedDrugCode'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="secondarySubstanceDetailedDrugCode"
          placeholder="Enter Drug Code"
        />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="tertiarySubstanceDetailedDrugCode"
          placeholder="Enter Drug Code"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Age First Used<span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="primaryAgeFirstUsed"
          type="number"
          placeholder="Age First Used"
          min="0"
          max="97"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['primaryAgeFirstUsed']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="secondaryAgeFirstUsed"
          type="number"
          placeholder="Age First Used"
        />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="tertiaryAgeFirstUsed"
          type="number"
          placeholder="Age First Used"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" style="text-align: right">
      <label class="labelBold">
        Days since last use<span style="color: red">*</span>
      </label>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="primaryDaysSinceLastUse"
          type="number"
          placeholder="Days since last use"
          min="0"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'primaryDaysSinceLastUse'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="secondaryDaysSinceLastUse"
          type="number"
          placeholder="Days since last use"
        />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="tertiaryDaysSinceLastUse"
          type="number"
          placeholder="Days since last use"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row" style="margin-top: 25px">
    <div class="col-md-6">
      <label class="labelBold">
        Does Client Currently use Tobacoo?
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="tobaccoUse" placeholder="Tobacco Use">
          <mat-option *ngFor="let option of tobaccoUseOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['tobaccoUse'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelBold">
        Smoker Status
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="smokerStatus" placeholder="Smoker Status">
          <mat-option
            *ngFor="let option of smokerStatusOptions"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['smokerStatus'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelBold">
        # of Days Abstinent in Last 30 days
        <span style="color: red">*</span>
      </label>

      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="daysAbstinent"
          type="number"
          placeholder="Days Abstinent"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['daysAbstinent']
              .invalid
          "
        >
          Acceptable value 0 - 30
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelBold">
        # of Days in Support Group in Last 30 days
        <span style="color: red">*</span>
      </label>

      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="daysInSupportGroup"
          type="number"
          placeholder="Days in Support Group"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['daysInSupportGroup']
              .invalid
          "
        >
          Acceptable value 0 - 30
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelBold">
        # of Days AA/NA/Similiar Meetings in Last 30 Days
        <span style="color: red">*</span>
      </label>

      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="daysInMeetings"
          type="number"
          placeholder="Days Attended Meetings"
          min="0"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls['daysInMeetings']
              .invalid
          "
        >
          Acceptable value 0 - 30
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelBold">
        # of Days Waited to Enter Treatment
        <span style="color: red">*</span>
      </label>

      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="daysWaitedToEnterTreatment"
          type="number"
          placeholder="Days Waited"
          min="0"
        />
        <mat-error
          *ngIf="
            casetreatmentadmissionsubstanceForm.controls[
              'daysWaitedToEnterTreatment'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12" style="text-align: right" *ngIf="!readOnly">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!casetreatmentadmissionsubstanceForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
