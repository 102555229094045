<div *ngIf="hasCaseDocumentsViewAccess" style="padding: 20px; background-color: #FFFFFF;">
  <div class="row" *ngIf="
      hasCaseDocumentsAddAccess &&
      patient.status === 'Active' &&
      case.status === 'active'
    " style="margin-bottom: 15px">
    <div class="col-md-12" style="text-align: right">
      <button type="button" mat-raised-button color="warn" (click)="addDocument()">
        + Upload Document
      </button>
    </div>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving Document records
    </mat-error>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No document found </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0" style="margin-bottom: 15px">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row.fileName }}

            <div style="font-size: 13px; color: #888888" *ngIf="row.additionalInfo">
              <b>Notes : </b> <i>{{ row.additionalInfo }}</i>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef>Document Type</th>
          <td mat-cell *matCellDef="let row">{{ row.fileType }}</td>
        </ng-container>
        <ng-container matColumnDef="uploadedDate">
          <th mat-header-cell *matHeaderCellDef>Upload Date</th>
          <td mat-cell *matCellDef="let row">{{ row.createdDate }}</td>
        </ng-container>
        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
          <td mat-cell *matCellDef="let row">
            <label class="labelBold">{{ row.createdBy }}</label>
            <br />
            <span style="font-size: 11px">
              Uploaded on : {{ row.createdDate | date : 'MM/dd/y' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button mat-raised-button [matMenuTriggerFor]="menu" class="btn btn-just-icon btn-white btn-fab btn-round">
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="downloadFile(record.caseId, record.id)">
                <mat-icon style="color: green">download</mat-icon>
                <span>Download File</span>
              </button>
              <button mat-menu-item (click)="deleteDocument(record)"
                *ngIf="hasCaseDocumentsDeleteAccess && case.status === 'active'">
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator #paginator [showFirstLastButtons]="true" [length]="data.total" [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page" (page)="getNext($event)">
    </mat-paginator>
  </div>
</div>

<mat-card appearance="raised" *ngIf="!hasCaseDocumentsViewAccess">
  <mat-error> Sorry, You don't have access to view documents </mat-error>
</mat-card>