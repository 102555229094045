import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { ICaseTreatmentTeam } from 'src/app/_models/case/treatment/admission/casetreatmentteam.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { CaseTreatmentTeamApiService } from 'src/app/_services/case/treatment/admission/casetreatmentteam.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditCaseTreatmentTeamDialogComponent } from './dialog/add-edit-case-treatment-team-dialog/add-edit-case-treatment-team-dialog.component';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';

@Component({
  selector: 'app-case-admission-care-team',
  templateUrl: './case-admission-care-team.component.html',
  styleUrl: './case-admission-care-team.component.css',
})
export class CaseAdmissionCareTeamComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() case: Case;
  @Input() patient: Patient;
  @Input() recordType: string;
  @Input() selectedReview: ITreatmentReview;
  @Input() selectedPlan: ITreatmentPlan;

  displayedColumns = [
    'staffName',
    'roleRelation',
    'startDate',
    'endDate',
    'reviewMember',
    'primaryCareStaff',
    'denyAccessToClientRecords',
    'selectedSubTeams',
    'notes',
    'status',
    'actions',
  ];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;
  patientId: string;

  constructor(
    public dialog: MatDialog,
    public casetreatmentteamApiService: CaseTreatmentTeamApiService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadCaseTreatmentTeamList();
  }

  addCaseTreatmentTeam() {
    const dialogRef = this.dialog.open(
      AddEditCaseTreatmentTeamDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'ADD',
          casetreatmentteam: null,
          case: this.case,
          patient: this.patient,
          selectedReview: this.selectedReview,
          recordType: this.recordType,
        },
      }
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseTreatmentTeamList();
      }
    });
  }

  public editCaseTreatmentTeam(casetreatmentteam: ICaseTreatmentTeam) {
    const dialogRef = this.dialog.open(
      AddEditCaseTreatmentTeamDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
        data: {
          action: 'EDIT',
          casetreatmentteam,
          case: this.case,
          patient: this.patient,
          recordType: this.recordType,
        },
      }
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseTreatmentTeamList();
      }
    });
  }

  public loadCaseTreatmentTeamList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.casetreatmentteamApiService
      .getCaseTreatmentTeamList(
        this.case?.id,
        this.selectedReview?.id,
        this.recordType,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadCaseTreatmentTeamList();
  }

  deleteCaseTreatmentTeam(treatmentprogram: any) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this team member?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.casetreatmentteamApiService
          .deleteTreatmentTeam(treatmentprogram.id)
          .subscribe(() => {
            this.loadCaseTreatmentTeamList();
          });
      }
    });
  }
}
