import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  Countries,
  DataTablesModel,
  Patient,
  ResponseModal,
} from '../../_models';
import { IReferral } from 'src/app/_models/referrals/referral.model';

@Injectable({ providedIn: 'root' })
export class PatientService {
  constructor(private httpClient: HttpClient) {}

  getPatients(
    facilityId: string,
    memberNumber: string,
    firstName: string,
    lastName: string,
    phone: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (facilityId) params = params.append('facilityId', facilityId);
    if (firstName) params = params.append('firstName', firstName);
    if (lastName) params = params.append('lastName', lastName);
    if (phone) params = params.append('phone', phone);
    if (memberNumber) params = params.append('memberNumber', memberNumber);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patients`,
      { params: params }
    );
  }

  getNonFacilityPatients(
    country: string,
    facilityId: string,
    firstName: string,
    lastName: string,
    phone: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('facilityId', facilityId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    if (firstName) params = params.append('firstName', firstName);
    if (lastName) params = params.append('lastName', lastName);
    if (phone) params = params.append('phone', phone);

    if (status) params = params.append('status', status);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patients-not-at-facility`,
      { params: params }
    );
  }

  getMyTherapyPatients(pageSize: number, pageIndex: number, filter: string) {
    let params = new HttpParams();

    if (filter) {
      params = params.append('status', filter);
    }

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/physician-therapy-patients`,
      { params: params }
    );
  }

  getMyPatients(
    pageSize: number,
    pageIndex: number,
    filter: string,
    sort: string,
    memberNumber: string,
    firstName: string,
    lastName: string,
    phone: string
  ): Observable<DataTablesModel> {
    let path = 'patients-by-physician';

    let params = new HttpParams();

    if (filter) {
      params = params.append('status', filter);
    }
    if (sort) {
      params = params.append('order', sort);
    }
    if (memberNumber) params = params.append('memberNumber', memberNumber);
    if (firstName) params = params.append('firstName', firstName);
    if (lastName) params = params.append('lastName', lastName);
    if (phone) params = params.append('phone', phone);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/` + path,
      { params: params }
    );
  }

  getPatientDetails(
    patientId: string,
    country: string
  ): Observable<ResponseModal> {
    let path = 'patient';
    if (country && country !== Countries.USA) {
      path += '-' + country.toLowerCase();
    }
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/` + path + `/` + patientId
    );
  }

  addPatient(patient: Patient): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/patient`, patient, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addReferralPatient(referral: IReferral): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/referral-patient`, referral, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePatient(patient: Patient): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(`${environment.apiUrl}/patient`, patient, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateSelfDetails(patient: Patient, consentRequestId: string) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/memberselfupdatedetails/${consentRequestId}`,
        patient,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGuestIntakeDemographicDetails(
    requestId: string,
    accessCode: string,
    consentRequestId: string,
    patient: Patient
  ) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestintake/updatedemographics/${requestId}/${accessCode}/${consentRequestId}`,
        patient,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  activatePatientProfile(patient: Patient) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/activatepatientprofile`, patient, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getActiveProvidersAtMemberFacility(patientId: string) {
    let params = new HttpParams();
    if (patientId) params = params.append('patientId', patientId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/providersatpatientfacility`,
      {
        params: params,
      }
    );
  }

  getSupervisorList(patientId: string, facilityId: string) {
    let params = new HttpParams();
    if (patientId) params = params.append('patientId', patientId);
    if (facilityId) params = params.append('facilityId', facilityId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/supervisingrenderingproviderlist`,
      {
        params: params,
      }
    );
  }

  getAllActiveSupervisors(sessionId: string) {
    let params = new HttpParams();
    if (sessionId) params = params.append('sessionId', sessionId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/allactivesupervisors`,
      {
        params: params,
      }
    );
  }
  // Patient Get Self Details
  getMyDetails() {
    return this.httpClient.get<any>(`${environment.apiUrl}/membergetmydetails`);
  }

  // Patient Intake Guest Link
  getGuestIntakeMemberDetails(requestId: string, accessCode: string) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/guestintake/getintakememberdetails/${requestId}/${accessCode}`
    );
  }

  assignAccessToUsers(memberId: String, providerId: String): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/patient-access/` + memberId, providerId)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  unAssignAccessToUsers(
    memberId: String,
    providerId: String
  ): Observable<void> {
    return this.httpClient
      .delete<void>(
        `${environment.apiUrl}/patient-access/` + memberId + `/` + providerId
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  loadUnauthorizedStaffMembers(
    memberId: string,
    firstName: string,
    lastName: string,
    email: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('memberId', memberId);

    if (firstName) params = params.append('firstName', firstName.trim());
    if (lastName) params = params.append('lastName', lastName.trim());
    if (email)
      params = params.append('email', encodeURIComponent(email.trim()));

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patient-unauthorized-users`,
      {
        params: params,
      }
    );
  }

  getAuthorizedStaffMembers(
    memberId: string,
    firstName: string,
    lastName: string,
    email: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('memberId', memberId);

    if (firstName) params = params.append('firstName', firstName.trim());
    if (lastName) params = params.append('lastName', lastName.trim());
    if (email)
      params = params.append('email', encodeURIComponent(email.trim()));

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patient-authorized-users`,
      {
        params: params,
      }
    );
  }
}
