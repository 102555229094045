import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentDischargeSubstance } from 'src/app/_models/case/treatment/discharge/treatmentdischargesubstance.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentDischargeSubstanceApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentDischargeSubstanceDetails(treatmentdischargesubstanceId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/treatmentdischargesubstance/` + treatmentdischargesubstanceId);
    }
    updateTreatmentDischargeSubstance(thisTreatmentDischargeSubstance: ITreatmentDischargeSubstance): Observable<ITreatmentDischargeSubstance> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentDischargeSubstance>(`${environment.apiUrl}/treatmentdischargesubstance`, thisTreatmentDischargeSubstance, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}
