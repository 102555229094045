import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatStepper } from '@angular/material/stepper';
import { DataTablesModel, IDiagnosis, Patient } from 'src/app/_models';
import {
  DiagnosisApiService,
  IcdCodeService,
  ToastMessageService,
} from 'src/app/_services';
import { FavoritesSandbox } from 'src/app/shared/sandbox/favorites-sandbox';

@Component({
  selector: 'app-add-diagnosis',
  templateUrl: './add-diagnosis.component.html',
  styleUrls: ['./add-diagnosis.component.css'],
})
export class AddDiagnosisComponent implements OnInit {
  diagnosis: IDiagnosis;
  public diagnosisForm: FormGroup;

  patient: Patient;
  parentClass: string = '';

  isProcessing: boolean = false;
  isLoadingResults: boolean = false;

  icdSearchName: string = '';
  selectedICD: string = '';

  // Data Tables
  icdCodeData: DataTablesModel = {} as DataTablesModel;
  favICDCodeData: DataTablesModel = {} as DataTablesModel;

  // Sandbox
  sandboxFavList: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddDiagnosisComponent>,
    public diagnosisApiService: DiagnosisApiService,
    public icdCodeService: IcdCodeService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private favoritesSandbox: FavoritesSandbox
  ) {
    this.patient = data.patient;
    this.parentClass = data.parentClass;
  }

  ngOnInit(): void {
    this.icdCodeData.page = 0;
    this.icdCodeData.per_page = 25;

    this.favICDCodeData.page = 0;
    this.favICDCodeData.per_page = 100;

    this.buildDiagnosisForm();
    this.loadIcdList();
    this.loadFavoriteICD();

    // Subcribe to favorites sandbox
    this.favoritesSandbox.favoritesPayload$.subscribe((favoritesPayload) => {
      if (favoritesPayload) {
        this.sandboxFavList = favoritesPayload.data;

        this.toggleAllFavourites();
      }
    });
  }

  searchIcdCode() {
    this.icdCodeData.page = 0;
    this.loadIcdList();
  }

  resetForm() {
    this.icdCodeData.page = 0;
    this.loadIcdList();
  }

  loadIcdList() {
    this.isLoadingResults = true;

    this.icdCodeService
      .getICDCodes(
        this.icdSearchName,
        this.icdCodeData.per_page,
        this.icdCodeData.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.icdCodeData.items = response.items;
            this.icdCodeData.total = response.total;
            this.toggleAllFavourites();
          } else {
            this.icdCodeData.items = [];
            this.icdCodeData.total = 0;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load ICD Codes list'
          );
        },
      });
  }

  // Load Fav ICD Codes list
  loadFavoriteICD() {
    this.icdCodeService
      .getMyFavoriteICDCodes(
        this.favICDCodeData.per_page,
        this.favICDCodeData.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.favICDCodeData.items = response.items;
            this.favICDCodeData.total = response.total;

            this.toggleAllFavourites();
          } else {
            this.favICDCodeData.items = [];
            this.favICDCodeData.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load favorite ICD codes'
          );
        },
      });
  }

  private buildDiagnosisForm() {
    this.diagnosisForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(
        this.patient.organizationId,
        Validators.required
      ),
      patientId: new FormControl(this.patient.id, Validators.required),
      icdCode: new FormControl('', Validators.required),
      shortDesc: new FormControl('', Validators.required),
      longDesc: new FormControl('', Validators.required)
    });
  }

  submitDiagnosis() {
    this.diagnosis = Object.assign({}, this.diagnosis);
    this.diagnosis = Object.assign(this.diagnosis, this.diagnosisForm.value);

    this.isProcessing = true;
    this.diagnosisApiService.addDiagnosis(this.diagnosis).subscribe(
      (response) => {
        this.isProcessing = false;
        this.dialogRef.close({ type: 'success', diagnosis: response.data });
        if (this.parentClass === 'Member') {
          this.toastMessageService.displaySuccessMessage(
            'Diagnosis has been added'
          );
        }
      },
      (error) => {
        this.isProcessing = false;
        this.toastMessageService.displayErrorMessage(
          'Error occured adding the diagnosis record'
        );
      }
    );
  }

  getNext(event: PageEvent) {
    this.icdCodeData.page = event.pageIndex;
    this.loadIcdList();
  }

  selectIcd(icdData, stepper: MatStepper) {
    this.selectedICD = icdData.icdCode;
    this.diagnosisForm.controls['icdCode'].setValue(icdData.icdCode);
    this.diagnosisForm.controls['shortDesc'].setValue(icdData.icdDescription);
    this.diagnosisForm.controls['longDesc'].setValue(icdData.icdDescription);
    stepper.next();
  }

  // From sandbox handle all the stars
  toggleAllFavourites() {
    if (this.icdCodeData.items) {
      this.icdCodeData.items.forEach((record) => {
        record.isFavorite = this.isFavorite('ICD_CODES', record.id);
      });
    }

    if (this.favICDCodeData.items) {
      this.favICDCodeData.items.forEach((record) => {
        record.isFavorite = this.isFavorite('ICD_CODES', record.id);
      });
    }
  }

  // Determine if this ICD is fav or not
  isFavorite(categoryName: string, favoriteItem: string): boolean {
    if (this.sandboxFavList && this.sandboxFavList.favoriteCategories) {
      const category = this.sandboxFavList.favoriteCategories.find(
        (cat) => cat.name === categoryName
      );
      return category && category.userFavorites.includes(favoriteItem);
    } else {
      return false;
    }
  }

  // Toggle fav
  toggleFavorite(categoryName: string, item: any): void {
    if (item.isFavorite) {
      this.favoritesSandbox.removeFavorite(categoryName, item.id);
    } else {
      this.favoritesSandbox.addFavorite(categoryName, item.id);
    }

    setTimeout(() => {
      this.loadFavoriteICD();
    }, 500);
  }
}
