import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import {
  ClaimsAPIService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AutoAccidentQualifier } from 'src/app/shared/utilities/billing/autoAccidentQualifier';
import { ClaimFilingIndicators } from 'src/app/shared/utilities/billing/claimFilingIndicators';
import { ClaimResubmissionCodesList } from 'src/app/shared/utilities/billing/claimResubmissionCodes';
import { EPSDTConditionInticatorType } from 'src/app/shared/utilities/billing/epsdtType';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { PlaceOfServiceCodes } from 'src/app/shared/utilities/billing/placeofServices';
import { Colors } from 'src/app/shared/utilities/colors';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import { IntakeSearchAddInsuranceComponent } from '../bpsa/bpsa-intake/intake-insurance-info/intake-search-add-insurance/intake-search-add-insurance.component';
import { ExistingDiagnosisComponent } from '../session-codes/session-diagnosis-codes/existing-diagnosis/existing-diagnosis.component';
import { EditConfirmProcedureCodeComponent } from '../session-codes/session-procedural-codes/edit-confirm-procedure-code/edit-confirm-procedure-code.component';
import { SearchAddProcedureCodeComponent } from '../session-codes/session-procedural-codes/search-add-procedure-code/search-add-procedure-code.component';
import { PrintDialogComponent } from '../session-notes/dialogs/print-dialog/print-dialog.component';
import { ArchiveClaimConfirmationComponent } from './archive-claim-confirmation/archive-claim-confirmation.component';
import { EditBillingRenderingProviderComponent } from './edit-billing-rendering-provider/edit-billing-rendering-provider.component';
import { EditPriorAuthComponent } from './edit-prior-auth/edit-prior-auth.component';
import { EditSupervisingProviderComponent } from './edit-supervising-provider/edit-supervising-provider.component';
import { SelectReferringProviderComponent } from './select-referring-provider/select-referring-provider.component';

@Component({
  selector: 'app-session-billing',
  templateUrl: './session-billing.component.html',
  styleUrls: ['./session-billing.component.css'],
})
export class SessionBillingComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() parentClass: string;
  @Input() therapySession: ITherapySession;
  @Input() isBPSAEnforced: boolean;

  @Input() billingStatus: string;
  @Input() externalClaimsErrors: any;
  @Input() claimSubmittedToPayer: boolean;

  @Input() hasBillingViewAccess: boolean;
  @Input() hasBillingEditAccess: boolean;

  @Input() hasClaimsViewAccess: boolean;
  @Input() hasClaimsEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();

  alerts: string[] = [];

  processing = false;
  epsdtConditionTypes = EPSDTConditionInticatorType;
  usStateList = USStates;
  autoAccidentQualifierList = AutoAccidentQualifier;
  resubmissionCodesList = ClaimResubmissionCodesList;
  placeOfService = PlaceOfServiceCodes;
  healthInsuranceTypeList = HealthInsuranceTypes;
  claimFilingIndicatorList = ClaimFilingIndicators;
  initialTherapySession: ITherapySession;

  diagnosisColumn = ['diagnosisIndex', 'diagnosisCode', 'diagnosisDescription'];
  chargesColumn = [
    'cptIndex',
    'cptCode',
    'm1',
    'm2',
    'm3',
    'm4',
    'diagPointer',
    'amount',
    'unit',
    'charges',
  ];
  indexMap = new Map()
    .set(0, 'A')
    .set(1, 'B')
    .set(2, 'C')
    .set(3, 'D')
    .set(4, 'E')
    .set(5, 'F')
    .set(6, 'G')
    .set(7, 'H')
    .set(8, 'I')
    .set(9, 'J')
    .set(10, 'K')
    .set(11, 'L');

  colors = Colors;
  currentTime = new Date();

  public sessionBillingForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public therapysessionApiService: TherapySessionService,
    public claimsApiService: ClaimsAPIService,
    public toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.buildBillingForm();
    this.pushCharges();
    this.pushExistingDiagnosis();

    if (
      (this.parentClass === 'Session' &&
        this.therapySession.status === 'Active' &&
        this.hasBillingEditAccess) ||
      (this.parentClass === 'Claims' &&
        this.hasClaimsEditAccess &&
        this.billingStatus != 'Archived')
    ) {
      this.chargesColumn.push('actions');
      this.chargesColumn.splice(1, 0, 'moveArrows');
      this.diagnosisColumn.push('actions');
      this.diagnosisColumn.splice(1, 0, 'moveArrows');
    }

    this.initialTherapySession = Object.assign({}, this.therapySession);
    this.validateClaimsForm();

    // Determine whether to disable the form component or not
    if (
      (this.parentClass === 'Session' &&
        (this.therapySession.status != 'Active' ||
          !this.hasBillingEditAccess)) ||
      (this.parentClass === 'Claims' && !this.hasClaimsEditAccess) ||
      this.billingStatus === 'Archived'
    ) {
      this.sessionBillingForm.disable();
    }

    // check if this is re-submission
    if (this.claimSubmittedToPayer) {
      this.sessionBillingForm.controls['resubmissionCode'].addValidators(
        Validators.required
      );
      this.sessionBillingForm.controls[
        'resubmissionCode'
      ].updateValueAndValidity();
    }

    // Insurance form control validators
    if (this.therapySession.billingType) {
      this.billingTypeChanged(
        { value: this.therapySession.billingType },
        false
      );
    }

    if (this.therapySession.anotherHealthPlan) {
      this.secondaryInsuranceChaged({
        value: this.therapySession.anotherHealthPlan,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.hasClaimsEditAccess) {
      if (
        (this.parentClass === 'Session' &&
          (this.therapySession.status != 'Active' ||
            !this.hasBillingEditAccess)) ||
        (this.parentClass === 'Claims' && !this.hasClaimsEditAccess) ||
        this.billingStatus === 'Archived'
      ) {
        if (this.sessionBillingForm) {
          this.sessionBillingForm.disable();
          this.diagnosisColumn = [
            'diagnosisIndex',
            'diagnosisCode',
            'diagnosisDescription',
          ];
          this.chargesColumn = [
            'cptIndex',
            'cptCode',
            'm1',
            'm2',
            'm3',
            'm4',
            'diagPointer',
            'amount',
            'unit',
            'charges',
          ];
        }
      } else {
        if (this.sessionBillingForm) {
          this.sessionBillingForm.enable();
          this.diagnosisColumn = [
            'moveArrows',
            'diagnosisIndex',
            'diagnosisCode',
            'diagnosisDescription',
            'actions',
          ];
          this.chargesColumn = [
            'moveArrows',
            'cptIndex',
            'cptCode',
            'm1',
            'm2',
            'm3',
            'm4',
            'diagPointer',
            'amount',
            'unit',
            'charges',
            'actions',
          ];
        }
      }
    }
  }

  private buildBillingForm() {
    this.sessionBillingForm = this.formBuilder.group({
      billingType: new FormControl(
        this.therapySession.billingType,

        Validators.required
      ),
      primaryInsuranceDetails: new FormControl(
        this.therapySession.primaryInsuranceDetails
      ),
      primaryClaimFilingIndicator: new FormControl(
        this.therapySession.primaryClaimFilingIndicator
      ),
      primaryHealthInsuranceType: new FormControl(
        this.therapySession.primaryHealthInsuranceType
      ),

      anotherHealthPlan: new FormControl(this.therapySession.anotherHealthPlan),
      secondaryInsuranceDetails: new FormControl(
        this.therapySession.secondaryInsuranceDetails
      ),
      secondaryClaimFilingIndicator: new FormControl(
        this.therapySession.secondaryClaimFilingIndicator
      ),
      secondaryHealthInsuranceType: new FormControl(
        this.therapySession.secondaryHealthInsuranceType
      ),

      epsdtType: new FormControl(this.therapySession.epsdtType),
      epsdtFamilyPlan: new FormControl(this.therapySession.epsdtFamilyPlan),
      emgType: new FormControl(this.therapySession.emgType),
      acceptAssignment: new FormControl(this.therapySession.acceptAssignment),
      conditionRTEmployment: new FormControl(
        this.therapySession.conditionRTEmployment
      ),
      conditionRTAutoAccident: new FormControl(
        this.therapySession.conditionRTAutoAccident
      ),
      conditionRTAutoState: new FormControl(
        this.therapySession.conditionRTAutoState
      ),

      conditionRTAutoDate: new FormControl(
        this.therapySession.conditionRTAutoDate
      ),

      conditionRTAutoQualifier: new FormControl(
        this.therapySession.conditionRTAutoQualifier
      ),

      conditionRTOtherAccident: new FormControl(
        this.therapySession.conditionRTOtherAccident
      ),
      icdIndicator: new FormControl(0),
      charges: this.formBuilder.array([]),
      diagnosisCodes: this.formBuilder.array([]),
      billingProviderPOSCode: new FormControl(
        this.therapySession.billingProviderPOSCode
      ),

      billingProviderGroupNPI: new FormControl(
        this.therapySession.billingProviderGroupNPI
      ),
      billingProviderTaxonomyCode: new FormControl(
        this.therapySession.billingProviderTaxonomyCode
      ),
      billingProviderBillingOption: new FormControl(
        this.therapySession.billingProviderBillingOption
      ),
      billingProviderTaxId: new FormControl(
        this.therapySession.billingProviderTaxId
      ),
      billingProviderSSN: new FormControl(
        this.therapySession.billingProviderSSN
      ),

      physicianTaxonomyCode: new FormControl(
        this.therapySession.physicianTaxonomyCode
      ),
      physicianNPI: new FormControl(this.therapySession.physicianNPI),
      physicianBillingOption: new FormControl(
        this.therapySession.physicianBillingOption
      ),
      physicianTaxId: new FormControl(this.therapySession.physicianTaxId),
      physicianSSN: new FormControl(this.therapySession.physicianSSN),
      supervisingRenderingProvider: new FormControl(
        this.therapySession.supervisingRenderingProvider
      ),
      supervisingRPId: new FormControl(this.therapySession?.supervisingRPId),
      supervisingRPFirstName: new FormControl(
        this.therapySession.supervisingRPFirstName
      ),
      supervisingRPMiddleName: new FormControl(
        this.therapySession.supervisingRPMiddleName
      ),
      supervisingRPLastName: new FormControl(
        this.therapySession.supervisingRPLastName
      ),
      supervisingRPEmail: new FormControl(
        this.therapySession.supervisingRPEmail
      ),
      supervisingRPPhone: new FormControl(
        this.therapySession.supervisingRPPhone
      ),
      supervisingRPTaxonomyCode: new FormControl(
        this.therapySession.supervisingRPTaxonomyCode
      ),
      supervisingRPNPI: new FormControl(this.therapySession.supervisingRPNPI),
      supervisingRPBillingOption: new FormControl(
        this.therapySession.supervisingRPBillingOption
      ),
      supervisingRPTaxId: new FormControl(
        this.therapySession.supervisingRPTaxId
      ),
      supervisingRPSSN: new FormControl(this.therapySession.supervisingRPSSN),
      priorAuthCode: new FormControl(this.therapySession.priorAuthCode),
      priorAuthDetails: new FormControl(this.therapySession.priorAuthDetails),

      referringProvider: new FormControl(this.therapySession.referringProvider),

      referringProviderId: new FormControl(
        this.therapySession.referringProviderId
      ),

      referringProviderFirstName: new FormControl(
        this.therapySession.referringProviderFirstName
      ),
      referringProviderMiddleName: new FormControl(
        this.therapySession.referringProviderMiddleName
      ),
      referringProviderLastName: new FormControl(
        this.therapySession.referringProviderLastName
      ),
      referringProviderNPI: new FormControl(
        this.therapySession.referringProviderNPI
      ),
      referringProviderTaxonomy: new FormControl(
        this.therapySession.referringProviderTaxonomy
      ),

      resubmissionCode: new FormControl(''),
    });
  }

  supervisingProviderChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('supervisingRPId')
        .addValidators(Validators.required);
      this.sessionBillingForm.get('supervisingRPId').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('supervisingRPId').setValue(null);
      this.sessionBillingForm.get('supervisingRPFirstName').setValue(null);
      this.sessionBillingForm.get('supervisingRPMiddleName').setValue(null);
      this.sessionBillingForm.get('supervisingRPLastName').setValue(null);
      this.sessionBillingForm.get('supervisingRPEmail').setValue(null);
      this.sessionBillingForm.get('supervisingRPPhone').setValue(null);
      this.sessionBillingForm.get('supervisingRPTaxonomyCode').setValue(null);
      this.sessionBillingForm.get('supervisingRPNPI').setValue(null);
      this.sessionBillingForm.get('supervisingRPBillingOption').setValue(null);
      this.sessionBillingForm.get('supervisingRPTaxId').setValue(null);
      this.sessionBillingForm.get('supervisingRPSSN').setValue(null);
      this.sessionBillingForm.get('supervisingRPId').clearValidators();
      this.sessionBillingForm.get('supervisingRPId').updateValueAndValidity();

      this.therapySession = Object.assign(
        this.therapySession,
        this.sessionBillingForm.value
      );

      // Update the billing information
      this.updateBilling(false);
    }
  }

  referringProviderChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('referringProviderId')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('referringProviderId')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('referringProviderId').setValue(null);
      this.sessionBillingForm.get('referringProviderId').clearValidators();
      this.sessionBillingForm
        .get('referringProviderId')
        .updateValueAndValidity();

      this.sessionBillingForm.get('referringProviderFirstName').setValue(null);
      this.sessionBillingForm.get('referringProviderMiddleName').setValue(null);
      this.sessionBillingForm.get('referringProviderLastName').setValue(null);
      this.sessionBillingForm.get('referringProviderNPI').setValue(null);
      this.sessionBillingForm.get('referringProviderTaxonomy').setValue(null);

      this.therapySession = Object.assign(
        this.therapySession,
        this.sessionBillingForm.value
      );

      // Update the billing information
      this.updateBilling(false);
    }
  }

  searchInsurance(type) {
    let dialogRef = this.dialog.open(IntakeSearchAddInsuranceComponent, {
      data: {
        patient: this.patient,
        therapySession: null,
        parentClass: 'SessionBilling',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response?.type === 'success') {
        if (type === 'Primary') {
          this.sessionBillingForm.controls['primaryInsuranceDetails'].setValue(
            response.insurance
          );
          this.sessionBillingForm.controls[
            'primaryClaimFilingIndicator'
          ].setValue(response.insurance.claimFilingIndicator);
          this.sessionBillingForm.controls[
            'primaryHealthInsuranceType'
          ].setValue(response.insurance.healthInsuranceType);
        } else {
          this.sessionBillingForm.controls[
            'secondaryInsuranceDetails'
          ].setValue(response.insurance);

          this.sessionBillingForm.controls[
            'secondaryClaimFilingIndicator'
          ].setValue(response.insurance.claimFilingIndicator);
          this.sessionBillingForm.controls[
            'secondaryHealthInsuranceType'
          ].setValue(response.insurance.healthInsuranceType);
        }

        // Update the billing information
        this.updateBilling(false);
      }
    });
  }

  secondaryInsuranceChaged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('secondaryInsuranceDetails')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();

      if (this.parentClass === 'Claims') {
        this.sessionBillingForm
          .get('secondaryClaimFilingIndicator')
          .addValidators(Validators.required);
        this.sessionBillingForm
          .get('secondaryClaimFilingIndicator')
          .updateValueAndValidity();
        this.sessionBillingForm
          .get('secondaryHealthInsuranceType')
          .addValidators(Validators.required);
        this.sessionBillingForm
          .get('secondaryHealthInsuranceType')
          .updateValueAndValidity();
      }
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('secondaryInsuranceDetails').setValue(null);
      this.sessionBillingForm
        .get('secondaryInsuranceDetails')
        .clearValidators();
      this.sessionBillingForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();

      this.sessionBillingForm
        .get('secondaryClaimFilingIndicator')
        .setValue(null);
      this.sessionBillingForm
        .get('secondaryClaimFilingIndicator')
        .clearValidators();
      this.sessionBillingForm
        .get('secondaryClaimFilingIndicator')
        .updateValueAndValidity();

      this.sessionBillingForm
        .get('secondaryHealthInsuranceType')
        .setValue(null);
      this.sessionBillingForm
        .get('secondaryHealthInsuranceType')
        .clearValidators();
      this.sessionBillingForm
        .get('secondaryHealthInsuranceType')
        .updateValueAndValidity();

      // Update the billing information
      this.updateBilling(false);
    }
  }

  removeInsurance(type) {
    if (type === 'Primary') {
      this.sessionBillingForm.controls['primaryInsuranceDetails'].setValue(
        null
      );
      this.sessionBillingForm.controls['primaryClaimFilingIndicator'].setValue(
        null
      );
      this.sessionBillingForm.controls['primaryHealthInsuranceType'].setValue(
        null
      );
    } else {
      this.sessionBillingForm.controls['secondaryInsuranceDetails'].setValue(
        null
      );
      this.sessionBillingForm.controls[
        'secondaryClaimFilingIndicator'
      ].setValue(null);
      this.sessionBillingForm.controls['secondaryHealthInsuranceType'].setValue(
        null
      );
    }

    // Update the billing information
    this.updateBilling(false);
  }

  pushCharges() {
    const control = <FormArray>this.sessionBillingForm.get('charges');

    if (this.therapySession.charges && this.therapySession.charges.length > 0) {
      this.therapySession.charges.forEach((charge) => {
        control.push(
          new FormGroup({
            cptIndex: new FormControl(charge.cptIndex),
            id: new FormControl(charge.id),
            cptCode: new FormControl(charge.cptCode),
            description: new FormControl(charge.description),
            rate: new FormControl(charge.rate),
            defaultUnit: new FormControl(charge.defaultUnit),
            charges: new FormControl(charge.charges),
            duration: new FormControl(charge.duration),
            durationUnit: new FormControl(charge.durationUnit),
            modifier1: new FormControl(charge.modifier1),
            modifier2: new FormControl(charge.modifier2),
            modifier3: new FormControl(charge.modifier3),
            modifier4: new FormControl(charge.modifier4),
            diagnosisPointer: new FormControl(charge.diagnosisPointer),
          })
        );
      });
    }
  }

  pushExistingDiagnosis() {
    const control = <FormArray>this.sessionBillingForm.get('diagnosisCodes');

    if (
      this.therapySession.diagnosisCodes &&
      this.therapySession.diagnosisCodes.length > 0
    ) {
      this.therapySession.diagnosisCodes.forEach((diagnosis) => {
        control.push(
          new FormGroup({
            id: new FormControl(diagnosis.id),
            icdCode: new FormControl(diagnosis.icdCode),
            shortDesc: new FormControl(diagnosis.shortDesc),
            longDesc: new FormControl(diagnosis.longDesc),
            diagnosisIndex: new FormControl(diagnosis.diagnosisIndex),
            status: new FormControl(diagnosis.status),
          })
        );
      });
    }
  }

  addDiagnosis() {
    const dialogRef = this.dialog.open(ExistingDiagnosisComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        loggedInUserId: this.loggedInUserId,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        const control = <FormArray>(
          this.sessionBillingForm.get('diagnosisCodes')
        );
        let existingDiagnosis = Object.assign([], control.value);
        let recordExists = false;
        if (response.diagnosis) {
          existingDiagnosis.forEach((diagnosis) => {
            if (diagnosis.icdCode === response.diagnosis.icdCode) {
              recordExists = true;
            }
          });
        }

        if (!recordExists) {
          this.pushDiagnosis(response.diagnosis);
        }
      }
    });
  }

  pushDiagnosis(diagnosis) {
    const control = <FormArray>this.sessionBillingForm.get('diagnosisCodes');
    let index = control.value.length;

    control.push(
      new FormGroup({
        id: new FormControl(diagnosis.id),
        icdCode: new FormControl(diagnosis.icdCode),
        shortDesc: new FormControl(diagnosis.shortDesc),
        longDesc: new FormControl(diagnosis.longDesc),
        diagnosisIndex: new FormControl(this.indexMap.get(index)),
        status: new FormControl(diagnosis.status),
      })
    );

    this.therapySession = Object.assign(
      this.therapySession,
      this.sessionBillingForm.value
    );

    // Update the session object
    this.updateBilling(false);
  }

  moveDiagnosis(direction, index) {
    const control = <FormArray>this.sessionBillingForm.get('diagnosisCodes');
    let controlLength = control.length;

    if (controlLength == 1) {
      return;
    }

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      // Now change position
      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(previosIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(previosIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(previosIndex) as FormGroup;

      changeIndexControl1.controls['diagnosisIndex'].setValue(
        this.indexMap.get(index)
      );
      changeIndexControl2.controls['diagnosisIndex'].setValue(
        this.indexMap.get(previosIndex)
      );

      // Update the session object
      this.updateBilling(false);
    } else {
      // Downward
      let nextIndex = index + 1;
      if (index - 1 == controlLength || nextIndex > controlLength - 1) {
        return;
      }

      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(nextIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(nextIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(nextIndex) as FormGroup;

      changeIndexControl1.controls['diagnosisIndex'].setValue(
        this.indexMap.get(index)
      );
      changeIndexControl2.controls['diagnosisIndex'].setValue(
        this.indexMap.get(nextIndex)
      );

      // Update the session object
      this.updateBilling(false);
    }
  }

  removeDiagnosis(index) {
    const control = <FormArray>this.sessionBillingForm.get('diagnosisCodes');
    control.removeAt(index);

    // Resizing the index
    for (let i = index; i < control.length; i++) {
      let subFormGroup = control.at(i) as FormGroup;
      subFormGroup.controls['diagnosisIndex'].setValue(this.indexMap.get(i));
    }

    this.therapySession = Object.assign(
      this.therapySession,
      this.sessionBillingForm.value
    );

    // Update the session object
    this.updateBilling(false);
  }

  addCharge() {
    let dialogRef = this.dialog.open(SearchAddProcedureCodeComponent, {
      data: {
        parentClass: 'SessionBilling',
        patient: this.patient,
        primaryInsurance:
          this.sessionBillingForm.controls['primaryInsuranceDetails'].value,
        billingProviderId: this.therapySession.billingProviderRefId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '70vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.pushCharge(response.cptCode);
      }
    });
  }

  pushCharge(cptCode) {
    const control = <FormArray>this.sessionBillingForm.get('charges');
    let index = control.value.length;

    control.push(
      new FormGroup({
        cptIndex: new FormControl(index + 1),
        id: new FormControl(cptCode.id),
        cptCode: new FormControl(cptCode.cptCode),
        description: new FormControl(cptCode.description),
        rate: new FormControl(cptCode.rate),
        defaultUnit: new FormControl(cptCode.defaultUnit),
        charges: new FormControl(cptCode.rate * cptCode.defaultUnit),
        duration: new FormControl(cptCode.duration),
        durationUnit: new FormControl(cptCode.durationUnit),
        modifier1: new FormControl(cptCode.modifier1),
        modifier2: new FormControl(cptCode.modifier2),
        modifier3: new FormControl(cptCode.modifier3),
        modifier4: new FormControl(cptCode.modifier4),
        diagnosisPointer: new FormControl(cptCode.diagnosisPointer),
      })
    );

    // Update the session object
    this.updateBilling(false);
  }

  editCharge(row, index) {
    let dialogRef = this.dialog.open(EditConfirmProcedureCodeComponent, {
      data: {
        cptCode: row,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let cptCode = response.cptCode;
        // Get form control at index
        const control = <FormArray>this.sessionBillingForm.get('charges');
        // Get Form Group and update the data
        let subFormGroup = control.at(index) as FormGroup;

        subFormGroup.controls['description'].setValue(cptCode.description);
        subFormGroup.controls['rate'].setValue(cptCode.rate);
        subFormGroup.controls['defaultUnit'].setValue(cptCode.defaultUnit);
        subFormGroup.controls['duration'].setValue(cptCode.duration);
        subFormGroup.controls['durationUnit'].setValue(cptCode.durationUnit);
        subFormGroup.controls['charges'].setValue(
          cptCode.rate * cptCode.defaultUnit
        ),
          subFormGroup.controls['modifier1'].setValue(cptCode.modifier1);
        subFormGroup.controls['modifier2'].setValue(cptCode.modifier2);
        subFormGroup.controls['modifier3'].setValue(cptCode.modifier3);
        subFormGroup.controls['modifier4'].setValue(cptCode.modifier4);
        subFormGroup.controls['diagnosisPointer'].setValue(
          cptCode.diagnosisPointer
        );

        // Update the session object
        this.updateBilling(false);
      }
    });
  }

  removeCharge(index) {
    const control = <FormArray>this.sessionBillingForm.get('charges');
    control.removeAt(index);

    // Resizing the index
    for (let i = index; i < control.length; i++) {
      let subFormGroup = control.at(i) as FormGroup;
      subFormGroup.controls['cptIndex'].setValue(i + 1);
    }

    // Update the session object
    this.updateBilling(false);
  }

  moveCharge(direction, index) {
    const control = <FormArray>this.sessionBillingForm.get('charges');
    let controlLength = control.length;

    if (controlLength == 1) {
      return;
    }

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      // Now change position
      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(previosIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(previosIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(previosIndex) as FormGroup;

      changeIndexControl1.controls['cptIndex'].setValue(index + 1);
      changeIndexControl2.controls['cptIndex'].setValue(previosIndex + 1);

      // Update the session object
      this.updateBilling(false);
    } else {
      // Downward
      let nextIndex = index + 1;
      if (index - 1 == controlLength || nextIndex > controlLength - 1) {
        return;
      }

      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(nextIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(nextIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(nextIndex) as FormGroup;

      changeIndexControl1.controls['cptIndex'].setValue(index + 1);
      changeIndexControl2.controls['cptIndex'].setValue(nextIndex + 1);

      // Update the session object
      this.updateBilling(false);
    }
  }

  billingTypeChanged(event, updateRequired: boolean) {
    if (event.value === 'Insurance') {
      this.sessionBillingForm
        .get('primaryInsuranceDetails')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('primaryInsuranceDetails')
        .updateValueAndValidity();

      if (this.parentClass === 'Claims') {
        this.sessionBillingForm
          .get('primaryClaimFilingIndicator')
          .addValidators(Validators.required);
        this.sessionBillingForm
          .get('primaryClaimFilingIndicator')
          .updateValueAndValidity();
        this.sessionBillingForm
          .get('primaryHealthInsuranceType')
          .addValidators(Validators.required);
        this.sessionBillingForm
          .get('primaryHealthInsuranceType')
          .updateValueAndValidity();
      }

      // Update the session object
      if (updateRequired) {
        this.updateBilling(false);
      }
    } else if (event.value === 'Self-Pay') {
      this.sessionBillingForm.get('primaryInsuranceDetails').clearValidators();
      this.sessionBillingForm
        .get('primaryInsuranceDetails')
        .updateValueAndValidity();

      this.sessionBillingForm
        .get('primaryClaimFilingIndicator')
        .clearValidators();
      this.sessionBillingForm
        .get('primaryClaimFilingIndicator')
        .updateValueAndValidity();
      this.sessionBillingForm
        .get('primaryHealthInsuranceType')
        .clearValidators();
      this.sessionBillingForm
        .get('primaryHealthInsuranceType')
        .updateValueAndValidity();

      // Update the session object
      if (updateRequired) {
        this.updateBilling(false);
      }
    }
  }

  conditionRTEmploymentChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    } else {
      this.sessionBillingForm.get('conditionRTAutoDate').setValue(null);
      this.sessionBillingForm.get('conditionRTAutoDate').clearValidators();
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    }
    // Update the details on the member
    this.updateBilling(false);
  }

  autoAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('conditionRTAutoState')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('conditionRTAutoState')
        .updateValueAndValidity();

      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();

      this.sessionBillingForm
        .get('conditionRTAutoQualifier')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('conditionRTAutoQualifier')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('conditionRTAutoState').setValue(null);
      this.sessionBillingForm.get('conditionRTAutoState').clearValidators();
      this.sessionBillingForm
        .get('conditionRTAutoState')
        .updateValueAndValidity();

      this.sessionBillingForm.get('conditionRTAutoDate').setValue(null);
      this.sessionBillingForm.get('conditionRTAutoDate').clearValidators();
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();

      this.sessionBillingForm.get('conditionRTAutoQualifier').setValue('');
      this.sessionBillingForm.get('conditionRTAutoQualifier').clearValidators();
      this.sessionBillingForm
        .get('conditionRTAutoQualifier')
        .updateValueAndValidity();
    }

    // Update the details on the member
    this.updateBilling(false);
  }

  otherAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('conditionRTAutoDate').setValue(null);
      this.sessionBillingForm.get('conditionRTAutoDate').clearValidators();
      this.sessionBillingForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    }

    // Update the details on the member
    this.updateBilling(false);
  }

  updateBilling(showToast: boolean) {
    this.therapySession = Object.assign(
      this.therapySession,
      this.sessionBillingForm.value
    );

    let isClaimValid = this.validateClaimsForm();

    this.processing = true;

    if (this.parentClass === 'Session') {
      this.therapysessionApiService
        .updateBillingInformation(this.therapySession.id, this.therapySession)
        .subscribe({
          next: (response) => {
            // Show toast if show toast
            if (showToast) {
              this.toastMessageService.displaySuccessMessage(
                'Successfully updated the billing information.'
              );
            }

            this.processing = false;
            this.updatedTherapySession.emit({
              therapySession: response.data,
              type: 'reload',
            });
            this.initialTherapySession = Object.assign({}, response.data);

            // If the claim is not valid return here
            if (!isClaimValid && showToast) {
              this.toastMessageService.displayWarnMessage(
                'If applicable, please fix the following issues'
              );
              window.scrollTo(0, 0);
              return;
            }
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to updated the billing information.'
            );
          },
        });
    } else {
      // If the parent class is from CLaims
      this.therapysessionApiService
        .updateClaimsBillingInformation(
          this.therapySession.id,
          this.therapySession
        )
        .subscribe({
          next: (response) => {
            // Show toast if show toast
            if (showToast) {
              this.toastMessageService.displaySuccessMessage(
                'Successfully updated the claim billing information.'
              );
            }
            this.processing = false;

            this.updatedTherapySession.emit({
              therapySession: response.data,
              type: 'reload',
            });
            this.initialTherapySession = Object.assign({}, response.data);

            // If the claim is not valid return here
            if (!isClaimValid && showToast) {
              this.toastMessageService.displayWarnMessage(
                'If applicable, please fix the following issues'
              );
              window.scrollTo(0, 0);
              return;
            }
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to updated the billing information.'
            );
          },
        });
    }
  }

  archiveClaim() {
    let dialogRef = this.dialog.open(ArchiveClaimConfirmationComponent, {
      data: { therapySession: this.therapySession },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.type === 'success') {
        this.updatedTherapySession.emit({
          type: 'reload_claim',
        });
      }
    });
  }

  unArchiveClaim() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'UnArchive Claim',
        msg: 'Are you sure that you want to unarchive claim?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;

        this.claimsApiService
          .unArchievePrimaryClaim(
            this.therapySession.id,
            this.therapySession.patientId
          )
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Successfully unarchived the claim'
              );
              this.processing = false;
              this.updatedTherapySession.emit({
                type: 'reload_claim',
              });
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to unarchive the claim'
              );
            },
          });
      }
    });
  }

  markOnHold() {
    this.processing = true;

    this.claimsApiService
      .markPrimaryClaimOnHold(
        this.therapySession.id,
        this.therapySession.patientId
      )
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully marked the claim as "On Hold"'
          );
          this.processing = false;
          this.updatedTherapySession.emit({
            type: 'reload_claim',
          });
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to mark the claim as "On Hold"'
          );
        },
      });
  }

  getTotal() {
    let total = 0;
    const control = <FormArray>this.sessionBillingForm.get('charges');
    for (let i = 0; i < control.length; i++) {
      let tempFormGroup = Object.assign({}, control.at(i)) as FormGroup;
      total = total + tempFormGroup.controls['charges'].value;
    }
    return total;
  }

  editProvider(type) {
    let dialogRef = this.dialog.open(EditBillingRenderingProviderComponent, {
      data: {
        type: type,
        therapySession: this.therapySession,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let updatedProviderInfo = response.updatedProvder;
        if (type === 'Billing') {
          this.sessionBillingForm.controls['billingProviderGroupNPI'].setValue(
            updatedProviderInfo.npi
          );
          this.sessionBillingForm.controls[
            'billingProviderTaxonomyCode'
          ].setValue(updatedProviderInfo.taxonomy);
          this.sessionBillingForm.controls[
            'billingProviderBillingOption'
          ].setValue(updatedProviderInfo.billingOption);
          this.sessionBillingForm.controls['billingProviderTaxId'].setValue(
            updatedProviderInfo.taxId
          );
          this.sessionBillingForm.controls['billingProviderSSN'].setValue(
            updatedProviderInfo.ssn
          );
        } else {
          this.sessionBillingForm.controls['physicianNPI'].setValue(
            updatedProviderInfo.npi
          );
          this.sessionBillingForm.controls['physicianTaxonomyCode'].setValue(
            updatedProviderInfo.taxonomy
          );
          this.sessionBillingForm.controls['physicianBillingOption'].setValue(
            updatedProviderInfo.billingOption
          );
          this.sessionBillingForm.controls['physicianTaxId'].setValue(
            updatedProviderInfo.taxId
          );
          this.sessionBillingForm.controls['physicianSSN'].setValue(
            updatedProviderInfo.ssn
          );
        }

        this.therapySession = Object.assign(
          this.therapySession,
          this.sessionBillingForm.value
        );

        // Update the session object
        this.updateBilling(false);
      }
    });
  }

  restore() {
    this.therapySession = Object.assign({}, this.initialTherapySession);

    // Restore form components
    this.sessionBillingForm.controls['billingType'].setValue(
      this.therapySession.billingType
    );
    this.sessionBillingForm.controls['primaryInsuranceDetails'].setValue(
      this.therapySession.primaryInsuranceDetails
    );
    this.sessionBillingForm.controls['primaryClaimFilingIndicator'].setValue(
      this.therapySession.primaryClaimFilingIndicator
    );
    this.sessionBillingForm.controls['primaryHealthInsuranceType'].setValue(
      this.therapySession.primaryHealthInsuranceType
    );

    this.sessionBillingForm.controls['anotherHealthPlan'].setValue(
      this.therapySession.anotherHealthPlan
    );
    this.sessionBillingForm.controls['secondaryInsuranceDetails'].setValue(
      this.therapySession.secondaryInsuranceDetails
    );
    this.sessionBillingForm.controls['secondaryClaimFilingIndicator'].setValue(
      this.therapySession.secondaryClaimFilingIndicator
    );
    this.sessionBillingForm.controls['secondaryHealthInsuranceType'].setValue(
      this.therapySession.secondaryHealthInsuranceType
    );

    this.secondaryInsuranceChaged({
      value: this.therapySession.anotherHealthPlan,
    });

    this.sessionBillingForm.controls['epsdtType'].setValue(
      this.therapySession.epsdtType
    );
    this.sessionBillingForm.controls['epsdtFamilyPlan'].setValue(
      this.therapySession.epsdtFamilyPlan
    );
    this.sessionBillingForm.controls['emgType'].setValue(
      this.therapySession.emgType
    );
    this.sessionBillingForm.controls['acceptAssignment'].setValue(
      this.therapySession.acceptAssignment
    );
    this.sessionBillingForm.controls['conditionRTEmployment'].setValue(
      this.therapySession.conditionRTEmployment
    );
    this.sessionBillingForm.controls['conditionRTAutoAccident'].setValue(
      this.therapySession.conditionRTAutoAccident
    );
    this.sessionBillingForm.controls['conditionRTAutoState'].setValue(
      this.therapySession.conditionRTAutoState
    );
    this.sessionBillingForm.controls['conditionRTAutoDate'].setValue(
      this.therapySession.conditionRTAutoDate
    );
    this.sessionBillingForm.controls['conditionRTAutoQualifier'].setValue(
      this.therapySession.conditionRTAutoQualifier
    );
    this.sessionBillingForm.controls['conditionRTOtherAccident'].setValue(
      this.therapySession.conditionRTOtherAccident
    );
    this.sessionBillingForm.controls['icdIndicator'].setValue(
      this.therapySession.icdIndicator
    );
    this.sessionBillingForm.controls['billingProviderPOSCode'].setValue(
      this.therapySession.billingProviderPOSCode
    );

    let control1 = <FormArray>this.sessionBillingForm.get('charges');
    control1.clear();

    let control2 = <FormArray>this.sessionBillingForm.get('diagnosisCodes');
    control2.clear();

    this.pushCharges();
    this.pushExistingDiagnosis();

    this.billingTypeChanged({ value: this.therapySession.billingType }, true);
    this.autoAccidentTypeChanged({
      value: this.therapySession.conditionRTAutoAccident,
    });

    this.sessionBillingForm.controls['supervisingRenderingProvider'].setValue(
      this.therapySession.supervisingRenderingProvider
    );
    this.sessionBillingForm.controls['supervisingRPId'].setValue(
      this.therapySession?.supervisingRPId
    );
    this.sessionBillingForm.controls['supervisingRPFirstName'].setValue(
      this.therapySession.supervisingRPFirstName
    );
    this.sessionBillingForm.controls['supervisingRPMiddleName'].setValue(
      this.therapySession.supervisingRPMiddleName
    );
    this.sessionBillingForm.controls['supervisingRPLastName'].setValue(
      this.therapySession.supervisingRPLastName
    );

    this.sessionBillingForm.controls['supervisingRPEmail'].setValue(
      this.therapySession.supervisingRPEmail
    );
    this.sessionBillingForm.controls['supervisingRPPhone'].setValue(
      this.therapySession.supervisingRPPhone
    );
    this.sessionBillingForm.controls['supervisingRPTaxonomyCode'].setValue(
      this.therapySession.supervisingRPTaxonomyCode
    );
    this.sessionBillingForm.controls['supervisingRPNPI'].setValue(
      this.therapySession.supervisingRPNPI
    );
    this.sessionBillingForm.controls['supervisingRPBillingOption'].setValue(
      this.therapySession.supervisingRPBillingOption
    );
    this.sessionBillingForm.controls['supervisingRPTaxId'].setValue(
      this.therapySession.supervisingRPTaxId
    );
    this.sessionBillingForm.controls['supervisingRPSSN'].setValue(
      this.therapySession.supervisingRPSSN
    );

    this.supervisingProviderChanged({
      value: this.therapySession.supervisingRenderingProvider,
    });

    this.sessionBillingForm.controls['priorAuthCode'].setValue(
      this.therapySession.priorAuthCode
    );
    this.sessionBillingForm.controls['priorAuthDetails'].setValue(
      this.therapySession.priorAuthDetails
    );
    this.priorAuthChanged({ value: this.therapySession.priorAuthCode });

    this.sessionBillingForm.controls['referringProviderId'].setValue(
      this.therapySession.referringProviderId
    );
    this.sessionBillingForm.controls['referringProvider'].setValue(
      this.therapySession.referringProvider
    );
    this.sessionBillingForm.controls['referringProviderFirstName'].setValue(
      this.therapySession.referringProviderFirstName
    );
    this.sessionBillingForm.controls['referringProviderMiddleName'].setValue(
      this.therapySession.referringProviderMiddleName
    );
    this.sessionBillingForm.controls['referringProviderLastName'].setValue(
      this.therapySession.referringProviderLastName
    );
    this.sessionBillingForm.controls['referringProviderNPI'].setValue(
      this.therapySession.referringProviderNPI
    );
    this.sessionBillingForm.controls['referringProviderTaxonomy'].setValue(
      this.therapySession.referringProviderTaxonomy
    );

    this.referringProviderChanged({
      value: this.therapySession.referringProvider,
    });
  }

  editSupervisingProvider() {
    let dialogRef = this.dialog.open(EditSupervisingProviderComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        parentClass: this.parentClass,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let supervisorDetails = response.details;

        this.sessionBillingForm.controls['supervisingRPId'].setValue(
          supervisorDetails.supervisingRPId
        );
        this.sessionBillingForm.controls['supervisingRPFirstName'].setValue(
          supervisorDetails.supervisingRPFirstName
        );
        this.sessionBillingForm.controls['supervisingRPMiddleName'].setValue(
          supervisorDetails.supervisingRPMiddleName
        );
        this.sessionBillingForm.controls['supervisingRPLastName'].setValue(
          supervisorDetails.supervisingRPLastName
        );
        this.sessionBillingForm.controls['supervisingRPEmail'].setValue(
          supervisorDetails.supervisingRPEmail
        );
        this.sessionBillingForm.controls['supervisingRPPhone'].setValue(
          supervisorDetails.supervisingRPPhone
        );
        this.sessionBillingForm.controls['supervisingRPTaxonomyCode'].setValue(
          supervisorDetails.supervisingRPTaxonomyCode
        );
        this.sessionBillingForm.controls['supervisingRPNPI'].setValue(
          supervisorDetails.supervisingRPNPI
        );
        this.sessionBillingForm.controls['supervisingRPBillingOption'].setValue(
          supervisorDetails.supervisingRPBillingOption
        );
        this.sessionBillingForm.controls['supervisingRPTaxId'].setValue(
          supervisorDetails.supervisingRPTaxId
        );
        this.sessionBillingForm.controls['supervisingRPSSN'].setValue(
          supervisorDetails.supervisingRPSSN
        );

        this.therapySession = Object.assign(
          this.therapySession,
          this.sessionBillingForm.value
        );

        // Update the billing information
        this.updateBilling(false);
      }
    });
  }

  sendClaims() {
    // Verify if all the required details are there
    // First Check for Billing Provider details
    this.therapySession = Object.assign(
      this.therapySession,
      this.sessionBillingForm.value
    );

    let isClaimValid = this.validateClaimsForm();

    // If the claim is not valid return here
    if (!isClaimValid) {
      this.toastMessageService.displayInfoMessage(
        'If applicable, please fix the following issues'
      );
      window.scrollTo(0, 0);
      return;
    }

    this.processing = true;

    this.therapysessionApiService
      .submitPrimaryClaimsForProcessing(
        this.therapySession.id,
        this.therapySession
      )
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted the claim for processing'
          );
          this.processing = false;
          this.updatedTherapySession.emit({
            therapySession: response.data,
            type: 'deselect',
          });
          this.initialTherapySession = Object.assign({}, response.data);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to submit the claim. Please make sure all the required details are provided.'
          );
        },
      });
  }

  validateClaimsForm() {
    this.alerts = [];

    // Validate All Components for the Claims/Billing Form

    if (this.parentClass === 'Claims') {
      // 01 ***************************************
      // First Validate Billing Provider Information
      // Check for Billing Provider NPI and Tax/SSN
      if (!this.therapySession.billingProviderGroupNPI) {
        this.alerts.push('Billing Provider GROUP NPI is invalid');
      }

      // Check for Billing Provider NPI and Tax/SSN
      if (this.therapySession.billingProviderBillingOption === 'SSN') {
        if (!this.therapySession.billingProviderSSN) {
          this.alerts.push('Billing Provider SSN is invalid');
        }
      } else {
        if (!this.therapySession.billingProviderTaxId) {
          this.alerts.push('Billing Provider Tax ID is invalid');
        }
      }

      // 02 ***************************************
      // Verify Rendering or Supervising RP Information
      if (this.therapySession.supervisingRenderingProvider === 'Yes') {
        if (!this.therapySession.supervisingRPNPI) {
          this.alerts.push('Supervising Rendering Provider NPI is invalid');
        }
        // Check for Billing Provider NPI and Tax/SSN
        if (this.therapySession.supervisingRPBillingOption === 'SSN') {
          if (!this.therapySession.supervisingRPSSN) {
            this.alerts.push('Supervising Rendering Provider SSN is invalid');
          }
        } else {
          if (!this.therapySession.supervisingRPTaxId) {
            this.alerts.push(
              'Supervising Rendering Provider Tax ID is invalid'
            );
          }
        }
      } else {
        if (!this.therapySession.physicianNPI) {
          this.alerts.push('Rendering Provider NPI is invalid');
        }
        // Check for Billing Provider NPI and Tax/SSN
        if (this.therapySession.physicianBillingOption === 'SSN') {
          if (!this.therapySession.physicianNPI) {
            this.alerts.push('Rendering Provider SSN is invalid');
          }
        } else {
          if (!this.therapySession.physicianTaxId) {
            this.alerts.push('Rendering Provider Tax ID is invalid');
          }
        }
      }
    }

    // 03 ***************************************
    // If Billing type is Insurance, check if primary insurance exists
    if (this.therapySession.billingType === 'Insurance') {
      if (!this.therapySession.primaryInsuranceDetails) {
        this.alerts.push('Primary Insurance is not selected');
      }

      // Check health care indicator types
      if (this.parentClass === 'Claims') {
        if (!this.therapySession.primaryClaimFilingIndicator) {
          this.alerts.push(
            'Primary Insurance Claim Filing Indicator is not selected'
          );
        }
        if (!this.therapySession.primaryHealthInsuranceType) {
          this.alerts.push('Primary Insurance Insurance Type is not selected');
        }
      }

      if (this.therapySession.anotherHealthPlan == null) {
        this.alerts.push(
          'Please select if there is another health plan or not'
        );
      }

      if (this.therapySession.anotherHealthPlan === 'Yes') {
        if (!this.therapySession.secondaryInsuranceDetails) {
          this.alerts.push('Secondary Insurance is not selected');
        }

        // Check health care indicator types
        if (this.parentClass === 'Claims') {
          if (!this.therapySession.secondaryClaimFilingIndicator) {
            this.alerts.push(
              'Secondary Insurance Claim Filing Indicator is not selected'
            );
          }
          if (!this.therapySession.secondaryHealthInsuranceType) {
            this.alerts.push(
              'Secondary Insurance Insurance Type is not selected'
            );
          }
        }
      }
    }

    // 04 ***************************************
    // Condition Related to Auto Accident
    if (this.therapySession.conditionRTAutoAccident === 'Yes') {
      if (!this.therapySession.conditionRTAutoState) {
        this.alerts.push('Auto Accident State needs to be selected');
      }
      if (!this.therapySession.conditionRTAutoDate) {
        this.alerts.push('Auto Accident Date is required');
      }
    }

    // Condition Related to Other Accident
    if (this.therapySession.conditionRTOtherAccident === 'Yes') {
      if (!this.therapySession.conditionRTAutoDate) {
        this.alerts.push('Accident Date is required');
      }
    }

    // 05 ***************************************
    // Check if atleast 1 diagnosis exists
    if (
      !this.therapySession.diagnosisCodes ||
      this.therapySession.diagnosisCodes.length == 0
    ) {
      this.alerts.push('Atleast 1 Primary Diagnosis code needs to be selected');
    }

    // 06 ***************************************
    // Check if atleast 1 charge exists
    if (
      !this.therapySession.charges ||
      this.therapySession.charges.length == 0
    ) {
      this.alerts.push('Atleast 1 Charge needs to be added');
    }

    // 07 ***************************************
    // Check for Prior Auth
    if (this.therapySession.priorAuthCode === 'Yes') {
      if (!this.therapySession.priorAuthDetails) {
        this.alerts.push('Please add the prior authorization code');
      }
    }

    // 08 ***************************************
    // Check for Referring Provider
    if (this.therapySession.referringProvider === 'Yes') {
      if (!this.therapySession.referringProviderFirstName) {
        this.alerts.push('Please add the referring provider information');
      }
    }

    if (!this.therapySession.billingProviderPOSCode) {
      this.alerts.push('Please select the place of service code');
    }

    // CHeck if the diagnosis and claims number are >0
    if (
      this.therapySession?.charges &&
      this.therapySession?.diagnosisCodes &&
      this.therapySession.charges.length > 0 &&
      this.therapySession.diagnosisCodes.length > 0
    ) {
      let allDiagnosisPointers = '';
      // Now we need to validate if all the charges and diagnosis align with each other
      // We need to make sure all the diagnosis needs at least 1 cpt charges
      this.therapySession.diagnosisCodes.forEach((diagnosis) => {
        let diagnosisIndexMapped = false;
        allDiagnosisPointers += diagnosis.diagnosisIndex;

        // Now for each cpt's check if this pointer is being mapped or not
        this.therapySession.charges.forEach((charge) => {
          if (charge?.diagnosisPointer?.includes(diagnosis.diagnosisIndex)) {
            diagnosisIndexMapped = true;
          }
        });

        // If the diagnosis is not mapped, then add alert saying the diagnosis needs to be mapped
        if (!diagnosisIndexMapped) {
          this.alerts.push(
            'Please map the diagnosis pointer ' +
              diagnosis.diagnosisIndex +
              ' - ICD Code ' +
              diagnosis.icdCode +
              ' to the applicable procedure code.'
          );
        }
      });

      // Now for each CPT Charges make sure the diagnosis pointers exists
      this.therapySession.charges.forEach((charge) => {
        let currentPointers = charge?.diagnosisPointer;
        let currentPointersArray = currentPointers?.split('');

        if (!currentPointersArray || currentPointersArray.length == 0) {
          this.alerts.push(
            'Procedure code at index ' +
              charge.cptIndex +
              ' with Code - ' +
              charge.cptCode +
              ' is not mapped to any diagnosis.'
          );
        } else {
          // As this cpt code now has pointers, check if all the pointers exists
          currentPointersArray.forEach((pointer) => {
            if (!allDiagnosisPointers.includes(pointer)) {
              this.alerts.push(
                'Procedure code at index ' +
                  charge.cptIndex +
                  ' with Code - ' +
                  charge.cptCode +
                  ' has invalid diagnosis pointer ' +
                  pointer +
                  '.'
              );
            }
          });
        }
      });

      // For resubmission
      if (this.claimSubmittedToPayer) {
        if (!this.therapySession.resubmissionCode) {
          this.alerts.push(
            '* If and only if you wish to re-submit this claim, please select the Resubmission Code.'
          );
        }
      }
    }

    if (this.alerts.length > 0) {
      return false;
    }

    return true;
  }

  getBackgrounColor(i) {
    let index = i % this.colors.length;
    return `10px solid ${this.colors[index]}`;
  }

  priorAuthChanged(event) {
    if (event.value === 'Yes') {
      this.sessionBillingForm
        .get('priorAuthDetails')
        .addValidators(Validators.required);
      this.sessionBillingForm.get('priorAuthDetails').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.sessionBillingForm.get('priorAuthDetails').setValue(null);
      this.sessionBillingForm.get('priorAuthDetails').clearValidators();
      this.sessionBillingForm.get('priorAuthDetails').updateValueAndValidity();

      // Update the billing information
      this.updateBilling(false);
    }
  }

  lookUpPriorAuth() {
    let dialogRef = this.dialog.open(EditPriorAuthComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        primaryInsuranceDetails:
          this.sessionBillingForm.controls['primaryInsuranceDetails'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.sessionBillingForm.controls['priorAuthDetails'].setValue(
          response.details
        );

        // Update the billing information
        this.updateBilling(false);
      }
    });
  }

  selectReferringProvider() {
    let dialogRef = this.dialog.open(SelectReferringProviderComponent, {
      data: { therapySession: this.therapySession },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.sessionBillingForm
          .get('referringProviderId')
          .setValue(response.details.id);

        this.sessionBillingForm.controls['referringProviderFirstName'].setValue(
          response.details.firstName
        );
        this.sessionBillingForm.controls[
          'referringProviderMiddleName'
        ].setValue(response.details?.middleName);
        this.sessionBillingForm.controls['referringProviderLastName'].setValue(
          response.details.lastName
        );

        this.sessionBillingForm.controls['referringProviderNPI'].setValue(
          response.details.individualNPI
        );

        this.sessionBillingForm.controls['referringProviderTaxonomy'].setValue(
          response.details.taxonomyCode
        );

        this.therapySession = Object.assign(
          this.therapySession,
          this.sessionBillingForm.value
        );

        // Update the billing information
        this.updateBilling(false);
      }
    });
  }

  // When EPSDT is restored, restore type
  epsdtTypeChanged(event) {
    if (event.value === 'NA' || event.value === 'No') {
      // Restore epsdt type
      this.sessionBillingForm.controls['epsdtType'].setValue('');
      this.sessionBillingForm.controls['epsdtType'].clearValidators();
      this.sessionBillingForm.controls['epsdtType'].updateValueAndValidity();
    } else {
      this.sessionBillingForm.controls['epsdtType'].addValidators(
        Validators.required
      );
      this.sessionBillingForm.controls['epsdtType'].updateValueAndValidity();
    }

    // Update the billing
    this.updateBilling(false);
  }

  printDialog() {
    this.dialog.open(PrintDialogComponent, {
      data: {
        therapySession: this.therapySession,
        patient: this.patient,
        isBPSAEnforced: this.isBPSAEnforced,
      },
      autoFocus: false,
      minWidth: '35vw',
      disableClose: true,
    });
  }
}
