import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentPlanOverview } from 'src/app/_models/case/treatment/plan/treatmentplanoverview.model';
import { TreatmentPlanOverviewApiService } from 'src/app/_services/case/treatment/plan/treatmentplanoverview.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-care-plan-overview',
  templateUrl: './care-plan-overview.component.html',
  styleUrl: './care-plan-overview.component.css',
})
export class CarePlanOverviewComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;

  public action: string;
  public treatmentplanoverviewForm: FormGroup;
  treatmentplanoverview: ITreatmentPlanOverview;

  processing: boolean = false;

  constructor(
    public treatmentplanoverviewApiService: TreatmentPlanOverviewApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();

    this.loadTreatmentPlanOverview();
  }

  loadTreatmentPlanOverview() {
    this.treatmentplanoverviewApiService
      .getTreatmentPlanOverviewDetails(this.selectedPlan.id)
      .subscribe((treatmentplanoverview) => {
        if (treatmentplanoverview?.data) {
          this.treatmentplanoverviewForm.controls['id'].setValue(
            treatmentplanoverview.data.id
          );
          this.treatmentplanoverviewForm.controls['organizationId'].setValue(
            treatmentplanoverview.data.organizationId
          );
          this.treatmentplanoverviewForm.controls['patientId'].setValue(
            treatmentplanoverview.data.patientId
          );
          this.treatmentplanoverviewForm.controls['caseId'].setValue(
            treatmentplanoverview.data.caseId
          );
          this.treatmentplanoverviewForm.controls['planId'].setValue(
            treatmentplanoverview.data.planId
          );
          this.treatmentplanoverviewForm.controls[
            'assessmentsReviewed'
          ].setValue(treatmentplanoverview.data.assessmentsReviewed);
          this.treatmentplanoverviewForm.controls['presentingProblem'].setValue(
            treatmentplanoverview.data.presentingProblem
          );
          this.treatmentplanoverviewForm.controls[
            'strengthsResourcesAbilitiesInterestsBarriersToSuccess'
          ].setValue(
            treatmentplanoverview.data
              .strengthsResourcesAbilitiesInterestsBarriersToSuccess
          );
          this.treatmentplanoverviewForm.controls[
            'transferDischargeCriteria'
          ].setValue(treatmentplanoverview.data.transferDischargeCriteria);
          this.treatmentplanoverviewForm.controls['unknownField'].setValue(
            treatmentplanoverview.data.unknownField
          );
          this.treatmentplanoverviewForm.controls[
            'clinicianCommentsRecommendations'
          ].setValue(
            treatmentplanoverview.data.clinicianCommentsRecommendations
          );
        }
      });
  }

  onTreatmentPlanOverviewFormSubmit() {
    if (this.treatmentplanoverviewForm.invalid) {
      this.treatmentplanoverviewForm.markAllAsTouched();
      return;
    }

    this.treatmentplanoverview = Object.assign({}, this.treatmentplanoverview);
    this.treatmentplanoverview = Object.assign(
      this.treatmentplanoverview,
      this.treatmentplanoverviewForm.value
    );

    this.processing = true;
    this.treatmentplanoverviewApiService
      .updateTreatmentPlanOverview(this.treatmentplanoverview)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentplanoverviewForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planId: new FormControl(
        this.selectedPlan.id,
        Validators.compose([Validators.required])
      ),
      assessmentsReviewed: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      presentingProblem: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      strengthsResourcesAbilitiesInterestsBarriersToSuccess: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      transferDischargeCriteria: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      unknownField: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      clinicianCommentsRecommendations: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });

    if (this.selectedPlan.status != 'Active') {
      this.treatmentplanoverviewForm.disable();
    }
  }
}
