import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CareLog, Case, Countries, Patient } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import {
  formatDate,
  formatDateTime,
  mmddyyyyStringFromDate,
  mmddyyyyhhmiToDate,
} from 'src/app/shared/utilities/utilities';
import { CarelogDialogSelectorComponent } from '../carelog-dialogs/carelog-dialog-selector/carelog-dialog-selector.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
@Component({
  selector: 'app-carelog-card',
  templateUrl: './carelog-card.component.html',
  styleUrls: ['./carelog-card.component.css'],
})
export class CaseNoteCardComponent implements OnInit {
  @Input() carelog: CareLog;
  @Input() showGoal: boolean;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() goals: any[];
  @Input() memberGoals: any[];
  @Input() goalDrivenCare: boolean;

  @Input() hasCaseLogAddAccess: boolean;
  @Input() hasCaseLogEditAccess: boolean;
  @Input() hasCaseLogDeleteAccess: boolean;

  @Output() hasChanges = new EventEmitter<boolean>();
  caseId: string;

  orgConfig: any;
  isIndianOrg: boolean = false;
  activityDate: Date;
  isProcessing: boolean = false;

  basicTests = [
    { label: 'Oxidant', key: 'oxidant', normalCondition: 'Normal' },
    {
      label: 'Specific Gravity',
      key: 'specificGravity',
      normalCondition: 'Normal',
    },
    { label: 'Ph', key: 'ph', normalCondition: 'Normal' },
    { label: 'Nitrite', key: 'nitrite', normalCondition: 'Normal' },
    { label: 'Gl', key: 'gl', normalCondition: 'Normal' },
    { label: 'Creatinine', key: 'creatinine', normalCondition: 'Normal' },
  ];

  drugTests = [
    {
      name: 'Alcohol',
      code: 'ALC',
      control: 'alcohol',
      normalCondition: 'Negative',
    },
    {
      name: 'Amphetamines',
      code: 'AMP',
      control: 'amphetamine',
      normalCondition: 'Negative',
    },
    {
      name: 'Barbiturates',
      code: 'BAR',
      control: 'barbiturates',
      normalCondition: 'Negative',
    },
    {
      name: 'Buprenorphine',
      code: 'BUP',
      control: 'buprenorphine',
      normalCondition: 'Negative',
    },
    {
      name: 'Benzodiazepines',
      code: 'BZO',
      control: 'benzodiazepines',
      normalCondition: 'Negative',
    },
    {
      name: 'Cocaine',
      code: 'COC',
      control: 'cocaine',
      normalCondition: 'Negative',
    },
    {
      name: 'Cotinine',
      code: 'COT',
      control: 'cotinine',
      normalCondition: 'Negative',
    },
    {
      name: 'Methadone Metabolite',
      code: 'EDDP',
      control: 'methadonemetabolite',
      normalCondition: 'Negative',
    },
    {
      name: 'Ethyl Glucuronide',
      code: 'ETG',
      control: 'ethyl',
      normalCondition: 'Negative',
    },
    {
      name: 'Fentanyl',
      code: 'FTY',
      control: 'fentanyl',
      normalCondition: 'Negative',
    },
    {
      name: 'Heroin',
      code: 'HRN',
      control: 'heroin',
      normalCondition: 'Negative',
    },
    {
      name: 'Ketanine',
      code: 'KTY',
      control: 'ketanine',
      normalCondition: 'Negative',
    },
    {
      name: 'Methadone',
      code: 'MTD',
      control: 'methadone',
      normalCondition: 'Negative',
    },
    {
      name: 'Methamphetamine',
      code: 'mAMP/MET',
      control: 'methamphetamine',
      normalCondition: 'Negative',
    },
    {
      name: 'Ecstasy',
      code: 'MDMA',
      control: 'ecstasy',
      normalCondition: 'Negative',
    },
    {
      name: 'Morphine/Opiates 300',
      code: 'MOP/OPI300',
      control: 'morphine',
      normalCondition: 'Negative',
    },
    {
      name: 'Opiates',
      code: 'OPI',
      control: 'opiate',
      normalCondition: 'Negative',
    },
    {
      name: 'Oxycodone',
      code: 'OXY',
      control: 'oxycodone',
      normalCondition: 'Negative',
    },
    {
      name: 'Phencyclidine',
      code: 'PCP',
      control: 'phencyclidine',
      normalCondition: 'Negative',
    },
    {
      name: 'Propoxyphene',
      code: 'PPX',
      control: 'propoxyphene',
      normalCondition: 'Negative',
    },
    {
      name: 'Synthetic Cannabinoids',
      code: 'K2',
      control: 'syntheticcannabinoids',
      normalCondition: 'Negative',
    },
    {
      name: 'Marijuana',
      code: 'THC',
      control: 'marijuana',
      normalCondition: 'Negative',
    },
    {
      name: 'Tramadol',
      code: 'TRA',
      control: 'tramadol',
      normalCondition: 'Negative',
    },
    {
      name: 'Tricyclic Antidepressants',
      code: 'TCA',
      control: 'tricyclicAntidepressants',
      normalCondition: 'Negative',
    },
    { name: 'Other', code: '', control: 'other', normalCondition: 'No' },
    {
      name: 'Other Description',
      code: '',
      control: 'otherDesc',
      isRequired: false,
      normalCondition: '',
    },
  ];

  constructor(
    public careLogHttpService: CareLogHttpService,
    public dialog: MatDialog,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.caseId = this.case.id;

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
          }
        });
      }
    });

    if (this.carelog.activityDateTime || this.carelog.createdDate) {
      this.activityDate = this.carelog.activityDateTime
        ? mmddyyyyhhmiToDate(this.carelog.activityDateTime)
        : mmddyyyyhhmiToDate(this.carelog.createdDate);
    }
  }

  public getGoalAndAddCareLog(carelog: CareLog) {
    carelog.vitalInfoAvailable = false;
    carelog.patientId = this.case.patientId;

    if (carelog.additionalInput === 'Vital') {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Vitals',
          carelog: this.carelog,
          action: 'ADD',
        },
        disableClose: true,
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else if (carelog.additionalInput === 'Seizure Log') {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Seizure',
          carelog: this.carelog,
          action: 'ADD',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else if (carelog.additionalInput === 'Bowel Movement') {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Bowel',
          carelog: this.carelog,
          action: 'ADD',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else if (carelog.additionalInput === 'Sleep Record') {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Sleep',
          carelog: this.carelog,
          action: 'ADD',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else if (carelog.additionalInput === 'Behavioral Incident') {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Behavioral Incident',
          carelog: this.carelog,
          action: 'ADD',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else {
      carelog.vitalInfoAvailable = false;
      carelog.activityDateTime = formatDateTime(new Date(this.logDate));
      this.addCareLog(carelog);
    }
  }

  public addCareLog(carelog: CareLog) {
    this.isProcessing = true;
    carelog.caseId = this.caseId;
    carelog.logDate = formatDate(this.logDate);
    carelog.id = null;
    carelog.shift = this.shift;
    carelog.patientId = this.case.patientId;

    this.careLogHttpService.addCareLog(this.caseId, carelog).subscribe(() => {
      this.isProcessing = false;
      this.hasChanges.emit(true);
    });
  }

  public deleteCareLog(log: CareLog) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this care log?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.careLogHttpService
          .deleteCareLog(
            this.caseId,
            log.id,
            log.logNumber,
            mmddyyyyStringFromDate(this.logDate),
            this.shift
          )
          .subscribe({
            next: () => {
              this.toastMessageService.displaySuccessMessage(
                'Care log has been updated'
              );
              this.hasChanges.emit(true);
            },
            error: () => {
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong while deleting the care log'
              );
            },
          });
      }
    });
  }

  public editCareLog(carelog: CareLog) {
    if (carelog.vitalInfoAvailable) {
      this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          carelog,
          goalDrivenCare: this.goalDrivenCare,
          initialAnswer: 'Vitals',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });
    } else if (carelog.seizureLog) {
      this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          initialAnswer: 'Seizure',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });
    } else if (carelog.bowelMovement) {
      this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          initialAnswer: 'Bowel',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });
    } else if (carelog.sleepTimes && carelog.sleepTimes.length > 0) {
      this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          initialAnswer: 'Sleep',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });
    } else if (carelog.behavioralIncident) {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          initialAnswer: 'Behavioral Incident',
          action: 'VIEW',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((payload) => {
        this.hasChanges.emit(true);
      });
    } else if (carelog.urinalDrugScreen) {
      this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          goals: this.goals,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          initialAnswer: 'Urine Drug Screen',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });
    } else {
      const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
        data: {
          case: this.case,
          logDate: this.logDate,
          shift: this.shift,
          goalDrivenCare: this.goalDrivenCare,
          carelog,
          goals: this.goals,
          memberGoals: this.memberGoals,
          patientId: this.patient.id,
          initialAnswer: 'Generic',
          action: 'EDIT',
        },
        disableClose: true,
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((payload) => {
        if (payload === 'success') {
          carelog = payload.note;

          this.hasChanges.emit(true);
        }
      });
    }
  }
}
