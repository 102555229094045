import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IObsessiveCompulsiveInventory } from 'src/app/_models/assessments/ocd/obsessivecompulsiveinventory.model';
import {
  ObsessiveCompulsiveInventoryApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-obsessive-compulsive-inventory',
  templateUrl: './obsessive-compulsive-inventory.component.html',
  styleUrls: ['./obsessive-compulsive-inventory.component.css'],
})
export class ObsessiveCompulsiveInventoryComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public obsessiveCompulsiveInventoryApiService: ObsessiveCompulsiveInventoryApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public obsessiveCompulsiveInventoryForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  obsessiveCompulsiveInventoryAssessment: IObsessiveCompulsiveInventory;
  obsessiveCompulsiveInventoryAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.obsessiveCompulsiveInventoryForm,
      this.errors
    );

    this.loadObsessiveCompulsiveInventoryAssessment();
  }

  loadObsessiveCompulsiveInventoryAssessment() {
    this.obsessiveCompulsiveInventoryForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.obsessiveCompulsiveInventoryApiService
        .getObsessiveCompulsiveInventoryDetails(this.assessmentId)
        .subscribe((obsessiveCompulsiveInventoryAssessment) => {
          if (obsessiveCompulsiveInventoryAssessment.data) {
            this.obsessiveCompulsiveInventoryForm.controls[
              'savedUpSoManyThings'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.savedUpSoManyThings
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'checkThingsMoreOften'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.checkThingsMoreOften
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'getUpsetIfObjectAreNotArrangedProperly'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .getUpsetIfObjectAreNotArrangedProperly.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'compelledToCount'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.compelledToCount
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'dontWantToTouchAnObjectIfTouchedByStrangers'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .dontWantToTouchAnObjectIfTouchedByStrangers.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'difficultToControlMyOwnThoughts'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .difficultToControlMyOwnThoughts.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'collectThingsIDontNeed'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.collectThingsIDontNeed
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'repeatedlyCheckDoorsWindowsDrawers'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .repeatedlyCheckDoorsWindowsDrawers.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'getUpsetIfOthersChangeArrangedThings'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .getUpsetIfOthersChangeArrangedThings.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'repeatCertainNumbers'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.repeatCertainNumbers
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'washOrCleanMyself'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.washOrCleanMyself
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'upsetByUnpleasantThoughts'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .upsetByUnpleasantThoughts.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'pilingUpThings'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.pilingUpThings.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'repeatedlyCheckGasAndWaterTapsAndLightSwitches'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .repeatedlyCheckGasAndWaterTapsAndLightSwitches.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'arrangeInParticularWay'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.arrangeInParticularWay
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'goodAndBadNumbers'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.goodAndBadNumbers
                .answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'washHandsOftenAndLongerThanNecessary'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data
                .washHandsOftenAndLongerThanNecessary.answer
            );
            this.obsessiveCompulsiveInventoryForm.controls[
              'getNastyThoughts'
            ].setValue(
              obsessiveCompulsiveInventoryAssessment.data.getNastyThoughts
                .answer
            );

            this.riskScore =
              obsessiveCompulsiveInventoryAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.obsessiveCompulsiveInventoryForm.invalid) {
      this.obsessiveCompulsiveInventoryForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.obsessiveCompulsiveInventoryAssessment = Object.assign(
      {},
      this.obsessiveCompulsiveInventoryAssessment
    );
    this.obsessiveCompulsiveInventoryAssessment.patientId =
      this.obsessiveCompulsiveInventoryForm.controls['patientId'].value;
    this.obsessiveCompulsiveInventoryAssessment.sessionId =
      this.obsessiveCompulsiveInventoryForm.controls['sessionId'].value;
    this.obsessiveCompulsiveInventoryAssessment.assessmentRequestId =
      this.obsessiveCompulsiveInventoryForm.controls[
        'assessmentRequestId'
      ].value;
    this.obsessiveCompulsiveInventoryAssessment.savedUpSoManyThings = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['savedUpSoManyThings']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.checkThingsMoreOften = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['checkThingsMoreOften']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.getUpsetIfObjectAreNotArrangedProperly =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'getUpsetIfObjectAreNotArrangedProperly'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.compelledToCount = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['compelledToCount']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.dontWantToTouchAnObjectIfTouchedByStrangers =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'dontWantToTouchAnObjectIfTouchedByStrangers'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.difficultToControlMyOwnThoughts =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'difficultToControlMyOwnThoughts'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.collectThingsIDontNeed = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['collectThingsIDontNeed']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.repeatedlyCheckDoorsWindowsDrawers =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'repeatedlyCheckDoorsWindowsDrawers'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.getUpsetIfOthersChangeArrangedThings =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'getUpsetIfOthersChangeArrangedThings'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.repeatCertainNumbers = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['repeatCertainNumbers']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.washOrCleanMyself = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['washOrCleanMyself']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.upsetByUnpleasantThoughts = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'upsetByUnpleasantThoughts'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.pilingUpThings = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['pilingUpThings'].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.repeatedlyCheckGasAndWaterTapsAndLightSwitches =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'repeatedlyCheckGasAndWaterTapsAndLightSwitches'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.arrangeInParticularWay = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['arrangeInParticularWay']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.goodAndBadNumbers = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['goodAndBadNumbers']
          .value,
    };
    this.obsessiveCompulsiveInventoryAssessment.washHandsOftenAndLongerThanNecessary =
    {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls[
          'washHandsOftenAndLongerThanNecessary'
        ].value,
    };
    this.obsessiveCompulsiveInventoryAssessment.getNastyThoughts = {
      answer:
        this.obsessiveCompulsiveInventoryForm.controls['getNastyThoughts']
          .value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.obsessiveCompulsiveInventoryAssessment.accessCode = this.accessCode;

      this.obsessiveCompulsiveInventoryApiService
        .addGuestObsessiveCompulsiveInventory(
          this.obsessiveCompulsiveInventoryAssessment
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Obsessive Compulsive Inventory Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Obsessive Compulsive Inventory Assessment'
            );
          }
        );
    } else {
      this.obsessiveCompulsiveInventoryApiService
        .addObsessiveCompulsiveInventory(
          this.obsessiveCompulsiveInventoryAssessment
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Obsessive Compulsive Inventory Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Obsessive Compulsive Inventory Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    savedUpSoManyThings: [
      {
        type: 'required',
        message:
          'I have saved up so many things that they get in the way is required',
      },
    ],
    checkThingsMoreOften: [
      {
        type: 'required',
        message: 'I check things more often than necessary is required',
      },
    ],
    getUpsetIfObjectAreNotArrangedProperly: [
      {
        type: 'required',
        message: 'I get upset if objects are not arranged properly is required',
      },
    ],
    compelledToCount: [
      {
        type: 'required',
        message:
          'I feel compelled to count while I am doing things is required',
      },
    ],
    dontWantToTouchAnObjectIfTouchedByStrangers: [
      {
        type: 'required',
        message:
          'I find it difficult to touch an object when I know it has been touched by strangers or certain people is required',
      },
    ],
    difficultToControlMyOwnThoughts: [
      {
        type: 'required',
        message: 'I find it difficult to control my own thoughts is required',
      },
    ],
    collectThingsIDontNeed: [
      {
        type: 'required',
        message: "I collect things I don't need is required",
      },
    ],
    repeatedlyCheckDoorsWindowsDrawers: [
      {
        type: 'required',
        message: 'I repeatedly check doors, windows, drawers, etc is required',
      },
    ],
    getUpsetIfOthersChangeArrangedThings: [
      {
        type: 'required',
        message:
          'I get upset if others change the way I have arranged things is required',
      },
    ],
    repeatCertainNumbers: [
      {
        type: 'required',
        message: 'I feel I have to repeat certain numbers is required',
      },
    ],
    washOrCleanMyself: [
      {
        type: 'required',
        message:
          'I sometimes have to wash or clean myself simply because I feel contaminated is required',
      },
    ],
    upsetByUnpleasantThoughts: [
      {
        type: 'required',
        message:
          'I am upset by unpleasant thoughts that come into my mind against my will is required',
      },
    ],
    pilingUpThings: [
      {
        type: 'required',
        message:
          'I avoid throwing things away because I am afraid I might need them later is required',
      },
    ],
    repeatedlyCheckGasAndWaterTapsAndLightSwitches: [
      {
        type: 'required',
        message:
          'I repeatedly check gas and water taps and light switches after turning them off is required',
      },
    ],
    arrangeInParticularWay: [
      {
        type: 'required',
        message: 'I need things to be arranged in a particular way is required',
      },
    ],
    goodAndBadNumbers: [
      {
        type: 'required',
        message: 'I feel that there are good and bad numbers is required',
      },
    ],
    washHandsOftenAndLongerThanNecessary: [
      {
        type: 'required',
        message:
          'I wash my hands more often and longer than necessary is required',
      },
    ],
    getNastyThoughts: [
      {
        type: 'required',
        message:
          'I frequently get nasty thoughts and have difficulty in getting rid of them is required',
      },
    ],
  };

  private buildForm() {
    this.obsessiveCompulsiveInventoryForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      savedUpSoManyThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      checkThingsMoreOften: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      getUpsetIfObjectAreNotArrangedProperly: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      compelledToCount: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      dontWantToTouchAnObjectIfTouchedByStrangers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      difficultToControlMyOwnThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      collectThingsIDontNeed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      repeatedlyCheckDoorsWindowsDrawers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      getUpsetIfOthersChangeArrangedThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      repeatCertainNumbers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      washOrCleanMyself: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      upsetByUnpleasantThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      pilingUpThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      repeatedlyCheckGasAndWaterTapsAndLightSwitches: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      arrangeInParticularWay: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      goodAndBadNumbers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      washHandsOftenAndLongerThanNecessary: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      getNastyThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.obsessiveCompulsiveInventoryForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.obsessiveCompulsiveInventoryForm) {
      return;
    }
    const form = this.obsessiveCompulsiveInventoryForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
