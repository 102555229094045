import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ITherapySession } from 'src/app/_models';
import { IBpsaClinicalCulturalEtc } from 'src/app/_models/session/clinical/bpsa-clinical-cultural-etc.model';
import { ToastMessageService } from 'src/app/_services';
import { BpsaClinicalCulturalEtcApiService } from 'src/app/_services/session/clinical/bpsa-clinical-cultural-etc.service';

@Component({
  selector: 'app-cultural-spirutual-leisure-strengths',
  templateUrl: './cultural-spirutual-leisure-strengths.component.html',
  styleUrls: ['./cultural-spirutual-leisure-strengths.component.css'],
})
export class CulturalSpirutualLeisureStrengthsComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsaclinicalculturaletc: IBpsaClinicalCulturalEtc;
  public action: string;
  public bpsaclinicalculturaletcForm: FormGroup;
  processing: boolean = false;

  constructor(
    public bpsaclinicalculturaletcApiService: BpsaClinicalCulturalEtcApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) { }

  public ngOnInit() {
    this.bpsaclinicalculturaletc = this.therapySession.bpsaClinicalCulturalEtc;
    this.buildForm();
    this.loadBpsaClinicalCulturalEtc();
  }

  loadBpsaClinicalCulturalEtc() {
    if (this.bpsaclinicalculturaletc) {
      this.bpsaclinicalculturaletcForm.controls['culturalInfluences'].setValue(
        this.bpsaclinicalculturaletc.culturalInfluences
      );
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].setValue(this.bpsaclinicalculturaletc.culturalInfluencesDetails);
      this.bpsaclinicalculturaletcForm.controls['religion'].setValue(
        this.bpsaclinicalculturaletc.religion
      );
      this.bpsaclinicalculturaletcForm.controls['beliefs'].setValue(
        this.bpsaclinicalculturaletc.beliefs
      );
      this.bpsaclinicalculturaletcForm.controls['spareTimeActivity'].setValue(
        this.bpsaclinicalculturaletc.spareTimeActivity
      );
      this.bpsaclinicalculturaletcForm.controls[
        'lastOccurenceOfSareTimeActivity'
      ].setValue(this.bpsaclinicalculturaletc.lastOccurenceOfSareTimeActivity);
      this.bpsaclinicalculturaletcForm.controls['strengths'].setValue(
        this.bpsaclinicalculturaletc.strengths
      );
    }

    // Initialize Auto save
    this.initiateAutoSave();
  }

  initiateAutoSave() {
    this.bpsaclinicalculturaletcForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaClinicalCulturalEtcFormSubmit();
      });
  }

  onBpsaClinicalCulturalEtcFormSubmit() {
    this.bpsaclinicalculturaletc = Object.assign(
      {},
      this.bpsaclinicalculturaletc
    );
    this.bpsaclinicalculturaletc = Object.assign(
      this.bpsaclinicalculturaletc,
      this.bpsaclinicalculturaletcForm.value
    );

    this.processing = true;

    this.bpsaclinicalculturaletcApiService
      .updateBpsaClinicalCulturalEtc(this.bpsaclinicalculturaletc)
      .subscribe({
        next: (response) => {
          this.updatedTherapySession.emit(response);
          this.processing = false;
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsaclinicalculturaletcForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      culturalInfluences: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      culturalInfluencesDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      religion: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      beliefs: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      spareTimeActivity: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      lastOccurenceOfSareTimeActivity: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      strengths: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
    });
  }

  checkCulturalInfluence() {
    let selectedAnswer =
      this.bpsaclinicalculturaletcForm.controls['culturalInfluences'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].addValidators(Validators.required);
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].clearValidators();
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].reset();
      this.bpsaclinicalculturaletcForm.controls[
        'culturalInfluencesDetails'
      ].updateValueAndValidity();
    }
  }
}
