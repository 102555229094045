<div class="col-md-12">
  <form
    [formGroup]="treatmentdischargestatusForm"
    (ngSubmit)="onTreatmentDischargeStatusFormSubmit()"
  >
    <div class="row">
      <!-- Pregnant Status At Discharge  -->
      <div class="col-md-6">
        <label class="labelText">
          Pregnant Status At Discharge <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="pregnantStatusAtDischarge"
            placeholder="Pregnant Status At Discharge"
          >
            <mat-option value="Not Applicable">Not Applicable</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls['pregnantStatusAtDischarge']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Relationship Status At Discharge  -->
      <div class="col-md-6">
        <label class="labelText">
          Relationship Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="relationshipStatus"
            placeholder="Relationship Status"
          >
            <mat-option value="Never Married">Never Married</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Remarried">Remarried</mat-option>
            <mat-option value="Separated">Separated</mat-option>
            <mat-option value="Divorced/Annulled">Divorced/Annulled</mat-option>
            <mat-option value="Widowed">Widowed</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls['relationshipStatus']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--Living Arrangement -->
      <div class="col-md-6">
        <label class="labelText">
          Living Arrangement <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="livingArrangement"
            placeholder="Living Arrangement"
          >
            <mat-option value="Street, shelter, no permanent residence"
              >Street, shelter, no permanent residence</mat-option
            >
            <mat-option value="Supervised Housing - RTF (residential Tx)"
              >Supervised Housing - RTF (residential Tx)</mat-option
            >
            <mat-option
              value="Supervised Housing - SLF (supported living, mental health org)"
            >
              Supervised Housing - SLF (supported living, mental health org)
            </mat-option>
            <mat-option value="Other supervised housing"
              >Other supervised housing</mat-option
            >
            <mat-option value="Jail, correction facility"
              >Jail, correction facility</mat-option
            >
            <mat-option value="Mental Health Institution"
              >Mental Health Institution</mat-option
            >
            <mat-option value="Nursing Home">Nursing Home</mat-option>
            <mat-option value="Other Institution">Other Institution</mat-option>
            <mat-option value="Private residence">Private residence</mat-option>
            <mat-option
              value="Supported housing (private residence with staff)"
            >
              Supported housing (private residence with staff)
            </mat-option>
            <mat-option value="Other">Other</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls['livingArrangement'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Employment Status -->
      <div class="col-md-6">
        <label class="labelText">
          Employment Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="employmentStatus"
            placeholder="Employment Status"
          >
            <mat-option value="Full time (35+ hours)"
              >Full time (35+ hours)</mat-option
            >
            <mat-option value="Part time (regular hours)"
              >Part time (regular hours)</mat-option
            >
            <mat-option value="Part time (irregular hours)"
              >Part time (irregular hours)</mat-option
            >
            <mat-option value="Student">Student</mat-option>
            <mat-option value="Military service">Military service</mat-option>
            <mat-option value="Retired/disability"
              >Retired/disability</mat-option
            >
            <mat-option value="Unemployed">Unemployed</mat-option>
            <mat-option value="In controlled environment"
              >In controlled environment</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls['employmentStatus'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Days Attended Meetings Last 30 Days -->
      <div class="col-md-6">
        <label class="labelText">
          Days Attended Meetings Last 30 Days <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Days Attended Meetings Last 30 Days"
            formControlName="daysAttendedMeetingsInLast30Days"
            name="daysAttendedMeetingsInLast30Days"
            required
            type="number"
          />
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls[
                'daysAttendedMeetingsInLast30Days'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- County Of Residence -->
      <div class="col-md-6">
        <label class="labelText">
          County Of Residence <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="countyOfResidence"
            placeholder="County Of Residence"
          >
            <mat-option *ngFor="let county of counties" [value]="county">
              {{ county }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls['countyOfResidence'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Education -->
      <div class="col-md-6">
        <label class="labelText">
          Education <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="education" placeholder="Education">
            <mat-option value="Less than one grade completed"
              >Less than one grade completed</mat-option
            >
            <mat-option value="1st Grade">1st Grade</mat-option>
            <mat-option value="2nd Grade">2nd Grade</mat-option>
            <mat-option value="3rd Grade">3rd Grade</mat-option>
            <mat-option value="4th Grade">4th Grade</mat-option>
            <mat-option value="5th Grade">5th Grade</mat-option>
            <mat-option value="6th Grade">6th Grade</mat-option>
            <mat-option value="7th Grade">7th Grade</mat-option>
            <mat-option value="8th Grade">8th Grade</mat-option>
            <mat-option value="9th Grade">9th Grade</mat-option>
            <mat-option value="10th Grade">10th Grade</mat-option>
            <mat-option value="11th Grade">11th Grade</mat-option>
            <mat-option value="Grade 12, HS diploma, GED"
              >Grade 12, HS diploma, GED</mat-option
            >
            <mat-option value="Some college, no degree"
              >Some college, no degree</mat-option
            >
            <mat-option value="Two year college/associate degree"
              >Two year college/associate degree</mat-option
            >
            <mat-option value="Four year college degree"
              >Four year college degree</mat-option
            >
            <mat-option value="Graduate work, no degree"
              >Graduate work, no degree</mat-option
            >
            <mat-option value="Graduate professional degree or more"
              >Graduate professional degree or more</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
            <mat-option value="Vocational Training Beyond High School"
              >Vocational Training Beyond High School</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="treatmentdischargestatusForm.controls['education'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Number Of Children Living With Client -->
      <div class="col-md-6">
        <label class="labelText">
          Number Of Children Living With Client
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Number Of Children Living With Client"
            formControlName="numberOfChildrenLivingWithClient"
            name="numberOfChildrenLivingWithClient"
            required
            type="number"
          />
          <mat-error
            *ngIf="
              treatmentdischargestatusForm.controls[
                'numberOfChildrenLivingWithClient'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!treatmentdischargestatusForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
