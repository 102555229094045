import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentDischargeStatus } from 'src/app/_models/case/treatment/discharge/treatmentdischargestatus.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentDischargeStatusApiService } from 'src/app/_services/case/treatment/discharge/treatmentdischargestatus.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { getCountiesByState } from 'src/app/shared/utilities/states-counties/counties';
@Component({
  selector: 'app-discharge-status',
  templateUrl: './discharge-status.component.html',
  styleUrl: './discharge-status.component.css',
})
export class DischargeStatusComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedProgram: ITreatmentProgram;

  public action: string;
  public treatmentdischargestatusForm: FormGroup;
  treatmentdischargestatus: ITreatmentDischargeStatus;
  processing: boolean = false;

  public counties: string[] = [];

  constructor(
    public treatmentdischargestatusApiService: TreatmentDischargeStatusApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();

    this.counties = getCountiesByState('TN');
    this.loadTreatmentDischargeStatus();
  }

  loadTreatmentDischargeStatus() {
    this.treatmentdischargestatusApiService
      .getTreatmentDischargeStatusDetails(this.selectedProgram.id)
      .subscribe((treatmentdischargestatus) => {
        if (treatmentdischargestatus?.data) {
          this.treatmentdischargestatusForm.controls['id'].setValue(
            treatmentdischargestatus.data.id
          );
          this.treatmentdischargestatusForm.controls['organizationId'].setValue(
            treatmentdischargestatus.data.organizationId
          );
          this.treatmentdischargestatusForm.controls['patientId'].setValue(
            treatmentdischargestatus.data.patientId
          );
          this.treatmentdischargestatusForm.controls['caseId'].setValue(
            treatmentdischargestatus.data.caseId
          );
          this.treatmentdischargestatusForm.controls['programId'].setValue(
            treatmentdischargestatus.data.programId
          );
          this.treatmentdischargestatusForm.controls[
            'pregnantStatusAtDischarge'
          ].setValue(treatmentdischargestatus.data.pregnantStatusAtDischarge);
          this.treatmentdischargestatusForm.controls[
            'relationshipStatus'
          ].setValue(treatmentdischargestatus.data.relationshipStatus);
          this.treatmentdischargestatusForm.controls[
            'livingArrangement'
          ].setValue(treatmentdischargestatus.data.livingArrangement);
          this.treatmentdischargestatusForm.controls[
            'employmentStatus'
          ].setValue(treatmentdischargestatus.data.employmentStatus);
          this.treatmentdischargestatusForm.controls[
            'daysAttendedMeetingsInLast30Days'
          ].setValue(
            treatmentdischargestatus.data.daysAttendedMeetingsInLast30Days
          );
          this.treatmentdischargestatusForm.controls[
            'countyOfResidence'
          ].setValue(treatmentdischargestatus.data.countyOfResidence);
          this.treatmentdischargestatusForm.controls['education'].setValue(
            treatmentdischargestatus.data.education
          );
          this.treatmentdischargestatusForm.controls[
            'numberOfChildrenLivingWithClient'
          ].setValue(
            treatmentdischargestatus.data.numberOfChildrenLivingWithClient
          );
        }
      });
  }

  onTreatmentDischargeStatusFormSubmit() {
    if (this.treatmentdischargestatusForm.invalid) {
      this.treatmentdischargestatusForm.markAllAsTouched();
      return;
    }
    this.treatmentdischargestatus = Object.assign(
      {},
      this.treatmentdischargestatus
    );
    this.treatmentdischargestatus = Object.assign(
      this.treatmentdischargestatus,
      this.treatmentdischargestatusForm.value
    );

    this.processing = true;
    this.treatmentdischargestatusApiService
      .updateTreatmentDischargeStatus(this.treatmentdischargestatus)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentdischargestatusForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      programId: new FormControl(this.selectedProgram.id, Validators.required),
      pregnantStatusAtDischarge: new FormControl('', Validators.required),
      relationshipStatus: new FormControl('', Validators.required),
      livingArrangement: new FormControl('', Validators.required),
      employmentStatus: new FormControl('', Validators.required),
      daysAttendedMeetingsInLast30Days: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      countyOfResidence: new FormControl('', Validators.required),
      education: new FormControl('', Validators.compose([Validators.required])),
      numberOfChildrenLivingWithClient: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
    });
  }
}
