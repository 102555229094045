<mat-tab-group
  #matTabGroup
  [@.disabled]="true"
  (selectedTabChange)="changeSelectedIndex($event)"
  [selectedIndex]="selectedIndex"
>
  <mat-tab label="Intake">
    <ng-container *ngIf="isIntakeTabSelected">
      <app-case-treatment-intake
        [case]="case"
        [patient]="patient"
      ></app-case-treatment-intake>
    </ng-container>
  </mat-tab>

  <mat-tab label="Admission">
    <ng-container *ngIf="isAdmissionSelected">
      <mat-card-title>Admission</mat-card-title>
      <div class="chip-container">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let subTab of admissionTabs"
            [selected]="selectedAdmissionSubTab === subTab.value"
            (click)="selectAdmissionSubTab(subTab.value)"
            selectable="true"
            [class]="
              selectedAdmissionSubTab === subTab.value
                ? 'mat-chip-active'
                : 'mat-chip-inactive'
            "
          >
            {{ subTab.label }}
          </mat-chip>
        </mat-chip-list>
      </div>

      <mat-divider style="margin: 10px 0px"></mat-divider>

      <div>
        <ng-container *ngIf="selectedAdmissionSubTab === 'Profile'">
          <app-case-admission-profile
            [case]="case"
            [patient]="patient"
            recordType="Admission"
          ></app-case-admission-profile>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'Financial'">
          <app-case-admission-financial
            [case]="case"
            [patient]="patient"
          ></app-case-admission-financial>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'Substance'">
          <app-case-admission-substance
            [case]="case"
            [patient]="patient"
            recordType="Admission"
          ></app-case-admission-substance>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'Legal'">
          <app-case-admission-legal
            [case]="case"
            [patient]="patient"
            recordType="Admission"
          ></app-case-admission-legal>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'ASAM'">
          <app-case-asam-admission-ongoing
            [case]="case"
            [patient]="patient"
            recordType="Admission"
          ></app-case-asam-admission-ongoing>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'Diagnosis'">
          <app-case-admission-diagnosis
            [case]="case"
            [patient]="patient"
            diagnosisType="Admission"
          ></app-case-admission-diagnosis>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'ProgramEnroll'">
          <app-discharge
            [case]="case"
            [patient]="patient"
            [showAddButton]="true"
          ></app-discharge>
        </ng-container>
        <ng-container *ngIf="selectedAdmissionSubTab === 'TreatmentTeam'">
          <app-case-admission-care-team
            [case]="case"
            [patient]="patient"
            recordType="Admission"
          ></app-case-admission-care-team>
        </ng-container>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab label="ASAM">
    <ng-container *ngIf="isAsamTabSelected">
      <app-case-treatment-asam
        [case]="case"
        [patient]="patient"
      ></app-case-treatment-asam>
    </ng-container>
  </mat-tab>

  <mat-tab label="Plans">
    <ng-container *ngIf="isTreatmentPlanTabSelected">
      <ng-container *ngIf="selectedPlan && selectedPlanSubTab !== ''">
        <div style="margin-bottom: 20px">
          <span
            (click)="resetPlanTab()"
            style="color: #2196f3; cursor: pointer; font-weight: 500"
          >
            Plans
          </span>
          <span *ngIf="selectedPlan"> &nbsp; / &nbsp; </span>
          <span *ngIf="selectedPlan">
            {{ selectedPlan.planName }}
          </span>
        </div>
        <div class="chip-container">
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let subTab of planTabs"
              [selected]="selectedPlanSubTab === subTab.value"
              (click)="selectPlanSubTab(subTab.value)"
              selectable="true"
              [class]="
                selectedPlanSubTab === subTab.value
                  ? 'mat-chip-active'
                  : 'mat-chip-inactive'
              "
            >
              {{ subTab.label }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <mat-divider style="margin: 10px 0px"></mat-divider>
      </ng-container>
      <div>
        <ng-container *ngIf="selectedPlanSubTab === ''">
          <app-care-plans
            [case]="case"
            [patient]="patient"
            (selectedPlan)="onPlanSelected($event)"
          ></app-care-plans>
        </ng-container>

        <ng-container *ngIf="selectedPlan && selectedPlanSubTab === 'Profile'">
          <app-care-plan-profile
            [case]="case"
            [patient]="patient"
            [selectedPlan]="selectedPlan"
          ></app-care-plan-profile>
        </ng-container>
        <ng-container *ngIf="selectedPlan && selectedPlanSubTab === 'Overview'">
          <app-care-plan-overview
            [case]="case"
            [patient]="patient"
            [selectedPlan]="selectedPlan"
          ></app-care-plan-overview>
        </ng-container>
        <ng-container
          *ngIf="selectedPlan && selectedPlanSubTab === 'Diagnosis'"
        >
          <app-case-admission-diagnosis
            [case]="case"
            [patient]="patient"
            [selectedPlan]="selectedPlan"
            diagnosisType="Plan"
          ></app-case-admission-diagnosis>
        </ng-container>
        <ng-container *ngIf="selectedPlan && selectedPlanSubTab === 'Targets'">
          <app-care-plan-problems
            [case]="case"
            [patient]="patient"
            [selectedPlan]="selectedPlan"
          ></app-care-plan-problems>
        </ng-container>
        <ng-container
          *ngIf="selectedPlan && selectedPlanSubTab === 'Plan Outline'"
        >
          <app-print-plan-treatment-report
            [selectedPlan]="selectedPlan"
          ></app-print-plan-treatment-report>
        </ng-container>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab label="Reviews">
    <ng-container *ngIf="isTreatmentReviewTabSelected">
      <ng-container *ngIf="selectedPlan">
        <div style="margin-bottom: 20px">
          <span
            (click)="resetReviewTab()"
            style="color: #2196f3; cursor: pointer; font-weight: 500"
          >
            Reviews
          </span>
          <span *ngIf="selectedPlan"> &nbsp; / &nbsp; </span>
          <span
            *ngIf="selectedPlan"
            (click)="resetReviewPlan()"
            style="color: #2196f3; cursor: pointer; font-weight: 500"
          >
            {{ selectedPlan.planNumber }}
          </span>
          <span *ngIf="selectedReview && selectedReviewSubTab">
            &nbsp; / &nbsp;
          </span>
          <span *ngIf="selectedReview && selectedReviewSubTab">
            {{ selectedReview.reviewDate }}
          </span>
        </div>

        <div class="chip-container" *ngIf="selectedReview">
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let subTab of reviewTabs"
              [selected]="selectedReviewSubTab === subTab.value"
              (click)="selectReviewSubTab(subTab.value)"
              selectable="true"
              [class]="
                selectedReviewSubTab === subTab.value
                  ? 'mat-chip-active'
                  : 'mat-chip-inactive'
              "
            >
              {{ subTab.label }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <mat-divider style="margin: 10px 0px"></mat-divider>
      </ng-container>

      <div>
        <ng-container *ngIf="!selectedPlan && selectedReviewSubTab === ''">
          <app-care-plans
            [case]="case"
            [patient]="patient"
            (selectedPlan)="onReviewPlanSelected($event)"
            [displayAddButton]="false"
            title="Select Plan"
          ></app-care-plans>
        </ng-container>

        <ng-container *ngIf="selectedPlan && selectedReviewSubTab === ''">
          <app-treatment-reviews
            [selectedPlan]="selectedPlan"
            (selectedReview)="onReviewSelected($event)"
          ></app-treatment-reviews>
        </ng-container>

        <ng-container
          *ngIf="selectedReview && selectedReviewSubTab === 'Profile'"
        >
          <app-treatment-review-profile
            [case]="case"
            [patient]="patient"
            [selectedReview]="selectedReview"
            [selectedPlan]="selectedPlan"
          ></app-treatment-review-profile>
        </ng-container>
        <ng-container *ngIf="selectedReview && selectedReviewSubTab === 'ASAM'">
          <app-case-asam-admission-ongoing
            [case]="case"
            [patient]="patient"
            recordType="Review"
            [selectedReview]="selectedReview"
          ></app-case-asam-admission-ongoing>
        </ng-container>
        <ng-container *ngIf="selectedReview && selectedReviewSubTab === 'Team'">
          <app-case-admission-care-team
            [case]="case"
            [patient]="patient"
            recordType="Review"
            [selectedReview]="selectedReview"
            [selectedPlan]="selectedPlan"
          ></app-case-admission-care-team>
        </ng-container>
        <ng-container
          *ngIf="selectedReview && selectedReviewSubTab === 'Comments'"
        >
          <app-treatment-review-comments
            [case]="case"
            [patient]="patient"
            [selectedReview]="selectedReview"
            [selectedPlan]="selectedPlan"
          ></app-treatment-review-comments>
        </ng-container>
        <ng-container
          *ngIf="selectedReview && selectedReviewSubTab === 'Plan Outline'"
        >
          <app-print-plan-treatment-report
            [selectedPlan]="selectedPlan"
          ></app-print-plan-treatment-report>
        </ng-container>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab label="Admission Discharge">
    <ng-container *ngIf="isDischargeTabSelected">
      <ng-container *ngIf="selectedProgram && selectedDischargeSubTab !== ''">
        <div style="margin-bottom: 20px">
          <span
            (click)="resetProgram()"
            style="color: #2196f3; cursor: pointer; font-weight: 500"
          >
            Admission Discharge
          </span>

          <span *ngIf="selectedProgram">
            &nbsp; / &nbsp; {{ selectedProgram.programName }}
          </span>
        </div>

        <div class="chip-container">
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let subTab of dischargeTabs"
              [selected]="selectedDischargeSubTab === subTab.value"
              (click)="selectDischargeSubTab(subTab.value)"
              selectable="true"
              [class]="
                selectedDischargeSubTab === subTab.value
                  ? 'mat-chip-active'
                  : 'mat-chip-inactive'
              "
            >
              {{ subTab.label }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <mat-divider style="margin: 10px 0px"></mat-divider>
      </ng-container>

      <div>
        <ng-container *ngIf="selectedDischargeSubTab === ''">
          <app-discharge
            [case]="case"
            [patient]="patient"
            [showAddButton]="false"
            (selectedProgram)="onProgramSelected($event)"
          ></app-discharge>
        </ng-container>

        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'Profile'"
        >
          <div class="row">
            <div class="col-md-12" style="padding: 15px">
              <app-discharge-profile
                [case]="case"
                [patient]="patient"
                [selectedProgram]="selectedProgram"
              ></app-discharge-profile>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'ASAM'"
        >
          <div class="row">
            <div class="col-md-12" style="padding: 15px">
              <app-case-asam-admission-ongoing
                [case]="case"
                [patient]="patient"
                [selectedProgram]="selectedProgram"
                recordType="Discharge"
              ></app-case-asam-admission-ongoing>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'Legal'"
        >
          <div class="row">
            <div class="col-md-12" style="padding: 15px">
              <app-discharge-legal
                [case]="case"
                [patient]="patient"
                [selectedProgram]="selectedProgram"
              ></app-discharge-legal>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'Status'"
        >
          <div class="row">
            <div class="col-md-12" style="padding: 15px">
              <app-discharge-status
                [case]="case"
                [patient]="patient"
                [selectedProgram]="selectedProgram"
              ></app-discharge-status>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'Substance'"
        >
          <div class="row">
            <div class="col-md-12" style="padding: 15px">
              <app-discharge-substance-use
                [case]="case"
                [patient]="patient"
                [selectedProgram]="selectedProgram"
              ></app-discharge-substance-use>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedProgram && selectedDischargeSubTab === 'Summary'"
        >
          <app-discharge-summary
            [case]="case"
            [patient]="patient"
            recordType="Discharge"
            [selectedProgram]="selectedProgram"
          ></app-discharge-summary>
        </ng-container>
      </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>
