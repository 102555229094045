<div style="margin: 10px; padding: 10px; padding-bottom: 40px">
  <div class="row">
    <div class="col-md-12" style="text-align: right">
      <label *ngIf="billingStatus === 'On Hold'" class="holdClaimDisclaimer">
        <mat-icon>back_hand</mat-icon>
        Claim is on Hold
      </label>
      <label *ngIf="billingStatus === 'Archived'" class="archivedDisclaimer">
        <mat-icon>inventory_2</mat-icon>
        Claim has been Archived
      </label>
      <button
        mat-raised-button
        type="button"
        style="color: #2196f3"
        (click)="printDialog()"
      >
        <mat-icon color="primary">print</mat-icon> View Notes
      </button>
    </div>
  </div>

  <form [formGroup]="sessionBillingForm" style="padding-top: 15px">
    <div class="row">
      <!-- Alerts -->
      <div
        class="col-md-12"
        style="margin-bottom: 25px"
        *ngIf="alerts.length > 0"
      >
        <mat-card
          appearance="raised"
          *ngFor="let alert of alerts; let i = index"
          class="alertCard mat-elevation-z8"
          style="margin-bottom: 10px"
        >
          <label class="labelText" style="color: red"> {{ alert }}</label>
        </mat-card>
      </div>

      <!-- Show External Errors -->
      <div
        class="col-md-12"
        style="margin-top: 15px; margin-bottom: 15px"
        *ngIf="externalClaimsErrors?.length > 0"
      >
        <div class="row">
          <div class="col-md-12" *ngFor="let error of externalClaimsErrors">
            <mat-card appearance="raised" class="alertCard mat-elevation-z8">
              <label class="labelText" style="color: red"> {{ error }}</label>
            </mat-card>
          </div>
        </div>
      </div>

      <!-- Billing Details on  Left Most  -->
      <div class="col-xl-4" style="margin-bottom: 15px">
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px; position: relative"
        >
          <!-- Action button for claims -->
          <div
            style="position: absolute; z-index: 2000; top: 0px; right: 0px"
            *ngIf="
              parentClass === 'Claims' &&
              hasClaimsEditAccess &&
              billingStatus != 'Archived'
            "
          >
            <button
              mat-icon-button
              id="editButton"
              type="button"
              style="opacity: 0.5"
              (click)="editProvider('Billing')"
            >
              <mat-icon style="font-size: 18px">edit </mat-icon>
            </button>
          </div>

          <label class="labelHeader"> Billing Details</label>
          <!-- Billing Provider Details -->
          <div class="twoGridContainer">
            <!-- Provider Name -->
            <label class="labelBold">Billing Provider</label>
            <label class="labelText">
              {{ therapySession?.billingProviderName }}
            </label>
            <!-- Provider Address -->
            <label class="labelBold">Address</label>
            <label class="labelText">
              {{ therapySession?.billingProviderAddressLine1 }}
              {{ therapySession?.billingProviderAddressLine2 }}
              <br />
              {{ therapySession?.billingProviderCity }},
              {{ therapySession?.billingProviderState }},
              {{ therapySession?.billingProviderZip }}
            </label>
            <!-- Provider Phone -->
            <label class="labelBold">Phone</label>
            <label class="labelText">
              <span *ngIf="therapySession?.billingProviderPhone">
                {{ therapySession?.billingProviderPhone | phoneFormat }}
              </span>
            </label>
            <!-- Provider NPI -->
            <label class="labelBold"> Group NPI</label>
            <label class="labelText">
              {{ therapySession?.billingProviderGroupNPI }}
            </label>
            <!-- Provider Taxonomy -->
            <label class="labelBold"> Taxonomy Code </label>
            <label class="labelText">
              {{ therapySession?.billingProviderTaxonomyCode }}
            </label>
            <!-- Tax Id -->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.billingProviderBillingOption === 'Tax Id'
              "
            >
              Tax Id
            </label>
            <label
              class="labelText"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.billingProviderBillingOption === 'Tax Id'
              "
            >
              {{ therapySession?.billingProviderTaxId }}
            </label>
            <!-- SSN-->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.billingProviderBillingOption === 'SSN'
              "
            >
              SSN
            </label>
            <label
              class="labelText"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.billingProviderBillingOption === 'SSN'
              "
            >
              {{ therapySession?.billingProviderSSN }}
            </label>
          </div>

          <!-- Extra Questions -->
          <!-- Billing Type -->
          <label class="labelBold" style="margin-top: 25px">
            Billing Type <span style="color: red">*</span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="billingType"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="billingTypeChanged($event, true)"
          >
            <mat-radio-button value="Self-Pay"> Self-Pay </mat-radio-button>
            <mat-radio-button value="Insurance"> Insurance </mat-radio-button>
          </mat-radio-group>
          <!-- Place Of Service -->
          <div style="margin-top: 12px; margin-bottom: 12px">
            <label class="labelBold">
              Place of Service Code
              <span style="opacity: 0.5; font-size: 12px">
                (Box 24b on the CMS1500)
              </span>
              <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Place of Service Code"
                formControlName="billingProviderPOSCode"
                (selectionChange)="updateBilling(false)"
              >
                <mat-option
                  *ngFor="let pos of placeOfService"
                  [value]="pos.code"
                >
                  {{ pos.code }} - {{ pos.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  sessionBillingForm.controls['billingProviderPOSCode'].invalid
                "
              >
                Please select the place of service code
              </mat-error>
            </mat-form-field>
          </div>
          <!-- emergency service -->
          <label class="labelBold">
            Is this an emergency service?
            <span style="opacity: 0.5; font-size: 12px">
              (Box 24c on the CMS1500)
            </span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="emgType"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="updateBilling(false)"
          >
            <mat-radio-button value="NA"> NA </mat-radio-button>
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>
          <!-- EPSDT -->
          <div class="row">
            <div class="col-md-6" style="margin-top: 12px">
              <label class="labelBold">
                EPSDT
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 24H on the CMS1500)
                </span>
              </label>
              <br />
              <mat-radio-group
                aria-label="Select an option"
                formControlName="epsdtFamilyPlan"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
                (change)="epsdtTypeChanged($event)"
              >
                <mat-radio-button value="NA"> NA </mat-radio-button>
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>
            <div
              class="col-md-6"
              style="margin-bottom: 12px"
              *ngIf="
                sessionBillingForm.controls['epsdtFamilyPlan'].value === 'Yes'
              "
            >
              <label class="labelBold">
                EPSDT Condition Indicator <span style="color: red">*</span>
                <span style="opacity: 0.5; font-size: 12px">
                  (Shaded area of Box 24H on the CMS1500)
                </span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="epsdtType"
                  placeholder=""
                  (selectionChange)="updateBilling(false)"
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    [value]="condition.id"
                    *ngFor="let condition of epsdtConditionTypes"
                  >
                    {{ condition.id }} - {{ condition.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="sessionBillingForm.controls['epsdtType'].invalid"
                >
                  Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Is the Member Condition Related To -->
          <label class="labelBold" style="margin-top: 12px">
            Is the Member Condition Related To
            <span style="opacity: 0.5; font-size: 12px">
              (Box 10 on the CMS1500)
            </span>
          </label>
          <!-- Employment -->
          <div style="margin-top: 12px; padding-left: 45px">
            <label class="labelBold">
              Employment
              <span style="opacity: 0.5; font-size: 12px">
                (Box 10a on the CMS1500)
              </span>
            </label>
            <br />
            <mat-radio-group
              aria-label="Select an option"
              formControlName="conditionRTEmployment"
              style="column-gap: 15px; display: flex; flex-wrap: wrap"
              color="primary"
              (change)="conditionRTEmploymentChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No"> No </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Auto Accident -->
          <div style="margin-top: 12px; padding-left: 45px">
            <div class="row">
              <!-- General Question -->
              <div class="col">
                <label class="labelBold">
                  Auto Accident
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 10b on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="conditionRTAutoAccident"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="autoAccidentTypeChanged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- State Question -->
              <div
                class="col"
                *ngIf="
                  sessionBillingForm.controls['conditionRTAutoAccident']
                    .value === 'Yes'
                "
              >
                <label class="labelText">
                  Place (State) <span style="color: red">*</span></label
                >
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="conditionRTAutoState"
                    placeholder="State"
                    (selectionChange)="updateBilling(false)"
                  >
                    <mat-option
                      [value]="state.id"
                      *ngFor="let state of usStateList"
                    >
                      {{ state.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      sessionBillingForm.controls['conditionRTAutoState']
                        .invalid
                    "
                  >
                    Please select the state
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Date Question -->
              <div
                class="col"
                *ngIf="
                  sessionBillingForm.controls['conditionRTAutoAccident']
                    .value === 'Yes' ||
                  sessionBillingForm.controls['conditionRTOtherAccident']
                    .value === 'Yes' ||
                  sessionBillingForm.controls['conditionRTEmployment'].value ===
                    'Yes'
                "
              >
                <label class="labelText">
                  Accident Date <span style="color: red">*</span>
                </label>

                <mat-form-field appearance="outline">
                  <input
                    formControlName="conditionRTAutoDate"
                    matInput
                    [matDatepicker]="accidentDatePicker"
                    placeholder="Date"
                    [max]="currentTime"
                    autoSlashDate
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="accidentDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #accidentDatePicker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      sessionBillingForm.controls['conditionRTAutoDate'].invalid
                    "
                  >
                    Please select a valid date
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Qualifier Question -->
              <div
                class="col"
                *ngIf="
                  sessionBillingForm.controls['conditionRTAutoAccident']
                    .value === 'Yes'
                "
              >
                <label class="labelText">
                  Qualifier <span style="color: red">*</span></label
                >
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="conditionRTAutoQualifier"
                    placeholder="Qualifier"
                    (selectionChange)="updateBilling(false)"
                  >
                    <mat-option
                      [value]="qualifier.id"
                      *ngFor="let qualifier of autoAccidentQualifierList"
                    >
                      {{ qualifier.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      sessionBillingForm.controls['conditionRTAutoQualifier']
                        .invalid
                    "
                  >
                    Please select the qualifier
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- Other Accident -->
          <div class="col-md-12" style="margin-top: 12px; padding-left: 45px">
            <label class="labelBold">
              Other Accident
              <span style="opacity: 0.5; font-size: 12px">
                (Box 10c on the CMS1500)
              </span>
            </label>
            <br />
            <mat-radio-group
              aria-label="Select an option"
              formControlName="conditionRTOtherAccident"
              style="column-gap: 15px; display: flex; flex-wrap: wrap"
              color="primary"
              (change)="otherAccidentTypeChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No"> No </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Resubmission code -->
          <div
            style="margin-top: 12px; margin-bottom: 12px"
            *ngIf="claimSubmittedToPayer"
          >
            <label class="labelBold">
              Resubmission Code
              <span style="opacity: 0.5; font-size: 12px">
                (Box 22 on the CMS1500)
              </span>
              <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Resubmission code"
                formControlName="resubmissionCode"
                (selectionChange)="updateBilling(false)"
              >
                <mat-option
                  *ngFor="let code of resubmissionCodesList"
                  [value]="code.id"
                >
                  {{ code.id }} - {{ code.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="sessionBillingForm.controls['resubmissionCode'].invalid"
              >
                Please select the resubmission code
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card>
      </div>

      <!-- Provider & Prior Auth Details on  Middle -->
      <div
        class="col-xl-4"
        style="
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 20px;
        "
      >
        <!-- Provider Details -->
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px; position: relative"
        >
          <!-- Action button for claims -->
          <div
            style="position: absolute; right: 0px; top: 0; z-index: 2000"
            *ngIf="
              parentClass === 'Claims' &&
              hasClaimsEditAccess &&
              billingStatus != 'Archived'
            "
          >
            <button
              mat-icon-button
              id="editButton"
              type="button"
              style="opacity: 0.5"
              (click)="editProvider('Rendering')"
            >
              <mat-icon style="font-size: 18px">edit </mat-icon>
            </button>
          </div>

          <label class="labelHeader"> Provider Details</label>
          <div class="twoGridContainer">
            <!-- Provider Name -->
            <label class="labelBold">Provider Name</label>
            <label class="labelText">
              {{ therapySession.physicianLastName }},{{ ' ' }}
              {{ therapySession.physicianFirstName }}{{ ' ' }}
              {{ therapySession.physicianMiddleName }}
            </label>
            <!-- Provider Email -->
            <label class="labelBold">Provider Email</label>
            <label class="labelText">
              {{ therapySession.physicianEmail }}
            </label>
            <!-- Provider Phone -->
            <label class="labelBold">Provider Phone</label>
            <label class="labelText">
              <span *ngIf="therapySession.physicianPhone">
                {{ therapySession?.physicianPhone | phoneFormat }}
              </span>
            </label>
            <!-- Provider NPI -->
            <label class="labelBold">NPI</label>
            <label class="labelText">
              <span *ngIf="therapySession.physicianPhone">
                {{ therapySession?.physicianNPI }}
              </span>
            </label>
            <!-- Provider NPI -->
            <label class="labelBold">Taxonomy</label>
            <label class="labelText">
              <span *ngIf="therapySession.physicianPhone">
                {{ therapySession?.physicianTaxonomyCode }}
              </span>
            </label>
            <!-- Provider Tax Id -->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.physicianBillingOption === 'Tax Id'
              "
            >
              Tax Id
            </label>
            <label
              class="labelText"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.physicianBillingOption === 'Tax Id'
              "
            >
              {{ therapySession?.physicianTaxId }}
            </label>
            <!-- Provider SSN -->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.physicianBillingOption === 'SSN'
              "
            >
              SSN
            </label>
            <label
              class="labelText"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.physicianBillingOption === 'SSN'
              "
            >
              {{ therapySession?.physicianSSN }}
            </label>
          </div>

          <!-- Asking if there is supervising Provider -->
          <!-- Supervising RP -->
          <label class="labelBold" style="margin-top: 25px">
            Is there a supervising rendering provider?
            <span style="color: red">*</span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="supervisingRenderingProvider"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="supervisingProviderChanged($event)"
          >
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>

          <!-- Add Edit Supervisor button -->
          <div
            style="text-align: right"
            *ngIf="
              ((parentClass === 'Session' &&
                therapySession.status === 'Active' &&
                hasBillingEditAccess) ||
                (parentClass === 'Claims' &&
                  hasClaimsEditAccess &&
                  billingStatus != 'Archived')) &&
              sessionBillingForm.controls['supervisingRenderingProvider']
                .value === 'Yes'
            "
          >
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="editSupervisingProvider()"
            >
              Edit Supervising Provider
            </button>
          </div>
          <!-- SuperVising Provider Details -->
          <label
            class="labelHeader"
            style="margin-top: 15px"
            *ngIf="
              sessionBillingForm.controls['supervisingRenderingProvider']
                .value === 'Yes'
            "
          >
            Supervisor Details
          </label>
          <!-- SuperVising Provider Details -->
          <div
            class="twoGridContainer"
            *ngIf="
              sessionBillingForm.controls['supervisingRenderingProvider']
                .value === 'Yes'
            "
          >
            <!-- Supervisor Name -->
            <label class="labelBold">Name</label>
            <label class="labelText">
              <span *ngIf="therapySession.supervisingRPLastName">
                {{ therapySession.supervisingRPLastName }},{{ ' ' }}
                {{ therapySession.supervisingRPFirstName }}{{ ' ' }}
                {{ therapySession.supervisingRPMiddleName }}
              </span>
            </label>
            <!-- Supervisor Email -->
            <label class="labelBold">Email</label>
            <label class="labelText">
              {{ therapySession.supervisingRPEmail }}
            </label>
            <!-- Supervisor Phone -->
            <label class="labelBold">Phone</label>
            <label class="labelText">
              <span *ngIf="therapySession.supervisingRPPhone">
                {{ therapySession?.supervisingRPPhone | phoneFormat }}
              </span>
            </label>
            <!-- Supervisor NPI -->
            <label class="labelBold">NPI</label>
            <label class="labelText">
              <span *ngIf="therapySession.supervisingRPNPI">
                {{ therapySession?.supervisingRPNPI }}
              </span>
            </label>
            <!-- Supervisor NPI -->
            <label class="labelBold">Taxonomy</label>
            <label class="labelText">
              <span *ngIf="therapySession.supervisingRPTaxonomyCode">
                {{ therapySession?.supervisingRPTaxonomyCode }}
              </span>
            </label>
            <!-- Supervisor Tax ID -->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.supervisingRPBillingOption === 'Tax Id'
              "
            >
              Tax Id
            </label>
            <label class="labelText">
              {{ therapySession?.supervisingRPTaxId }}
            </label>
            <!-- Supervisor SSN -->
            <label
              class="labelBold"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.supervisingRPBillingOption === 'SSN'
              "
            >
              SSN
            </label>
            <label
              class="labelText"
              *ngIf="
                parentClass === 'Claims' &&
                therapySession?.supervisingRPBillingOption === 'SSN'
              "
            >
              {{ therapySession?.supervisingRPSSN }}
            </label>
          </div>

          <!-- Asking if there is Referring Provider -->
          <label class="labelBold" style="margin-top: 25px">
            Is there a referring provider?
            <span style="color: red">*</span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="referringProvider"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="referringProviderChanged($event)"
          >
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>

          <!-- Add Edit Referring Provider button -->
          <div
            *ngIf="
              ((parentClass === 'Session' &&
                therapySession.status === 'Active' &&
                hasBillingEditAccess) ||
                (parentClass === 'Claims' &&
                  hasClaimsEditAccess &&
                  billingStatus != 'Archived')) &&
              sessionBillingForm.controls['referringProvider'].value === 'Yes'
            "
            style="text-align: right"
          >
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="selectReferringProvider()"
            >
              Select Referring Provider
            </button>
          </div>
          <!-- Referring Provider Details -->
          <label
            class="labelHeader"
            style="margin-top: 15px"
            *ngIf="
              sessionBillingForm.controls['referringProvider'].value === 'Yes'
            "
          >
            Referring Provider Details
          </label>
          <!-- Referring Provider Details -->
          <div
            class="twoGridContainer"
            *ngIf="
              sessionBillingForm.controls['referringProvider'].value === 'Yes'
            "
          >
            <!-- Referring Provider Name -->
            <label class="labelBold">Name</label>
            <label class="labelText">
              <span *ngIf="therapySession.referringProviderLastName">
                {{ therapySession.referringProviderLastName }},{{ ' ' }}
                {{ therapySession.referringProviderFirstName }}{{ ' ' }}
                {{ therapySession.referringProviderMiddleName }}
              </span>
            </label>
            <!-- Referring Provider NPI -->
            <label class="labelBold">NPI</label>
            <label class="labelText">
              <span *ngIf="therapySession.referringProviderNPI">
                {{ therapySession?.referringProviderNPI }}
              </span>
            </label>
            <!-- Supervisor NPI -->
            <label class="labelBold">Taxonomy</label>
            <label class="labelText">
              <span *ngIf="therapySession.referringProviderTaxonomy">
                {{ therapySession?.referringProviderTaxonomy }}
              </span>
            </label>
          </div>
        </mat-card>

        <!-- Prior Auth Details -->
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <label class="labelHeader"> Prior Authorization</label>
          <label class="labelBold">
            Is there a Prior Authorization Code?
            <span style="opacity: 0.5; font-size: 12px">
              (Box 23 on the CMS1500)
            </span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="priorAuthCode"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="priorAuthChanged($event)"
          >
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>

          <!-- IF there is a prior authorization code -->
          <div
            *ngIf="sessionBillingForm.controls['priorAuthCode'].value === 'Yes'"
          >
            <!-- If primary insurance is not selected -->
            <div
              *ngIf="
                !sessionBillingForm.controls['primaryInsuranceDetails'].value
              "
            >
              <label style="font-weight: 450; font-style: italic">
                * Please first select the primary insurance to view the list of
                available prior authorizations.
              </label>
            </div>
            <!-- If primary insurance is  selected -->
            <div
              class="row"
              *ngIf="
                sessionBillingForm.controls['primaryInsuranceDetails'].value
              "
            >
              <div
                style="text-align: right"
                *ngIf="
                  (parentClass === 'Session' &&
                    therapySession.status === 'Active' &&
                    hasBillingEditAccess) ||
                  (parentClass === 'Claims' &&
                    hasClaimsEditAccess &&
                    billingStatus != 'Archived')
                "
              >
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  (click)="lookUpPriorAuth()"
                >
                  <i class="material-icons">search</i> Lookup Prior Auth
                </button>
              </div>
              <div
                *ngIf="sessionBillingForm.controls['priorAuthDetails'].value"
                style="margin-top: 15px"
              >
                <div class="gridContainer">
                  <label class="labelBold"> Prior Auth # </label>
                  <label class="labelText">
                    {{
                      sessionBillingForm.controls['priorAuthDetails'].value
                        .authNumber
                    }}
                  </label>
                  <label class="labelBold"> Effective Start </label>
                  <label class="labelText">
                    {{
                      sessionBillingForm.controls['priorAuthDetails'].value
                        .effectiveStartDate | date : 'MM/dd/y'
                    }}
                  </label>
                  <label class="labelBold"> Effective End </label>
                  <label class="labelText">
                    {{
                      sessionBillingForm.controls['priorAuthDetails'].value
                        .effectiveEndDate | date : 'MM/dd/y'
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Insurance Details on Right Most -->
      <div class="col-xl-4">
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px; margin-bottom: 15px"
        >
          <label class="labelHeader"> Primary Insurance Details</label>
          <!-- Add Button -->
          <div
            style="text-align: right"
            *ngIf="
              (parentClass === 'Session' &&
                therapySession.status === 'Active' &&
                hasBillingEditAccess) ||
              (parentClass === 'Claims' &&
                hasClaimsEditAccess &&
                billingStatus != 'Archived')
            "
          >
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="searchInsurance('Primary')"
            >
              + Add Primary Insurance
            </button>
          </div>

          <!-- // For Primary Insurance type and indicator -->
          <div
            class="row"
            *ngIf="sessionBillingForm.controls['primaryInsuranceDetails'].value"
          >
            <div class="col-md-12">
              <label class="labelText">
                Type of Health Insurance
                <span style="opacity: 0.5; font-size: 12px">
                  (HCFA 1500 Box 1)
                </span>
                <span style="color: red" *ngIf="parentClass === 'Claims'">
                  *
                </span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="primaryHealthInsuranceType"
                  (selectionChange)="updateBilling(false)"
                >
                  <mat-option
                    *ngFor="let insuranceType of healthInsuranceTypeList"
                    [value]="insuranceType.id"
                  >
                    {{ insuranceType.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    sessionBillingForm.controls['primaryHealthInsuranceType']
                      .invalid
                  "
                >
                  Please select the health insurance type
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <label class="labelText">
                Claim Filing Indicator
                <span style="color: red" *ngIf="parentClass === 'Claims'">
                  *
                </span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="primaryClaimFilingIndicator"
                  (selectionChange)="updateBilling(false)"
                >
                  <mat-option
                    *ngFor="let indicatorType of claimFilingIndicatorList"
                    [value]="indicatorType.id"
                  >
                    {{ indicatorType.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    sessionBillingForm.controls['primaryClaimFilingIndicator']
                      .invalid
                  "
                >
                  Please select the claim indicator type
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Display Insurance -->
          <div
            style="margin-top: 15px; margin-bottom: 15px; position: relative"
          >
            <!-- Insurance Information -->
            <app-insurance-card
              [insurance]="
                sessionBillingForm.controls['primaryInsuranceDetails'].value
              "
              [patient]="patient"
              parentClass="Session"
              [therapySession]="therapySession"
            ></app-insurance-card>
            <!-- Insurance Action -->
            <div
              style="position: absolute; right: 25px; top: 20px"
              *ngIf="
                sessionBillingForm.controls['primaryInsuranceDetails'].value &&
                ((parentClass === 'Session' &&
                  therapySession.status === 'Active' &&
                  hasBillingEditAccess) ||
                  (parentClass === 'Claims' &&
                    hasClaimsEditAccess &&
                    billingStatus != 'Archived'))
              "
            >
              <mat-icon
                style="color: red; cursor: pointer"
                (click)="removeInsurance('Primary')"
              >
                delete_forever
              </mat-icon>
            </div>
          </div>
          <mat-error
            *ngIf="
              !sessionBillingForm.controls['primaryInsuranceDetails'].value
            "
          >
            Please select/add a primary insurance
          </mat-error>
        </mat-card>

        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px; margin-bottom: 15px"
        >
          <label class="labelHeader"> Secondary Insurance Details</label>
          <!-- Another Insurance -->
          <label class="labelBold" style="margin-top: 25px">
            Is there another Health Benefit Plan?
            <span style="opacity: 0.5; font-size: 12px">
              (Box 11d on the CMS1500)
            </span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="anotherHealthPlan"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="secondaryInsuranceChaged($event)"
          >
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>
          <!-- Secondary Insurance -->
          <div
            style="
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              margin-top: 15px;
            "
            *ngIf="
              sessionBillingForm.controls['anotherHealthPlan'].value === 'Yes'
            "
          >
            <!-- Add Button -->
            <div
              style="text-align: right"
              *ngIf="
                (parentClass === 'Session' &&
                  therapySession.status === 'Active' &&
                  hasBillingEditAccess) ||
                (parentClass === 'Claims' &&
                  hasClaimsEditAccess &&
                  billingStatus != 'Archived')
              "
            >
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="searchInsurance('Secondary')"
              >
                + Add Secondary Insurance
              </button>
            </div>

            <!-- // For Primary Insurance type and indicator -->
            <div
              class="row"
              *ngIf="
                sessionBillingForm.controls['secondaryInsuranceDetails'].value
              "
            >
              <div class="col-md-12">
                <label class="labelText">
                  Type of Health Insurance
                  <span style="color: red" *ngIf="parentClass === 'Claims'">
                    *
                  </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="secondaryHealthInsuranceType"
                    (selectionChange)="updateBilling(false)"
                  >
                    <mat-option
                      *ngFor="let insuranceType of healthInsuranceTypeList"
                      [value]="insuranceType.id"
                    >
                      {{ insuranceType.description }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      sessionBillingForm.controls[
                        'secondaryHealthInsuranceType'
                      ].invalid
                    "
                  >
                    Please select the health insurance type
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <label class="labelText">
                  Claim Filing Indicator
                  <span style="color: red" *ngIf="parentClass === 'Claims'">
                    *
                  </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="secondaryClaimFilingIndicator"
                    (selectionChange)="updateBilling(false)"
                  >
                    <mat-option
                      *ngFor="let indicatorType of claimFilingIndicatorList"
                      [value]="indicatorType.id"
                    >
                      {{ indicatorType.description }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      sessionBillingForm.controls[
                        'secondaryClaimFilingIndicator'
                      ].invalid
                    "
                  >
                    Please select the claim indicator type
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Display Secondary Insurance -->
            <div
              style="margin-top: 15px; margin-bottom: 15px; position: relative"
            >
              <!-- Insurance Information -->
              <app-insurance-card
                [insurance]="
                  sessionBillingForm.controls['secondaryInsuranceDetails'].value
                "
                [patient]="patient"
                parentClass="Session"
                [therapySession]="therapySession"
              ></app-insurance-card>
              <!-- Insurance Action -->
              <div
                style="position: absolute; right: 25px; top: 20px"
                *ngIf="
                  sessionBillingForm.controls['secondaryInsuranceDetails']
                    .value &&
                  ((parentClass === 'Session' &&
                    therapySession.status === 'Active' &&
                    hasBillingEditAccess) ||
                    (parentClass === 'Claims' &&
                      hasClaimsEditAccess &&
                      billingStatus != 'Archived'))
                "
              >
                <mat-icon
                  style="color: red; cursor: pointer"
                  (click)="removeInsurance('Secondary')"
                >
                  delete_forever
                </mat-icon>
              </div>
            </div>
            <mat-error
              *ngIf="
                sessionBillingForm.controls['secondaryInsuranceDetails'].invalid
              "
            >
              Please select/add a secondary insurance
            </mat-error>
          </div>
        </mat-card>
      </div>

      <!-- Lower Cards -->
      <div
        class="col-md-12"
        style="
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 20px;
        "
      >
        <!-- Diagnosis Details-->
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <label class="labelHeader"> Diagnosis Codes (Maximum 12)</label>

          <!-- Add Diagnosis Button -->
          <div
            style="text-align: right; margin-top: 10px; margin-bottom: 10px"
            *ngIf="
              sessionBillingForm.controls['diagnosisCodes'].value.length < 12 &&
              ((parentClass === 'Session' &&
                therapySession.status === 'Active' &&
                hasBillingEditAccess) ||
                (parentClass === 'Claims' &&
                  hasClaimsEditAccess &&
                  billingStatus != 'Archived'))
            "
          >
            <button mat-raised-button color="warn" (click)="addDiagnosis()">
              Add Diagnosis
            </button>
          </div>

          <!-- No Diagnosis Message -->
          <div
            *ngIf="
              sessionBillingForm.controls['diagnosisCodes'].value.length == 0
            "
          >
            <mat-error>No diagnosis code has been assigned.</mat-error>
          </div>

          <!-- Display Diagnosis -->
          <div
            class="table-responsive"
            *ngIf="
              sessionBillingForm.controls['diagnosisCodes'].value.length > 0
            "
          >
            <table
              mat-table
              [dataSource]="sessionBillingForm.controls['diagnosisCodes'].value"
            >
              <ng-container matColumnDef="diagnosisIndex">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <b>{{ row?.diagnosisIndex }}</b>
                </td>
              </ng-container>
              <ng-container matColumnDef="moveArrows">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let row; let i = index"
                  style="column-gap: 15px"
                >
                  <button
                    mat-mini-fab
                    (click)="moveDiagnosis('UP', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_upward </mat-icon>
                  </button>
                  <button
                    mat-mini-fab
                    (click)="moveDiagnosis('DOWN', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_downward </mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="diagnosisCode">
                <th mat-header-cell *matHeaderCellDef>ICD Code</th>
                <td mat-cell *matCellDef="let row" style="max-width: 350px">
                  {{ row.icdCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="diagnosisDescription">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.shortDesc }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeDiagnosis(i)"
                    type="button"
                  >
                    <mat-icon> cancel </mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="diagnosisColumn"></tr>
              <tr mat-row *matRowDef="let row; columns: diagnosisColumn"></tr>
            </table>
          </div>
        </mat-card>

        <!-- Charges Details-->
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <label class="labelHeader"> Charges (Maximum 6) </label>
          <!-- Add Button -->
          <div
            style="text-align: right; margin-top: 10px; margin-bottom: 10px"
            *ngIf="
              sessionBillingForm.controls['charges'].value.length < 6 &&
              ((parentClass === 'Session' &&
                therapySession.status === 'Active' &&
                hasBillingEditAccess) ||
                (parentClass === 'Claims' &&
                  hasClaimsEditAccess &&
                  billingStatus != 'Archived'))
            "
          >
            <button mat-raised-button color="warn" (click)="addCharge()">
              Add Charge
            </button>
          </div>
          <!-- No Charge Message -->
          <div
            *ngIf="
              sessionBillingForm.controls['charges'].value.length == 0 &&
              therapySession.status === 'Active'
            "
          >
            <mat-error> No charge has been added. </mat-error>
          </div>
          <!-- Charge Display -->
          <div
            class="table-responsive"
            *ngIf="sessionBillingForm.controls['charges'].value.length > 0"
          >
            <table
              mat-table
              [dataSource]="sessionBillingForm.controls['charges'].value"
            >
              <ng-container matColumnDef="cptIndex">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <b>{{ row.cptIndex }}</b>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="moveArrows">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let row; let i = index"
                  style="column-gap: 15px"
                >
                  <button
                    mat-mini-fab
                    (click)="moveCharge('UP', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_upward </mat-icon>
                  </button>
                  <button
                    mat-mini-fab
                    (click)="moveCharge('DOWN', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_downward </mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="cptCode">
                <th mat-header-cell *matHeaderCellDef>Procedure/CPT Code</th>
                <td mat-cell *matCellDef="let row" style="max-width: 350px">
                  {{ row.cptCode }}
                  <span *ngIf="row.description"> - {{ row.description }}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m1">
                <th mat-header-cell *matHeaderCellDef>M1</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier1 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m2">
                <th mat-header-cell *matHeaderCellDef>M2</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier2 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m3">
                <th mat-header-cell *matHeaderCellDef>M3</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier3 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m4">
                <th mat-header-cell *matHeaderCellDef>M4</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier4 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="diagPointer">
                <th mat-header-cell *matHeaderCellDef>Diagnosis Pointer</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.diagnosisPointer }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Rate</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.rate | currency : 'USD' }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef>Units</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.defaultUnit }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                  Total
                </td>
              </ng-container>
              <ng-container matColumnDef="charges">
                <th mat-header-cell *matHeaderCellDef>Charges</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.charges | currency : 'USD' }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                  {{ getTotal() | currency : 'USD' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <button
                    mat-icon-button
                    (click)="editCharge(row, i)"
                    type="button"
                  >
                    <mat-icon style="color: #a8a8a8; font-size: 20px">
                      edit
                    </mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeCharge(i)"
                    type="button"
                  >
                    <mat-icon> cancel </mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chargesColumn"></tr>
              <tr mat-row *matRowDef="let row; columns: chargesColumn"></tr>
              <tr mat-footer-row *matFooterRowDef="chargesColumn"></tr>
            </table>
          </div>
        </mat-card>
      </div>

      <!-- Update Button -->
      <div
        class="col-md-12"
        style="
          display: flex;
          justify-content: right;
          margin-top: 15px;
          gap: 25px;
        "
        *ngIf="
          (parentClass === 'Session' &&
            therapySession.status === 'Active' &&
            hasBillingEditAccess) ||
          (parentClass === 'Claims' &&
            hasClaimsEditAccess &&
            billingStatus != 'Archived')
        "
      >
        <!-- <button mat-raised-button type="button" (click)="restore()">
          <i class="material-icons">history</i> Restore
        </button> -->

        <button
          *ngIf="
            parentClass === 'Claims' &&
            hasClaimsEditAccess &&
            billingStatus === 'Rejected'
          "
          mat-raised-button
          type="button"
          (click)="archiveClaim()"
          [disabled]="processing"
        >
          <i class="material-icons">inventory_2</i> Archive Claim
        </button>

        <button
          mat-raised-button
          type="button"
          (click)="markOnHold()"
          [disabled]="processing"
          *ngIf="
            parentClass === 'Claims' &&
            hasClaimsEditAccess &&
            (billingStatus === 'In Draft' || billingStatus === 'Rejected')
          "
        >
          <i class="material-icons">back_hand</i> Mark On Hold
        </button>

        <button
          *ngIf="
            (parentClass === 'Session' &&
              therapySession.status === 'Active' &&
              hasBillingEditAccess) ||
            (parentClass === 'Claims' &&
              (billingStatus === 'In Draft' ||
                billingStatus === 'On Hold' ||
                billingStatus === 'Rejected') &&
              hasClaimsEditAccess)
          "
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="sessionBillingForm.invalid || processing"
          (click)="updateBilling(true)"
        >
          <i class="material-icons">save</i> Update
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>

        <button
          mat-raised-button
          color="warn"
          type="button"
          [disabled]="sessionBillingForm.invalid || processing"
          *ngIf="parentClass === 'Claims' && billingStatus != 'Archived'"
          (click)="sendClaims()"
        >
          <i class="material-icons">send</i>
          {{
            billingStatus === 'In Draft' || billingStatus === 'On Hold'
              ? 'Submit Claim'
              : 'Re-submit Claim'
          }}
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>

      <div
        class="col-md-12"
        style="
          display: flex;
          justify-content: right;
          margin-top: 15px;
          gap: 25px;
        "
        *ngIf="
          parentClass === 'Claims' &&
          hasClaimsEditAccess &&
          billingStatus === 'Archived'
        "
      >
        <button
          mat-raised-button
          color="warn"
          type="button"
          (click)="unArchiveClaim()"
          [disabled]="processing"
        >
          <i class="material-icons">widgets</i> UnArchive Claim
        </button>
      </div>
    </div>
  </form>
</div>
