import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { IMemberGoal } from 'src/app/_models/assessments/patientassessmentgoal.model';
import { ToastMessageService } from 'src/app/_services/';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-add-edit-session-goal',
  templateUrl: './add-edit-session-goal.component.html',
  styleUrls: ['./add-edit-session-goal.component.css'],
})
export class AddEditSessionGoalComponent implements OnInit {
  public patientAssessmentGoalForm: FormGroup;

  action: string = '';
  loggedInUserId: string = '';
  goal: any;
  therapySession: ITherapySession;
  todaysDate: Date = new Date();
  patientAssessmentGoal: IMemberGoal;
  processing: boolean = false;
  showFollowUp: boolean = false;
  patient: Patient;

  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditSessionGoalComponent>,
    private formBuilder: FormBuilder,
    private goalsApiService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.action = data.action;
      if (
        data.action === 'ADDEXISTINGGOAL' ||
        data.action === 'EDITEXISTINGGOAL'
      ) {
        this.goal = data.goal;
      }
      this.loggedInUserId = data.loggedInUserId;
      this.therapySession = data.therapySession;
      this.patient = data.patient;
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (
      this.action === 'ADDEXISTINGGOAL' ||
      this.action === 'EDITEXISTINGGOAL'
    ) {
      if (this.action === 'EDITEXISTINGGOAL') {
        this.patientAssessmentGoalForm.controls['id'].setValue(this.goal.id);
        this.patientAssessmentGoalForm.controls['careProviderId'].setValue(
          this.goal.careProviderId
        );
      }

      this.patientAssessmentGoalForm.controls['description'].setValue(
        this.goal.description
      );

      for (var objective of this.goal.objectives) {
        this.createObjective(
          objective.objectiveDescription,
          objective?.targetDate ? objective.targetDate : null,
          objective?.status
        );
      }
    } else {
      this.createObjective('', null, 'Open');
    }
  }

  buildForm() {
    this.patientAssessmentGoalForm = this.formBuilder.group({
      id: new FormControl(null),

      organizationId: new FormControl(this.therapySession.organizationId),
      patientId: new FormControl(this.patient.id),
      patientFirstName: new FormControl(this.patient.firstName),
      patientMiddleName: new FormControl(this.patient.middleName),
      patientLastName: new FormControl(this.patient.lastName),

      careProviderId: new FormControl(this.loggedInUserId),
      sessionId: new FormControl(this.therapySession.id),
      sessionCode: new FormControl(this.therapySession.sessionCode),
      requestorId: new FormControl(this.therapySession.physicianId),
      requestorName: new FormControl(
        this.therapySession.physicianFirstName +
          ' ' +
          this.therapySession.physicianLastName
      ),

      description: new FormControl(
        { value: '', disabled: this.patient.status != 'Active' },
        Validators.compose([Validators.required])
      ),

      objectives: this.formBuilder.array([]),
      status: new FormControl('Active', Validators.required),
    });
  }

  updateGoal() {
    this.patientAssessmentGoal = Object.assign({}, this.patientAssessmentGoal);
    this.patientAssessmentGoal = Object.assign(
      this.patientAssessmentGoal,
      this.patientAssessmentGoalForm.value
    );

    this.processing = true;

    if (this.action === 'EDITEXISTINGGOAL') {
      this.goalsApiService
        .updateMemberGoal(this.patientAssessmentGoal)
        .subscribe(
          (response) => {
            this.processing = false;

            this.toastMessageService.displaySuccessMessage(
              'Goal has been updated'
            );
            this.dialogRef.close('success');
          },
          () => {
            this.processing = false;

            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the goal'
            );
          }
        );
    } else {
      this.goalsApiService.addMemberGoal(this.patientAssessmentGoal).subscribe(
        (response) => {
          this.processing = false;

          this.toastMessageService.displaySuccessMessage('Goal has been added');
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;

          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add the goal'
          );
        }
      );
    }
  }

  objectives(): FormArray {
    return this.patientAssessmentGoalForm.get('objectives') as FormArray;
  }

  createObjective(objectiveDesc: string, targetDate: Date, status: string) {
    let objective = new FormGroup({
      objectiveDescription: new FormControl(
        { value: objectiveDesc, disabled: this.patient.status != 'Active' },
        Validators.required
      ),
      targetDate: new FormControl(targetDate, Validators.required),
      status: new FormControl(status, Validators.required),
    });
    this.objectives().push(objective);
  }

  removeObjective(index: number) {
    let currentObjectiveSize = this.objectives().value.length;
    if (currentObjectiveSize == 1) {
      this.toastMessageService.displayInfoMessage(
        'A minimum of 1 objective is required'
      );
      return;
    }
    this.objectives().removeAt(index);
  }
}
