import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DataTablesModel } from 'src/app/_models/data-tables-modal';
import { IReferral } from 'src/app/_models/referrals/referral.model';
import { PatientInsuranceApiService } from 'src/app/_services/patient/patient-insurance.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { npiValidator } from 'src/app/shared/utilities/validators/npi-validator';

@Component({
  selector: 'app-manual-eligibility-modal',
  templateUrl: './manual-eligibility-modal.component.html',
  styleUrl: './manual-eligibility-modal.component.css',
})
export class ManualEligibilityModalComponent implements OnInit {
  public eligibilityForm: FormGroup;

  today = new Date();
  searchPayer: string = '';
  insuranceList: DataTablesModel = {} as DataTablesModel;
  processing = false;

  // For prepopulating
  referralInformation: IReferral;
  displayTitle: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ManualEligibilityModalComponent>,
    private formBuilder: FormBuilder,
    private patientInsuranceApiService: PatientInsuranceApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.displayTitle = data?.displayTitle;
    this.referralInformation = data?.referralInformation;
  }

  ngOnInit(): void {
    this.insuranceList.per_page = 100;
    this.insuranceList.page = 0;

    this.buildForm();
    this.loadActiveOrgInsurances();

    if (!this.displayTitle && this.referralInformation) {
      this.loadFormValueFromReferral();
    }
  }

  buildForm() {
    this.eligibilityForm = this.formBuilder.group({
      claimPayerId: new FormControl('', Validators.required),
      claimPayerName: new FormControl('', Validators.required),
      claimPayerCode: new FormControl('', Validators.required),
      providerIndividualNPI: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          npiValidator(),
          RxwebValidators.mask({ mask: '9999999999' }),
        ])
      ),
      providerFirstName: new FormControl('', Validators.required),
      providerLastName: new FormControl('', Validators.required),

      subscriberMemberId: new FormControl('', Validators.required),
      memberFirstName: new FormControl('', Validators.required),
      memberLastName: new FormControl('', Validators.required),
      memberDOB: new FormControl('', Validators.required),
      subscriberSameAsMember: new FormControl(true, Validators.required),

      subscriberFirstName: new FormControl(''),
      subscriberLastName: new FormControl(''),
      subscriberDOB: new FormControl(''),
      serviceType: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),

      sessionId: new FormControl(''),
    });
  }

  loadFormValueFromReferral() {
    this.eligibilityForm.controls['sessionId'].setValue(
      this.referralInformation?.id
    );

    this.eligibilityForm.controls['claimPayerId'].setValue(
      this.referralInformation?.patientInsurancePayorDetails?.id
    );
    this.eligibilityForm.controls['claimPayerName'].setValue(
      this.referralInformation?.patientInsurancePayorDetails?.payerName
    );
    this.eligibilityForm.controls['claimPayerCode'].setValue(
      this.referralInformation?.patientInsurancePayorDetails?.claimPayerCode
    );
    this.eligibilityForm.controls['subscriberMemberId'].setValue(
      this.referralInformation?.insuranceMemberId
    );
    this.eligibilityForm.controls['memberFirstName'].setValue(
      this.referralInformation?.patientFirstName
    );
    this.eligibilityForm.controls['memberLastName'].setValue(
      this.referralInformation?.patientLastName
    );

    // Patient DOB
    if (this.referralInformation?.patientDateOfBirth) {
      this.eligibilityForm.controls['memberDOB'].setValue(
        new Date(this.referralInformation?.patientDateOfBirth)
      );
    }

    if (this.referralInformation?.patientInsuranceRelationship === '18') {
      // Self
      this.eligibilityForm.controls['subscriberSameAsMember'].setValue(true);
    } else {
      // Subscriber is different
      this.eligibilityForm.controls['subscriberSameAsMember'].setValue(false);

      this.eligibilityForm.controls['subscriberFirstName'].setValue(
        this.referralInformation?.subscriberFirstName
      );
      this.eligibilityForm.controls['subscriberLastName'].setValue(
        this.referralInformation?.subscriberLastName
      );

      // Subscriber DOB
      if (this.referralInformation?.subscriberDOB) {
        this.eligibilityForm.controls['subscriberDOB'].setValue(
          new Date(this.referralInformation?.subscriberDOB)
        );
      }
    }
  }

  susbcriberSameAsChanged(event: MatCheckboxChange) {
    // If check subscriber is same as member
    if (!event.checked) {
      this.eligibilityForm
        .get('subscriberFirstName')
        .addValidators(Validators.required);
      this.eligibilityForm.get('subscriberFirstName').updateValueAndValidity();
      this.eligibilityForm
        .get('subscriberLastName')
        .addValidators(Validators.required);
      this.eligibilityForm.get('subscriberLastName').updateValueAndValidity();
      this.eligibilityForm
        .get('subscriberDOB')
        .addValidators(Validators.required);
      this.eligibilityForm.get('subscriberDOB').updateValueAndValidity();
    } else {
      // If unchecked the subscriber is different
      this.eligibilityForm.get('subscriberFirstName').clearValidators();
      this.eligibilityForm.get('subscriberFirstName').updateValueAndValidity();
      this.eligibilityForm.get('subscriberLastName').clearValidators();
      this.eligibilityForm.get('subscriberLastName').updateValueAndValidity();
      this.eligibilityForm.get('subscriberDOB').clearValidators();
      this.eligibilityForm.get('subscriberDOB').updateValueAndValidity();
    }
  }

  // Select the claim payer
  insuranceSelected(event) {
    let filteredPayer = this.insuranceList.items
      .filter((payer) => payer.id === event.value)
      .at(0);

    this.eligibilityForm.controls['claimPayerName'].setValue(
      filteredPayer.payerName
    );
    this.eligibilityForm.controls['claimPayerCode'].setValue(
      filteredPayer.claimPayerCode
    );
  }

  loadActiveOrgInsurances() {
    this.patientInsuranceApiService
      .getInsuranceProviderList(
        this.searchPayer,
        'Active',
        this.insuranceList.per_page,
        this.insuranceList.page
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.insuranceList.items = response.items;
            this.insuranceList.total = response.total;
          } else {
            this.insuranceList.items = [];
            this.insuranceList.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get insurance providers for this organization.'
          );
        },
      });
  }

  searchByPayerChanged() {
    this.insuranceList.page = 0;
    this.loadActiveOrgInsurances();
  }

  submitForm() {
    if (this.eligibilityForm.invalid) {
      return;
    }

    const eligibilityData = this.eligibilityForm.value;
    eligibilityData.startDate = formatDate(new Date(eligibilityData.startDate));
    eligibilityData.memberDOB = formatDate(new Date(eligibilityData.memberDOB));

    // If the subscriber is same as member
    if (eligibilityData?.subscriberSameAsMember) {
      // Copy member information
      eligibilityData.subscriberFirstName = eligibilityData.memberFirstName;
      eligibilityData.subscriberLastName = eligibilityData.memberLastName;
      eligibilityData.subscriberDOB = eligibilityData.memberDOB;
    } else {
      // Format the dates
      eligibilityData.subscriberDOB = formatDate(
        new Date(eligibilityData.subscriberDOB)
      );
    }

    this.processing = true;
    this.patientInsuranceApiService
      .manualEligibilityCheck(eligibilityData)
      .subscribe({
        next: (response) => {
          // this.listenToWebSocket(response.message.code);
          this.toastMessageService.displaySuccessMessage(
            'Eligibility check submitted successfully'
          );
          this.processing = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong while processing the eligibility request'
          );
          this.processing = false;
        },
      });
  }
}
