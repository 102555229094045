<mat-card appearance="raised" *ngIf="hasCaseViewAccess && !selectedCaseId"
  style="margin-top: 10px; margin-bottom: 20px">
  <div class="row" style="margin-bottom: 15px">
    <div class="col-md-12">
      <mat-card-title>Care Management</mat-card-title>
    </div>
  </div>

  <!-- Search Box -->
  <div class="row" style="margin-right: 5px; row-gap: 15px; margin-bottom: 15px">
    <div class="col-sm-12 col-xl-8">
      <div class="row" style="
          row-gap: 15px;
          column-gap: 15px;
          flex-wrap: wrap;
          margin-left: 5px;
        ">
        <div class="col" style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          ">
          <span class="material-icons" style="text-align: left; font-size: 20px; opacity: 0.5">
            search
          </span>
          <input type="search" placeholder="Case #" [(ngModel)]="searchCaseId" (ngModelChange)="searchChanged()" style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            " />
        </div>

        <div class="col" style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          ">
          <span class="material-icons" style="text-align: left; font-size: 20px; opacity: 0.5">
            search
          </span>
          <input type="search" placeholder="Description" [(ngModel)]="searchDescription"
            (ngModelChange)="searchChanged()" style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            " />
        </div>

        <div class="col" style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          ">
          <span class="material-icons" style="text-align: left; font-size: 20px; opacity: 0.5">
            search
          </span>
          <input type="search" placeholder="Case Manager" [(ngModel)]="searchCaseManager"
            (ngModelChange)="searchChanged()" style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            " />
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-4" style="
        column-gap: 20px;
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
      ">
      <div *ngIf="hasCaseAddAccess && patient.status === 'Active'">
        <button type="button" mat-raised-button color="warn" (click)="addCase()">
          + Add Case
        </button>
      </div>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces" *ngIf="searchStatus === ''">
          Status
        </span>
        <span style="white-space: break-spaces; color: #3ac47d" *ngIf="searchStatus === 'active'">
          Status: Active
        </span>
        <span style="white-space: break-spaces; color: #ff9f40" *ngIf="searchStatus === 'Transferred'">
          Status: Transferred
        </span>
        <span style="white-space: break-spaces; color: #d92550" *ngIf="searchStatus === 'Discharged'">
          Status: Discharged
        </span>
        <span style="white-space: break-spaces; color: #d92550" *ngIf="searchStatus === 'closed'">
          Status: Closed
        </span>

        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button *ngIf="hasClosedCaseViewAccess" mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('')">
          All
        </button>
        <button mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('active')">
          Active
        </button>
        <button *ngIf="hasClosedCaseViewAccess" mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Transferred')">
          Transferred
        </button>
        <button *ngIf="hasClosedCaseViewAccess" mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Discharged')">
          Discharged
        </button>
        <button *ngIf="hasClosedCaseViewAccess" mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('closed')">
          Closed
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No case found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving cases
    </mat-error>
  </div>

  <div *ngIf="data.total > 0" class="mat-elevation-z8">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items">
        <ng-container matColumnDef="caseDetails">
          <th mat-header-cell *matHeaderCellDef>Case Details</th>
          <td mat-cell *matCellDef="let row" style="position: relative">
            <div class="colFlexContainer">
              <label (click)="setSelectCase(row.id)">
                <b>Id</b> : <span class="pointer">{{ row.caseNumber }}</span>
              </label>
              <label style="font-size: 13px; font-style: italic" *ngIf="row?.description">
                ({{ row?.description }})
              </label>

              <!-- Status Dot Wrapper -->
              <div class="stausDotWrapper">
                <mat-icon class="statusDot" [style.color]="
                    row.status === 'active'
                      ? '#3ac47d'
                      : row.status === 'Transferred'
                      ? '#ff9f40'
                      : '#d92550'
                  ">
                  fiber_manual_record
                </mat-icon>
                <span class="statusIndicator" [style.color]="
                    row.status === 'active'
                      ? '#3ac47d'
                      : row.status === 'Transferred'
                      ? '#ff9f40'
                      : '#d92550'
                  ">
                  {{ row.status }}
                </span>
              </div>

              <!--   For transferred reasons -->
              <label style="font-size: 13px; font-style: italic" *ngIf="
                  row?.transferredReasons &&
                  row.status === 'Transferred' &&
                  row?.transferredReasons?.length > 0
                ">
                ({{ row?.transferredReasons }})
              </label>

              <!--   For Discharged reasons -->
              <label style="font-size: 13px; font-style: italic" *ngIf="
                  row?.dischargedReasons &&
                  row.status === 'Discharged' &&
                  row?.dischargedReasons?.length > 0
                ">
                ({{ row?.dischargedReasons }})
              </label>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="services">
          <th mat-header-cell *matHeaderCellDef>Service Details</th>
          <td mat-cell *matCellDef="let row">
            <!-- Services -->
            <label class="labelHeader" style="padding: 0; margin: 0; color: #6d6d6d">
              {{ row.services.join(', ') }}
            </label>
            <br />
            <label style="padding: 0; margin: 0">
              <b>Case Manager</b> : {{ row.caseManager }}
            </label>
            <br />
            <label *ngIf="row?.primaryDiagnosisCode" style="padding: 0; margin: 0">
              <b>Primary Dianosis</b> : {{ row?.primaryDiagnosisCode }}
            </label>
            <br *ngIf="row?.primaryDiagnosisCode" />
            <label *ngIf="row?.roomNumber" style="padding: 0; margin: 0">
              <b>Room Number</b> : {{ row?.roomNumber }}
            </label>
            <br *ngIf="row?.roomNumber" />
            <label style="padding: 0; margin: 0" *ngIf="row?.status === 'active' && row.expectedDischargeDate">
              <b>Length of Stay (LOS)</b> : {{ getLOS(row) }}
              {{ getLOS(row) <= 1 ? 'day' : 'days' }} since {{ row.admittanceDate }} </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="payor">
          <th mat-header-cell *matHeaderCellDef>Case Payors</th>
          <td mat-cell *matCellDef="let row">
            <!-- Grant Providers  -->
            <div class="colFlexContainer" *ngIf="
                row?.attachedGrantProviders &&
                row?.attachedGrantProviders.length > 0
              " style="margin-bottom: 12px">
              <label class="labelBold"> Grant Providers </label>
              <label *ngFor="let provider of row.attachedGrantProviders" style="padding: 0; margin: 0">
                {{ provider.grantName }}
              </label>
            </div>
            <!-- Insurance Providers  -->
            <div class="colFlexContainer" *ngIf="
                row?.attachedMemberInsurances &&
                row?.attachedMemberInsurances.length > 0
              ">
              <label class="labelBold"> Insurance Providers </label>
              <label *ngFor="let provider of row.attachedMemberInsurances" style="padding: 0; margin: 0">
                {{ provider.payerName }}
                <span *ngIf="provider.claimPayerCode">
                  ({{ provider.claimPayerCode }})
                </span>
              </label>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">
            Status
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <span style="font-style: italic; color: #029102" *ngIf="row.status === 'active'">
              (Ongoing case)
            </span>

            <!-- For active with discharged Date -->
            <div *ngIf="row.status === 'active' && row.expectedDischargeDate">
              <label style="padding: 0; margin: 0">
                Estimated Discharge Date
              </label>
              <br />
              <label style="padding: 0; margin: 0">
                <small>{{ row.expectedDischargeDate }}</small>
              </label>
              <br />
              <circle-progress [percent]="getEstimatedTitle(row, 'percentage')" [radius]="55" [space]="-10"
                [outerStrokeWidth]="10" [outerStrokeColor]="getEstimatedTitle(row, 'colorOuter')"
                [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="10" [title]="getEstimatedTitle(row, 'days')"
                [subtitle]="getEstimatedTitle(row, 'status')" [subtitleFontSize]="'12'" [animateTitle]="false"
                [animationDuration]="400" [showUnits]="false" [showBackground]="false" [clockwise]="true"
                [startFromZero]="false" [lazy]="true"></circle-progress>
            </div>

            <div *ngIf="row.status != 'active' || !row.expectedDischargeDate">
              <label style="padding: 0; margin: 0">
                <b>Case Date Range </b>
              </label>
              <br />
              <label style="padding: 0; margin: 0">
                <small>{{ row.admittanceDate }} - {{ row.closeDate }}</small>
              </label>
              <br />
              <label style="padding: 0; margin: 0">
                <b>Length of Stay (LOS)</b> : {{ getLOS(row) }}
                {{ getLOS(row) <= 1 ? 'day' : 'days' }} </label>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="font-weight: 900; text-align: right">
            Action
          </th>
          <td mat-cell *matCellDef="let row" style="justify-content: center; text-align: right">
            <button mat-raised-button (click)="editCase(row)" class="btn btn-just-icon btn-white btn-fab btn-round">
              <i class="material-icons text_align-center">edit</i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator #paginator [length]="data.total" [pageSize]="data.per_page" [showFirstLastButtons]="true"
      *ngIf="data.total > data.per_page" (page)="getNext($event)">
    </mat-paginator>
  </div>
</mat-card>


<app-case-details-v2 *ngIf="selectedCaseId" [caseId]="selectedCaseId" [activeTab]="secondaryTab" [patient]="patient"
  [hasCaseEditAccess]="hasCaseEditAccess" [hasCaseTaskViewAccess]="hasCaseTaskViewAccess"
  [hasCaseTaskAddAccess]="hasCaseTaskAddAccess" [hasCaseTaskEditAccess]="hasCaseTaskEditAccess"
  [hasCaseTaskDeleteAccess]="hasCaseTaskDeleteAccess" [hasCaseNotesViewAccess]="hasCaseNotesViewAccess"
  [hasCaseNotesAddAccess]="hasCaseNotesAddAccess" [hasCaseNotesEditAccess]="hasCaseNotesEditAccess"
  [hasCaseNotesDeleteAccess]="hasCaseNotesDeleteAccess" [hasCaseDocumentsViewAccess]="hasCaseDocumentsViewAccess"
  [hasCaseDocumentsAddAccess]="hasCaseDocumentsAddAccess" [hasCaseDocumentsDeleteAccess]="hasCaseDocumentsDeleteAccess"
  [hasCaseLogViewAccess]="hasCaseLogViewAccess" [hasCaseLogAddAccess]="hasCaseLogAddAccess"
  [hasCaseLogEditAccess]="hasCaseLogEditAccess" [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
  [hasGoalsViewAccess]="hasGoalsViewAccess" [hasGoalsAddAccess]="hasGoalsAddAccess"
  [hasGoalsEditAccess]="hasGoalsEditAccess" [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
  (output)="onCaseTabEventTriggered($event)"></app-case-details-v2>

<mat-card appearance="raised" *ngIf="!hasCaseViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error> Sorry, You don't have access to view Cases </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>