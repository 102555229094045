import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { ITreatmentReviewComments } from 'src/app/_models/case/treatment/review/treatmentreviewcomments.model';
import { TreatmentReviewCommentsApiService } from 'src/app/_services/case/treatment/review/treatmentreviewcomments.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-treatment-review-comments',
  templateUrl: './treatment-review-comments.component.html',
  styleUrl: './treatment-review-comments.component.css',
})
export class TreatmentReviewCommentsComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedPlan: ITreatmentPlan;
  @Input() selectedReview: ITreatmentReview;

  public action: string;
  public treatmentreviewcommentsForm: FormGroup;
  treatmentreviewcomments: ITreatmentReviewComments;
  processing: boolean = false;

  constructor(
    public treatmentreviewcommentsApiService: TreatmentReviewCommentsApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();

    this.loadTreatmentReviewComments();
  }

  loadTreatmentReviewComments() {
    this.treatmentreviewcommentsApiService
      .getTreatmentReviewCommentsDetails(this.selectedReview.id)
      .subscribe((treatmentreviewcomments) => {
        if (treatmentreviewcomments?.data) {
          this.treatmentreviewcommentsForm.controls['id'].setValue(
            treatmentreviewcomments.data.id
          );
          this.treatmentreviewcommentsForm.controls['organizationId'].setValue(
            treatmentreviewcomments.data.organizationId
          );
          this.treatmentreviewcommentsForm.controls['patientId'].setValue(
            treatmentreviewcomments.data.patientId
          );
          this.treatmentreviewcommentsForm.controls['caseId'].setValue(
            treatmentreviewcomments.data.caseId
          );
          this.treatmentreviewcommentsForm.controls['planId'].setValue(
            treatmentreviewcomments.data.planId
          );
          this.treatmentreviewcommentsForm.controls['reviewId'].setValue(
            treatmentreviewcomments.data.reviewId
          );
          this.treatmentreviewcommentsForm.controls[
            'clientInvolvedInReviewProcess'
          ].setValue(
            treatmentreviewcomments.data.clientInvolvedInReviewProcess
          );
          this.treatmentreviewcommentsForm.controls[
            'newProblemsAddedToTreatmentPlan'
          ].setValue(
            treatmentreviewcomments.data.newProblemsAddedToTreatmentPlan
          );
          this.treatmentreviewcommentsForm.controls[
            'dischargeCriteria'
          ].setValue(treatmentreviewcomments.data.dischargeCriteria);
          this.treatmentreviewcommentsForm.controls[
            'strengthsResourcesAbilitiesBarriersToSuccess'
          ].setValue(
            treatmentreviewcomments.data
              .strengthsResourcesAbilitiesBarriersToSuccess
          );
          this.treatmentreviewcommentsForm.controls[
            'progressTowardsGoalsAccomplishments'
          ].setValue(
            treatmentreviewcomments.data.progressTowardsGoalsAccomplishments
          );
          this.treatmentreviewcommentsForm.controls[
            'familyRecipientCommentsSatisfaction'
          ].setValue(
            treatmentreviewcomments.data.familyRecipientCommentsSatisfaction
          );
          this.treatmentreviewcommentsForm.controls[
            'changesToClientsDiagnosis'
          ].setValue(treatmentreviewcomments.data.changesToClientsDiagnosis);
          this.treatmentreviewcommentsForm.controls[
            'needForFurtherTreatment'
          ].setValue(treatmentreviewcomments.data.needForFurtherTreatment);
          this.treatmentreviewcommentsForm.controls[
            'planReviewedForLeastRestrictiveSetting'
          ].setValue(
            treatmentreviewcomments.data.planReviewedForLeastRestrictiveSetting
          );
          this.treatmentreviewcommentsForm.controls[
            'planReviewedForAppropriateServices'
          ].setValue(
            treatmentreviewcomments.data.planReviewedForAppropriateServices
          );
        }
      });
  }

  onTreatmentReviewCommentsFormSubmit() {
    if (this.treatmentreviewcommentsForm.invalid) {
      this.treatmentreviewcommentsForm.markAllAsTouched();
      return;
    }
    this.treatmentreviewcomments = Object.assign(
      {},
      this.treatmentreviewcomments
    );
    this.treatmentreviewcomments = Object.assign(
      this.treatmentreviewcomments,
      this.treatmentreviewcommentsForm.value
    );

    this.processing = true;
    this.treatmentreviewcommentsApiService
      .updateTreatmentReviewComments(this.treatmentreviewcomments)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentreviewcommentsForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      planId: new FormControl(this.selectedReview.planId, Validators.required),
      reviewId: new FormControl(this.selectedReview.id, Validators.required),
      clientInvolvedInReviewProcess: new FormControl('', Validators.required),
      newProblemsAddedToTreatmentPlan: new FormControl('', Validators.required),
      dischargeCriteria: new FormControl('', Validators.required),
      strengthsResourcesAbilitiesBarriersToSuccess: new FormControl(
        '',
        Validators.required
      ),
      progressTowardsGoalsAccomplishments: new FormControl(
        '',
        Validators.required
      ),
      familyRecipientCommentsSatisfaction: new FormControl(
        '',
        Validators.required
      ),
      changesToClientsDiagnosis: new FormControl('', Validators.required),
      needForFurtherTreatment: new FormControl('', Validators.required),
      planReviewedForLeastRestrictiveSetting: new FormControl(
        '',
        Validators.required
      ),
      planReviewedForAppropriateServices: new FormControl(
        '',
        Validators.required
      ),
    });

    if (this.selectedPlan.status != 'Active') {
      this.treatmentreviewcommentsForm.disable();
    }
  }
}
