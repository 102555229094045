import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IGamingAddiction } from 'src/app/_models/assessments/addiction/gamingaddiction.model';
import {
  GamingAddictionApiService,
  ToastMessageService,
} from 'src/app/_services';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-gaming-addiction',
  templateUrl: './gaming-addiction.component.html',
  styleUrls: ['./gaming-addiction.component.css'],
})
export class GamingAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public gamingAddictionApiService: GamingAddictionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService
  ) { }

  public riskScore: number;
  public gamingaddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  gamingAddiction: IGamingAddiction;
  gamingAddictionError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.gamingaddictionForm, this.errors);

    this.loadGamingAddictionAssessment();
  }

  loadGamingAddictionAssessment() {
    this.gamingaddictionForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.gamingAddictionApiService
        .getGamingAddictionDetails(this.assessmentId)
        .subscribe((gamingaddiction) => {
          if (gamingaddiction.data) {
            this.gamingaddictionForm.controls[
              'stayLongerThanIntended'
            ].setValue(gamingaddiction.data.stayLongerThanIntended.answer);
            this.gamingaddictionForm.controls[
              'neglectHouseholdChoresToSpendMoreTimeOnline'
            ].setValue(
              gamingaddiction.data.neglectHouseholdChoresToSpendMoreTimeOnline
                .answer
            );
            this.gamingaddictionForm.controls[
              'internetOverIntimacyWithYourPartner'
            ].setValue(
              gamingaddiction.data.internetOverIntimacyWithYourPartner.answer
            );
            this.gamingaddictionForm.controls[
              'formNewRelationshipsWithFellowOnlineUsers'
            ].setValue(
              gamingaddiction.data.formNewRelationshipsWithFellowOnlineUsers
                .answer
            );
            this.gamingaddictionForm.controls[
              'complainToYouAboutTheAmountOfTimeYouSpendOnline'
            ].setValue(
              gamingaddiction.data
                .complainToYouAboutTheAmountOfTimeYouSpendOnline.answer
            );
            this.gamingaddictionForm.controls[
              'sufferBecauseOfTheAmountOfTimeYouSpendOnline'
            ].setValue(
              gamingaddiction.data.sufferBecauseOfTheAmountOfTimeYouSpendOnline
                .answer
            );
            this.gamingaddictionForm.controls[
              'checkYourEMailBeforeSomethingElseThatYouNeedToDo'
            ].setValue(
              gamingaddiction.data
                .checkYourEMailBeforeSomethingElseThatYouNeedToDo.answer
            );
            this.gamingaddictionForm.controls[
              'performanceOrProductivitySufferBecauseOfTheInternet'
            ].setValue(
              gamingaddiction.data
                .performanceOrProductivitySufferBecauseOfTheInternet.answer
            );
            this.gamingaddictionForm.controls[
              'defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline'
            ].setValue(
              gamingaddiction.data
                .defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline.answer
            );
            this.gamingaddictionForm.controls[
              'soothingThoughtsOfTheInternet'
            ].setValue(
              gamingaddiction.data.soothingThoughtsOfTheInternet.answer
            );
            this.gamingaddictionForm.controls[
              'anticipatingWhenYouWillGoOnlineAgain'
            ].setValue(
              gamingaddiction.data.anticipatingWhenYouWillGoOnlineAgain.answer
            );
            this.gamingaddictionForm.controls[
              'lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless'
            ].setValue(
              gamingaddiction.data
                .lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless.answer
            );
            this.gamingaddictionForm.controls[
              'bothersYouWhileYouAreOnline'
            ].setValue(gamingaddiction.data.bothersYouWhileYouAreOnline.answer);
            this.gamingaddictionForm.controls[
              'loseSleepDueToLateNightLogIns'
            ].setValue(
              gamingaddiction.data.loseSleepDueToLateNightLogIns.answer
            );
            this.gamingaddictionForm.controls[
              'preoccupiedWithTheInternet'
            ].setValue(gamingaddiction.data.preoccupiedWithTheInternet.answer);
            this.gamingaddictionForm.controls[
              'yourselfSayingJustAFewMoreMinutes'
            ].setValue(
              gamingaddiction.data.yourselfSayingJustAFewMoreMinutes.answer
            );
            this.gamingaddictionForm.controls[
              'cutDownTheAmountOfTimeYouSpendOnline'
            ].setValue(
              gamingaddiction.data.cutDownTheAmountOfTimeYouSpendOnline.answer
            );
            this.gamingaddictionForm.controls[
              'hideHowLongYouHaveBeenOnline'
            ].setValue(
              gamingaddiction.data.hideHowLongYouHaveBeenOnline.answer
            );
            this.gamingaddictionForm.controls[
              'spendMoreTimeOnlineOverGoingOutWithOthers'
            ].setValue(
              gamingaddiction.data.spendMoreTimeOnlineOverGoingOutWithOthers
                .answer
            );
            this.gamingaddictionForm.controls[
              'feelDepressedMoodyOrNervousWhenOffline'
            ].setValue(
              gamingaddiction.data.feelDepressedMoodyOrNervousWhenOffline.answer
            );
            this.riskScore = gamingaddiction.data.riskScore;
          }
        });
    }
  }
  onGamingAddictionFormSubmit() {
    if (this.gamingaddictionForm.invalid) {
      this.gamingaddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.gamingAddiction = Object.assign({}, this.gamingAddiction);
    this.gamingAddiction.patientId =
      this.gamingaddictionForm.controls['patientId'].value;
    this.gamingAddiction.sessionId =
      this.gamingaddictionForm.controls['sessionId'].value;
    this.gamingAddiction.assessmentRequestId =
      this.gamingaddictionForm.controls['assessmentRequestId'].value;
    this.gamingAddiction.stayLongerThanIntended = {
      answer: this.gamingaddictionForm.controls['stayLongerThanIntended'].value,
    };
    this.gamingAddiction.neglectHouseholdChoresToSpendMoreTimeOnline = {
      answer:
        this.gamingaddictionForm.controls[
          'neglectHouseholdChoresToSpendMoreTimeOnline'
        ].value,
    };
    this.gamingAddiction.internetOverIntimacyWithYourPartner = {
      answer:
        this.gamingaddictionForm.controls['internetOverIntimacyWithYourPartner']
          .value,
    };
    this.gamingAddiction.formNewRelationshipsWithFellowOnlineUsers = {
      answer:
        this.gamingaddictionForm.controls[
          'formNewRelationshipsWithFellowOnlineUsers'
        ].value,
    };
    this.gamingAddiction.complainToYouAboutTheAmountOfTimeYouSpendOnline = {
      answer:
        this.gamingaddictionForm.controls[
          'complainToYouAboutTheAmountOfTimeYouSpendOnline'
        ].value,
    };
    this.gamingAddiction.sufferBecauseOfTheAmountOfTimeYouSpendOnline = {
      answer:
        this.gamingaddictionForm.controls[
          'sufferBecauseOfTheAmountOfTimeYouSpendOnline'
        ].value,
    };
    this.gamingAddiction.checkYourEMailBeforeSomethingElseThatYouNeedToDo = {
      answer:
        this.gamingaddictionForm.controls[
          'checkYourEMailBeforeSomethingElseThatYouNeedToDo'
        ].value,
    };
    this.gamingAddiction.performanceOrProductivitySufferBecauseOfTheInternet = {
      answer:
        this.gamingaddictionForm.controls[
          'performanceOrProductivitySufferBecauseOfTheInternet'
        ].value,
    };
    this.gamingAddiction.defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline =
    {
      answer:
        this.gamingaddictionForm.controls[
          'defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline'
        ].value,
    };
    this.gamingAddiction.soothingThoughtsOfTheInternet = {
      answer:
        this.gamingaddictionForm.controls['soothingThoughtsOfTheInternet']
          .value,
    };
    this.gamingAddiction.anticipatingWhenYouWillGoOnlineAgain = {
      answer:
        this.gamingaddictionForm.controls[
          'anticipatingWhenYouWillGoOnlineAgain'
        ].value,
    };
    this.gamingAddiction.lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless = {
      answer:
        this.gamingaddictionForm.controls[
          'lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless'
        ].value,
    };
    this.gamingAddiction.bothersYouWhileYouAreOnline = {
      answer:
        this.gamingaddictionForm.controls['bothersYouWhileYouAreOnline'].value,
    };
    this.gamingAddiction.loseSleepDueToLateNightLogIns = {
      answer:
        this.gamingaddictionForm.controls['loseSleepDueToLateNightLogIns']
          .value,
    };
    this.gamingAddiction.preoccupiedWithTheInternet = {
      answer:
        this.gamingaddictionForm.controls['preoccupiedWithTheInternet'].value,
    };
    this.gamingAddiction.yourselfSayingJustAFewMoreMinutes = {
      answer:
        this.gamingaddictionForm.controls['yourselfSayingJustAFewMoreMinutes']
          .value,
    };
    this.gamingAddiction.cutDownTheAmountOfTimeYouSpendOnline = {
      answer:
        this.gamingaddictionForm.controls[
          'cutDownTheAmountOfTimeYouSpendOnline'
        ].value,
    };
    this.gamingAddiction.hideHowLongYouHaveBeenOnline = {
      answer:
        this.gamingaddictionForm.controls['hideHowLongYouHaveBeenOnline'].value,
    };
    this.gamingAddiction.spendMoreTimeOnlineOverGoingOutWithOthers = {
      answer:
        this.gamingaddictionForm.controls[
          'spendMoreTimeOnlineOverGoingOutWithOthers'
        ].value,
    };
    this.gamingAddiction.feelDepressedMoodyOrNervousWhenOffline = {
      answer:
        this.gamingaddictionForm.controls[
          'feelDepressedMoodyOrNervousWhenOffline'
        ].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.gamingAddiction.accessCode = this.accessCode;

      this.gamingAddictionApiService
        .addGuestGamingAddiction(this.gamingAddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Gaming Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Gaming Addiction Assessment'
            );
          }
        );
    } else {
      this.gamingAddictionApiService
        .addGamingAddiction(this.gamingAddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Gaming Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Gaming Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    stayLongerThanIntended: [
      {
        type: 'required',
        message:
          'How often do you find that you stay online longer than you intended? is required',
      },
    ],
    neglectHouseholdChoresToSpendMoreTimeOnline: [
      {
        type: 'required',
        message:
          'How often do you neglect household chores to spend more time online? is required',
      },
    ],
    internetOverIntimacyWithYourPartner: [
      {
        type: 'required',
        message:
          'How often do you prefer the excitement of the Internet to intimacy with your partner? is required',
      },
    ],
    formNewRelationshipsWithFellowOnlineUsers: [
      {
        type: 'required',
        message:
          'How often do you form new relationships with fellow online users? is required',
      },
    ],
    complainToYouAboutTheAmountOfTimeYouSpendOnline: [
      {
        type: 'required',
        message:
          'How often do others in your life complain to you about the amount of time you spend online? is required',
      },
    ],
    sufferBecauseOfTheAmountOfTimeYouSpendOnline: [
      {
        type: 'required',
        message:
          'How often do your grades or school work suffer because of the amount of time you spend online? is required',
      },
    ],
    checkYourEMailBeforeSomethingElseThatYouNeedToDo: [
      {
        type: 'required',
        message:
          'How often do you check your e-mail before something else that you need to do? is required',
      },
    ],
    performanceOrProductivitySufferBecauseOfTheInternet: [
      {
        type: 'required',
        message:
          'How often does your job performance or productivity suffer because of the Internet? is required',
      },
    ],
    defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline: [
      {
        type: 'required',
        message:
          'How often do you become defensive or secretive when anyone asks you what you do online? is required',
      },
    ],
    soothingThoughtsOfTheInternet: [
      {
        type: 'required',
        message:
          'How often do you block out disturbing thoughts about your life with soothing thoughts of the Internet? is required',
      },
    ],
    anticipatingWhenYouWillGoOnlineAgain: [
      {
        type: 'required',
        message:
          'How often do you find yourself anticipating when you will go online again? is required',
      },
    ],
    lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless: [
      {
        type: 'required',
        message:
          'How often do you fear that life without the Internet would be boring, empty, and joyless? is required',
      },
    ],
    bothersYouWhileYouAreOnline: [
      {
        type: 'required',
        message:
          'How often do you snap, yell, or act annoyed if someone bothers you while you are online? is required',
      },
    ],
    loseSleepDueToLateNightLogIns: [
      {
        type: 'required',
        message:
          'How often do you lose sleep due to late-night log-ins? is required',
      },
    ],
    preoccupiedWithTheInternet: [
      {
        type: 'required',
        message:
          'How often do you feel preoccupied with the Internet when off-line, or fantasize about being online? is required',
      },
    ],
    yourselfSayingJustAFewMoreMinutes: [
      {
        type: 'required',
        message:
          'How often do you find yourself saying "just a few more minutes" when online? is required',
      },
    ],
    cutDownTheAmountOfTimeYouSpendOnline: [
      {
        type: 'required',
        message:
          'How often do you try to cut down the amount of time you spend online and fail? is required',
      },
    ],
    hideHowLongYouHaveBeenOnline: [
      {
        type: 'required',
        message:
          "How often do you try to hide how long you've been online? is required",
      },
    ],
    spendMoreTimeOnlineOverGoingOutWithOthers: [
      {
        type: 'required',
        message:
          'How often do you choose to spend more time online over going out with others? is required',
      },
    ],
    feelDepressedMoodyOrNervousWhenOffline: [
      {
        type: 'required',
        message:
          'How often do you feel depressed, moody, or nervous when you are off-line, which goes away once you are back online? is required',
      },
    ],
  };

  private buildForm() {
    this.gamingaddictionForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      stayLongerThanIntended: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      neglectHouseholdChoresToSpendMoreTimeOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      internetOverIntimacyWithYourPartner: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      formNewRelationshipsWithFellowOnlineUsers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      complainToYouAboutTheAmountOfTimeYouSpendOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sufferBecauseOfTheAmountOfTimeYouSpendOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      checkYourEMailBeforeSomethingElseThatYouNeedToDo: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      performanceOrProductivitySufferBecauseOfTheInternet: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      soothingThoughtsOfTheInternet: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      anticipatingWhenYouWillGoOnlineAgain: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      bothersYouWhileYouAreOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      loseSleepDueToLateNightLogIns: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      preoccupiedWithTheInternet: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      yourselfSayingJustAFewMoreMinutes: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      cutDownTheAmountOfTimeYouSpendOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hideHowLongYouHaveBeenOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      spendMoreTimeOnlineOverGoingOutWithOthers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelDepressedMoodyOrNervousWhenOffline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.gamingaddictionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.gamingaddictionForm) {
      return;
    }
    const form = this.gamingaddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
