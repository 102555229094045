export const GoalProblemStatus = [
  'Deferred',
  'In Treatment',
  'Referred',
  'Resolved',
  'Withdrawn',
];

export const GoalStatus = [
  'Active',
  'Continue As Is',
  'Discontinue',
  'Modify',
  'Closed',
];

export const GoalObjectiveStatus = [
  'Completed',
  'In Progress',
  'None selected',
  'Postponed',
  'Withdrawn',
];

export const GoalInterventionStatus = [
  'Withdrawn',
  'Postponed',
  'None selected',
  'In Progress',
  'Completed',
];
