import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPtsdScreening } from 'src/app/_models/assessments/stress/ptsdscreening.model';
import {
  PtsdScreeningApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-ptsd-screening',
  templateUrl: './ptsd-screening.component.html',
  styleUrls: ['./ptsd-screening.component.css'],
})
export class PtsdScreeningComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public ptsdScreeningApiService: PtsdScreeningApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public ptsdScreeningForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  ptsdScreeningAssessment: IPtsdScreening;
  ptsdScreeningAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;
  showFurtherQuetsions: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.ptsdScreeningForm, this.errors);
    this.loadPtsdScreeningAssessment();
  }

  loadPtsdScreeningAssessment() {
    this.ptsdScreeningForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.ptsdScreeningApiService
        .getPtsdScreeningDetails(this.assessmentId)
        .subscribe((ptsdScreeningAssessment) => {
          if (ptsdScreeningAssessment.data) {
            this.ptsdScreeningForm.controls['q1'].setValue(
              ptsdScreeningAssessment.data.q1.answer
            );
            this.ptsdScreeningForm.controls['q2'].setValue(
              ptsdScreeningAssessment.data.q2.answer
            );
            this.ptsdScreeningForm.controls['q3'].setValue(
              ptsdScreeningAssessment.data.q3.answer
            );
            this.ptsdScreeningForm.controls['q4'].setValue(
              ptsdScreeningAssessment.data.q4.answer
            );
            this.ptsdScreeningForm.controls['q5'].setValue(
              ptsdScreeningAssessment.data.q5.answer
            );
            this.ptsdScreeningForm.controls['q6'].setValue(
              ptsdScreeningAssessment.data.q6.answer
            );

            this.riskScore = ptsdScreeningAssessment.data.riskScore;
          }
          this.askFurtherQuestions();
        });
    }
  }

  onFormSubmit() {
    if (this.ptsdScreeningForm.invalid) {
      this.ptsdScreeningForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.ptsdScreeningAssessment = Object.assign(
      {},
      this.ptsdScreeningAssessment
    );
    this.ptsdScreeningAssessment.patientId =
      this.ptsdScreeningForm.controls['patientId'].value;
    this.ptsdScreeningAssessment.sessionId =
      this.ptsdScreeningForm.controls['sessionId'].value;
    this.ptsdScreeningAssessment.assessmentRequestId =
      this.ptsdScreeningForm.controls['assessmentRequestId'].value;
    this.ptsdScreeningAssessment.q1 = {
      answer: this.ptsdScreeningForm.controls['q1'].value,
    };
    this.ptsdScreeningAssessment.q2 = {
      answer: this.ptsdScreeningForm.controls['q2'].value,
    };
    this.ptsdScreeningAssessment.q3 = {
      answer: this.ptsdScreeningForm.controls['q3'].value,
    };
    this.ptsdScreeningAssessment.q4 = {
      answer: this.ptsdScreeningForm.controls['q4'].value,
    };
    this.ptsdScreeningAssessment.q5 = {
      answer: this.ptsdScreeningForm.controls['q5'].value,
    };
    this.ptsdScreeningAssessment.q6 = {
      answer: this.ptsdScreeningForm.controls['q6'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.ptsdScreeningAssessment.accessCode = this.accessCode;

      this.ptsdScreeningApiService
        .addGuestPtsdScreening(this.ptsdScreeningAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted PTSD Screening'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting PTSD Screening'
            );
          }
        );
    } else {
      this.ptsdScreeningApiService
        .addPtsdScreening(this.ptsdScreeningAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted PTSD Screening'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting PTSD Screening'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. Have you ever experienced this kind of event? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. had nightmares about the event(s) or thought about the event(s) when you did not want to? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. tried hard not to think about the event(s) or went out of your way to avoid situations that reminded you of the event(s)? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. been constantly on guard, watchful, or easily startled? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. felt numb or detached from people, activities, or your surroundings? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. felt guilty or unable to stop blaming yourself or others for the event(s) or any problems the event(s) may have caused? is required',
      },
    ],
  };

  private buildForm() {
    this.ptsdScreeningForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
    });
    this.ptsdScreeningForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.ptsdScreeningForm) {
      return;
    }
    const form = this.ptsdScreeningForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public askFurtherQuestions() {
    let q1answer = this.ptsdScreeningForm.controls['q1'].value;
    if ('Yes' === q1answer) {
      this.showFurtherQuetsions = true;
      this.ptsdScreeningForm.get('q2').setValidators([Validators.required]);
      this.ptsdScreeningForm.get('q3').setValidators([Validators.required]);
      this.ptsdScreeningForm.get('q4').setValidators([Validators.required]);
      this.ptsdScreeningForm.get('q5').setValidators([Validators.required]);
      this.ptsdScreeningForm.get('q6').setValidators([Validators.required]);
    } else {
      this.showFurtherQuetsions = false;

      this.ptsdScreeningForm.get('q2').setValidators([]);
      this.ptsdScreeningForm.get('q3').setValidators([]);
      this.ptsdScreeningForm.get('q4').setValidators([]);
      this.ptsdScreeningForm.get('q5').setValidators([]);
      this.ptsdScreeningForm.get('q6').setValidators([]);

      this.ptsdScreeningForm.controls['q2'].setValue('');
      this.ptsdScreeningForm.controls['q3'].setValue('');
      this.ptsdScreeningForm.controls['q4'].setValue('');
      this.ptsdScreeningForm.controls['q5'].setValue('');
      this.ptsdScreeningForm.controls['q6'].setValue('');
    }
  }
}
