<div class="col-md-12">
  <form
    [formGroup]="treatmentdischargesubstanceForm"
    (ngSubmit)="onTreatmentDischargeSubstanceFormSubmit()"
  >
    <div class="row">
      <!-- Primary Substance -->
      <div class="col-md-3">
        <label class="labelText">
          Primary Substance <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="primarySubstance"
            placeholder="Primary Substance"
          >
            <mat-option
              *ngFor="let option of substanceCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['primarySubstance']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Primary Severity -->
      <div class="col-md-3">
        <label class="labelText">
          Primary Severity <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="primarySeverity"
            placeholder="Primary Severity"
          >
            <mat-option
              *ngFor="let option of severityCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['primarySeverity']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Primary Frequency -->
      <div class="col-md-3">
        <label class="labelText">
          Primary Frequency <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="primaryFrequency"
            placeholder="Primary Frequency"
          >
            <mat-option
              *ngFor="let option of frequencyCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['primaryFrequency']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Primary Method -->
      <div class="col-md-3">
        <label class="labelText">
          Primary Method <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="primaryMethod"
            placeholder="Primary Method"
          >
            <mat-option
              *ngFor="let option of intakeTypeCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['primaryMethod'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Secondary Substance-->
      <div class="col-md-3">
        <label class="labelText">
          Secondary Substance <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="secondarySubstance"
            placeholder="Secondary Substance"
          >
            <mat-option
              *ngFor="let option of substanceCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['secondarySubstance']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Secondary Severity-->
      <div class="col-md-3">
        <label class="labelText">
          Secondary Severity<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="secondarySeverity"
            placeholder="Secondary Severity"
          >
            <mat-option
              *ngFor="let option of severityCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['secondarySeverity']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Secondary Frequency-->
      <div class="col-md-3">
        <label class="labelText">
          Secondary Frequency <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="secondaryFrequency"
            placeholder="Secondary Frequency"
          >
            <mat-option
              *ngFor="let option of frequencyCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['secondaryFrequency']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Secondary Method-->
      <div class="col-md-3">
        <label class="labelText">
          Secondary Method <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="secondaryMethod"
            placeholder="Secondary Method"
          >
            <mat-option
              *ngFor="let option of intakeTypeCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['secondaryMethod']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tertiary Substance -->
      <div class="col-md-3">
        <label class="labelText">
          Tertiary Substance <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="tertiarySubstance"
            placeholder="Tertiary Substance"
          >
            <mat-option
              *ngFor="let option of substanceCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['tertiarySubstance']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tertiary Severity-->
      <div class="col-md-3">
        <label class="labelText">
          Tertiary Severity <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="tertiarySeverity"
            placeholder="Tertiary Severity"
          >
            <mat-option
              *ngFor="let option of severityCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['tertiarySeverity']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tertiary Frequency-->
      <div class="col-md-3">
        <label class="labelText">
          Tertiary Frequency<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="tertiaryFrequency"
            placeholder="Tertiary Frequency"
          >
            <mat-option
              *ngFor="let option of frequencyCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['tertiaryFrequency']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tertiary Method-->
      <div class="col-md-3">
        <label class="labelText">
          Tertiary Method <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="tertiaryMethod"
            placeholder="Tertiary Method"
          >
            <mat-option
              *ngFor="let option of intakeTypeCodeOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['tertiaryMethod'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row" style="margin-top: 15px">
      <!-- Was Methadone Maintenance Part Of Tx? -->
      <div class="col-md-6">
        <label class="labelText">
          Was Methadone Maintenance Part Of Tx?
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="wasMethadoneMaintenancePartOfTx"
            placeholder="Was Methadone Maintenance Part Of Tx"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'wasMethadoneMaintenancePartOfTx'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Does Client Use Tobacco -->
      <div class="col-md-6">
        <label class="labelText">
          Does Client Use Tobacco?
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="doesClientUseTobacco"
            placeholder="Does Client Use Tobacco"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['doesClientUseTobacco']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Discharge Status -->
      <div class="col-md-6">
        <label class="labelText">
          Discharge Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="dischargeStatus"
            placeholder="Discharge Status"
          >
            <mat-option value="Complete">Complete</mat-option>
            <mat-option value="Incomplete">Incomplete</mat-option>
            <mat-option value="Continue">Continue</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['dischargeStatus']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Post Discharge Case Management -->
      <div class="col-md-6">
        <label class="labelText">
          Post Discharge Case Management
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="postDischargeCaseManagement"
            placeholder="Post Discharge Case Management"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'postDischargeCaseManagement'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Post Discharge Case Management Days -->
      <div class="col-md-6">
        <label class="labelText">
          Post Discharge Case Management Days <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Post Discharge Case Management Days"
            formControlName="postDischargeCaseManagementDays"
            name="postDischargeCaseManagementDays"
            required
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'postDischargeCaseManagementDays'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Prognosis -->
      <div class="col-md-6">
        <label class="labelText">
          Prognosis <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="prognosis" placeholder="Prognosis">
            <mat-option value="Excellent">Excellent</mat-option>
            <mat-option value="Fair">Fair</mat-option>
            <mat-option value="Good">Good</mat-option>
            <mat-option value="Poor">Poor</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['prognosis'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--Was Family Member Involved-->
      <div class="col-md-6">
        <label class="labelText">
          Was Family Member Involved? <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="wasFamilyMemberInvolved"
            placeholder="Was Family Member Involved"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'wasFamilyMemberInvolved'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Was Concerned Person Involved  -->
      <div class="col-md-6">
        <label class="labelText">
          Was Concerned Person Involved? <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="wasConcernedPersonInvolved"
            placeholder="Was Concerned Person Involved"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'wasConcernedPersonInvolved'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Was Service Received -->
      <div class="col-md-6">
        <label class="labelText">
          Was Service Received <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="wasMHServiceReceived"
            placeholder="Was Service Received"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['wasMHServiceReceived']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Psychiatric Follow Up -->
      <div class="col-md-6">
        <label class="labelText">
          Psychiatric Follow Up <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="psychiatricFollowUp"
            placeholder="Psychiatric Follow Up"
          >
            <mat-option value="Inapprop for intervention"
              >Inapprop for intervention</mat-option
            >
            <mat-option value="In-house assessment"
              >In-house assessment</mat-option
            >
            <mat-option value="ot nec-clinician judgment"
              >ot nec-clinician judgment</mat-option
            >
            <mat-option value="Provided info to client"
              >Provided info to client</mat-option
            >
            <mat-option value="Referral Created">Referral Created</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls['psychiatricFollowUp']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--Codependent Or Collateral -->
      <div class="col-md-6">
        <label class="labelText">
          Codependent Or Collateral <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="codependentOrCollateral"
            placeholder="Codependent Or Collateral"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              treatmentdischargesubstanceForm.controls[
                'codependentOrCollateral'
              ].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions align="end" class="pad20 pull-right">
      <button
        class="btn btn-primary pull-right"
        mat-raised-button
        type="submit"
        [disabled]="!treatmentdischargesubstanceForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
