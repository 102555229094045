import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel } from 'src/app/_models';
import { ITreatmentPlanTarget } from 'src/app/_models/case/treatment/plan/treatmentplantargets.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class TreatmentPlanTargetApiService {
    constructor(private httpClient: HttpClient) { }
    getTreatmentPlanTargetList(planId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('planId', planId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/treatmentplantargets`, { params: params });
    }

    addTreatmentPlanTarget(thisTreatmentPlanTargets: ITreatmentPlanTarget): Observable<ITreatmentPlanTarget> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<ITreatmentPlanTarget>(`${environment.apiUrl}/treatmentplantarget`, thisTreatmentPlanTargets, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateTreatmentPlanTarget(thisTreatmentPlanTarget: ITreatmentPlanTarget): Observable<ITreatmentPlanTarget> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<ITreatmentPlanTarget>(`${environment.apiUrl}/treatmentplantarget`, thisTreatmentPlanTarget, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}