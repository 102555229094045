import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case, Patient } from 'src/app/_models';
import { ICaseTreatmentAdmissionFinancial } from 'src/app/_models/case/treatment/admission/casetreatmentadmissionfinancial.model';
import { CaseTreatmentAdmissionFinancialApiService } from 'src/app/_services/case/treatment/admission/casetreatmentadmissionfinancial.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';

@Component({
  selector: 'app-case-admission-financial',
  templateUrl: './case-admission-financial.component.html',
  styleUrl: './case-admission-financial.component.css',
})
export class CaseAdmissionFinancialComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;

  public action: string;
  public casetreatmentadmissionfinancialForm: FormGroup;

  casetreatmentadmissionfinancial: ICaseTreatmentAdmissionFinancial;
  processing: boolean = false;

  constructor(
    public casetreatmentadmissionfinancialApiService: CaseTreatmentAdmissionFinancialApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.buildForm();

    this.loadCaseTreatmentAdmissionFinancial();
  }

  loadCaseTreatmentAdmissionFinancial() {
    this.casetreatmentadmissionfinancialApiService
      .getCaseTreatmentAdmissionFinancialDetails(this.case.id)
      .subscribe((casetreatmentadmissionfinancial) => {
        if (casetreatmentadmissionfinancial?.data) {
          Object.keys(casetreatmentadmissionfinancial.data).forEach((key) => {
            if (this.casetreatmentadmissionfinancialForm.controls[key]) {
              this.casetreatmentadmissionfinancialForm.controls[key].setValue(
                casetreatmentadmissionfinancial.data[key]
              );
            }
          });
        }
      });
  }

  onCaseTreatmentAdmissionFinancialFormSubmit() {
    if (this.casetreatmentadmissionfinancialForm.invalid) {
      this.casetreatmentadmissionfinancialForm.markAllAsTouched();
      return;
    }
    this.casetreatmentadmissionfinancial = Object.assign(
      {},
      this.casetreatmentadmissionfinancialForm.value
    );

    this.processing = true;
    this.casetreatmentadmissionfinancialApiService
      .updateCaseTreatmentAdmissionFinancial(
        this.casetreatmentadmissionfinancial
      )
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.casetreatmentadmissionfinancialForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      employmentStatus: new FormControl('', Validators.required),
      monthsEmployedInLast6Months: new FormControl(''),
      primaryIncomeSource: new FormControl('', Validators.required),
      insuranceType: new FormControl('', Validators.required),
      annualHouseholdIncome: new FormControl('', Validators.required),
      householdComposition: new FormControl(''),
      maritalStatus: new FormControl('', Validators.required),
      livingArrangement: new FormControl('', Validators.required),

      numberOfChildrenLivingWithClient: new FormControl(''),
      numberOfPeopleLivingWithClient: new FormControl('', Validators.required),
      numberOfChildrenEighteenOrAbove: new FormControl('', Validators.required),
      numberOfChildrenBelowEighteen: new FormControl('', Validators.required),
      numberOfDependentsSupported: new FormControl('', Validators.required),
    });
  }
}
