<form
  [formGroup]="treatmentreviewcommentsForm"
  (ngSubmit)="onTreatmentReviewCommentsFormSubmit()"
  style="padding: 15px"
>
  <div class="row">
    <div class="col-md-6">
      <!-- Client Involved In Review Process -->
      <label class="labelText">
        Client Involved In Review Process <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="clientInvolvedInReviewProcess"
          placeholder="Client Involved In Review Process"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'clientInvolvedInReviewProcess'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!--   New Problems Added To Treatment Plan  -->
    <div class="col-md-6">
      <label class="labelText">
        New Problems Added To Treatment Plan <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="newProblemsAddedToTreatmentPlan"
          placeholder="New Problems Added To Treatment Plan"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'newProblemsAddedToTreatmentPlan'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Discharge Criteria -->
    <div class="col-md-12">
      <label class="labelText">
        Discharge Criteria <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="dischargeCriteria"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls['dischargeCriteria'].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Strengths Resources Abilities Barriers To Success -->
    <div class="col-md-12">
      <label class="labelText">
        Strengths Resources Abilities Barriers To Success
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="strengthsResourcesAbilitiesBarriersToSuccess"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'strengthsResourcesAbilitiesBarriersToSuccess'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Progress Towards Goals Accomplishments-->
    <div class="col-md-12">
      <label class="labelText">
        Progress Towards Goals Accomplishments <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="progressTowardsGoalsAccomplishments"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'progressTowardsGoalsAccomplishments'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Family Recipient Comments Satisfaction -->
    <div class="col-md-12">
      <label class="labelText">
        Family Recipient Comments Satisfaction
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="familyRecipientCommentsSatisfaction"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'familyRecipientCommentsSatisfaction'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Changes To Clients Diagnosis -->
    <div class="col-md-12">
      <label class="labelText">
        Changes To Clients Diagnosis <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="changesToClientsDiagnosis"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls['changesToClientsDiagnosis']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!--Need For Further Treatment  -->
    <div class="col-md-12">
      <label class="labelText">
        Need For Further Treatment <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <textarea
          formControlName="needForFurtherTreatment"
          matInput
          rows="3"
        ></textarea>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls['needForFurtherTreatment']
              .invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <!--Plan Reviewed For Least Restrictive Setting -->
    <div class="col-md-6">
      <label class="labelText">
        Plan Reviewed For Least Restrictive Setting
        <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="planReviewedForLeastRestrictiveSetting"
          placeholder="Plan Reviewed For Least Restrictive Setting"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'planReviewedForLeastRestrictiveSetting'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <label class="labelText">
        Plan Reviewed For Appropriate Services <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="planReviewedForAppropriateServices"
          placeholder="Plan Reviewed For Appropriate Services"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            treatmentreviewcommentsForm.controls[
              'planReviewedForAppropriateServices'
            ].invalid
          "
        >
          Required
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-12"
      style="text-align: right"
      *ngIf="selectedPlan.status === 'Active'"
    >
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!treatmentreviewcommentsForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
