<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <a routerLink="/remittances/remit" routerLinkActive="active">
      <span style="color: #2196f3; cursor: pointer; font-weight: 500">
        Remittances
      </span>
    </a>
    &nbsp; / &nbsp; Record New Remit
  </p>
</div>

<!-- Progress bar -->
<div *ngIf="isLoadingDetails || isLoadingResults">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingDetails || isLoadingResults"
  ></mat-progress-bar>
</div>

<!-- Show Internal Scrubbing Errors -->
<div *ngIf="errorsList && errorsList?.length > 0" style="margin-bottom: 15px">
  <div class="row">
    <div class="col-md-12" *ngFor="let error of errorsList">
      <mat-card
        appearance="raised"
        class="alertCard mat-elevation-z8"
        style="border-left: '5px solid red'"
      >
        <label class="labelText" style="color: red"> {{ error }}</label>
      </mat-card>
    </div>
  </div>
</div>

<form [formGroup]="remitForm">
  <div class="row">
    <!-- Payment Information -->
    <div class="col-md-4" style="margin-bottom: 25px">
      <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
        <!-- Header -->
        <div class="columnsGrid">
          <label class="labelHeader">
            <div class="justifyCenterContainer">
              <mat-icon style="margin-right: 10px">paid</mat-icon>
              Payment Details
            </div>
          </label>
        </div>

        <!-- Form Elements -->
        <div class="row">
          <div class="col-md-12 col-xl-6">
            <label class="labelBold">
              Payment Method <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                (selectionChange)="paymentMethodChanged($event)"
                formControlName="paymentMethodCode"
              >
                <mat-option
                  *ngFor="let method of paymentMethodsList"
                  [value]="method.code"
                >
                  {{ method.code }} - {{ method.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="remitForm.controls['paymentMethodCode'].invalid"
              >
                Please select the payment method
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-xl-6">
            <label class="labelBold">
              Payment Reference Number <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="paymentReferenceNumber"
                matInput
                (change)="paymentReferenceNumberChanged()"
              />
              <mat-error
                *ngIf="remitForm.controls['paymentReferenceNumber'].invalid"
              >
                Payment Ref # is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-xl-6">
            <label class="labelBold">
              Payment Amount <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="paymentAmount"
                matInput
                type="number"
                min="0"
              />
              <span matPrefix>$</span>
              <mat-error *ngIf="remitForm.controls['paymentAmount'].invalid">
                Payment amount must be >= $0
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-xl-6">
            <label class="labelBold">
              Payment Effective Date<span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="paymentEffectiveDate"
                matInput
                [matDatepicker]="paymentEffectiveDate"
                [max]="maxDate"
                placeholder="MM/DD/YYYY"
                required
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="paymentEffectiveDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #paymentEffectiveDate></mat-datepicker>

              <mat-error
                *ngIf="remitForm.controls['paymentEffectiveDate'].invalid"
              >
                Please enter a valid payment effective date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelBold">
              Payment Status <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let status of paymentStatusList"
                  [value]="status"
                >
                  {{ status }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="remitForm.controls['status'].invalid">
                Please select the status
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- Insurance Payer Information -->
    <div class="col-md-4" style="margin-bottom: 25px">
      <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
        <!-- Header -->
        <div class="columnsGrid">
          <label class="labelHeader">
            <div class="justifyCenterContainer">
              <mat-icon style="margin-right: 10px">assured_workload</mat-icon>
              Payer Details
            </div>
          </label>
        </div>

        <!-- Form Elements -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Insurance Payer <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                (selectionChange)="payerChanged($event)"
                formControlName="payerName"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Payer Name"
                    noEntriesFoundLabel="No matching payer found"
                    [(ngModel)]="payerName"
                    (ngModelChange)="filterPayer()"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let payer of filteredPayerList"
                  [value]="payer.payerName"
                >
                  {{ payer.payerName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="remitForm.controls['payerName'].invalid">
                Please select the payer
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Display Selected Insurance Payer -->
          <div class="col-md-12" *ngIf="remitForm.controls['payerName'].value">
            <label class="labelHeader">
              <div class="justifyCenterContainer">
                <mat-icon style="margin-right: 10px">assured_workload</mat-icon>
                Selected Payer
              </div>
            </label>
            <br />
            <div class="columnsGrid">
              <label class="labelBold">
                {{ remitForm.controls['payerName'].value }}
              </label>
              <label
                style="margin-top: 8px"
                *ngIf="remitForm.controls['payerAddressLine1'].value"
              >
                <b> {{ remitForm.controls['payerAddressLine1'].value }}</b>
              </label>
              <label *ngIf="remitForm.controls['payerAddressLine1'].value">
                <b>
                  {{ remitForm.controls['payerCity'].value }},
                  {{ remitForm.controls['payerState'].value }},
                  {{ remitForm.controls['payerZip'].value }}
                </b>
              </label>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- Insurance Payee Information -->
    <div class="col-md-4" style="margin-bottom: 25px">
      <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
        <!-- Header -->
        <div class="columnsGrid">
          <label class="labelHeader">
            <div class="justifyCenterContainer">
              <mat-icon style="margin-right: 10px">business</mat-icon>
              Payee Details
            </div>
          </label>
        </div>

        <!-- Form Elements -->
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Payee/Facility <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                (selectionChange)="payeeChanged($event)"
                formControlName="payeeName"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Payee Name"
                    noEntriesFoundLabel="No matching payee found"
                    [(ngModel)]="payeeName"
                    (ngModelChange)="filterPayee()"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let payee of filteredFacilities"
                  [value]="payee.facilityName"
                >
                  {{ payee.facilityName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="remitForm.controls['payeeName'].invalid">
                Please select the payee
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Display Selected Insurance Payer -->
          <div class="col-md-12" *ngIf="remitForm.controls['payeeName'].value">
            <label class="labelHeader">
              <div class="justifyCenterContainer">
                <mat-icon style="margin-right: 10px">business</mat-icon>
                Selected Payee
              </div>
            </label>
            <br />
            <div class="columnsGrid">
              <label class="labelBold">
                {{ remitForm.controls['payeeName'].value }}
              </label>
              <label
                style="margin-top: 8px"
                *ngIf="remitForm.controls['payeeAddressLine1'].value"
              >
                <b> {{ remitForm.controls['payeeAddressLine1'].value }}</b>
              </label>
              <label *ngIf="remitForm.controls['payeeAddressLine1'].value">
                <b>
                  {{ remitForm.controls['payeeCity'].value }},
                  {{ remitForm.controls['payeeState'].value }},
                  {{ remitForm.controls['payeeZip'].value }}
                </b>
              </label>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- Claims Information -->
    <div class="col-md-12">
      <!-- Header -->
      <label class="labelHeader">
        <div class="justifyCenterContainer">
          <mat-icon>list_alt</mat-icon>
          Claim Responses
        </div>
      </label>
      <!-- Built Claim Responses -->
      <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
        <div class="row">
          <!-- Select Claim Action Button -->
          <div class="col-md-12" style="text-align: right">
            <button
              mat-raised-button
              type="button"
              color="warn"
              (click)="selectExistingClaims()"
            >
              + Select Claim
            </button>
          </div>

          <!-- Claims -->
          <div formArrayName="claimLevelLoop">
            <div
              *ngFor="
                let claimLoop of remitForm.get('claimLevelLoop')['controls'];
                let claimLoopIndex = index
              "
            >
              <div
                class="row mat-elevation-z8 claimBox"
                [formGroupName]="claimLoopIndex"
              >
                <!-- Remove claim box icon -->
                <div class="claimBoxAction">
                  <button
                    mat-icon-button
                    type="button"
                    id="deleteButton"
                    #deleteButton
                    style="color: red; opacity: 0.5"
                    (click)="removeClaim(claimLoopIndex)"
                  >
                    <mat-icon style="font-size: 18px">
                      delete_forever
                    </mat-icon>
                  </button>
                </div>
                <!-- // Individual claim object = claimLoop -->
                <div class="col-md-3">
                  <label class="labelBold">Claim Details</label>
                  <div class="columnsGrid">
                    <!-- Claim Code -->
                    <label class="labelText">
                      Claim Code -
                      <b>{{ claimLoop.controls['claimCode'].value }}</b>
                    </label>
                    <!-- Member -->
                    <label class="labelText">
                      Member -
                      <b>
                        {{ claimLoop.controls['memberLastName'].value }},
                        {{ claimLoop.controls['memberFirstName'].value }}
                      </b>
                    </label>
                    <!-- Provider -->
                    <label class="labelText">
                      Provider -
                      <b>
                        {{ claimLoop.controls['providerLastName'].value }},
                        {{ claimLoop.controls['providerFirstName'].value }}
                      </b>
                    </label>
                    <!-- Session Details -->
                    <label class="labelText">
                      {{
                        claimLoop.controls['sessionDate'].value
                          | date : 'M/d/y, h:mm a' : currentTimezone
                      }}
                      {{ currentTimezone }}
                    </label>
                    <label class="labelText">
                      {{ claimLoop.controls['visitReason'].value }} ({{
                        claimLoop.controls['sessionDuration'].value
                      }}
                      min)
                    </label>
                  </div>
                </div>

                <!-- Claim Status Code -->
                <div class="col-md-2">
                  <label class="labelBold">
                    Claim Status Code <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      (selectionChange)="
                        claimStatusCodeChanged($event, claimLoopIndex)
                      "
                      formControlName="claimStatusCode"
                    >
                      <mat-option
                        *ngFor="let claimStatusCode of claimStatusCodeList"
                        [value]="claimStatusCode.id"
                      >
                        {{ claimStatusCode.id }} -
                        {{ claimStatusCode.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="claimLoop.controls['claimStatusCode'].invalid"
                    >
                      Please select the claim status code
                    </mat-error>
                  </mat-form-field>
                </div>

                <!--  Adjudicated Date -->
                <div class="col-md-2">
                  <label class="labelBold">
                    Adjudication Date <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="paymentEffectiveDate"
                      matInput
                      [matDatepicker]="paymentEffectiveDate"
                      [max]="maxDate"
                      placeholder="MM/DD/YYYY"
                      required
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="paymentEffectiveDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #paymentEffectiveDate></mat-datepicker>
                    <mat-error
                      *ngIf="claimLoop.controls['paymentEffectiveDate'].invalid"
                    >
                      Adjudication Date is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <!--  Submitted Amount -->
                <div class="col-md-1">
                  <label class="labelBold"> Submitted Amount </label>

                  <div class="columnsGrid">
                    <!-- Total Amount -->
                    <label class="labelText">
                      <b>{{
                        claimLoop.controls['totalAmount'].value
                          | currency : 'USD'
                      }}</b>
                    </label>
                  </div>
                </div>

                <!--  Paid Amount -->
                <div class="col-md-2">
                  <label class="labelBold">
                    Paid Amount <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="paidAmount"
                      matInput
                      type="number"
                    />
                    <span matPrefix>$</span>
                    <mat-error *ngIf="claimLoop.controls['paidAmount'].invalid">
                      Paid amount is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <!--  PR Amount -->
                <div class="col-md-2">
                  <label class="labelBold"> Patient Responsibility </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="patientResponsibility"
                      matInput
                      type="number"
                    />
                    <span matPrefix>$</span>
                    <mat-error
                      *ngIf="
                        claimLoop.controls['patientResponsibility'].invalid
                      "
                    >
                      Required
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- Claim Level Adjustments -->
                <div class="col-md-12 borderTopBox">
                  <label class="labelBold">
                    <div class="justifyCenterContainer">
                      <mat-icon>article</mat-icon>
                      Claim Level Adjustments

                      <button
                        mat-icon-button
                        type="button"
                        color="warn"
                        (click)="addClaimLevelAdjustment(claimLoopIndex)"
                      >
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </div>
                  </label>

                  <!-- claimLevelAdjustments -->
                  <div formArrayName="claimLevelAdjustments">
                    <div
                      *ngFor="
                        let claimLevelAdjustment of claimLoop.get(
                          'claimLevelAdjustments'
                        )['controls'];
                        let claimLoopclaimLevelAdjustmentIndex = index
                      "
                    >
                      <div
                        class="row"
                        [formGroupName]="claimLoopclaimLevelAdjustmentIndex"
                        style="margin-top: 15px"
                      >
                        <div class="col-md-3">
                          <label class="labelBold">
                            Adjustment Group <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="groupCode"
                              (selectionChange)="
                                claimLevelAdjustmentGroupCodeChanged(
                                  $event,
                                  claimLoopIndex,
                                  claimLoopclaimLevelAdjustmentIndex
                                )
                              "
                            >
                              <mat-option
                                *ngFor="
                                  let groupCode of adjustmentGroupCodesList
                                "
                                [value]="groupCode.code"
                              >
                                {{ groupCode.code }} -
                                {{ groupCode.description }}
                              </mat-option>
                            </mat-select>

                            <mat-error
                              *ngIf="
                                claimLevelAdjustment.controls['groupCode']
                                  .invalid
                              "
                            >
                              Please select the group
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <label class="labelBold">
                            Amount <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <input
                              matInput
                              formControlName="amount"
                              type="number"
                              min="0"
                            />
                            <span matPrefix>$</span>
                            <mat-error
                              *ngIf="
                                claimLevelAdjustment.controls['amount'].invalid
                              "
                            >
                              Please enter the amount
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <label class="labelBold">
                            Reason Code <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="reasonCode"
                              (selectionChange)="
                                claimLevelAdjustmentReasonCodeChanged(
                                  $event,
                                  claimLoopIndex,
                                  claimLoopclaimLevelAdjustmentIndex
                                )
                              "
                            >
                              <mat-option
                                *ngFor="
                                  let reasonCode of adjustmentReasonCodesList
                                "
                                [value]="reasonCode.code"
                              >
                                {{ reasonCode.code }} -
                                {{ reasonCode.description }}
                              </mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="
                                claimLevelAdjustment.controls['reasonCode']
                                  .invalid
                              "
                            >
                              Please select the reason code
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <div class="row">
                            <div class="col-6">
                              <label class="labelBold">Units</label>
                              <mat-form-field appearance="outline">
                                <input matInput formControlName="quantity" />
                              </mat-form-field>
                            </div>
                            <div
                              class="col-6"
                              style="
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                              "
                            >
                              <button
                                mat-icon-button
                                type="button"
                                id="deleteButton"
                                #deleteButton
                                style="color: red; opacity: 0.5"
                                (click)="
                                  removeClaimLevelAdjustments(
                                    claimLoopIndex,
                                    claimLoopclaimLevelAdjustmentIndex
                                  )
                                "
                                matTooltip="Remove adjustment"
                              >
                                <mat-icon>delete_forever</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Provider Level Adjustments -->
                <div class="col-md-12 borderTopBox">
                  <label class="labelBold">
                    <div class="justifyCenterContainer">
                      <mat-icon>person</mat-icon>
                      Provider Level Adjustments

                      <button
                        mat-icon-button
                        type="button"
                        color="warn"
                        (click)="addProviderLevelAdjustment(claimLoopIndex)"
                      >
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </div>
                  </label>

                  <!-- providerLevelAdjustments -->
                  <div formArrayName="providerLevelAdjustments">
                    <div
                      *ngFor="
                        let providerLevelAdjustment of claimLoop.get(
                          'providerLevelAdjustments'
                        )['controls'];
                        let providerLevelLoopIndex = index
                      "
                    >
                      <div
                        class="row"
                        [formGroupName]="providerLevelLoopIndex"
                        style="margin-top: 15px"
                      >
                        <div class="col-md-3">
                          <label class="labelBold">
                            Adjustment Group <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="groupCode"
                              (selectionChange)="
                                providerLevelAdjustmentGroupCodeChanged(
                                  $event,
                                  claimLoopIndex,
                                  providerLevelLoopIndex
                                )
                              "
                            >
                              <mat-option
                                *ngFor="
                                  let groupCode of adjustmentGroupCodesList
                                "
                                [value]="groupCode.code"
                              >
                                {{ groupCode.code }} -
                                {{ groupCode.description }}
                              </mat-option>
                            </mat-select>

                            <mat-error
                              *ngIf="
                                providerLevelAdjustment.controls['groupCode']
                                  .invalid
                              "
                            >
                              Please select the group
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <label class="labelBold">
                            Amount <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <input
                              matInput
                              formControlName="amount"
                              type="number"
                              min="0"
                            />
                            <span matPrefix>$</span>
                            <mat-error
                              *ngIf="
                                providerLevelAdjustment.controls['amount']
                                  .invalid
                              "
                            >
                              Please enter the amount
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <label class="labelBold">
                            Reason Code <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select
                              formControlName="reasonCode"
                              (selectionChange)="
                                providerLevelAdjustmentReasonCodeChanged(
                                  $event,
                                  claimLoopIndex,
                                  providerLevelLoopIndex
                                )
                              "
                            >
                              <mat-option
                                *ngFor="
                                  let reasonCode of adjustmentReasonCodesList
                                "
                                [value]="reasonCode.code"
                              >
                                {{ reasonCode.code }} -
                                {{ reasonCode.description }}
                              </mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="
                                providerLevelAdjustment.controls['reasonCode']
                                  .invalid
                              "
                            >
                              Please select the reason code
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3">
                          <div class="row">
                            <div class="col-6">
                              <label class="labelBold">Units</label>
                              <mat-form-field appearance="outline">
                                <input matInput formControlName="quantity" />
                              </mat-form-field>
                            </div>
                            <div
                              class="col-6"
                              style="
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                              "
                            >
                              <button
                                mat-icon-button
                                type="button"
                                id="deleteButton"
                                #deleteButton
                                style="color: red; opacity: 0.5"
                                (click)="
                                  removeProviderLevelAdjustments(
                                    claimLoopIndex,
                                    providerLevelLoopIndex
                                  )
                                "
                                matTooltip="Remove adjustment"
                              >
                                <mat-icon>delete_forever</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Service Line Details -->
                <div class="col-md-12 borderTopBox">
                  <label class="labelBold">
                    <div class="justifyCenterContainer">
                      <mat-icon>toc</mat-icon>
                      Service Line Details
                    </div>
                  </label>

                  <!-- Each service Line -->
                  <div formArrayName="serviceLineLoop">
                    <div class="row">
                      <div
                        *ngFor="
                          let serviceLineDetails of claimLoop.get(
                            'serviceLineLoop'
                          )['controls'];
                          let serviceLineDetailsIndex = index
                        "
                        class="col-md-12"
                      >
                        <mat-card
                          [formGroupName]="serviceLineDetailsIndex"
                          appearance="raised"
                          class="mat-elevation-z8 serviceLineBox"
                        >
                          <div class="row">
                            <!-- CPT Code -->
                            <div class="col-md-2">
                              <label class="labelBold"> CPT Code </label>
                              <div class="columnsGrid">
                                <label class="labelText">
                                  {{
                                    serviceLineDetails.controls['cptCode'].value
                                  }}
                                </label>
                              </div>
                            </div>
                            <!-- CPT Code -->
                            <div class="col-md-2">
                              <label class="labelBold"> Service Date </label>
                              <div class="columnsGrid">
                                <label class="labelText">
                                  {{
                                    serviceLineDetails.controls['serviceDate']
                                      .value
                                  }}
                                </label>
                              </div>
                            </div>
                            <!-- Total Amount -->
                            <div class="col-md-2">
                              <label class="labelBold"> Total Amount </label>
                              <div class="columnsGrid">
                                <label class="labelText">
                                  {{
                                    serviceLineDetails.controls['totalAmount']
                                      .value | currency : 'USD'
                                  }}
                                </label>
                              </div>
                            </div>
                            <!-- Adjudication Date  -->
                            <div class="col-md-2">
                              <label class="labelBold">
                                Adjudication Date
                                <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <input
                                  formControlName="adjudicationDate"
                                  matInput
                                  [matDatepicker]="adjudicationDate"
                                  [max]="maxDate"
                                  placeholder="MM/DD/YYYY"
                                  required
                                  autoSlashDate
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="adjudicationDate"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                  #adjudicationDate
                                ></mat-datepicker>
                                <mat-error
                                  *ngIf="
                                    serviceLineDetails.controls[
                                      'adjudicationDate'
                                    ].invalid
                                  "
                                >
                                  Adjudication Date is required
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <!--  Paid Unit -->
                            <div class="col-md-2">
                              <label class="labelBold">
                                Paid Unit <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <input
                                  formControlName="quantity"
                                  matInput
                                  type="number"
                                />
                                <mat-error
                                  *ngIf="
                                    serviceLineDetails.controls['quantity']
                                      .invalid
                                  "
                                >
                                  Paid unit is required
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <!--  Paid Amount -->
                            <div class="col-md-2">
                              <label class="labelBold">
                                Paid Amount <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <input
                                  formControlName="paidAmount"
                                  matInput
                                  type="number"
                                />
                                <span matPrefix>$</span>
                                <mat-error
                                  *ngIf="
                                    serviceLineDetails.controls['paidAmount']
                                      .invalid
                                  "
                                >
                                  Paid amount is required
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <!-- Adjustments -->
                            <div class="col-md-12 borderTopBox">
                              <label class="labelBold">
                                <div class="justifyCenterContainer">
                                  <mat-icon>toc</mat-icon>
                                  Service Level Adjustments

                                  <button
                                    mat-icon-button
                                    type="button"
                                    color="warn"
                                    (click)="
                                      addServiceLineLevelAdjustment(
                                        claimLoopIndex,
                                        serviceLineDetailsIndex
                                      )
                                    "
                                  >
                                    <mat-icon>add_circle</mat-icon>
                                  </button>
                                </div>
                              </label>

                              <!-- claimLevelAdjustments -->
                              <div formArrayName="claimAdjustments">
                                <div
                                  *ngFor="
                                    let serviceLineAdjustment of serviceLineDetails.get(
                                      'claimAdjustments'
                                    )['controls'];
                                    let serviceLineAdjustmentIndex = index
                                  "
                                >
                                  <div
                                    class="row"
                                    [formGroupName]="serviceLineAdjustmentIndex"
                                    style="margin-top: 15px"
                                  >
                                    <div class="col-md-3">
                                      <label class="labelBold">
                                        Adjustment Group
                                        <span style="color: red">*</span>
                                      </label>
                                      <mat-form-field appearance="outline">
                                        <mat-select
                                          formControlName="groupCode"
                                          (selectionChange)="
                                            serviceLineAdjustmentGroupCodeChanged(
                                              $event,
                                              claimLoopIndex,
                                              serviceLineDetailsIndex,
                                              serviceLineAdjustmentIndex
                                            )
                                          "
                                        >
                                          <mat-option
                                            *ngFor="
                                              let groupCode of adjustmentGroupCodesList
                                            "
                                            [value]="groupCode.code"
                                          >
                                            {{ groupCode.code }} -
                                            {{ groupCode.description }}
                                          </mat-option>
                                        </mat-select>

                                        <mat-error
                                          *ngIf="
                                            serviceLineAdjustment.controls[
                                              'groupCode'
                                            ].invalid
                                          "
                                        >
                                          Please select the group
                                        </mat-error>
                                      </mat-form-field>
                                    </div>

                                    <div class="col-md-3">
                                      <label class="labelBold">
                                        Amount <span style="color: red">*</span>
                                      </label>
                                      <mat-form-field appearance="outline">
                                        <input
                                          matInput
                                          formControlName="amount"
                                          type="number"
                                          min="0"
                                        />
                                        <span matPrefix>$</span>
                                        <mat-error
                                          *ngIf="
                                            serviceLineAdjustment.controls[
                                              'amount'
                                            ].invalid
                                          "
                                        >
                                          Please enter the amount
                                        </mat-error>
                                      </mat-form-field>
                                    </div>

                                    <div class="col-md-3">
                                      <label class="labelBold">
                                        Reason Code
                                        <span style="color: red">*</span>
                                      </label>
                                      <mat-form-field appearance="outline">
                                        <mat-select
                                          formControlName="reasonCode"
                                          (selectionChange)="
                                            serviceLineAdjustmentReasonCodeChanged(
                                              $event,
                                              claimLoopIndex,
                                              serviceLineDetailsIndex,
                                              serviceLineAdjustmentIndex
                                            )
                                          "
                                        >
                                          <mat-option
                                            *ngFor="
                                              let reasonCode of adjustmentReasonCodesList
                                            "
                                            [value]="reasonCode.code"
                                          >
                                            {{ reasonCode.code }} -
                                            {{ reasonCode.description }}
                                          </mat-option>
                                        </mat-select>
                                        <mat-error
                                          *ngIf="
                                            serviceLineAdjustment.controls[
                                              'reasonCode'
                                            ].invalid
                                          "
                                        >
                                          Please select the reason code
                                        </mat-error>
                                      </mat-form-field>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="row">
                                        <div class="col-6">
                                          <label class="labelBold">Units</label>
                                          <mat-form-field appearance="outline">
                                            <input
                                              matInput
                                              formControlName="quantity"
                                            />
                                          </mat-form-field>
                                        </div>
                                        <div
                                          class="col-6"
                                          style="
                                            display: flex;
                                            align-items: center;
                                            flex-wrap: wrap;
                                          "
                                        >
                                          <button
                                            mat-icon-button
                                            type="button"
                                            id="deleteButton"
                                            #deleteButton
                                            style="color: red; opacity: 0.5"
                                            (click)="
                                              removeServiceLineAdjustments(
                                                claimLoopIndex,
                                                serviceLineDetailsIndex,
                                                serviceLineAdjustmentIndex
                                              )
                                            "
                                            matTooltip="Remove adjustment"
                                          >
                                            <mat-icon>delete_forever</mat-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- Save Button -->
    <div class="col-md-12" style="margin-top: 65px; text-align: right">
      <button
        *ngIf="editRemitId"
        mat-raised-button
        type="button"
        style="margin-right: 20px"
        (click)="cancelEdit()"
      >
        Cancel
      </button>

      <button
        mat-raised-button
        type="button"
        [disabled]="remitForm.invalid"
        (click)="saveRemit()"
        color="warn"
      >
        <mat-icon>save</mat-icon>
        {{ editRemitId ? 'Update Remit' : 'Save Remit' }}
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
