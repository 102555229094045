import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ITreatmentDischargeSummary } from 'src/app/_models/case/treatment/discharge/treatmentdischargesummary.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TreatmentDischargeSummaryApiService {
  constructor(private httpClient: HttpClient) {}

  getTreatmentDischargeSummaryDetails(
    caseId: string,
    recordType: string,
    programId: string
  ): Observable<ResponseModal> {
    let params = new HttpParams();
    if (caseId) params = params.append('caseId', caseId);
    if (recordType) params = params.append('recordType', recordType);
    if (programId) params = params.append('programId', programId);

    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/treatmentdischargesummary`,
      { params: params }
    );
  }

  updateTreatmentDischargeSummary(
    thisTreatmentDischargeSummary: ITreatmentDischargeSummary
  ): Observable<ITreatmentDischargeSummary> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITreatmentDischargeSummary>(
        `${environment.apiUrl}/treatmentdischargesummary`,
        thisTreatmentDischargeSummary,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
