import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { ITreatmentReview } from 'src/app/_models/case/treatment/review/treatmentreview.model';
import { TreatmentReviewApiService } from 'src/app/_services/case/treatment/review/treatmentreview.service';
import { formatDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-treatment-reviews-dialog',
  templateUrl: './add-edit-treatment-reviews-dialog.component.html',
  styleUrl: './add-edit-treatment-reviews-dialog.component.css',
})
export class AddEditTreatmentReviewsDialogComponent implements OnInit {
  public action: string;
  public treatmentreviewForm: FormGroup;
  treatmentreview: ITreatmentReview;

  processing = false;
  selectedPlan: ITreatmentPlan;

  constructor(
    public dialogRef: MatDialogRef<AddEditTreatmentReviewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public treatmentreviewApiService: TreatmentReviewApiService,
    private formBuilder: FormBuilder
  ) {
    this.action = data.action;
    this.treatmentreview = data.treatmentreview;
    this.selectedPlan = data.selectedPlan;
  }

  public ngOnInit() {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.treatmentreviewForm.controls['id'].setValue(this.treatmentreview.id);
      this.treatmentreviewForm.controls['organizationId'].setValue(
        this.treatmentreview.organizationId
      );
      this.treatmentreviewForm.controls['patientId'].setValue(
        this.treatmentreview.patientId
      );
      this.treatmentreviewForm.controls['caseId'].setValue(
        this.treatmentreview.caseId
      );
      if (this.treatmentreview.planId) {
        this.treatmentreviewForm.controls['planId'].setValue(
          this.treatmentreview.planId
        );
      }

      this.treatmentreviewForm.controls['reviewDate'].setValue(
        new Date(this.treatmentreview.reviewDate)
      );
      this.treatmentreviewForm.controls['reviewPeriodStart'].setValue(
        new Date(this.treatmentreview.reviewPeriodStart)
      );
      this.treatmentreviewForm.controls['reviewPeriodEnd'].setValue(
        new Date(this.treatmentreview.reviewPeriodEnd)
      );
      this.treatmentreviewForm.controls['status'].setValue(
        this.treatmentreview.status
      );
    }
  }

  onTreatmentReviewsFormSubmit() {
    if (this.treatmentreviewForm.invalid) {
      this.treatmentreviewForm.markAllAsTouched();
      return;
    }
    this.treatmentreview = Object.assign({}, this.treatmentreview);
    this.treatmentreview = Object.assign(
      this.treatmentreview,
      this.treatmentreviewForm.value
    );
    if (this.treatmentreview.reviewDate !== '') {
      this.treatmentreview.reviewDate = formatDate(
        new Date(this.treatmentreview.reviewDate)
      );
    }
    if (this.treatmentreview.reviewPeriodStart !== '') {
      this.treatmentreview.reviewPeriodStart = formatDate(
        new Date(this.treatmentreview.reviewPeriodStart)
      );
    }
    if (this.treatmentreview.reviewPeriodEnd !== '') {
      this.treatmentreview.reviewPeriodEnd = formatDate(
        new Date(this.treatmentreview.reviewPeriodEnd)
      );
    }

    this.processing = true;

    if (this.action === 'EDIT') {
      this.treatmentreviewApiService
        .updateTreatmentReview(this.treatmentreview)
        .subscribe(() => {
          this.processing = false;

          this.dialogRef.close('success');
        });
    } else {
      this.treatmentreviewApiService
        .addTreatmentReview(this.treatmentreview)
        .subscribe(() => {
          this.processing = false;

          this.dialogRef.close('success');
        });
    }
  }

  private buildForm() {
    this.treatmentreviewForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(
        this.selectedPlan.patientId,
        Validators.required
      ),
      caseId: new FormControl(this.selectedPlan.caseId, Validators.required),
      planId: new FormControl(this.selectedPlan.id, Validators.required),
      reviewDate: new FormControl('', Validators.required),
      reviewPeriodStart: new FormControl('', Validators.required),
      reviewPeriodEnd: new FormControl('', Validators.required),
      status: new FormControl('', Validators.compose([Validators.required])),
    });
  }
}
