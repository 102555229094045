<div
  class="main-content"
  style="background-color: #eee"
  *ngIf="!isLoadingResults"
>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>
  <div class="container-fluid" *ngIf="!isLoadingResults">
    <br />
    <div class="row pad10">
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Relationship</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="relationshipChartData"
                  [options]="chartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Goals and Topics</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="goalsChartData"
                  [options]="chartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10">
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Approach or Method</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="approachChartData"
                  [options]="chartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Overall</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="overallChartData"
                  [options]="chartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
