import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { FacilitySearchComponent } from 'src/app/shared/components/search/facility-search/facility-search.component';
import { ProviderSearchComponent } from 'src/app/shared/components/search/provider-search/provider-search.component';
import { ReferralStaffSearchComponent } from 'src/app/shared/components/search/referral-staff-search/referral-staff-search.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PatientDetailModule } from '../patient-details/patient-details.module';
import { AddAttemptDialogComponent } from './add-attempt-dialog/add-attempt-dialog.component';
import { AddEditReferralComponent } from './add-edit-referral/add-edit-referral.component';
import { ReferralAttemptDetailsViewComponent } from './referral-attempt-details-view/referral-attempt-details-view.component';
import { ReferralEligibilityTestComponent } from './referral-eligibility-test/referral-eligibility-test.component';
import { ReferralHomeComponent } from './referral-home/referral-home.component';

const routes: Routes = [
  {
    path: 'home',
    component: ReferralHomeComponent,
  },
];

@NgModule({
  declarations: [
    ReferralHomeComponent,
    ProviderSearchComponent,
    FacilitySearchComponent,
    AddEditReferralComponent,
    AddAttemptDialogComponent,
    ReferralStaffSearchComponent,
    ReferralAttemptDetailsViewComponent,
    ReferralEligibilityTestComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    RouterModule.forChild(routes),
    CustomDirectivesModule,
    NgxMaterialTimepickerModule,
    NgxGpAutocompleteModule,
    OwlDateTimeModule,
    PatientDetailModule,
  ],
  exports: [ReferralHomeComponent],
})
export class ReferralModule {}
