<mat-card
  appearance="raised"
  style="border-radius: 15px"
  class="mat-elevation-z8"
>
  <ngx-spinner
    *ngIf="isProcessing"
    bdColor="#fff"
    size="medium"
    color="lightsteelblue"
    type="line-spin-fade"
    style="position: relative; display: contents"
    [fullScreen]="false"
  ></ngx-spinner>

  <div class="row" *ngIf="!isProcessing">
    <!-- Profile Header -->
    <div class="col-md-12" style="text-align: center">
      <i
        class="fa fa-user-circle-o fa-5x"
        style="color: cornflowerblue; font-size: 50px"
      ></i>
    </div>
    <div
      class="col-md-12"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      "
    >
      <span style="font-weight: bold; font-size: 16px; margin-top: 5px">
        {{ patient?.lastName }}, {{ patient?.firstName }}
        {{ patient?.middleName }}
      </span>
      <label style="margin: 0; word-break: break-all" *ngIf="patient?.email">
        {{ patient?.email }}
      </label>
      <label style="margin: 0" *ngIf="patient?.phoneNumber">
        {{ patient?.phoneNumber | phoneFormat }}
      </label>
    </div>

    <!-- Member Additional Details -->
    <div class="col-md-12" style="text-align: center; margin-top: 15px">
      <label
        style="
          color: #2196f3;
          cursor: pointer;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        "
        (click)="showMemberInformation = true"
        *ngIf="!showMemberInformation"
      >
        Show My Information <mat-icon>expand_more</mat-icon>
      </label>
      <label
        style="
          color: #2196f3;
          cursor: pointer;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        "
        (click)="showMemberInformation = false"
        *ngIf="showMemberInformation"
      >
        Hide My Information <mat-icon>expand_less</mat-icon>
      </label>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-top: 15px;
        width: 100%;
      "
      *ngIf="showMemberInformation"
    >
      <!-- DOB -->
      <div class="twoGridContainer">
        <div class="text-bold">DOB</div>
        <div style="text-transform: capitalize">
          {{ patient?.dateOfBirth }}
        </div>
      </div>
      <!-- Gender -->
      <div
        class="twoGridContainer"
        *ngIf="
          patient?.gender === 'M' ||
          patient?.gender === 'F' ||
          patient?.gender === 'MALE' ||
          patient?.gender === 'FEMALE'
        "
      >
        <div class="text-bold">Gender</div>
        <div style="text-transform: capitalize">
          {{
            patient?.gender === 'M'
              ? 'Male'
              : patient?.gender === 'F'
              ? 'Female'
              : patient?.gender
          }}
        </div>
      </div>
      <!-- Height -->
      <div class="twoGridContainer" *ngIf="patient?.height">
        <div class="text-bold">Height</div>
        <div>{{ patient?.height }} in</div>
      </div>
      <!-- Weight -->
      <div class="twoGridContainer" *ngIf="patient?.weight">
        <div class="text-bold">Weight</div>
        <div>{{ patient?.weight }} Lb</div>
      </div>
      <!-- Marital Status-->
      <div
        class="twoGridContainer"
        *ngIf="
          patient?.maritalStatus &&
          patient?.maritalStatus != 'UNKWN' &&
          patient?.maritalStatus != 'OTHER'
        "
      >
        <div class="text-bold">Marital Status</div>
        <div style="text-transform: capitalize">
          {{ getMaritalStatus() }}
        </div>
      </div>
      <!-- Employment Status-->
      <div class="twoGridContainer" *ngIf="patient?.employmentStatus">
        <div class="text-bold">Employment Status</div>
        <div style="text-transform: capitalize">
          {{ getEmploymentStatus() }}
        </div>
      </div>
      <!-- Language Preferred -->
      <div
        class="twoGridContainer"
        *ngIf="
          patient?.preferredLanguage &&
          patient?.preferredLanguage != 'NTSPF' &&
          patient?.preferredLanguage != 'OTHER'
        "
      >
        <div class="text-bold">Preffered Language</div>
        <div>
          {{ getPreferredLanguage() }}
        </div>
      </div>
      <!-- Home Phone -->
      <div class="twoGridContainer" *ngIf="patient?.homePhoneNumber">
        <div class="text-bold">Home Phone</div>
        <div style="text-transform: capitalize">
          {{ patient?.homePhoneNumber | phoneFormat }}
        </div>
      </div>
      <!-- Work Phone -->
      <div class="twoGridContainer" *ngIf="patient?.workPhoneNumber">
        <div class="text-bold">Work Phone</div>
        <div style="text-transform: capitalize">
          {{ patient?.workPhoneNumber | phoneFormat }}
        </div>
      </div>
      <!-- Address -->
      <div class="twoGridContainer" *ngIf="patient?.addressLine1">
        <div class="text-bold">Address</div>
        <div style="text-transform: capitalize">
          {{ patient?.addressLine1 }} {{ patient?.addressLine2 }}
          <br />
          {{ patient?.city }}&nbsp; {{ patient?.state }}&nbsp;
          {{ patient?.zip }}
        </div>
      </div>
    </div>
  </div>
</mat-card>
