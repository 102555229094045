<!-- Top Navigation with patient details -->
<p
  style="font-size: 16px; font-weight: 450; white-space: nowrap"
  *ngIf="patient"
>
  <button
    type="button"
    mat-mini-fab
    color="primary"
    (click)="sideBarVisible = !sideBarVisible"
    style="margin-right: 15px"
  >
    <mat-icon>{{ sideBarVisible ? 'menu_open' : 'menu' }}</mat-icon>
  </button>

  <a routerLink="/" routerLinkActive="active">
    <em
      class="material-icons text_align-center"
      style="font-size: 18px; margin-top: -3px"
      >home</em
    > </a
  >&nbsp; / &nbsp;
  <a [routerLink]="['/patients']"> Members </a>
  &nbsp; / &nbsp; {{ patient?.lastName }}, {{ patient?.firstName }}
</p>

<div class="row" *ngIf="patient">
  <div class="col-lg-3" [@slideInOut]="sideBarVisible ? 'true' : 'false'">
    <!-- Member Profile Information -->
    <app-member-profile
      [patient]="patient"
      [hasPatientPortalFeature]="hasPatientPortalFeature"
      [hasMemberEditAccess]="hasMemberEditAccess"
      [hasContactsViewAccess]="hasContactsViewAccess"
      (output)="triggerEvent($event)"
    ></app-member-profile>

    <!-- Member Alerts -->
    <app-member-alerts
      [patient]="patient"
      [isAlertAcknowledgementEnabled]="isAlertAcknowledgementEnabled"
      [reloadAlerts]="reloadAlerts.asObservable()"
      (output)="triggerEvent($event)"
    >
    </app-member-alerts>

    <!-- Member Notes -->
    <mat-card
      appearance="raised"
      style="margin-top: 20px; border-radius: 15px"
      *ngIf="hasNoteViewAccess"
      class="mat-elevation-z8"
    >
      <p
        class="subCardHeader"
        (click)="patientNotesExpanded = !patientNotesExpanded"
      >
        <span
          class="material-icons"
          style="color: cornflowerblue; font-size: 25px"
        >
          edit_note
        </span>
        Notes ({{ memberAllNotes?.total > 0 ? memberAllNotes?.total : 0 }})
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="!patientNotesExpanded"
        >
          arrow_drop_down
        </span>
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="patientNotesExpanded"
        >
          arrow_drop_up
        </span>
      </p>

      <div *ngIf="patientNotesExpanded">
        <mat-divider></mat-divider>
        <div
          *ngIf="patient?.status === 'Active' && hasNoteAddAccess"
          style="text-align: right; margin: 10px"
        >
          <button
            style="margin-top: 8px"
            mat-raised-button
            color="warn"
            type="button"
            (click)="addMemberNote()"
          >
            + Add Note
          </button>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="memberAllNotesProcessing"
        ></mat-progress-bar>

        <!-- List of member notes -->
        <div
          *ngIf="
            (!memberAllNotes?.total || memberAllNotes?.total == 0) &&
            !memberAllNotesProcessing
          "
          style="
            text-align: center;
            font-weight: 450;
            font-size: 14px;
            color: #d92550;
          "
        >
          No note found
        </div>
        <div
          *ngIf="memberAllNotes?.total > 0"
          style="max-height: 400px; overflow-x: hidden; margin-top: 10px"
        >
          <div *ngFor="let note of memberAllNotes.items">
            <div class="noteContainer mat-elevation-z8">
              <label class="labelText" style="width: 95%"
                >{{ note.text }}
              </label>
              <!-- Footer  -->
              <div class="createdByFooter">
                <div>
                  <i class="material-icons" style="vertical-align: bottom">
                    person
                  </i>
                  <span class="mat-small" style="font-size: 10px">
                    Created by: {{ note.createdBy }}
                  </span>
                </div>

                <div>
                  <i class="material-icons" style="vertical-align: bottom">
                    event
                  </i>
                  <span class="mat-small" style="font-size: 10px">
                    {{ note.createdDate | date : 'MM/dd/YYYY' }}
                  </span>
                </div>
              </div>

              <!-- Action  -->
              <div
                class="caseNoteAction"
                *ngIf="patient?.status === 'Active' && hasNoteEditAccess"
              >
                <div
                  id="editButton"
                  class="material-icons"
                  style="
                    color: gray;
                    font-size: 15px;
                    cursor: pointer;
                    z-index: 2;
                    opacity: 0.5;
                  "
                  type="button"
                  (click)="editMemberNote(note)"
                >
                  edit
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            [length]="memberAllNotes.total"
            [pageSize]="memberAllNotes.per_page"
            *ngIf="memberAllNotes.total > memberAllNotes.per_page"
            [showFirstLastButtons]="true"
            (page)="getNextMemberNotes($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-card>

    <!-- Pending Assessments -->
    <mat-card
      appearance="raised"
      style="margin-top: 20px; border-radius: 15px"
      [matBadge]="
        assessmentRequests.length > 0 ? assessmentRequests.length : '&#8288;'
      "
      matBadgeColor="warn"
      [matBadgeHidden]="!assessmentRequests || assessmentRequests?.length === 0"
      *ngIf="assessmentRequests.length > 0"
      class="mat-elevation-z8"
    >
      <p
        class="subCardHeader"
        (click)="newAssessmentsExpanded = !newAssessmentsExpanded"
      >
        <span class="material-icons" style="color: #ff5722; font-size: 25px">
          report_gmailerrorred
        </span>
        Pending Assessments
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="!newAssessmentsExpanded"
        >
          arrow_drop_down
        </span>
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="newAssessmentsExpanded"
        >
          arrow_drop_up
        </span>
      </p>

      <mat-progress-bar
        mode="indeterminate"
        *ngIf="pendingAssessmentProcessing"
      ></mat-progress-bar>

      <!-- Scroll View -->
      <div
        style="
          max-height: 400px;
          overflow-x: hidden;
          margin-top: 10px;
          margin-left: 10px;
        "
        *ngIf="newAssessmentsExpanded"
      >
        <mat-divider></mat-divider>
        <div
          *ngIf="
            (!assessmentRequests?.length || assessmentRequests?.length == 0) &&
            !pendingAssessmentProcessing
          "
          style="
            text-align: center;
            font-weight: 450;
            font-size: 14px;
            color: #d92550;
          "
        >
          No new assessment requested
        </div>

        <div class="assessmentGridContainer" style="margin-top: 8px">
          <div *ngFor="let assessmentRequest of assessmentRequests">
            <div class="card assessmentRequestCard">
              <span style="font-weight: 700; opacity: 0.8; font-size: 11px">{{
                assessmentRequest.assessmentType
              }}</span>
              <span
                style="font-size: 9px; font-weight: bold"
                *ngIf="assessmentRequest?.sessionCode"
              >
                Session Id :
                <span style="cursor: pointer; color: #2196f3">
                  {{ assessmentRequest?.sessionCode }}
                </span>
              </span>

              <div class="assessmentRequestRequested">
                Requested by {{ assessmentRequest.requestorName }}
                {{ assessmentRequest.createdDate | date : 'mediumDate' }}
              </div>
              <div class="assessmentRequestAction">
                <button
                  mat-button
                  type="button"
                  color="warn"
                  *ngIf="hasHospitalDeviceFeature"
                  (click)="generateAcessCode(assessmentRequest)"
                  [disabled]="otpLoader"
                >
                  Request OTP
                </button>
                <button
                  mat-button
                  type="button"
                  color="primary"
                  *ngIf="hasAssessmentTakeAddAccess"
                  (click)="completeAssessment(assessmentRequest)"
                >
                  Take Assessment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- Recurring Assessment -->
    <mat-card
      class="mat-elevation-z8"
      appearance="raised"
      style="margin-top: 20px; border-radius: 15px"
      *ngIf="reoccurringAssessments && reoccurringAssessments?.total > 0"
    >
      <!-- Heading -->
      <p class="subCardHeader" (click)="recurringExpanded = !recurringExpanded">
        <span class="material-icons" style="font-size: 25px; color: #59a310">
          update
        </span>
        Recurring Assessments &nbsp; ({{
          reoccurringAssessments?.total > 0 ? reoccurringAssessments?.total : 0
        }})
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="!recurringExpanded"
        >
          arrow_drop_down
        </span>
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="recurringExpanded"
        >
          arrow_drop_up
        </span>
      </p>

      <mat-progress-bar
        mode="indeterminate"
        *ngIf="reoccurringProcessing"
      ></mat-progress-bar>

      <!-- Add button -->
      <div *ngIf="recurringExpanded">
        <mat-divider></mat-divider>
        <!-- List of Reoccurring Assessments -->
        <div
          *ngIf="
            (!reoccurringAssessments?.total ||
              reoccurringAssessments?.total == 0) &&
            !reoccurringProcessing
          "
          style="
            text-align: center;
            font-weight: 450;
            font-size: 14px;
            color: #d92550;
          "
        >
          No recurring assessment found
        </div>
        <div
          *ngIf="reoccurringAssessments?.total > 0"
          style="
            max-height: 400px;
            overflow-x: hidden;
            margin-top: 10px;
            margin-left: 20px;
          "
        >
          <div
            *ngFor="
              let reoccurring of reoccurringAssessments.items;
              let i = index
            "
          >
            <div class="reoccurringCard">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-left: 10px;
                "
                [style.border-left]="getColor(i)"
              >
                <span
                  style="
                    font-weight: 900;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                >
                  {{ reoccurring.assessmentCategory }}
                </span>
                <span
                  style="
                    font-weight: 900;
                    font-size: 12px;
                    opacity: 0.7;
                    white-space: break-spaces;
                  "
                >
                  {{ reoccurring.assessmentType }}
                </span>
                <span
                  style="
                    font-weight: 900;
                    font-size: 12px;
                    opacity: 0.7;
                    white-space: break-spaces;
                    border: 2px solid #6d2abd;
                    margin-right: auto;
                  "
                >
                  Frequency: {{ reoccurring.frequency }}
                </span>
                <span
                  style="
                    font-weight: 700;
                    font-size: 10px;
                    opacity: 0.7;
                    white-space: break-spaces;
                  "
                >
                  Requested By&nbsp;&nbsp;&nbsp;&nbsp;:
                  {{ reoccurring.requestorName }}
                </span>
                <span
                  style="
                    font-weight: 700;
                    font-size: 10px;
                    opacity: 0.7;
                    white-space: break-spaces;
                    margin-top: -10px;
                  "
                >
                  Start Date &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {{ reoccurring.startDate | date : 'mediumDate' }}
                </span>
                <span
                  style="
                    font-weight: 700;
                    font-size: 10px;
                    opacity: 0.7;
                    white-space: break-spaces;
                    margin-top: -10px;
                  "
                >
                  End Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                  &nbsp;&nbsp;:
                  {{ reoccurring.endDate | date : 'mediumDate' }}
                </span>
                <span
                  *ngIf="reoccurring?.lastAssignedDate"
                  style="
                    font-weight: 700;
                    font-size: 10px;
                    opacity: 0.7;
                    white-space: break-spaces;
                    margin-top: -10px;
                  "
                >
                  Last Requested :
                  {{ reoccurring.lastAssignedDate | date : 'mediumDate' }}
                </span>
              </div>
              <div
                style="position: absolute; right: 10px; top: 0"
                *ngIf="hasMemberAssessmentDeleteAccess"
              >
                <button
                  id="recurringCancelButton"
                  mat-icon-button
                  style="cursor: pointer; opacity: 0.4"
                  (click)="deleteReoccurringAssessment(reoccurring.id)"
                  [disabled]="reoccurringProcessing"
                >
                  <mat-icon style="font-size: 18px">clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            [length]="reoccurringAssessments.total"
            [pageSize]="reoccurringAssessments.per_page"
            *ngIf="
              reoccurringAssessments.total > reoccurringAssessments.per_page
            "
            (page)="getNextReoccurring($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-card>

    <!-- Virtual Appointment Section -->
    <mat-card
      class="mat-elevation-z8"
      appearance="raised"
      style="margin-top: 20px; border-radius: 15px"
      *ngIf="hasTeleHealthEnabled"
    >
      <p class="subCardHeader" (click)="virtualExpanded = !virtualExpanded">
        <span class="material-icons" style="font-size: 22px"> laptop </span>
        Virtual Appointments ({{
          virtualAppointments?.total > 0 ? virtualAppointments?.total : 0
        }})
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="!virtualExpanded"
        >
          arrow_drop_down
        </span>
        <span
          class="material-icons"
          style="position: absolute; right: 10px"
          *ngIf="virtualExpanded"
        >
          arrow_drop_up
        </span>
      </p>
      <div *ngIf="virtualExpanded">
        <mat-divider></mat-divider>
        <div style="margin: 10px">
          <!-- Selection Tab -->
          <div>
            <button
              style="margin-top: 8px"
              mat-button
              type="button"
              [class]="
                selectedVirtualAppointment == 'Upcoming' ? 'activeTab' : 'none'
              "
              (click)="changeVirtualAppointment('Upcoming')"
            >
              Today's & Upcoming
            </button>
            <button
              mat-button
              type="button"
              [class]="
                selectedVirtualAppointment == 'Past' ? 'activeTab' : 'none'
              "
              (click)="changeVirtualAppointment('Past')"
            >
              Past
            </button>
          </div>
        </div>
        <!-- Progress Bar -->
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="virtualAppointmentProcessing"
        ></mat-progress-bar>
        <!-- Virtual Appointment Contents -->
        <div
          *ngIf="
            (!virtualAppointments?.total || virtualAppointments?.total == 0) &&
            !virtualAppointmentProcessing
          "
          style="
            text-align: center;
            font-weight: 450;
            font-size: 14px;
            color: #d92550;
          "
        >
          No {{ selectedVirtualAppointment }} Appointment
        </div>
        <div
          *ngIf="virtualAppointments?.total > 0"
          style="
            max-height: 400px;
            overflow-x: hidden;
            margin-top: 10px;
            margin-left: 20px;
          "
        >
          <div class="assessmentGridContainer">
            <div *ngFor="let appointments of virtualAppointments.items">
              <div class="card assessmentRequestCard">
                <span style="font-weight: 700; opacity: 0.8">
                  {{
                    appointments.start
                      | date : 'M/d/y, h:mm a' : currentTimezone
                  }}
                  {{ currentTimezone }}
                </span>
                <div
                  class="assessmentRequestAction"
                  *ngIf="selectedVirtualAppointment == 'Upcoming'"
                >
                  <button
                    mat-button
                    color="warn"
                    [disabled]="!isJoinValid(appointments) || hostUrlProcessing"
                    (click)="JoinMeeting(appointments.id)"
                  >
                    Join
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            [length]="virtualAppointments.total"
            [pageSize]="virtualAppointments.per_page"
            *ngIf="virtualAppointments.total > virtualAppointments.per_page"
            (page)="getNextVirtual($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-card>

    <!-- Extra break for smaller screens gap -->
    <br />
  </div>

  <!-- Tab Selection -->
  <div
    class="col-lg-9"
    [@slideInOut]="sideBarVisible ? 'true' : 'fullWidth'"
    (@slideInOut.start)="onAnimationStart($event)"
    (@slideInOut.done)="onAnimationEnd($event)"
  >
    <nav
      mat-tab-nav-bar
      backgroundColor="primary"
      class="mat-elevation-z8"
      [tabPanel]="tabPanel"
      *ngIf="activeTab != 'esign'"
    >
      <a
        mat-tab-link
        [routerLink]="['/main/member', patientId, 'MEMBER']"
        [queryParams]="{ secondaryId: '0' }"
        [active]="activeTab === 'MEMBER'"
        (click)="triggerTabReloadEvent('MEMBER')"
      >
        Member
      </a>
      <a
        mat-tab-link
        *ngIf="hasCaseManagementFeature && hasCaseViewAccess"
        [routerLink]="['/main/member', patientId, 'CARE']"
        [active]="activeTab === 'CARE'"
        (click)="triggerTabReloadEvent('CARE')"
      >
        Care
      </a>

      <a
        *ngIf="hasTherapySessionFeature && hasTherapySessionViewAccess"
        mat-tab-link
        [routerLink]="['/main/member', patientId, 'NOTES']"
        [active]="activeTab === 'NOTES'"
        (click)="triggerTabReloadEvent('NOTES')"
      >
        Notes
      </a>

      <a
        *ngIf="
          hasTherapySessionFeature && hasClaimsFeature && hasClaimsViewAccess
        "
        mat-tab-link
        [active]="activeTab === 'CLAIMS'"
        [routerLink]="['/main/member', patientId, 'CLAIMS']"
        (click)="triggerTabReloadEvent('CLAIMS')"
      >
        Claims
      </a>

      <a
        *ngIf="hasERXFeatures && prescriptionEnabled"
        mat-tab-link
        (click)="activeTab = 'ERX'"
        [active]="activeTab === 'ERX'"
      >
        eRx
      </a>

      <a
        mat-tab-link
        (click)="activeTab = 'DOCUMENTS'"
        [routerLink]="['/main/member', patientId, 'DOCUMENTS']"
        [active]="activeTab === 'DOCUMENTS'"
        (click)="triggerTabReloadEvent('DOCUMENTS')"
      >
        Documents
      </a>

      <a
        mat-tab-link
        *ngIf="hasMoodViewAccess || hasIncidentViewAccess"
        [routerLink]="['/main/member', patientId, 'REPORTS']"
        [active]="activeTab === 'REPORTS'"
        (click)="triggerTabReloadEvent('REPORTS')"
      >
        Reports
      </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
      <app-member-tab
        *ngIf="activeTab === 'MEMBER'"
        [subSectionId]="secondaryId"
        [secondaryTab]="secondaryTab"
        [orgConfig]="orgConfig"
        [patient]="patient"
        [isPhysician]="isPhysician"
        [memberTabPreSelected]="memberTabPreSelected"
        [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
        [hasTherapySessionFeature]="hasTherapySessionFeature"
        [hasTeleHealthEnabled]="hasTeleHealthEnabled"
        [prescriptionEnabled]="prescriptionEnabled"
        [hasMemberEditAccess]="hasMemberEditAccess"
        [hasMemberPaymentsViewAccess]="hasMemberPaymentsViewAccess"
        [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [hasMemberPaymentsEditAccess]="hasMemberPaymentsEditAccess"
        [hasMemberPaymentsDeleteAccess]="hasMemberPaymentsDeleteAccess"
        [hasTherapySessionViewAccess]="hasTherapySessionViewAccess"
        [hasNoteViewAccess]="hasNoteViewAccess"
        [hasNoteAddAccess]="hasNoteAddAccess"
        [hasNoteEditAccess]="hasNoteEditAccess"
        [hasMoodViewAccess]="hasMoodViewAccess"
        [hasMoodAddAccess]="hasMoodAddAccess"
        [hasIncidentViewAccess]="hasIncidentViewAccess"
        [hasIncidentAddAccess]="hasIncidentAddAccess"
        [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasIncidentAddAccess]="hasIncidentAddAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
        [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
        [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
        [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
        [hasMedicationViewAccess]="hasMedicationViewAccess"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
        [hasAllergiesViewAccess]="hasAllergiesViewAccess"
        [hasAllergiesAddAccess]="hasAllergiesAddAccess"
        [hasAllergiesEditAccess]="hasAllergiesEditAccess"
        [hasAllergiesDeleteAccess]="hasAllergiesDeleteAccess"
        [hasPhysiciansViewAccess]="hasPhysiciansViewAccess"
        [hasPhysiciansAddAccess]="hasPhysiciansAddAccess"
        [hasPhysiciansEditAccess]="hasPhysiciansEditAccess"
        [hasPhysiciansDeleteAccess]="hasPhysiciansDeleteAccess"
        [hasContactsViewAccess]="hasContactsViewAccess"
        [hasContactsAddAccess]="hasContactsAddAccess"
        [hasContactsEditAccess]="hasContactsEditAccess"
        [hasContactsDeleteAccess]="hasContactsDeleteAccess"
        [hasMemberHistoryViewAccess]="hasMemberHistoryViewAccess"
        [hasMemberHistoryEditAccess]="hasMemberHistoryEditAccess"
        [hasFamilyHistoryViewAccess]="hasFamilyHistoryViewAccess"
        [hasFamilyHistoryEditAccess]="hasFamilyHistoryEditAccess"
        [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
        [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
        [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
        [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
        [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
        [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
        [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
        [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
        [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
        [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
        (output)="triggerEvent($event)"
      >
      </app-member-tab>

      <app-member-cases
        *ngIf="activeTab === 'CARE'"
        [selectedCaseId]="secondaryId"
        [secondaryTab]="secondaryTab"
        [patient]="patient"
        [hasCaseViewAccess]="hasCaseViewAccess"
        [hasClosedCaseViewAccess]="hasClosedCaseViewAccess"
        [hasCaseAddAccess]="hasCaseAddAccess"
        [hasCaseEditAccess]="hasCaseEditAccess"
        [hasCaseTaskViewAccess]="hasCaseTaskViewAccess"
        [hasCaseTaskAddAccess]="hasCaseTaskAddAccess"
        [hasCaseTaskEditAccess]="hasCaseTaskEditAccess"
        [hasCaseTaskDeleteAccess]="hasCaseTaskDeleteAccess"
        [hasCaseNotesViewAccess]="hasCaseNotesViewAccess"
        [hasCaseNotesAddAccess]="hasCaseNotesAddAccess"
        [hasCaseNotesEditAccess]="hasCaseNotesEditAccess"
        [hasCaseNotesDeleteAccess]="hasCaseNotesDeleteAccess"
        [hasCaseDocumentsViewAccess]="hasCaseDocumentsViewAccess"
        [hasCaseDocumentsAddAccess]="hasCaseDocumentsAddAccess"
        [hasCaseDocumentsDeleteAccess]="hasCaseDocumentsDeleteAccess"
        [hasCaseLogViewAccess]="hasCaseLogViewAccess"
        [hasCaseLogAddAccess]="hasCaseLogAddAccess"
        [hasCaseLogEditAccess]="hasCaseLogEditAccess"
        [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
        [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        [reloadCasesTab]="reloadCasesTab.asObservable()"
      ></app-member-cases>

      <app-sessions
        *ngIf="activeTab === 'NOTES'"
        [sessionId]="secondaryId"
        [secondaryTab]="secondaryTab"
        [patient]="patient"
        [orgConfig]="orgConfig"
        [isBPSAEnforced]="isBPSAEnforced"
        [preSelectedSession]="preSelectedSession"
        [hasMemberPaymentsViewAccess]="hasMemberPaymentsViewAccess"
        [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [hasMemberPaymentsEditAccess]="hasMemberPaymentsEditAccess"
        [hasMemberPaymentsDeleteAccess]="hasMemberPaymentsDeleteAccess"
        [hasCaseManagementFeature]="hasCaseManagementFeature"
        [hasMemberEditAccess]="hasMemberEditAccess"
        [hasTeleHealthEnabled]="hasTeleHealthEnabled"
        [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
        [hasClaimsFeature]="hasClaimsFeature"
        [hasASIFeatures]="hasASIFeatures"
        [hasTherapySessionViewAccess]="hasTherapySessionViewAccess"
        [hasTherapySessionAddAccess]="hasTherapySessionAddAccess"
        [hasTherapySessionEditAccess]="hasTherapySessionEditAccess"
        [hasTherapySessionDeleteAccess]="hasTherapySessionDeleteAccess"
        [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
        [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
        [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
        [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
        [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
        [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
        [hasClinicalNotesViewAccess]="hasClinicalNotesViewAccess"
        [hasClinicalNotesAddAccess]="hasClinicalNotesAddAccess"
        [hasClinicalNotesEditAccess]="hasClinicalNotesEditAccess"
        [hasSubjectivesViewAccess]="hasSubjectivesViewAccess"
        [hasSubjectivesEditAccess]="hasSubjectivesEditAccess"
        [hasObjectivesViewAccess]="hasObjectivesViewAccess"
        [hasObjectivesEditAccess]="hasObjectivesEditAccess"
        [hasBillingViewAccess]="hasBillingViewAccess"
        [hasBillingEditAccess]="hasBillingEditAccess"
        [hasSessionDocumentsViewAccess]="hasSessionDocumentsViewAccess"
        [hasSessionDocumentsAddAccess]="hasSessionDocumentsAddAccess"
        [hasSessionDocumentsDeleteAccess]="hasSessionDocumentsDeleteAccess"
        [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
        [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
        [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
        [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
        [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
        [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
        [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
        [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
        [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
        [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
        [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
        [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
        [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
        [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
        [hasMedicationViewAccess]="hasMedicationViewAccess"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
        [prescriptionEnabled]="prescriptionEnabled"
        (output)="triggerEvent($event)"
        [reload]="reloadSessionTab.asObservable()"
      ></app-sessions>

      <app-member-erx
        *ngIf="activeTab === 'ERX'"
        [patient]="patient"
        [prescriptionEnabled]="prescriptionEnabled"
        [hasMedicationViewAccess]="hasMedicationViewAccess"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
        (output)="triggerEvent($event)"
      ></app-member-erx>

      <app-member-claims
        *ngIf="activeTab === 'CLAIMS'"
        [patient]="patient"
        [hasBillingViewAccess]="hasBillingViewAccess"
        [hasBillingEditAccess]="hasBillingEditAccess"
        [hasClaimsViewAccess]="hasClaimsViewAccess"
        [hasClaimsEditAccess]="hasClaimsEditAccess"
        [reload]="reloadClaimsTab.asObservable()"
      >
      </app-member-claims>

      <app-member-report
        *ngIf="activeTab === 'REPORTS'"
        [patient]="patient"
        [hasMoodViewAccess]="hasMoodViewAccess"
        [hasMoodAddAccess]="hasMoodAddAccess"
        [hasIncidentViewAccess]="hasIncidentViewAccess"
        [hasIncidentAddAccess]="hasIncidentAddAccess"
        [reload]="reloadReportsTab.asObservable()"
      >
      </app-member-report>

      <app-member-documents
        *ngIf="activeTab === 'DOCUMENTS'"
        [patient]="patient"
        [reload]="reloadDocumentsTab.asObservable()"
        (output)="triggerEvent($event)"
      >
      </app-member-documents>
    </mat-tab-nav-panel>
  </div>
</div>
