import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITaylorManifest } from 'src/app/_models/assessments/anxiety/taylormanifest.model';
import {
  TaylorManifestApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-taylor-manifest',
  templateUrl: './taylor-manifest.component.html',
  styleUrls: ['./taylor-manifest.component.css'],
})
export class TaylorManifestComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public taylorManifestApiService: TaylorManifestApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }

  public riskScore: number;
  public taylorManifestForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  taylorManifestAssessment: ITaylorManifest;
  taylorManifestAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.taylorManifestForm, this.errors);

    this.loadTaylorManifestAssessment();
  }

  loadTaylorManifestAssessment() {
    this.taylorManifestForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.taylorManifestApiService
        .getTaylorManifestDetails(this.assessmentId)
        .subscribe((taylorManifestAssessment) => {
          if (taylorManifestAssessment.data) {
            this.taylorManifestForm.controls['q1'].setValue(
              taylorManifestAssessment.data.q1.answer
            );
            this.taylorManifestForm.controls['q2'].setValue(
              taylorManifestAssessment.data.q2.answer
            );
            this.taylorManifestForm.controls['q3'].setValue(
              taylorManifestAssessment.data.q3.answer
            );
            this.taylorManifestForm.controls['q4'].setValue(
              taylorManifestAssessment.data.q4.answer
            );
            this.taylorManifestForm.controls['q5'].setValue(
              taylorManifestAssessment.data.q5.answer
            );
            this.taylorManifestForm.controls['q6'].setValue(
              taylorManifestAssessment.data.q6.answer
            );
            this.taylorManifestForm.controls['q7'].setValue(
              taylorManifestAssessment.data.q7.answer
            );
            this.taylorManifestForm.controls['q8'].setValue(
              taylorManifestAssessment.data.q8.answer
            );
            this.taylorManifestForm.controls['q9'].setValue(
              taylorManifestAssessment.data.q9.answer
            );
            this.taylorManifestForm.controls['q10'].setValue(
              taylorManifestAssessment.data.q10.answer
            );
            this.taylorManifestForm.controls['q11'].setValue(
              taylorManifestAssessment.data.q11.answer
            );
            this.taylorManifestForm.controls['q12'].setValue(
              taylorManifestAssessment.data.q12.answer
            );
            this.taylorManifestForm.controls['q13'].setValue(
              taylorManifestAssessment.data.q13.answer
            );
            this.taylorManifestForm.controls['q14'].setValue(
              taylorManifestAssessment.data.q14.answer
            );
            this.taylorManifestForm.controls['q15'].setValue(
              taylorManifestAssessment.data.q15.answer
            );
            this.taylorManifestForm.controls['q16'].setValue(
              taylorManifestAssessment.data.q16.answer
            );
            this.taylorManifestForm.controls['q17'].setValue(
              taylorManifestAssessment.data.q17.answer
            );
            this.taylorManifestForm.controls['q18'].setValue(
              taylorManifestAssessment.data.q18.answer
            );
            this.taylorManifestForm.controls['q19'].setValue(
              taylorManifestAssessment.data.q19.answer
            );
            this.taylorManifestForm.controls['q20'].setValue(
              taylorManifestAssessment.data.q20.answer
            );
            this.taylorManifestForm.controls['q21'].setValue(
              taylorManifestAssessment.data.q21.answer
            );
            this.taylorManifestForm.controls['q22'].setValue(
              taylorManifestAssessment.data.q22.answer
            );
            this.taylorManifestForm.controls['q23'].setValue(
              taylorManifestAssessment.data.q23.answer
            );
            this.taylorManifestForm.controls['q24'].setValue(
              taylorManifestAssessment.data.q24.answer
            );
            this.taylorManifestForm.controls['q25'].setValue(
              taylorManifestAssessment.data.q25.answer
            );
            this.taylorManifestForm.controls['q26'].setValue(
              taylorManifestAssessment.data.q26.answer
            );
            this.taylorManifestForm.controls['q27'].setValue(
              taylorManifestAssessment.data.q27.answer
            );
            this.taylorManifestForm.controls['q28'].setValue(
              taylorManifestAssessment.data.q28.answer
            );
            this.taylorManifestForm.controls['q29'].setValue(
              taylorManifestAssessment.data.q29.answer
            );
            this.taylorManifestForm.controls['q30'].setValue(
              taylorManifestAssessment.data.q30.answer
            );
            this.taylorManifestForm.controls['q31'].setValue(
              taylorManifestAssessment.data.q31.answer
            );
            this.taylorManifestForm.controls['q32'].setValue(
              taylorManifestAssessment.data.q32.answer
            );
            this.taylorManifestForm.controls['q33'].setValue(
              taylorManifestAssessment.data.q33.answer
            );
            this.taylorManifestForm.controls['q34'].setValue(
              taylorManifestAssessment.data.q34.answer
            );
            this.taylorManifestForm.controls['q35'].setValue(
              taylorManifestAssessment.data.q35.answer
            );
            this.taylorManifestForm.controls['q36'].setValue(
              taylorManifestAssessment.data.q36.answer
            );
            this.taylorManifestForm.controls['q37'].setValue(
              taylorManifestAssessment.data.q37.answer
            );
            this.taylorManifestForm.controls['q38'].setValue(
              taylorManifestAssessment.data.q38.answer
            );

            this.riskScore = taylorManifestAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.taylorManifestForm.invalid) {
      this.taylorManifestForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.taylorManifestAssessment = Object.assign(
      {},
      this.taylorManifestAssessment
    );
    this.taylorManifestAssessment.patientId =
      this.taylorManifestForm.controls['patientId'].value;
    this.taylorManifestAssessment.sessionId =
      this.taylorManifestForm.controls['sessionId'].value;
    this.taylorManifestAssessment.assessmentRequestId =
      this.taylorManifestForm.controls['assessmentRequestId'].value;
    this.taylorManifestAssessment.q1 = {
      answer: this.taylorManifestForm.controls['q1'].value,
    };
    this.taylorManifestAssessment.q2 = {
      answer: this.taylorManifestForm.controls['q2'].value,
    };
    this.taylorManifestAssessment.q3 = {
      answer: this.taylorManifestForm.controls['q3'].value,
    };
    this.taylorManifestAssessment.q4 = {
      answer: this.taylorManifestForm.controls['q4'].value,
    };
    this.taylorManifestAssessment.q5 = {
      answer: this.taylorManifestForm.controls['q5'].value,
    };
    this.taylorManifestAssessment.q6 = {
      answer: this.taylorManifestForm.controls['q6'].value,
    };
    this.taylorManifestAssessment.q7 = {
      answer: this.taylorManifestForm.controls['q7'].value,
    };
    this.taylorManifestAssessment.q8 = {
      answer: this.taylorManifestForm.controls['q8'].value,
    };
    this.taylorManifestAssessment.q9 = {
      answer: this.taylorManifestForm.controls['q9'].value,
    };
    this.taylorManifestAssessment.q10 = {
      answer: this.taylorManifestForm.controls['q10'].value,
    };
    this.taylorManifestAssessment.q11 = {
      answer: this.taylorManifestForm.controls['q11'].value,
    };
    this.taylorManifestAssessment.q12 = {
      answer: this.taylorManifestForm.controls['q12'].value,
    };
    this.taylorManifestAssessment.q13 = {
      answer: this.taylorManifestForm.controls['q13'].value,
    };
    this.taylorManifestAssessment.q14 = {
      answer: this.taylorManifestForm.controls['q14'].value,
    };
    this.taylorManifestAssessment.q15 = {
      answer: this.taylorManifestForm.controls['q15'].value,
    };
    this.taylorManifestAssessment.q16 = {
      answer: this.taylorManifestForm.controls['q16'].value,
    };
    this.taylorManifestAssessment.q17 = {
      answer: this.taylorManifestForm.controls['q17'].value,
    };
    this.taylorManifestAssessment.q18 = {
      answer: this.taylorManifestForm.controls['q18'].value,
    };
    this.taylorManifestAssessment.q19 = {
      answer: this.taylorManifestForm.controls['q19'].value,
    };
    this.taylorManifestAssessment.q20 = {
      answer: this.taylorManifestForm.controls['q20'].value,
    };
    this.taylorManifestAssessment.q21 = {
      answer: this.taylorManifestForm.controls['q21'].value,
    };
    this.taylorManifestAssessment.q22 = {
      answer: this.taylorManifestForm.controls['q22'].value,
    };
    this.taylorManifestAssessment.q23 = {
      answer: this.taylorManifestForm.controls['q23'].value,
    };
    this.taylorManifestAssessment.q24 = {
      answer: this.taylorManifestForm.controls['q24'].value,
    };
    this.taylorManifestAssessment.q25 = {
      answer: this.taylorManifestForm.controls['q25'].value,
    };
    this.taylorManifestAssessment.q26 = {
      answer: this.taylorManifestForm.controls['q26'].value,
    };
    this.taylorManifestAssessment.q27 = {
      answer: this.taylorManifestForm.controls['q27'].value,
    };
    this.taylorManifestAssessment.q28 = {
      answer: this.taylorManifestForm.controls['q28'].value,
    };
    this.taylorManifestAssessment.q29 = {
      answer: this.taylorManifestForm.controls['q29'].value,
    };
    this.taylorManifestAssessment.q30 = {
      answer: this.taylorManifestForm.controls['q30'].value,
    };
    this.taylorManifestAssessment.q31 = {
      answer: this.taylorManifestForm.controls['q31'].value,
    };
    this.taylorManifestAssessment.q32 = {
      answer: this.taylorManifestForm.controls['q32'].value,
    };
    this.taylorManifestAssessment.q33 = {
      answer: this.taylorManifestForm.controls['q33'].value,
    };
    this.taylorManifestAssessment.q34 = {
      answer: this.taylorManifestForm.controls['q34'].value,
    };
    this.taylorManifestAssessment.q35 = {
      answer: this.taylorManifestForm.controls['q35'].value,
    };
    this.taylorManifestAssessment.q36 = {
      answer: this.taylorManifestForm.controls['q36'].value,
    };
    this.taylorManifestAssessment.q37 = {
      answer: this.taylorManifestForm.controls['q37'].value,
    };
    this.taylorManifestAssessment.q38 = {
      answer: this.taylorManifestForm.controls['q38'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.taylorManifestAssessment.accessCode = this.accessCode;

      this.taylorManifestApiService
        .addGuestTaylorManifest(this.taylorManifestAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Taylor Manifest Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Taylor Manifest Assessment'
            );
          }
        );
    } else {
      this.taylorManifestApiService
        .addTaylorManifest(this.taylorManifestAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Taylor Manifest Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Taylor Manifest Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [{ type: 'required', message: '1. I do not tire quickly is required' }],
    q2: [
      {
        type: 'required',
        message: '2. I believe I am no more nervous than others is required',
      },
    ],
    q3: [
      { type: 'required', message: '3. I have very few headaches is required' },
    ],
    q4: [
      {
        type: 'required',
        message: '4. I work under a great deal of tension is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. I frequently notice my hand shakes when I try do something is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message: '6. I blush no more often than others is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: '7. I have diarrhea one a month or more is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. I worry quite a bit over possible misfortunes is required',
      },
    ],
    q9: [
      { type: 'required', message: '9. I practically never blush is required' },
    ],
    q10: [
      {
        type: 'required',
        message: '10. I am often afraid that I am going to blush is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. My hands and feet are usually warm enough is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '12. I sweat very easily even on cool days is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Sometimes when embarrassed, I break out in a sweat is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I hardly ever notice my heart pounding, and I am seldom short of breath is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message: '15. I feel hungry almost all of the time is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message: '16. I am very seldom troubled by constipation is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message: '17. I have a great deal of stomach trouble is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. I have had periods in which I lost sleep over worry is required',
      },
    ],
    q19: [
      { type: 'required', message: '19. I am easily embarrassed is required' },
    ],
    q20: [
      {
        type: 'required',
        message: '20. I am more sensitive than most other people is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          '21. I frequently find myself worrying about something is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. I wish I could be as happy as others seem to be is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. I am usually calm and not easily upset is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '24. I feel anxiety about something or someone almost all of the time is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message: '25. I am happy most of the time is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message: '26. It makes me nervous to have to wait is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message:
          '27. Sometimes I become so excited I find it hard to get to sleep is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          "28. I have sometimes felt that difficulties piling up so high I couldn't get over them is required",
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          '29. I admit I have felt worried beyond reason over small things is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message: '30. I have very few fears compared to my friends is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message: '31. I certainly feel useless at times is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message:
          '32. I find it hard to keep my mind on a task or job is required',
      },
    ],
    q33: [
      {
        type: 'required',
        message: '33. I am usually self-conscious is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message: '34. I am inclined to take things hard is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message: '35. At times I think I am no good at all is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message: '36. I am certainly lacking in self-confidence is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message:
          '37. I sometimes feel that I am about to go to pieces is required',
      },
    ],
    q38: [
      {
        type: 'required',
        message: '38. I am entirely self-confident is required',
      },
    ],
  };

  private buildForm() {
    this.taylorManifestForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.taylorManifestForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.taylorManifestForm) {
      return;
    }
    const form = this.taylorManifestForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
