import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';
import { employmentStatusOptions } from 'src/app/shared/utilities/billing/employmentStatus';
import { ethnicityOptions } from 'src/app/shared/utilities/billing/ethnicity';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import { preferredLanguageOptions } from 'src/app/shared/utilities/billing/preferredLanguages';
import { raceOptions } from 'src/app/shared/utilities/billing/race';
import { TimeZones } from 'src/app/shared/utilities/billing/timeZones';
import { USStates } from 'src/app/shared/utilities/states-counties/states';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-guest-intake-demographics',
  templateUrl: './guest-intake-demographics.component.html',
  styleUrl: './guest-intake-demographics.component.css',
})
export class GuestIntakeDemographicsComponent implements OnInit {
  @Input() memberId: string = '';
  @Input() requestId: string = '';
  @Input() accessCode: string = '';
  @Input() organizationId: string = '';
  @Input() consentRequestId: string = '';
  @Input() consentDocumentId: string = '';
  @Input() documentName: string = '';
  @Input() patient: Patient = null;

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  oldPatientDemographics: Patient;
  action: string = '';

  autoCompleteOptions: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  maxDate = new Date();
  public patientForm: FormGroup;

  usStates = USStates;
  genderList = Gender;
  raceList = [];
  ethnicityList = [];
  languageList = [];
  employmentList = [];
  timeZoneList = TimeZones;
  marriedStatusList = MarriedStatus;
  processing: boolean = false;
  isModalWindow: boolean = true;
  showSSN: boolean = false;
  dropDownOptions: string = null;

  differences: {
    field: string;
    oldValue: string;
    newValue: string;
  }[] = [];
  differenceColumn = ['field', 'oldValue', 'newValue'];

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<GuestIntakeDemographicsComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    public patientApiService: PatientService
  ) {
    if (data) {
      this.patient = data.patient;
      this.requestId = data.requestId;
      this.accessCode = data.accessCode;
      this.organizationId = data.organizationId;
      this.consentRequestId = data.consentRequestId;
      this.consentDocumentId = data.consentDocumentId;
      this.documentName = data.documentName;
      this.action = data.action;
      this.oldPatientDemographics = data.oldPatientDemographics;
    } else {
      this.isModalWindow = false;
    }
  }

  ngOnInit() {
    this.raceList = raceOptions('General');
    this.languageList = preferredLanguageOptions('General');
    this.ethnicityList = ethnicityOptions('General');
    this.employmentList = employmentStatusOptions('General');

    this.buildForm();

    this.loadExistingData();

    if (this.action === 'VERIFY') {
      this.differences = this.comparePatients(
        this.oldPatientDemographics,
        this.patient
      );
    }
  }

  private buildForm() {
    this.patientForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),

      firstName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      middleName: new FormControl(''),
      lastName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      email: new FormControl('', Validators.email),
      dateOfBirth: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      gender: new FormControl(null, Validators.compose([Validators.required])),

      ssn: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '999-99-9999' })])
      ),
      genderAtBirth: new FormControl(''),

      addressLine1: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      addressLine2: new FormControl(''),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required])),
      zip: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '99999' }),
        ])
      ),

      phonePreference: new FormControl('Cell Phone', Validators.required),
      phoneNumber: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),

      homePhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      workPhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      preferredLanguage: new FormControl(null),

      race: new FormControl(''),
      ethnicity: new FormControl(''),
      maritalStatus: new FormControl(''),
      employmentStatus: new FormControl(''),
      timeZone: new FormControl(''),
      pronouns: new FormControl(''),
      height: new FormControl(''),
      weight: new FormControl(''),
    });
  }

  loadExistingData() {
    this.patientForm.controls['id'].setValue(this.patient.id);
    this.patientForm.controls['organizationId'].setValue(
      this.patient.organizationId
    );

    this.patientForm.controls['firstName'].setValue(this.patient.firstName);
    this.patientForm.controls['middleName'].setValue(this.patient.middleName);
    this.patientForm.controls['lastName'].setValue(this.patient.lastName);
    if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
      this.patientForm.controls['dateOfBirth'].setValue(
        mmddyyyyToDate(this.patient.dateOfBirth)
      );
    }
    this.patientForm.controls['gender'].setValue(this.patient.gender);

    this.patientForm.controls['ssn'].setValue(this.patient.ssn);
    this.patientForm.controls['genderAtBirth'].setValue(
      this.patient.genderAtBirth
    );

    this.patientForm.controls['addressLine1'].setValue(
      this.patient.addressLine1
    );
    this.patientForm.controls['addressLine2'].setValue(
      this.patient.addressLine2
    );
    this.patientForm.controls['city'].setValue(this.patient.city);
    this.patientForm.controls['state'].setValue(this.patient.state);
    this.patientForm.controls['zip'].setValue(this.patient.zip);
    this.patientForm.controls['phonePreference'].setValue(
      this.patient.phonePreference
    );
    this.patientForm.controls['phoneNumber'].setValue(this.patient.phoneNumber);
    this.patientForm.controls['homePhoneNumber'].setValue(
      this.patient.homePhoneNumber
    );
    this.patientForm.controls['workPhoneNumber'].setValue(
      this.patient.workPhoneNumber
    );
    this.patientForm.controls['preferredLanguage'].setValue(
      this.patient.preferredLanguage
    );
    this.patientForm.controls['email'].setValue(this.patient.email);
    this.patientForm.controls['race'].setValue(this.patient.race);
    this.patientForm.controls['ethnicity'].setValue(this.patient.ethnicity);
    this.patientForm.controls['maritalStatus'].setValue(
      this.patient.maritalStatus
    );
    this.patientForm.controls['employmentStatus'].setValue(
      this.patient.employmentStatus
    );
    this.patientForm.controls['timeZone'].setValue(this.patient.timeZone);
    this.patientForm.controls['pronouns'].setValue(this.patient.pronouns);

    if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
      this.patientForm.controls['admittanceDate'].setValue(
        mmddyyyyToDate(this.patient.admittanceDate)
      );
    }
    if (this.patient.height) {
      this.patientForm.controls['height'].setValue(this.patient.height);
    }
    if (this.patient.weight) {
      this.patientForm.controls['weight'].setValue(this.patient.weight);
    }
  }

  // A function that calculates the differences
  comparePatients(oldRecord: Patient, newRecord: Patient) {
    const keysToCompare = [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'email',
      'gender',
      'phonePreference',
      'phoneNumber',
      'homePhoneNumber',
      'workPhoneNumber',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zip',
      'preferredLanguage',
      'race',
      'ethnicity',
      'maritalStatus',
      'employmentStatus',
      'timeZone',
      'height',
      'weight',
      'weight',
    ];

    let differences: {
      field: string;
      oldValue: string;
      newValue: string;
    }[] = [];

    for (let key of keysToCompare) {
      if (oldRecord[key as keyof Patient] !== newRecord[key as keyof Patient]) {
        differences.push({
          field: key,
          oldValue: oldRecord[key as keyof Patient],
          newValue: newRecord[key as keyof Patient],
        });
      }
    }

    return differences;
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.patientForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.patientForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.patientForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.patientForm.controls['addressLine1'].setValue(addressField);
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.patientForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();

      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.patientForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  onPatientFormSubmit() {
    if (this.patientForm.invalid) {
      this.patientForm.markAllAsTouched();
      return;
    }

    this.patient = Object.assign({}, this.patient);
    this.patient = Object.assign(this.patient, this.patientForm.value);

    if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
      this.patient.dateOfBirth = formatDate(new Date(this.patient.dateOfBirth));
    }
    if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
      this.patient.admittanceDate = formatDate(
        new Date(this.patient.admittanceDate)
      );
    }

    this.processing = true;

    if (this.accessCode) {
      this.patientApiService
        .updateGuestIntakeDemographicDetails(
          this.requestId,
          this.accessCode,
          this.consentRequestId,
          this.patient
        )
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully updated the demographic information.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          },
        });
    } else {
      this.patientApiService
        .updateSelfDetails(this.patient, this.consentRequestId)
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully updated the demographic information.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          },
        });
    }
  }

  genderChanged(event) {
    if (event.value === 'O') {
      this.patientForm.get('genderAtBirth').addValidators(Validators.required);
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    } else {
      this.patientForm.get('genderAtBirth').clearValidators();
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    }
  }
}
