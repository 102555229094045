import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentPlan } from 'src/app/_models/case/treatment/plan/treatmentplan.model';
import { TreatmentPlansApiService } from 'src/app/_services/case/treatment/plan/treatmentplan.service';
import { formatDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-treatment-plans-dialog',
  templateUrl: './add-edit-treatment-plans-dialog.component.html',
  styleUrl: './add-edit-treatment-plans-dialog.component.css',
})
export class AddEditTreatmentPlansDialogComponent implements OnInit {
  public action: string;
  public treatmentplansForm: FormGroup;
  treatmentplans: ITreatmentPlan;

  processing = false;
  case: Case;
  patient: Patient;

  constructor(
    public dialogRef: MatDialogRef<AddEditTreatmentPlansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public treatmentplansApiService: TreatmentPlansApiService,
    private formBuilder: FormBuilder
  ) {
    this.action = data.action;
    this.treatmentplans = data.treatmentplans;
    this.case = data.case;
    this.patient = data.patient;
  }

  public ngOnInit() {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.treatmentplansForm.controls['id'].setValue(this.treatmentplans.id);

      this.treatmentplansForm.controls['organizationId'].setValue(
        this.treatmentplans.organizationId
      );

      this.treatmentplansForm.controls['patientId'].setValue(
        this.treatmentplans.patientId
      );

      this.treatmentplansForm.controls['caseId'].setValue(
        this.treatmentplans.caseId
      );

      this.treatmentplansForm.controls['planName'].setValue(
        this.treatmentplans.planName
      );
      this.treatmentplansForm.controls['status'].setValue(
        this.treatmentplans.status
      );

      this.treatmentplansForm.controls['planNumber'].setValue(
        this.treatmentplans.planNumber
      );

      this.treatmentplansForm.controls['versionNumber'].setValue(
        this.treatmentplans.versionNumber
      );

      this.treatmentplansForm.controls['startDate'].setValue(
        new Date(this.treatmentplans.startDate)
      );

      this.treatmentplansForm.controls['endDate'].setValue(
        new Date(this.treatmentplans.endDate)
      );
    }
  }

  onTreatmentPlansFormSubmit() {
    if (this.treatmentplansForm.invalid) {
      this.treatmentplansForm.markAllAsTouched();
      return;
    }

    this.treatmentplans = Object.assign({}, this.treatmentplans);
    this.treatmentplans = Object.assign(
      this.treatmentplans,
      this.treatmentplansForm.value
    );

    if (this.treatmentplans.startDate !== '') {
      this.treatmentplans.startDate = formatDate(
        new Date(this.treatmentplans.startDate)
      );
    }

    if (this.treatmentplans.endDate !== '') {
      this.treatmentplans.endDate = formatDate(
        new Date(this.treatmentplans.endDate)
      );
    }

    this.processing = true;
    if (this.action === 'EDIT') {
      this.treatmentplansApiService
        .updateTreatmentPlan(this.treatmentplans)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    } else {
      this.treatmentplansApiService
        .addTreatmentPlan(this.treatmentplans)
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close('success');
        });
    }
  }

  private buildForm() {
    this.treatmentplansForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      patientId: new FormControl(
        this.patient.id,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(
        this.case.id,
        Validators.compose([Validators.required])
      ),
      planName: new FormControl('', Validators.compose([Validators.required])),
      status: new FormControl(
        'Active',
        Validators.compose([Validators.required])
      ),
      planNumber: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      versionNumber: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', Validators.compose([Validators.required])),
    });
  }
}
