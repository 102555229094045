<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle *ngIf="!carelog">Add Care log</p>
    <p matDialogTitle *ngIf="carelog">Edit Care log</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="caseForm" (ngSubmit)="onCaseNoteSubmit()">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Note <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Custom Note"
            formControlName="message"
            name="message"
            spellcheck="true"
            required
          ></textarea>
          <mat-error *ngIf="caseForm.controls['message'].invalid"
            >Please add your note
          </mat-error>
        </mat-form-field>
      </div>

      <!-- // Selecting member goals -->
      <div class="col-md-12" *ngIf="case.goalDrivenCare">
        <label class="labelText">Goals <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="memberGoals"
            multiple
            placeholder="Goals"
          >
            <mat-option *ngFor="let goal of goals" [value]="goal.id">
              {{ goal.description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="caseForm.controls['memberGoals'].invalid">
            Please select a goal
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText"> Additional Details </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Additional Details"
            formControlName="customMessage"
            name="customMessage"
            spellcheck="true"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Activity Date/Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Date Time"
            formControlName="activityDateTime"
            [max]="todaysDate"
          />
          <owl-date-time #dt1></owl-date-time>

          <mat-error *ngIf="caseForm.controls['activityDateTime'].invalid">
            Please select the activity date and time
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!caseForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
