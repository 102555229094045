import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ITherapySession, Patient } from 'src/app/_models';
import {
  CaseApiService,
  CoreService,
  GoalHttpService,
  PatientService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { DateTimeZoneService } from 'src/app/_services/date-time-zone.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { SessionGeneralNotesComponent } from '../session-general-notes/session-general-notes.component';
import { CloseSessionComponent } from './close-session/close-session.component';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.css'],
})
export class SessionDetailsComponent implements OnInit {
  @Input() patient: Patient;
  @Input() secondaryTab: any;
  @Input() therapySession: ITherapySession;
  @Input() hasMemberEditAccess: boolean;
  @Input() hasTeleHealthEnabled: boolean;
  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasClaimsFeature: boolean;
  @Input() hasASIFeatures: boolean;
  @Input() isBPSAEnforced: boolean;

  @Input() hasBillingViewAccess: boolean;
  @Input() hasBillingEditAccess: boolean;

  @Input() hasTherapySessionViewAccess: boolean;
  @Input() hasTherapySessionAddAccess: boolean;
  @Input() hasTherapySessionEditAccess: boolean;
  @Input() hasTherapySessionDeleteAccess: boolean;

  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;

  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Input() hasClinicalNotesViewAccess: boolean;
  @Input() hasClinicalNotesAddAccess: boolean;
  @Input() hasClinicalNotesEditAccess: boolean;

  @Input() hasSubjectivesViewAccess: boolean;
  @Input() hasSubjectivesEditAccess: boolean;

  @Input() hasObjectivesViewAccess: boolean;
  @Input() hasObjectivesEditAccess: boolean;

  @Input() hasSessionDocumentsViewAccess: boolean;
  @Input() hasSessionDocumentsAddAccess: boolean;
  @Input() hasSessionDocumentsDeleteAccess: boolean;

  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;
  @Input() hasAssessmentTakeAddAccess: boolean;
  @Input() hasAssessmentChartsViewAccess: boolean;
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  @Input() prescriptionEnabled: boolean;

  @Output() output = new EventEmitter<any>();

  orgConfig: any;
  isPhysician: boolean = false;
  currentTimezone: string;

  loggedInUserId: string = '';

  constructor(
    public dialog: MatDialog,
    public apiService: CaseApiService,
    private coreService: CoreService,
    public patientApiService: PatientService,
    public goalApiService: GoalHttpService,
    public therapySessionService: TherapySessionService,
    protected permissionsSandbox: PermissionsSandbox,
    private dateTimeZoneService: DateTimeZoneService,
    private toastMessageService: ToastMessageService,
    public router: Router
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.isPhysician = this.coreService.isPhysician();
    if (!this.secondaryTab) {
      if (
        (this.hasBPSAClinicalViewAccess || this.hasBPSAClinicalViewAccess) &&
        this.isBPSAEnforced
      ) {
        this.secondaryTab = 'BPSA-INTAKE';
      } else {
        this.secondaryTab = 'notedetails';
      }
    }

    if (this.isPhysician) {
      this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    }
  }

  public closeSession() {
    // Validation for member data
    if (!this.therapySession.intakeMemberData && this.isBPSAEnforced) {
      this.toastMessageService.displayInfoMessage(
        "Please verify the member's detail on the Intake step"
      );
      return;
    }

    // Validation for signature for Supervising RP
    if (
      this.therapySession.supervisingRenderingProvider === 'Yes' &&
      this.therapySession.supervisorSigned != 'Yes' &&
      this.isBPSAEnforced
    ) {
      this.toastMessageService.displayInfoMessage(
        'Cannot close the session. Supervisor signature is still required.'
      );
      return;
    }

    // Validation for Rendering Provider
    if (this.therapySession.physicianSigned != 'Yes' && this.isBPSAEnforced) {
      this.toastMessageService.displayInfoMessage(
        'Rendering Provider signature is required at the Clinical Step.'
      );
      return;
    }

    // If all the checks have passes
    let preDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Please Confirm',
        msg: 'Before continuing, please ensure that you have added the required clinical notes and verified the billing information.',
        yesButtonTitle: 'Proceed',
        noButtonTitle: 'Cancel',
      },
      disableClose: true,
      autoFocus: false,
    });
    preDialog.afterClosed().subscribe((response) => {
      if (response) {
        const dialogRef = this.dialog.open(CloseSessionComponent, {
          minWidth: '70vw',
          minHeight: '40vh',
          disableClose: true,
          autoFocus: false,
          data: {
            sessionId: this.therapySession.id,
            therapySession: this.therapySession,
            isBPSAEnforced: this.isBPSAEnforced,
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response === 'success') {
            // If only the user has the therapy add access
            if (this.hasTherapySessionAddAccess) {
              // Also bring in another box to ask if you would like to setup another session
              const addAnotherDialog = this.dialog.open(
                ConfirmDialogComponent,
                {
                  data: {
                    title: 'Another Session?',
                    msg: 'Do you want to schedule another session?',
                    yesButtonTitle: 'Yes',
                    noButtonTitle: 'No',
                  },
                  disableClose: true,
                }
              );
              addAnotherDialog.afterClosed().subscribe((response) => {
                if (response) {
                  // Navigate to therapy scheduler
                  this.router.navigate(['/sessionscheduler', this.patient.id]);
                } else {
                  this.deSelectSession();
                }
              });
            } else {
              this.deSelectSession();
            }
          }
        });
      }
    });
  }

  public deSelectSession() {
    this.router.navigate(['/main/member/' + this.patient.id + '/NOTES']);
    this.output.emit({ eventType: 'DESELECT_SESSION' });
  }

  triggerEvent(payload: any) {
    if (payload.eventType === 'RELOAD_PENDING_ASSESSMENT_REQUESTS') {
      if (payload?.therapySession) {
        this.reloadTherapySession(payload.therapySession);
      }

      this.output.emit(payload);
    } else if (payload.eventType === 'RELOAD_THERPAY_OBJECT') {
      this.reloadTherapySession(payload.therapySession);
    } else if (payload.eventType === 'RELOAD_PATIENT_PROFILE') {
      this.output.emit(payload);
    } else if (payload.eventType === 'DESELECT_SESSION') {
      this.output.emit(payload);
    } else if (payload.eventType === 'RELOAD_THERPAY_OBJECT_AFTER_1_SECOND') {
      setTimeout(() => {
        this.loadTherapySessionDetails(payload.sessionId);
      }, 1000);
    }
  }

  public loadTherapySessionDetails(sessionId: string) {
    this.therapySessionService.getTherapySessionDetails(sessionId).subscribe({
      next: (response) => {
        if (response) {
          this.therapySession = response.data;
        }
      },
    });
  }

  reloadTherapySession(therapySession) {
    this.therapySession = therapySession;
  }

  reloadBillingSession(event) {
    this.therapySession = event.therapySession;
  }

  viewSessionLevelNote() {
    let dialogRef = this.dialog.open(SessionGeneralNotesComponent, {
      data: {
        therapySession: this.therapySession,
        hasEditAccess: this.hasTherapySessionEditAccess,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.reloadTherapySession(response.updatedTherapy);
      }
    });
  }

  changeSelectedTab(selectedTab: string) {
    if (selectedTab === 'notedetails') {
      this.router.navigate(['/main/member/' + this.patient.id + '/NOTES'], {
        queryParams: {
          secondaryId: this.therapySession.id,
          secondaryTab: selectedTab,
          hideMemberDetails: true,
        },
      });
    } else {
      this.router.navigate(['/main/member/' + this.patient.id + '/NOTES'], {
        queryParams: {
          secondaryId: this.therapySession.id,
          secondaryTab: selectedTab,
        },
      });
    }
  }
}
