import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { MemberGoalsHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-patient-screen-goals',
  templateUrl: './patient-screen-goals.component.html',
  styleUrls: ['./patient-screen-goals.component.css'],
})
export class PatientScreenGoalsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loggedInUserId: string = '';
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = true;

  order: string = 'DESC';
  goalStatus: string = 'Active';
  searchGoalDescription: string = '';

  constructor(
    public memberGoalHttpService: MemberGoalsHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.data.page = 0;
    this.data.per_page = 12;

    this.loadMemberGoals();
  }

  loadMemberGoals() {
    this.isLoadingResults = true;

    this.memberGoalHttpService
      .memberGetMyGoals(
        this.data.per_page,
        this.data.page,
        this.order,
        this.goalStatus,
        this.searchGoalDescription
      )
      .subscribe(
        (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;

            // if (this.goalStatus === 'Active') {
            //   if (this.data.items.length > 0) {
            //     this.data.items.forEach((item) => {
            //       if (item?.objectives) {
            //         if (item.objectives.length > 0) {
            //           item.objectives = item.objectives.filter((objective) => {
            //             if (
            //               objective.status === 'Open' ||
            //               objective.status === 'Extended' ||
            //               objective.status === 'Revised' ||
            //               objective.status === 'Referred'
            //             ) {
            //               return true;
            //             } else {
            //               return false;
            //             }
            //           });
            //         }
            //       }
            //     });
            //   }
            // }
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.isLoadingResults = false;
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get goals for this session'
          );
        }
      );
  }

  filterOptionChanged(filterOption: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.goalStatus = filterOption;
    this.loadMemberGoals();
  }

  sortByChanged(order: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.order = order;
    this.loadMemberGoals();
  }

  searchByDescription() {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.loadMemberGoals();
  }

  getNextPage(event) {
    this.data.page = event.pageIndex;
    this.loadMemberGoals();
  }
}
