import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession } from 'src/app/_models';
import { IBpsaFinancialMaritalEtc } from 'src/app/_models/session/clinical/bpsa-clinical-financial-marital-etc.model';
import { ToastMessageService } from 'src/app/_services';
import { BpsaFinancialMaritalEtcApiService } from 'src/app/_services/session/clinical/bpsa-clinical-financial-marital-etc.service';

@Component({
  selector: 'app-financial-marital-family-legal-history',
  templateUrl: './financial-marital-family-legal-history.component.html',
  styleUrls: ['./financial-marital-family-legal-history.component.css'],
})
export class FinancialMaritalFamilyLegalHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsafinancialmaritaletc: IBpsaFinancialMaritalEtc;

  constructor(
    public bpsafinancialmaritaletcApiService: BpsaFinancialMaritalEtcApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) { }

  public action: string;
  public bpsafinancialmaritaletcForm: FormGroup;

  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.bpsafinancialmaritaletc = this.therapySession.bpsaFinancialMaritalEtc;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();

    this.loadBpsaFinancialMaritalEtc();
  }

  loadBpsaFinancialMaritalEtc() {
    if (this.bpsafinancialmaritaletc) {
      this.bpsafinancialmaritaletcForm.controls['supportFinancially'].setValue(
        this.bpsafinancialmaritaletc.supportFinancially
      );
      this.bpsafinancialmaritaletcForm.controls['canBudget'].setValue(
        this.bpsafinancialmaritaletc.canBudget
      );
      this.bpsafinancialmaritaletcForm.controls['currentlyLiveWith'].setValue(
        this.bpsafinancialmaritaletc.currentlyLiveWith
      );
      this.bpsafinancialmaritaletcForm.controls['relationshipStatus'].setValue(
        this.bpsafinancialmaritaletc.relationshipStatus
      );
      this.bpsafinancialmaritaletcForm.controls[
        'currentRelationshipDetail'
      ].setValue(this.bpsafinancialmaritaletc.currentRelationshipDetail);
      this.bpsafinancialmaritaletcForm.controls['haveChildren'].setValue(
        this.bpsafinancialmaritaletc.haveChildren
      );
      this.bpsafinancialmaritaletcForm.controls['childrenAge'].setValue(
        this.bpsafinancialmaritaletc.childrenAge
      );
      this.bpsafinancialmaritaletcForm.controls['primaryCustodyBy'].setValue(
        this.bpsafinancialmaritaletc.primaryCustodyBy
      );
      this.bpsafinancialmaritaletcForm.controls['legalIssues'].setValue(
        this.bpsafinancialmaritaletc.legalIssues
      );
    }

    this.initiateAutoSave();
  }

  onBpsaFinancialMaritalEtcFormSubmit() {
    this.bpsafinancialmaritaletc = Object.assign(
      {},
      this.bpsafinancialmaritaletc
    );
    this.bpsafinancialmaritaletc = Object.assign(
      this.bpsafinancialmaritaletc,
      this.bpsafinancialmaritaletcForm.value
    );

    this.processing = true;
    this.bpsafinancialmaritaletcApiService
      .updateBpsaFinancialMaritalEtc(this.bpsafinancialmaritaletc)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsafinancialmaritaletcForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      supportFinancially: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      canBudget: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      currentlyLiveWith: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      relationshipStatus: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      currentRelationshipDetail: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      haveChildren: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      childrenAge: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      primaryCustodyBy: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      legalIssues: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
    });
  }

  initiateAutoSave() {
    this.bpsafinancialmaritaletcForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaFinancialMaritalEtcFormSubmit();
      });
  }
}
