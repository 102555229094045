<div
  class="main-content"
  style="background-color: #eee"
  *ngIf="!isLoadingResults"
>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>
  <div class="container-fluid" *ngIf="!isLoadingResults">
    <br />
    <div class="row">
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">
              Overall Score - On a scale of 100
            </h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  height="100%"
                  [data]="lineChartData"
                  [options]="lineChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Interpreting the Overall Score</h4>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row">
              <div class="col-md-12" style="padding-top: 4px; font-weight: 300">
                Scores on the DSI-SF were positively related to self-esteem and
                negatively related to symptoms of depression, state anxiety,
                trait anxiety, and perceived stress.
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="padding-top: 4px; font-weight: 300">
                Evidence of convergent validity was found in a positive
                relationship between the subscales of the DSI-SF and the Level
                of Differentiation of Self Scale
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="padding-top: 4px; font-weight: 300">
                The DSI- SF consist of 20-item, the 20-items are divided into
                four subscales: Emotional Cut off (EC), Emotional Reactivity
                (ER), Fusion with Others (FO) and I-Position (IP)
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Emotional Cut off (EC)</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="ecChartData"
                  [options]="averageChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="averageChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-3">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Emotional Reactivity (ER)</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="erChartData"
                  [options]="averageChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-3">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Fusion with Others (FO)</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="foChartData"
                  [options]="averageChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-3">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">I-Position (IP)</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas
                  baseChart
                  [data]="ipChartData"
                  [options]="averageChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType"
                  [plugins]="lineChartPlugins"
                >
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br />
    <div class="row">
      <ng-container *ngFor="let scoreDetail of scoreDetails">
        <div class="col-md-4" style="margin-bottom: 20px">
          <mat-card>
            <mat-card-header>
              <h4 class="header text-center">{{ scoreDetail.title }}</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="scoreDetail"
                    [options]="assessmentDetailChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="detailChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
            <small
              ><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small
            >
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>
