import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { IReferral } from 'src/app/_models/referrals/referral.model';

@Component({
  selector: 'app-referral-eligibility-test',
  templateUrl: './referral-eligibility-test.component.html',
  styleUrl: './referral-eligibility-test.component.css',
})
export class ReferralEligibilityTestComponent implements OnInit {
  referralInformation: IReferral;

  constructor(
    public dialogRef: MatDialogRef<ReferralEligibilityTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.referralInformation = data.referralInformation;
  }

  ngOnInit(): void {}
}
