import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Case, Patient } from 'src/app/_models';
import { ITreatmentDischargeLegal } from 'src/app/_models/case/treatment/discharge/treatmentdischargelegal.model';
import { ITreatmentProgram } from 'src/app/_models/case/treatment/treatmentprogram.model';
import { TreatmentDischargeLegalApiService } from 'src/app/_services/case/treatment/discharge/treatmentdischargelegal.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
@Component({
  selector: 'app-discharge-legal',
  templateUrl: './discharge-legal.component.html',
  styleUrl: './discharge-legal.component.css',
})
export class DischargeLegalComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() selectedProgram: ITreatmentProgram;

  public action: string;
  public treatmentdischargelegalForm: FormGroup;
  treatmentdischargelegal: ITreatmentDischargeLegal;
  key: string;
  processing: boolean = false;

  public legalStatusOptions: string[] = [
    '180 Day Commitment',
    '30 Day Commitment',
    '90 Day Commitment',
    'Court ordered juveniles (INT); DJJ custody',
    'Court ordered juveniles (INT); parents retain custody',
    'Court order for observation and evaluation',
    'Case Pending',
    'Community Sentencing',
    'Deferred Prosecution',
    'Deferred Sentence',
    'Emergency Commitment',
    'Furlough/Rehabilitative Leave',
    'Incarcerated',
    'Title 12-Not guilty by reason of insanity (NGRI), (GBMI)',
    'None/No Involvement',
    'No Response',
    'Protective custody',
    'Probation',
    'Parole',
    'Unknown',
  ];

  constructor(
    public treatmentdischargelegalApiService: TreatmentDischargeLegalApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();
    this.loadTreatmentDischargeLegal();
  }

  loadTreatmentDischargeLegal() {
    this.treatmentdischargelegalApiService
      .getTreatmentDischargeLegalDetails(this.selectedProgram.id)
      .subscribe((treatmentdischargelegal) => {
        if (treatmentdischargelegal?.data) {
          this.treatmentdischargelegalForm.controls['id'].setValue(
            treatmentdischargelegal.data.id
          );
          this.treatmentdischargelegalForm.controls['organizationId'].setValue(
            treatmentdischargelegal.data.organizationId
          );
          this.treatmentdischargelegalForm.controls['patientId'].setValue(
            treatmentdischargelegal.data.patientId
          );
          this.treatmentdischargelegalForm.controls['caseId'].setValue(
            treatmentdischargelegal.data.caseId
          );
          this.treatmentdischargelegalForm.controls['programId'].setValue(
            treatmentdischargelegal.data.programId
          );
          this.treatmentdischargelegalForm.controls['legalStatus'].setValue(
            treatmentdischargelegal.data.legalStatus
          );
          this.treatmentdischargelegalForm.controls[
            'numberOfArrestsUpTo30DaysDuringTreatment'
          ].setValue(
            treatmentdischargelegal.data
              .numberOfArrestsUpTo30DaysDuringTreatment
          );
          this.treatmentdischargelegalForm.controls[
            'totalNumberOfArrestsSinceAdmission'
          ].setValue(
            treatmentdischargelegal.data.totalNumberOfArrestsSinceAdmission
          );
        }
      });
  }

  onTreatmentDischargeLegalFormSubmit() {
    if (this.treatmentdischargelegalForm.invalid) {
      this.treatmentdischargelegalForm.markAllAsTouched();
      return;
    }

    this.treatmentdischargelegal = Object.assign(
      {},
      this.treatmentdischargelegal
    );
    this.treatmentdischargelegal = Object.assign(
      this.treatmentdischargelegal,
      this.treatmentdischargelegalForm.value
    );

    this.processing = true;
    this.treatmentdischargelegalApiService
      .updateTreatmentDischargeLegal(this.treatmentdischargelegal)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage('Failed to update');
          this.processing = false;
        },
      });
  }

  private buildForm() {
    this.treatmentdischargelegalForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(this.patient.id, Validators.required),
      caseId: new FormControl(this.case.id, Validators.required),
      programId: new FormControl(this.selectedProgram.id, Validators.required),
      legalStatus: new FormControl('', Validators.required),
      numberOfArrestsUpTo30DaysDuringTreatment: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      totalNumberOfArrestsSinceAdmission: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(0)])
      ),
    });
  }

  removeSelectedOption(record, index) {
    let existingRecords = this.treatmentdischargelegalForm.controls[
      'legalStatus'
    ].value as [];

    existingRecords.splice(index, 1);

    this.treatmentdischargelegalForm.controls['legalStatus'].setValue(
      existingRecords
    );
  }
}
