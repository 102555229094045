<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Close Note</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="objectiveForm" novalidate>
    <mat-stepper linear="true" #stepper>
      <mat-step [stepControl]="sessionClosingDetails">
        <ng-template matStepLabel>Appointment Details</ng-template>
        <div class="row">
          <div class="col-md-6">
            <label class="labelText">
              Appointment Started On <span style="color: red">*</span>
            </label>

            <input
              style="
                border: 1px solid lightgray;
                padding: 7px;
                border-radius: 5px;
                margin-left: 15px;
              "
              mbsc-datepicker
              [controls]="['datetime']"
              [stepMinute]="15"
              display="anchored"
              [touchUi]="true"
              formControlName="start"
            />
          </div>
          <div class="col-md-6">
            <label class="labelText">
              Appointment Ended On <span style="color: red">*</span>
            </label>

            <input
              style="
                border: 1px solid lightgray;
                padding: 7px;
                border-radius: 5px;
                margin-left: 15px;
              "
              mbsc-datepicker
              [controls]="['datetime']"
              [stepMinute]="15"
              display="anchored"
              [touchUi]="true"
              formControlName="end"
            />
          </div>

          <div class="col-md-12" style="margin-top: 12px">
            <label class="labelText"> Therapeutic Modality </label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="therapeuticModality"
                placeholder="Therapeutic Modality"
                (selectionChange)="setSessionTherapeutic()"
                multiple
              >
                <mat-option value="Applied Behavior Analysis (ABA)"
                  >Applied Behavior Analysis (ABA)</mat-option
                >
                <mat-option value="Accelerated Resolution Therapy (ART)"
                  >Accelerated Resolution Therapy (ART)</mat-option
                >
                <mat-option
                  value="Accelerated Experiential Dynamic Psychotherapy (AEDP)"
                  >Accelerated Experiential Dynamic Psychotherapy
                  (AEDP)</mat-option
                >
                <mat-option value="Acceptance-Commitment Therapy (ACT)"
                  >Acceptance-Commitment Therapy (ACT)</mat-option
                >
                <mat-option value="Adlerian Therapy"
                  >Adlerian Therapy</mat-option
                >
                <mat-option value="Body Mind Centering (BMC)"
                  >Body Mind Centering (BMC)</mat-option
                >
                <mat-option value="Brain Spotting (BSP)"
                  >Brain Spotting (BSP)</mat-option
                >
                <mat-option value="Career Counseling"
                  >Career Counseling</mat-option
                >
                <mat-option value="Cognitive-Behavior Therapy (CBT)"
                  >Cognitive-Behavior Therapy (CBT)</mat-option
                >
                <mat-option value="Dialectical Behavior Therapy (DBT)"
                  >Dialectical Behavior Therapy (DBT)</mat-option
                >
                <mat-option value="Emotion-Focused Therapy (EFT)"
                  >Emotion-Focused Therapy (EFT)</mat-option
                >
                <mat-option value="Experiential Therapy"
                  >Experiential Therapy</mat-option
                >
                <mat-option
                  value="Eye Movement Desensitization and Reprocessing (EMDR)"
                  >Eye Movement Desensitization and Reprocessing
                  (EMDR)</mat-option
                >
                <mat-option value="Family Systems Therapy"
                  >Family Systems Therapy</mat-option
                >
                <mat-option value="Gestalt Therapy">Gestalt Therapy</mat-option>
                <mat-option value="Gottman Couples Therapy"
                  >Gottman Couples Therapy</mat-option
                >
                <mat-option value="Grief Therapy">Grief Therapy</mat-option>
                <mat-option value="Humanistic Therapy"
                  >Humanistic Therapy</mat-option
                >
                <mat-option value="Integrative (technical eclecticism) Therapy"
                  >Integrative (technical eclecticism) Therapy</mat-option
                >
                <mat-option value="Internal Family Systems (IFS)"
                  >Internal Family Systems (IFS)</mat-option
                >
                <mat-option value="Life Transitions Therapy"
                  >Life Transitions Therapy</mat-option
                >
                <mat-option value="Mindfulness-Based Cognitive Therapy (MBCT)"
                  >Mindfulness-Based Cognitive Therapy (MBCT)</mat-option
                >
                <mat-option value="Modern Psychoanalytic Therapy"
                  >Modern Psychoanalytic Therapy</mat-option
                >
                <mat-option value="Motivational Interviewing Therapy"
                  >Motivational Interviewing Therapy</mat-option
                >
                <mat-option value="Neurofeedback">Neurofeedback</mat-option>
                <mat-option value="Narrative Therapy"
                  >Narrative Therapy</mat-option
                >
                <mat-option value="Object Relations Therapy Based Psychotherapy"
                  >Object Relations Therapy Based Psychotherapy</mat-option
                >
                <mat-option value="Person & Family Centered Therapy"
                  >Person & Family Centered Therapy</mat-option
                >
                <mat-option value="Play Therapy">Play Therapy</mat-option>
                <mat-option value="Polyvagal Theory Informed Therapy"
                  >Polyvagal Theory Informed Therapy</mat-option
                >
                <mat-option value="Psychodynamic Therapy"
                  >Psychodynamic Therapy</mat-option
                >
                <mat-option value="Rational Emotive Behavior Therapy (REBT)"
                  >Rational Emotive Behavior Therapy (REBT)</mat-option
                >
                <mat-option value="Relational Life Therapy (RLT) for Couples"
                  >Relational Life Therapy (RLT) for Couples</mat-option
                >
                <mat-option value="Solution-Focused Brief Therapy (SFBT)"
                  >Solution-Focused Brief Therapy (SFBT)</mat-option
                >
                <mat-option value="Somatic Experiencing (SE)"
                  >Somatic Experiencing (SE)</mat-option
                >
                <mat-option value="Spiritual Integration Counseling"
                  >Spiritual Integration Counseling</mat-option
                >
                <mat-option value="Strength-based Therapy"
                  >Strength-based Therapy</mat-option
                >
                <mat-option value="Structural Family Therapy"
                  >Structural Family Therapy</mat-option
                >
                <mat-option value="Trauma Focused Therapy"
                  >Trauma Focused Therapy</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="objectiveForm.controls['therapeuticModality'].invalid"
              >
                Please select a therapeutic modality
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12" style="text-align: right">
            <button
              color="primary"
              mat-raised-button
              type="button"
              (click)="onNextClicked(stepper)"
              [disabled]="objectiveForm.controls['therapeuticModality'].invalid"
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step *ngIf="isBPSAEnforced" [stepControl]="subjectiveStep">
        <ng-template matStepLabel>Subjective</ng-template>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder="Subjective"
                formControlName="subjective"
                name="subjective"
                spellcheck="true"
                rows="20"
              ></textarea>
            </mat-form-field>
          </div>
          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button
              class="btn btn-default pull-right"
              mat-raised-button
              type="button"
              matStepperPrevious
            >
              Previous
            </button>
            <button
              class="btn btn-primary pull-right"
              mat-raised-button
              type="button"
              (click)="onNextClicked(stepper)"
            >
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step *ngIf="isBPSAEnforced" [stepControl]="objectiveStep">
        <ng-template matStepLabel>Objective</ng-template>

        <div formGroupName="objective" novalidate>
          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Observations</h3>

            <ng-container formArrayName="observations">
              <div
                *ngFor="let observation of observations; let questId = index"
                [formGroupName]="questId"
              >
                <label class="labelText">
                  {{ observation.label }}
                </label>
                <br />
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="
                      let options of observation.responses;
                      let optId = index
                    "
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ options.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <mat-form-field appearance="outline">
              <mat-label>AEB (up to 400 characters)</mat-label>
              <textarea
                matInput
                formControlName="obeAEB"
                name="obeAEB"
                spellcheck="true"
                rows="4"
                maxlength="400"
              ></textarea>
            </mat-form-field>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Mood</h3>

            <ng-container formArrayName="mood">
              <div
                *ngFor="let option of moodOptions; let questId = index"
                [formGroupName]="questId"
              >
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <mat-form-field appearance="outline">
              <mat-label>AEB (up to 400 characters)</mat-label>
              <textarea
                matInput
                formControlName="mAEB"
                name="mAEB"
                spellcheck="true"
                rows="4"
                maxlength="400"
              ></textarea>
            </mat-form-field>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Cognition</h3>

            <ng-container formArrayName="cognition">
              <div
                *ngFor="let option of cognitionOptions; let questId = index"
                [formGroupName]="questId"
              >
                <label class="labelText">{{ option.label }}</label
                ><br />
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Perception</h3>

            <ng-container formArrayName="perception">
              <div
                *ngFor="let option of perceptionOptions; let questId = index"
                [formGroupName]="questId"
              >
                <label class="labelText">{{ option.label }}</label
                ><br />
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Thoughts</h3>

            <ng-container formArrayName="thought">
              <div
                *ngFor="let option of thoughtOptions; let questId = index"
                [formGroupName]="questId"
              >
                <label class="labelText">{{ option.label }}</label
                ><br />
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Behavior Towards Therapist</h3>

            <ng-container formArrayName="behaviourTowardsTherapist">
              <div
                *ngFor="
                  let option of behaviourTowardsTherapistOptions;
                  let questId = index
                "
                [formGroupName]="questId"
              >
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Insight</h3>

            <ng-container formArrayName="insight">
              <div
                *ngFor="let option of insightOptions; let questId = index"
                [formGroupName]="questId"
              >
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>

          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Judgement</h3>

            <ng-container formArrayName="judgement">
              <div
                *ngFor="let option of judgementOptions; let questId = index"
                [formGroupName]="questId"
              >
                <ng-container formArrayName="responses">
                  <ng-container
                    *ngFor="let response of option.responses; let optId = index"
                    [formGroupName]="optId"
                  >
                    <mat-checkbox
                      color="primary"
                      class="checkbox-margin"
                      formControlName="selected"
                      >{{ response.value }}</mat-checkbox
                    >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card>
        </div>

        <!-- <div class="alert alert-info col-md-12">
                    Reactive Form Value:
                    <pre>{{ objectiveForm.value | json }}</pre>
                </div> -->

        <div
          class="col-md-12"
          style="display: flex; justify-content: space-between"
        >
          <button
            class="btn btn-default pull-right"
            mat-raised-button
            type="button"
            matStepperPrevious
          >
            Previous
          </button>
          <button
            class="btn btn-primary pull-right"
            mat-raised-button
            type="button"
            (click)="onNextClicked(stepper)"
          >
            Next
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="confirmStep">
        <ng-template matStepLabel>Confirm</ng-template>
        <div>
          <mat-card appearance="raised" class="mat-elevation-z8 margin20">
            <h3 style="font-weight: 450">Appointment Details</h3>

            <label class="labelText">
              <span style="font-weight: 600">Appointment Start</span> :
              {{
                objectiveForm.controls['start'].value
                  | date : 'M/d/y, h:mm a' : currentTimezone
              }}
              {{ currentTimezone }}
            </label>
            <br />
            <label class="labelText">
              <span style="font-weight: 600">Appointment End</span> :
              {{
                objectiveForm.controls['end'].value
                  | date : 'M/d/y, h:mm a' : currentTimezone
              }}
              {{ currentTimezone }}
            </label>
            <br />
            <label class="labelText">
              <span style="font-weight: 600">Therapeutic Modality</span>
              :
              {{ objectiveForm.controls['therapeuticModality'].value }}
            </label>
          </mat-card>

          <mat-card
            *ngIf="isBPSAEnforced"
            appearance="raised"
            class="mat-elevation-z8 margin20"
          >
            <h3 style="font-weight: 450">Subjective</h3>

            <label class="labelText">
              {{ objectiveForm.controls['subjective'].value }}
            </label>
          </mat-card>

          <mat-card
            *ngIf="isBPSAEnforced"
            appearance="raised"
            class="mat-elevation-z8 margin20"
          >
            <h3 style="font-weight: 450">Objective</h3>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600"
                >Observations</label
              ><br />
              <div style="padding-left: 30px">
                <b>Appearance: </b
                >{{ getAnswers('observations', 'obsAppearance') }}
              </div>
              <div style="padding-left: 30px">
                <b>Speech: </b>{{ getAnswers('observations', 'obsSpeech') }}
              </div>
              <div style="padding-left: 30px">
                <b>Eye Contact: </b
                >{{ getAnswers('observations', 'obsEyeContact') }}
              </div>
              <div style="padding-left: 30px">
                <b>Motor Activity: </b
                >{{ getAnswers('observations', 'obsMotorActivity') }}
              </div>
              <div style="padding-left: 30px">
                <b>Affect: </b>{{ getAnswers('observations', 'obsAffect') }}
              </div>
              <div style="padding-left: 30px">
                <b>AEB: </b>{{ getAebValue('obeAEB') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600">Mood</label
              ><br />
              <div style="padding-left: 30px">
                {{ getAnswers('mood', 'm') }}
              </div>
              <div style="padding-left: 30px">
                <b>AEB: </b>{{ getAebValue('mAEB') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600">Cognition</label
              ><br />
              <div style="padding-left: 30px">
                <b>Orientation: </b
                >{{ getAnswers('cognition', 'cogOrientation') }}
              </div>
              <div style="padding-left: 30px">
                <b>Organization: </b
                >{{ getAnswers('cognition', 'cogOrganization') }}
              </div>
              <div style="padding-left: 30px">
                <b>Memory: </b>{{ getAnswers('cognition', 'cogMemory') }}
              </div>
              <div style="padding-left: 30px">
                <b>Attention: </b>{{ getAnswers('cognition', 'cogAttention') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600"
                >Perception</label
              ><br />
              <div style="padding-left: 30px">
                <b>Hallucinations: </b
                >{{ getAnswers('perception', 'percHallucinations') }}
              </div>
              <div style="padding-left: 30px">
                <b>Other: </b>{{ getAnswers('perception', 'percOther') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600">Thoughts</label
              ><br />
              <div style="padding-left: 30px">
                <b>Suicidal: </b>{{ getAnswers('thought', 'thSuicidal') }}
              </div>
              <div style="padding-left: 30px">
                <b>Homicidal: </b>{{ getAnswers('thought', 'thHomicidal') }}
              </div>
              <div style="padding-left: 30px">
                <b>Content: </b>{{ getAnswers('thought', 'thContent') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600"
                >Behavior Towards Therapist</label
              ><br />
              <div style="padding-left: 30px">
                {{ getAnswers('behaviourTowardsTherapist', 'btt') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600">Insight</label
              ><br />
              <div style="padding-left: 30px">
                {{ getAnswers('insight', 'ins') }}
              </div>
            </div>
            <div class="margin20">
              <label class="labelText" style="font-weight: 600">Judgement</label
              ><br />
              <div style="padding-left: 30px">
                {{ getAnswers('judgement', 'jud') }}
              </div>
            </div>
          </mat-card>
        </div>

        <div
          class="col-md-12"
          style="
            display: flex;
            justify-content: space-between;
            margin-right: 10px;
          "
        >
          <button
            class="btn btn-default pull-right"
            mat-raised-button
            type="button"
            matStepperPrevious
          >
            Previous
          </button>

          <button
            [class]="
              objectiveForm.valid ? 'btn btn-primary' : 'btn btn-default'
            "
            mat-raised-button
            type="button"
            [disabled]="!objectiveForm.valid || processing"
            (click)="onCloseSessionSubmit()"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
