import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReferralAttemptResult } from 'src/app/shared/utilities/referrals/referralAttemptResults';

@Component({
  selector: 'app-add-attempt-dialog',
  templateUrl: './add-attempt-dialog.component.html',
  styleUrl: './add-attempt-dialog.component.css',
})
export class AddAttemptDialogComponent {
  action: string = '';
  attemptCount: string = '';
  attemptDetails: any;
  addAttemptForm: FormGroup;

  userName: string = '';
  allCareProviders: any[];
  filteredCareProvidersList: any[];

  attemptResultsList = ReferralAttemptResult;

  constructor(
    public dialogRef: MatDialogRef<AddAttemptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.action = data.action;
    this.attemptCount = data.attemptCount;
    this.allCareProviders = data.allCareProviders;
    this.filteredCareProvidersList = data.allCareProviders;
    this.attemptDetails = data?.attemptDetails;
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.addAttemptForm.controls['attemptDateAndTime'].setValue(
        this.attemptDetails.attemptDateAndTime
      );
      this.addAttemptForm.controls['attemptResult'].setValue(
        this.attemptDetails.attemptResult
      );
      this.addAttemptForm.controls['recordedByUserId'].setValue(
        this.attemptDetails.recordedByUserId
      );
      this.addAttemptForm.controls['recordedByUserDetails'].setValue(
        this.attemptDetails.recordedByUserDetails
      );
      this.addAttemptForm.controls['attemptNote'].setValue(
        this.attemptDetails.attemptNote
      );
    }
  }

  buildForm() {
    this.addAttemptForm = this.formBuilder.group({
      attemptDateAndTime: new FormControl('', Validators.required),
      attemptResult: new FormControl('', Validators.required),
      recordedByUserId: new FormControl('', Validators.required),
      recordedByUserDetails: new FormControl(null, Validators.required),
      attemptNote: new FormControl(''),
    });
  }

  // Filter case managers
  filterAllActiveCaseManagers() {
    if (this.userName) {
      this.filteredCareProvidersList = this.allCareProviders.filter(
        (provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.userName.toLowerCase()) ||
            concatLastFirst.toLowerCase().includes(this.userName.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredCareProvidersList = [...this.allCareProviders];
    }
  }

  userSelected() {
    let selectedUser = this.allCareProviders
      .filter(
        (provider) =>
          provider.id === this.addAttemptForm.controls['recordedByUserId'].value
      )
      .at(0);

    this.addAttemptForm.controls['recordedByUserDetails'].setValue(
      selectedUser
    );
  }

  addRecord() {
    let attemptDetails = Object.assign({}, this.addAttemptForm.value);
    this.dialogRef.close({ type: 'success', attemptDetails: attemptDetails });
  }
}
