<mat-card-title>Intake</mat-card-title>
<mat-divider></mat-divider>
<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="casetreatmentintakeForm"
    novalidate
    (ngSubmit)="onCaseTreatmentIntakeFormSubmit()"
  >
    <div class="row">
      <!-- Facility -->
      <div class="col-md-12">
        <label class="labelBold">
          Facility
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="intakeFacility"
            placeholder="Facility"
            (selectionChange)="facilitySelected($event)"
          >
            <mat-option *ngFor="let facility of facilities" [value]="facility">
              {{ facility.facilityName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['intakeFacilityId'].invalid"
          >
            Please select a facility
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Intake Date -->
      <div class="col-md-6">
        <label class="labelText">
          Intake Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="intakeDate"
            matInput
            [matDatepicker]="intakeDatePicker"
            autoSlashDate
            placeholder="Enter intake date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="intakeDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #intakeDatePicker></mat-datepicker>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['intakeDate'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- County of Residence -->
      <div class="col-md-6">
        <label class="labelText">
          County Of Residence <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="countyOfResidence"
            placeholder="County Of Residence"
          >
            <mat-option *ngFor="let county of counties" [value]="county">
              {{ county }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentintakeForm.controls['countyOfResidence'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Source Of Referral -->
      <div class="col-md-6">
        <label class="labelText">
          Source Of Referral <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="sourceOfReferral"
            placeholder="Source Of Referral"
          >
            <mat-option *ngFor="let source of referralSources" [value]="source">
              {{ source }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['sourceOfReferral'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Initial Contact Type -->
      <div class="col-md-6">
        <label class="labelText">
          Initial Contact Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="initialContact"
            placeholder="Initial Contact"
          >
            <mat-option
              *ngFor="let contact of initialContactTypes"
              [value]="contact"
            >
              {{ contact }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['initialContact'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-6"
        *ngIf="patient.gender === 'FEMALE' || patient.gender === 'F'"
      >
        <label class="labelText">
          Pregnant<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="pregnant" placeholder="Pregnant">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not Applicable">Not Applicable</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['pregnant'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <ng-container
          *ngIf="casetreatmentintakeForm.get('pregnant').value === 'Yes'"
        >
          <label class="labelText">
            Due Date <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              formControlName="dueDate"
              matInput
              autoSlashDate
              [matDatepicker]="dueDatePicker"
              placeholder="Enter Due Date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dueDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['dueDate'].invalid"
          >
            Please enter a valid date
          </mat-error>
        </ng-container>
      </div>

      <div class="col-md-6">
        <label class="labelText">
          Injection Drug User <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="injectionDrugUser"
            placeholder="Injection Drug User"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Denies">Denies</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentintakeForm.controls['injectionDrugUser'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6"></div>

      <div class="col-md-6">
        <label class="labelText">
          Criminal Justice Client <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="criminalJusticeClient"
            placeholder="Criminal Justice Client"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentintakeForm.controls['criminalJusticeClient'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-6"
        *ngIf="
          casetreatmentintakeForm.get('criminalJusticeClient').value === 'Yes'
        "
      >
        <label class="labelText">
          Number Of Days Since Release <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="numberOfDaysSinceRelease"
            matInput
            placeholder="Enter Number of Days Since Release"
            type="number"
            min="0"
          />
          <mat-error
            *ngIf="
              casetreatmentintakeForm.controls['numberOfDaysSinceRelease']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Problem Area <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="problemArea" placeholder="Problem Area">
            <mat-option *ngFor="let problem of problemAreas" [value]="problem">
              {{ problem }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="casetreatmentintakeForm.controls['problemArea'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Presenting Problem (In Client's Own Words)
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="presentingProblem"
            matInput
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="
              casetreatmentintakeForm.controls['presentingProblem'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="casetreatmentintakeForm.invalid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
