import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models/session/therapySession.model';
import {
  TherapySessionService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services';

@Component({
  selector: 'app-session-assign',
  templateUrl: './session-assign.component.html',
  styleUrl: './session-assign.component.css',
})
export class SessionAssignComponent implements OnInit {
  patientId: string;
  therapySession: ITherapySession;

  providerName: string = '';
  providersList: any = [];
  filteredProvidersList: any = [];
  providersListLoading: boolean = true;

  public assignForm: FormGroup;

  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SessionAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService,
    public therapysessionApiService: TherapySessionService,
    private formBuilder: FormBuilder
  ) {
    this.patientId = data.patientId;
    this.therapySession = data.therapySession;
  }

  ngOnInit() {
    this.buildForm();

    this.loadActiveProviders();
  }

  private buildForm() {
    this.assignForm = this.formBuilder.group({
      patientId: new FormControl(this.patientId, Validators.required),
      providerId: new FormControl('', Validators.required),
      note: new FormControl(''),
    });
  }

  loadActiveProviders() {
    this.providersListLoading = true;

    this.userApiService.getAllCareProvidersAtMyAssignedFacility().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.filteredProvidersList = response.items;
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers.'
        );
        this.providersListLoading = false;
      },
    });
  }

  // Search Filter for supervising provider
  filterProvider() {
    if (this.providerName) {
      this.filteredProvidersList = this.providersList.filter((provider) => {
        const concatFirstLast = provider.firstName + ' ' + provider.lastName;
        const concatLastFirst = provider.lastName + ' ' + provider.firstName;

        if (
          concatFirstLast
            .toLowerCase()
            .includes(this.providerName.toLowerCase()) ||
          concatLastFirst
            .toLowerCase()
            .includes(this.providerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredProvidersList = [...this.providersList];
    }
  }

  onSessionAssignFormSubmit() {
    if (this.assignForm.invalid) {
      this.assignForm.markAllAsTouched();
      return;
    }

    let payload = {
      clinicianId: this.assignForm.controls['providerId'].value,
      notes: this.assignForm.controls['note'].value,
    };

    this.processing = true;

    this.therapysessionApiService
      .assignClinician(this.therapySession.id, payload)
      .subscribe({
        next: () => {
          this.processing = false;

          this.toastMessageService.displaySuccessMessage(
            'Note has has been assigned to the provider'
          );

          this.dialogRef.close('success');
        },
        error: () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to assign note to the provider'
          );
        },
      });
  }
}
