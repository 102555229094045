import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { DataTablesModel } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrl: './claims-list.component.css',
})
export class ClaimsListComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() selectedClaimTab: string;
  @Output() output = new EventEmitter<boolean>();

  searchStartDate: Date;
  searchEndDate: Date;
  searchClaimCode = '';
  searchSessionCode = '';
  searchMemberName: string = '';
  searchProviderName: string = '';
  billingSequence: string = '';

  processing: boolean = false;
  apiError: boolean = false;

  currentTimezone: string;
  currentTime = new Date();

  selectedSessionId: string = '';

  data: DataTablesModel = {} as DataTablesModel;
  displayedColumns = [
    'claimId',
    'referenceIds',
    'member',
    'time',
    'payer',
    'sequence',
    'renderingProvider',
    'status',
    'totalCharge',
    'actions',
  ];

  // Claims Permission
  hasClaimsViewAccess = false;
  hasClaimsEditAccess = false;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService,
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.permissionsSandbox.permissions$.subscribe((response) => {
            this.hasClaimsViewAccess = hasAccess(
              this.keycloakService,
              'CLAIMS_VIEW',
              response,
              null
            );
            this.hasClaimsEditAccess = hasAccess(
              this.keycloakService,
              'CLAIMS_EDIT',
              response,
              null
            );
          });
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedClaimTab) {
      this.data.per_page = 10;
      this.data.page = 0;
      this.selectedSessionId = null;
      this.loadClaims();
    }
  }

  loadClaims() {
    this.processing = true;
    this.apiError = false;
    this.claimsApiService
      .getAllClaims(
        this.searchMemberName,
        this.searchProviderName,
        this.selectedClaimTab,
        this.searchClaimCode,
        this.searchSessionCode,
        this.searchStartDate,
        this.searchEndDate,
        this.billingSequence,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the claims list.'
          );
          this.apiError = true;
          this.processing = false;
        },
      });
  }

  clearDate() {
    this.searchStartDate = null;
    this.searchEndDate = null;

    this.searchChanged();
  }

  searchChanged() {
    this.data.page = 0;
    this.loadClaims();
  }

  billingSequenceChanged(sequence) {
    this.billingSequence = sequence;
    this.searchChanged();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadClaims();
  }

  selectClaim(claim) {
    // Navigating to Primary Claim
    if (claim.billingSequence === 'P') {
      this.router.navigate([`/claims/details/${claim.id}`], {
        queryParams: {
          parent: 'claims',
          prevTab: this.selectedClaimTab,
        },
      });
    } else if (claim.billingSequence === 'S') {
      // Navigating to Secondary Claim using primaryclaimid
      this.router.navigate([`/claims/secondary/${claim.primaryClaimId}`], {
        queryParams: {
          parent: 'claims',
        },
      });
    }
  }

  unarchiveClaim(claim) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'UnArchive Claim',
        msg: 'Are you sure that you want to unarchive claim?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;
        if (claim.billingSequence === 'P') {
          this.claimsApiService
            .unArchievePrimaryClaim(
              claim.therapySession.id,
              claim.therapySession.patientId
            )
            .subscribe({
              next: (response) => {
                this.toastMessageService.displaySuccessMessage(
                  'Successfully unarchived the claim'
                );
                this.processing = false;
                this.loadClaims();
                this.output.emit(true);
              },
              error: (error) => {
                this.processing = false;
                this.toastMessageService.displayErrorMessage(
                  'Error: Failed to unarchive the claim'
                );
              },
            });
        }
      }
    });
  }

  fileOtherClaim(claim) {
    this.router.navigate([`/claims/secondary/${claim.id}`], {
      queryParams: {
        parent: 'claims',
      },
    });
  }

  viewHistory(claim) {
    // Navigating to Primary Claim
    if (claim.billingSequence === 'P') {
      this.router.navigate([`/claims/history/${claim.id}`], {
        queryParams: {
          parent: 'claims',
          prevTab: this.selectedClaimTab,
        },
      });
    } else if (claim.billingSequence === 'S') {
      this.router.navigate([`/claims/secondaryhistory/${claim.id}`], {
        queryParams: {
          parent: 'claims',
          prevTab: '',
        },
      });
    }
  }

  viewPaymentHistory(claim) {
    this.router.navigate([`/remittances/remit/paymenthistory/${claim.id}`], {
      queryParams: {
        parent: 'claims',
      },
    });
  }
}
