import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IBigFive } from 'src/app/_models/assessments/personality/bigfive.model';
import { BigFiveApiService, ToastMessageService } from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-big-five',
  templateUrl: './big-five.component.html',
  styleUrls: ['./big-five.component.css'],
})
export class BigFiveComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public bigFiveApiService: BigFiveApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }

  public agreeableness: number;
  public extroversion: number;
  public conscientiousness: number;
  public neuroticism: number;
  public openness: number;

  public riskScore: number;
  public bigFiveForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  bigFiveAssessment: IBigFive;
  bigFiveAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.bigFiveForm, this.errors);

    this.loadBigFiveAssessment();
  }

  loadBigFiveAssessment() {
    this.bigFiveForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.bigFiveApiService
        .getBigFiveDetails(this.assessmentId)
        .subscribe((bigFiveAssessment) => {
          if (bigFiveAssessment.data) {
            this.bigFiveForm.controls['q1'].setValue(
              bigFiveAssessment.data.q1.answer
            );
            this.bigFiveForm.controls['q2'].setValue(
              bigFiveAssessment.data.q2.answer
            );
            this.bigFiveForm.controls['q3'].setValue(
              bigFiveAssessment.data.q3.answer
            );
            this.bigFiveForm.controls['q4'].setValue(
              bigFiveAssessment.data.q4.answer
            );
            this.bigFiveForm.controls['q5'].setValue(
              bigFiveAssessment.data.q5.answer
            );
            this.bigFiveForm.controls['q6'].setValue(
              bigFiveAssessment.data.q6.answer
            );
            this.bigFiveForm.controls['q7'].setValue(
              bigFiveAssessment.data.q7.answer
            );
            this.bigFiveForm.controls['q8'].setValue(
              bigFiveAssessment.data.q8.answer
            );
            this.bigFiveForm.controls['q9'].setValue(
              bigFiveAssessment.data.q9.answer
            );
            this.bigFiveForm.controls['q10'].setValue(
              bigFiveAssessment.data.q10.answer
            );
            this.bigFiveForm.controls['q11'].setValue(
              bigFiveAssessment.data.q11.answer
            );
            this.bigFiveForm.controls['q12'].setValue(
              bigFiveAssessment.data.q12.answer
            );
            this.bigFiveForm.controls['q13'].setValue(
              bigFiveAssessment.data.q13.answer
            );
            this.bigFiveForm.controls['q14'].setValue(
              bigFiveAssessment.data.q14.answer
            );
            this.bigFiveForm.controls['q15'].setValue(
              bigFiveAssessment.data.q15.answer
            );
            this.bigFiveForm.controls['q16'].setValue(
              bigFiveAssessment.data.q16.answer
            );
            this.bigFiveForm.controls['q17'].setValue(
              bigFiveAssessment.data.q17.answer
            );
            this.bigFiveForm.controls['q18'].setValue(
              bigFiveAssessment.data.q18.answer
            );
            this.bigFiveForm.controls['q19'].setValue(
              bigFiveAssessment.data.q19.answer
            );
            this.bigFiveForm.controls['q20'].setValue(
              bigFiveAssessment.data.q20.answer
            );
            this.bigFiveForm.controls['q21'].setValue(
              bigFiveAssessment.data.q21.answer
            );
            this.bigFiveForm.controls['q22'].setValue(
              bigFiveAssessment.data.q22.answer
            );
            this.bigFiveForm.controls['q23'].setValue(
              bigFiveAssessment.data.q23.answer
            );
            this.bigFiveForm.controls['q24'].setValue(
              bigFiveAssessment.data.q24.answer
            );
            this.bigFiveForm.controls['q25'].setValue(
              bigFiveAssessment.data.q25.answer
            );
            this.bigFiveForm.controls['q26'].setValue(
              bigFiveAssessment.data.q26.answer
            );
            this.bigFiveForm.controls['q27'].setValue(
              bigFiveAssessment.data.q27.answer
            );
            this.bigFiveForm.controls['q28'].setValue(
              bigFiveAssessment.data.q28.answer
            );
            this.bigFiveForm.controls['q29'].setValue(
              bigFiveAssessment.data.q29.answer
            );
            this.bigFiveForm.controls['q30'].setValue(
              bigFiveAssessment.data.q30.answer
            );
            this.bigFiveForm.controls['q31'].setValue(
              bigFiveAssessment.data.q31.answer
            );
            this.bigFiveForm.controls['q32'].setValue(
              bigFiveAssessment.data.q32.answer
            );
            this.bigFiveForm.controls['q33'].setValue(
              bigFiveAssessment.data.q33.answer
            );
            this.bigFiveForm.controls['q34'].setValue(
              bigFiveAssessment.data.q34.answer
            );
            this.bigFiveForm.controls['q35'].setValue(
              bigFiveAssessment.data.q35.answer
            );
            this.bigFiveForm.controls['q36'].setValue(
              bigFiveAssessment.data.q36.answer
            );
            this.bigFiveForm.controls['q37'].setValue(
              bigFiveAssessment.data.q37.answer
            );
            this.bigFiveForm.controls['q38'].setValue(
              bigFiveAssessment.data.q38.answer
            );
            this.bigFiveForm.controls['q39'].setValue(
              bigFiveAssessment.data.q39.answer
            );
            this.bigFiveForm.controls['q40'].setValue(
              bigFiveAssessment.data.q40.answer
            );
            this.bigFiveForm.controls['q41'].setValue(
              bigFiveAssessment.data.q41.answer
            );
            this.bigFiveForm.controls['q42'].setValue(
              bigFiveAssessment.data.q42.answer
            );
            this.bigFiveForm.controls['q43'].setValue(
              bigFiveAssessment.data.q43.answer
            );
            this.bigFiveForm.controls['q44'].setValue(
              bigFiveAssessment.data.q44.answer
            );
            this.bigFiveForm.controls['q45'].setValue(
              bigFiveAssessment.data.q45.answer
            );
            this.bigFiveForm.controls['q46'].setValue(
              bigFiveAssessment.data.q46.answer
            );
            this.bigFiveForm.controls['q47'].setValue(
              bigFiveAssessment.data.q47.answer
            );
            this.bigFiveForm.controls['q48'].setValue(
              bigFiveAssessment.data.q48.answer
            );
            this.bigFiveForm.controls['q49'].setValue(
              bigFiveAssessment.data.q49.answer
            );
            this.bigFiveForm.controls['q50'].setValue(
              bigFiveAssessment.data.q50.answer
            );

            this.riskScore = bigFiveAssessment.data.riskScore;

            this.agreeableness = bigFiveAssessment.data.props['a-score'];
            this.extroversion = bigFiveAssessment.data.props['e-score'];
            this.conscientiousness = bigFiveAssessment.data.props['c-score'];
            this.neuroticism = bigFiveAssessment.data.props['n-score'];
            this.openness = bigFiveAssessment.data.props['o-score'];

          }
        });
    }
  }

  onFormSubmit() {
    if (this.bigFiveForm.invalid) {
      this.bigFiveForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.bigFiveAssessment = Object.assign({}, this.bigFiveAssessment);
    this.bigFiveAssessment.patientId =
      this.bigFiveForm.controls['patientId'].value;
    this.bigFiveAssessment.sessionId =
      this.bigFiveForm.controls['sessionId'].value;
    this.bigFiveAssessment.assessmentRequestId =
      this.bigFiveForm.controls['assessmentRequestId'].value;
    this.bigFiveAssessment.q1 = {
      answer: this.bigFiveForm.controls['q1'].value,
    };
    this.bigFiveAssessment.q2 = {
      answer: this.bigFiveForm.controls['q2'].value,
    };
    this.bigFiveAssessment.q3 = {
      answer: this.bigFiveForm.controls['q3'].value,
    };
    this.bigFiveAssessment.q4 = {
      answer: this.bigFiveForm.controls['q4'].value,
    };
    this.bigFiveAssessment.q5 = {
      answer: this.bigFiveForm.controls['q5'].value,
    };
    this.bigFiveAssessment.q6 = {
      answer: this.bigFiveForm.controls['q6'].value,
    };
    this.bigFiveAssessment.q7 = {
      answer: this.bigFiveForm.controls['q7'].value,
    };
    this.bigFiveAssessment.q8 = {
      answer: this.bigFiveForm.controls['q8'].value,
    };
    this.bigFiveAssessment.q9 = {
      answer: this.bigFiveForm.controls['q9'].value,
    };
    this.bigFiveAssessment.q10 = {
      answer: this.bigFiveForm.controls['q10'].value,
    };
    this.bigFiveAssessment.q11 = {
      answer: this.bigFiveForm.controls['q11'].value,
    };
    this.bigFiveAssessment.q12 = {
      answer: this.bigFiveForm.controls['q12'].value,
    };
    this.bigFiveAssessment.q13 = {
      answer: this.bigFiveForm.controls['q13'].value,
    };
    this.bigFiveAssessment.q14 = {
      answer: this.bigFiveForm.controls['q14'].value,
    };
    this.bigFiveAssessment.q15 = {
      answer: this.bigFiveForm.controls['q15'].value,
    };
    this.bigFiveAssessment.q16 = {
      answer: this.bigFiveForm.controls['q16'].value,
    };
    this.bigFiveAssessment.q17 = {
      answer: this.bigFiveForm.controls['q17'].value,
    };
    this.bigFiveAssessment.q18 = {
      answer: this.bigFiveForm.controls['q18'].value,
    };
    this.bigFiveAssessment.q19 = {
      answer: this.bigFiveForm.controls['q19'].value,
    };
    this.bigFiveAssessment.q20 = {
      answer: this.bigFiveForm.controls['q20'].value,
    };
    this.bigFiveAssessment.q21 = {
      answer: this.bigFiveForm.controls['q21'].value,
    };
    this.bigFiveAssessment.q22 = {
      answer: this.bigFiveForm.controls['q22'].value,
    };
    this.bigFiveAssessment.q23 = {
      answer: this.bigFiveForm.controls['q23'].value,
    };
    this.bigFiveAssessment.q24 = {
      answer: this.bigFiveForm.controls['q24'].value,
    };
    this.bigFiveAssessment.q25 = {
      answer: this.bigFiveForm.controls['q25'].value,
    };
    this.bigFiveAssessment.q26 = {
      answer: this.bigFiveForm.controls['q26'].value,
    };
    this.bigFiveAssessment.q27 = {
      answer: this.bigFiveForm.controls['q27'].value,
    };
    this.bigFiveAssessment.q28 = {
      answer: this.bigFiveForm.controls['q28'].value,
    };
    this.bigFiveAssessment.q29 = {
      answer: this.bigFiveForm.controls['q29'].value,
    };
    this.bigFiveAssessment.q30 = {
      answer: this.bigFiveForm.controls['q30'].value,
    };
    this.bigFiveAssessment.q31 = {
      answer: this.bigFiveForm.controls['q31'].value,
    };
    this.bigFiveAssessment.q32 = {
      answer: this.bigFiveForm.controls['q32'].value,
    };
    this.bigFiveAssessment.q33 = {
      answer: this.bigFiveForm.controls['q33'].value,
    };
    this.bigFiveAssessment.q34 = {
      answer: this.bigFiveForm.controls['q34'].value,
    };
    this.bigFiveAssessment.q35 = {
      answer: this.bigFiveForm.controls['q35'].value,
    };
    this.bigFiveAssessment.q36 = {
      answer: this.bigFiveForm.controls['q36'].value,
    };
    this.bigFiveAssessment.q37 = {
      answer: this.bigFiveForm.controls['q37'].value,
    };
    this.bigFiveAssessment.q38 = {
      answer: this.bigFiveForm.controls['q38'].value,
    };
    this.bigFiveAssessment.q39 = {
      answer: this.bigFiveForm.controls['q39'].value,
    };
    this.bigFiveAssessment.q40 = {
      answer: this.bigFiveForm.controls['q40'].value,
    };
    this.bigFiveAssessment.q41 = {
      answer: this.bigFiveForm.controls['q41'].value,
    };
    this.bigFiveAssessment.q42 = {
      answer: this.bigFiveForm.controls['q42'].value,
    };
    this.bigFiveAssessment.q43 = {
      answer: this.bigFiveForm.controls['q43'].value,
    };
    this.bigFiveAssessment.q44 = {
      answer: this.bigFiveForm.controls['q44'].value,
    };
    this.bigFiveAssessment.q45 = {
      answer: this.bigFiveForm.controls['q45'].value,
    };
    this.bigFiveAssessment.q46 = {
      answer: this.bigFiveForm.controls['q46'].value,
    };
    this.bigFiveAssessment.q47 = {
      answer: this.bigFiveForm.controls['q47'].value,
    };
    this.bigFiveAssessment.q48 = {
      answer: this.bigFiveForm.controls['q48'].value,
    };
    this.bigFiveAssessment.q49 = {
      answer: this.bigFiveForm.controls['q49'].value,
    };
    this.bigFiveAssessment.q50 = {
      answer: this.bigFiveForm.controls['q50'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.bigFiveAssessment.accessCode = this.accessCode;

      this.bigFiveApiService.addGuestBigFive(this.bigFiveAssessment).subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted Big Five Assessment'
          );
          if (response.result) this.taskCompleted.emit(true);
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed submitting Big Five Assessment'
          );
        }
      );
    } else {
      this.bigFiveApiService.addBigFive(this.bigFiveAssessment).subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted Big Five Assessment'
          );
          if (response.result) this.taskCompleted.emit(true);
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed submitting Big Five Assessment'
          );
        }
      );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. I am the life of the party. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message: '2. I feel little concern for others. is required',
      },
    ],
    q3: [{ type: 'required', message: '3. I am always prepared. is required' }],
    q4: [
      {
        type: 'required',
        message: '4. I get stressed out easily. is required',
      },
    ],
    q5: [
      { type: 'required', message: '5. I have a rich vocabulary. is required' },
    ],
    q6: [{ type: 'required', message: '6. I don’t talk a lot. is required' }],
    q7: [
      {
        type: 'required',
        message: '7. I am interested in people. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. I leave my belongings around. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. I am relaxed most of the time. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. I have difficulty understanding abstract ideas. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. I feel comfortable around people. is required',
      },
    ],
    q12: [{ type: 'required', message: '12. I insult people. is required' }],
    q13: [
      {
        type: 'required',
        message: '13. I pay attention to details. is required',
      },
    ],
    q14: [
      { type: 'required', message: '14. I worry about things. is required' },
    ],
    q15: [
      {
        type: 'required',
        message: '15. I have a vivid imagination. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message: '16. I keep in the background. is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message: '17. I sympathize with others’ feelings. is required',
      },
    ],
    q18: [
      { type: 'required', message: '18. I make a mess of things. is required' },
    ],
    q19: [{ type: 'required', message: '19. I seldom feel blue. is required' }],
    q20: [
      {
        type: 'required',
        message: '20. I am not interested in abstract ideas. is required',
      },
    ],
    q21: [
      { type: 'required', message: '21. I start conversations. is required' },
    ],
    q22: [
      {
        type: 'required',
        message:
          '22. I am not interested in other people’s problems. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. I get chores done right away. is required',
      },
    ],
    q24: [
      { type: 'required', message: '24. I am easily disturbed. is required' },
    ],
    q25: [
      { type: 'required', message: '25. I have excellent ideas. is required' },
    ],
    q26: [
      { type: 'required', message: '26. I have little to say. is required' },
    ],
    q27: [
      { type: 'required', message: '27. I have a soft heart. is required' },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. I often forget to put things back in their proper place. is required',
      },
    ],
    q29: [{ type: 'required', message: '29. I get upset easily. is required' }],
    q30: [
      {
        type: 'required',
        message: '30. I do not have a good imagination. is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message:
          '31. I talk to a lot of different people at parties. is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message: '32. I am not really interested in others. is required',
      },
    ],
    q33: [{ type: 'required', message: '33. I like order. is required' }],
    q34: [
      { type: 'required', message: '34. I change my mood a lot. is required' },
    ],
    q35: [
      {
        type: 'required',
        message: '35. I am quick to understand things. is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message: '36. I don’t like to draw attention to myself. is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message: '37. I take time out for others. is required',
      },
    ],
    q38: [{ type: 'required', message: '38. I shirk my duties. is required' }],
    q39: [
      {
        type: 'required',
        message: '39. I have frequent mood swings. is required',
      },
    ],
    q40: [
      { type: 'required', message: '40. I use difficult words. is required' },
    ],
    q41: [
      {
        type: 'required',
        message: '41. I don’t mind being the center of attention. is required',
      },
    ],
    q42: [
      { type: 'required', message: '42. I feel others’ emotions. is required' },
    ],
    q43: [
      { type: 'required', message: '43. I follow a schedule. is required' },
    ],
    q44: [
      { type: 'required', message: '44. I get irritated easily. is required' },
    ],
    q45: [
      {
        type: 'required',
        message: '45. I spend time reflecting on things. is required',
      },
    ],
    q46: [
      {
        type: 'required',
        message: '46. I am quiet around strangers. is required',
      },
    ],
    q47: [
      {
        type: 'required',
        message: '47. I make people feel at ease. is required',
      },
    ],
    q48: [
      {
        type: 'required',
        message: '48. I am exacting in my work. is required',
      },
    ],
    q49: [{ type: 'required', message: '49. I often feel blue. is required' }],
    q50: [{ type: 'required', message: '50. I am full of ideas. is required' }],
  };

  private buildForm() {
    this.bigFiveForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q46: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q47: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q48: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q49: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q50: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.bigFiveForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.bigFiveForm) {
      return;
    }
    const form = this.bigFiveForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
