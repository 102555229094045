import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { subMonths, subYears } from 'date-fns';
import { IMoodLog } from 'src/app/_models/hourlylogs/moodlog.model';
import { ToastMessageService } from 'src/app/_services';
import { MoodLogApiService } from 'src/app/_services/hourlylogs/moodlog.service';
import { formatDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-mood-log-dialog',
  templateUrl: './add-mood-log-dialog.component.html',
  styleUrls: ['./add-mood-log-dialog.component.css'],
})
export class AddMoodLogDialogComponent implements OnInit {
  public action: string;
  public moodlogForm: FormGroup;
  patientId: string;
  moodlog: IMoodLog;
  todaysDate = new Date();
  minimumDate = subMonths(new Date(), 1);

  mood: string = '';
  selectedHour: number;

  processing: boolean = false;

  datetime = new FormControl('');
  mooddetails = new FormControl('', Validators.required);

  filteredHours = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddMoodLogDialogComponent>,
    public moodlogApiService: MoodLogApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.moodlog = data.moodlog;
    this.patientId = data.patientId;
  }

  public ngOnInit() {
    this.buildForm();
    this.selectedHour = new Date().getHours();

    if (this.action === 'EDIT') {
      this.moodlogForm.controls['id'].setValue(this.moodlog.id);
      this.moodlogForm.controls['organizationId'].setValue(
        this.moodlog.organizationId
      );
      this.moodlogForm.controls['patientId'].setValue(this.moodlog.patientId);
      this.moodlogForm.controls['mood'].setValue(this.moodlog.mood);
      this.moodlogForm.controls['hour'].setValue(this.moodlog.hour);
      this.moodlogForm.controls['color'].setValue(this.moodlog.color);
      this.moodlogForm.controls['note'].setValue(this.moodlog.note);
      this.moodlogForm.controls['logDate'].setValue(this.moodlog.logDate);
    }

    this.handleViewHours();
  }

  onMoodLogFormSubmit() {
    if (this.moodlogForm.invalid) {
      this.moodlogForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.moodlog = Object.assign({}, this.moodlog);
    this.moodlog = Object.assign(this.moodlog, this.moodlogForm.value);
    if (this.moodlog.logDate !== '') {
      this.moodlog.logDate = formatDate(new Date(this.moodlog.logDate));
    }

    this.moodlogApiService.updateMoodLog(this.moodlog).subscribe(
      (response) => {
        this.processing = false;
        this.toastMessageService.displaySuccessMessage(
          'Mood successfully logged.'
        );
        this.dialogRef.close('success');
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong. Please refresh the page.'
        );
      }
    );
  }

  private buildForm() {
    this.moodlogForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(''),
      patientId: new FormControl(
        this.patientId,
        Validators.compose([Validators.required])
      ),
      mood: new FormControl('', Validators.compose([Validators.required])),
      logDate: new FormControl(
        new Date(),
        Validators.compose([Validators.required])
      ),
      hour: new FormControl(
        new Date().getHours(),
        Validators.compose([Validators.required])
      ),
      color: new FormControl(''),
      note: new FormControl(''),
    });

    this.moodlogForm.controls['logDate'].valueChanges.subscribe(() => {
      this.handleViewHours();
    });
  }

  handleViewHours() {
    let selectedDate = formatDate(
      new Date(this.moodlogForm.controls['logDate'].value)
    );
    let todaysDate = formatDate(this.todaysDate);

    if (todaysDate === selectedDate) {
      this.filteredHours = this.hours.filter(
        (hour) => hour.value <= new Date().getHours()
      );
    } else {
      this.filteredHours = this.hours;
    }
  }

  setSelectedHour(selectedHour: any) {
    this.selectedHour = selectedHour;
    this.datetime.setValue(selectedHour);
    this.moodlogForm.controls['hour'].setValue(selectedHour);
  }

  setMood(mood: string): void {
    this.mood = mood;
    this.moodlogForm.controls['mood'].setValue(mood);

    if (mood === 'Very Sad') {
      this.moodlogForm.controls['color'].setValue('red');
    } else if (mood === 'Somewhat Sad') {
      this.moodlogForm.controls['color'].setValue('orange');
    } else if (mood === 'Neutral') {
      this.moodlogForm.controls['color'].setValue('#72b9f1');
    } else if (mood === 'Somewhat Happy') {
      this.moodlogForm.controls['color'].setValue('lightgreen');
    } else if (mood === 'Very Happy') {
      this.moodlogForm.controls['color'].setValue('green');
    }
  }

  hours: any = [
    { value: 0, text: '12 AM' },
    { value: 1, text: '1 AM' },
    { value: 2, text: '2 AM' },
    { value: 3, text: '3 AM' },
    { value: 4, text: '4 AM' },
    { value: 5, text: '5 AM' },
    { value: 6, text: '6 AM' },
    { value: 7, text: '7 AM' },
    { value: 8, text: '8 AM' },
    { value: 9, text: '9 AM' },
    { value: 10, text: '10 AM' },
    { value: 11, text: '11 AM' },
    { value: 12, text: '12 PM' },
    { value: 13, text: '1 PM' },
    { value: 14, text: '2 PM' },
    { value: 15, text: '3 PM' },
    { value: 16, text: '4 PM' },
    { value: 17, text: '5 PM' },
    { value: 18, text: '6 PM' },
    { value: 19, text: '7 PM' },
    { value: 20, text: '8 PM' },
    { value: 21, text: '9 PM' },
    { value: 22, text: '10 PM' },
    { value: 23, text: '11 PM' },
  ];
}
