import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IClaims, Patient } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';
import { AdjustmentGroupCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsGroupCode';
import { AdjustmentReasonCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsReasonCodes';
import { IntakeSearchAddInsuranceComponent } from '../../patient-details/patient-components/sessions/bpsa/bpsa-intake/intake-insurance-info/intake-search-add-insurance/intake-search-add-insurance.component';
import { EditPriorAuthComponent } from '../../patient-details/patient-components/sessions/session-billing/edit-prior-auth/edit-prior-auth.component';
import { LoadOtherClaimFromEobComponent } from '../load-other-claim-from-eob/load-other-claim-from-eob.component';
import { ClaimResubmissionCodesList } from 'src/app/shared/utilities/billing/claimResubmissionCodes';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { ClaimFilingIndicators } from 'src/app/shared/utilities/billing/claimFilingIndicators';
import { extractDateFromYYYYMMDDFormat } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-file-other-claims',
  templateUrl: './file-other-claims.component.html',
  styleUrl: './file-other-claims.component.css',
})
export class FileOtherClaimsComponent implements OnInit {
  primaryClaimId: string;
  parent: string;

  isLoadingDetails: boolean = false;
  isLoadingResults: boolean = false;
  processing: boolean = false;

  patient: Patient;
  currentTimezone: string;

  primaryClaimDetails: IClaims;
  secondaryClaimForm: FormGroup;
  secondaryClaimDetails: IClaims;

  adjustmentGroupCodesList = AdjustmentGroupCodes;
  adjustmentReasonCodesList = AdjustmentReasonCodes;
  resubmissionCodesList = ClaimResubmissionCodesList;
  healthInsuranceTypeList = HealthInsuranceTypes;
  claimFilingIndicatorList = ClaimFilingIndicators;

  todaysDate = new Date();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dateTimeZoneService: DateTimeZoneService,
    private patientAPIService: PatientService,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.primaryClaimId = this.route.snapshot.paramMap.get('primaryId');
    this.route.queryParamMap.subscribe((param) => {
      this.parent = param.get('parent');
    });

    this.buildForm();
    // Load Claim Details
    this.loadPrimaryClaimDetails();
  }

  private buildForm() {
    this.secondaryClaimForm = this.formBuilder.group({
      id: new FormControl(null),
      claimCode: new FormControl(''),
      organizationId: new FormControl('', Validators.required),
      patientId: new FormControl('', Validators.required),
      billingProviderRefId: new FormControl('', Validators.required),
      sessionId: new FormControl('', Validators.required),
      sessionCode: new FormControl('', Validators.required),
      therapySession: new FormControl(null, Validators.required),

      billingStatus: new FormControl('In Draft', Validators.required),
      billingSequence: new FormControl('S', Validators.required),

      // Secondary Claims Extra Fields
      primaryClaimCode: new FormControl('', Validators.required),
      primaryClaimId: new FormControl('', Validators.required),

      adjudicationDate: new FormControl('', Validators.required),
      payerPaidAmount: new FormControl('', Validators.required),
      remainingPatientLiability: new FormControl(''),
      claimLevelAdjustments: this.formBuilder.array([]),

      insuranceDetails: new FormControl(null, Validators.required),
      claimFilingIndicator: new FormControl('', Validators.required),
      healthInsuranceType: new FormControl('', Validators.required),

      priorAuthCode: new FormControl('No'),
      priorAuthDetails: new FormControl(null),

      serviceLevelDetails: this.formBuilder.array([]),
      resubmissionCode: new FormControl(''),
    });
  }

  // Loading Primary Claim Details
  loadPrimaryClaimDetails() {
    this.isLoadingDetails = true;
    this.claimsApiService.getClaimDetails(this.primaryClaimId).subscribe({
      next: (response) => {
        // Get claim details
        this.primaryClaimDetails = response.data;

        // Load Patient's information
        this.loadPatientDetails();

        // Load Primary Claim Data
        this.loadPrimaryClaimDataOnForm();

        // Load Charges
        this.loadCPTCharges();

        // Load Secondary Claim Details
        if (
          this.primaryClaimDetails.secondaryClaimStarted &&
          this.primaryClaimDetails.secondaryClaimId
        ) {
          this.loadSecondaryClaimDetails();
        }
      },
      error: (error) => {
        this.isLoadingDetails = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve primary claim details'
        );
      },
    });
  }

  loadPrimaryClaimDataOnForm() {
    this.secondaryClaimForm.controls['organizationId'].setValue(
      this.primaryClaimDetails.organizationId
    );
    this.secondaryClaimForm.controls['patientId'].setValue(
      this.primaryClaimDetails.patientId
    );
    this.secondaryClaimForm.controls['billingProviderRefId'].setValue(
      this.primaryClaimDetails.billingProviderRefId
    );
    this.secondaryClaimForm.controls['sessionId'].setValue(
      this.primaryClaimDetails.sessionId
    );
    this.secondaryClaimForm.controls['sessionCode'].setValue(
      this.primaryClaimDetails.sessionCode
    );
    this.secondaryClaimForm.controls['therapySession'].setValue(
      this.primaryClaimDetails.therapySession
    );
    this.secondaryClaimForm.controls['primaryClaimCode'].setValue(
      this.primaryClaimDetails.claimCode
    );
    this.secondaryClaimForm.controls['primaryClaimId'].setValue(
      this.primaryClaimDetails.id
    );
    // Only if there is secondary insurance already selected, load secondary
    if (this.primaryClaimDetails?.therapySession?.secondaryInsuranceDetails) {
      this.secondaryClaimForm.controls['insuranceDetails'].setValue(
        this.primaryClaimDetails.therapySession.secondaryInsuranceDetails
      );

      this.secondaryClaimForm.controls['claimFilingIndicator'].setValue(
        this.primaryClaimDetails.therapySession.secondaryInsuranceDetails
          .claimFilingIndicator
      );
      this.secondaryClaimForm.controls['healthInsuranceType'].setValue(
        this.primaryClaimDetails.therapySession.secondaryInsuranceDetails
          .healthInsuranceType
      );
    }
  }

  // Loading Secondary Claims Details
  loadSecondaryClaimDetails() {
    this.isLoadingDetails = true;
    this.claimsApiService
      .getClaimDetails(this.primaryClaimDetails.secondaryClaimId)
      .subscribe({
        next: (response) => {
          this.isLoadingDetails = false;
          this.secondaryClaimDetails = response.data;

          this.loadSecondaryClaimDataOnForm();

          // check if this is re-submission
          if (this.secondaryClaimDetails?.claimSubmittedToPayer) {
            this.secondaryClaimForm.controls['resubmissionCode'].addValidators(
              Validators.required
            );
            this.secondaryClaimForm.controls[
              'resubmissionCode'
            ].updateValueAndValidity();
          }
        },
        error: (error) => {
          this.isLoadingDetails = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve secondary claim details'
          );
        },
      });
  }

  // Loading all previously selected/saved data
  loadSecondaryClaimDataOnForm() {
    this.secondaryClaimForm.controls['id'].setValue(
      this.secondaryClaimDetails.id
    );
    this.secondaryClaimForm.controls['claimCode'].setValue(
      this.secondaryClaimDetails.claimCode
    );
    this.secondaryClaimForm.controls['billingStatus'].setValue(
      this.secondaryClaimDetails.billingStatus
    );

    // For Adjudication date
    if (this.secondaryClaimDetails.adjudicationDate) {
      this.secondaryClaimForm.controls['adjudicationDate'].setValue(
        this.secondaryClaimDetails.adjudicationDate
      );
    }
    this.secondaryClaimForm.controls['payerPaidAmount'].setValue(
      this.secondaryClaimDetails.payerPaidAmount
    );
    this.secondaryClaimForm.controls['remainingPatientLiability'].setValue(
      this.secondaryClaimDetails.remainingPatientLiability
    );

    // For Claim Level Adjustments
    if (this.secondaryClaimDetails.claimLevelAdjustments) {
      //There is claim level adjustments
      this.loadClaimLevelAdjustment(
        this.secondaryClaimDetails.claimLevelAdjustments
      );
    }

    // Only if there is secondary insurance already selected, load secondary
    if (this.secondaryClaimDetails?.insuranceDetails) {
      this.secondaryClaimForm.controls['insuranceDetails'].setValue(
        this.secondaryClaimDetails.insuranceDetails
      );

      this.secondaryClaimForm.controls['claimFilingIndicator'].setValue(
        this.secondaryClaimDetails.claimFilingIndicator
      );
      this.secondaryClaimForm.controls['healthInsuranceType'].setValue(
        this.secondaryClaimDetails.healthInsuranceType
      );
    }

    // For Prior auth
    this.secondaryClaimForm.controls['priorAuthCode'].setValue(
      this.secondaryClaimDetails.priorAuthCode
    );
    if (this.secondaryClaimDetails?.priorAuthDetails) {
      this.secondaryClaimForm.controls['priorAuthDetails'].setValue(
        this.secondaryClaimDetails.priorAuthDetails
      );
    }
    this.priorAuthChanged({ value: this.secondaryClaimDetails.priorAuthCode });

    // For service line COB
    if (this.secondaryClaimDetails?.serviceLevelDetails) {
      this.loadServiceLineDetails(
        this.secondaryClaimDetails?.serviceLevelDetails
      );
    }

    // For resubmission
    if (this.secondaryClaimDetails?.resubmissionCode) {
      this.secondaryClaimForm.controls['resubmissionCode'].setValue(
        this.secondaryClaimDetails.resubmissionCode
      );
    }
  }

  loadCPTCharges() {
    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );

    this.primaryClaimDetails.therapySession.charges.forEach((charge, index) => {
      control.push(
        new FormGroup({
          adjudicationDate: new FormControl('', Validators.required),

          cptCode: new FormControl(charge.cptCode, Validators.required),
          modifier1: new FormControl(charge.modifier1),
          modifier2: new FormControl(charge.modifier2),
          modifier3: new FormControl(charge.modifier3),
          modifier4: new FormControl(charge.modifier4),

          quantity: new FormControl(charge.defaultUnit, Validators.required),
          totalAmount: new FormControl(charge.charges, Validators.required),
          paidAmount: new FormControl('', Validators.required),
          patientResponsibility: new FormControl(''),
          claimAdjustments: this.formBuilder.array([]),
        })
      );
    });
  }

  addServiceLineDetails() {
    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );

    control.push(
      new FormGroup({
        adjudicationDate: new FormControl('', Validators.required),

        cptCode: new FormControl('', Validators.required),
        modifier1: new FormControl(''),
        modifier2: new FormControl(''),
        modifier3: new FormControl(''),
        modifier4: new FormControl(''),

        quantity: new FormControl('', Validators.required),
        totalAmount: new FormControl('', Validators.required),
        paidAmount: new FormControl('', Validators.required),
        patientResponsibility: new FormControl(''),
        claimAdjustments: this.formBuilder.array([]),
      })
    );
  }

  addClaimLevelAdjustments() {
    const control = <FormArray>(
      this.secondaryClaimForm.get('claimLevelAdjustments')
    );

    control.push(
      new FormGroup({
        groupCode: new FormControl('', Validators.required),
        groupCodeDescription: new FormControl(''),
        reasonCode: new FormControl('', Validators.required),
        reasonCodeDescription: new FormControl(''),
        amount: new FormControl('', Validators.required),
        quantity: new FormControl(''),
      })
    );
  }

  removeClaimLevelAdjustments(index) {
    const control = <FormArray>(
      this.secondaryClaimForm.get('claimLevelAdjustments')
    );
    control.removeAt(index);
  }

  addClaimServiceLevelAdjustments(index) {
    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );

    const innerControl = control.at(index);

    const claimAdjustmentsControl = <FormArray>(
      innerControl.get('claimAdjustments')
    );

    claimAdjustmentsControl.push(
      new FormGroup({
        groupCode: new FormControl('', Validators.required),
        groupCodeDescription: new FormControl(''),
        reasonCode: new FormControl('', Validators.required),
        reasonCodeDescription: new FormControl(''),
        amount: new FormControl('', Validators.required),
        quantity: new FormControl(''),
      })
    );
  }

  removeServiceLevelAdjustments(controlIndex, index) {
    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );

    const innerControl = control.at(controlIndex);

    const claimAdjustmentsControl = <FormArray>(
      innerControl.get('claimAdjustments')
    );

    claimAdjustmentsControl.removeAt(index);
  }

  // Loading Patient Details
  loadPatientDetails() {
    this.patientAPIService
      .getPatientDetails(this.primaryClaimDetails.patientId, null)
      .subscribe({
        next: (response) => {
          this.patient = response.data;
          this.isLoadingDetails = false;
        },
        error: (error) => {
          this.isLoadingDetails = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve member information'
          );
        },
      });
  }

  addEditInsurance() {
    let dialogRef = this.dialog.open(IntakeSearchAddInsuranceComponent, {
      data: {
        patient: this.patient,
        therapySession: null,
        parentClass: 'OtherClaim',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response?.type === 'success') {
        this.secondaryClaimForm.controls['insuranceDetails'].setValue(
          response.insurance
        );
        this.secondaryClaimForm.controls['claimFilingIndicator'].setValue(
          response.insurance.claimFilingIndicator
        );
        this.secondaryClaimForm.controls['healthInsuranceType'].setValue(
          response.insurance.healthInsuranceType
        );
      }
    });
  }

  priorAuthChanged(event) {
    if (event.value === 'Yes') {
      this.secondaryClaimForm
        .get('priorAuthDetails')
        .addValidators(Validators.required);
      this.secondaryClaimForm.get('priorAuthDetails').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.secondaryClaimForm.get('priorAuthDetails').setValue(null);
      this.secondaryClaimForm.get('priorAuthDetails').clearValidators();
      this.secondaryClaimForm.get('priorAuthDetails').updateValueAndValidity();
    }
  }

  lookUpPriorAuth() {
    let dialogRef = this.dialog.open(EditPriorAuthComponent, {
      data: {
        patient: this.patient,
        therapySession: this.primaryClaimDetails.therapySession,
        primaryInsuranceDetails:
          this.secondaryClaimForm.controls['insuranceDetails'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.secondaryClaimForm.controls['priorAuthDetails'].setValue(
          response.details
        );
      }
    });
  }

  removeInsurance() {
    this.secondaryClaimForm.controls['insuranceDetails'].setValue(null);
    this.secondaryClaimForm.controls['claimFilingIndicator'].setValue(null);
    this.secondaryClaimForm.controls['healthInsuranceType'].setValue(null);
  }

  loadDataFromEOB() {
    const dialogRef = this.dialog.open(LoadOtherClaimFromEobComponent, {
      data: { primaryClaimDetails: this.primaryClaimDetails },
      disableClose: true,
      autoFocus: false,
      minHeight: '60vh',
      minWidth: '80vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let eobSelected = response.eob;

        // Loading Data from EOB
        // First loading the payment Effective Date i.e adjudication date
        if (eobSelected.paymentEffectiveDate) {
          // Create a new Date object
          let dateObject = extractDateFromYYYYMMDDFormat(
            eobSelected.paymentEffectiveDate
          );

          this.secondaryClaimForm.controls['adjudicationDate'].setValue(
            dateObject
          );
        }

        // Now as the adjudicated date has been loaded, loading Claim Level COB
        this.secondaryClaimForm.controls['payerPaidAmount'].setValue(
          eobSelected.paidAmount
        );

        // For Claim Level Adjustments
        if (
          eobSelected.claimLevelAdjustments &&
          eobSelected.claimLevelAdjustments.length > 0
        ) {
          //There is claim level adjustments
          this.loadClaimLevelAdjustment(eobSelected.claimLevelAdjustments);
        }

        // Now for service line COB
        if (
          eobSelected.serviceLineLoop &&
          eobSelected.serviceLineLoop.length > 0
        ) {
          // For each service line in the loop
          this.loadServiceLineDetails(eobSelected.serviceLineLoop);
        }
      }
    });
  }

  loadClaimLevelAdjustment(claimLevelAdjustments) {
    // Remove the value from old control COB Adjustments
    const oldCOBAdjustmentControl = <FormArray>(
      this.secondaryClaimForm.get('claimLevelAdjustments')
    );
    while (oldCOBAdjustmentControl.length > 0) {
      oldCOBAdjustmentControl.removeAt(0);
    }

    claimLevelAdjustments.forEach((cladjustments) => {
      const control = <FormArray>(
        this.secondaryClaimForm.get('claimLevelAdjustments')
      );
      control.push(
        new FormGroup({
          groupCode: new FormControl(
            cladjustments.groupCode,
            Validators.required
          ),
          groupCodeDescription: new FormControl(
            cladjustments.groupCodeDescription
          ),
          reasonCode: new FormControl(
            cladjustments.reasonCode,
            Validators.required
          ),
          reasonCodeDescription: new FormControl(
            cladjustments.reasonCodeDescription
          ),
          amount: new FormControl(cladjustments.amount, Validators.required),
          quantity: new FormControl(cladjustments.quantity),
        })
      );
    });
  }

  // Loading the service line details
  loadServiceLineDetails(serviceLineLoop) {
    // Begin loop
    serviceLineLoop.forEach((slCOB) => {
      // Main Form Array Control
      const control = <FormArray>(
        this.secondaryClaimForm.get('serviceLevelDetails')
      );
      // Individual Service Line Control
      control.controls.forEach((formArrayControl) => {
        // Specific Service Line Control
        let servicelineFormGroup = <FormGroup>formArrayControl;
        let formGroupCPTValue = servicelineFormGroup.controls['cptCode'].value;
        // *******
        // Only if both CPT values match, update the data
        if (formGroupCPTValue == slCOB?.cptCode) {
          // Populate the service line COB values
          servicelineFormGroup.controls['adjudicationDate'].setValue(
            this.secondaryClaimForm.controls['adjudicationDate'].value
          );
          servicelineFormGroup.controls['quantity'].setValue(slCOB.quantity);
          servicelineFormGroup.controls['paidAmount'].setValue(
            slCOB.paidAmount
          );

          // REmove the value from old control
          const oldAdjustmentControl = <FormArray>(
            servicelineFormGroup.get('claimAdjustments')
          );
          while (oldAdjustmentControl.length > 0) {
            oldAdjustmentControl.removeAt(0);
          }

          // Claim adjustment for Service level
          if (slCOB.claimAdjustments && slCOB.claimAdjustments.length > 0) {
            slCOB.claimAdjustments.forEach((adjustment) => {
              const claimLevelAdjustmentControl = <FormArray>(
                servicelineFormGroup.get('claimAdjustments')
              );

              claimLevelAdjustmentControl.push(
                new FormGroup({
                  groupCode: new FormControl(
                    adjustment.groupCode,
                    Validators.required
                  ),
                  groupCodeDescription: new FormControl(
                    adjustment.groupCodeDescription
                  ),
                  reasonCode: new FormControl(
                    adjustment.reasonCode,
                    Validators.required
                  ),
                  reasonCodeDescription: new FormControl(
                    adjustment.reasonCodeDescription
                  ),
                  amount: new FormControl(
                    adjustment.amount,
                    Validators.required
                  ),
                  quantity: new FormControl(adjustment.quantity),
                })
              );
            });
          }
        }
      });
    });
  }

  viewHistory() {
    this.router.navigate(
      [`/claims/secondaryhistory/${this.secondaryClaimDetails.id}`],
      {
        queryParams: {
          parent: this.parent,
          prevTab: '',
        },
      }
    );
  }

  serviceLineAdjustmentGroupCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedAdjustment = this.adjustmentGroupCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineClaimAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      serviceLineClaimAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['groupCodeDescription'].setValue(
      selectedAdjustment.description
    );
  }

  serviceLineAdjustmentReasonCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedAdjustment = this.adjustmentReasonCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineClaimAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      serviceLineClaimAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['reasonCodeDescription'].setValue(
      selectedAdjustment.description
    );
  }

  markClaimOnHold() {
    this.processing = true;
    this.claimsApiService
      .markSecondaryClaimOnHold(this.secondaryClaimDetails.id)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.secondaryClaimDetails = response.data;
          this.toastMessageService.displaySuccessMessage(
            'Successfully marked the secondary claim as on Hold.'
          );
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to mark the secondary claim as on Hold.'
          );
        },
      });
  }

  saveForm() {
    let payload = Object.assign({}, this.secondaryClaimDetails);
    payload = Object.assign(payload, this.secondaryClaimForm.value);

    this.processing = true;
    this.claimsApiService.saveSecondaryClaim(payload).subscribe({
      next: (response) => {
        this.processing = false;
        this.secondaryClaimDetails = response.data;
        this.secondaryClaimForm.controls['id'].setValue(
          this.secondaryClaimDetails.id
        );

        this.toastMessageService.displaySuccessMessage(
          'Successfully saved the secondary claim.'
        );
      },
      error: (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Failed to save the secondary claim.'
        );
      },
    });
  }

  submitForm() {
    // First validate the form
    if (this.isFormValid()) {
      let payload = Object.assign({}, this.secondaryClaimDetails);
      payload = Object.assign(payload, this.secondaryClaimForm.value);

      this.processing = true;
      this.claimsApiService.submitSecondaryClaim(payload).subscribe({
        next: (response) => {
          this.processing = false;
          this.secondaryClaimDetails = response.data;
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted the secondary claim.'
          );

          // Re-routing to list page
          if (this.parent === 'claims') {
            this.router.navigate(['/claims']);
          } else {
            this.router.navigate([`/main/member/${this.patient.id}/CLAIMS`]);
          }
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to submit the secondary claim.'
          );
        },
      });
    }
  }

  isFormValid() {
    let errors = [];
    let payload = Object.assign({}, this.secondaryClaimDetails);
    payload = Object.assign(payload, this.secondaryClaimForm.value);
    this.secondaryClaimDetails = payload;
    // Adjudication Date
    if (!payload.adjudicationDate) {
      errors.push('Claim Level Adjudication Date is required');
    }

    // Payer paid amount
    if (payload.payerPaidAmount < 0 || payload.payerPaidAmount == null) {
      errors.push('Payer Paid Amount is required');
    }

    // Insurance Details
    if (payload.insuranceDetails == null) {
      errors.push('Insurance Details is required');
    }

    if (payload.healthInsuranceType == null || !payload.healthInsuranceType) {
      errors.push('Health Insurance Type is required');
    }

    if (payload.claimFilingIndicator == null || !payload.claimFilingIndicator) {
      errors.push('Claim Filing Indicator is required');
    }

    // Insurance Details
    if (payload.priorAuthCode === 'Yes' && payload.priorAuthDetails == null) {
      errors.push('Prior Authorization code is required');
    }

    // For Service line details
    if (payload.serviceLevelDetails) {
      for (const slcob of payload.serviceLevelDetails) {
        // Each service line needs adjudicated date, paid units, paid amount
        if (!slcob.adjudicationDate) {
          errors.push(
            'Adjudication Date for CPT Code ' + slcob.cptCode + ' is required'
          );
        }

        if (slcob.quantity == null) {
          errors.push(
            'Paid Units for CPT Code ' + slcob.cptCode + ' is required'
          );
        }

        if (slcob.paidAmount == null) {
          errors.push(
            'Paid Amount for CPT Code ' + slcob.cptCode + ' is required'
          );
        }

        // For service line claim adjustments
        if (slcob.claimAdjustments && slcob.claimAdjustments.length > 0) {
          let allAdjustmentAmount = 0;
          for (const slcadjustments of slcob.claimAdjustments) {
            allAdjustmentAmount += slcadjustments.amount;
          }

          // Sum of all adjustments amounts + paid amount =total service line charge
          if (!(allAdjustmentAmount + slcob.paidAmount == slcob.totalAmount)) {
            errors.push(
              'Total Amount for CPT Code ' +
                slcob.cptCode +
                ', $' +
                slcob.totalAmount +
                ' does not equal to sum of the paid amount and all adjustments ($' +
                (allAdjustmentAmount + slcob.paidAmount) +
                ').'
            );
          }
        }
      }
    }

    if (errors.length == 0) {
      this.secondaryClaimDetails.mindwiseClaimsScrubbingErrors = [];
      return true;
    } else {
      this.secondaryClaimDetails.mindwiseClaimsScrubbingErrors = errors;
      window.scroll(0, 0);
      this.toastMessageService.displayWarnMessage(
        'Please fix the following errors'
      );
      return false;
    }
  }
}
