import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { DataTablesModel } from 'src/app/_models/data-tables-modal';
import { IReferral } from 'src/app/_models/referrals/referral.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  constructor(private httpClient: HttpClient) {}

  getReferrals(
    memberName: string,
    memberPhone: string,
    referringProvider: string,
    startDate: any,
    endDate: any,
    dob: any,
    status: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    if (memberName) params = params.append('memberName', memberName);
    if (memberPhone) params = params.append('memberPhone', memberPhone);
    if (referringProvider)
      params = params.append('referringProvider', referringProvider);
    if (startDate) params = params.append('startDate', startDate);
    if (dob) params = params.append('memberDOB', dob);
    if (endDate) params = params.append('endDate', endDate);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(`${environment.referralUrl}/referrals`, {
      params: params,
    });
  }

  createReferral(referral: IReferral): Observable<IReferral> {
    return this.httpClient
      .post<any>(`${environment.referralUrl}/referral`, referral)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateReferral(referral: IReferral): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.referralUrl}/referral`, referral)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteReferral(id: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.referralUrl}/referral/${id}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addReferralAttempt(
    id: string,
    step: string,
    attemptDetails: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.referralUrl}/add-referral-attempt/${id}/${step}`,
        attemptDetails
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  removeReferralAttempt(id: string, step: string): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${environment.referralUrl}/remove-referral-attempt/${id}/${step}`
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getDetails(
    status: string,
    selectedFacilities: any[],
    staffMembers: any[]
  ): Observable<DataTablesModel> {
    const payload: any = {};
    if (status) payload.status = status;
    if (selectedFacilities) {
      payload.facilities = selectedFacilities.map(
        (facility: any) => facility.id
      );
    }
    if (staffMembers) {
      payload.staffMembers = staffMembers.map(
        (staffMember: any) => staffMember.id
      );
    }

    return this.httpClient.post<DataTablesModel>(
      `${environment.referralUrl}/referrals`,
      payload
    );
  }

  getReferralById(referralId: string): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.referralUrl}/referral/${referralId}`)
      .pipe(
        map((response) => {
          return response || {};
        })
      );
  }

  markMemberAsImported(referral: IReferral): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.referralUrl}/import-member`, referral)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
