import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseModal } from 'src/app/_models';
import { ICaseTreatmentAdmissionSubstance } from 'src/app/_models/case/treatment/admission/casetreatmentadmissionsubstance.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CaseTreatmentAdmissionSubstanceApiService {
	constructor(private httpClient: HttpClient) { }
	getCaseTreatmentAdmissionSubstanceDetails(caseId: string, recordType: string, programId: string): Observable<ResponseModal> {
		let params = new HttpParams();
		if (caseId) params = params.append('caseId', caseId);
		if (recordType) params = params.append('recordType', recordType);
        if (programId) params = params.append('programId', programId);

		return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/casetreatmentadmissionsubstance`, { params: params });
	}
	updateCaseTreatmentAdmissionSubstance(thisCaseTreatmentAdmissionSubstance: ICaseTreatmentAdmissionSubstance): Observable<ICaseTreatmentAdmissionSubstance> {
		const headers = { 'content-type': 'application/json' }
		return this.httpClient.put<ICaseTreatmentAdmissionSubstance>(`${environment.apiUrl}/casetreatmentadmissionsubstance`, thisCaseTreatmentAdmissionSubstance, { headers }).pipe(
			catchError((err) => {
				console.error(err);
				throw err;
			}
			)
		);
	}
}