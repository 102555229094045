import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPennStateWorry } from 'src/app/_models/assessments/anxiety/pennstateworry.model';
import {
  PennStateWorryApiService,
  ToastMessageService,
} from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-penn-state-worry',
  templateUrl: './penn-state-worry.component.html',
  styleUrls: ['./penn-state-worry.component.css'],
})
export class PennStateWorryComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public pennStateWorryApiService: PennStateWorryApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public pennStateWorryForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  pennStateWorryAssessment: IPennStateWorry;
  pennStateWorryAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.pennStateWorryForm, this.errors);

    this.loadPennStateWorryAssessment();
  }

  loadPennStateWorryAssessment() {
    this.pennStateWorryForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.pennStateWorryApiService
        .getPennStateWorryDetails(this.assessmentId)
        .subscribe((pennStateWorryAssessment) => {
          if (pennStateWorryAssessment.data) {
            this.pennStateWorryForm.controls['q1'].setValue(
              pennStateWorryAssessment.data.q1.answer
            );
            this.pennStateWorryForm.controls['q2'].setValue(
              pennStateWorryAssessment.data.q2.answer
            );
            this.pennStateWorryForm.controls['q3'].setValue(
              pennStateWorryAssessment.data.q3.answer
            );
            this.pennStateWorryForm.controls['q4'].setValue(
              pennStateWorryAssessment.data.q4.answer
            );
            this.pennStateWorryForm.controls['q5'].setValue(
              pennStateWorryAssessment.data.q5.answer
            );
            this.pennStateWorryForm.controls['q6'].setValue(
              pennStateWorryAssessment.data.q6.answer
            );
            this.pennStateWorryForm.controls['q7'].setValue(
              pennStateWorryAssessment.data.q7.answer
            );
            this.pennStateWorryForm.controls['q8'].setValue(
              pennStateWorryAssessment.data.q8.answer
            );
            this.pennStateWorryForm.controls['q9'].setValue(
              pennStateWorryAssessment.data.q9.answer
            );
            this.pennStateWorryForm.controls['q10'].setValue(
              pennStateWorryAssessment.data.q10.answer
            );
            this.pennStateWorryForm.controls['q11'].setValue(
              pennStateWorryAssessment.data.q11.answer
            );
            this.pennStateWorryForm.controls['q12'].setValue(
              pennStateWorryAssessment.data.q12.answer
            );
            this.pennStateWorryForm.controls['q13'].setValue(
              pennStateWorryAssessment.data.q13.answer
            );
            this.pennStateWorryForm.controls['q14'].setValue(
              pennStateWorryAssessment.data.q14.answer
            );
            this.pennStateWorryForm.controls['q15'].setValue(
              pennStateWorryAssessment.data.q15.answer
            );
            this.pennStateWorryForm.controls['q16'].setValue(
              pennStateWorryAssessment.data.q16.answer
            );

            this.riskScore = pennStateWorryAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.pennStateWorryForm.invalid) {
      this.pennStateWorryForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.pennStateWorryAssessment = Object.assign(
      {},
      this.pennStateWorryAssessment
    );
    this.pennStateWorryAssessment.patientId =
      this.pennStateWorryForm.controls['patientId'].value;
    this.pennStateWorryAssessment.sessionId =
      this.pennStateWorryForm.controls['sessionId'].value;
    this.pennStateWorryAssessment.assessmentRequestId =
      this.pennStateWorryForm.controls['assessmentRequestId'].value;
    this.pennStateWorryAssessment.q1 = {
      answer: this.pennStateWorryForm.controls['q1'].value,
    };
    this.pennStateWorryAssessment.q2 = {
      answer: this.pennStateWorryForm.controls['q2'].value,
    };
    this.pennStateWorryAssessment.q3 = {
      answer: this.pennStateWorryForm.controls['q3'].value,
    };
    this.pennStateWorryAssessment.q4 = {
      answer: this.pennStateWorryForm.controls['q4'].value,
    };
    this.pennStateWorryAssessment.q5 = {
      answer: this.pennStateWorryForm.controls['q5'].value,
    };
    this.pennStateWorryAssessment.q6 = {
      answer: this.pennStateWorryForm.controls['q6'].value,
    };
    this.pennStateWorryAssessment.q7 = {
      answer: this.pennStateWorryForm.controls['q7'].value,
    };
    this.pennStateWorryAssessment.q8 = {
      answer: this.pennStateWorryForm.controls['q8'].value,
    };
    this.pennStateWorryAssessment.q9 = {
      answer: this.pennStateWorryForm.controls['q9'].value,
    };
    this.pennStateWorryAssessment.q10 = {
      answer: this.pennStateWorryForm.controls['q10'].value,
    };
    this.pennStateWorryAssessment.q11 = {
      answer: this.pennStateWorryForm.controls['q11'].value,
    };
    this.pennStateWorryAssessment.q12 = {
      answer: this.pennStateWorryForm.controls['q12'].value,
    };
    this.pennStateWorryAssessment.q13 = {
      answer: this.pennStateWorryForm.controls['q13'].value,
    };
    this.pennStateWorryAssessment.q14 = {
      answer: this.pennStateWorryForm.controls['q14'].value,
    };
    this.pennStateWorryAssessment.q15 = {
      answer: this.pennStateWorryForm.controls['q15'].value,
    };
    this.pennStateWorryAssessment.q16 = {
      answer: this.pennStateWorryForm.controls['q16'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.pennStateWorryAssessment.accessCode = this.accessCode;

      this.pennStateWorryApiService
        .addGuestPennStateWorry(this.pennStateWorryAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Worry Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Worry Assessment'
            );
          }
        );
    } else {
      this.pennStateWorryApiService
        .addPennStateWorry(this.pennStateWorryAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Worry Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Worry Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. If I do not have enough time to do everything, I do not worry about it. is required',
      },
    ],
    q2: [
      { type: 'required', message: '2. My worries overwhelm me. is required' },
    ],
    q3: [
      {
        type: 'required',
        message: '3. I do not tend to worry about things. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message: '4. Many situations make me worry. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. I know I should not worry about things, but I just cannot help it. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message: '6. When I am under pressure I worry a lot. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: '7. I am always worrying about something. is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. I find it easy to dismiss worrisome thoughts. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. As soon as I finish one task, I start to worry about everything else I have to do. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: '10. I never worry about anything. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. When there is nothing more I can do about a concern, I do not worry about it any more. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '12. I have been a worrier all my life. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. I notice that I have been worrying about things. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message: '14. Once I start worrying, I cannot stop is required',
      },
    ],
    q15: [
      { type: 'required', message: '15. I worry all the time. is required' },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. I worry about projects until they are all done. is required',
      },
    ],
  };

  private buildForm() {
    this.pennStateWorryForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.pennStateWorryForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.pennStateWorryForm) {
      return;
    }
    const form = this.pennStateWorryForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
