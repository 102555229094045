<mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 20px">
  <div class="caseHeaderContainer" *ngIf="case">
    <span
      (click)="deSelectCaseTab()"
      style="color: #2196f3; cursor: pointer; font-weight: 500"
    >
      Cases
    </span>
    &nbsp; / &nbsp; {{ this.case.caseNumber }}

    <button
      *ngIf="hasCaseEditAccess"
      id="editButton"
      style="z-index: 1000; opacity: 0.4"
      mat-icon-button
      type="button"
      (click)="editCase()"
    >
      <mat-icon style="font-size: 16px">edit</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12" *ngIf="case && patient">
      <app-case-header
        [case]="case"
        [patient]="patient"
        [reload]="reloadHeader.asObservable()"
        (output)="onLoadTabTriggerEvent($event)"
      >
      </app-case-header>
    </div>
  </div>
</mat-card>

<div class="row">
  <div class="col-md-12" style="padding-top: 10px" *ngIf="case && patient">
    <nav
      mat-tab-nav-bar
      class="mat-elevation-z8"
      style="background-color: #e1f5fe"
      [tabPanel]="tabPanel"
    >
      <a
        mat-tab-link
        *ngIf="hasCaseLogViewAccess"
        (click)="changeTab('CARE-LOG')"
        [active]="activeTab === 'CARE-LOG'"
      >
        Care Log
      </a>
      <a
        mat-tab-link
        *ngIf="isTNwitsMember && isTreatmentSectionEnabled"
        (click)="changeTab('TREATMENT')"
        [active]="activeTab === 'TREATMENT'"
      >
        Treatment
      </a>
      <a
        mat-tab-link
        *ngIf="hasGoalsViewAccess && case.goalDrivenCare"
        (click)="changeTab('GOALS')"
        [active]="activeTab === 'GOALS'"
      >
        Goals
      </a>
      <a
        mat-tab-link
        *ngIf="hasCaseTaskViewAccess"
        (click)="changeTab('TASKS')"
        [active]="activeTab === 'TASKS'"
      >
        Tasks
      </a>
      <a
        mat-tab-link
        *ngIf="hasCaseNotesViewAccess"
        (click)="changeTab('CASE-NOTES')"
        [active]="activeTab === 'CASE-NOTES'"
      >
        Case Notes
      </a>
      <a
        mat-tab-link
        *ngIf="hasCaseDocumentsViewAccess"
        (click)="changeTab('DOCUMENTS')"
        [active]="activeTab === 'DOCUMENTS'"
      >
        Documents
      </a>
      <a
        mat-tab-link
        (click)="changeTab('PRINT')"
        [active]="activeTab === 'PRINT'"
      >
        Print
      </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
      <app-carelog
        [case]="case"
        [patient]="patient"
        [goalDrivenCare]="case.goalDrivenCare"
        [goals]="goals"
        [memberGoals]="memberGoals"
        [hasCaseLogViewAccess]="hasCaseLogViewAccess"
        [hasCaseLogAddAccess]="hasCaseLogAddAccess"
        [hasCaseLogEditAccess]="hasCaseLogEditAccess"
        [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
        *ngIf="activeTab === 'CARE-LOG'"
      >
      </app-carelog>
      <app-case-treatment
        [case]="case"
        [patient]="patient"
        *ngIf="
          activeTab === 'TREATMENT' &&
          isTNwitsMember &&
          isTreatmentSectionEnabled
        "
      ></app-case-treatment>
      <app-member-goals
        [case]="case"
        [patient]="patient"
        [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        *ngIf="activeTab === 'GOALS' && case.goalDrivenCare"
        (output)="reloadGoalsEvent($event)"
      >
      </app-member-goals>
      <app-case-tasks
        [case]="case"
        [patient]="patient"
        [goalDrivenService]="case.goalDrivenCare"
        [availableGoals]="goals"
        [hasCaseTaskViewAccess]="hasCaseTaskViewAccess"
        [hasCaseTaskAddAccess]="hasCaseTaskAddAccess"
        [hasCaseTaskEditAccess]="hasCaseTaskEditAccess"
        [hasCaseTaskDeleteAccess]="hasCaseTaskDeleteAccess"
        *ngIf="activeTab === 'TASKS'"
      >
      </app-case-tasks>
      <app-case-notes
        [case]="case"
        [patient]="patient"
        [hasCaseNotesViewAccess]="hasCaseNotesViewAccess"
        [hasCaseNotesAddAccess]="hasCaseNotesAddAccess"
        [hasCaseNotesEditAccess]="hasCaseNotesEditAccess"
        [hasCaseNotesDeleteAccess]="hasCaseNotesDeleteAccess"
        *ngIf="activeTab === 'CASE-NOTES'"
      >
      </app-case-notes>
      <app-case-documents
        *ngIf="activeTab === 'DOCUMENTS'"
        [case]="case"
        [patient]="patient"
        [loadDischargeDocs]="loadDischargeDocs.asObservable()"
        [documentType]="documentType"
        [hasCaseDocumentsViewAccess]="hasCaseDocumentsViewAccess"
        [hasCaseDocumentsAddAccess]="hasCaseDocumentsAddAccess"
        [hasCaseDocumentsDeleteAccess]="hasCaseDocumentsDeleteAccess"
        (output)="onDocumentAddedEvent($event)"
      >
      </app-case-documents>
      <app-case-print [case]="case" *ngIf="activeTab === 'PRINT'">
      </app-case-print>
    </mat-tab-nav-panel>
  </div>
</div>
