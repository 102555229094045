<div style="padding: 10px">
  <div
    class="row"
    style="margin-top: 15px; margin-bottom: 20px"
    *ngIf="!vitalInfo && !isProcessing"
  >
    <div
      class="col-md-12"
      *ngIf="therapySession.status === 'Active' && patient.status === 'Active'"
      style="text-align: right"
    >
      <button
        mat-raised-button
        type="button"
        (click)="captureVitals()"
        style="color: #2196f3"
      >
        + Add Vitals
      </button>
    </div>
  </div>

  <!-- Progress bar -->
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isProcessing"
  ></mat-progress-bar>

  <div class="row">
    <!-- // No Vitals -->
    <div class="col-md-12" *ngIf="!isProcessing && !vitalInfo">
      <mat-error> No vitals recorded </mat-error>
    </div>
  </div>

  <!-- Vital Information -->
  <div class="row" *ngIf="vitalInfo && !isProcessing">
    <div class="col-md-12">
      <mat-card
        appearance="raised"
        class="mat-elevation-z8"
        style="border-radius: 15px; position: relative"
      >
        <!-- Action -->
        <div class="goalAction">
          <div
            class="material-icons"
            style="
              color: gray;
              font-size: 17px;
              cursor: pointer;
              border-radius: 50%;
              z-index: 2;
              border: 2px solid darkgray;
            "
            (click)="editVitalData()"
          >
            more_vert
          </div>
        </div>

        <div class="vitalGridContainer">
          <mat-icon *ngIf="vitalInfo.height">height</mat-icon>
          <label *ngIf="vitalInfo.height" class="labelHeader"> Height </label>
          <label *ngIf="vitalInfo.height" class="labelText">
            {{ vitalInfo.height }} in
          </label>

          <mat-icon *ngIf="vitalInfo.weight">monitor_weight</mat-icon>
          <label *ngIf="vitalInfo.weight" class="labelHeader"> Weight </label>
          <label *ngIf="vitalInfo.weight" class="labelText">
            {{ vitalInfo.weight }} Lb
          </label>

          <mat-icon *ngIf="vitalInfo.bloodSugar">bloodtype</mat-icon>
          <label *ngIf="vitalInfo.bloodSugar" class="labelHeader">
            Blood Sugar
          </label>
          <label *ngIf="vitalInfo.bloodSugar" class="labelText">
            {{ vitalInfo.bloodSugar }}
          </label>

          <mat-icon *ngIf="vitalInfo.pulse">monitor_heart</mat-icon>
          <label *ngIf="vitalInfo.pulse" class="labelHeader"> Pulse </label>
          <label *ngIf="vitalInfo.pulse" class="labelText">
            {{ vitalInfo.pulse }}
          </label>

          <mat-icon *ngIf="vitalInfo.oxygenLevel">scatter_plot</mat-icon>
          <label *ngIf="vitalInfo.oxygenLevel" class="labelHeader">
            Oxygen Saturation
          </label>
          <label *ngIf="vitalInfo.oxygenLevel" class="labelText">
            {{ vitalInfo.oxygenLevel }}
          </label>

          <mat-icon *ngIf="vitalInfo.bodyTemperature">thermostat</mat-icon>
          <label *ngIf="vitalInfo.bodyTemperature" class="labelHeader">
            Body Temperature
          </label>
          <label *ngIf="vitalInfo.bodyTemperature" class="labelText">
            {{ vitalInfo.bodyTemperature }}
          </label>

          <mat-icon
            *ngIf="vitalInfo.bloodPressureHigh || vitalInfo.bloodPressureLow"
          >
            tire_repair
          </mat-icon>
          <label
            *ngIf="vitalInfo.bloodPressureHigh || vitalInfo.bloodPressureLow"
            class="labelHeader"
          >
            Blood Pressure
          </label>
          <label
            *ngIf="vitalInfo.bloodPressureHigh || vitalInfo.bloodPressureLow"
            class="labelText"
          >
            {{ vitalInfo.bloodPressureHigh }} /
            {{ vitalInfo.bloodPressureLow }}
          </label>
        </div>

        <div *ngIf="vitalInfo.customMessage" style="margin: 10px 0px">
          <label class="labelHeader" style="text-decoration: underline">
            Additional Details
          </label>
          <label class="labelText">&emsp;{{ vitalInfo.customMessage }}</label>
          <br />
        </div>

        <!-- Activity Date Time -->
        <div style="padding-top: 10px; text-align: right">
          <label>
            <small>{{ vitalInfo?.activityDateTime }}</small>
          </label>
        </div>
      </mat-card>
    </div>
  </div>
</div>
