<div class="col-md-12">
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle>ASAM Assessment</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form
    [formGroup]="casetreatmentasamForm"
    novalidate
    (ngSubmit)="onCaseTreatmentAsamFormSubmit()"
  >
    <!-- Acute Intoxication Row -->
    <div class="row">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelBold">Acute Intoxication</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="acuteIntoxicationLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['acuteIntoxicationLevelOfRisk']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="acuteIntoxicationLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['acuteIntoxicationLevelOfCare']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4" style="align-content: center">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="acuteIntoxicationComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- First Emotional Behavioral Row -->
    <div class="row pad20">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelText">First Emotional Behavioral</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="bioMedicalLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['bioMedicalLevelOfRisk'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="bioMedicalLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['bioMedicalLevelOfCare'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="bioMedicalComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Second Emotional Behavioral Row -->
    <div class="row pad20">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelText">Second Emotional Behavioral</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="emcLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="casetreatmentasamForm.controls['emcLevelOfRisk'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="emcLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="casetreatmentasamForm.controls['emcLevelOfCare'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="emcComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Readiness To Change Row -->
    <div class="row pad20">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelText">Readiness To Change</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="readinessToChangeLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['readinessToChangeLevelOfRisk']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="readinessToChangeLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['readinessToChangeLevelOfCare']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="readinessToChangeComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Relapse Potential Row -->
    <div class="row pad20">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelText">Relapse Potential</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="relapsePotentialLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['relapsePotentialLevelOfRisk']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="relapsePotentialLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['relapsePotentialLevelOfCare']
                .invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="relapsePotentialComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Relapse Potential Row -->
    <div class="row pad20">
      <div class="col-md-2" style="text-align: right; align-content: center">
        <label class="labelText">Recovery / Living Environment</label>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Risk <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="recoveryLevelOfRisk">
            <mat-option
              *ngFor="let option of riskLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['recoveryLevelOfRisk'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" style="align-content: center">
        <label class="labelText">
          Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="recoveryLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['recoveryLevelOfCare'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label class="labelText"> Comments </label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="recoveryComments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Clinical Override Row -->
    <div class="row" style="padding: 15px; margin-top: 25px">
      <div class="col-md-4">
        <label class="labelText">
          Recommended Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="recommendedLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              casetreatmentasamForm.controls['recommendedLevelOfCare'].invalid
            "
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">
          Acute Level of Care <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="actualLevelOfCare">
            <mat-option
              *ngFor="let option of careLevelOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="casetreatmentasamForm.controls['actualLevelOfCare'].invalid"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">Clinical Override</label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="clinicalOverride"
            placeholder="Clinical Override"
          >
            <mat-option
              *ngFor="let option of clinicalOverrideOptions"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">Comments</label>

        <mat-form-field appearance="outline">
          <textarea
            formControlName="comments"
            matInput
            rows="3"
            placeholder="Comments"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12" *ngIf="!readOnly" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!casetreatmentasamForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
